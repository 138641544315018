/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { breakpoints } from '@coursera/cds-core';
import { SearchIcon } from '@coursera/cds-icons';

import MagnifierSvg from 'bundles/browse/components/PageHeader/MagnifierSvg';
import { useIsSimplifiedPageHeader } from 'bundles/page-header/contexts/PageHeaderContext';
import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/page';

type Props = {
  fill: string;
  onClick: () => void;
};
const styles = {
  buttonInUnifiedPageHeader: css`
    && {
      margin: 0;
      padding: var(--cds-spacing-150);
      color: var(--cds-color-neutral-primary-weak);
      transition: background-color 0.3s ease;
    }
  `,
  icon: css`
    display: block;

    ${breakpoints.up('sm')} {
      width: 24px;
      height: 24px;
    }
  `,
};
const HeaderMobileSearchIcon = (props: Props) => {
  const isSimplifiedPageHeader = useIsSimplifiedPageHeader();

  return (
    <TrackedButton
      className="mobile-search-icon"
      trackingName="mobile_search_icon"
      onClick={props.onClick}
      style={{ border: 'none', backgroundColor: 'transparent' }}
      css={isSimplifiedPageHeader && styles.buttonInUnifiedPageHeader}
      data={{ name: 'search-icon' }}
      aria-label={_t('Search Coursera')}
    >
      {isSimplifiedPageHeader ? (
        <SearchIcon size="medium" css={styles.icon} color="support" />
      ) : (
        <MagnifierSvg fill={props.fill} />
      )}
    </TrackedButton>
  );
};

export default HeaderMobileSearchIcon;
