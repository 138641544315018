import memoize from 'lodash/memoize';

import requestCountryCode from 'bundles/phoenix/template/models/requestCountryCode';
import socialPlugins from 'bundles/socialPlugins/lib';
import constants from 'bundles/third-party-auth/constants';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import Instrumentation from 'bundles/userModal/lib/instrumentation';

const isRequestFromChina = requestCountryCode === 'CN';

type GoogleOneTapNotification = {
  getDismissedReason: () => string;
  getNotDisplayedReason: () => string;
  getSkippedReason: () => string;
  isDisplayed: () => boolean;
  isDismissedMoment: () => boolean;
  isNotDisplayed: () => boolean;
  isSkippedMoment: () => boolean;
};

type GoogleOneTapAccounts = {
  initialize: (options: object) => void;
  prompt: (handler: (notification: GoogleOneTapNotification) => void) => void;
};

type GoogleOneTapType = {
  accounts: { id: GoogleOneTapAccounts };
};

function nonce() {
  const dictionary = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return [...dictionary]
    .sort(() => Math.random() - 0.5)
    .slice(0, 10)
    .join('');
}

// Memoized so initialization will only run once
const googleOneTap = {
  enabled: !isRequestFromChina,
  init: memoize(async () => {
    const Google = (await socialPlugins.googleOneTap()) as GoogleOneTapType;

    return Google.accounts.id;
  }),
  loaded: false,
  getStatusOrLogin() {
    // eslint-disable-next-line new-cap
    return new Promise<any>((resolve, reject) => {
      googleOneTap
        .init()
        .catch(() => undefined)
        .then((GoogleID: GoogleOneTapAccounts | undefined) => {
          if (!GoogleID) {
            return;
          }

          function handleCredentialResponse({ credential }: { credential: string }) {
            resolve({ code: credential });
          }

          GoogleID.initialize({
            ...constants.google.init,
            callback: handleCredentialResponse,
            nonce: nonce(),
          });

          GoogleID.prompt((notification) => {
            if (notification.isDisplayed() && !notification.isSkippedMoment()) {
              Instrumentation.oneTapView();
            } else {
              // Sometimes google considers that user rejected prompt enough times to not show it anymore.
              // This is the only way to find out the reason why is not displayed
              // eslint-disable-next-line no-console
              console.log('[Google One Tap]:', notification.getNotDisplayedReason(), notification.getSkippedReason());

              if (notification.isDismissedMoment()) {
                Instrumentation.oneTapClose({ reason: notification.getDismissedReason() });
              }
            }
          });
        })
        .catch(reject);
    });
  },
};

export default googleOneTap;
