import * as React from 'react';
import { useContext } from 'react';

import type { SearchContextValue } from 'bundles/search-common/providers/searchTypes';

export const SearchContext = React.createContext<SearchContextValue | undefined>(undefined);

export const useSearch = () => {
  const searchContext = useContext(SearchContext);
  if (searchContext === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return searchContext;
};

export default SearchContext;
