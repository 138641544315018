import { useQuery } from '@apollo/client';

import type {
  ArticleEstimatedReadTimeQuery as ArticleEstimatedReadTimeQueryType,
  ArticleEstimatedReadTimeQueryVariables,
} from 'bundles/unified-seo-content-common/queries/__generated__/ArticleEstimatedReadTimeQuery';
import { ArticleEstimatedReadTimeQuery } from 'bundles/unified-seo-content-common/queries/assemblerQueries';
import { getDeferToCSRQueryOptions } from 'bundles/unified-seo-content-common/utils/index';

type EstimatedReadTimeProps = {
  estimatedReadTime?: number;
  estimatedReadTimeLabel?: string;
  loading: boolean;
};

const useEstimatedReadTimeData = ({ slug }: ArticleEstimatedReadTimeQueryVariables): EstimatedReadTimeProps => {
  const { data, loading, error } = useQuery<ArticleEstimatedReadTimeQueryType, ArticleEstimatedReadTimeQueryVariables>(
    ArticleEstimatedReadTimeQuery,
    {
      skip: !slug,
      variables: { slug },
      ...getDeferToCSRQueryOptions(),
    }
  );

  if (error) {
    return {
      estimatedReadTime: undefined,
      estimatedReadTimeLabel: undefined,
      loading,
    };
  }

  const content = data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements[0]?.content;

  return {
    estimatedReadTime: content?.estimatedreadingtime,
    estimatedReadTimeLabel: content?.estimatedreadingtimelabel,
    loading,
  };
};

export default useEstimatedReadTimeData;
