export default {
  s: '%d 초',
  smicro: '%d 초',
  smid: '%d 초',

  ss: '%d 초',
  ssmicro: '%d 초',
  ssmid: '%d 초',

  m: '%d 분',
  mmicro: '%d 분',
  mmid: '%d 분',

  mm: '%d 분',
  mmmicro: '%d 분',
  mmmid: '%d 분',

  h: '%d 시간',
  hmicro: '%d 시간',
  hmid: '%d 시간',

  hh: '%d 시간 ',
  hhmicro: '%d 시간 ',
  hhmid: '%d 시간 ',

  d: '%d 일',
  dmicro: '%d 일',
  dmid: '%d 일',

  dd: '%d 일',
  ddmicro: '%d 일',
  ddmid: '%d 일',
};
