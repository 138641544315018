import * as React from 'react';

import { breakpoints, useMediaQuery } from '@coursera/cds-core';

import PageNavLink from 'bundles/page-header/components/page-navigation/PageNavLink';
import { getCareerAcademyLabel, getCareerAcademyLink } from 'bundles/page/components/shared/utils/careerAcademyUtils';

import _t from 'i18n!nls/page-header';

const PageNavigationL1 = () => {
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));

  return (
    <React.Fragment>
      <PageNavLink label={isSmallScreen ? _t('Degrees') : _t('Online Degrees')} name="online_degrees" href="/degrees" />
      <PageNavLink label={getCareerAcademyLabel()} name="online_career" href={getCareerAcademyLink('globalnav')} />
    </React.Fragment>
  );
};

export default PageNavigationL1;
