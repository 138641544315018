import { useLocalizedStringFormatter } from '@coursera/cds-common';

import i18nMessages from '@core/notifications/i18n';
import type { NotificationSeverity } from '@core/notifications/types';

const useNotificationAriaLabel = (
  severity: NotificationSeverity,
  title = ''
): string | undefined => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const a11yLabelStatus: Record<NonNullable<NotificationSeverity>, string> = {
    information: stringFormatter.format('information', { title }),
    warning: stringFormatter.format('warning', { title }),
    success: stringFormatter.format('success', { title }),
    error: stringFormatter.format('error', { title }),
  };

  return a11yLabelStatus[severity as NonNullable<NotificationSeverity>];
};

export default useNotificationAriaLabel;
