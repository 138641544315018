/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';

import { validIcons } from 'bundles/degrees/utils/constants';
import type { InfoModuleItemFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';

type Props = {
  infoModuleContent: InfoModuleItemFragment;
};

const styles = {
  hightlightsHeader: css`
    margin-bottom: var(--cds-spacing-300);
  `,
  highlightContainer: css`
    display: flex;
    gap: var(--cds-spacing-200);
    margin-bottom: var(--cds-spacing-200);
  `,
};

export const InformationalSectionSubBenefits = ({ infoModuleContent }: Props): React.ReactElement => {
  const { highlightsHeader, highlightsCollection } = infoModuleContent;

  return (
    <div>
      <Typography2 component="h3" variant="subtitleLarge" css={styles.hightlightsHeader}>
        {highlightsHeader}
      </Typography2>
      <Grid container spacing={32}>
        {highlightsCollection?.items?.map((highlight) => {
          const Icon = highlight?.iconVariant && validIcons[highlight?.iconVariant as keyof typeof validIcons];
          return (
            <Grid key={highlight?.iconLabel} item xs={12} md={6} css={styles.highlightContainer}>
              <div>{Icon && <Icon />}</div>
              <div>
                <Typography2 component="h4" variant="subtitleMedium">
                  {highlight?.iconLabel}
                </Typography2>
                <Typography2 component="p" variant="bodyPrimary">
                  {highlight?.description}
                </Typography2>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default InformationalSectionSubBenefits;
