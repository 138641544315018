// TODO think up a better name for this library
// TODO: Convert these into components
import PropTypes from 'prop-types';

import _t from 'i18n!nls/teach-course';

const roundToPrecision = (precision: $TSFixMe) => {
  return (num: $TSFixMe) => {
    if (num === 0) {
      return 0;
    }

    const numDigits = Math.floor(Math.log(num) / Math.log(10));
    const divisor = Math.pow(10, numDigits - precision + 1);

    return Math.round(num / divisor) * divisor;
  };
};

export const Rounders = {
  integer: Math.round,
  percentage: roundToPrecision(2),
  largeNumber(num: $TSFixMe) {
    return num < 1000 ? Rounders.integer(num) : roundToPrecision(2)(num);
  },
};

export const Formatters = {
  integer(val: number) {
    return val.toLocaleString(undefined, { maximumFractionDigits: 0 });
  },

  integerOrTenths(val: number) {
    const maximumFractionDigits = val < 10 ? 1 : 0;

    return val.toLocaleString(undefined, { maximumFractionDigits });
  },

  float(val: number) {
    return val.toLocaleString(undefined, { maximumFractionDigits: 2 });
  },

  percentage(val: number, includeSign = true) {
    const percentage = (val * 100).toLocaleString(undefined, {
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2,
    });

    const suffix = includeSign ? '%' : '';

    return percentage + suffix;
  },

  percentageWithTenths(val: number) {
    const percentage =
      val < 0.01
        ? (val * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })
        : (val * 100).toLocaleString(undefined, { maximumSignificantDigits: 2 });

    return percentage + '%';
  },

  largeNumber(val: number, capitalizeAbbreviation = false) {
    const orderOfMagnitude = Math.floor(Math.log(val) / Math.log(10));
    if (orderOfMagnitude < 3) {
      return String(Rounders.integer(val));
    } else if (orderOfMagnitude < 6) {
      // unclear if the suggested ** operator will be properly pollyfilled for IE
      // eslint-disable-next-line no-restricted-properties
      const numberOfThousands = (val / Math.pow(10, 3)).toLocaleString(undefined, {
        maximumSignificantDigits: 2,
      });
      // the eslint error is going off because this isn't a direct child of a function but since it is the return value of a function called in the request context it should still translate correctly
      return capitalizeAbbreviation
        ? _t('#{numberOfThousands}K', { numberOfThousands })
        : _t('#{numberOfThousands}k', { numberOfThousands });
    } else {
      // eslint-disable-next-line no-restricted-properties
      const numberOfMillions = (val / Math.pow(10, 6)).toLocaleString(undefined, {
        maximumSignificantDigits: 2,
      });
      return capitalizeAbbreviation
        ? _t('#{numberOfMillions}M', { numberOfMillions })
        : _t('#{numberOfMillions}m', { numberOfMillions });
      /* eslint-enable no-restricted-syntax */
    }
    // TODO: ADD BILLIONS?? :D
  },
};

const numberRenders = {
  Rounders,
  Formatters,
  PropTypes: {
    renderableNumber: PropTypes.oneOf([
      'integer', // render a whole number
      'integerOrTenths', // integer, but if it would be one digit then add tenths
      'float',
      'largeNumber',
      'percentage',
      'percentageWithTenths',
    ]),
  },
};

export default numberRenders;
