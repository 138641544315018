import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

const getPageGridContainerCss = css`
  ${breakpoints.up('xs')} {
    padding: 0 var(--cds-spacing-200);
  }

  ${breakpoints.up('md')} {
    padding: 0 var(--cds-spacing-600);
  }
`;

export default getPageGridContainerCss;
