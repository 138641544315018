import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('FullscreenDialogHeader', [
  'container',
  'slot1',
  'closeBtn',
  'slot2',
]);

export const getHeaderCss = () => {
  return css`
    padding: var(--cds-spacing-200) var(--cds-spacing-600);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: var(--cds-spacing-150) var(--cds-spacing-200);

    .${classes.slot1} {
      flex: 1 1 auto;
    }

    .${classes.slot2} {
      flex: 1 1 auto;
    }

    .${classes.closeBtn} {
      flex: 0 0 auto;
    }

    ${breakpoints.down('sm')} {
      padding: var(--cds-spacing-200);

      .${classes.slot1} {
        flex: 1 0 auto;
      }

      .${classes.slot2} {
        order: 3;
        flex: 1 0 100%;
      }

      .${classes.closeBtn} {
        order: 2;
      }
    }
  `;
};
