import { css } from '@emotion/react';

import { classes as inputClasses } from '@core/forms/Input/getInputCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('Search', [
  'base',
  'inputRoot',
  'default',
  'silent',
  'prefix',
  'suffix',
  'actionButtons',
  'searchClearButton',
  'searchClearIcon',
  'searchLoader',
  'clearPlaceholder',
]);

const getSearchCss = css`
  --search-action-button-size: 36px;

  .${classes.inputRoot} {
    min-width: 240px;

    .${inputClasses.input} {
      padding-left: var(--cds-spacing-150);
      padding-right: 0;
      text-overflow: ellipsis;
      box-sizing: content-box;

      /* hide the default cancel since we implement our own,
      only webkit browsers add this for input[type=search] */
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }

  &.${classes.silent} {
    .${classes.inputRoot} {
      background-color: var(--cds-color-neutral-background-primary-weak);

      fieldset {
        border: none;
      }
    }
  }

  .${classes.prefix} {
    padding: 0;
    padding-left: var(--cds-spacing-150);
  }

  .${classes.suffix} {
    gap: var(--cds-spacing-50);
    padding-left: 0;
    margin-left: var(--cds-spacing-50);
    padding-right: 6px;
  }

  .${classes.actionButtons} {
    display: flex;
    gap: var(--cds-spacing-50);
  }

  .${classes.clearPlaceholder} {
    width: var(--search-action-button-size);
    height: var(--search-action-button-size);
  }
`;

export default getSearchCss;
