import * as React from 'react';

import { compose } from 'recompose';

import { Grid } from '@coursera/cds-core';

import TopProductsList from 'bundles/premium-hub/components/shared/TopProductsList';
import { ProductType } from 'bundles/premium-hub/constants';
import type { CertificateProps } from 'bundles/premium-hub/types';
import { withCertificatesContentfulIntegration } from 'bundles/premium-hub/utils/contentfulIntegration';

type InnerProps = {
  certificatesList?: Array<CertificateProps>;
};

type OuterProps = {
  allCertificateData?: Array<CertificateProps & { description?: string; durationDescription?: string }>;
} & InnerProps;

const sectionName = 'top_certificates_list';

const TopCertificatesList: React.FunctionComponent<OuterProps> = ({ allCertificateData }) => {
  if (!allCertificateData || !allCertificateData.length) {
    return null;
  }

  return (
    <Grid>
      <TopProductsList
        productsList={allCertificateData}
        productType={ProductType.Certificates}
        sectionName={sectionName}
      />
    </Grid>
  );
};

export const BaseComp = TopCertificatesList;

export default compose<InnerProps, OuterProps>(withCertificatesContentfulIntegration)(TopCertificatesList);
