import { track } from '@coursera/event-pulse/sdk';

import {
  CERTIFICATE_SECTION_TYPE,
  DEGREES_SECTION_TYPE,
  GET_STARTED_SECTION_TYPE,
  OTHER_SECTION_TYPE,
  POPULAR_SKILLS_SECTION_TYPE,
} from 'bundles/megamenu/constants/constants';
import type { MegaMenuContent } from 'bundles/megamenu/types/MegaMenuContent';
import { MEGAMENU_CONTENT_TYPE } from 'bundles/megamenu/types/MegaMenuContent';

import _t from 'i18n!nls/megamenu';

const ALL_CERTS_SERP_LINK =
  '/search?productTypeDescription=Graduate%20Certificates&productTypeDescription=MasterTrack%C2%AE%20Certificates&productTypeDescription=Postgraduate%20Diploma&productTypeDescription=Professional%20Certificates&productTypeDescription=University%20Certificates&sortBy=BEST_MATCH';

export const simplifiedMegaMenuContent = (): MegaMenuContent[] => {
  return [
    {
      type: MEGAMENU_CONTENT_TYPE.section,
      sectionType: OTHER_SECTION_TYPE,
      href: '/career-academy/?trk_ref=globalnav',
      label: _t('Launch your new career'),
      name: 'career-academy',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/career-academy/?trk_ref=globalnav',
          megamenuItemName: 'find-your-new-career',
          megamenuItemType: 'other',
          megamenuSection: GET_STARTED_SECTION_TYPE,
        });
      },
      footer: {
        type: MEGAMENU_CONTENT_TYPE.link,
        href: '/career-academy/?trk_ref=globalnav',
        label: _t('Browse all careers'),
        name: 'browse-all-careers',
        onButtonClick: () => {
          track('click_megamenu_item', {
            megamenuItemLink: '/career-academy/?trk_ref=globalnav',
            megamenuItemName: 'browse-all-careers',
            megamenuItemType: 'view_all_link',
            megamenuSection: GET_STARTED_SECTION_TYPE,
          });
        },
      },
    },
    {
      type: MEGAMENU_CONTENT_TYPE.section,
      sectionType: POPULAR_SKILLS_SECTION_TYPE,
      href: '/search?sortBy=BEST_MATCH',
      label: _t('Browse by popular skills'),
      name: 'browse-by-popular-skills',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/search?sortBy=BEST_MATCH',
          megamenuItemName: 'browse-by-popular-skills',
          megamenuItemType: 'other',
          megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
        });
      },
      footer: {
        type: MEGAMENU_CONTENT_TYPE.link,
        href: '/search?sortBy=BEST_MATCH',
        label: _t('Browse all skills'),
        name: 'browse-all-skills',
        onButtonClick: () => {
          track('click_megamenu_item', {
            megamenuItemLink: '/search?sortBy=BEST_MATCH',
            megamenuItemName: 'browse-all-skills',
            megamenuItemType: 'view_all_link',
            megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
          });
        },
      },
      children: [
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=artificial%20intelligence',
          label: _t('Artificial Intelligence'),
          name: 'artificial-intelligence',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=artificial%20intelligence',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=microsoft%20excel',
          label: _t('Excel'),
          name: 'excel',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=microsoft%20excel',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=digital%20marketing',
          label: _t('Digital Marketing'),
          name: 'digital-marketing',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=digital%20marketing',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=cybersecurity',
          label: _t('Cybersecurity'),
          name: 'cybersecurity',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=cybersecurity',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=python',
          label: _t('Python'),
          name: 'python',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=python',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=data%20analytics',
          label: _t('Data Analytics'),
          name: 'data-analytics',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=data%20analytics',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=english%20speaking',
          label: _t('English Speaking'),
          name: 'english-speaking',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=english%20speaking',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=data%20science&topic=data%20science',
          label: _t('Data Science'),
          name: 'data-science',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=data%20science&topic=data%20science',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=project%20management',
          label: _t('Project Management'),
          name: 'project-management',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=project%20management',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=graphic%20design',
          label: _t('Graphic Design'),
          name: 'graphic-design',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=graphic%20design',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=web%20development',
          label: _t('Web Development'),
          name: 'web-development',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=web%20development',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/courses?query=generative%20ai',
          label: _t('Gen AI'),
          name: 'generative-ai',
          onButtonClick: () => {
            track('click_megamenu_item', {
              megamenuItemLink: '/courses?query=generative%20ai',
              megamenuItemName: 'browse-all-popular-skills',
              megamenuItemType: 'other',
              megamenuSection: POPULAR_SKILLS_SECTION_TYPE,
            });
          },
        },
      ],
    },
    {
      type: MEGAMENU_CONTENT_TYPE.section,
      sectionType: CERTIFICATE_SECTION_TYPE,
      href: ALL_CERTS_SERP_LINK,
      label: _t('Earn a Certificate'),
      name: 'earn-a-certificate',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: ALL_CERTS_SERP_LINK,
          megamenuItemName: 'earn-a-certificate',
          megamenuItemType: 'other',
          megamenuSection: CERTIFICATE_SECTION_TYPE,
        });
      },
      footer: {
        type: MEGAMENU_CONTENT_TYPE.link,
        href: ALL_CERTS_SERP_LINK,
        label: _t('Browse all Certificates'),
        name: 'browse-all-certificates',
        onButtonClick: () => {
          track('click_megamenu_item', {
            megamenuItemLink: ALL_CERTS_SERP_LINK,
            megamenuItemName: 'earn-a-certificate',
            megamenuItemType: 'view_all_link',
            megamenuSection: CERTIFICATE_SECTION_TYPE,
          });
        },
      },
      children: [
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/search?productTypeDescription=Graduate%20Certificates&productTypeDescription=Postgraduate%20Diploma&productTypeDescription=Professional%20Certificates&productTypeDescription=University%20Certificates&productTypeDescription=MasterTrack%C2%AE%20Certificates&topic=Data%20Science&sortBy=BEST_MATCH',
          label: _t('Data Science Certificates'),
          name: 'data-science-certificates',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/search?productTypeDescription=Graduate%20Certificates&productTypeDescription=MasterTrack%C2%AE%20Certificates&productTypeDescription=Postgraduate%20Diploma&productTypeDescription=Professional%20Certificates&productTypeDescription=University%20Certificates&topic=Business&sortBy=BEST_MATCH',
          label: _t('Business Certificates'),
          name: 'business-certificates',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/search?productTypeDescription=Graduate%20Certificates&productTypeDescription=MasterTrack%C2%AE%20Certificates&productTypeDescription=Postgraduate%20Diploma&productTypeDescription=Professional%20Certificates&productTypeDescription=University%20Certificates&topic=Computer%20Science&sortBy=BEST_MATCH',
          label: _t('Computer Science Certificates'),
          name: 'computer-science-certificates',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/search?productTypeDescription=Graduate%20Certificates&productTypeDescription=MasterTrack%C2%AE%20Certificates&productTypeDescription=Postgraduate%20Diploma&productTypeDescription=Professional%20Certificates&productTypeDescription=University%20Certificates&topic=Information%20Technology&sortBy=BEST_MATCH',
          label: _t('IT Certificates'),
          name: 'it-certificates',
        },
      ],
    },
    {
      type: MEGAMENU_CONTENT_TYPE.section,
      sectionType: DEGREES_SECTION_TYPE,
      href: '/degrees',
      label: _t('Earn a Degree'),
      name: 'earn-a-degree',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/degrees',
          megamenuItemName: 'earn-a-degree',
          megamenuItemType: 'other',
          megamenuSection: DEGREES_SECTION_TYPE,
        });
      },
      footer: {
        type: MEGAMENU_CONTENT_TYPE.link,
        href: '/degrees',
        label: _t('Browse all degrees'),
        name: 'browse-free-courses',
        onButtonClick: () => {
          track('click_megamenu_item', {
            megamenuItemLink: '/degrees',
            megamenuItemName: 'earn-a-degree',
            megamenuItemType: 'view_all_link',
            megamenuSection: DEGREES_SECTION_TYPE,
          });
        },
      },
      children: [
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/degrees/bachelors',
          label: _t('Bachelor’s Degrees'),
          name: 'bachelors-degrees',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/degrees/masters',
          label: _t('Master’s Degrees'),
          name: 'masters-degrees',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/degrees/postgraduate-studies',
          label: _t('Postgraduate Programs'),
          name: 'postgraduate-programs',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/degrees/business',
          label: _t('Business and MBA Degrees'),
          name: 'business-and-mba-degrees',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/degrees/computer-science',
          label: _t('Computer Science Degrees'),
          name: 'computer-science-degrees',
        },
        {
          type: MEGAMENU_CONTENT_TYPE.link,
          href: '/degrees/data-science',
          label: _t('Data Science Degrees'),
          name: 'data-science-degrees',
        },
      ],
    },
    {
      type: MEGAMENU_CONTENT_TYPE.section,
      sectionType: GET_STARTED_SECTION_TYPE,
      href: '/courses?query=free',
      label: _t('Not sure where to begin?'),
      name: 'not-sure-where-to-begin',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/courses?query=free',
          megamenuItemName: 'free-course',
          megamenuItemType: 'other',
          megamenuSection: GET_STARTED_SECTION_TYPE,
        });
      },
      footer: {
        type: MEGAMENU_CONTENT_TYPE.link,
        href: '/courses?query=free',
        label: _t('Browse free courses'),
        name: 'browse-free-courses',
        onButtonClick: () => {
          track('click_megamenu_item', {
            megamenuItemLink: '/courses?query=free',
            megamenuItemName: 'free-course',
            megamenuItemType: 'view_all_link',
            megamenuSection: 'goals',
          });
        },
      },
    },
  ];
};
