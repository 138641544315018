import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';
import type { Theme } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('AccordionHeader', [
  'button',
  'chevron',
  'expanded',
  'content',
  'labelGroup',
  'freeContent',
]);

export const getAccordionHeaderCss = (theme: Theme) => {
  return css`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    z-index: 0;

    .${classes.chevron} {
      flex-shrink: 0;
      color: inherit;
      padding: 0;
      margin: 2px 12px 0 0;
      transition-property: transform;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
      transform: ${theme.direction === 'rtl'
        ? 'rotate(180deg)'
        : 'rotate(0deg)'};

      &.${classes.expanded} {
        transform: rotate(90deg);
      }
    }

    .${classes.button} {
      text-align: inherit;
      padding: var(--cds-spacing-200);
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      align-items: flex-start;
      flex-grow: 1;
      z-index: 1;

      &:hover {
        background-color: var(
          --cds-color-interactive-background-primary-hover-weak
        );
      }
    }

    .${classes.content} {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      ${breakpoints.down('xs')} {
        flex-direction: column;
      }
    }

    .${classes.labelGroup} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 1;
    }

    .${classes.freeContent} {
      padding-left: var(--cds-spacing-600);
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-end;
      flex-grow: 1;

      ${breakpoints.down('xs')} {
        justify-content: flex-start;
        padding-top: var(--cds-spacing-200);
        padding-left: 0;
      }
    }
  `;
};
