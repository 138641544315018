/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';
import { useMemo } from 'react';

import { useNavigate } from 'js/lib/useRouter';
import user from 'js/lib/user';

import { Button, Grid } from '@coursera/cds-core';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import TrackedButton from 'bundles/page/components/TrackedButton';
import DegreeProductCard from 'bundles/premium-hub/components/degrees/DegreeProductCard';
import ShowMoreSection from 'bundles/premium-hub/components/shared/ShowMoreSection';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import { HubPages } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { PremiumProductWithMetadata } from 'bundles/premium-hub/types';
import { getDegreeProductCardProps } from 'bundles/premium-hub/utils';

import _t from 'i18n!nls/premium-hub';

type Props = {
  degreeListWithMetadata?: PremiumProductWithMetadata[];
  currentPageType: PremiumHubPageType;
  slug: string;
};

const sectionName = 'top_degrees_list';

export const DegreeCarousel = ({ degreeListWithMetadata, currentPageType, slug }: Props) => {
  const isUserLoggedIn = user.isAuthenticatedUser();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const navigate = useNavigate();

  const degreeCarouselRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName,
      index: sectionIndex,
    },
  });

  const header = useMemo(() => {
    if (currentPageType === HubPages.LandingPage && slug === 'pathways') {
      return _t('Discover degrees with flexible learning pathways');
    }
    return _t('Degrees recommendations for you');
  }, [currentPageType, slug]);

  const description = useMemo(() => {
    if (currentPageType === HubPages.LandingPage && slug === 'pathways') {
      return _t(
        'With these programs, you can build valuable skills, earn career credentials, and make progress toward a degree before you even enroll.'
      );
    }
    if (isUserLoggedIn) {
      return _t('Based on your profile and learning activity, these degree programs may interest you.');
    }
    return undefined;
  }, [currentPageType, isUserLoggedIn, slug]);

  const overriddenButton = (
    <Button
      component={TrackedButton}
      variant="secondary"
      onClick={() => navigate('/degrees/landing/pathways/browse')}
      trackingName="premium_hub_pathway_explore"
      withVisibilityTracking={false}
      requireFullyVisible={false}
    >
      {_t('See all degrees')}
    </Button>
  );

  return (
    <ShowMoreSection
      header={header}
      description={description}
      translatedEntitiesType={_t('Degrees')}
      items={degreeListWithMetadata?.map((degree, index) => {
        return (
          <Grid
            item
            md={3}
            sm={6}
            xs={12}
            key={degree.slug}
            css={css`
              display: flex;
            `}
            ref={degreeCarouselRef}
          >
            <DegreeProductCard
              degree={getDegreeProductCardProps(degree)}
              entityIndex={index}
              sectionName={sectionName}
            />
          </Grid>
        );
      })}
      carouselId="premium_hub_degree_recommendation_carousel"
      overriddenButton={overriddenButton}
    />
  );
};

export default React.memo(DegreeCarousel);
