import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';

export const getShouldShowProductCardDensitySkills = () =>
  GrowthDiscoveryExperiments.preview('cSerpProductCardDensitySkills');
export const getShouldShowProductCardDensitySkillsWithImpression = () =>
  GrowthDiscoveryExperiments.get('cSerpProductCardDensitySkills');
export const getShouldShowProductCardDensityRating = () =>
  GrowthDiscoveryExperiments.preview('cSerpProductCardDensityRating');
export const getShouldShowProductCardDensityRatingWithImpression = () =>
  GrowthDiscoveryExperiments.get('cSerpProductCardDensityRating');
export const getShowNewAutocomplete = () =>
  GrowthDiscoveryExperiments.get('showNewAutocomplete') ||
  GrowthDiscoveryExperiments.get('showNewAutocompleteDogfooding');
export const getShowNewMegamenu = () => GrowthDiscoveryExperiments.get('showNewMegamenu');

// filter experiment utils
export const impressIntoFilterExperiment = () => GrowthDiscoveryExperiments.get('serpFilterVariant');
export const isInControlFilterVariant = () => GrowthDiscoveryExperiments.preview('serpFilterVariant') === 0;
export const isInFilterVariant1 = () => GrowthDiscoveryExperiments.preview('serpFilterVariant') === 1;
export const isInFilterVariant2 = () => GrowthDiscoveryExperiments.preview('serpFilterVariant') === 2;
export const isInFilterTestVariant = () => GrowthDiscoveryExperiments.preview('serpFilterVariant') >= 1;
// end filter variant utils

export const getShowNewSERPSuggestedSearch = () => GrowthDiscoveryExperiments.get('showNewSERPSuggestedSearch');

// Browse in SERP experiment utils
export const getShouldShowBrowseInSERP = () => GrowthDiscoveryExperiments.preview('showBrowseInSerp');
export const getShouldShowBrowseInSERPWithImpression = () => GrowthDiscoveryExperiments.get('showBrowseInSerp');

/**
 * Derived from a looker list and filtered and mapped via a one-off script.
 * One-off script: https://gist.github.com/ntobolski-coursera/b0c9a61634662d33cbd38dc7c2113edc
 */
export const uniquePathwaySlugs = new Set([
  'a-technical-leaders-qualities-and-effectiveness',
  'a-theoretical-origin-of-ethics-in-business-and-tech-industry',
  'accountability-employee-engagement',
  'accounting-analysis-2-equity',
  'accounting-analysis-2-liabilities',
  'accounting-data-analytics',
  'accounting-data-analytics-python',
  'accounting-for-ma-1',
  'accounting-for-ma-2',
  'accounting-for-managers',
  'accounting-information-system',
  'active-optical-devices',
  'advanced-financial-reporting',
  'advanced-topics-future-trends-database-technologies',
  'ai-engineer',
  'algorithms-searching-sorting-indexing',
  'analisis-de-negocios-con-estados-financieros',
  'analitica-procesos-optimizacion-desde-datos',
  'analytics',
  'analytics-information',
  'anova-and-experimental-design',
  'applied-artifical-intelligence-ibm-watson-ai',
  'applied-data-science',
  'applying-data-analytics-accounting',
  'applying-data-analytics-business-in-marketing',
  'applying-systems-engineering-to-the-design-process',
  'approximation-methods',
  'asset-measurement-disclosure',
  'auditing-part1-conceptual-foundations',
  'auditing-part2-the-practice',
  'averagedswitchmodelingandsimulation',
  'avoiding-ethical-pitfalls-in-the-tech-industry',
  'ball-state-university-data-visualization-open',
  'ball-state-university-introduction-to-programming-open-content',
  'banking-and-financial-institutions',
  'basic-robotic-behaviors-and-odometry',
  'battery-management-systems',
  'battery-pack-balancing-power-estimation',
  'battery-state-of-charge',
  'battery-state-of-health',
  'beyond-financials-insights-analysis-valuations',
  'beyond-the-sustainable-development-goals-addressing-sustainability-and-development',
  'boulder-data-structures-algorithms',
  'building-and-pitching-marketing-campaigns-in-tech-industries',
  'business-analytics-r',
  'business-data',
  'business-statistics',
  'business-value-project-management',
  'capstone-fpga-design',
  'central-banks-monetary-policies',
  'challenges-of-leading-individuals-in-the-tech-industry',
  'challenges-of-leading-technical-teams',
  'closed-loop-photovoltaic-power-electronics-laboratory',
  'communication-as-a-technical-leader',
  'communitypurpose',
  'computing-ethics-and-society-foundations',
  'converter-circuits',
  'converter-control',
  'corporate-commercial-law-part1',
  'corporate-commercial-law-part2',
  'corporate-finance-measure-success',
  'corporate-finance-two',
  'corporate-strategy',
  'cplusplus-crypto-i',
  'cplusplus-crypto-ii',
  'cplusplus-crypto-iii',
  'cplusplus-crypto-iv',
  'cplusplus-crypto-v',
  'creating-a-technology-startup-company',
  'creativity-toolkit-1',
  'creativity-toolkit-2',
  'current-control',
  'current-modecontrol',
  'cybersecurity-for-data-science',
  'data-analysis-visualization-foundations',
  'data-driven-decision-making',
  'data-mining-foundations-practice',
  'data-mining-methods',
  'data-mining-pipeline',
  'data-mining-theory-practice-project',
  'data-science-as-a-field',
  'data-science-fundamentals-python-sql',
  'data-science-methods-for-quality-improvement',
  'databases-for-data-scientists',
  'deep-learning',
  'deep-learning-computer-vision',
  'defining-describing-and-visualizing-data',
  'design-high-performance-optical-systems',
  'designing-organization',
  'developing-industrial-iot',
  'digital-analytics',
  'digital-marketing',
  'digital-marketing-revolution',
  'digital-media-and-strategic-planning-in-technology-markets',
  'diode-pn-junction-metal-semiconductor-contact',
  'displays',
  'dynamic-programming-greedy-algorithms',
  'effective-communication-capstone-project-msds',
  'effective-communication-writing-design-and-presentation-msds',
  'electric-vehicle-sensors',
  'electronic-converters',
  'embedding-sensors-motors',
  'empathy-data-risk',
  'engineering-genetic-circuits-modeling-and-analysis',
  'entrepreneurship-1',
  'entrepreneurship-2',
  'equivalent-circuit-cell-model-simulation',
  'ethical-decision-making-for-success-in-the-tech-industry',
  'ethical-issues-computing-applications',
  'ethical-issues-data-science',
  'ethical-issues-in-ai-and-professional-ethics',
  'ethics',
  'exploratory-data-analysis-visualisation',
  'facebook-social-media-marketing',
  'federal-taxation-business',
  'federal-taxation-individuals',
  'finance-for-technical-managers',
  'financial-accounting-advanced',
  'financial-accounting-basics',
  'financial-forecasting-and-reporting',
  'financial-management',
  'financial-reporting',
  'financial-statement-ratio-analysis-accountants',
  'financial-statements-sec-filings-ratio-analysis',
  'firm-level-economics',
  'firm-level-economics-markets',
  'first-order-optical-system-design',
  'forecasting-financial-statements-business-valuation-accountants',
  'forming-funding-launching-a-technology-startup-company',
  'foundations-quantum-mechanics',
  'fpga-design',
  'fpga-hardware-description-languages',
  'fpga-softcore-proccessors-ip',
  'fractal-data-science',
  'fundamentals-of-data-visualization',
  'generalized-linear-models-and-nonparametric-regression',
  'getting-started-with-technology-startups',
  'git-espanol',
  'global-challenges-business',
  'global-impact-cross-cultural-management',
  'global-impact-multiculturalism',
  'global-marketing-building-iconic-brands',
  'global-marketing-cultural-frameworks',
  'global-strategy',
  'global-strategy-two',
  'google-advanced-data-analytics',
  'google-business-intelligence',
  'google-cloud-security',
  'google-cybersecurity',
  'google-data-analytics',
  'google-digital-marketing-ecommerce',
  'google-it-support',
  'google-project-management',
  'google-ux-design',
  'high-voltage-schottky-and-p-n-diodes',
  'ibm-applied-devops-engineering',
  'ibm-cybersecurity-analyst',
  'ibm-data-analyst',
  'ibm-data-analyst-r-excel',
  'ibm-data-engineer',
  'ibm-data-science',
  'ibm-full-stack-cloud-developer',
  'ibm-machine-learning',
  'illinois-tech-a-comprehensive-excel-masterclass-unleashing-business-potential',
  'illinois-tech-business-economics-and-game-theory-for-decision-making',
  'illinois-tech-cloud-computing-technologies-mit',
  'illinois-tech-competitive-strategy',
  'illinois-tech-computer-and-peripheral-hardware',
  'illinois-tech-computer-networking',
  'illinois-tech-data-preparation-and-analysis',
  'illinois-tech-financial-statement-application',
  'illinois-tech-fundamentals-of-web-development',
  'illinois-tech-innovation-and-international-strategy',
  'illinois-tech-introduction-to-data-networks-and-the-internet-bit',
  'illinois-tech-introduction-to-data-networks-and-the-internet-mit',
  'illinois-tech-introduction-to-open-source-application-development',
  'illinois-tech-introduction-to-open-source-operating-systems-bit',
  'illinois-tech-introduction-to-open-source-operating-systems-mit',
  'illinois-tech-introduction-to-relational-databases',
  'illinois-tech-leadership-organization-design',
  'illinois-tech-linear-regression',
  'illinois-tech-linux-os',
  'illinois-tech-managerial-economics-buyer-and-seller-behavior',
  'illinois-tech-marketing-strategy',
  'illinois-tech-mastering-excel-essentials-to-enhance-business-value',
  'illinois-tech-microsoft-windows-os',
  'illinois-tech-mobile-computing-and-cloud',
  'illinois-tech-model-diagnostics-and-remedial-measures',
  'illinois-tech-open-source-programming-mit',
  'illinois-tech-operating-system-virtualization-mit',
  'illinois-tech-operations-process-management',
  'illinois-tech-operations-public-engagement-for-scientists',
  'illinois-tech-os-security',
  'illinois-tech-programming-and-application-foundations',
  'illinois-tech-relational-database-design',
  'illinois-tech-relational-database-implementation-and-applications',
  'illinois-tech-statistical-learning',
  'illinois-tech-variable-selection-model-validation-and-nonlinear-regression',
  'industrial-iot-markets-security',
  'industrial-iot-project-planning-machine-learning',
  'infonomics-1',
  'infonomics-2',
  'innovation-creativity-entrepreneurship',
  'inputfilterdesign',
  'intro-accounting-data-analytics-visual',
  'intro-business-analytics',
  'intro-fpga-design-embedded-systems',
  'introduccion-a-la-inteligencia-artificial-contemporanea',
  'introduccion-a-la-valoracion-financiera',
  'introduccion-a-la-visualizacion-de-datos',
  'introduccion-al-deep-learning-contemporaneo',
  'introduccion-desarrollo-web',
  'introduction-data-science',
  'introduction-high-performance-computing',
  'introduction-robotics-webots',
  'introduction-to-deep-learning-boulder',
  'introduction-to-finance-the-basics',
  'introduction-to-finance-the-role-of-financial-markets',
  'introduction-to-machine-learning-supervised-learning',
  'introduction-to-power-semiconductor-switches',
  'investment-banking-financial-analysis-valuation',
  'investment-banking-mergers-acquisitions-ipos',
  'investments-applications',
  'investments-fundamentals',
  'leadership-style-high-performance-team',
  'leading-oneself-with-personal-excellence',
  'leading-oneself-with-purpose-and-meaning',
  'leading-oneself-with-self-knowledge',
  'leading-teams-building-effective-team-cultures',
  'leading-teams-developing-as-a-leader',
  'leds-semiconductor-lasers',
  'linear-programming-and-approximation-algorithms',
  'linux-kernel-programming-yocto-project',
  'linux-system-programming-introduction-to-buildroot',
  'm2m-iot-interface-design-embedded-systems',
  'machine-learnin-theory-and-hands-on-practice-with-pythong-cu',
  'machine-learning-accounting-python',
  'machine-learning-algorithms-r-business-analytics',
  'magnetics-for-power-electronic-converters-v2',
  'managerial-accounting-business-decisions',
  'managerial-economics-business-analysis',
  'managing-describing-analyzing-data',
  'managing-organization',
  'managing-the-new-product-development-process',
  'market-research-and-analysis-for-tech-industries',
  'marketing-analytics',
  'marketing-channels',
  'marketing-digital',
  'marketing-management',
  'marketing-management-two',
  'marketing-plan',
  'measurement-systems-analysis',
  'meem-agile-project-management',
  'meem-project-management',
  'mejores-decisiones-con-business-analytics',
  'mergers-acquisitions-ma-deal',
  'mergers-acquisitions-valuation-pricing',
  'meta-back-end-developer',
  'meta-front-end-developer',
  'microsoft-power-bi-data-analyst',
  'modeling-and-control-of-power-electronics',
  'modeling-and-control-of-single-phase-rectifiers-and-inverters',
  'modeling-debugging-embedded-systems',
  'modern-regression-analysis-in-r',
  'motors-circuits-design',
  'multistate-taxation',
  'nanophotonics-detectors',
  'need-for-systems-engineering',
  'network-analysis-for-marketing-analytics',
  'network-systems-foundations',
  'object-oriented-programming-s12n',
  'open-loop-photovoltaic-power-electronics-laboratory',
  'operations-management-organization-and-analysis',
  'operations-management-quality-and-supply-chain',
  'optical-efficiency-and-resolution',
  'optical-engineering',
  'our-world-and-how-to-accept-it',
  'perspectivas-en-salud-publica-ampliando-la-vision-en-salud',
  'power-electronics',
  'power-electronics-capstone',
  'pressure-force-motion-humidity-sensors',
  'principles-of-leadership-leading-oneself',
  'principles-of-leadership-leading-technical-teams',
  'probability-theory-foundation-for-data-science',
  'product-cost-and-investment-cash-flow-analysis',
  'product-innovation-management',
  'programming-for-data-science',
  'programming-introduction',
  'project-execution-control',
  'project-initiation-planning',
  'project-management-foundations-initiation',
  'project-planning-execution',
  'project-valuation-and-the-capital-budgeting-process',
  'python',
  'python-para-ciencia-de-datos',
  'quantum-mechanics-for-engineers',
  'rapid-prototyping-embedded-interface',
  'real-time-embedded-systems-concepts-practices',
  'real-time-embedded-theory-analysis',
  'real-time-mission-critical-systems-design',
  'real-time-project-embedded-systems',
  'real-world-cloud-pm',
  'real-world-product-management',
  'regression-and-classification',
  'relational-database-design',
  'risk-management-empathy-data',
  'robotic-mapping-trajectory-generation',
  'robotic-path-planning-task-execution',
  'sales-development-representative',
  'salesforce-sales-operations',
  'sas-advanced-programmer',
  'sas-programming',
  'semiconductor-devices',
  'semiconductor-physics',
  'sensor-manufacturing-process-control',
  'sensors-circuit-interface',
  'sistemas-y-modelos-de-organizacion-de-la-atencion-en-salud',
  'social-work-practice-advocating-social-justice-and-change',
  'software-architecture-big-data',
  'software-architecture-for-big-data-applications',
  'software-architecture-for-big-data-fundamentals',
  'software-architecture-patterns-for-big-data',
  'software-engineering-fundamentals',
  'speaking-to-a-technical-group',
  'stability-and-capability-in-quality-improvement',
  'statistical-inference-and-hypothesis-testing-in-data-science-applications',
  'statistical-inference-for-data-science-applications',
  'statistical-inference-for-estimation-in-data-science',
  'statistical-modeling-for-data-science-applications',
  'strategic-innovation-building-and-sustaining-innovative-organizations',
  'strategic-innovation-innovation-at-the-frontier',
  'strategic-leadership',
  'strategic-product-development',
  'strategy-business',
  'subsistence-marketplaces-1',
  'supervised-text-classification-for-marketing-analytics',
  'sustainable-business-enterprises',
  'systems-engineering-and-program-management',
  'taxation-business-entities-part-1',
  'taxation-business-entities-part-2',
  'technical-managerial-written-skills',
  'techniques-of-design-oriented-analysis',
  'text-marketing-analytics',
  'the-neuroscience-of-leading-high-performance-teams',
  'the-neuroscience-of-leading-transformational-organizations',
  'the-neuroscience-of-personal-excellence',
  'the-structured-query-language-sql',
  'their-world-and-how-you-define-it',
  'theory-of-angular-momentum',
  'tools-exploratory-data-analysis-business',
  'transistor-field-effect-transistor-bipolar-junction-transistor',
  'trees-graphs-basics',
  'united-states-federal-taxation',
  'unsupervised-algorithms-in-machine-learning',
  'unsupervised-text-classification-for-marketing-analytics',
  'us-international-tax',
  'ux-interface-design-embedded-systems',
  'value-chain-management',
  'value-creation-building-enduring-relationships',
  'vital-skills-for-data-science',
  'your-world-and-what-shapes-it',
]);

/**
 * Determine whether or not a given slug is part of "pathway" content
 */
export const isPathwaySlug = (slug: string) => uniquePathwaySlugs.has(slug);
