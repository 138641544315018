import React from 'react';

import useForkRef from '@material-ui/core/utils/useForkRef';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import type { BaseComponentProps } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import ButtonBase from '@core/ButtonBase';
import {
  useItemIndexInCollection,
  useItemsCollection,
  useRegisterItemInCollection,
} from '@core/utils';
import VisuallyHidden from '@core/VisuallyHidden';

import getChipCss, { classes } from './getChipCss';
import messages from './i18n';

export type Props = BaseComponentProps<'button'> & {
  /**
   * Which type of Chip to render. Defaults to variant from parent ChipGroup; select if standalone.
   * @default select
   */
  variant?: 'select' | 'delete';

  /**
   * Chip label to display.
   */
  children: React.ReactText;

  /**
   * Render a filled chip if true (ignored by delete variant).
   */
  selected?: boolean;

  /**
   * Invoked when chip is clicked.
   */
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

/**
 * Chips are compact, interactive components that enable users to modify their content experience. Users can make single or multiple chip selections to filter search results, personalize their course recommendations or convert information into a deletable component.
 *
 * See [Props](__storybookUrl__/components-data-display-chip--default#props)
 */
const Chip = React.forwardRef<HTMLButtonElement, Props>(function Chip(
  props,
  ref
) {
  const stringFormatter = useLocalizedStringFormatter(messages);

  const {
    children,
    selected = false,
    variant: chipVariant,
    id: idFromProps,
    ...buttonProps
  } = props;

  const { id, ref: groupItemRef, commonProps } = useRegisterItemInCollection<
    HTMLButtonElement,
    Pick<Props, 'variant' | 'role'>,
    { id?: string; selected?: boolean }
  >(
    {
      id: idFromProps,
      selected,
    },
    [idFromProps, selected]
  );

  const variant = commonProps?.variant || chipVariant || 'select';
  const role = commonProps?.role || props.role;

  const collection = useItemsCollection<HTMLButtonElement>();
  const indexInChipsGroup = useItemIndexInCollection(id);

  let tabIndex: number | undefined;
  if (collection && role === 'radio') {
    const groupHasSelectedChips = collection.items.some(
      (item) => collection.props[item.id].selected
    );

    if (groupHasSelectedChips) {
      tabIndex = selected ? 0 : -1;
    } else {
      tabIndex = indexInChipsGroup === 0 ? 0 : -1;
    }
  }

  return (
    <ButtonBase
      ref={useForkRef(ref, groupItemRef)}
      aria-checked={variant === 'select' ? selected : undefined}
      classes={{
        root: clsx(classes.root, {
          [classes.deletable]: variant === 'delete',
          [classes.selected]: variant === 'select' && selected,
        }),
      }}
      css={getChipCss}
      tabIndex={tabIndex}
      {...buttonProps}
      id={id}
      role={role}
      onClick={(event) => {
        if (collection && variant === 'delete' && indexInChipsGroup > 0) {
          collection.items[indexInChipsGroup - 1]?.element.focus();
        }

        props.onClick?.(event);
      }}
    >
      {children}
      {variant === 'delete' && (
        <>
          <VisuallyHidden>
            {','}
            {stringFormatter.format('Delete')}
          </VisuallyHidden>
          <div className={classes.deleteIcon}>
            <CloseIcon size="small" />
          </div>
        </>
      )}
    </ButtonBase>
  );
});

export default Chip;
