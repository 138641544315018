/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { memo, useState } from 'react';

import { Button } from '@coursera/cds-core';

import CourseraPlusSearchFilter from 'bundles/search-common/components/filters/CourseraPlusSearchFilter';
import SearchFilterItem from 'bundles/search-common/components/filters/SearchFilterItem';
import type { EventingData } from 'bundles/search-common/components/filters/SearchFilterItem';
import { useSortedItems } from 'bundles/search-common/components/filters/useSortedItems';
import { IS_PART_OF_COURSERA_PLUS, NUMBER_OF_FILTER_ITEMS_TO_SHOW } from 'bundles/search-common/constants/filter';
import type { SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
};

type Props = {
  attribute: string;
  filterName: string;
  showCPlusSupportText?: boolean;
  items?: SearchFilterOption[];
  eventingData: EventingData;
  addFilters: (filters: string) => void;
  removeFilters: (filters: string) => void;
};

const SearchFilterMobile = ({
  items = [],
  filterName,
  attribute,
  addFilters,
  removeFilters,
  eventingData,
  showCPlusSupportText,
}: Props) => {
  const [numOfItemsToShow, setNumOfItemToShow] = useState(NUMBER_OF_FILTER_ITEMS_TO_SHOW);
  const sortedItems = useSortedItems(items, attribute, { sortByCount: true });

  if (sortedItems.length === 0) {
    return null;
  }

  if (attribute === IS_PART_OF_COURSERA_PLUS) {
    return (
      <CourseraPlusSearchFilter
        name={filterName}
        items={items}
        addFilters={addFilters}
        removeFilters={removeFilters}
        eventingData={eventingData}
        showCPlusSupportText={showCPlusSupportText}
      />
    );
  }

  let itemsToShow = sortedItems;
  if (sortedItems.length > NUMBER_OF_FILTER_ITEMS_TO_SHOW) {
    itemsToShow = sortedItems.slice(0, numOfItemsToShow);
  }

  return (
    <div css={styles.container}>
      {itemsToShow.map((item) => (
        <SearchFilterItem
          key={item.label}
          {...item}
          value={`${attribute}:${item.value}`}
          filterCategoryName={filterName}
          addFilters={addFilters}
          removeFilters={removeFilters}
          eventingData={eventingData}
        />
      ))}
      {numOfItemsToShow < sortedItems.length && (
        <Button variant="ghost" onClick={() => setNumOfItemToShow(numOfItemsToShow + NUMBER_OF_FILTER_ITEMS_TO_SHOW)}>
          {_t('Show More')}
        </Button>
      )}
    </div>
  );
};

export default memo(SearchFilterMobile);
