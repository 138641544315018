import * as React from 'react';

import type { FluxibleContext } from 'fluxible';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';
import FluxibleComponent from 'vendor/cnpm/fluxible.v0-4/addons/FluxibleComponent';

import type UserAgentInfo from 'js/lib/useragent';

import PageHeaderApp from 'bundles/page-header/PageHeaderApp';
import type { PropsFromCaller as DesktopHeaderControlsProps } from 'bundles/page-header/components/desktop/DesktopHeaderControls';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import DesktopHeaderControls from 'bundles/page-header/components/desktop/DesktopHeaderControls';
import { applicationStoreIn, naptimeStoreIn } from 'bundles/page/lib/migration';

class DesktopHeader extends React.Component<DesktopHeaderControlsProps & { userAgent: UserAgentInfo }> {
  declare fluxibleContext: FluxibleContext;

  static contextTypes = {
    getStore: PropTypes.func,
  };

  declare context: LegacyContextType<typeof DesktopHeader.contextTypes>;

  componentWillMount() {
    if (!(applicationStoreIn(this) && naptimeStoreIn(this))) {
      this.fluxibleContext = PageHeaderApp.createContext();
    }
  }

  render() {
    if (this.fluxibleContext) {
      const context = this.fluxibleContext.getComponentContext();
      return (
        <FluxibleComponent context={context}>
          <DesktopHeaderControls {...this.props} />
        </FluxibleComponent>
      );
    } else {
      return <DesktopHeaderControls {...this.props} />;
    }
  }
}

export default DesktopHeader;
