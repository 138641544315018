import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import { classes as footerClasses } from './footerCss';

export const classes = generateEmotionClasses('popoverDrawer', [
  'paper',
  'topBar',
  'fullHeight',
]);

const getDrawerCss = (props: { height: string }) => css`
  --popover-padding: var(--cds-spacing-200);
  --drawer-border-radius: var(--cds-border-radius-200);
  --drawer-footer-offset: 100%;
  --drawer-max-height: calc(100% - var(--cds-spacing-300));

  background: rgb(0 0 0 / 50%);

  .${classes.paper} {
    box-sizing: border-box;
    padding-top: var(--cds-spacing-300);
    display: flex;
    bottom: 0;

    /*! @noflip */
    left: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    max-height: var(--drawer-max-height);
    border-radius: var(--drawer-border-radius) var(--drawer-border-radius) 0 0;
    height: ${props.height};
    overflow: hidden;
  }

  .${classes.topBar} {
    text-align: right;
    padding: 0 var(--popover-padding);
  }

  .${footerClasses.root} {
    position: fixed;
    width: 100%;
    transform: translateY(-100%);
    top: var(--drawer-footer-offset);
  }

  &.${classes.fullHeight} {
    --drawer-max-height: unset;
    --drawer-border-radius: 0;

    .${classes.paper} {
      height: 100%;
    }
  }
`;

export default getDrawerCss;
