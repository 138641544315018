import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('text-field', [
  'root',
  'focused',
  'label',
  'formValidationStatus',
  'formSupportText',
  'validationIcon',
  'valid',
  'invalid',
  'readOnly',
  'characterLimit',
]);

const styles = css`
  .${classes.root} {
    margin-top: var(--cds-spacing-100);

    &:only-child {
      margin-top: 0;
    }
  }

  .${classes.label} {
    margin: 0;
  }

  .${classes.formValidationStatus} {
    margin-top: var(--cds-spacing-100);

    & + .${classes.root} {
      margin-top: var(--cds-spacing-50);
    }
  }

  .${classes.formSupportText} {
    margin: var(--cds-spacing-50) 0 0;
  }

  .${classes.characterLimit} {
    margin-top: var(--cds-spacing-50);
  }

  &.${classes.valid}, &.${classes.invalid} {
    .${classes.validationIcon} {
      padding-left: 0;
    }
  }

  &.${classes.valid} {
    .${classes.root} {
      &.${classes.focused} {
        .${classes.validationIcon} {
          color: var(--cds-color-feedback-success-hover);
        }
      }

      &:hover {
        background: var(--cds-color-feedback-background-success-weak);

        .${classes.validationIcon} {
          color: var(--cds-color-feedback-success-hover);
        }
      }
    }
  }

  &.${classes.invalid} {
    .${classes.root} {
      &.${classes.focused} {
        .${classes.validationIcon} {
          color: var(--cds-color-feedback-error-hover);
        }
      }

      &:hover {
        background: var(--cds-color-feedback-background-error-weak);

        .${classes.validationIcon} {
          color: var(--cds-color-feedback-error-hover);
        }
      }
    }
  }

  &.${classes.readOnly} {
    .${classes.root} {
      background: var(--cds-color-neutral-background-primary-weak);
    }
  }
`;

export default styles;
