import * as Sentry from '@sentry/react';
import URI from 'jsuri';

import API from 'js/lib/api';

import { getEoiContext } from 'bundles/expression-of-interest/utils/util';

// Define the types for SurveyData, SurveyResponseData, and DegreeData
type SurveyData = {
  section: {
    definition: {
      questions: any[];
    };
  };
  productId: string;
};

type SurveyResponseData = {
  [key: string]: string;
};

type DegreeData = {
  elements: {
    id: string;
  }[];
};

// Create API instances for QualificationSurveySectionResponses and Degrees
const QualificationSurveyResponseAPI = API('/api/qualificationSurveySectionResponses.v2/', { type: 'rest' });

const APIUtils = {
  /**
   * Preview the qualification survey for a given product ID.
   * @param {string} productId - The product ID to preview the survey for.
   * @returns {Promise<SurveyData>} A promise that resolves with the survey data.
   */
  async previewQualificationSurvey(productId: string): Promise<SurveyData> {
    const uri = new URI().addQueryParam('action', 'previewStartSection').addQueryParam('productId', productId);
    return QualificationSurveyResponseAPI.post(uri.toString());
  },

  /**
   * Calls window.document (do not execute on server-side)
   * Create or update a qualification survey with the given parameters.
   * @param {string} productId - The product ID for the survey.
   * @param {string} sectionId - The section ID for the survey.
   * @param {Record<string, unknown>} response - The response object for the survey.
   * @param {Record<string, unknown>} trackingFields - The tracking fields for the survey.
   * @param {string} [responseId] - Optional response ID for the survey.
   * @returns {Promise<SurveyResponseData>} A promise that resolves with the created or updated survey data.
   */
  async createOrUpdateQualificationSurvey(
    productId: string,
    sectionId: string,
    response: Record<string, unknown>,
    trackingFields: Record<string, unknown>,
    responseId?: string
  ): Promise<SurveyResponseData> {
    const uri = new URI().addQueryParam('action', 'createOrUpdate').addQueryParam('productId', productId);
    let eoiContext: Record<string, unknown> = {};

    if (responseId) uri.addQueryParam('responseId', responseId);

    try {
      eoiContext = getEoiContext(document.location.href);
    } catch (e) {
      Sentry.captureException(e);
    }

    const data = {
      sectionId,
      responses: response,
      trackingFields,
      eoiContext,
    };
    return QualificationSurveyResponseAPI.post(uri.toString(), { data });
  },
};

export default APIUtils;

export const { previewQualificationSurvey, createOrUpdateQualificationSurvey } = APIUtils;
