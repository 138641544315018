import { useCallback } from 'react';

import { useFilter as useAriaFilter, useCollator } from '@react-aria/i18n';

/**
 * Filter hook that provides filter functions to compare two strings.
 * Automatically uses the current locale.
 * @param options
 */
const useFilter = (options?: Intl.CollatorOptions) => {
  const collator = useCollator({ usage: 'search', ...options });
  const { contains, startsWith, endsWith } = useAriaFilter(options);

  const exact = useCallback(
    (string: string, substring: string) => {
      // Normalize both strings to NFC before comparing
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#description
      string = string.normalize('NFC');
      substring = substring.normalize('NFC');
      return collator.compare(string, substring) === 0;
    },
    [collator]
  );

  return {
    contains,
    startsWith,
    endsWith,
    exact,
  };
};

export default useFilter;
