import user from 'js/lib/user';

import { useSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';

export type NewCPlusEntryPointsEligibilityStatus = {
  shouldShowCourseraPlusBanner: boolean;
};

const useNewCPlusEntryPointsEligibilityCheck = (): NewCPlusEntryPointsEligibilityStatus => {
  const { subscriptions } = useSubscriptionsByUser();

  const hasActiveCourseraPlusOrLiteSubscription = subscriptions?.find(
    (subscription) =>
      subscription.isActive && (subscription.isCourseraPlusSubscription || subscription.isCourseraLiteSubscription)
  );

  const isEligibleUser = user.isAuthenticatedUser() && !hasActiveCourseraPlusOrLiteSubscription;

  return {
    shouldShowCourseraPlusBanner: isEligibleUser,
  };
};

export default useNewCPlusEntryPointsEligibilityCheck;
