import { Defaults } from './__generated__/types';
import type { GetConfiguredFeaturesData, IProductFeatureSet, Scope } from './types';
import { assertName, convertToNamespaces } from './utils';

// These are immutable value-objects, so caching them statically is fine.
const dangerousCreateWithMemoizeCache = new WeakMap<GetConfiguredFeaturesData, IProductFeatureSet<{}>>();

export class ProductFeatureSet<S extends Scope<{}>> implements IProductFeatureSet<S> {
  private declare data: S;

  constructor(data: S) {
    this.data = data;
  }

  get: <NS extends string & keyof S, N extends string & keyof S[NS]>(namespace: NS, name: N) => S[NS][N] = (
    namespace,
    name
  ) => {
    assertName(namespace, name);
    return this.data[namespace][name];
  };

  static Defaults = new ProductFeatureSet(Defaults);

  static createWithMemoize<S extends Scope<{}>>(data?: GetConfiguredFeaturesData): IProductFeatureSet<S> {
    if (data == null) {
      return ProductFeatureSet.Defaults as IProductFeatureSet<S>;
    }

    let features: IProductFeatureSet<S> = dangerousCreateWithMemoizeCache.get(data) as IProductFeatureSet<S>;
    if (features == null) {
      features = new ProductFeatureSet(convertToNamespaces<S>(data));
      dangerousCreateWithMemoizeCache.set(data, features);
    }

    return features;
  }
}
