import * as React from 'react';
import { useMemo } from 'react';

import type { Location } from 'history';

import PageHeader from 'bundles/page-header/components/PageHeader';
import { getHeaderProps } from 'bundles/premium-hub/utils/getHeaderProps';

type Props = {
  isSimplified: boolean;
  location?: Location;
};

const PremiumHubPageHeader = ({ isSimplified, location }: Props) => {
  return useMemo(() => <PageHeader {...getHeaderProps(isSimplified, location)} />, [isSimplified, location]);
};

export default PremiumHubPageHeader;
