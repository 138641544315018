import * as React from 'react';

import classNames from 'classnames';

import { Typography } from '@coursera/cds-core';

import { SOCIAL_MEDIA_ICON_URLS } from 'bundles/page-footer/components/global-footer/constants';
import getFooterLinks from 'bundles/page-footer/components/global-footer/data/footerLinks';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/page';

import 'css!bundles/page-footer/components/global-footer/common/__styles__/SocialMediaLinks';

type Props = {
  centered?: boolean;
  leftAligned?: boolean;
  header?: string;
  enableNewDefensiveSubfooter?: boolean;
};

const getSocialMediaLinkItems = () => {
  const links = getFooterLinks();

  return [
    {
      src: SOCIAL_MEDIA_ICON_URLS.facebook,
      tracking: 'facebook_link',
      alt: _t('Coursera Facebook'),
      href: links.facebook.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.linkedin,
      tracking: 'linkedin_link',
      alt: _t('Coursera LinkedIn'),
      href: links.linkedin.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.twitter,
      tracking: 'twitter_link',
      alt: _t('Coursera Twitter'),
      href: links.twitter.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.youtube,
      tracking: 'youtube_link',
      alt: _t('Coursera YouTube'),
      href: links.youtube.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.instagram,
      tracking: 'instagram_link',
      alt: _t('Coursera Instagram'),
      href: links.instagram.href,
    },
  ];
};

export default function SocialMediaLinks({ enableNewDefensiveSubfooter, centered, leftAligned, header }: Props) {
  const socialMediaLinkItems = getSocialMediaLinkItems();
  const socialMediaClasses = classNames(
    'rc-GlobalFooter_social-media',
    {
      'rc-GlobalFooter_social-media--centered': centered,
      'rc-GlobalFooter_social-media--left-aligned': leftAligned,
      'lohp-rebrand': true,
    } // even margin on right/left side of icons
  );
  const socialMediaListClasses = classNames('rc-GlobalFooter_social-media_list', {
    'in-new-experiment': enableNewDefensiveSubfooter,
  });

  return (
    <div className={socialMediaClasses}>
      {header && (
        <Typography
          variant="h2"
          component="p"
          className="rc-GlobalFooter_column_header rc-GlobalFooter_column_header--social-media"
        >
          {header}
        </Typography>
      )}
      <ul className={socialMediaListClasses}>
        {socialMediaLinkItems.map((mediaItem) => (
          <li key={mediaItem.tracking}>
            <TrackedLink2
              trackingName={mediaItem.tracking}
              className="rc-GlobalFooter_social-media_link"
              href={mediaItem.href}
              target="_blank"
              rel="noopener noreferrer"
              key={mediaItem.tracking}
            >
              <LazyImgix src={mediaItem.src} maxHeight={28} maxWidth={28} height={28} width={28} alt={mediaItem.alt} />
            </TrackedLink2>
          </li>
        ))}
      </ul>
    </div>
  );
}
