/**
 * Combines multiple label ids into one aria-labelledby.
 * Filters undefined ids.
 *
 * @param {...*} ...labelId label ids to combine
 * @return {string} combined aria-labelledby
 */
export const ariaLabelledByForMultipleLabels = (
  ...labelIds: (string | undefined)[]
) => {
  return labelIds.filter((id) => id !== undefined).join(' ');
};
