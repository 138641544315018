import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import { classes as ContextualHelpClasses } from './getContextualHelpCss';

export const classes = generateEmotionClasses('ContextualHelpIcon', [
  'customHelpIcon',
  'inverted',
]);

const getContextualHelpIconCss = css`
  margin: 0;
  width: 16px;
  height: auto;
  transform: rotate(0deg);
  transition: transform, fill, stroke 0.3s ease-in-out;

  /* --- Tooltip SVG Icon Styling --- */
  .info-icon {
    fill: var(--cds-color-interactive-primary);
  }

  /* Background Transition */
  .i-background {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform, fill, stroke 0.3s ease-in-out;
    fill: var(--cds-color-interactive-primary);
    fill-opacity: 0;
    stroke: var(--cds-color-interactive-primary);
    stroke-width: 2;
  }

  /* i Top Serif Transform */
  .i-top-serif {
    transform-box: fill-box;
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
  }

  /* i Bottom Serif Transform */
  .i-bottom-serif {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }

  /* i Neck Transform */
  .i-neck {
    fill: var(--cds-color-interactive-primary);
    transform-box: fill-box;
    transform-origin: bottom;
    transition: transform, fill 0.3s ease-in-out;
  }

  /* i Dot Transform */
  .i-dot {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }

  /* x Crosspath Transform */
  .x-crosspath {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform, fill 0.3s ease-in-out;
  }

  &.${ContextualHelpClasses.expanded} {
    transform: rotate(-45deg);

    .i-background {
      transform: rotate(-45deg);
      fill-opacity: 1;
    }

    .i-top-serif {
      transform: scale(0, 1);
    }

    .i-bottom-serif {
      transform: scale(0, 1);
    }

    .i-neck {
      transform: scale(1, 1.42) translate(0%, 2.5%);
      fill: var(--cds-color-neutral-primary-invert);
    }

    .i-dot {
      transform: scale(0, 0);
    }

    .x-crosspath {
      transform: scale(9.5, 1);
      fill: var(--cds-color-neutral-primary-invert);
    }
  }

  &.${classes.inverted} {
    .info-icon,
    .i-neck {
      fill: var(--cds-color-neutral-primary-invert);
    }

    .i-background {
      stroke: var(--cds-color-neutral-primary-invert);
      fill: var(--cds-color-neutral-primary-invert) !important;
    }

    .x-crosspath {
      transform: scale(9.5, 1);
      fill-opacity: 0;
    }
  }

  &.${classes.inverted}.${ContextualHelpClasses.expanded} {
    .i-neck,
    .x-crosspath {
      fill: var(--cds-color-interactive-primary) !important;
      fill-opacity: 1;
    }
  }
`;

export default getContextualHelpIconCss;
