import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('ShowMoreList', [
  'listContainer',
  'expanded',
  'ctaButton',
  'ctaIcon',
  'collapseContainer',
  'firstExpandedItem',
]);

const getShowMoreListCss = css`
  .${classes.listContainer} {
    list-style: none;
    overflow: hidden;
  }

  .${classes.ctaButton} {
    margin: var(--cds-spacing-300) auto var(--cds-spacing-150) auto;
    display: flex;
  }

  .${classes.ctaIcon} {
    flex-shrink: 0;
    color: inherit;
    transform: rotate(90deg);
    transition: transform 300ms ease-in-out;

    &.${classes.expanded} {
      transform: rotate(-90deg);
    }
  }

  .${classes.firstExpandedItem} {
    outline: 1px solid transparent;
  }
`;

export default getShowMoreListCss;
