import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('tab-list', [
  'indicator',
  'scrollable',
]);

const getTabCss = css`
  min-height: auto;
  overflow: visible;
  position: relative;
  border-bottom: 1px solid var(--cds-color-neutral-stroke-primary);

  .${classes.indicator} {
    height: var(--cds-spacing-50);
    background-color: var(--cds-color-interactive-primary);
    border-radius: var(--cds-border-radius-25) var(--cds-border-radius-25) 0 0;
  }

  .${classes.scrollable} {
    padding: 0;
  }

  @media (forced-colors: active) {
    .${classes.indicator} {
      border-bottom: 4px solid white;
      box-sizing: border-box;
    }
  }
`;

export default getTabCss;
