type Response = {
  code: string;
  existingAccountType?: string;
};

type Service = 'apple' | 'facebook' | 'google';

class SSOError extends Error {
  declare code: string;

  declare response: Response;

  declare service: Service;

  constructor(response: Response = { code: '' }, service: $TSFixMe) {
    const { code = '' } = response;

    super(code);

    this.code = code;
    this.response = response;
    this.service = service;
  }
}

export default SSOError;
