import { useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import type { DegreeWebinar_DegreeWebinar as DegreeWebinar } from '__generated__/graphql-types';

import type {
  GetDegreeWebinarsQuery,
  GetDegreeWebinarsQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/getDegreeWebinars';
import GetDegreeWebinars from 'bundles/premium-hub/queries/getDegreeWebinars.graphql';

type UseWebinarQueryResponse = {
  loading: boolean;
  error?: ApolloError;
  pastWebinars?: DegreeWebinar[];
  upcomingWebinars?: DegreeWebinar[];
};

const getQueryVariables = (pageId: string) => {
  if (pageId === 'bachelors') {
    return { productVariants: 'BachelorsDegree' };
  }

  if (pageId === 'masters') {
    return { productVariants: 'MastersDegree' };
  }

  return { categories: [pageId.toUpperCase()] };
};

export const useWebinarQuery = (pageId: string, displayingWebinarsModule: boolean): UseWebinarQueryResponse => {
  const { loading, error, data } = useQuery<GetDegreeWebinarsQuery, GetDegreeWebinarsQueryVariables>(
    GetDegreeWebinars,
    {
      skip: !pageId || !displayingWebinarsModule,
      context: { clientName: 'gatewayGql' },
      variables: { ...getQueryVariables(pageId) },
      onError: (queryError) =>
        Sentry.captureException(queryError, {
          extra: {
            message: 'Error fetching webinars',
          },
        }),
    }
  );

  const webinars = data?.DegreeWebinarQueries?.getWebinars;
  const { pastWebinars, upcomingWebinars } = webinars || {};
  return {
    loading,
    error,
    pastWebinars: pastWebinars as DegreeWebinar[],
    upcomingWebinars: upcomingWebinars as DegreeWebinar[],
  };
};

export default useWebinarQuery;
