/** @jsx jsx */

/** @jsxFrag */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { debounce, isEqual } from 'lodash';

import { Autocomplete, IconButton, breakpoints, useMediaQuery } from '@coursera/cds-core';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import AutocompleteItemIcon from 'bundles/search-common/components/searchbar/AutocompleteItemIcon';
import AutocompleteOptionTrackingDiv from 'bundles/search-common/components/searchbar/AutocompleteOptionTrackingDiv';
import { AUTOCOMPLETE_DIRECT_MATCH_INDEX_ID, SEARCH_SUGGESTIONS_INDEX_ID } from 'bundles/search-common/constants';
import {
  AUTOCOMPLETE_MODES,
  type AutocompleteMode,
  DEBOUNCE_DURATION_MS,
  TRENDING_SEARCH_SUGGESTIONS_CONFIG,
} from 'bundles/search-common/constants/autocomplete';
import { useSearch } from 'bundles/search-common/providers/SearchContext';
import useSearchQuery from 'bundles/search-common/providers/useSearchQuery';
import {
  type AutocompleteItemGroup,
  type AutocompleteItems,
  createAsYouTypeAutocompleteItems,
  createRepeatSearchAutocompleteItems,
  createZeroStateAutocompleteItems,
} from 'bundles/search-common/utils/autocomplete';
import inServerContext from 'bundles/ssr/util/inServerContext';

import _t from 'i18n!nls/search-common';

type WrapperProps = {
  searchIsOpen?: boolean;
  hideMobileSearchPage?: () => void;
  recentlySearchedItems?: AutocompleteItems;
  recentlyViewedItems?: AutocompleteItems;
};

type Props = WrapperProps & {
  searchText: string;
  onInputChange: (value: string) => void;
  autocompleteMode: AutocompleteMode;
  onSubmit: (query: string) => void;
};

const styles = {
  autocomplete: css`
    .cds-popoverDropdown-paper {
      /* if we don't remove 2px from CDS, we have an awkward gap with page content */
      margin-top: 10px;
    }

    /* temporarily hidden for the sake of experiment */
    .cds-SearchClearButton-searchClearButton {
      display: none;
    }

    &.cds-Search-base {
      margin: auto;
      margin-left: 4px;
      height: 44px;
      width: 280px;

      @media (max-width: 1030px) {
        /* technically we show mobile header */
        width: 100px;
      }

      @media (min-width: 1200px) and (max-width: 1492px) {
        width: 330px;
      }

      @media (min-width: 1492px) {
        width: 440px;
      }

      > div {
        border-radius: 24px;
        border: 1px solid #dbe0e1;

        fieldset {
          /* remove duplicate border */
          border: none;
        }

        ::before {
          /* a11y border */
          border-radius: 24px !important;
        }
      }

      .cds-Search-suffix {
        /* for search button right spacing */
        padding-right: 4px;
      }

      /* necessary to remove inherited styles on account-profile (potentially elsewhere) */
      input {
        margin-bottom: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
      }

      /* necessary to remove inherited styles on account-profile (potentially elsewhere) */
      input[aria-invalid='true']:invalid:focus {
        box-shadow: none;
      }
    }

    & .cds-AutocompleteOption-suffix {
      display: none !important;
    }

    & .cds-AutocompleteOption-checked:not(:hover) {
      background: transparent !important;
    }
  `,
  searchButton: css`
    /* circular shape of search button */
    border-radius: 24px;
  `,
};

const SearchBarContent = (props: Props) => {
  const {
    searchIsOpen,
    hideMobileSearchPage,
    searchText,
    onSubmit,
    autocompleteMode,
    onInputChange,
    recentlySearchedItems = [],
    recentlyViewedItems = [],
  } = props;

  const isMobileOrTabletView = useMediaQuery(breakpoints.down('sm'));
  const inputRef = React.useRef<HTMLInputElement>(null);
  // a separate, durable query results set for the "trending" section of the autocomplete
  const { results: trendingResults, error: trendingItemsError } = useSearchQuery(TRENDING_SEARCH_SUGGESTIONS_CONFIG, {
    skip: inServerContext,
  });
  // user input derived search results
  const { getSearchResults, updateQuery, isLoading, error: searchResultsError } = useSearch();
  const mainSearchData = getSearchResults(SEARCH_SUGGESTIONS_INDEX_ID);
  const directMatchResult = getSearchResults(AUTOCOMPLETE_DIRECT_MATCH_INDEX_ID);
  const { elements: mainSearchDataElements, query: providerQuery } = mainSearchData || {};
  const isLoadingOrHasErrors = isLoading || trendingItemsError || searchResultsError;

  // previous options - displayed during loading state
  const [prevOptions, setPrevOptions] = React.useState<AutocompleteItemGroup[]>([]);
  const autocompleteItemsInGroups = React.useMemo(() => {
    if (isLoadingOrHasErrors) return prevOptions;
    switch (autocompleteMode) {
      case AUTOCOMPLETE_MODES.AUTOCOMPLETE_ZERO_STATE:
        return createZeroStateAutocompleteItems(trendingResults);
      case AUTOCOMPLETE_MODES.AUTOCOMPLETE_AS_YOU_TYPE:
        return createAsYouTypeAutocompleteItems(mainSearchDataElements, searchText, directMatchResult?.elements);
      case AUTOCOMPLETE_MODES.AUTOCOMPLETE_REPEAT_SEARCH:
        return createRepeatSearchAutocompleteItems(recentlySearchedItems, recentlyViewedItems, trendingResults);
      default:
        return [];
    }
  }, [
    autocompleteMode,
    mainSearchDataElements,
    trendingResults,
    searchText,
    prevOptions,
    isLoadingOrHasErrors,
    recentlyViewedItems,
    recentlySearchedItems,
    directMatchResult?.elements,
  ]);

  const refineSearch = (newValue: string) => {
    updateQuery(newValue);
  };
  const debouncedRefineSearch = debounce(refineSearch, DEBOUNCE_DURATION_MS);

  // When the response is loading, we want to persist the previous options
  React.useEffect(() => {
    if (!isLoadingOrHasErrors && !isEqual(autocompleteItemsInGroups, prevOptions)) {
      setPrevOptions(autocompleteItemsInGroups);
    }
  }, [autocompleteItemsInGroups, prevOptions, isLoadingOrHasErrors]);

  // Update the query in the provider when the search text changes
  React.useEffect(() => {
    if (!isLoadingOrHasErrors && searchText && searchText !== providerQuery) {
      debouncedRefineSearch(searchText);
    }
  }, [searchText, debouncedRefineSearch, providerQuery, isLoadingOrHasErrors]);

  const handleSubmit = React.useCallback(
    (value: string) => {
      onSubmit(decodeURIComponent(value));
      hideMobileSearchPage?.();
      if (!isMobileOrTabletView) {
        // If we blur immediately, the text in the input will not update on selection
        setTimeout(() => {
          inputRef.current?.blur?.();
        });
      }
    },
    [onSubmit, hideMobileSearchPage, isMobileOrTabletView]
  );
  const visibilityTrackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_autocomplete_suggestions',
    { searchSuggestions: 'TestSearch' }
  );
  return (
    <div className="rc-SearchBarContent">
      <Autocomplete
        popoverTrigger="focus"
        id="search-autocomplete"
        name="query"
        placeholder={_t('What do you want to learn?')}
        items={autocompleteItemsInGroups}
        value={searchText}
        allowsCustomValue
        defaultValue={searchText}
        prefixIcon={<div />}
        css={styles.autocomplete}
        inputActionButtons={<IconButton intent="search" size="small" css={styles.searchButton} type="submit" />}
        onInputChange={onInputChange}
        disableSelectionChangeOnTab
        onSelectionChange={(selectedOptionText: React.Key) => {
          if (selectedOptionText) {
            handleSubmit(encodeURIComponent(String(selectedOptionText)));
          }
        }}
        inputProps={{
          id: 'search-autocomplete-input',
          'data-testid': 'search-autocomplete-input',
          name: 'query',
          type: 'text',
          onKeyUp: (e) => {
            if (e.key === 'Enter') {
              handleSubmit(encodeURIComponent(searchText));
            }
          },
        }}
        ref={inputRef}
        autoFocus={!!searchIsOpen}
        data-testid="search-autocomplete"
        aria-label={_t('Search catalog')}
        dropdownProps={{
          disablePortal: true,
          style: {
            width: '568px',
            maxHeight: 'calc(100vh - 120px)',
          },
        }}
        drawerInputProps={{
          enterkeyhint: 'Search',
          onKeyUp: (e) => {
            if (e.key === 'Enter') {
              handleSubmit(encodeURIComponent(searchText));
            }
          },
        }}
        onClose={() => hideMobileSearchPage?.()}
      >
        {({ title: groupTitle, children, id: groupId }) => (
          <Autocomplete.Group key={groupTitle} items={children} title={groupTitle}>
            {({ imageUrl, id, supportText, name, textValue }) => (
              // CDS only allows a string, but we needed this trackedDiv for eventing parity
              <Autocomplete.Option
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error: This does nothing
                ref={visibilityTrackingRef}
                prefix={<AutocompleteItemIcon imageUrl={imageUrl} groupId={groupId} avatarAltText={name} />}
                key={id}
                supportText={supportText}
                textValue={textValue ?? name}
                aria-label={name}
              >
                <AutocompleteOptionTrackingDiv
                  autocompleteState={autocompleteMode}
                  id={id}
                  recentlyViewedItems={recentlyViewedItems}
                >
                  {name}
                </AutocompleteOptionTrackingDiv>
              </Autocomplete.Option>
            )}
          </Autocomplete.Group>
        )}
      </Autocomplete>
    </div>
  );
};

export default SearchBarContent;
