import { useLocation } from 'js/lib/useRouter';

import { ON_SITE_SEARCH_PATH } from 'bundles/search-common/constants';

const useIsConsumerSerp = () => {
  const currentRoute = useLocation();
  const wellIsIt = currentRoute.pathname.startsWith(ON_SITE_SEARCH_PATH);
  return wellIsIt;
};

export default useIsConsumerSerp;
