import _t from 'i18n!nls/logged-in-home';

export const LOGGED_OUT_ENTERPRISE_USER_CONTEXT_ID = 'logged-out-enterprise-program-home-v2';

export const LOGGED_IN_ENTERPRISE_USER_CONTEXT_ID = 'logged-in-enterprise-program-home-v2';

export const CURATED_COLLECTION_MAX_LIMIT = 5;

export const CURATED_COLLECTION_MIN_LIMIT = 2;

export const ENTERPRISE_BROWSE_COLLECTION_LIMIT = 3;

export const MAX_ONBOARDING_SKILLS_LENGTH = 6;

export const ENTERPRISE_COLLECTION_TYPES = {
  CURATED: 'curated',
  FOR_CREDIT: 'for-credit',
  BROWSE: 'browse',
  SKILL_CLIPS: 'skill-clips',
  SKILL_RECS: 'skill-recs',
};

export const CURRENT_TAB_TO_URL_PARAM = {
  MY_LEARNING: 'my-learning',
  SKILLSETS: 'skillsets',
  RECOMMENDATIONS: 'recommendations',
};

export const PAGE_SIZE_DEFAULT = 8;

export const LOGGED_IN_HOME_PAGE_TYPES = {
  HOME: 'HOME',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  SAVED: 'SAVED',
};

export const QUERY_PARAM_VALUES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  SAVED: 'SAVED',
  SKILLS_DASHBOARD: 'SKILLS_DASHBOARD',
};

export const LOGGED_IN_HOME_PAGE_TYPE_TO_NAME = {
  get HOME() {
    return _t('Home');
  },
  get IN_PROGRESS() {
    return _t('In Progress');
  },
  get COMPLETED() {
    return _t('Completed');
  },
  get SAVED() {
    return _t('Saved');
  },
};

export const QUERY_PARAMS = {
  MY_LEARNING_TAB: 'myLearningTab',
  CURRENT_TAB: 'currentTab',
};

export const LOGGED_IN_HOME_PAGE_TYPE_TO_SLUG = {
  HOME: 'home',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  UPDATES: 'updates',
  ACCOMPLISHMENTS: 'accomplishments',
  SAVED: 'saved',
  MY_LEARNING: 'my-learning',
};

export const COACH_ANIMATION_STATES = {
  DEFAULT: 'default',
  WINK: 'wink',
} as const;

// TODO: use link to new onboarding flow
export const ONBOARDING_URL = '/onboarding-2022';
export const RDP_URL = '/career-academy/roles/';
