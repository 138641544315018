/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { NavigationButton, NavigationMetadata, NavigationTitle } from 'bundles/ai-course-coach/components/common';
import type { RecommendedNavigationItem } from 'bundles/ai-course-coach/utils/types';

type Props = {
  itemId: string;
  title: { content: string; headingComponent?: 'h3' | 'h4' };
  buttonText: string;
  metadata: string[];
  itemType: RecommendedNavigationItem;
  handleNavButtonClick: () => void;
};

const styles = {
  navigationContainer: css`
    display: flex;
    width: 100%;
    min-width: auto;
    padding: var(--cds-spacing-200);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--cds-spacing-200);
    border-radius: var(--cds-border-radius-200);
    border: 2px solid var(--cds-color-grey-100);
    background: #f4f7fa;
  `,
};

export const CoachNavigationContainer = (props: Props): React.ReactElement => {
  const {
    itemId,
    title: { content, headingComponent },
    buttonText,
    metadata,
    itemType,
    handleNavButtonClick,
  } = props;
  const containerId = `${itemId}-container`;

  return (
    <div css={styles.navigationContainer} id={containerId}>
      <NavigationTitle content={content} component={headingComponent} />
      <NavigationMetadata metadata={metadata} itemType={itemType} />
      <NavigationButton
        buttonText={buttonText}
        handleNavButtonClick={handleNavButtonClick}
        aria-describedby={containerId}
      />
    </div>
  );
};

export default CoachNavigationContainer;
