// Not present in the schema because it is an apollo-rest-link query.
import gql from 'graphql-tag';

/* eslint-disable graphql/template-strings */
export const productFeaturesQuery = gql`
  query ProductFeaturesQuery($input: ProductFeaturesQueryInput!) {
    GetConfiguredFeatures(input: $input)
      @rest(
        type: "GetConfiguredFeatures"
        path: "grpc/productfeatures/v1/ProductFeaturesAPI/GetConfiguredFeatures"
        method: "POST"
      ) {
      featureConfigurations @type(name: "FeatureConfiguration") {
        featureIdentification @type(name: "FeatureIdentification") {
          namespace
          name
        }
        value
      }
    }
  }
`;
/* eslint-enable graphql/template-strings */
