/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import Markdown from 'bundles/cms/components/Markdown';

type Props = {
  content: string;
};

const styles = {
  container: css`
    border-radius: var(--cds-spacing-100);
    padding: var(--cds-spacing-400);
    background-color: var(--cds-color-interactive-stroke-primary-focus-invert);
  `,
};

const InformationalSectionSidebar = ({ content }: Props) => {
  if (!content) return null;
  return (
    <div css={styles.container}>
      <Typography2 component="p" variant="bodyPrimary">
        <Markdown source={content} />
      </Typography2>
    </div>
  );
};

export default InformationalSectionSidebar;
