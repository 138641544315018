/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { ColumnHeaderProps } from 'bundles/page-footer/components/global-footer/common/LinkColumn';
import LinkColumn from 'bundles/page-footer/components/global-footer/common/LinkColumn';
import getFooterLinks from 'bundles/page-footer/components/global-footer/data/footerLinks';

import _t from 'i18n!nls/page';

const MoreLinkColumn: React.FC<ColumnHeaderProps> = ({ columnHeader, extraClassName }) => {
  const links = getFooterLinks();

  return (
    <LinkColumn
      columnHeader={columnHeader}
      header={_t('More')}
      links={[
        links.press,
        links.investors,
        links.terms,
        links.privacy,
        links.help,
        links.accessibility,
        links.contact,
        links.articles,
        links.directory,
        links.affiliates,
        links.modernSlavery,
        links.cookiePolicy,
      ]}
      extraClassName={extraClassName}
    />
  );
};

export default MoreLinkColumn;
