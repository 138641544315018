import Q from 'q';

import API from 'js/lib/api';

const ReviewHelpfulResponseAPI = API('/api/helpfulFeedbackVotes.v1/', { type: 'rest' });

const toHelpfulFeedbackVoteId = (userId: number, reviewId: string) => {
  /*
    A review ID is comprised of two parts: 
    An 8 digit unique ID + a course ID.
    Example: 37773539~COURSE!~Gtv4Xb1-EeS-ViIACwYKVQ

    In order to create an ID for helpfulFeedbackVotes.v1 we need to select pieces of this ID.
    Below we isolate the course ID, the unique review ID and along with the userId we can create
    the helpfulFeedbackVotes.v1 ID shape.
  */
  const splitReviewId = reviewId.split('~');
  const uniqueReviewId = splitReviewId[0];
  const courseId = splitReviewId[splitReviewId.length - 1];

  return `${userId}~${uniqueReviewId}!~courseContext!!~${courseId}!~STAR`;
};

const addHelpfulReviewResponse = (userId: $TSFixMe, reviewId: $TSFixMe, voteType: $TSFixMe) => {
  const helpfulFeedbackVoteId = toHelpfulFeedbackVoteId(userId, reviewId);
  const data = { voteType };

  return Q(ReviewHelpfulResponseAPI.put(helpfulFeedbackVoteId, { data }));
};

const submitResponse = (actionContext: $TSFixMe, { reviewData, voteType, onSuccess }: $TSFixMe) => {
  addHelpfulReviewResponse(reviewData.userId, reviewData.reviewId, voteType)
    .then((response) => {
      onSuccess(response.elements[0]);
    })
    .done();
};

export { addHelpfulReviewResponse, submitResponse, toHelpfulFeedbackVoteId };
