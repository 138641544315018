import type { ButtonName, EoiInteraction, EoiSection, EoiStatus, LinkType } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { appendUtmParametersToApplicationLink } from 'bundles/expression-of-interest/utils/util';

export const useEoiEventing = (productId: string) => {
  const track = useTracker();

  const trackInteractEoi = (
    eoiInteraction: EoiInteraction,
    eoiSection?: EoiSection | null,
    trackingIndex?: number,
    personalizedMessageName?: string
  ) => {
    track('interact_eoi', {
      eoiInteraction,
      ...(eoiSection && { eoiSection }),
      pageSection: {
        sectionName: 'eoi',
        subindex: trackingIndex ?? 1,
      },
      ...(personalizedMessageName && {
        personalizedMessage: {
          messageId: personalizedMessageName,
        },
      }),
      product: {
        id: productId,
      },
    });
  };

  const trackSubmitEoi = (eoiStatus: EoiStatus, eoiSection: EoiSection, trackingIndex?: number) => {
    track('submit_eoi', {
      eoiStatus,
      eoiSection,
      pageSection: {
        sectionName: 'eoi',
        subindex: trackingIndex ?? 1,
      },
      product: {
        id: productId,
      },
    });
  };

  const trackClickButton = (buttonName: ButtonName, linkType?: LinkType, link?: string, trackingIndex?: number) => {
    const linkURL = link ? appendUtmParametersToApplicationLink(link) : null;
    track('click_button', {
      button: {
        name: buttonName,
        ...(linkType && {
          linkType,
        }),
        ...(linkURL && {
          linkURL,
        }),
      },
      pageSection: {
        sectionName: 'eoi',
        subindex: trackingIndex ?? 1,
      },
      product: {
        id: productId,
      },
    });
  };

  return {
    trackInteractEoi,
    trackSubmitEoi,
    trackClickButton,
  };
};
