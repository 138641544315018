/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import PropTypes from 'prop-types';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import useRouter from 'js/lib/useRouter';
import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, Typography2, breakpoints } from '@coursera/cds-core';
import { ThumbsUpFilledIcon, ThumbsUpIcon } from '@coursera/cds-icons';
import { StarRating } from '@coursera/coursera-ui';

import { ENROLL } from 'bundles/enroll/utils/enrollActionParams';
import type { TopReview, TopReviewIdAndVoteType } from 'bundles/reviews-common/types/reviewsSharedTypes';
import { submitResponse } from 'bundles/reviews-common/utils/ReviewHelpfulAPIUtils';
import { ReviewComment } from 'bundles/reviews/components/ReviewComment';
import { HELPFUL_VOTE, UNMARKED_VOTE } from 'bundles/reviews/constants';

import _t from 'i18n!nls/reviews-common';

const styles = {
  reviewHelpfulButton: () => css`
    margin-top: var(--cds-spacing-200);

    &:hover,
    &:focus {
      color: var(--cds-color-blue-800);
      background-color: var(--cds-color-blue-25);
    }
  `,
  topReviewComment: () => css`
    margin: var(--cds-spacing-50) 0 0;
    overflow-wrap: break-word;

    &:first-of-type {
      margin-top: var(--cds-spacing-100) 0 0;
    }
  `,
  topReviewCard: () => css`
    width: 50%;
    margin: 0 0 0 var(--cds-spacing-150);
    padding: var(--cds-spacing-300);
    box-shadow: 3px 3px 14px -2px rgb(0 0 0 / 30%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:first-of-type {
      margin: 0 var(--cds-spacing-150) 0 0;
    }
    ${breakpoints.down(767)} {
      width: 100%;
      margin: 0;

      &:first-of-type {
        margin: 0 0 var(--cds-spacing-200);
      }
    }
  `,
};

type Props = {
  topReview: TopReview;
  // the data we get from the top review is incomplete, so we need to fetch the original review to get all info
  originalReviewData?: TopReviewIdAndVoteType;
  userId?: number;
  router?: InjectedRouter;
};

type Context = {
  executeAction: (actionContext: $TSFixMe, payload: Record<string, unknown>) => void;
};

const submitHelpfulResponse = ({
  userId,
  reviewId,
  executeAction,
  isMarkedHelpful,
  setIsMarkedHelpful,
}: {
  userId: number;
  reviewId: string;
  executeAction: (actionContext: $TSFixMe, payload: Record<string, unknown>) => void;
  isMarkedHelpful: boolean;
  setIsMarkedHelpful: (isHelpful: boolean) => void;
}) => {
  const newVoteType = isMarkedHelpful ? UNMARKED_VOTE : HELPFUL_VOTE;

  executeAction(submitResponse, {
    reviewData: {
      userId,
      reviewId,
    },
    voteType: newVoteType,
    onSuccess: () => setIsMarkedHelpful(newVoteType === HELPFUL_VOTE),
  });
};

const TopReviewCard: React.FC<Props> = ({ topReview, originalReviewData, userId }, { executeAction }: Context) => {
  const router = useRouter();
  const [isMarkedHelpful, setIsMarkedHelpful] = useState(Boolean(originalReviewData?.isMarkedHelpful));

  const { averageFiveStarRating, timestamp, authorName, comment } = topReview;

  const showSignupModal = () => {
    router.replace({
      pathname: router.location.pathname,
      query: { ...router.location.query, authMode: 'signup', action: ENROLL },
    });
  };

  const handleHelpfulButtonClick = () => {
    const originalReviewId = originalReviewData && originalReviewData.id;

    if (!userId || !originalReviewId) {
      showSignupModal();
    } else {
      submitHelpfulResponse({ userId, reviewId: originalReviewId, executeAction, isMarkedHelpful, setIsMarkedHelpful });
    }
  };

  const starRatingProps = {
    rating: averageFiveStarRating,
    a11yIdentifier: `${authorName + timestamp}`,
    enableColorOpacity: true,
  };

  return (
    <div css={styles.topReviewCard} data-unit="top-review-card">
      <Typography2 component="p" variant="bodySecondary">
        {authorName}
      </Typography2>
      <Typography2 component="p" variant="bodySecondary" color="supportText">
        {formatDateTimeDisplay(timestamp, MED_DATE_ONLY_DISPLAY, null, _t.getLocale())}
      </Typography2>
      <StarRating {...starRatingProps} />
      <ReviewComment comment={comment} textVariant="body1" css={styles.topReviewComment} />
      <Button
        variant="secondary"
        css={styles.reviewHelpfulButton}
        icon={isMarkedHelpful ? <ThumbsUpFilledIcon /> : <ThumbsUpIcon />}
        iconPosition="before"
        onClick={handleHelpfulButtonClick}
        data-unit="helpful-btn"
      >
        {isMarkedHelpful ? _t('Helpful') : _t('Helpful?')}
      </Button>
    </div>
  );
};
TopReviewCard.contextTypes = {
  executeAction: PropTypes.func,
};

export default TopReviewCard;
