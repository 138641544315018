/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import courseraPlus from 'bundles/epic/data/defaults/courseraPlus.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  courseUpsellVariant: 0 | 1 | 2 | 3;
  welcome: 'control' | 'features' | 'carousel';
  isCollectionPagePendoEnabled: boolean;
  is39GatewaysTo49CourseraPlusUpsellEnabled: boolean;
  is39SpecializationTo49UpsellEnabled: boolean;
  hideS12nUpsellFromWesternEuropeVariant: boolean;
  isS12nUpsell49NoFreeTrialEnabled: boolean;
  subscriptionTiersLiteVariant: 'optOut' | 'control' | 'A' | 'B' | 'C';
  tiersEnrollmentFromStandaloneCoursesVariant: 'optOut' | 'control' | '3';
  subscriptionTiersFullStory: boolean;
  idealUpgradeFullStoryEnabled: boolean;
  subscriptionTiersLiteLandingPageVariant: 'optOut' | '3';
  contentfulEditor: boolean;
  liteEnrollmentModalDesignTweaksVariant: 'optOut' | 'control' | '1' | '2' | '3' | '4' | '5';
  liteEnrollmentModalDesignTweaksVariantVersion2: 'optOut' | 'control' | '1' | '2' | '5';
  subscriptionTiersDesignV3Variant: 'optOut' | 'control' | '1' | '2' | '3' | '4' | '5';
  subscriptionTiersDesignV3RelaunchVariant: 'optOut' | 'control' | '3';
  subscriptionTiersPricingTestVariant: 'optOut' | '3';
  subscriptionTiersPricingTestVariantIndia: 'optOut' | '3';
  subscriptionTiersPricingVariantLowIncomeCountries: 'optOut' | '3';
  tiersDowngradeExpandedAudienceVariant: 'optOut' | '3';
  s12n49GoogleUpsellVariant: 'control' | 'upsell_59_cplus_with_promo' | 'upsell_59_cplus_no_promo';
  s12n39GoogleUpsellVariant: 'control' | 'upsell_49_cplus_with_promo' | 'upsell_49_cplus_no_promo';
  checkoutSubscriberCountAndPartnerLogo: 'control' | '1' | '2' | '3';
  plusToLiteDowngradeVariant: 'control' | '1';
  isLearnerImpressedInIndiaTiersEpic: boolean;
  showWesOnCourseraPlusLandingPage: boolean;
  s12n49StripeUpsellVariant: 'optOut' | 'upsell_49_cplus';
  courseraPlusLandingPageAnnualPricePresentation: 'control' | '1' | '2';
  courseraPlusLandingPageAnnualPricePresentationVersion2: 'control' | '1' | '2' | '3';
  plusMonthlyToAnnualUpgradeEnabled: boolean;
  plusMonthlyToAnnualUpgradeEnabledOnXDP: boolean;
  nonGoogleS12nPriceDecreaseTestVariant: boolean;
  courseUpsellStripe: boolean;
  tiersFromStandaloneCoursesPartnerExclusionEnabled: boolean;
};

const NAMESPACE = 'courseraPlus';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([courseraPlus as $TSFixMe]);

const courseraPlusEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default courseraPlusEpicClient;
