/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';

import config from 'js/app/config';

import { Grid, Typography, breakpoints } from '@coursera/cds-core';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import { buildDegreeUrlRelative } from 'bundles/common/utils/urlUtils';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { ProductVariantCardsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import CategoryCard from 'bundles/premium-hub/components/shared/CategoryCard';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { PPCDegreeCategory } from 'bundles/premium-hub/types';
import { getSlugFromHref } from 'bundles/premium-hub/utils';

import _t from 'i18n!nls/premium-hub';

export type DegreeCategoryCardProps = {
  categoryLinks?: Array<Maybe<ProductVariantCardsFragment>>;
  productVariantCards?: Array<Maybe<ProductVariantCardsFragment>>;
  categories?: Array<PPCDegreeCategory>;
  displayVariantCards: boolean;
  listView?: boolean;
  heading?: string;
};

type CategoriesBySlug = { [id: string]: PPCDegreeCategory };

const styles = {
  heading: () => css`
    margin-bottom: var(--cds-spacing-200);
  `,

  categoryCardList: () => css`
    margin-bottom: var(--cds-spacing-600);

    ${breakpoints.down('sm')} {
      display: block;
    }
  `,
  categoryCardItem: () => css`
    ${breakpoints.down('sm')} {
      display: inline-block;
    }

    > div {
      /* The div added by TrackedLink2 needs to be full height of the parent to align the text correctly */
      height: 100%;
    }
  `,
};

type FormattedCategoryLink = ProductVariantCardsFragment &
  PPCDegreeCategory & {
    // eslint-disable-next-line react/no-unused-prop-types
    href: string;
  };

// Merges the categoryLinks data from Contentful with categories data from our API
const getFormattedCategoryLinks = (
  categoryLinks: Maybe<ProductVariantCardsFragment>[],
  categories: Maybe<PPCDegreeCategory>[]
): FormattedCategoryLink[] => {
  // Create a lookup for faster access
  const categoriesBySlug = categories?.reduce((acc: CategoriesBySlug, curr: Maybe<PPCDegreeCategory>) => {
    if (curr?.slug) {
      acc[curr.slug] = curr;
    }
    return acc;
  }, {});

  // Filter out any null or undefined values, or entries that don't have a title and callToActionLink
  const filteredCategoryLinks: ProductVariantCardsFragment[] = categoryLinks.filter(
    (item: Maybe<ProductVariantCardsFragment>): item is ProductVariantCardsFragment =>
      !!item && 'title' in item && 'callToActionLink' in item
  );

  // Return the category links with the additional degreeCount property
  return filteredCategoryLinks.map((marketingData: ProductVariantCardsFragment) => {
    const href =
      marketingData?.callToActionLink && marketingData?.callToActionLink.includes(config.url.base)
        ? marketingData?.callToActionLink
        : buildDegreeUrlRelative(marketingData?.callToActionLink || '');
    const slug = getSlugFromHref(href);
    const degreeData = categoriesBySlug?.[slug];

    return {
      ...marketingData,
      ...degreeData,
      href,
    };
  });
};

const DegreesCategoryCards: React.FunctionComponent<DegreeCategoryCardProps> = ({
  categoryLinks = [],
  productVariantCards = [],
  categories = [],
  displayVariantCards,
  listView,
  heading,
}) => {
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const allCategoryLinks: Maybe<ProductVariantCardsFragment>[] = [
    ...categoryLinks,
    ...(productVariantCards && displayVariantCards ? productVariantCards : []),
  ];

  const sectionTitle = heading || _t('Browse by category');

  const browseByRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName: 'browse_by',
      sectionTitle,
      index: sectionIndex,
    },
  });

  if (!allCategoryLinks || !allCategoryLinks.length) {
    return null;
  }

  const gridSizeOfDegreesPerRow = 3;
  let categoryLinksWithCounts: FormattedCategoryLink[] = getFormattedCategoryLinks(allCategoryLinks, categories);
  categoryLinksWithCounts = categoryLinksWithCounts.filter(
    (category) => !['MANAGEMENT', 'SOCIAL_SCIENCES'].includes(category.id)
  );

  return (
    <div className="rc-DegreesCategoryCards" data-e2e="DegreesCategoryCards" ref={browseByRef}>
      <Typography variant="h1semibold" css={styles.heading} component="h2">
        {sectionTitle}
      </Typography>
      <Grid container spacing={16} css={styles.categoryCardList} role="list" data-testid="degrees-category-card-list">
        {categoryLinksWithCounts.map(({ title, href, slug }: FormattedCategoryLink) => (
          <Grid
            item
            xs={12}
            md={listView ? 6 : gridSizeOfDegreesPerRow}
            lg={listView ? 12 : gridSizeOfDegreesPerRow}
            key={title}
            css={styles.categoryCardItem}
            role="listitem"
            alignContent="center"
          >
            <CategoryCard slug={slug} callToActionLink={href} title={title || ''} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default React.memo(DegreesCategoryCards);
