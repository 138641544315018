import type { ElementType, ReactElement } from 'react';
import * as React from 'react';
import type { ReactMarkdownProps } from 'react-markdown';
import Markdown from 'react-markdown';

import classNames from 'classnames';

import { SvgCheck } from '@coursera/coursera-ui/svg';
import type { ButtonName, PageSection, SectionName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { isExternalLink } from 'bundles/common/utils/urlUtils';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import 'css!bundles/cms/components/__styles__/Markdown';

type Props = Omit<ReactMarkdownProps, 'source'> & {
  source?: string | null;
  className?: string;
  linkTarget?: string;
  onLinkClick?: () => void;
  trackingName?: string;
  buttonTrackingNameV3?: ButtonName;
  trackingSection?: SectionName;
  trackingSubSection?: PageSection['subsectionName'];
  trackingIndex?: number;
  trackingProductId?: string;
  rel?: string;
  showSvgCheck?: boolean;
  renderers?: { [nodeType: string]: ElementType };
};

const textToSlug = (text: React.ReactChild) =>
  text
    .toString()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9- ]/g, '')
    .replace(/ +/g, '-');

const Heading = ({ children, level }: { children?: React.ReactChild[]; level: number }) => {
  if (!children) {
    return null;
  }

  const slug = textToSlug(children[0]);

  // Implemented due to unidentified Typescript issues with dynamically generated tags and children props
  switch (level) {
    case 1:
      return <h1 id={slug}>{children}</h1>;
    case 2:
      return <h2 id={slug}>{children}</h2>;
    case 3:
      return <h3 id={slug}>{children}</h3>;
    case 4:
      return <h4 id={slug}>{children}</h4>;
    case 5:
      return <h5 id={slug}>{children}</h5>;
    case 6:
    default:
      return <h6 id={slug}>{children}</h6>;
  }
};

type ListItemProps = {
  children?: Array<string | ReactElement>;
};

const renderListItem = (props: ListItemProps, showSvgCheck: boolean | undefined) => {
  const { children } = props;

  if (!children || !children.length) {
    return null;
  }
  const listItemArrayFirstItem = children[0];
  let key;
  // Means the list item include at least one React Element, e.g. <strong></strong>, then we need to use the text content as the key
  if (typeof listItemArrayFirstItem === 'object' && !!listItemArrayFirstItem?.props?.children?.[0]) {
    key = listItemArrayFirstItem.props.children[0];
    // This case helps account for invalid formatting from Contentful. Sometimes it will not have a "child", but will have "value".
  } else if (typeof listItemArrayFirstItem === 'object' && !!listItemArrayFirstItem?.props?.value) {
    key = listItemArrayFirstItem.props.value;
  } else if (typeof listItemArrayFirstItem === 'string') {
    // string should handle all other cases
    key = listItemArrayFirstItem;
  }
  return (
    // Ignore eslint error to enable VoiceOver: https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <li role="listitem" key={key}>
      {showSvgCheck && (
        <SvgCheck
          className="checkIcon"
          suppressTitle={true}
          width="24px"
          height="24px"
          style={{
            left: '0',
            marginRight: '1rem',
            marginTop: '-1px',
            position: 'absolute',
            top: '0',
            fill: '#1f8354',
          }}
        />
      )}
      {children}
    </li>
  );
};

type ListProps = {
  ordered: boolean;
  children?: React.ReactElement<ListItemProps>[];
};

const renderList = (props: ListProps, showSvgCheck: boolean | undefined) => {
  const { children, ordered } = props;
  if (!children || !children.length) {
    return null;
  }

  const Tag = ordered ? 'ol' : 'ul';

  // Ignore to enable VoiceOver: https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
  return <Tag role="list">{children.map((child) => renderListItem(child.props, showSvgCheck))}</Tag>;
};

type ComponentProps<T> = T extends React.Component<infer P> ? P : never;

function MarkdownRender({
  source,
  className,
  trackingName,
  linkTarget = '_blank',
  onLinkClick,
  buttonTrackingNameV3,
  trackingSection,
  trackingSubSection,
  trackingIndex,
  trackingProductId,
  rel,
  showSvgCheck,
  renderers,
  ...markdownProps
}: Props) {
  const track = useTracker();
  if (!source) {
    return null;
  }

  const customRenderers = {
    link: (props: ComponentProps<TrackedA>) => {
      const { href } = props;

      const linkType = typeof href === 'string' && isExternalLink(href) ? 'external' : 'internal';

      const handleOnClick = () => {
        if (buttonTrackingNameV3) {
          track('click_button', {
            button: {
              name: buttonTrackingNameV3,
              linkURL: href,
              linkType,
            },
            ...(trackingSection && {
              pageSection: {
                sectionName: trackingSection,
                ...(trackingSubSection && {
                  subsectionName: trackingSubSection,
                }),
                ...(trackingIndex && {
                  subindex: trackingIndex,
                }),
              },
            }),
            ...(trackingProductId && {
              product: {
                id: trackingProductId,
              },
            }),
          });
        }
        if (onLinkClick) {
          onLinkClick();
        }
      };

      return (
        <TrackedA
          {...props}
          trackingName={trackingName || 'link_in_contentful'}
          data={{ href }}
          onClick={handleOnClick}
          rel={rel}
        />
      );
    },
    heading: Heading,
    list: (props: ListProps) => renderList(props, showSvgCheck),
    ...renderers,
  };

  return (
    <Markdown
      {...markdownProps}
      className={classNames('rc-Markdown styled', className)}
      source={source}
      linkTarget={linkTarget}
      renderers={customRenderers}
    />
  );
}

export default MarkdownRender;
