/* eslint-disable jsx-a11y/no-redundant-roles */
import * as React from 'react';

import initBem from 'js/lib/bem';

import { Grid, Typography2 } from '@coursera/cds-core';

import type { GlobalFooterSubFooterLink } from 'bundles/page-footer/components/seo-sub-footer/types';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import 'css!./__styles__/SubFooterSection';

type DirectoryLinkProps = {
  label: string;
  href: string;
  target?: string;
};

type SubFooterSectionProps = {
  header: string;
  links: Array<GlobalFooterSubFooterLink>;
};

const bem = initBem('SubFooterSection');

const DirectoryLink = ({ label, ...anchorProps }: DirectoryLinkProps) => {
  return (
    <li className={bem('content-column-link-item', '', 'lohp-rebrand')} role="listitem">
      <TrackedA className={bem('content-column-link-text')} {...anchorProps} trackingName="seo_directory_link">
        {label}
      </TrackedA>
    </li>
  );
};

const SubFooterSection = ({ header, links }: SubFooterSectionProps) => {
  return (
    <Grid item sm={3} className="rc-SubFooterSection lohp-rebrand">
      <Typography2 variant="subtitleMedium" component="p" className={bem('header', '', 'lohp-rebrand')}>
        {header}
      </Typography2>
      <ul className={bem('content-column-links')} role="list">
        {links
          .filter((link) => !!link)
          .map((link) => (
            <DirectoryLink key={link.href} {...link} />
          ))}
      </ul>
    </Grid>
  );
};

export default SubFooterSection;
