import { isNull, isUndefined, mergeWith } from 'lodash';

import { processImage } from 'js/lib/imgix';
import { cmsSupportedLanguageSubdomains, getCmsLocaleString, languageCodeToName } from 'js/lib/language';
import { MOMENT_FORMATS, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { domainBackgroundScoreMap } from 'bundles/browse/constants';
import {
  buildCourseUrlAbsolute,
  buildDegreeUrlAbsolute,
  buildDegreeUrlRelative,
  buildS12nsUrlAbsolute,
} from 'bundles/common/utils/urlUtils';
import countries from 'bundles/page/common/countries';
import type { LearnMoreValuePropositionUnifiedFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import {
  APPLICATION_DATE_TIMEZONE_OVERRIDE,
  CertificateCategory,
  HubPages,
  PRODUCT_VARIANT_TO_PRODUCT_NAME,
  ProductType,
  ProductVariant,
  SALESFORCE_PARTNER,
  UnifiedHubProductVariant,
} from 'bundles/premium-hub/constants';
import type { GetCoursesByIds_CourseFragment as Course } from 'bundles/premium-hub/queries/__generated__/getCoursesByIds';
import type { GetSpecializationsByIds_SpecializationFragment as Specialization } from 'bundles/premium-hub/queries/__generated__/getSpecializationsByIds';
import type {
  AtAGlanceProps,
  Degree,
  DegreeCardV2Props,
  DegreeContentfulMetadata,
  DegreeWithMetadata,
  EoiProductType,
  PremiumProductWithMetadata,
  UnifiedProductCardProps,
} from 'bundles/premium-hub/types';
import { getProgramDetailsText } from 'bundles/premium-hub/utils/CatalogDegreeAPIUtils';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import _t from 'i18n!nls/premium-hub';

export const filterNullOrUndefined = <T>(x: T | null | undefined): x is T => {
  return !!x;
};

export const getSlugFromHref = (href: string) => {
  let url = href;

  if (href.lastIndexOf('/') === href.length - 1) {
    url = href.slice(0, -1);
  }
  const lastIndex = url.lastIndexOf('/');
  const slug = url.substr(lastIndex + 1);

  return slug;
};

export const getUrlFromProductVariant = (productVariant: string, slug: string) => {
  const urls = {
    [UnifiedHubProductVariant.ProfessionalCertificate]: `/professional-certificates/${slug}`,
    [UnifiedHubProductVariant.Mastertrack]: `/mastertrack/${slug}`,
    [UnifiedHubProductVariant.UniversityCertificate]: `/certificates/${slug}`,
    [UnifiedHubProductVariant.GraduateCertificate]: `/certificates/${slug}`,
    [UnifiedHubProductVariant.Degree]: `/degrees/${slug}`,
  };

  return urls[productVariant];
};

export const getNameFromProductVariant = (degreeType: $TSFixMe): string => {
  switch (degreeType) {
    case ProductVariant.BachelorsDegree:
      return _t("Bachelor's Degree");
    case ProductVariant.MastersDegree:
      return _t("Master's Degree");
    case ProductVariant.PostgraduateDiploma:
      return _t('Postgraduate Diploma');
    default:
      return '';
  }
};

export const getOverviewListTitle = (degreeType: $TSFixMe): string => {
  switch (degreeType) {
    case ProductVariant.BachelorsDegree:
      return _t("Bachelor's Degrees");
    case ProductVariant.MastersDegree:
      return _t("Master's Degrees");
    case ProductVariant.PostgraduateDiploma:
      return _t('Postgraduate Diplomas');
    default:
      return '';
  }
};

export const getFinalPartnerInfo = (partner: $TSFixMe, slug: $TSFixMe) => {
  // Need to hardcode salesforce partner for now to get ready for launch
  const partnerInfo =
    slug === 'sales-development-representative' || slug === 'salesforce-sales-operations'
      ? SALESFORCE_PARTNER
      : partner;

  const partnerLogo = processImage(partnerInfo?.squareLogo);
  const partnerText = _t('Offered by #{partnerName}', { partnerName: partnerInfo?.name });

  return {
    partnerName: partnerInfo?.name,
    partnerLogo,
    partnerText,
  };
};

export const getProductOfferingInfo = (product: $TSFixMe, productType: $TSFixMe) => {
  const { id, name, productVariant, slug, partner, description, duration, languageCode } = product;

  const url = getUrlFromProductVariant(productVariant, slug);
  const productName = PRODUCT_VARIANT_TO_PRODUCT_NAME[productVariant];

  const partnerInfo = getFinalPartnerInfo(partner, slug);
  const title = productName ? _t('#{name} #{productName}', { name, productName }) : name;

  const linkText = _t('Go to #{productType}', {
    productType: productType === ProductType.Certificates ? 'certificate' : 'degree',
  });

  return {
    id,
    name,
    title,
    slug,
    url,
    productName,
    partnerInfo,
    programDetails: duration,
    programSummary: description,
    linkText,
    languageCode,
  };
};

export const getDegreeProductOfferingInfo = (
  degree: Degree,
  allDegreeContentfulData?: Array<{ slug: string; atAGlance: AtAGlanceProps }>
) => {
  const degreeSlug =
    degree.slug === 'masters-in-computer-data-science' ? 'master-of-computer-science-illinois' : degree.slug;
  const degreeContentfulData = allDegreeContentfulData?.find((degreeData) => degreeData.slug === degreeSlug);
  const degreeLink = buildDegreeUrlRelative(degreeSlug);
  const programDetails = getProgramDetailsText(
    degreeContentfulData?.atAGlance?.tuition,
    degreeContentfulData?.atAGlance?.timeFrame
  );

  const dueDate = degree?.degreeUpcomingTermDates?.nextAdmissionSubtermDeadlineDate;
  const formattedDueDate = dueDate
    ? formatDateTimeDisplay(dueDate, MOMENT_FORMATS.LONG_DATE_ONLY_DISPLAY, APPLICATION_DATE_TIMEZONE_OVERRIDE)
    : undefined;
  const programDeadlineText = formattedDueDate
    ? `${_t('Application due #{formattedDueDate}', { formattedDueDate })}`
    : undefined;

  const firstPartner = degree.partners?.elements[0];
  const partnerInfo = getFinalPartnerInfo(firstPartner, degree.slug);

  return {
    programSummary: degreeContentfulData?.atAGlance?.ranking,
    slug: degreeSlug,
    url: degreeLink,
    title: degree.marketingName ?? '',
    partnerInfo,
    programDetails,
    programDeadlineText,
    languageCode: degree.languageCode,
    name: degree.name,
    id: degree.id,
  };
};

export const getCountryNameFromCode = (countryCode?: string | null): string | undefined =>
  countries.find((country: { code: string; name: string }) => country.code.toLowerCase() === countryCode?.toLowerCase())
    ?.name;

export const buildFooterStrings = (product: UnifiedProductCardProps): string[] => {
  const isCourse = product?.productVariant === 'Course';
  const isSpecialization = product?.productVariant?.includes('S12N');
  const isDegree = !isCourse && !isSpecialization;
  const footerStrings = [];
  if (!isDegree && product?.difficultyLevel) footerStrings.push(product.difficultyLevel);
  if (product?.productVariant) {
    if (isDegree) {
      footerStrings.push(
        product.productVariant === 'BachelorsDegree' ? _t("Bachelor's Degree") : _t("Master's Degree")
      );
    } else if (isSpecialization) {
      footerStrings.push(
        product.productVariant === 'PROFESSIONAL_CERTIFICATE_S12N' ? 'Professional Certificate' : 'Specialization'
      );
    } else {
      footerStrings.push(product.productVariant);
    }
  }
  if (product?.programTimeFrame) footerStrings.push(product.programTimeFrame);
  return footerStrings;
};

export const getSkillsFromCourse = (course: Course): string | undefined => {
  const skills: string[] = [];
  course?.domains?.forEach((domain) => {
    if (domain?.subdomainSlug) skills?.push(domain?.subdomainSlug);
  });
  skills?.filter(filterNullOrUndefined);
  return skills && skills?.length ? _t("Skills you'll gain: #{skills}", { skills: skills.join(', ') }) : undefined;
};

export const getCourseProductCardProps = (course: Course): UnifiedProductCardProps => {
  const partnerInfo = {
    partnerName: course?.partners?.[0]?.name,
    partnerLogo: course?.partners?.[0]?.squareLogo,
  };
  const programSummary = getSkillsFromCourse(course);

  return {
    id: course?.id,
    title: course?.name,
    slug: course.slug,
    bannerImage: course.headerImageUrl || undefined,
    // @ts-expect-error URL: string
    partnerInfo,
    productVariant: 'Course',
    programSummary,
    programTimeFrame: course?.estimatedWorkload,
    difficultyLevel: course?.difficultyLevel || undefined,
  };
};

export const getSpecializationProductCardProps = (s12n: Specialization): UnifiedProductCardProps => {
  const partnerInfo = {
    partnerName: s12n?.partners?.[0]?.name,
    partnerLogo: s12n?.partners?.[0]?.squareLogo,
  };

  return {
    id: s12n?.id,
    title: s12n?.name,
    slug: s12n?.slug,
    bannerImage: s12n?.cardImageUrl,
    // @ts-expect-error URL: string
    partnerInfo,
    productVariant: s12n?.productVariant,
    programSummary: s12n?.tagline,
    programTimeFrame: s12n?.hoursPerWeekOverride
      ? // eslint-disable-next-line no-restricted-syntax
        _t('#{timeFrame} hours per week', { timeFrame: s12n?.hoursPerWeekOverride })
      : undefined,
    difficultyLevel: s12n?.difficultyLevel?.[0] + s12n?.difficultyLevel?.slice(1)?.toLowerCase(),
  };
};

export const getDegreeProductCardProps = (degree: PremiumProductWithMetadata): UnifiedProductCardProps => {
  const deadlineDateText =
    degree.upcomingTermDates?.nextAdmissionDeadlineDate?.seconds &&
    formatDateTimeDisplay(
      parseInt(degree.upcomingTermDates?.nextAdmissionDeadlineDate?.seconds, 10) * 1000,
      MOMENT_FORMATS.LONG_DATE_ONLY_DISPLAY,
      APPLICATION_DATE_TIMEZONE_OVERRIDE
    );
  const firstPartner = degree.partners?.elements[0];
  const finalPartnerInfo = getFinalPartnerInfo(firstPartner, degree.slug);
  const partnerInfo = {
    partnerName: finalPartnerInfo?.partnerName,
    partnerLogo: finalPartnerInfo?.partnerLogo,
    country: getCountryNameFromCode(firstPartner?.location?.country),
  };
  const language = degree.languageCode ? languageCodeToName(degree.languageCode) : undefined;
  const programDeadlineText = deadlineDateText
    ? _t('Application due #{deadlineDateText}', { deadlineDateText })
    : undefined;

  return {
    id: degree.id,
    title: degree.name,
    slug: degree.slug,
    bannerImage: degree.bannerImage || undefined,
    partnerInfo,
    productVariant: degree.productVariant ?? undefined,
    programSummary: degree.atAGlance?.ranking,
    programDeadlineText,
    programTimeFrame: degree.atAGlance?.timeFrame,
    languageCode: degree.languageCode ?? undefined,
    language,
  };
};

export const getDegreeCardV2Props = (degree: PremiumProductWithMetadata | DegreeWithMetadata): DegreeCardV2Props => {
  const programTimeFrame = degree.atAGlance?.timeFrame;

  let deadlineDateText = null;

  if ('upcomingTermDates' in degree) {
    deadlineDateText =
      degree?.upcomingTermDates?.nextAdmissionSubtermDeadlineDate?.seconds &&
      formatDateTimeDisplay(
        parseInt(degree?.upcomingTermDates?.nextAdmissionSubtermDeadlineDate?.seconds, 10) * 1000,
        MOMENT_FORMATS.LONG_DATE_ONLY_DISPLAY,
        APPLICATION_DATE_TIMEZONE_OVERRIDE
      );
  } else {
    const degreeWithMetadata = degree as DegreeWithMetadata;
    deadlineDateText =
      degreeWithMetadata.degreeUpcomingTermDates?.nextAdmissionSubtermDeadlineDate &&
      formatDateTimeDisplay(
        degreeWithMetadata.degreeUpcomingTermDates.nextAdmissionSubtermDeadlineDate,
        MOMENT_FORMATS.LONG_DATE_ONLY_DISPLAY,
        APPLICATION_DATE_TIMEZONE_OVERRIDE
      );
  }

  const programDeadlineText = deadlineDateText ? `${_t('Application due')} ${deadlineDateText}` : undefined;

  const programLanguage = degree.languageCode && languageCodeToName(degree.languageCode);

  const firstPartner = degree.partners?.elements[0];
  const partnerInfo = getFinalPartnerInfo(firstPartner, degree.slug);
  const programCountry = getCountryNameFromCode(firstPartner?.location?.country);

  return {
    title: degree?.marketingName ?? '',
    partnerInfo,
    programCountry,
    programLanguage,
    languageCode: degree?.languageCode ?? '',
    programSummary: degree?.atAGlance?.ranking,
    programTimeFrame,
    programDeadlineText,
  };
};

export const getPrimaryImage = (slug: $TSFixMe) => {
  let domainId;
  switch (slug) {
    case 'public-health':
      domainId = 'life-sciences';
      break;
    case 'management':
      domainId = 'business';
      break;
    case 'data-analytics':
      domainId = 'data-science';
      break;
    case 'computer-science-it':
      domainId = 'computer-science';
      break;
    default:
      domainId = slug;
  }
  return domainBackgroundScoreMap[domainId]?.url;
};

export const getTitleForCategory = (category: $TSFixMe) => {
  switch (category) {
    case CertificateCategory.Entry:
      return _t('Launch your career');
    case CertificateCategory.Advanced:
      return _t('Advance your career');
    case CertificateCategory.Certification:
      return _t('Prepare for a Certification');
    case CertificateCategory.DataScience:
      return _t('Earn a Data Science Certificate');
    case CertificateCategory.Business:
      return _t('Earn a Business Certificate');
    case CertificateCategory.ComputerScience:
      return _t('Earn a Computer Science or IT Certificate');
    default:
      return '';
  }
};

export const getSlugForCategory = (category: $TSFixMe) => {
  switch (category) {
    case CertificateCategory.Entry:
      return 'launch-your-career';
    case CertificateCategory.Advanced:
      return 'advance-your-career';
    case CertificateCategory.Certification:
      return 'prepare-for-a-certification';
    case CertificateCategory.DataScience:
      return 'data-science';
    case CertificateCategory.Business:
      return 'business';
    case CertificateCategory.ComputerScience:
      return 'computer-science-it';
    default:
      return '';
  }
};

type Variant = (typeof UnifiedHubProductVariant)[keyof typeof UnifiedHubProductVariant];

export const getTitleForVariant = (variant: Variant) => {
  switch (variant) {
    case UnifiedHubProductVariant.ProfessionalCertificate:
      return _t('Professional Certificates');
    case UnifiedHubProductVariant.Mastertrack:
      return _t('MasterTrack Certificates');
    case UnifiedHubProductVariant.UniversityCertificate:
      return _t('University Certificates');
    case UnifiedHubProductVariant.GraduateCertificate:
      return _t('Graduate Certificates');
    default:
      return '';
  }
};

export const mapToDegreeContentfulSlug = (slug: string) =>
  slug === 'masters-in-computer-data-science' ? 'master-of-computer-science-illinois' : slug;

export const getI18nLocaleStringToUse = (listOfSupportedi18nLocales: Array<string>): string => {
  let i18nLocaleStringToUse: string | undefined;
  const cmsLocaleString = getCmsLocaleString();

  if (listOfSupportedi18nLocales) {
    i18nLocaleStringToUse = listOfSupportedi18nLocales.find(
      (localeString) => cmsSupportedLanguageSubdomains[localeString] === cmsLocaleString
    );
  }

  return i18nLocaleStringToUse ? cmsSupportedLanguageSubdomains[i18nLocaleStringToUse] : 'en-US';
};

/**
 * Converts a string in seconds to milliseconds as a number. Ex: `secondsStringToMillis("5") === 5000`
 * @param seconds
 * @returns A number of milliseconds equal to the seconds passed in
 */
export const secondsStringToMillis = (seconds: string) => parseInt(seconds, 10) * 1000;

/**
 *
 * @param oldDdpAtAGlanceItems At-a-glance data from the old DDP
 * @param redesignDdpAtAGlanceItems At-a-glance data from the redesigned DDP
 * @returns Combined at-a-glance data. If both old and redesigned DDPs have at-a-glance data, the data from the redesigned DDP will be favored
 */
export const combineOldAndRedesignAtAGlance = (
  oldDdpAtAGlanceItems: DegreeContentfulMetadata[] | null | undefined,
  redesignDdpAtAGlanceItems: DegreeContentfulMetadata[] | null | undefined
) => {
  if (!redesignDdpAtAGlanceItems) return oldDdpAtAGlanceItems ?? undefined;
  if (!oldDdpAtAGlanceItems) return redesignDdpAtAGlanceItems ?? undefined;

  const mergedItems: DegreeContentfulMetadata[] = [];

  const oldItemsMap = new Map<string, DegreeContentfulMetadata>();
  const redesignItemsMap = new Map<string, DegreeContentfulMetadata>();
  const allSlugsSet = new Set<string>();

  oldDdpAtAGlanceItems.forEach((item) => {
    if (item?.slug) {
      oldItemsMap.set(item.slug, item);
      allSlugsSet.add(item.slug);
    }
  });

  redesignDdpAtAGlanceItems.forEach((item) => {
    if (item?.slug) {
      redesignItemsMap.set(item.slug, item);
      allSlugsSet.add(item.slug);
    }
  });

  allSlugsSet.forEach((slug) => {
    const oldItem = oldItemsMap.get(slug);
    const redesignItem = redesignItemsMap.get(slug);
    const mergedItem = mergeWith({}, oldItem, redesignItem, (a, b) => (isNull(b) || isUndefined(b) ? a : undefined));
    mergedItems.push(mergedItem);
  });

  return mergedItems;
};

export const getSurveyProductType = (currentPageType: PremiumHubPageType): EoiProductType => {
  switch (currentPageType) {
    case HubPages.DegreesCategoryHubPage:
      return ProductTypes.DEGREE_CATEGORY as EoiProductType;

    case HubPages.ProductVariantHubPage:
      return ProductTypes.GENERIC_PAGE as EoiProductType;

    case HubPages.LandingPage:
      return ProductTypes.DEGREE_CATEGORY as EoiProductType;

    default:
      return ProductTypes.GENERIC_PAGE as EoiProductType;
  }
};

export const consolidateS12nSlugsFromMultipleItems = (valueProps?: LearnMoreValuePropositionUnifiedFragment[]) => {
  const specializationSlugs: string[] = [];
  valueProps?.forEach((valueProp) => {
    valueProp?.relatedSpecializationSlugs?.filter(filterNullOrUndefined)?.forEach((specializationSlug) => {
      if (!specializationSlugs.includes(specializationSlug)) specializationSlugs.push(specializationSlug);
    });
  });
  return specializationSlugs;
};

export const consolidateCourseSlugsFromMultipleItems = (valueProps?: LearnMoreValuePropositionUnifiedFragment[]) => {
  const courseSlugs: string[] = [];
  valueProps?.forEach((valueProp) => {
    valueProp?.relatedCourseSlugs?.filter(filterNullOrUndefined)?.forEach((courseSlug) => {
      if (!courseSlugs.includes(courseSlug)) courseSlugs.push(courseSlug);
    });
  });
  return courseSlugs;
};

export const buildValuePropCardUrl = (slug?: string, productVariant?: string) => {
  const isCourse = productVariant === 'Course';
  const isSpecialization = productVariant?.includes('S12N');
  if (isCourse) return buildCourseUrlAbsolute(slug ?? '');
  if (isSpecialization) return buildS12nsUrlAbsolute(slug ?? '');
  return buildDegreeUrlAbsolute(slug ?? '');
};
