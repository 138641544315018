import contentfulGql from 'js/lib/contentfulGql';

export const getAllDegreesAtAGlance = contentfulGql`#graphql
  query AtAGlanceQuery(
    $slugs: [UniversityProgramPageContentFilter]
    $locale: String!
    $preview: Boolean
  ) {
    universityProgramPageContentCollection(
      locale: $locale
      preview: $preview
      where: { OR: $slugs }
    ) {
      items {
        ...UniversityProgramItem
      }
    }
  }

  fragment UniversityProgramItem on UniversityProgramPageContent {
    slug
    atAGlance {
      timeFrame
      ranking
      location
    }
    partnerInformation {
      partnerLogo {
        url
      }
      partnerMonotoneLogo {
        url
      }
    }
  }
`;

export const getAllDegreesRedesignAtAGlance = contentfulGql`#graphql
  query RedesignAtAGlanceQuery(
    $slugs: [UniversityProgramDegreeDescriptionPageFilter]
    $locale: String!
    $preview: Boolean
  ) {
    universityProgramDegreeDescriptionPageCollection(
      locale: $locale
      preview: $preview
      where: { OR: $slugs }
    ) {
      items {
        ...DegreeDescriptionPageItem
      }
    }
  }

  fragment DegreeDescriptionPageItem on UniversityProgramDegreeDescriptionPage {
    slug
    atAGlance {
      timeFrame
      ranking
      location
    }
    partner {
      partnerLogo {
        url
      }
      partnerMonotoneLogo {
        url
      }
    }
  }
`;

export const getAllCertifcateContentfulData = contentfulGql`#graphql
query AllCertificatesData($slugs: [DegreesHubDegreeMarketingInformationFilter]!, $locale: String!) {
  degreesHubDegreeMarketingInformationCollection(locale: $locale, where: { OR: $slugs }) {
    items {
      slug
      description
      durationDescription
    }
  }
}
`;

export const getPremiumCertificateData = contentfulGql`#graphql
  query PremiumCertificateData(
    $slugs: [PremiumCertificatePageContentFilter]!
    $locale: String!
  ) {
    premiumCertificatePageContentCollection(
      locale: $locale
      where: { OR: $slugs }
    ) {
      items {
        sys {
          publishedAt
          firstPublishedAt
        }
        atAGlance {
          programEnrollmentDate
          timeFrame
        }
        slug
        programDescriptionSubtitle
      }
    }
  }
`;

export const getContentfulSeoData = contentfulGql`#graphql
query getContentfulSeoData($pageLocation: String!, $preview: Boolean!) {
  seoDataCollection(preview: $preview, where: {pageLocation: $pageLocation}) {
    items {
      i18NSupportedLocales
    }
  }
}
`;

export const VisualConsistencyFieldsFragmentDoc = contentfulGql`#graphql
  fragment VisualConsistencyFields on VisualConsistency {
    testimonialHeader
    testimonialListCollection {
      items {
        ...TestimonialVisualConsistency
      }
    }
    instructorHeader
    instructorListCollection {
      items {
        ...InstructorProfileVisualConsistency
      }
    }
    topTopicsHeader
    topTopicsListCollection {
      items {
        ...ArticleItem
      }
    }
  }



  fragment ArticleItem on Article {
    title
    slug
    intro
    sys {
      publishedAt
    }
  }

  fragment InstructorProfileVisualConsistency on InstructorProfile {
    id
    slug
    name
    position
    partner
    program
    programSlug
    introduction
    profilePicture {
      url
    }
    partnerLogo {
      url
    }
  }

  fragment TestimonialVisualConsistency on UniversityProgramTestimonial {
    entryTitle
    text
    videoEmbedUrl
    videoFile {
      sys {
        id
      }
      title
      description
      width
      height
      url
    }
    audioDescriptionTrack {
      title
      url
      sys {
        id
      }
    }
    authorName
    authorTitle
    authorOrganization
    image {
      sys {
        id
      }
      title
      description
      width
      height
      url
    }
    videoPlaceholderImage {
      sys {
        id
      }
      title
      description
      width
      height
      url
    }
  }
`;

export const HeroValuePropFieldsFragmentDoc = contentfulGql`#graphql
  fragment HeroValuePropFields on PremiumHubHeroValueProp {
    heading
    subheading
    heroImage {
      url
    }
    subBenefitsCollection {
      items {
        ...SubBenefitsItem
      }
    }
  }

  fragment SubBenefitsItem on GeneralIconLabel {
    iconLabel
    iconVariant
  }
`;

export const HubPageContentFieldsFragmentDoc = contentfulGql`#graphql
fragment HubPageContentFields on ProgramHubPageContent {
  pageId
  slug
  useSimplifiedHeader
  pageHeading
  pageSubheading
  associatedProgramsHeading
  moduleOrder
  
  relatedArticles {
    ...RelatedArticlesFields
  }

  ...CategoryIntroFields
  productVariantCardsCollection {
    items {
      ...ProductVariantCards
    }
  }
  
  seoInformation {
    metaTitle
    metaDescription
    enableCourseraSuffix
    enableCourseraDescriptionSuffix
    enableDescriptionLimit
  }
  expressionOfInterestContainer {
    ...ExpressionOfInterestContainer
  }
  learnMoreInfoHeading
  learnMoreCardsCollection {
    items {
      ...InfoCard
    }
  }
  learnMoreValuePropositionsCollection {
      items {
        ...LearnMoreValueProposition
      }
  }
  learningCommunityTitle
  communityStatsCollection {
    items {
      ...CommunityStats
    }
  }
  faqSectionHeader
  faqsCollection {
    items {
      question
      answer
    }
  }
  extraInformation {
    ...InfoCard
  }
  categoryLinksCollection {
    items{
      ...ProductVariantCards
    }
  }
  videoTestimonialsHeader
  primaryVideoTestimonialOverride {
    ...Testimonial
  }
  secondaryVideoTestimonialsOverrideCollection {
    items {
      ...Testimonial
    }
  }
  instructorMosaicHeader
  instructorsCollection {
    items {
      ...InstructorProfile
    }
  }
  instructorSpotlightSectionTitle
  instructorSpotlightDescription
  instructorHeadshot {
    url
  }
}

fragment ProductVariantCards on MarketingContent {
  title
  subtitle
  callToActionLabel
  callToActionLink
}

fragment ExpressionOfInterestContainer on UniversityProgramEoi {
  emailMeButtonText
  programInterestText
  emailMeFormHeaderText
  emailMeConfirmationText
}

fragment InfoCard on MarketingContent {
  title
  description
  visual {
    url
  }
  visualAlternativeText
  textOnLeft
}

fragment CommunityStats on LearnerHighlight {
  name
  profilePicture {
    url
  }
  enrolledProgram
  highlightLabel
  highlightStats
  imageOnTop
}

fragment Testimonial on UniversityProgramTestimonial {
  entryTitle
  text
  videoEmbedUrl
  authorName
  authorTitle
  authorOrganization
  image {
    url
  }
}

fragment InstructorProfile on InstructorProfile {
  id
  slug
  name
  position
  partner
  program
  introduction
  profilePicture {
    url
  }
}

fragment RelatedArticlesFields on PremiumHubRelatedContent {
  sys {
    id
  }
  title
  contentItemsCollection {
    items {
      slug
      title
      intro
      sys {
        publishedAt
      }
    }
  }
  footerLinkLabel
  footerLinkUrl
}

fragment LearnMoreValueProposition on PremiumHubValuePropositionItem{
  sys {
    id
  }
  image {
    title
    description
    width
    height
    url(transform: {
      quality: 60
    })
  }
  title
  text
  supportText,
  relatedProgramsTitle,
  relatedProgramSlugs
}

fragment CategoryIntroFields on ProgramHubPageContent{
  categoryIntroDescription
  profilePicture{
    url
  }
  learnerName
  enrolledProgram
}
`;

export const getPremiumHubPageContent = contentfulGql`#graphql
query ProgramHubPageContent($slug: String!, $locale: String!, $preview: Boolean!, $pageId: String, $pageType: String) {
  programHubPageContentCollection(preview: $preview, locale: $locale, where: { slug: $slug, pageId: $pageId, pageType: $pageType }, limit: 1) {
    items {
      ...HubPageContentFields
    }
  }
}
     ${HubPageContentFieldsFragmentDoc}`;

export const getVisualConsistencyContent = contentfulGql`#graphql
query VisualConsistencyContent($slug: String!, $locale: String!, $preview: Boolean!) {
  visualConsistencyCollection(preview: $preview, locale: $locale, where: { slug: $slug}, limit: 1) {
    items {
      ...VisualConsistencyFields
    }
  }
}
    ${VisualConsistencyFieldsFragmentDoc}`;

export const getHeroValuePropContent = contentfulGql`#graphql
query HeroValuePropContent($slug: String!, $locale: String!, $preview: Boolean!) {
  premiumHubHeroValuePropCollection(preview: $preview, locale: $locale, where: {slug: $slug}, limit: 1) {
    items {
      ...HeroValuePropFields
    }
  }
}
    ${HeroValuePropFieldsFragmentDoc}`;

export const PremiumHubAboveTheFoldFields = contentfulGql`#graphql
fragment PremiumHubAboveTheFoldFields on ProgramHubPageContent {
  countryCode
  languageCode
  variant
  appName
  pageType
  useSimplifiedHeader
  slug
  pageId
  seoInformation {
    metaTitle
    metaDescription
    enableCourseraSuffix
    enableCourseraDescriptionSuffix
    enableDescriptionLimit
  }
  coreHubHeroValuePropContent {
    heading
    subheading
    heroImage {
      url(transform: {
        quality: 60,
        width: 1100,
        format: AVIF
      })
    }
    subBenefitsCollection {
      items {
        iconLabel
        iconVariant
      }
    }
  }
  pageHeading
  pageSubheading
  moduleOrder
  expressionOfInterestContainer {
    emailMeButtonText
    programInterestText
    emailMeFormHeaderText
    emailMeConfirmationText
  }
  productVariantCardsCollection {
    items {
      title
      subtitle
      callToActionLabel
      callToActionLink
    }
  }
  learnMoreInfoHeading
  learnMoreCardsCollection {
    items {
      title
      description
      visual {
        url
      }
      visualAlternativeText
      textOnLeft
    }
  }
  extraInformation {
    title
    description
    visual {
      url
    }
    visualAlternativeText
    textOnLeft
  }
  categoryLinkHeader
  categoryLinksCollection {
    items {
      title
      subtitle
      callToActionLabel
      callToActionLink
    }
  }
  relatedArticles {
    sys {
      id
    }
    title
    contentItemsCollection {
      items {
        slug
        title
        intro
        sys {
          publishedAt
        }
      }
    }
    footerLinkLabel
    footerLinkUrl
  }
  vflpConfiguration
}`;

export const PremiumHubBelowTheFoldFields = contentfulGql`#graphql
fragment PremiumHubBelowTheFoldFields on ProgramHubPageContent {
  learnMoreValuePropositionsCollection {
    items {
      ...LearnMoreValuePropositionUnified
    }
  }
  visualConsistency {
    infoModule {
      ...InfoModuleItem
    }
    testimonialHeader
    testimonialListCollection {
      items {
        ...TestimonialVisualConsistencyFields
      }
    }
    instructorHeader
    instructorListCollection {
      items {
        ...InstructorProfileVisualConsistency
      }
    }
    topTopicsHeader
    topTopicsListCollection {
      items {
        ...ArticleItem
      }
    }
  }
  faqSectionHeader
  faqsCollection {
    items {
      question
      answer
    }
  }
}

fragment InfoModuleItem on PremiumHubInfoModule {
  moduleHeader
  moduleBody 
  highlightsHeader
  highlightsCollection {
    items {
      ...HighlightItem
    }
  }
  sidebarContent
}

fragment HighlightItem on GeneralIconLabel {
  iconLabel
  description
  iconVariant
}

fragment LearnMoreValuePropositionUnified on PremiumHubValuePropositionItem{
  sys {
    id
  }
  image {
    title
    description
    width
    height
    url(transform: {
      quality: 60
    })
  }
  title
  text
  callToActionText
  callToActionUrl
  subheading
  supportText
  relatedProgramsTitle
  relatedProgramSlugs
  relatedSpecializationSlugs
  relatedCourseSlugs
}

fragment ArticleItem on Article {
  title
  slug
  intro
  sys {
    publishedAt
  }
}

fragment PremiumHubImageAsset on Asset {
  sys { id }
  title
  description
  width
  height
  url
}

fragment PremiumHubVideoAsset on Asset {
  sys { id }
  title
  description
  width
  height
  url
}


fragment PremiumHubAudioDescriptionAsset on Asset {
  title
  url
  sys {
    id
  }
}

fragment InstructorProfileVisualConsistency on InstructorProfile {
  id
  slug
  name
  position
  partner
  program
  programSlug
  introduction
  profilePicture {
    url
  }
  partnerLogo {
    url
  }
}

fragment TestimonialVisualConsistencyFields on UniversityProgramTestimonial {
  entryTitle
  text
  videoEmbedUrl
  videoFile {
    ...PremiumHubVideoAsset
  }
  audioDescriptionTrack {
    ...PremiumHubAudioDescriptionAsset
  }
  authorName
  authorTitle
  authorOrganization
  image {
    ...PremiumHubImageAsset
  }
  videoPlaceholderImage {
    ...PremiumHubImageAsset
  }
}`;

export default { getAllDegreesAtAGlance };
