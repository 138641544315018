/** @jsx jsx */

/** @jsxFrag React.Fragment */
import type { Interpolation } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';

import type { LinkProps, Theme } from '@coursera/cds-core';
import { AccordionGroup, Divider, Grid, Link, ShowMoreList, Typography, breakpoints } from '@coursera/cds-core';
import { HelpIcon } from '@coursera/cds-icons';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import type { FaqProps, Maybe } from 'bundles/cms/types';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { urlConstants } from 'bundles/common/utils/urlUtils';
import AccordionFaq from 'bundles/premium-hub/components/degrees/redesign/AccordionFaq';
import Container from 'bundles/premium-hub/components/shared/Container';
import { MAX_FAQS_TO_SHOW } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import useTrackLinks from 'bundles/premium-hub/hooks/useTrackLinks';
import FaqsSchemaMarkup from 'bundles/seo/components/FaqsSchemaMarkup';

import _t from 'i18n!nls/premium-hub';

type Props = {
  faqs?: Array<Maybe<FaqProps>>;
  faqSectionHeader?: Maybe<string>;
};

const styles: Record<string, Interpolation<Theme>> = {
  faqContainer: css`
    margin: var(--cds-spacing-800) auto;
    max-width: ${breakpoints.values.lg}px;
  `,
  faqHeader: css`
    font-size: 28px;
    margin-bottom: var(--cds-spacing-300);
  `,
  faqAccordionGroup: css`
    padding: var(--cds-spacing-400);
    border: 2px solid var(--cds-color-grey-50);
    border-radius: var(--cds-spacing-100);

    .cds-AccordionHeader-button:focus {
      outline: 0;
    }
  `,
  divider: css`
    margin-top: var(--cds-spacing-100);
  `,
  moreQuestions: css`
    padding: 43px 32px;
    border: 2px solid var(--cds-color-grey-50);
    border-radius: var(--cds-spacing-100);
  `,
  helpIcon: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  learnerCenter: css`
    margin-top: var(--cds-spacing-200);
  `,
  showMoreList: css`
    .cds-ShowMoreList-listContainer {
      padding: var(--cds-spacing-50);
    }
  `,
  gridContainer: css`
    gap: var(--cds-spacing-400);
    ${breakpoints.up('md')} {
      flex-wrap: nowrap;
    }
  `,
};

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps>(Link);

function FaqsCms({ faqs, faqSectionHeader }: Props) {
  const handleEventingClick = useTrackLinks('degree_info_link', 'faq');
  const { index: sectionIndex } = useModuleSectionEventTrackingData();

  const faqListRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: { sectionName: 'faq', index: sectionIndex },
  });

  if (!faqs || !faqs.length) {
    return null;
  }

  const sectionHeader = faqSectionHeader || _t('Frequently Asked Questions');

  const enableShowMoreList = faqs.length > MAX_FAQS_TO_SHOW;

  const faqSection = faqs.map((faq, index) => (
    <div key={faq?.question}>
      <AccordionFaq {...faq} index={index} variant="silent" />
      {index !== faqs.length - 1 && <Divider color="light" css={styles.divider} />}
    </div>
  ));

  return (
    <Container>
      <div aria-label={sectionHeader} css={styles.faqContainer} data-testid="FAQ-container" ref={faqListRef}>
        <FaqsSchemaMarkup faqs={faqs} />
        <Typography css={styles.faqHeader} variant="h1semibold" component="h2" data-testid="FAQ-header">
          {sectionHeader}
        </Typography>
        <Grid container css={styles.gridContainer}>
          <Grid item xs={12} md={8}>
            <AccordionGroup css={styles.faqAccordionGroup} spacing={8}>
              {enableShowMoreList ? (
                <ShowMoreList
                  numItemsToShow={MAX_FAQS_TO_SHOW}
                  controllerProps={{ itemTypePlural: _t('frequently asked questions') }}
                  css={styles.showMoreList}
                >
                  {faqSection}
                </ShowMoreList>
              ) : (
                faqSection
              )}
            </AccordionGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <div css={styles.moreQuestions}>
              <HelpIcon size="large" css={styles.helpIcon} />
              <Typography variant="h2semibold">{_t('More Questions')}</Typography>
              <div css={styles.learnerCenter}>
                <TrackedLink
                  trackingName="learner_help_center_link"
                  href={`${urlConstants.learnerHelpCenterHome}/hc`}
                  aria-label={_t('Visit the learner help center')}
                  typographyVariant="subtitleMedium"
                  variant="quiet"
                  onClick={handleEventingClick}
                >
                  {_t('Visit the learner help center')}
                </TrackedLink>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default React.memo(FaqsCms);
