import * as React from 'react';

import { compose } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import type { InjectedRouter } from 'js/lib/connectToRouter';
import { useLocation } from 'js/lib/useRouter';

import { IS_PART_OF_COURSERA_PLUS } from 'bundles/search-common/constants/filter';

type InputProps = { children: React.ReactNode };
type Props = InjectedRouter & InputProps;

const RouterContext = React.createContext({});

export const useHasCourseraPlusSearchFilterParam = () => {
  const location = useLocation();

  return location?.query?.[IS_PART_OF_COURSERA_PLUS] === 'true';
};

const RouterProvider = ({ children, ...rest }: Props) => {
  return <RouterContext.Provider value={rest}>{children}</RouterContext.Provider>;
};

export default compose<Props, InputProps>(connectToRouter((props) => props))(RouterProvider);
