import { InputAdornment as MuiInputAdornment } from '@material-ui/core';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { EyeIcon, EyeHiddenIcon } from '@coursera/cds-icons';

import type { ButtonBaseProps } from '@core/ButtonBase';
import ButtonBase from '@core/ButtonBase';
import VisuallyHidden from '@core/VisuallyHidden';

import messages from './i18n';
import {
  classes,
  visibilityToggleCss,
  visibilityToggleButtonCss,
} from './passwordFieldCss';

export type Props = {
  disabled?: boolean;
  visible: boolean;
  setVisibility: (visible: boolean) => void;
} & ButtonBaseProps;

const VisibilityToggle = (props: Props) => {
  const { visible, setVisibility, disabled, ...rest } = props;
  const stringFormatter = useLocalizedStringFormatter(messages);

  return (
    <MuiInputAdornment
      disableTypography
      css={visibilityToggleCss}
      position="end"
    >
      <VisuallyHidden aria-live="polite">
        {visible
          ? stringFormatter.format('passwordIsShown')
          : stringFormatter.format('passwordIsHidden')}
      </VisuallyHidden>
      <ButtonBase
        className={clsx({
          [classes.disabled]: disabled,
        })}
        disabled={disabled}
        {...rest}
        aria-label={
          visible
            ? stringFormatter.format('hidePassword')
            : stringFormatter.format('showPassword')
        }
        css={visibilityToggleButtonCss}
        onClick={() => {
          setVisibility(!visible);
        }}
      >
        {visible ? <EyeHiddenIcon size="medium" /> : <EyeIcon size="medium" />}
      </ButtonBase>
    </MuiInputAdornment>
  );
};

export default VisibilityToggle;
