import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

const styles = css`
  padding: var(--popover-padding) var(--popover-padding) 0;

  ${breakpoints.down('xs')} {
    padding-top: 0;
  }
`;

export default styles;
