import { useLocalizedStringFormatter } from '@coursera/cds-common';

import { Popover } from '@core/Popover';
import Search from '@core/Search';
import type { SearchProps } from '@core/Search';
import Typography from '@core/Typography2';

import i18nMessages from './i18n';
import headerCss from './styles/headerCss';

export type Props = {
  isDrawer?: boolean;
  searchable?: boolean;

  /**
   * Field label. Should be the same as the label of the main control.
   */
  label: string;

  searchInputRef?: React.Ref<HTMLInputElement>;

  searchProps: SearchProps;
};

const Header = (props: Props) => {
  const { isDrawer, searchable, searchInputRef, searchProps, label } = props;

  const { inputProps, value = '', ...restSearchProps } = searchProps;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  return (
    <Popover.Header css={headerCss}>
      {isDrawer && (
        <Typography component="div" variant="subtitleMedium">
          {label}
        </Typography>
      )}
      {searchable && (
        <Search
          ref={searchInputRef}
          fullWidth
          hideSearchButton
          inputProps={{
            role: 'searchbox',
            'aria-haspopup': 'listbox',
            autoCorrect: 'off',
            spellCheck: false,
            autoComplete: 'off',
            'aria-autocomplete': 'list',
            type: 'text',
            'aria-label': stringFormatter.format('filterResults'),
            ...inputProps,
          }}
          placeholder={stringFormatter.format('filterResults')}
          value={value}
          variant="silent"
          {...restSearchProps}
        />
      )}
    </Popover.Header>
  );
};

export default Header;
