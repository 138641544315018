/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Grid, Typography2, breakpoints } from '@coursera/cds-core';

import DegreeEoiButton from 'bundles/premium-hub/components/degrees/DegreeEoiButton';
import type { HubPages } from 'bundles/premium-hub/constants';

import BrowseBannerLogo from 'images/premium-hub/BrowseBannerLogo.png';

import _t from 'i18n!nls/premium-hub';

type Props = {
  slug: string;
  productId: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  surveyProductType: string;
};

const styles = {
  browseBanner: css`
    padding: var(--cds-spacing-100);
    margin-bottom: var(--cds-spacing-200);

    ${breakpoints.up('sm')} {
      padding: var(--cds-spacing-300);
    }
  `,

  browseImage: css`
    display: block;
    width: 100%;
    height: auto;
    max-height: 192px;
    max-width: 350px;
    object-fit: cover;
    object-position: top;

    ${breakpoints.down('sm')} {
      margin: var(--cds-spacing-400) var(--cds-spacing-200);
    }
  `,

  eoiButtonContainer: css`
    /* Align the EOI button with the heading and description text */
    margin-left: calc(var(--cds-spacing-200) * -1);
  `,
};

const BrowseBanner = ({ productId, currentHubPage, surveyProductType, slug }: Props) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" css={styles.browseBanner}>
      <Grid item xs={12} sm={5} md={4} spacing={{ xs: 24, sm: 32 }}>
        <Imgix src={BrowseBannerLogo} alt="" css={styles.browseImage} loading="lazy" />
      </Grid>
      <Grid item container direction="column" spacing={16} xs={12} sm={7} md={8}>
        <Grid item>
          <Typography2 variant="subtitleLarge" component="h3">
            {_t('Looking for guidance to help navigate the application and degree decision journey?')}
          </Typography2>
        </Grid>
        <Grid item>
          <Typography2 component="p" variant="bodySecondary">
            {_t(
              'Sign up to receive emails with expert advice on program selection, application tips, financing your degree, long-term degree benefits and more. Prepare for success in pursuing and maximizing the value of your degree.'
            )}
          </Typography2>
        </Grid>
        <Grid item css={styles.eoiButtonContainer}>
          <DegreeEoiButton
            currentPageType={currentHubPage}
            slug={slug}
            productId={productId}
            surveyProductType={surveyProductType}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(BrowseBanner);
