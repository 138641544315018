import chatAvatarBotImageUrl from 'images/common/chat/chat_avatar_bot.png';
import chatAvatarHumanAgentImageUrl from 'images/common/chat/chat_avatar_human_agent_blue.svg';

import type { BoostConfig } from '../types/Boost';

type GetBoostConfigOptions = {
  startTriggerActionId?: number;
  conversationId?: string;
  pageUrl?: string;
};

function extractCssVarName(str: string) {
  const startIndex = str.indexOf('(') + 1;
  const endIndex = str.indexOf(')');
  return str.substring(startIndex, endIndex);
}

export const getBoostConfig = ({
  startTriggerActionId,
  conversationId,
  pageUrl,
}: GetBoostConfigOptions): BoostConfig => {
  // called in useEffect
  // exit with empty config
  if (typeof document === 'undefined') return {};

  const rootElement = document.documentElement;

  const rootStyles = getComputedStyle(rootElement);

  const getRawValue = (cssVar: string) => rootStyles.getPropertyValue(extractCssVarName(cssVar)).trim();

  // usage getRawVale(theme.palette.gray[100])

  return {
    chatPanel: {
      settings: {
        pageUrl, // If we let Boost get this automatically, it's always www.coursera.org without any path
        startTriggerActionId, // usually undefined, which means the bot starts the conversation at the generic welcome message.  But could be set to any Boost actionId number to have a contextual start to the conversation, e.g. based on the page the user is currently on
        conversationId, // used to resume a conversation.  If undefined or the conversation can't be found on the Boost side, a new conversation will be started.
        openTextLinksInNewTab: true,
      },
      header: {
        title: 'Chat with us',
        filters: {
          filterValues: '',
          options: [], // hide the dropdown filter selector (enabled by default so chat admins can use it).  In the future when we enable chat for partners/educators/etc, we need to use filters to ensure that they get mapped to different Saleforce origins for human chat
        },
      },
      styling: {
        avatarUrl: chatAvatarBotImageUrl,
        avatarHumanAgentUrl: chatAvatarHumanAgentImageUrl, // IMPORTANT: We only set this image URL here to coax the image to upload to S3.  The actual setting Boost reads can only be set within Boost admin panel: https://adminpanel-coursera.boost.ai/administration/integrations-config/ > Salesforce and update the setting human_chat_avatar_url
        avatarShape: 'squared',
        panelShape: 'rounded',
        pace: 'faster', // bot typing speed
        size: 'large',
        primaryColor: getRawValue('var(--cds-color-neutral-background-primary-weak)'),
        contrastColor: getRawValue('var(--cds-color-neutral-primary)'),
        fontFamily: getRawValue('var(--cds-font-family-body)'),
        chatBubbles: {
          userBackgroundColor: getRawValue('var(--cds-color-interactive-primary)'),
          userTextColor: getRawValue('var(--cds-color-neutral-primary-invert)'),
          vaBackgroundColor: getRawValue('var(--cds-color-neutral-background-primary-weak)'),
          vaTextColor: getRawValue('var(--cds-color-neutral-primary)'),
          typingBackgroundColor: getRawValue('var(--cds-color-neutral-background-primary-weak)'),
          typingDotColor: getRawValue('var(--cds-color-neutral-primary)'),
        },
        position: {
          spacingBottom: 16, // this must be a number, but theme.spacing(16) returns the string '16px' which is interpreted as 0
          spacingRight: 16,
        },
        messageFeedback: {
          hide: true, // the thumbs up/down after every bot message was considered confusing for end users because it does not affect the flow of chat.  We have final thumbs up/down feedback captured when the chat panel is closed, and many flows also ask if the bot was helpful.
        },
        buttons: {
          // We are setting button appearance via CSS style overrides because Boost config wasn't flexible enough to make buttons look like CDS secondary buttons
          // See BoostChat.tsx
          multiline: true,
          hoverBackgroundColor: getRawValue('var(--cds-color-interactive-primary-hover)'),
        },
        composer: {
          composeLengthColor: getRawValue('var(--cds-color-neutral-primary)'),
          composeLengthDisabledColor: getRawValue('var(--cds-color-neutral-stroke-primary-weak)'),
          frameBackgroundColor: getRawValue('var(--cds-color-neutral-background-primary-weak)'),
          sendButtonColor: getRawValue('var(--cds-color-interactive-primary)'),
          sendButtonDisabledColor: getRawValue('var(--cds-color-neutral-disabled)'),
          sendButtonFocusOutlineColor: getRawValue('var(--cds-color-interactive-stroke-primary-focus)'),
          textareaBorderColor: getRawValue('var(--cds-color-neutral-disabled)'),
          textareaBackgroundColor: getRawValue('var(--cds-color-neutral-primary-invert)'),
          textareaTextColor: getRawValue('var(--cds-color-neutral-primary)'),
          textareaFocusBorderColor: getRawValue('var(--cds-color-neutral-stroke-primary)'),
          // leave textareaFocusOutlineColor unspecified, otherwise Boost adds a large box shadow to the textarea
          textareaPlaceholderTextColor: getRawValue('var(--cds-color-neutral-primary-weak)'),
          topBorderColor: getRawValue('var(--cds-color-neutral-background-primary-weak)'),
          topBorderFocusColor: getRawValue('var(--cds-color-neutral-background-primary-weak)'),
        },
      },
    },
  };
};
