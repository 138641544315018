/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import { Typography } from '@coursera/cds-core';
import type { Theme, TypographyProps } from '@coursera/cds-core';

const MAX_COMMENT_LINES = 5;

type ReviewCommentProps = {
  comment: string;
  textVariant: TypographyProps['variant'];
  css?: (theme: Theme) => SerializedStyles | string;
};

const styles = {
  comment: css`
    margin: 0;
  `,
};

export const ReviewComment: React.FC<ReviewCommentProps> = ({ comment, textVariant, css: customCSS }) => {
  // Handles newline characters

  if (!comment) {
    return null;
  }

  const commentLines = comment?.split(/\\n/);
  const commentExceedsMaxLines = commentLines?.length > MAX_COMMENT_LINES;

  return (
    <>
      {commentLines.map((text) => (
        <Typography
          display={commentExceedsMaxLines ? 'inline' : 'block'}
          key={text}
          variant={textVariant}
          css={customCSS || styles.comment}
        >
          {text}
        </Typography>
      ))}
    </>
  );
};
