/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';

import config from 'js/app/config';

import { Grid, Hidden, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import type { Button } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { BasicCard } from 'bundles/cds-labs/components';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { ProductVariantCardsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import { productVariantCardsSchemaData } from 'bundles/premium-hub/utils/structuredDataUtils';
import GoogleMarkup from 'bundles/seo/components/GoogleMarkup';

import variantCardBg from 'images/premium-hub/product-variant-bg.png';

type Props = {
  title?: Maybe<string>;
  productVariantCards?: Array<Maybe<ProductVariantCardsFragment>>;
};

const styles = {
  container: css`
    margin-bottom: var(--cds-spacing-600);
  `,

  variantCardList: css`
    ${breakpoints.down('sm')} {
      display: block;
    }
  `,

  variantCarditem: css`
    ${breakpoints.down('sm')} {
      display: inline-block;
    }

    > div {
      height: 100%; /* The div added by TrackedLink2 needs to be full height of the parent to align the text correctly */
    }
  `,

  heading: css`
    margin-bottom: var(--cds-spacing-200);
  `,

  title: css`
    ${breakpoints.down('sm')} {
      font-size: var(--cds-font-size-175);
      font-weight: var(--cds-font-weight-600);
    }
  `,

  subtitle: css`
    margin-top: var(--cds-spacing-100);
  `,

  variantCard: css`
    display: block;
    background-color: var(--cds-color-emphasis-primary-background-xweak);
    background-image: url(${variantCardBg});
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: bottom right;
    padding: 40px var(--cds-spacing-400);
    transition: all 0.2s ease-in-out;
    height: 100%;

    ${breakpoints.down('sm')} {
      background-image: none;
      border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
      padding: var(--cds-spacing-150);
    }
  `,

  unifiedVariantCardTitle: css`
    ${breakpoints.down('sm')} {
      font-size: var(--cds-font-size-175);
      font-weight: var(--cds-font-weight-600);
    }
  `,
};

const sectionName = 'browse_by';

export const ProductVariantCard: React.FunctionComponent<ProductVariantCardsFragment> = ({
  title,
  subtitle,
  callToActionLink,
}) => {
  const trackV3 = useTracker();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();

  if (!title || !callToActionLink) {
    return null;
  }

  return (
    <BasicCard
      component={TrackedLink2}
      css={styles.variantCard}
      href={callToActionLink}
      data-test="ProductVariantCard"
      trackingName="product_variant_card"
      withVisibilityTracking={true}
      requireFullyVisible={true}
      data={{
        link: callToActionLink,
        title,
      }}
      onClick={() =>
        trackV3('click_button', {
          button: {
            name: 'degrees_category_hub',
            linkType: 'internal',
            linkName: title,
            linkURL: callToActionLink,
          } as Button,
          pageSection: {
            sectionName,
            index: sectionIndex,
          },
        })
      }
    >
      <Typography2 variant="subtitleLarge" component="h3" css={styles.title}>
        {title}
      </Typography2>

      <Hidden smDown>
        <Typography2 variant="bodySecondary" component="p" css={styles.subtitle}>
          {subtitle}
        </Typography2>
      </Hidden>
    </BasicCard>
  );
};

const ProductVariantCards: React.FunctionComponent<Props> = ({ title, productVariantCards }) => {
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const browseByRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName,
      sectionTitle: title ?? '',
      index: sectionIndex,
    },
  });
  const structuredData = productVariantCards && productVariantCardsSchemaData(productVariantCards);
  const formatCtaUrl = (url: string) => `${config.url.base}${url.replace(/^\//, '')}`;

  if (!productVariantCards || !productVariantCards.length) {
    return null;
  }

  return (
    <div data-test="ProductVariantCards" css={styles.container} ref={browseByRef}>
      {structuredData && <GoogleMarkup graph={structuredData} />}

      <Typography variant="h1semibold" component="h2" css={styles.heading}>
        {title}
      </Typography>
      <Grid container spacing={16} role="list" css={styles.variantCardList}>
        {productVariantCards.map((productVariantCard: Maybe<ProductVariantCardsFragment>) => (
          <Grid item xs={12} md={4} key={productVariantCard?.title} css={styles.variantCarditem} role="listitem">
            <ProductVariantCard
              key={productVariantCard?.title}
              title={productVariantCard?.title}
              subtitle={productVariantCard?.subtitle}
              callToActionLabel={productVariantCard?.callToActionLabel}
              callToActionLink={formatCtaUrl(productVariantCard?.callToActionLink ?? '')}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default React.memo(ProductVariantCards);
