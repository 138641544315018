import { flatten } from 'lodash';

import { SEARCH_FILTERS_FOR_URL_SYNC } from 'bundles/search-common/constants/filter';

function manipulateFiltersForEventing(facetFilters?: string[][]): string[] | undefined {
  const flattenedFilters = flatten(facetFilters);
  if (!flattenedFilters?.length) return undefined;
  return flattenedFilters.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
}

function extractFiltersForEventing(url: string) {
  const [urlWithoutFragment] = url.split('#'); // Remove the fragment part of the URL if it exists
  const urlParams = new URLSearchParams(urlWithoutFragment.split('?')[1]);
  const filters = [];

  for (const [key, value] of urlParams.entries()) {
    if (SEARCH_FILTERS_FOR_URL_SYNC.includes(key)) filters.push(`${key}:${value}`);
  }

  return manipulateFiltersForEventing([filters]);
}

export { manipulateFiltersForEventing, extractFiltersForEventing };
