import type * as React from 'react';
import { graphql } from 'react-apollo';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import user from 'js/lib/user';

import type {
  GetRolesByUserRequest,
  GetRolesByUserResponse,
} from '@coursera/grpc-types-enterprisepermissions/coursera/proto/enterprisepermissions/userroles/v1beta1/enterprise_user_roles_api';

import Naptime from 'bundles/naptimejs';
import EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';

/**
 * This GRPC call will be replaced with DGS as soon as we get the new API
 * I created a ticket for this FE work: https://coursera.atlassian.net/browse/EAP-5124
 * and it's currently blocked by BE: https://coursera.atlassian.net/browse/EAP-5123
 * This temporary GRPC implementation unblocks the release of PII-restricted admin functionality
 */
export const enterpriseOrganizationIdsQuery = gql`
  query EnterpriseUserRolesQuery($input: GetRolesByUserRequest!) {
    getRolesByUser(input: $input)
      @rest(
        type: "GetRolesByUser"
        path: "grpc/enterprisepermissions/userroles/v1beta1/EnterpriseUserRolesAPI/GetRolesByUser"
        method: "POST"
      ) {
      roles
    }
  }
`;

export const withIsEnterpriseAdmin = graphql<
  {},
  { getRolesByUser: GetRolesByUserResponse },
  { input: GetRolesByUserRequest },
  {}
>(enterpriseOrganizationIdsQuery, {
  options: () => {
    const input: GetRolesByUserRequest = {
      userId: user.get().id,
    };
    return { variables: { input } };
  },
  props: ({ data }) => {
    return {
      isEnterpriseAdmin: (data?.getRolesByUser?.roles ?? []).length > 0,
    };
  },
});

export const useEnterpriseRoles = (userId: number, skip = false) => {
  const { data, loading, error } = useQuery<
    { getRolesByUser: GetRolesByUserResponse },
    { input: GetRolesByUserRequest }
  >(enterpriseOrganizationIdsQuery, {
    variables: {
      input: {
        userId,
      },
    },
    ssr: false,
    skip,
  });
  const enterpriseAdminRoles = data?.getRolesByUser?.roles ?? [];
  const organizationIds =
    enterpriseAdminRoles.map((role) => role.roleDomainId?.organizationId ?? '').filter((id) => id) ?? [];
  const programIds = enterpriseAdminRoles.map((role) => role.roleDomainId?.programId ?? '').filter((id) => id) ?? [];
  return { organizationIds, programIds, isEnterpriseAdmin: enterpriseAdminRoles.length > 0, loading, error };
};

const withManagedOrganizations = <A, B>(Component: React.ComponentType<A>) =>
  compose<A, B>(
    graphql<
      {},
      { getRolesByUser: GetRolesByUserResponse },
      { input: GetRolesByUserRequest },
      { organizationIds: string[]; programIds: string[] }
    >(enterpriseOrganizationIdsQuery, {
      options: () => {
        const input: GetRolesByUserRequest = {
          userId: user.get().id,
        };
        return { variables: { input }, ssr: false };
      },
      skip: () => !user.isAuthenticatedUser(),
      props: ({ data }) => {
        return {
          organizationIds:
            data?.getRolesByUser?.roles.map((role) => role.roleDomainId?.organizationId ?? '').filter((id) => id) ?? [],
          programIds:
            data?.getRolesByUser?.roles.map((role) => role.roleDomainId?.programId ?? '').filter((id) => id) ?? [],
          userId: user.get().id,
          loadingManagedOrganizations: data?.loading,
        };
      },
    }),
    Naptime.createContainer(({ programIds }: { programIds: string[] }) => {
      if (!user.isAuthenticatedUser()) return {};
      return {
        enterprisePrograms:
          programIds.length > 0
            ? EnterpriseProgramsV1.multiGet(programIds, {
                fields: ['id', 'thirdPartyOrganizationId'],
                required: false,
              })
            : [],
      };
    }),
    Naptime.createContainer(
      ({
        enterprisePrograms = [],
        organizationIds = [],
      }: {
        enterprisePrograms?: EnterpriseProgramsV1[];
        organizationIds?: string[];
      }) => {
        if (!user.isAuthenticatedUser()) return {};
        const thirdPartyOrganizationIds = Array.from(
          new Set([
            ...enterprisePrograms.filter((_) => !_.metadata.endedAt).map((program) => program.thirdPartyOrganizationId),
            ...organizationIds,
          ])
        ).slice(0, 50); // a user can be automatically added as an admin into thousands of orgs https://coursera.slack.com/archives/CD66C1XB7/p1650990052624859;

        return {
          thirdPartyOrganizations:
            thirdPartyOrganizationIds && thirdPartyOrganizationIds.length > 0
              ? ThirdPartyOrganizationsV1.multiGet(thirdPartyOrganizationIds, {
                  fields: ['id', 'name', 'slug'],
                  required: false,
                })
              : [],
        };
      }
    )
  )(Component);

export default withManagedOrganizations;
