import camelCase from 'just-camel-case';

/**
 * Returns a string value that is safe to use in html attributes, like `id` or `aria-labelledby`.
 * Use this when dealing with unknown strings such as values passed externally.
 * See [CDS-930] for a use case of this util.
 *
 * @param value string to be safely converted
 */
export const getHtmlSafeValue = (values: string[]): string => {
  return values
    .map((value) => {
      // convert a multi-word string to camelcase
      if (value.split(/\s/).length > 1) {
        return camelCase(value);
      }

      return value;
    })
    .join('-'); // kebab-case the strings to maintain the grouping in the final result
};
