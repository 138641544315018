import { FOOTER_COLUMN_IDS } from 'bundles/page-footer/components/global-footer/constants';
import getFooterLinks from 'bundles/page-footer/components/global-footer/data/footerLinks';
import type { ColumnSizes, GlobalFooterSectionData } from 'bundles/page-footer/components/seo-sub-footer/types';
import type { GlobalFooterLinksForCategory } from 'bundles/seo/types';

import _t from 'i18n!nls/page';

export const NUMBER_OF_COLUMNS_PER_SECTION = 4;
export const NUMBER_OF_LINKS_MOBILE_VIEW = 10;

export const SUBFOOTER_REDESIGN_NO_ACCORDION_VARIANT = 'B';
export const SUBFOOTER_REDESIGN_WITH_ACCORDION_VARIANT = 'C';

export const DEFAULT_FOOTER_COL_CONFIG: ColumnSizes = {
  md: 3,
  sm: 12,
  xs: 12,
};

export const LOHP_REBRAND_FOOTER_COL_CONFIG: ColumnSizes = {
  md: 3,
  sm: 6,
  xs: 12,
};

export const getGlobalFooterSectionData = (
  globalFooterLinksForCategory?: GlobalFooterLinksForCategory
): Array<GlobalFooterSectionData> => {
  const links = getFooterLinks();

  return [
    {
      header: _t('Coursera'),
      key: 'Coursera',
      dropdownId: FOOTER_COLUMN_IDS.coursera,
      links: [
        links.about,
        links.whatWeOffer,
        links.leadership,
        links.careers,
        links.catalog,
        links.courseraPlus,
        links.certificates,
        links.mastertrack,
        links.degrees,
        links.enterprise,
        links.government,
        links.campus,
        links.becomeAPartner,
        links.socialImpact,
        ...(globalFooterLinksForCategory?.Coursera || []),
      ],
    },
    {
      header: _t('Community'),
      key: 'Community',
      dropdownId: FOOTER_COLUMN_IDS.community,
      links: [
        links.learners,
        links.partners,
        links.betaTesters,
        links.blog,
        links.podcast,
        links.techBlog,
        links.teachingCenter,
        ...(globalFooterLinksForCategory?.Community || []),
      ],
    },
    {
      header: _t('More'),
      key: 'More',
      dropdownId: FOOTER_COLUMN_IDS.more,
      links: [
        links.press,
        links.investors,
        links.terms,
        links.privacy,
        links.help,
        links.accessibility,
        links.contact,
        links.articles,
        links.directory,
        links.affiliates,
        links.modernSlavery,
        links.cookiePolicy,
        ...(globalFooterLinksForCategory?.More || []),
      ],
    },
  ];
};
