import extend from 'lodash/extend';
import has from 'lodash/has';
import includes from 'lodash/includes';
import Q from 'q';

import api from 'bundles/third-party-auth/api';
import apple from 'bundles/third-party-auth/apple';
import facebook from 'bundles/third-party-auth/facebook';
import google from 'bundles/third-party-auth/google';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import googleOneTap from 'bundles/third-party-auth/googleOneTap';

export { facebook, apple, google };

export const SSOTypes = {
  FACEBOOK: 'facebook',
  APPLE: 'apple',
  GOOGLE: 'google',
  GOOGLE_ONE_TAP: 'googleOneTap',
  SAML: 'saml',
  JWT: 'jwt',
  JWT_INVITE: 'jwtInvite',
  ZOOM: 'zoom',
} as const;

export type SSO_TYPE = (typeof SSOTypes)[keyof typeof SSOTypes];

/**
 *
 * Logs into Facebook / Apple and then either creates or links a Coursera account.
 *
 * @param {String} authType one of SSO_TYPE
 * @param {Object} [additionalData] e.g. {email} or {password}
 */
export const connect = (authType: SSO_TYPE, additionalData?: $TSFixMe): Q.Promise<$TSFixMe> => {
  if (authType === SSOTypes.FACEBOOK) {
    return Q(facebook.getStatusOrLogin()).then((response) => {
      const authResponse = response?.authResponse;
      const authData = extend(
        {
          authType,
          token: authResponse?.accessToken,
        },
        additionalData || {}
      );
      const grantedScopes = (authResponse?.grantedScopes || '').split(',');
      const grantedEmail = includes(grantedScopes, 'email') || includes(grantedScopes, 'contact_email');
      if (
        // if grantedScopes is not in authResponse, then this means the user has given permission to our app in the
        // past, and we don't need to request email again. This is the result of facebook.getLoginStatus
        !has(authResponse || {}, 'grantedScopes') ||
        // If grantedScopes is in authResponse, and the email permission was granted. This is the result of
        // facebook.login
        grantedEmail ||
        // If the email is supplied by the requestEmail form, after we requested an email from the user
        authData.email ||
        // If the password is supplied by the requestState, which mean we are linking accounts
        authData.password
      ) {
        return api.login(authData).then((userData) => {
          // TODO: Make sure user's full name is set on signup
          return extend(userData, { authType, authResponse });
        });
      } else {
        // The person has rejected giving us the permission for their email
        return Q.reject({ code: 'noEmailAvailable' });
      }
    });
  } else if (authType === SSOTypes.APPLE) {
    return Q(apple.getStatusOrLogin()).then((response) => {
      const code = response?.authorization?.code;
      const email = response?.user?.email;
      const name = response?.user?.name;

      // Names only come in from response separately, so we need to concatenate them into one full name
      const fullName = [name?.firstName, name?.middleName, name?.lastName].filter(Boolean).join(' ');

      const authDataObj = email
        ? {
            authType,
            token: code,
            email,
          }
        : {
            authType,
            token: code,
          };

      const authData = extend(authDataObj, additionalData || {});

      if (authData?.token) {
        return api.login(authData).then((userData) => {
          const { isRegistration, userId } = userData;

          // Only update user's full name on initial signup
          if (isRegistration === true && !!fullName) {
            api.updateProfileName(userId, fullName);
          }

          return extend(userData, { authType });
        });
      } else {
        return Q.reject({ code: 'unknownStatus' });
      }
    });
  } else if (authType === SSOTypes.GOOGLE) {
    return Q(google.getStatusOrLogin()).then((response) => {
      const { code, email } = response;
      const authData = extend(
        {
          authType,
          token: code,
          email,
        },
        additionalData || {}
      );

      const grantedEmail = authData.email || email;

      if (grantedEmail) {
        return api.login({ ...authData, email: grantedEmail }).then(function (userData) {
          return extend(userData, { authType, response });
        });
      } else {
        // The person has rejected giving us the permission for their email
        return Q.reject({ code: 'noEmailAvailable' });
      }
    });
  } else if (authType === SSOTypes.GOOGLE_ONE_TAP) {
    return Q(googleOneTap.getStatusOrLogin()).then((response) => {
      const { code } = response;
      const authData = extend(
        {
          authType,
          token: code,
        },
        additionalData || {}
      );

      if (authData?.token) {
        return api.login(authData).then(function (userData) {
          return extend(userData, { authType, response });
        });
      } else {
        // The person has rejected giving us the permission for their email
        return Q.reject({ code: 'noEmailAvailable' });
      }
    });
  } else if (authType === SSOTypes.SAML || authType === SSOTypes.JWT || authType === SSOTypes.JWT_INVITE) {
    const authData = Object.assign(
      {
        authType,
        token: additionalData.token,
      },
      additionalData || {}
    );
    return api.login(authData).then((userData) => {
      return extend(userData, { authType });
    });
  } else {
    return Q.reject({ code: 'unknownAuthType', authType });
  }
};

export const getLinkedAccounts = () => {
  return api.list();
};

// Used to link existing coursera accounts with a third party account
export const link = (authType: SSO_TYPE, additionalData?: $TSFixMe): Q.Promise<{ returnTo: string }> => {
  if (authType === SSOTypes.FACEBOOK) {
    return Q(facebook.getStatusOrLogin()).then((response) => {
      const authResponse = response.authResponse;
      const authData = extend(
        {
          authType,
          token: authResponse.accessToken,
        },
        additionalData || {}
      );
      return api.link(authData) as any;
    });
  } else if (authType === SSOTypes.APPLE) {
    return Q(apple.getStatusOrLogin()).then((response) => {
      const code = response?.authorization?.code;

      const authData = extend(
        {
          authType,
          token: code,
        },
        additionalData || {}
      );

      return api.link(authData) as $TSFixMe;
    });
  } else if (authType === SSOTypes.GOOGLE) {
    return Q(google.getStatusOrLogin()).then((response) => {
      const { code, email } = response;
      const authData = extend(
        {
          authType,
          token: code,
          email,
        },
        additionalData || {}
      );

      return api.link(authData) as $TSFixMe;
    });
  } else if (authType === SSOTypes.SAML || authType === SSOTypes.JWT || authType === SSOTypes.JWT_INVITE) {
    const authData = Object.assign(
      {
        authType,
        token: additionalData.token,
      },
      additionalData || {}
    );
    return api.link(authData) as $TSFixMe;
  } else if (authType === SSOTypes.ZOOM) {
    return Q.reject({ code: 'zoom', authType });
  } else {
    return Q.reject({ code: 'unknownAuthType', authType });
  }
};

export const delink = (authType: SSO_TYPE, additionalData: $TSFixMe) => {
  return api.delink(authType, additionalData);
};

export const addAlternateEmail = (authType: SSO_TYPE, additionalData: $TSFixMe, email: string) => {
  if (authType === SSOTypes.SAML || authType === SSOTypes.JWT || authType === SSOTypes.JWT_INVITE) {
    return api.findIdFromEmail(email).then((response) => {
      const userId = response.userEmail.userId;
      const newData = Object.assign({ userId }, additionalData);
      return api.addAlternateEmail(newData);
    });
  } else {
    return Q.reject();
  }
};

export default {
  facebook,
  apple,
  connect,
  getLinkedAccounts,
  link,
  delink,
  addAlternateEmail,
};
