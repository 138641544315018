import * as React from 'react';

import connectToStores from 'js/lib/connectToStores';
import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';
import type UserAgentInfo from 'js/lib/useragent';

import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

export type PropsFromWithUserAgent = {
  userAgent?: UserAgentInfo;
};

const withUserAgent =
  () =>
  <BaseProps extends PropsFromWithUserAgent>(
    WrappedComponent: React.ComponentType<BaseProps>
  ): React.ComponentClass<Omit<BaseProps, keyof PropsFromWithUserAgent>> => {
    const componentName = WrappedComponent.displayName || WrappedComponent.name;

    const HOCWithUserAgentApi: React.FC<BaseProps> = (props) => {
      return <WrappedComponent {...{ ...props, userAgent: props.userAgent }} />;
    };

    HOCWithUserAgentApi.displayName = `withUserAgent(${componentName})`;

    hoistNonReactStatics(HOCWithUserAgentApi, WrappedComponent);

    return connectToStores<BaseProps, Omit<BaseProps, keyof PropsFromWithUserAgent>, ApplicationStoreClass>(
      [ApplicationStoreClass],
      (ApplicationStore, props) =>
        ({
          ...props,
          userAgent: ApplicationStore.getUserAgent(),
        } as BaseProps)
    )(HOCWithUserAgentApi);
  };

export default withUserAgent;
