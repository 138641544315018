import * as React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';

import LinkButton from 'bundles/authentication/shared/components/authentication-modal/LinkButton';
import { isMisspelledDomain } from 'bundles/authentication/utils/emailVerificationUtils';
import {
  USER_EMAIL_REGEX_PATTERN,
  USER_NAME_MAX_LENGTH,
  USER_NAME_MIN_LENGTH,
  USER_PASSWORD_MAX_LENGTH,
  USER_PASSWORD_MIN_LENGTH,
} from 'bundles/user-account/common/constants';

import _t from 'i18n!nls/authentication';

export const HAS_PREVIOUSLY_LOGGED_IN = 'has_previously_logged_in';

export const HEADER_HEIGHT_PX = 55;

export const AUTH_MODES_WITH_SINGLE_PAGE = {
  signup: 'signup',
  login: 'login',
} as const;

const EMAIL = 'email';
const PASSWORD = 'password';
const FULLNAME = 'name';
const LOGIN_RECAPTCHA_ID = 'recaptcha-login';
const SIGNUP_RECAPTCHA_ID = 'recaptcha-signup';
const LOGIN_REDESIGN_RECAPTCHA_ID = 'recaptcha-login-redesign';
const SIGNUP_REDESIGN_RECAPTCHA_ID = 'recaptcha-signup-redesign';

export const RECAPTCHA_ACTIONS = {
  signup: 'signup',
  login: 'login',
  setPassword: 'set_password',
} as const;

export type RecaptchaAction = (typeof RECAPTCHA_ACTIONS)[keyof typeof RECAPTCHA_ACTIONS];

const tokenCodes = {
  GOOD_TOKEN: 'good',
  INVALID_TOKEN: 'invalid',
  EXPIRED_TOKEN: 'expired',
  ALREADY_VERIFIED: 'already-verified',
  IS_VERIFYING: 'is-verifying',
};

export const EMAIL_ERRORS = {
  get invalidEmail() {
    return _t('Invalid email. Please enter email as name@email.com');
  },
  get blankEmail() {
    return _t('Please provide an email in the input below.');
  },
  get misspelledEmailDomain() {
    return _t('Looks like you misspelled the domain of your email address. Please try again.');
  },
};

const EMAIL_CONSTRAINT = (email: string, allowBlankEmailError?: boolean) => {
  const regex = new RegExp(USER_EMAIL_REGEX_PATTERN);

  if (allowBlankEmailError && !email) {
    return EMAIL_ERRORS.blankEmail;
  }

  if (!regex.test(email)) {
    return EMAIL_ERRORS.invalidEmail;
  }

  if (email && email.includes('@')) {
    const emailSplit = email.split('@');
    const emailProvider = emailSplit[emailSplit.length - 1];

    return isMisspelledDomain(emailProvider) && EMAIL_ERRORS.misspelledEmailDomain;
  }

  return false;
};

const FIRSTNAME_CONSTRAINT = (name: string) => {
  if (name.length < USER_NAME_MIN_LENGTH) {
    return _t('First name is required.');
  }

  return false;
};

const LASTNAME_CONSTRAINT = (name: string) => {
  if (name.length < USER_NAME_MIN_LENGTH) {
    return _t('Last name is required.');
  }

  return false;
};

const FULLNAME_CONSTRAINT = (name: string) => {
  if (name.length < USER_NAME_MIN_LENGTH) {
    return _t('Full name is required.');
  }

  return false;
};

const PASSWORD_CONSTRAINT = (password: string) => {
  if (password.length > USER_PASSWORD_MAX_LENGTH || password.length < USER_PASSWORD_MIN_LENGTH) {
    return _t('Password must contain between 8 and 72 characters.');
  }

  return false;
};

const TOU_CHECKBOX_CONSTRAINT = (checked: string) => {
  if (checked !== 'true') {
    return _t('Must accept Terms of Use and Privacy Notice.');
  }

  return false;
};

const NOT_EMPTY_CONSTRAINT = (value: string) => {
  if (!value) {
    return _t('This field is required.');
  }

  return false;
};

export {
  existingEmailError,
  passwordRequirementsError,
  nameRequirementsError,
  emailRequirementsError,
  recaptchaFailed,
  tooManyRequests,
  EMAIL,
  EMAIL_CONSTRAINT,
  PASSWORD,
  PASSWORD_CONSTRAINT,
  FULLNAME,
  FIRSTNAME_CONSTRAINT,
  LASTNAME_CONSTRAINT,
  FULLNAME_CONSTRAINT,
  LOGIN_RECAPTCHA_ID,
  SIGNUP_RECAPTCHA_ID,
  LOGIN_REDESIGN_RECAPTCHA_ID,
  SIGNUP_REDESIGN_RECAPTCHA_ID,
  NOT_EMPTY_CONSTRAINT,
  TOU_CHECKBOX_CONSTRAINT,
};

export const { GOOD_TOKEN, INVALID_TOKEN, EXPIRED_TOKEN, ALREADY_VERIFIED, IS_VERIFYING } = tokenCodes;

export const emailResponseStatusToError = {
  [GOOD_TOKEN]: (email: string) =>
    email && (
      <FormattedHTMLMessage
        message={_t('Your email address <strong>{email}</strong> is verified. Thank you!')}
        email={email}
      />
    ),
  [INVALID_TOKEN]: () => _t('Sorry, your verification token is invalid.'),
  [EXPIRED_TOKEN]: () =>
    _t(
      'Sorry, your verification token expired. We resent you the verification email. Please use the link in the email to verify.'
    ),
  [ALREADY_VERIFIED]: () => _t('Your email address has already been verified. Thank you!'),
};

// Get to the const values of any object
type Values<T> = T[keyof T];

const ALL_THIRD_PARTY_AUTH_SERVICES = {
  apple: 'apple',
  facebook: 'facebook',
  google: 'google',
} as const;

export type ThirdPartyAuthService = Values<typeof ALL_THIRD_PARTY_AUTH_SERVICES>;

export const DEFAULT_THIRD_PARTY_AUTH_SERVICES: Array<ThirdPartyAuthService> = [
  ALL_THIRD_PARTY_AUTH_SERVICES.google,
  ALL_THIRD_PARTY_AUTH_SERVICES.facebook,
  ALL_THIRD_PARTY_AUTH_SERVICES.apple,
];

export default {
  tokenCodes,
  emailResponseStatusToError,
};

/* Start errors caught from BE: https://docs.google.com/spreadsheets/d/1YLMQa0o8dBDqRLxs1sHrjTWjjxC5ju8UaASC55SjANk/edit */
const existingEmailError = 'existingCourseraAccount';
const passwordRequirementsError = 'failedPasswordRequirements';
const nameRequirementsError = 'invalidName';
const emailRequirementsError = 'malformedEmailAddress';
const recaptchaFailed = 'recaptchaFailed';
const tooManyRequests = 'tooManyRequests';

type ResponseMessageFactoryOutput = Record<string, any>;

export function responseMessageFactory(): ResponseMessageFactoryOutput {
  return {
    malformedEmailAddress: _t('The email address you entered is not valid.'),
    missingReCaptcha: _t('reCaptcha must be checked'),
    failedPasswordRequirements: _t(
      'Password must be between #{minLength} and #{maxLength} characters. For your security, avoid passwords like qwerty123 or password123.',
      {
        minLength: USER_PASSWORD_MIN_LENGTH,
        maxLength: USER_PASSWORD_MAX_LENGTH,
      }
    ),
    invalidName: _t(
      'Please enter your full name. It should be between #{minLength} and #{maxLength} characters. It should not be an email address.',
      {
        minLength: USER_NAME_MIN_LENGTH,
        maxLength: USER_NAME_MAX_LENGTH,
      }
    ),
    existingCourseraAccount: (link: () => void) => (
      <FormattedMessage
        message={_t('Looks like you already have an account with that email address. Please {loginLink} instead.')}
        loginLink={
          <LinkButton onClick={link} type="button">
            <strong>{_t('log in')}</strong>
          </LinkButton>
        }
      />
    ),
    success: _t('Signup success! Redirecting you now...'),
  };
}

export const ARKOSE_PUBLIC_KEY_SIGN_UP = '98EFB87D-3554-499A-B9C9-4285EDF0DF57';
