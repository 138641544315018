/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Grid, breakpoints, useTheme } from '@coursera/cds-core';
import type { GridProps, Theme } from '@coursera/cds-core';

import type { VideoTestimonialsSectionProps } from 'bundles/cms/types';
import VideoTestimonial from 'bundles/premium-hub/components/shared/VideoTestimonial';
import Overlay from 'bundles/xddp/components/shared/Overlay';

type GridSize = NonNullable<Exclude<GridProps['lg'], boolean>>;

const GRID_SIZE = 12;

const styles = {
  videoPlayback: (theme: Theme) => ({
    [`${breakpoints.up('xs')}`]: {
      height: 128,
    },
    [`${breakpoints.up('sm')}`]: {
      height: 324,
    },
    [`${breakpoints.up('md')}`]: {
      height: 445,
    },
    [`${breakpoints.up('lg')}`]: {
      height: 567,
    },
    [`${breakpoints.up('xl')}`]: {
      height: 648,
    },
  }),
};

export const VideoTestimonials: React.FunctionComponent<VideoTestimonialsSectionProps> = ({
  secondaryVideoTestimonialsOverride,
  videoTestimonialsHeader,
  primaryVideoTestimonialOverride,
}) => {
  const theme = useTheme();
  const [shouldPlayBackVideo, setShouldPlayBackVideo] = useState(false);
  const [currentlyPlayed, setCurrentlyPlayed] = useState('');

  const enableVideoPlayback = useCallback(
    (playedCurrently: string) => {
      setShouldPlayBackVideo(!shouldPlayBackVideo);
      setCurrentlyPlayed(playedCurrently);
    },
    [shouldPlayBackVideo]
  );

  const disableVideoPlayback = useCallback(() => {
    if (shouldPlayBackVideo) {
      setShouldPlayBackVideo(!shouldPlayBackVideo);
      setCurrentlyPlayed('');
    }
  }, [shouldPlayBackVideo]);

  const renderSecondaryTestimonial = (videoTestimonial: $TSFixMe) => {
    if (!videoTestimonial) {
      return null;
    }

    const noOfSecondaryTestimonials =
      (secondaryVideoTestimonialsOverride && secondaryVideoTestimonialsOverride.length) || 0;
    const columnSize = Math.ceil(GRID_SIZE / noOfSecondaryTestimonials) as GridSize;
    return (
      <Grid item key={videoTestimonial.authorName} sm={GRID_SIZE} md={columnSize} lg={columnSize}>
        <VideoTestimonial
          totalSecondaryVideos={noOfSecondaryTestimonials}
          authorName={videoTestimonial.authorName}
          authorTitle={videoTestimonial.authorTitle}
          text={videoTestimonial.text}
          videoEmbedUrl={videoTestimonial.videoEmbedUrl}
          image={videoTestimonial.image}
          onClick={enableVideoPlayback}
        />
      </Grid>
    );
  };

  const renderPrimaryTestimonial = (videoTestimonial: $TSFixMe) => {
    if (!videoTestimonial) {
      return null;
    }

    return (
      <Grid spacing={4}>
        <VideoTestimonial
          authorName={videoTestimonial.authorName}
          authorTitle={videoTestimonial.authorTitle}
          text={videoTestimonial.text}
          videoEmbedUrl={videoTestimonial.videoEmbedUrl}
          image={videoTestimonial.image}
          isPrimary={true}
          onClick={enableVideoPlayback}
        />
      </Grid>
    );
  };

  const noOfSecondaryTestimonials = useMemo(
    () => (secondaryVideoTestimonialsOverride && secondaryVideoTestimonialsOverride.length) || 0,
    [secondaryVideoTestimonialsOverride]
  );

  if (!primaryVideoTestimonialOverride && !secondaryVideoTestimonialsOverride) {
    return null;
  }

  return (
    <Grid container className="m-b-3" direction="column">
      <h2 className="text-xs-center m-y-3">{videoTestimonialsHeader}</h2>
      <Grid item>{renderPrimaryTestimonial(primaryVideoTestimonialOverride)} </Grid>
      <Grid container spacing={16}>
        {noOfSecondaryTestimonials > 1 && secondaryVideoTestimonialsOverride?.map(renderSecondaryTestimonial)}
      </Grid>
      {shouldPlayBackVideo && (
        <Overlay handleClose={disableVideoPlayback}>
          <div css={styles.videoPlayback(theme)}>
            <iframe
              title="Youtube Embedded Video"
              width="100%"
              height="100%"
              src={currentlyPlayed}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </Overlay>
      )}
    </Grid>
  );
};

export default VideoTestimonials;
