import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('select-field', [
  'label',
  'formValidationStatus',
  'formSupportText',
  'readOnly',
  'root',
]);

const getSelectFieldCss = css`
  .${classes.root} {
    margin-top: var(--cds-spacing-100);

    &:only-child {
      margin-top: 0;
    }
  }

  .${classes.readOnly} {
    background-color: var(--cds-color-neutral-background-primary-weak);
  }

  .${classes.label} {
    margin: 0;
  }

  .${classes.formValidationStatus} {
    margin-top: var(--cds-spacing-100);

    & + .${classes.root} {
      margin-top: var(--cds-spacing-50);
    }
  }

  .${classes.formSupportText} {
    margin: var(--cds-spacing-50) 0 0;
  }
`;

export default getSelectFieldCss;
