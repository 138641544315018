import * as React from 'react';
import { Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import NotFound from 'bundles/phoenix/components/NotFound';
import PremiumHubApp from 'bundles/premium-hub/components/PremiumHubApp';
import CertificatesHubPage from 'bundles/premium-hub/components/certificates/CertificatesHubPage';
import CertificatesVariantHubPage from 'bundles/premium-hub/components/certificates/CertificatesVariantHubPage';
import CoreHubPageContent from 'bundles/premium-hub/pages/CoreHubPageContent';
import DegreeCategoryHubPageContent from 'bundles/premium-hub/pages/DegreeCategoryHubPageContent';
import DegreeProgramLevelHubPageContent from 'bundles/premium-hub/pages/DegreeProgramLevelHubPageContent';
import LandingPageContent from 'bundles/premium-hub/pages/LandingPageContent';

const reactRoute = (
  <Route name="app" component={PremiumHubApp} path="/">
    <CourseraRoute
      name="degreesHub"
      component={CoreHubPageContent}
      path="/degrees"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'degrees_core_hub' },
          }),
        },
      }}
    />
    <Route name="degreesHub" component={CoreHubPageContent} path="/degrees/browse" />
    <CourseraRoute
      name="bachelorsHub"
      component={DegreeProgramLevelHubPageContent}
      path="/degrees/bachelors"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'degrees_level_hub' },
          }),
        },
      }}
    />
    <Route name="bachelorsHub" component={DegreeProgramLevelHubPageContent} path="/degrees/bachelors/browse" />
    <CourseraRoute
      name="mastersHub"
      component={DegreeProgramLevelHubPageContent}
      path="/degrees/masters"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'degrees_level_hub' },
          }),
        },
      }}
    />
    <Route name="mastersHub" component={DegreeProgramLevelHubPageContent} path="/degrees/masters/browse" />
    <Route name="landingHub" component={LandingPageContent} path="/degrees/landing/:slug" />
    <Route name="landingHub" component={LandingPageContent} path="/degrees/landing/:slug/browse" />
    <CourseraRoute
      name="postgraduateStudiesHub"
      component={DegreeProgramLevelHubPageContent}
      path="/degrees/postgraduate-studies"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'degrees_level_hub' },
          }),
        },
      }}
    />
    <Route
      name="postgraduateStudiesHub"
      component={DegreeProgramLevelHubPageContent}
      path="/degrees/postgraduate-studies/browse"
    />
    <CourseraRoute
      name="degreesCategoryHub"
      component={DegreeCategoryHubPageContent}
      path="/degrees/:categorySlug"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'degrees_category_hub' },
          }),
        },
      }}
    />
    <Route name="degreesCategoryHub" component={DegreeCategoryHubPageContent} path="/degrees/:categorySlug/browse" />

    <CourseraRoute
      name="certificatesHub"
      component={CertificatesHubPage}
      path="/certificates"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'certs_core_hub' },
          }),
        },
      }}
    />

    <CourseraRoute
      name="mastertrackHub"
      component={CertificatesVariantHubPage}
      path="/mastertrack"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'certs_mastertracks_hub' },
          }),
        },
      }}
    />
    <CourseraRoute
      name="professionalCertificatesHub"
      component={CertificatesVariantHubPage}
      path="/professional-certificates"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'certs_prof_certs_hub' },
          }),
        },
      }}
    />
    <CourseraRoute
      name="universityCertificatesHub"
      component={CertificatesVariantHubPage}
      path="/certificates/learn"
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'certs_ucerts_hub' },
          }),
        },
      }}
    />
    <Route component={NotFound} path="*" />
  </Route>
);

export default reactRoute;
