import { useMemo } from 'react';

import useRouter from 'js/lib/useRouter';

export default function usePathname(): string {
  const router = useRouter();
  const pathname = useMemo(() => router?.location?.pathname ?? '', [router?.location?.pathname]);

  return pathname;
}
