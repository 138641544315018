import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('MenuListItem', [
  'checked',
  'disabled',
  'prefix',
  'suffix',
  'content',
  'supportText',
  'checkboxIcon',
  'loading',
]);

export const itemLayoutCss = css`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: var(--cds-spacing-100);
  gap: var(--cds-spacing-150);
  min-width: 200px;

  .${classes.content} {
    flex: 1;
  }

  .${classes.prefix}, .${classes.suffix} {
    display: flex;
  }

  &.${classes.loading} {
    .${classes.content} {
      align-self: center;
    }
  }
`;

const itemCss = css`
  color: var(--cds-color-neutral-primary);

  &[role='menuitemcheckbox'] {
    .${classes.checkboxIcon} {
      fill: var(--cds-color-neutral-background-primary);
    }
  }

  &.${classes.checked} {
    &[role='menuitemradio'] {
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    .${classes.checkboxIcon} {
      color: var(--cds-color-interactive-primary);
      fill: var(--cds-color-interactive-primary);
    }
  }

  &:hover {
    background-color: var(
      --cds-color-interactive-background-primary-hover-weak
    );
  }

  &.${classes.disabled} {
    background-color: unset;
    color: var(--cds-color-neutral-disabled-strong);
  }

  .${classes.checkboxIcon} {
    display: block;
  }
`;

export default itemCss;
