import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useForkRef } from '@material-ui/core/utils';

import { useListBox } from '@react-aria/listbox';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import { getItemCount } from '@react-stately/collections';
import type { ListProps, ListState } from '@react-stately/list';

import { useId, useLocalizedStringFormatter } from '@coursera/cds-common';

import i18nMessages from '@core/Autocomplete/i18n';
import PopoverBody from '@core/Popover/Body';
import VisuallyHidden from '@core/VisuallyHidden';

import Group from './Group';
import LoadingPlaceholder from './LoadingPlaceholder';
import Option from './Option';
import listBoxCss from './styles/listboxCss';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export type Props<OptionType> = AriaListBoxOptions<OptionType> & {
  children: ListProps<OptionType>['children'];
  autoFocus?: 'first' | 'last' | boolean;
  loading?: boolean;
  className?: string;
  state: ListState<OptionType>;
  /**
   * If set the fixed width will be applied to the listbox. This is useful when the listbox is rendered in a Dropdown
   * To avoid size change when filtering the options
   */
  fixedWidth?: boolean;

  elementProps?: React.HTMLAttributes<HTMLDivElement>;

  renderNotFoundMessage?: () => React.ReactNode;
};

function ListBoxComponent<T extends object>(
  props: Props<T>,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    loading,
    state,
    fixedWidth,
    elementProps,
    renderNotFoundMessage,
    ...listProps
  } = props;

  const innerRef = useRef<HTMLDivElement>(null);
  const handleRef = useForkRef(ref, innerRef);
  const id = useId(listProps.id);
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const [width, setWidth] = useState<number | undefined>(undefined);

  const { listBoxProps } = useListBox(
    { autoFocus: 'first', ...listProps, id },
    state,
    innerRef
  );

  const itemCount = getItemCount(state.collection);

  let tabIndexValue;
  // if shouldUseVirtualFocus is true, then we should use the tabIndex from the aria props
  if (listProps.shouldUseVirtualFocus) {
    tabIndexValue = listBoxProps.tabIndex;
  } else {
    // Make it not focusable for user if there is no option available
    if (itemCount === 0) {
      tabIndexValue = -1;
    } else {
      tabIndexValue = 0;
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (innerRef.current && fixedWidth) {
      setWidth(innerRef.current?.clientWidth);
    }
  }, []);

  return (
    <>
      {/* Announced every time loading is happening or number of items has changed */}
      <VisuallyHidden role="status">
        {loading
          ? stringFormatter.format('loading')
          : stringFormatter.format('optionsAvailable', { number: itemCount })}
      </VisuallyHidden>

      {!loading ? (
        <PopoverBody
          ref={handleRef}
          className={props.className}
          component="div"
          css={listBoxCss}
          {...listBoxProps}
          style={{
            width,
          }}
          tabIndex={tabIndexValue}
          {...elementProps}
        >
          {itemCount === 0 && renderNotFoundMessage && (
            <div>{renderNotFoundMessage()}</div>
          )}
          {Array.from(state.collection).map((item) => {
            if (item.type === 'section') {
              return <Group key={item.key} group={item} state={state} />;
            }

            return (
              <Option
                key={item.key}
                item={item}
                state={state}
                {...item.props}
              />
            );
          })}
        </PopoverBody>
      ) : (
        <LoadingPlaceholder ref={handleRef} style={{ width }} />
      )}
    </>
  );
}

// forwardRef doesn't support generic parameters, so cast the result to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const ListBox = forwardRef(ListBoxComponent) as <T extends object>(
  props: Props<T> & { ref?: React.Ref<HTMLDivElement> }
) => ReturnType<typeof ListBoxComponent>;

export default ListBox;
