import { forwardRef } from 'react';

import ItemLoading from '@core/MenuList/ItemLoading';
import Body from '@core/Popover/Body';
import type { Props as BodyProps } from '@core/Popover/Body';

const LoadingPlaceholder = forwardRef<
  HTMLDivElement,
  Omit<BodyProps, 'children'>
>(function _LoadingPlaceholder(props, ref) {
  return (
    <Body ref={ref} {...props} aria-hidden>
      <ItemLoading />
      <ItemLoading />
      <ItemLoading />
      <ItemLoading />
      <ItemLoading />
      <ItemLoading />
    </Body>
  );
});

export default LoadingPlaceholder;
