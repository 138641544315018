/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { OverridableComponent, OverrideProps } from '@coursera/cds-common';

interface BasicCardTypeMap<D extends React.ElementType = 'div'> {
  props: {};
  defaultComponent: D;
}

type BasicCardProps<D extends React.ElementType = BasicCardTypeMap['defaultComponent']> = OverrideProps<
  BasicCardTypeMap<D>,
  D
> & { component?: React.ElementType };

const styles = {
  card: css`
    background-color: var(--cds-color-neutral-background-primary);
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    border-radius: var(--cds-border-radius-100);
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1) 0s;
    box-shadow: none;
    transform: none;
    outline-width: 1px;
    outline-style: solid;
    outline-color: transparent;

    :hover {
      text-decoration: none;
      box-shadow: 0 0 40px -8px rgb(0 0 0 / 16%), 0 0 24px -16px rgb(0 0 0 / 16%);
      transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
      outline-color: transparent;
    }

    :focus {
      text-decoration: none;
      outline-color: var(--cds-color-interactive-stroke-primary-focus);
    }
  `,
};

export const BasicCard: OverridableComponent<BasicCardTypeMap> = React.forwardRef(
  ({ component: Component = 'div', children, ...props }: BasicCardProps, ref) => {
    return (
      <Component ref={ref} {...props} css={styles.card}>
        {children}
      </Component>
    );
  }
);

BasicCard.displayName = 'BasicCard';
