/** @jsx jsx */

/** @jsxFrag */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Avatar } from '@coursera/cds-core';
import { ClockOutlineIcon, SearchIcon } from '@coursera/cds-icons';

import TrendingIcon from 'bundles/search-common/components/searchbar/TrendingIcon';

const styles = {
  iconWrapper: css`
    /* to fix vertical icon alignment with option text */
    display: flex;
    align-self: center;
    color: var(--cds-color-neutral-primary-weak);
  `,
};

const AutoCompleteGroupIdToIconMap: Record<string, React.ReactElement> = {
  trending: <TrendingIcon />,
  default: <SearchIcon data-testid="default-icon" />,
  recentSearches: <ClockOutlineIcon />,
};

type Props = { imageUrl?: string; groupId?: string; avatarAltText?: string };

export const AutocompleteItemIcon = (props: Props): React.ReactElement => {
  const { imageUrl, groupId = '', avatarAltText = 'Coursera' } = props;

  return (
    <div css={styles.iconWrapper}>
      {imageUrl ? (
        <Avatar
          size={36}
          variant="organization"
          initial={avatarAltText.charAt(0)}
          imgProps={{ src: imageUrl, alt: avatarAltText }}
        />
      ) : (
        <>{AutoCompleteGroupIdToIconMap[groupId] ?? AutoCompleteGroupIdToIconMap.default} </>
      )}
    </div>
  );
};

export default AutocompleteItemIcon;
