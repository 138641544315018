/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Tab, TabContext, TabPanel, Tabs, Typography, breakpoints } from '@coursera/cds-core';

import Container from 'bundles/premium-hub/components/shared/Container';
import IndustryRelevanceModule from 'bundles/premium-hub/components/shared/IndustryRelevanceModule';
import type { IndustryRelevanceContent } from 'bundles/premium-hub/data/industryRelevanceModuleContent';

import _t from 'i18n!nls/premium-hub';

type Props = {
  heading?: string;
  modules: IndustryRelevanceContent[];
};

const styles = {
  container: css`
    ${breakpoints.up('md')} {
      background-color: var(--cds-color-interactive-background-primary-hover-weak);
      padding: var(--cds-spacing-600);
    }
  `,

  content: css`
    background-color: var(--cds-color-white-0);
    padding: var(--cds-spacing-200);

    ${breakpoints.up('md')} {
      padding: var(--cds-spacing-600);
    }
  `,

  heading: css`
    margin-bottom: var(--cds-spacing-300);
  `,

  tabHeader: css`
    margin-bottom: var(--cds-spacing-300);
  `,
};

export const IndustryRelevanceModuleContainer: React.FunctionComponent<Props> = ({ heading, modules }) => {
  const [selectedTab, setSelectedTab] = useState<string>(modules[0]?.industryName);
  const trackComponent = useRetracked();

  if (modules.length === 0) return null;

  return (
    <div css={styles.container}>
      <Container css={styles.content}>
        {heading && (
          <Typography variant="h1semibold" css={styles.heading}>
            {heading}
          </Typography>
        )}

        {modules.length > 1 ? (
          <TabContext value={selectedTab}>
            <Tabs
              css={styles.tabHeader}
              aria-label={_t('Select an industry')}
              onChange={(value) => {
                trackComponent({
                  trackingName: 'industry_relevance_module_tab',
                  trackingData: { tabValue: value },
                  action: 'click',
                });
                setSelectedTab(value);
              }}
            >
              {modules.map(({ tabHeading, industryName }) => (
                <Tab key={industryName} label={tabHeading || industryName} value={industryName} />
              ))}
            </Tabs>

            {modules.map((module) => (
              <TabPanel key={module.industryName} value={module.industryName}>
                <IndustryRelevanceModule {...module} />
              </TabPanel>
            ))}
          </TabContext>
        ) : (
          <IndustryRelevanceModule {...modules[0]} />
        )}
      </Container>
    </div>
  );
};

export default IndustryRelevanceModuleContainer;
