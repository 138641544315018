/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import times from 'lodash/times';

import { Grid, breakpoints, useMediaQuery } from '@coursera/cds-core';

import type { GridConfig } from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSection';
import { styles as ShowMoreGridSectionStyles } from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSection';
import { DEFAULT_GRID_BREAKPOINTS_CONFIG } from 'bundles/cds-labs/components/ShowMoreGridSection/constants';

const styles = {
  titlePlaceholder: css`
    width: 400px;
    height: 32px;
    border-radius: 6px;
    background-color: var(--cds-color-neutral-disabled);
    ${breakpoints.down('lg')} {
      width: 240px;
    }
  `,
  expandButtonPlaceholder: css`
    width: 110px;
    height: 36px;
    border-radius: 4px;
    background-color: var(--cds-color-neutral-disabled);
  `,

  itemList: css`
    margin-top: var(--cds-spacing-150);
    margin-bottom: var(--cds-spacing-150);
  `,
};

export type ShowMoreGridSectionPlaceholderProps = {
  className?: string;

  /**
   * Option to hide the title loading skeleton.
   * @default false
   */
  hideTitle?: boolean;

  /**
   * function that return the rendered placeholder item.
   */
  renderPlaceholderItem: () => React.ReactNode;

  /**
   * Function that return a rendered sub section.
   */
  renderSubSection?: () => React.ReactNode;

  /**
   * Optional prop to update items Grid Config for responsiveness.
   * @default DEFAULT_GRID_BREAKPOINTS_CONFIG
   */
  itemsGridConfig?: GridConfig;

  /**
   * Number of default showed row before expanding.
   * @default 1
   */
  defaultRows?: number;

  /**
   * Option to hide the expand button skeleton.
   * @default false
   */
  hideButton?: boolean;
};

export const ShowMoreGridSectionPlaceholder = ({
  hideTitle = false,
  renderPlaceholderItem,
  renderSubSection,
  itemsGridConfig = DEFAULT_GRID_BREAKPOINTS_CONFIG,
  defaultRows = 1,
  hideButton = false,
  ...props
}: ShowMoreGridSectionPlaceholderProps) => {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isTablet = useMediaQuery(breakpoints.down('sm'));

  let cardsPerRow = 12 / itemsGridConfig.md;
  if (isTablet) cardsPerRow = 12 / itemsGridConfig.sm;
  if (isMobile) cardsPerRow = 12 / itemsGridConfig.xs;
  const defaultCardsCount = cardsPerRow * defaultRows;

  return (
    <section css={ShowMoreGridSectionStyles.container} {...props}>
      {!hideTitle && <div className="showMoreGridPlaceholder-title" css={styles.titlePlaceholder} />}
      {renderSubSection?.()}

      <div css={styles.itemList}>
        <Grid container component="ul" css={ShowMoreGridSectionStyles.itemList} spacing={24}>
          {times(defaultCardsCount, (i) => (
            <Grid key={`placeholder-${i}`} item {...itemsGridConfig} component="li">
              {renderPlaceholderItem()}
            </Grid>
          ))}
        </Grid>
      </div>

      {!hideButton && <div css={styles.expandButtonPlaceholder} />}
    </section>
  );
};

export default ShowMoreGridSectionPlaceholder;
