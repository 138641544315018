import React, { useImperativeHandle } from 'react';

import type { MenuListProps } from '@material-ui/core';
import { MenuList as MuiMenuList } from '@material-ui/core';

import type {
  SelectOptionProps,
  SelectOption,
} from '@core/forms/Select/SelectOption';

import getMobileMenuList, { classes } from './getMobileMenuListCss';

type FocusalbleHTMLUListElement = {
  focus: () => void;
};

export type Props = {
  /**
   * Defines callback function fired when a menu item is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (any).
   * @param {object} [child] The react element that was selected when `native` is `false` (default).
   */
  onItemSelection?: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactElement<SelectOptionProps, typeof SelectOption>
  ) => void;

  /**
   * Defines selected option
   */
  value?: unknown;

  /**
   * Defines a number of visible items
   */
  visibleItemCount?: number;

  /**
   * Defines possible options
   */
  children:
    | React.ReactElement<SelectOptionProps, typeof SelectOption>
    | Array<React.ReactElement<SelectOptionProps, typeof SelectOption>>;
};

/**
 * Helper function to compare selected value and option value
 * @param {unknown} a
 * @param {unknown} b
 */
export const areEqualValues = (a: unknown, b: unknown): boolean => {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  return String(a) === String(b);
};

const MobileMenuList = (
  props: MenuListProps & Props,
  ref: React.Ref<FocusalbleHTMLUListElement>
): React.ReactElement<MenuListProps> => {
  const { children, onItemSelection, ...menuListProps } = props;

  const firstFocusableElementRef = React.useRef<HTMLLIElement>(null);

  // Handle focus internally
  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        firstFocusableElementRef.current?.focus();
      },
    };
  });

  let indexOfItemShouldReceiveFocus = -1;

  return (
    <MuiMenuList
      autoFocusItem
      classes={classes}
      css={getMobileMenuList}
      {...menuListProps}
    >
      {React.Children.map(
        children,
        (
          menuItem: React.ReactElement<SelectOptionProps, typeof SelectOption>,
          index
        ) => {
          const selected = areEqualValues(menuItem.props.value, props.value);

          const handleItemSelection = (event: unknown): void => {
            Object.defineProperty(event, 'target', {
              writable: true,
              value: { value: menuItem.props.value, name: undefined },
            });

            onItemSelection?.(
              event as React.ChangeEvent<{ value: unknown; name?: string }>,
              menuItem
            );
          };

          const handleItemKeyUp = (
            event: React.KeyboardEvent<HTMLLIElement>
          ) => {
            // Space key
            if (event.key === ' ') {
              // otherwise our MenuItems dispatches a click event
              // the select to close immediately since we open on space keydown
              event.preventDefault();
            }

            menuItem.props.onKeyUp?.(event);
          };

          const handleItemKeyDown = (
            event: React.KeyboardEvent<HTMLLIElement>
          ) => {
            if (event.key === ' ') {
              handleItemSelection(event);
            }
          };

          if (!menuItem.props.disabled) {
            if (selected) {
              indexOfItemShouldReceiveFocus = index;
            } else if (indexOfItemShouldReceiveFocus === -1) {
              indexOfItemShouldReceiveFocus = index;
            }
          }

          return React.cloneElement(menuItem, {
            'aria-selected': selected ? 'true' : undefined,
            role: 'option',
            onClick: handleItemSelection,
            onKeyUp: handleItemKeyUp,
            onKeyDown: handleItemKeyDown,
            selected: selected,
            value: undefined, // Makes sure value is not visible in DOM.
            ref:
              indexOfItemShouldReceiveFocus === index
                ? firstFocusableElementRef
                : null, // set ref if item should be focusable
          });
        }
      )}
    </MuiMenuList>
  );
};

/**
 * Styled MenuList component for mobile select
 */
export default React.forwardRef(MobileMenuList);
