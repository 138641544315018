/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import Media from 'react-media';

import classnames from 'classnames';

import { breakpoints, useTheme } from '@coursera/cds-core';

import type { ActionBarProps } from 'bundles/expression-of-interest/types';
import Container from 'bundles/xddp/components/shared/Container';

import 'css!bundles/expression-of-interest/components/__styles__/ActionBar';

type Props = {
  children: React.ReactNode;
  actionBarData?: ActionBarProps;
  isDesktop: boolean;
};

const withIsDesktopScreen = (Component: $TSFixMe) => (props: $TSFixMe) => {
  const theme = useTheme();
  return (
    <Media query={{ minWidth: breakpoints.values.md }} defaultMatches={false}>
      {(matches) => <Component {...props} isDesktop={matches} />}
    </Media>
  );
};

const renderPartnerLogo = (partnerName: $TSFixMe, partnerLogo: $TSFixMe) => {
  if (typeof partnerLogo === 'string') {
    return <img className="header-partner-logo" src={partnerLogo} alt={partnerName} />;
  } else {
    return partnerLogo;
  }
};

const renderTitleSection = (pageTitle: $TSFixMe, partnerName: $TSFixMe, partnerLogo: $TSFixMe) => (
  <div className="title-section">
    <div className="partner-logo-div">{renderPartnerLogo(partnerName, partnerLogo)} </div>
    {pageTitle && <h3 className="font-weight-bold header-title">{pageTitle}</h3>}
  </div>
);

const renderFooter = (children: $TSFixMe, isFixed: $TSFixMe) => {
  const footerClassNames = classnames('rc-StickyFooter', {
    slideUp: isFixed,
  });

  return <div className={footerClassNames}>{children}</div>;
};

const renderHeader = (
  children: $TSFixMe,
  isFixed: $TSFixMe,
  pageTitle: $TSFixMe,
  partnerName: $TSFixMe,
  partnerLogo: $TSFixMe
) => {
  const headerClassNames = classnames('rc-StickyHeader', {
    slideDown: isFixed,
  });

  return (
    <div className={headerClassNames}>
      <Container
        className="sticky-header-container"
        css={css`
          padding: 12px;
        `}
      >
        {renderTitleSection(pageTitle, partnerName, partnerLogo)}
        {children}
      </Container>
    </div>
  );
};

export const ActionBar = ({ children, isDesktop, actionBarData }: Props) => {
  if (!actionBarData) return null;

  const { isFixed, programName, partnerMonotoneLogo, partnerName } = actionBarData;

  return (
    <div className="rc-ActionBar" style={{ display: isFixed ? 'initial' : 'none' }}>
      {isDesktop
        ? renderHeader(children, isFixed, programName, partnerName, partnerMonotoneLogo)
        : renderFooter(children, isFixed)}
    </div>
  );
};

export default withIsDesktopScreen(ActionBar);
