import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('formSupportText', [
  'root',
  'invert',
]);

const getFormSupportTextCss = css`
  &.${classes.root} {
    text-align: left;
    color: var(--cds-color-neutral-primary);
    ${typography.bodySecondary};
  }

  &.${classes.invert} {
    color: var(--cds-color-neutral-primary-invert);
  }
`;

export default getFormSupportTextCss;
