import React from 'react';

import { Container } from '@material-ui/core';

import type {
  Breakpoint,
  OverrideProps,
  OverridableComponent,
} from '@coursera/cds-common';

import Grid from '@core/Grid';
import type { BaseProps as GridBaseProps } from '@core/Grid/Grid';

import getPageGridContainerCss from './getPageGridContainerCss';

type SupportedGridProps = Pick<
  GridBaseProps,
  | 'alignContent'
  | 'alignItems'
  | 'direction'
  | 'justifyContent'
  | 'wrap'
  | 'zeroMinWidth'
>;

type BaseProps = {
  /**
   * Determine the max-width of the container.
   * @default lg
   */
  maxWidth?: Extract<Breakpoint, 'lg' | 'xl'>;
  /**
   * Grid item children for the container.
   */
  children: React.ReactNode;
} & SupportedGridProps;

export interface PageGridContainerTypeMap<D extends React.ElementType = 'div'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = PageGridContainerTypeMap['defaultComponent']
> = OverrideProps<PageGridContainerTypeMap<D>, D> & {
  component?: React.ElementType;
};

/**
 * The breakpoint system and fluid grid ensure layout adaptation based on the screen size and device.
 * It provides consistency in component placement, achieving cohesion within the layout and across the experience.
 *
 * See [Props](__storybookUrl__/layout-pagegridcontainer--default#props)
 */
const PageGridContainer: OverridableComponent<PageGridContainerTypeMap> = React.forwardRef(
  function PageGridContainer(props: Props, ref: React.Ref<HTMLDivElement>) {
    const {
      children,
      maxWidth,
      justifyContent,
      direction,
      zeroMinWidth,
      alignContent,
      alignItems,
      wrap,
      ...rest
    } = props;

    return (
      <Container
        ref={ref}
        disableGutters
        css={getPageGridContainerCss}
        maxWidth={maxWidth}
        {...rest}
      >
        <Grid
          container
          alignContent={alignContent}
          alignItems={alignItems}
          direction={direction}
          justifyContent={justifyContent}
          spacing={{ xs: 8, sm: 16, md: 32 }}
          wrap={wrap}
          zeroMinWidth={zeroMinWidth}
        >
          {children}
        </Grid>
      </Container>
    );
  }
);

PageGridContainer.defaultProps = {
  maxWidth: 'lg',
};

export default PageGridContainer;
