/* Component is used in both standard env and next.js env. If adding data-fetching refactor SearchBarContainer and pass down data as props. */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { ChangeEvent } from 'react';
import * as React from 'react';

import classNames from 'classnames';
import URI from 'jsuri';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import Retracked from 'js/app/retracked';

import MagnifierSvg from 'bundles/browse/components/PageHeader/MagnifierSvg';
import type { DiscoveryCollection } from 'bundles/browse/components/types/DiscoveryCollections';
import HeroBannerSearchBar from 'bundles/front-page/components/modules/hero-banner/HeroBannerSearchBar';
import SearchBarLazyLoadInput from 'bundles/front-page/components/modules/hero-banner/SearchBarLazyLoadingInput';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import type { Props as AutoCompleteProps } from 'bundles/search-common/components/searchbar/AutoCompleteWrapper';
import { ON_SITE_SEARCH_PATH, SEARCH_BAR_LOCATION } from 'bundles/search-common/constants';
import type { SearchBarLocation } from 'bundles/search-common/constants';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';

import _t from 'i18n!nls/search-common';

import 'css!./__styles__/SearchBar';

type Props = {
  hideSearch?: boolean;
  searchIsOpen?: boolean;
  isSearchPage?: boolean;
  pageLocation?: SearchBarLocation;
  shouldFocusSearch?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  hideMobileSearchPage?: () => void;
  searchInputIsFocused?: boolean;
  collectionRecommendations?: DiscoveryCollection | null;
  enableOneStepSearch?: boolean;
  shouldShowExposedSearchAndReg?: boolean;
  smallSearchBar?: boolean;
  className?: string;
};

type State = {
  searchText: string;
  autoCompleteLoaded: boolean;
};

const styles = {
  longSearchBar: css`
    width: 600px;
  `,
  preUnifiedSearchBar: css`
    /* As per PageHeader.styl, mobile header kicks in at 1060px; these styles are for desktop only */
    @media (min-width: 1061px) {
      #algolia-placeholder-search-input {
        border-radius: 24px !important;
        padding: var(--cds-spacing-100) var(--cds-spacing-200) !important;
        border: 1px solid var(--design-sprint-gray-4, #dbe0e1) !important;
        color: var(--design-sprint-gray-2, #63676b) !important;
        line-height: 24px !important;
        font-size: 14px !important;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: 'Open Sans' !important;
        height: 44px !important;
      }

      .search-button .magnifier-wrapper {
        right: 3px;
        border-radius: 24px;
        width: 32px;
        height: 32px;
        padding: var(--cds-spacing-100);
        margin-right: var(--cds-spacing-50);
        margin-top: 6px;
        border: 1px solid transparent;
      }
    }
  `,
};

class SearchBarContent extends React.Component<Props, State> {
  declare LoadableAutoComplete: React.ComponentType<AutoCompleteProps> | null;

  static contextTypes = {
    router: PropTypes.object.isRequired,
    _eventData: PropTypes.object,
  };

  declare context: LegacyContextType<typeof SearchBarContent.contextTypes>;

  static defaultProps = {
    hideSearch: false,
    isSearchPage: false,
    enableOneStepSearch: false,
    shouldShowExposedSearchAndReg: false,
  };

  constructor(props: Props) {
    super(props);

    this.LoadableAutoComplete = null;
  }

  state = {
    searchText: '',
    autoCompleteLoaded: false,
  };

  componentDidMount() {
    this.loadAutoComplete();
  }

  loadAutoComplete = () => {
    import('bundles/search-common/components/searchbar/AutoCompleteWrapper').then((Mod) => {
      this.LoadableAutoComplete = Mod.default;
      this.setState(() => ({
        autoCompleteLoaded: true,
      }));
    });
  };

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value });
  };

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const {
      router,
      router: { location },
      _eventData,
    } = this.context;
    const { isSearchPage } = this.props;
    const { searchText } = this.state;
    const encodedSearchText = encodeURIComponent(searchText.replace(/\s+/g, ' '));

    const otherQueryParamsString = new URI(location.query).deleteQueryParam('query').query();
    const urlWithQuery = new URI(ON_SITE_SEARCH_PATH)
      .setQuery(otherQueryParamsString)
      .deleteQueryParam('page')
      .deleteQueryParam('sortBy')
      .addQueryParam('query', encodedSearchText, 0) as unknown as string;
    if (isSearchPage) {
      const newSearchProviderUrl = new URI(ON_SITE_SEARCH_PATH).addQueryParam(
        'query',
        encodedSearchText,
        0
      ) as unknown as string;
      router.push(isInFilterTestVariant() ? urlWithQuery : newSearchProviderUrl);
    } else {
      window.location.assign(urlWithQuery);
    }
    Retracked.trackComponent(
      _eventData,
      { searchText, page: window.location.pathname },
      'algolia_search_page_query_updated',
      'search'
    );
  };

  renderSearchBar = () => {
    const {
      // using false here because the default prop is false
      isSearchPage = false,
      pageLocation,
      shouldFocusSearch,
      onBlur,
      onFocus,
      searchIsOpen,
      hideMobileSearchPage,
      enableOneStepSearch,
      shouldShowExposedSearchAndReg,
      searchInputIsFocused,
      collectionRecommendations,
    } = this.props;
    const { LoadableAutoComplete } = this;
    const { searchText, autoCompleteLoaded } = this.state;
    const autoCompleteShouldFocus = shouldFocusSearch || searchInputIsFocused;

    if ((enableOneStepSearch || shouldShowExposedSearchAndReg) && autoCompleteLoaded && LoadableAutoComplete) {
      return (
        <LoadableAutoComplete
          onSearchFocus={onFocus}
          searchIsOpen={searchIsOpen}
          hideMobileSearchPage={hideMobileSearchPage}
          shouldShowExposedSearchAndReg={shouldShowExposedSearchAndReg}
          enableOneStepSearch={enableOneStepSearch}
          shouldFocusSearch={autoCompleteShouldFocus}
          collectionRecommendations={collectionRecommendations}
          isSearchPage={isSearchPage}
          startingQuery={searchText}
        />
      );
    }

    if (autoCompleteLoaded && LoadableAutoComplete) {
      return (
        <LoadableAutoComplete
          collectionRecommendations={collectionRecommendations}
          isSearchPage={isSearchPage}
          pageLocation={pageLocation}
          searchIsOpen={searchIsOpen}
          hideMobileSearchPage={hideMobileSearchPage}
          shouldFocusSearch={autoCompleteShouldFocus}
          startingQuery={searchText}
        />
      );
    }

    return pageLocation === SEARCH_BAR_LOCATION.FrontPage || pageLocation === SEARCH_BAR_LOCATION.FrontPageBanner ? (
      <HeroBannerSearchBar
        isFocused={autoCompleteShouldFocus ?? false}
        isLazyLoading={true}
        pageLocation={pageLocation}
      >
        <SearchBarLazyLoadInput value={searchText} onBlur={onBlur} onFocus={onFocus} onChange={this.onChange} />
      </HeroBannerSearchBar>
    ) : (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => (
          <div
            className="rc-SearchBar__container horizontal-box"
            {...(isSimplifiedPageHeader ? { css: styles.preUnifiedSearchBar } : {})}
          >
            <div className="mobile-magnifier">
              <MagnifierSvg fill="#4385F5" />
            </div>
            <input
              id="algolia-placeholder-search-input"
              type="text"
              // This is necessary to allow the search bar to work without rehydration
              name="query"
              placeholder={_t('What do you want to learn?')}
              value={searchText}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={this.onChange}
              autoComplete="off"
              aria-label={_t('Search catalog')}
            />
            <button type="submit" className="nostyle search-button" aria-label={_t('Submit Search')}>
              <MagnifierSvg fill="#E1E1E1" />
            </button>
          </div>
        )}
      </PageHeaderContext.Consumer>
    );
  };

  render() {
    const { hideSearch, shouldShowExposedSearchAndReg, enableOneStepSearch, smallSearchBar } = this.props;
    const { autoCompleteLoaded } = this.state;

    if (hideSearch) {
      return null;
    }

    // After lazy loading, the AutoComplete will handle the operations, but before
    // we still want to allow the user to search & be redirected accordingly
    const formProps = autoCompleteLoaded
      ? { onSubmit: (e: React.SyntheticEvent) => e.preventDefault() }
      : { onSubmit: this.onSubmit };
    const searchBarRootClasses = classNames('rc-SearchBar horizontal-box isLohpRebrand', {
      shouldShowExposedSearchAndReg,
      enableOneStepSearch: enableOneStepSearch && !shouldShowExposedSearchAndReg,
      'small-search-bar': smallSearchBar,
    });

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => (
          <div className={searchBarRootClasses} css={isSimplifiedPageHeader ? styles.longSearchBar : ''}>
            <form className="search-form" role="search" {...formProps}>
              {this.renderSearchBar()}
            </form>
          </div>
        )}
      </PageHeaderContext.Consumer>
    );
  }
}

export default SearchBarContent;
