import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('selectOption', [
  'root',
  'icon',
  'focusVisible',
  'button',
  'selected',
  'container',
]);

const baseStyles = css`
  background: var(--cds-color-interactive-background-primary-hover-weak);
  color: var(--cds-color-interactive-primary-hover);
`;

const getSelectOptionCss = css`
  padding: var(--cds-spacing-50) 0;
  overflow: visible;
  white-space: unset;
  border-radius: var(--cds-border-radius-50);

  ${typography.bodyPrimary}

  .${classes.container} {
    display: flex;
    padding: var(--cds-spacing-100);
    flex: 1 0 100%;
    max-width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: calc(var(--cds-border-radius-50) - 1px);
    box-sizing: border-box;
    color: var(--cds-color-neutral-primary);
  }

  &.${classes.selected} {
    background: var(--cds-color-interactive-background-primary-hover-weak);

    .${classes.container} {
      background: var(--cds-color-interactive-background-primary-hover-weak);
      color: var(--cds-color-interactive-primary-hover);
    }
  }

  &.${classes.focusVisible} {
    position: relative;
    z-index: 1;
    background: none;

    .${classes.container} {
      font-weight: 400;

      ${baseStyles}
    }

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 0;
      left: 0;
      border-radius: var(--cds-spacing-50);
      box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
        0 0 0 2px var(--cds-color-interactive-background-primary-hover-weak);
      outline: 1px solid transparent;
      outline-offset: 2px;
    }
  }

  .${classes.icon} {
    margin-top: 2px;
    margin-left: var(--cds-spacing-150);

    & > svg {
      display: block;
    }
  }

  &.${classes.button} {
    transition: none;
  }

  &.${classes.root} {
    &:hover {
      background: var(--cds-color-interactive-background-primary-hover-weak);

      .${classes.container} {
        ${baseStyles}
      }
    }
  }
`;

export default getSelectOptionCss;
