import type React from 'react';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import type { ButtonProps } from '@core/Button';
import Button from '@core/Button';
import i18nMessages from '@core/notifications/i18n';

export type DismissActionProps = Partial<
  Omit<ButtonProps, 'icon' | 'iconPosition' | 'size' | 'variant'>
>;

const DismissAction = (
  props: DismissActionProps
): React.ReactElement<DismissActionProps> => {
  const { children, ...rest } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  return (
    <Button
      edgeAlign="end"
      icon={<CloseIcon size="small" />}
      iconPosition="after"
      size="small"
      variant="ghost"
      {...rest}
    >
      {children || stringFormatter.format('dismiss')}
    </Button>
  );
};

export default DismissAction;
