/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import PropTypes from 'prop-types';

import Retracked from 'js/app/retracked';

import { Typography2 } from '@coursera/cds-core';
import { ChevronDownIcon } from '@coursera/cds-icons';
import { track } from '@coursera/event-pulse/sdk';

import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/megamenu';

type Props = {
  menuIsOpen: boolean;
  setButtonRef: (button: HTMLButtonElement | null) => void;
  openMenuUsingKeyboard: () => void;
  openMenu: () => void;
  closeMenu: () => void;
};

const styles = {
  exploreButton: css`
    background-color: var(--cds-color-neutral-background-primary);
    color: var(--cds-color-interactive-primary);
    border: 1px solid var(--cds-color-interactive-primary);
    min-width: max-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: default;
    padding: 6px var(--cds-spacing-200);
    margin-right: 5px;

    :hover,
    :focus,
    :active {
      color: var(--cds-color-interactive-primary-hover);
      background-color: var(--cds-color-interactive-background-primary-hover-weak);
      border-color: var(--cds-color-interactive-primary-hover);
    }
  `,
  exploreButtonLabel: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  exploreButtonText: css`
    color: var(--cds-color-interactive-primary);
  `,
};

const SimplifiedExploreButton: React.FC<Props> = (props, context) => {
  const { menuIsOpen, setButtonRef, openMenuUsingKeyboard, openMenu, closeMenu } = props;

  const toggleMenu = () => {
    if (!menuIsOpen) {
      track('open_navigation_menu', {
        navigationType: 'megamenu',
      });

      openMenuUsingKeyboard();
    } else {
      closeMenu();
    }
  };

  const onKeyUp = (evt: React.KeyboardEvent<unknown>) => {
    switch (evt.key) {
      case ' ':
        // because space does not trigger retracked click event like enter
        Retracked.trackComponent(context?._eventData, { menuIsOpen }, 'explore_button', 'click');
        evt.preventDefault();
        toggleMenu();
        break;
      case 'Enter':
        evt.preventDefault();
        toggleMenu();
        break;
      default:
    }
  };

  const openMenuWithTracking = () => {
    openMenu();

    // adding hover action here because trackedButton doesn't support tracking hover
    Retracked.trackComponent(context?._eventData, {}, 'explore_button', 'hover');
    track('open_navigation_menu', {
      navigationType: 'megamenu',
    });
  };

  const closeMenuComponent = () => {
    closeMenu();
    Retracked.trackComponent(context?._eventData, {}, 'explore_button', 'leave');
  };

  return (
    <TrackedButton
      trackingName="explore_button"
      css={styles.exploreButton}
      onMouseEnter={openMenuWithTracking}
      onMouseLeave={closeMenuComponent}
      onKeyUp={onKeyUp}
      onMouseDown={openMenu}
      aria-expanded={menuIsOpen}
      aria-haspopup={true}
      aria-label={_t('Explore our catalog')}
      data={{ menuIsOpen }}
      onSetRef={setButtonRef}
    >
      <span css={styles.exploreButtonLabel}>
        <Typography2 component="span" variant="actionPrimary" css={styles.exploreButtonText}>
          {_t('Explore')}
        </Typography2>
        <ChevronDownIcon size="small" width="12" height="12" />
      </span>
    </TrackedButton>
  );
};

SimplifiedExploreButton.contextTypes = { _eventData: PropTypes.object };

export default SimplifiedExploreButton;
