import * as React from 'react';

import { useLocation } from 'js/lib/useRouter';

import PageNavLink from 'bundles/page-header/components/page-navigation/PageNavLink';
import PageNavigation from 'bundles/page-header/components/page-navigation/PageNavigation';
import { getCareerAcademyLabel, getCareerAcademyLink } from 'bundles/page/components/shared/utils/careerAcademyUtils';
import { LOGGED_IN_HOME_PAGE_TYPE_TO_SLUG } from 'bundles/unified-home-common/constants';

import _t from 'i18n!nls/logged-in-home';

const { MY_LEARNING, HOME } = LOGGED_IN_HOME_PAGE_TYPE_TO_SLUG;

const PageNav = () => {
  const location = useLocation();
  const isOnHomePath = location.pathname === `/${HOME}`;

  return (
    <PageNavigation>
      <PageNavLink label={_t('Home')} name="home" href="/" {...(isOnHomePath ? { isCurrent: true } : {})} />
      <PageNavLink label={_t('My Learning')} name="my_learning" href={`/${MY_LEARNING}`} />
      <PageNavLink label={_t('Online Degrees')} name="online_degrees" href="/degrees" />
      <PageNavLink label={getCareerAcademyLabel()} name="online_career" href={getCareerAcademyLink('globalnav')} />
    </PageNavigation>
  );
};

export default PageNav;
