/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { forwardRef, useEffect, useState } from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';

import CountryCodeSelectField from 'bundles/account-profile/components/i18n-phone-number-input/CountryCodeSelectField';
import InternationalPhoneNumberTextField from 'bundles/account-profile/components/i18n-phone-number-input/InternationalPhoneNumberTextField';
import type {
  CustomStyles,
  InternationalPhoneNumber,
  ValidPhone,
} from 'bundles/account-profile/components/i18n-phone-number-input/types';
import { DEFAULT_REGION } from 'bundles/account-profile/components/i18n-phone-number-input/types';
import {
  isValidInternationalPhoneNumber,
  toRegionFromCountryCode,
} from 'bundles/account-profile/components/i18n-phone-number-input/utils';

import _t from 'i18n!nls/account-profile';

type InternationalPhoneNumberFieldProps = {
  customStyles?: CustomStyles;
  phone?: ValidPhone;
  isEmptyValid: boolean;
  submissionError: boolean;
  onPhoneNumberChange: (phone?: ValidPhone) => void;
  setRegion?: (region: string) => void;
  handleError?: (hasError: boolean) => void;
};

const styles = {
  widget: css`
    display: flex;
    gap: var(--cds-spacing-100);
  `,
};

function getRegionOrDefault(phone?: ValidPhone) {
  if (phone) {
    return toRegionFromCountryCode(phone.countryCode) ?? DEFAULT_REGION;
  } else {
    return DEFAULT_REGION;
  }
}

const InternationalPhoneNumberField = forwardRef<HTMLInputElement, InternationalPhoneNumberFieldProps>((props, ref) => {
  const { customStyles, phone, isEmptyValid, submissionError, onPhoneNumberChange, setRegion, handleError } = props;

  const [textFieldHasFocus, setTextFieldHasFocus] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState(getRegionOrDefault(phone));
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(
    isValidInternationalPhoneNumber(phone?.phoneNumber as InternationalPhoneNumber, selectedRegion, isEmptyValid)
  );
  const [showCustomError, setShowCustomError] = useState(false);
  const [textFieldTopOffset, setTextFieldTopOffset] = useState(0);
  const [currentInputValue, setCurrentInputValue] = useState<string | undefined>(phone?.phoneNumber);

  useEffect(() => {
    if (setRegion) {
      setRegion(selectedRegion.toString());
    }
    // Should only update on region change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    setIsValidPhoneNumber(
      isValidInternationalPhoneNumber(currentInputValue as InternationalPhoneNumber, selectedRegion, isEmptyValid)
    );
    // Only need to update the validity if the text field or country change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInputValue, selectedRegion]);

  useEffect(() => {
    if (showCustomError || !textFieldHasFocus || submissionError) {
      // Update this only when there is currently an error showing and the field has focus
      // or whenever the learner unfocuses the field
      if (isValidPhoneNumber) {
        setShowCustomError(false);
      } else {
        setShowCustomError(!!customStyles && (!isValidPhoneNumber || submissionError));
      }
    }
  }, [customStyles, textFieldHasFocus, isValidPhoneNumber, showCustomError, submissionError]);

  useEffect(() => {
    if (handleError) {
      handleError(showCustomError);
    }
  }, [showCustomError, handleError]);

  return (
    <Grid>
      <Grid item xs={12} sm css={styles.widget} data-testid="international_phone_number_field">
        <CountryCodeSelectField
          textFieldTopOffset={textFieldTopOffset}
          selectedRegion={selectedRegion}
          onRegionChange={setSelectedRegion}
        />
        <InternationalPhoneNumberTextField
          customStyles={customStyles}
          selectedRegion={selectedRegion}
          phoneNumber={phone?.phoneNumber}
          showCustomError={showCustomError}
          isEmptyValid={isEmptyValid}
          ref={ref}
          setTextFieldTopOffset={setTextFieldTopOffset}
          setCurrentInputValue={setCurrentInputValue}
          setTextFieldHasFocus={setTextFieldHasFocus}
          onPhoneNumberChange={onPhoneNumberChange}
        />
      </Grid>
      {!isValidPhoneNumber && !customStyles && (
        <Typography2 component="p" variant="bodySecondary" color="error">
          {_t('Invalid phone number')}
        </Typography2>
      )}
    </Grid>
  );
});

export default InternationalPhoneNumberField;
