/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum org_coursera_catalogp_degree_CatalogAvailabilityStatus {
  ARCHIVED = "ARCHIVED",
  LIVE = "LIVE",
  PRE_LAUNCH = "PRE_LAUNCH",
}

export enum org_coursera_course_CertificateType {
  ACE = "ACE",
  SoA = "SoA",
  Specialization = "Specialization",
  VerifiedCert = "VerifiedCert",
}

export enum org_coursera_course_CourseStatus {
  draft = "draft",
  inactive = "inactive",
  launched = "launched",
  preenroll = "preenroll",
}

export enum org_coursera_course_PremiumExperienceVariant {
  BaseVariant = "BaseVariant",
  PremiumCourse = "PremiumCourse",
  PremiumGrading = "PremiumGrading",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
