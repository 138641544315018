/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';

import PremiumHubPageHeader from 'bundles/premium-hub/components/shared/PremiumHubPageHeader';
import { HubPages } from 'bundles/premium-hub/constants';
import useLearnBrowse from 'bundles/premium-hub/hooks/useLearnBrowse';
import usePremiumHubPageData from 'bundles/premium-hub/hooks/usePremiumHubPageData';
import OverviewPageContent from 'bundles/premium-hub/pages/subpages/OverviewPageContent';
import { convertPpcPremiumProductToPremiumProductProps } from 'bundles/premium-hub/utils/dataTransformationUtils';
import DegreesCategorySchemaMarkup from 'bundles/seo/components/DegreesCategorySchemaMarkup';

import _t from 'i18n!nls/premium-hub';

const DegreeCategoryHubPageContent: React.FC = () => {
  const pageType = HubPages.DegreesCategoryHubPage;
  const { slug } = useLearnBrowse(pageType);
  const premiumHubPageData = usePremiumHubPageData({
    slug,
    pageType,
  });

  if (!premiumHubPageData) {
    return null;
  }

  const { categoryData, premiumProductList, useSimplifiedHeader = false } = premiumHubPageData;
  return (
    <>
      <PremiumHubPageHeader isSimplified={useSimplifiedHeader} />
      <main id="main" className="rc-DegreesCategoryHubPage" data-testid="degree-category-hub-page">
        <DegreesCategorySchemaMarkup
          allDegreeLists={[
            {
              degreeType: _t('Degrees'),
              title: categoryData?.name ?? '',
              degrees: premiumProductList?.map(convertPpcPremiumProductToPremiumProductProps) ?? [],
            },
          ]}
          currentHubPage={pageType}
        />
        <OverviewPageContent {...premiumHubPageData} currentPageType={pageType} shouldShowDriftChat={false} />
      </main>
    </>
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_hub',
      page: 'degree_sub',
    },
  }))
)(DegreeCategoryHubPageContent);
