import {
  AchievementIcon,
  BriefcaseIcon,
  CashIcon,
  CertificateIcon,
  CheckIcon,
  ClockOutlineIcon,
  CommentDotsIcon,
  CommentIcon,
  CorrectAnswerIcon,
  CurriculumIcon,
  CurriculumOneIcon,
  FolderIcon,
  GlobeOneIcon,
  GradingIcon,
  GraduationCapIcon,
  HelpIcon,
  InstitutionIcon,
  InstructorIcon,
  MembersIcon,
  MoneyIcon,
  OpenBookIcon,
  PodiumIcon,
  StackedFileIcon,
  StacksIcon,
  SuccessOutlineIcon,
  TargetIcon,
  TrophyIcon,
} from '@coursera/cds-icons';

// The possible input values are controlled by a select field in Contentful and is controlled/maintained by engineering
export const validIcons = {
  AchievementIcon,
  BriefcaseIcon,
  CashIcon,
  CertificateIcon,
  CheckIcon,
  ClockOutlineIcon,
  CommentDotsIcon,
  CommentIcon,
  CorrectAnswerIcon,
  CurriculumIcon,
  CurriculumOneIcon,
  FolderIcon,
  GlobeOneIcon,
  GradingIcon,
  GraduationCapIcon,
  HelpIcon,
  InstitutionIcon,
  InstructorIcon,
  MembersIcon,
  MoneyIcon,
  OpenBookIcon,
  PodiumIcon,
  StackedFileIcon,
  StacksIcon,
  SuccessOutlineIcon,
  TargetIcon,
  TrophyIcon,
};
