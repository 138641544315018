/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Grid, breakpoints, typography2, useMediaQuery } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { SectionName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import Markdown from 'bundles/cms/components/Markdown';
import ActionBar from 'bundles/expression-of-interest/components/ActionBar';
import { EoiDialog } from 'bundles/expression-of-interest/components/EoiDialog';
import HeaderLogo from 'bundles/page-header/components/HeaderLogo';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/premium-hub';

const styles = {
  container: css`
    background-color: var(--cds-color-neutral-primary-invert);
    color: var(--cds-color-neutral-primary);
    box-shadow: 0 0 24px -16px rgb(0 0 0 / 16%), 0 0 40px -8px rgb(0 0 0 / 16%);
    position: relative;
    z-index: 5;
    width: 100%;
    padding: var(--cds-spacing-300);
    border-radius: 8px;
  `,
  markdown: (theme: Theme) => css`
    .rc-Markdown.styled {
      h1,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${theme.typography.fontFamily};
      }

      a {
        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:visited {
          color: var(--cds-color-interactive-primary-pressed);
        }
      }

      h2 {
        font-size: 18px;
        font-family: inherit;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 8px;
      }

      p {
        ${typography2.bodyPrimary};
        margin: 0;
      }
    }
  `,
  content: css`
    margin-top: var(--cds-spacing-200);
    width: 100%;
  `,
};

type Props = {
  productName: string;
  productId: string;
  programInterestText?: string | null;
  eoiButtonText?: string | null;
};

/**
 * Created for DCAT experiment on old premium-hub
 */
export const EoiWidgetContainer = (props: Props): React.ReactElement => {
  const { productName, productId, programInterestText, eoiButtonText } = props;

  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const track = useTracker();

  const [isEoiOpen, setIsEoiOpen] = useState<boolean>(false);
  const [isScrollPastBanner, setScrollPastBanner] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      const hubBannerEl = document.getElementById('HubBanner');

      if (!hubBannerEl) return;

      const bannerBottom = hubBannerEl.getBoundingClientRect().bottom;
      if (bannerBottom < 0) {
        setScrollPastBanner(true);
      } else {
        setScrollPastBanner(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onOpen = (sectionName: SectionName) => {
    track('interact_eoi', {
      eoiInteraction: 'open_form',
      pageSection: {
        sectionName,
        subindex: 1, // there is only one EOI CTA on the page (the banner and sticky header are considered the same)
      },
    });

    setIsEoiOpen(true);
  };

  const actionBarData = {
    isFixed: isScrollPastBanner,
    partnerName: 'Coursera Logo',
    programName: productName,
    partnerMonotoneLogo: (
      <HeaderLogo
        disableUserInteraction={false}
        enableCourseraLogoOnly={true}
        isOpened={false}
        displaySwitcher={false}
        logoWrapper="div"
      />
    ),
  };

  const programInterestTitle = _t('Get started today');
  const programInterestDescription = _t('I am interested in learning more about degrees on Coursera.');
  const programInterestDefault = `## ${programInterestTitle}\n${programInterestDescription}`;

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" css={styles.container}>
      <ActionBar actionBarData={actionBarData}>
        <Button
          onClick={() => onOpen('sticky_page_header')}
          fullWidth={isMobile}
          component={TrackedLink2}
          trackingName="email_me_info"
          withVisibilityTracking={false}
          requireFullyVisible={false}
        >
          {eoiButtonText || _t('Email me info')}
        </Button>
      </ActionBar>

      <Grid item css={styles.markdown}>
        <Markdown source={programInterestText || programInterestDefault} />
      </Grid>

      <Grid item css={styles.content}>
        <Button
          onClick={() => onOpen('hero_banner')}
          fullWidth
          component={TrackedLink2}
          data-testid="email-info-button"
          trackingName="email_me_info"
          withVisibilityTracking={false}
          requireFullyVisible={false}
        >
          {eoiButtonText || _t('Email me info')}
        </Button>
      </Grid>

      <EoiDialog
        isOpen={isEoiOpen}
        onClose={() => setIsEoiOpen(false)}
        onOpen={() => setIsEoiOpen(true)}
        productId={productId}
      />
    </Grid>
  );
};

export default EoiWidgetContainer;
