/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/learning-assistant-chat-ai';

const styles = {
  betaTag: css`
    line-height: 1.3em;
    height: 18px;
    background-color: var(--cds-color-neutral-background-primary);
    border-radius: var(--cds-spacing-50);
    padding: 0 var(--cds-spacing-100);
  `,
};

/**
 * Customized coach design for Tag using Typography component from CDS
 */
export default function TagBeta() {
  return (
    <Typography2 variant="bodySecondary" css={styles.betaTag} component="span">
      {_t('Beta')}
    </Typography2>
  );
}
