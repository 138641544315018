import React, { useCallback } from 'react';

import useForkRef from '@material-ui/core/utils/useForkRef';

import clsx from 'clsx';

import type { IconProps } from '@coursera/cds-common';
import { useId, useLocalizedStringFormatter } from '@coursera/cds-common';
import { SearchIcon } from '@coursera/cds-icons';

import { InputAdornment } from '@core/forms';
import type { InputProps } from '@core/forms/Input';
import { Input } from '@core/forms/Input';
import { IconButton } from '@core/IconButton';
import VisuallyHidden from '@core/VisuallyHidden';

import getSearchCss, { classes } from './getSearchCss';
import i18nMessages from './i18n';
import { SearchClearButton } from './SearchClearButton';
import { SearchLoader } from './SearchLoader';

export type Props = {
  /**
   * @default default
   */
  variant?: 'default' | 'silent';

  /**
   * Search value for the text input
   */
  value?: string;

  disabled?: boolean;

  /**
   * Callback for changes to the search value
   * @returns new value
   */
  onChange: (value: string) => void;

  /**
   * Callback fired when the default Search button is clicked or Enter is pressed.
   * @returns new value
   */
  onSearch?: (value: string) => void;

  /**
   * Callback fired when the clear button is clicked or `Escape` is pressed (when `clearOnEscape=true`)
   */
  onClear?: () => void;

  /**
   * Callback fired when the input field gains focus
   */
  onFocus?: () => void;

  /**
   * Callback fired when the input field loses focus
   */
  onBlur?: () => void;

  /**
   * Whether to show a loader next to the input. Use this when async searching.
   */
  loading?: boolean;

  /**
   * Hides the default search button in the suffix area, pressing `Enter` still fires onSearch.
   * A custom search button may be implemented either outside of this component or as one of the `actionButtons`,
   * and use the controlled `value` as the final search term on click.
   *
   * Note: for visual UX, it is recommended to only have one of
   * `hideSearchButton` or `hideSearchIcon` set. Hiding both can take
   * away the visual affordance of the search field.
   *
   * @default false
   */
  hideSearchButton?: boolean;

  /**
   * Hides the default search icon in the prefix area
   *
   * Note: for visual UX, it is recommended to only have one of
   * `hideSearchIcon` or `hideSearchButton` set. Hiding both can take
   * away the visual affordance of a "search" field.
   *
   * @default false
   */
  hideSearchIcon?: boolean;

  /**
   * Screenreader-only message to show when the search is in a loading state
   * @default "Loading search results"
   */
  loadingMessage?: string;

  /**
   * Disables the default clear search behavior on `Escape`
   * @default false
   **/
  disableClearOnEscape?: boolean;

  /**
   * Extra action buttons to show in the suffix area.
   * Recommended to add no more than 2 buttons here (in addition to the default search button)
   *
   * @example
   * actionButtons={
      <>
        <IconButton intent="upload" size="small" variant="ghost" />
        <IconButton intent="setting" size="small" variant="ghost" />
      </>
    }
   **/
  actionButtons?: React.ReactNode;

  /**
   * Custom icon to show instead of the default search icon in the prefix area
   **/
  prefixIcon?: React.ReactElement<IconProps>;

  id?: string;

  autoFocus?: InputProps['autoFocus'];
  autoComplete?: InputProps['autoComplete'];
  fullWidth?: InputProps['fullWidth'];
  inputName?: InputProps['name'];
  inputProps?: InputProps['inputProps'];
  placeholder?: InputProps['placeholder'];
  readOnly?: InputProps['readOnly'];

  /**
   * Ref to the whole input container
   * @ignore
   */
  containerRef?: React.Ref<HTMLDivElement>;
} & Omit<
  InputProps,
  | 'classes'
  | 'error'
  | 'hideOutline'
  | 'inputComponent'
  | 'inputMode'
  | 'maxRows'
  | 'minRows'
  | 'multiline'
  | 'onChange'
  | 'prefix'
  | 'required'
  | 'rows'
  | 'suffix'
  | 'type'
> &
  Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>;

/**
 * The search component enables users to enter a keyword or phrase to quickly refine a
 * large set of content or to navigate based on their interest.
 *
 * See [Props](__storybookUrl__/components-inputs-search--default#props)
 */
const Search = (props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    id,
    variant = 'default',
    value = '',
    placeholder = '',
    disabled = false,
    onChange,
    onSearch,
    onClear,
    onFocus,
    onBlur,
    actionButtons,
    loading = false,
    prefixIcon = null,
    loadingMessage = '',
    inputProps,
    hideSearchButton = false,
    hideSearchIcon = false,
    disableClearOnEscape = false,
    fullWidth = false,

    className,
    autoFocus = false,
    autoComplete = undefined,
    inputName = '',
    readOnly = false,
    containerRef,
    ...rest
  } = props;

  const baseId = useId(id);
  const loadingMessageId = `${baseId}-loadingMessage`;
  const inputId = `${baseId}-input`;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleRef = useForkRef(ref, inputRef);
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange?.(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearch?.(value);
    }

    if (e.key === 'Escape') {
      e.stopPropagation();

      if (disableClearOnEscape) {
        e.preventDefault();
      } else {
        onClear?.();
      }
    }
  };

  const handleSearch = () => {
    onSearch?.(value);
  };

  const handleClear = () => {
    onChange('');
    onClear?.();

    // keep focus on input after clear
    inputRef?.current?.focus();
  };

  // for better UX, manually focus on the input for clicks on parts of the
  // component that are outside the main input e.g. the prefix area or the
  // clear button placeholder. This is only a mouse issue, keyboards will directly
  // focus on the input.
  const handleInputClick = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const showClear = !!value && !readOnly;

  const loadingDisplayMessage =
    loadingMessage || stringFormatter.format('loading_message');
  const placeholderDisplayMessage =
    placeholder || stringFormatter.format('search_placeholder');
  const inputAriaLabel =
    inputProps?.['aria-label'] || stringFormatter.format('search_placeholder');

  return (
    <div
      className={clsx(className, classes.base, {
        [classes.default]: variant === 'default',
        [classes.silent]: variant === 'silent',
      })}
      css={getSearchCss}
      {...rest}
    >
      <VisuallyHidden aria-live="polite" id={loadingMessageId}>
        {loading ? loadingDisplayMessage : ''}
      </VisuallyHidden>
      <Input
        ref={containerRef}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classes.inputRoot}
        disabled={disabled}
        fullWidth={fullWidth}
        id={inputId}
        inputProps={{
          'aria-label': inputAriaLabel,
          type: 'search',
          ...inputProps,
        }}
        inputRef={handleRef}
        name={inputName}
        placeholder={placeholderDisplayMessage}
        prefix={
          hideSearchIcon ? undefined : (
            <InputAdornment
              className={classes.prefix}
              onClick={handleInputClick}
            >
              {prefixIcon ?? <SearchIcon size="small" />}
            </InputAdornment>
          )
        }
        readOnly={readOnly}
        suffix={
          <InputAdornment aria-hidden="false" className={classes.suffix}>
            {loading && <SearchLoader />}

            {!loading && showClear && (
              <SearchClearButton disabled={disabled} onClear={handleClear} />
            )}

            {/* Placeholder to prevent layout shifting when clear button/loader is displayed.
                Also listens to clicks to invoke focus on the input.
            */}
            {!loading && !showClear && (
              <div
                className={classes.clearPlaceholder}
                onClick={handleInputClick}
              />
            )}

            <div className={classes.actionButtons}>{actionButtons}</div>

            {!hideSearchButton && (
              <IconButton
                hideTooltip
                disabled={disabled}
                intent="search"
                size="small"
                onClick={handleSearch}
              />
            )}
          </InputAdornment>
        }
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default React.forwardRef(Search);
