import { useCallback, useEffect, useRef, useState } from 'react';

import _ from 'lodash';

import useIsMounted from 'bundles/common/hooks/useIsMounted';
import { useNaptimeContext } from 'bundles/naptimejs/util/NaptimeContextAdapter';
import calculateDataRequirements from 'bundles/naptimejs/util/calculateDataRequirements';
import executeMutationBase from 'bundles/naptimejs/util/executeMutation';
import initListeners from 'bundles/naptimejs/util/initListeners';
import loadDataForClients from 'bundles/naptimejs/util/loadDataForClients';
import mergePropsWithLoadedData from 'bundles/naptimejs/util/mergePropsWithLoadedData';
import refresh from 'bundles/naptimejs/util/refresh/refresh';
import removeListeners from 'bundles/naptimejs/util/removeListeners';

/**
 * A custom hook meant to replace `Naptime.createContainer` for hookified
 * components. The API is designed to make it easy to replace legacy HoC uses,
 * but also be relatively close to modern hook solutions like `useMemo`.
 */
export default function useNaptime(getData, dependencies = []) {
  const context = useNaptimeContext();
  const naptimeStore = context.getStore('NaptimeStore');

  const isMounted = useIsMounted();
  const requestId = useRef();
  const [pending, setPending] = useState(false);
  const [storeState, setStoreState] = useState({
    allDataIsLoaded: false,
    required: true,
  });
  // Many naptime APIs expect a component class, but all they really want is
  // something with the `getWrappedComponentProps` function
  const NaptimeConnector = {
    getWrappedComponentProps: getData,
  };

  // Set the state that will be passed to the child components from the naptime
  // store.
  const getStateFromStores = () => {
    const { mergedProps, allDataIsLoaded, required } = mergePropsWithLoadedData(NaptimeConnector, context, {});
    // once allDataIsLoaded is set to true, never revert it to false.
    const dataHasBeenLoaded = allDataIsLoaded || storeState.allDataIsLoaded;
    return {
      data: mergedProps,
      allDataIsLoaded: dataHasBeenLoaded,
      required,
    };
  };

  const fetchData = useCallback(() => {
    const clientsWithoutData = calculateDataRequirements(NaptimeConnector, context, {});

    // We check if we have all of the data that this component is requesting. If
    // so, we ensure the local state `storeState` contains the most recent data.
    // Otherwise, we need to request the data from the server, and update
    // `storeState` once the request completes.
    if (isMounted()) {
      if (clientsWithoutData.length === 0) {
        const stateFromStores = getStateFromStores();
        const existingState = {
          mergedProps: storeState.mergedProps,
          allDataIsLoaded: storeState.allDataIsLoaded,
          required: storeState.required,
        };
        if (!_.isEqual(stateFromStores, existingState)) {
          setStoreState(stateFromStores);
        }
      } else if (!pending) {
        setPending(true);
        const requestListener = (id) => {
          if (id === requestId.current && isMounted()) {
            setPending(false);
            setStoreState({});
          }
          naptimeStore.removeRequestListener(id, requestListener);
        };
        requestId.current = naptimeStore.addRequestListener(requestListener);
      }
      if (clientsWithoutData.length > 0) {
        loadDataForClients(context, clientsWithoutData)
          .then((responseData) => {
            return naptimeStore.processData(requestId.current, responseData);
          })
          .done();
      }
    }
  }, dependencies);

  const refreshData = useCallback(
    (...args) => {
      return refresh(context.getStore('NaptimeStore'), context.getStore('ApplicationStore'), ...args).then(() => {
        fetchData();
      });
    },
    [fetchData]
  );

  const executeMutation = useCallback(
    (client, options = {}) => executeMutationBase(client, naptimeStore, options),
    [naptimeStore]
  );

  const { data, allDataIsLoaded, required } = getStateFromStores();

  // When a NaptimeJS component is mounted (or dependencies change), init
  // Listeners and fetch data
  useEffect(() => {
    // initListeners in particular expects the current instance, but just uses
    // it to get at the constructor, to in turn get the props mapping
    // `getWrappedComponentProps`. We just make a quick shim of the interface
    // it uses.
    const shim = {
      constructor: NaptimeConnector,
      fetchData,
    };
    initListeners(shim, {}, context);

    const shouldComponentUpdate = required ? !pending : true;
    if (shouldComponentUpdate) {
      fetchData();
    }

    // On a NaptimeJS component unmount or dependencies change, clean up all
    // listeners to avoid pollution
    return () => {
      removeListeners(shim, {}, context);
    };
  }, dependencies);

  const { naptime: injectedNaptime, ...naptimeClientData } = data;
  injectedNaptime.refreshData = refreshData;
  injectedNaptime.executeMutation = executeMutation;
  return {
    data: naptimeClientData,
    pending,
    naptime: injectedNaptime,
    _forInternalUseOnlyAllDataIsLoaded: allDataIsLoaded,
    _forInternalUseOnlyRequired: required,
  };
}
