/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useEffect, useState } from 'react';

import classNames from 'classnames';

import cookie from 'js/lib/cookie';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { getActionUrl } from 'bundles/user-account/common/lib';

import _t from 'i18n!nls/page';

const styles = {
  SignoutForm: () => css`
    width: 100%;
    height: 100%;
    padding: 0;
  `,
};

type Props = {
  mobileOnly?: boolean;
  tabIndex?: number;
  onKeyDown?: () => void;
  targetRef?: () => void;
};

const SignoutButton = ({ mobileOnly, onKeyDown, tabIndex, targetRef }: Props) => {
  const [componentDidMount, setComponentDidMount] = useState(false);

  useEffect(() => {
    setComponentDidMount(true);
  }, []);

  const csrfToken = cookie.get('CSRF3-Token') || '';
  const liClassName = classNames(
    'c-ph-right-nav-button',
    'rc-HeaderRightNavButton',
    'dropdown-btn',
    mobileOnly && 'c-ph-right-nav-mobile-only'
  );
  const action = (componentDidMount && getActionUrl('logout', csrfToken)) || '';

  return (
    <li className={liClassName} role="none">
      <form
        role="none"
        className="c-ph-right-nav-button isLohpRebrand"
        css={styles.SignoutForm}
        action={action}
        method="post"
      >
        <TrackedButton
          id="logout-btn"
          trackingName="logout_btn"
          role="menuitem"
          tabIndex={tabIndex}
          onSetRef={targetRef}
          onKeyDown={onKeyDown}
          className="sign-out"
          type="submit"
          data-popup-close
          style={{ border: 'none' }}
        >
          {_t('Log Out')}
        </TrackedButton>
      </form>
    </li>
  );
};

export default SignoutButton;
