import clsx from 'clsx';

import { classes as ContextualHelpClasses } from './getContextualHelpCss';
import getContextualHelpIconCss, { classes } from './getContextualHelpIconCss';

export type Props = {
  expanded: boolean;
  id: string;
  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;
};

/**
 * Custom implementation of animated icons that transition between the "i" and "x",
 * when the help state is toggled.
 * Adapted from https://codepen.io/BearClawz/pen/PoOBedZ/7bc25ee7e052d7fa63df7f166c3e3c6a
 */
const ContextualHelpIcon = ({ expanded, id, invert = false }: Props) => {
  return (
    <svg
      aria-hidden={true}
      className={clsx(classes.customHelpIcon, {
        [ContextualHelpClasses.expanded]: expanded,
        [classes.inverted]: invert,
      })}
      css={getContextualHelpIconCss}
      id={id}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="info-icon">
        <path
          className="i-background"
          d="M18.78,3.23A11,11,0,1,0,23,11,11,11,0,0,0,18.78,3.23Z"
        />
        <rect
          className="i-top-serif"
          height="1.99"
          width="3.62"
          x="9.38"
          y="8.71"
        />
        <rect
          className="i-bottom-serif"
          height="1.99"
          width="5.25"
          x="9.38"
          y="16.59"
        />
        <rect className="i-neck" height="9.8" width="1.99" x="11.2" y="8.8" />
        <circle className="i-dot" cx="12" cy="5.46" r="1.33" />
        <rect
          className="x-crosspath"
          height="1.99"
          width="1.43"
          x="11.29"
          y="10.99"
        />
      </g>
    </svg>
  );
};

export default ContextualHelpIcon;
