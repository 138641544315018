import phoneUtil from 'google-libphonenumber';

import countryList from 'js/json/countries';

import type {
  E164PhoneNumber,
  InternationalPhoneNumber,
  PhoneNumberCountryCode,
  PhoneNumberRegion,
  PhoneNumberRegionToDetailsMapping,
  UndefinedOr,
  ValidPhone,
} from 'bundles/account-profile/components/i18n-phone-number-input/types';
import {
  isCountryCode,
  newCountryCode,
  newE164PhoneNumber,
  newPhoneNumberRegion,
} from 'bundles/account-profile/components/i18n-phone-number-input/types';

const PhoneNumberFormat = phoneUtil.PhoneNumberFormat;
const PhoneNumberType = phoneUtil.PhoneNumberType;

function toCountryCodeFromRegion(region: PhoneNumberRegion): UndefinedOr<PhoneNumberCountryCode> {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  const code = util.getCountryCodeForRegion(region);
  return isCountryCode(code) ? code : undefined;
}

export function formatCountryCodePrefix(region: PhoneNumberRegion): UndefinedOr<string> {
  const code = toCountryCodeFromRegion(region);

  return code ? `+${code}` : undefined;
}

function parseDigits(s: string): string {
  return s.replace(/[^0-9]/g, '');
}

export function stripCountryCodeFromE164PhoneNumber(
  phoneNumber: string,
  region: PhoneNumberRegion
): UndefinedOr<string> {
  const countryCodePrefix = formatCountryCodePrefix(region);
  if (!countryCodePrefix || !phoneNumber) {
    return undefined;
  }
  const local = phoneNumber.replace(countryCodePrefix, '').trim();
  if (parseDigits(local) === parseDigits(phoneNumber)) {
    // nothing stripped
    return undefined;
  }
  return local;
}

export function stripCountryCodeFromInternationalPhoneNumber(phoneNumber?: string): UndefinedOr<string> {
  if (phoneNumber?.charAt(0) !== '+') {
    return undefined;
  }

  const local = phoneNumber.substring(phoneNumber.indexOf(' ')).trim();
  if (parseDigits(local) === parseDigits(phoneNumber)) {
    // nothing stripped
    return undefined;
  }
  return local;
}

export function toRegionFromCountryCode(code: PhoneNumberCountryCode): UndefinedOr<PhoneNumberRegion> {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  return newPhoneNumberRegion(util.getRegionCodeForCountryCode(code));
}

/**
 * Returns country mapping sorted by popularity and name (alphabetically)
 */
export function getPhoneNumberRegionToDetailsMapping(): PhoneNumberRegionToDetailsMapping {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  return countryList.reduce((acc: PhoneNumberRegionToDetailsMapping, { code, name }) => {
    const countryCode = util.getCountryCodeForRegion(code);
    return countryCode === 0
      ? acc
      : {
          ...acc,
          [code]: {
            code: countryCode,
            label: name ?? '',
          },
        };
  }, {});
}

type PhoneData = { countryCode?: string | null; phoneNumber?: string | null };

export function newValidPhone(phone: PhoneData): ValidPhone | undefined {
  if (!!phone?.countryCode && !!phone?.phoneNumber) {
    const countryCode = newCountryCode(phone.countryCode);
    if (countryCode) {
      const region = toRegionFromCountryCode(countryCode);
      if (region) {
        const phoneNumber = newE164PhoneNumber(phone.phoneNumber, region);
        if (phoneNumber) {
          return {
            countryCode,
            phoneNumber,
          };
        }
      }
    }
  }
  return undefined;
}

export function getExampleNumber(regionCode: PhoneNumberRegion): string | undefined {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  const mobilePhoneNumberExample = util.getExampleNumberForType(regionCode, PhoneNumberType.MOBILE);
  // Formatting correctly so the learner knows how to enter their phone number
  return mobilePhoneNumberExample ? util.format(mobilePhoneNumberExample, PhoneNumberFormat.INTERNATIONAL) : undefined;
}

export const isValidInternationalPhoneNumber = (
  phoneNumber: InternationalPhoneNumber | undefined,
  region: PhoneNumberRegion,
  isEmptyValid: boolean
): boolean => {
  if (!phoneNumber && isEmptyValid) {
    // Accounts for people that want to remove their phone numbers: phoneNumber === ''
    // Or when they haven't entered one yet: phoneNumber === undefined
    // NOTE: if a learner removes their phone number then refreshes the page phoneNumber === undefined
    return true;
  }

  const util = phoneUtil.PhoneNumberUtil.getInstance();
  try {
    const parsedLearnerPhoneNumber = util.parse(phoneNumber);

    return util.isValidNumberForRegion(parsedLearnerPhoneNumber, region);
  } catch {
    // This will execute if the phone number is too small to be a phone number from any region
    // e.g. +971 0
    return false;
  }
};

export function toValidPhone(
  num: InternationalPhoneNumber,
  region: PhoneNumberRegion,
  isEmptyValid: boolean
): UndefinedOr<ValidPhone> {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  const isValidPhoneNumber = isValidInternationalPhoneNumber(num, region, isEmptyValid);
  const countryCode = toCountryCodeFromRegion(region);
  const strippedPhoneNumber = stripCountryCodeFromInternationalPhoneNumber(num);
  let phoneNumber: E164PhoneNumber | '';

  if (!strippedPhoneNumber) {
    /*
     * Previously both partially complete phone numbers and an empty field (no phone number)
     * were treated as invalid phone numbers. We now consider an empty field as a valid input.
     * This is because we want to allow a learner to have the option to not enter a phone number
     * at all and be able to delete the phone number at any time.
     *
     * We continue define partially complete phone numbers as invalid learner inputs so we should
     * continue to return undefined in that case. Otherwise phoneNumber should be set to a string
     * (empty or whatever num is) because both complete and empty phone numbers are considered valid.
     */
    phoneNumber = '';
  } else {
    try {
      const parsedPhoneNumber = util.parse(num, region);
      phoneNumber = util.format(parsedPhoneNumber, PhoneNumberFormat.E164) as E164PhoneNumber;
    } catch {
      return undefined;
    }
  }

  if (isValidPhoneNumber && countryCode) {
    return {
      phoneNumber,
      countryCode,
    };
  } else {
    return undefined;
  }
}

/**
 * Returns formatted number string
 */
export const inputStringIntoFormatter = (
  formatter: libphonenumber.AsYouTypeFormatter,
  phoneNumberWithoutCountryCode: string
): string => {
  if (phoneNumberWithoutCountryCode?.length === 0) {
    return '';
  }
  let formattedNumber = '';
  const onlyDigits = parseDigits(phoneNumberWithoutCountryCode).split('');

  formatter.clear();
  onlyDigits?.forEach((digit) => {
    // accepts one digit at a time
    formattedNumber = formatter.inputDigit(digit);
  });
  return formattedNumber;
};

export const __exportForTesting__ = {
  formatCountryCodePrefix,
  toCountryCodeFromRegion,
  stripCountryCodeFromInternationalPhoneNumber,
  stripCountryCodeFromE164PhoneNumber,
  parseDigits,
};
