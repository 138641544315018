import _t from 'i18n!nls/reviews';

export const REVIEWS_PER_PAGE = 25;

export const ALL_LEARNERS_FILTER_LABEL = () => _t('All Learners');

export const COMPLETERS_FILTER_LABEL = () => _t('Completers');

export const MOST_HELPFUL_SORT_LABEL = () => _t('Most Helpful');

export const MOST_RECENT_SORT_LABEL = () => _t('Most Recent');

export const STAR_FILTER_LABEL = () => _t('All Stars');

export const UNMARKED_VOTE = 'UNMARKED_VOTE';

export const HELPFUL_VOTE = 'HELPFUL_VOTE';

export const STAR_VALUES = [
  {
    get label() {
      return _t('1 Star');
    },
    value: [1],
    dataE2eValue: '1-star',
  },
  {
    get label() {
      return _t('2 Stars');
    },
    value: [2],
    dataE2eValue: '2-stars',
  },
  {
    get label() {
      return _t('3 Stars');
    },
    value: [3],
    dataE2eValue: '3-stars',
  },
  {
    get label() {
      return _t('4 Stars');
    },
    value: [4],
    dataE2eValue: '4-stars',
  },
  {
    get label() {
      return _t('5 Stars');
    },
    value: [5],
    dataE2eValue: '5-stars',
  },
  {
    get label() {
      return _t('All Stars');
    },
    value: [1, 2, 3, 4, 5],
    dataE2eValue: 'all-stars',
  },
];

export default {
  REVIEWS_PER_PAGE,
  ALL_LEARNERS_FILTER_LABEL,
  COMPLETERS_FILTER_LABEL,
  MOST_HELPFUL_SORT_LABEL,
  MOST_RECENT_SORT_LABEL,
  STAR_VALUES,
};
