export default {
  baseWithAuth: 'https://learner.coursera.help/hc', // we rely on the bundles/help-redirects code to detect auth and use SSO if appropriate

  baseWithoutAuth: 'https://learner.coursera.help/hc',

  _linkWrapper(oldLink: string, newLink: string) {
    return this.baseWithAuth + newLink;
  },

  _articleBaseUrl(isAuthenticated: boolean) {
    return (isAuthenticated ? this.baseWithAuth : this.baseWithoutAuth) + '/articles/';
  },
  _sectionBaseUrl(isAuthenticated: boolean) {
    return (isAuthenticated ? this.baseWithAuth : this.baseWithoutAuth) + '/sections/';
  },

  getHomeLink(oldLink: string) {
    return this._linkWrapper(oldLink, '/');
  },

  getNewHelpCenterHome(isAuthenticated?: boolean) {
    if (isAuthenticated) {
      return this.baseWithAuth;
    } else {
      return this.baseWithoutAuth;
    }
  },

  getReporterLink(oldLink: string) {
    return this._linkWrapper(oldLink, '/');
  },

  getCantSignInLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '201531725-I-Can-t-Log-In';
  },

  getForumLink(oldLink: string) {
    return this._linkWrapper(oldLink, '/articles/208279996');
  },

  // remove and replace usages w/ getCodeOfConductLink when we remove support for courses w/o the new help center enabled
  getFlagLink(oldLink: string) {
    return this._linkWrapper(oldLink, '/articles/208280036');
  },

  getCodeOfConductLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '201532585-Code-of-Conduct';
  },

  getHumanGradingLink(oldLink: string) {
    return this._linkWrapper(oldLink, '/sections/201895903');
  },

  getMathAssignmentLink(oldLink?: string) {
    return this.baseWithAuth + '/articles/208279916';
  },

  getRecommendedSoftwareDevicesLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '209818543?flash_digest=75f451e7220df572270d7836a0cde07b58c0fbc5';
  },

  getVideosLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '209818683?flash_digest=a29e7280f51e4f5140cc1de96b18d6131dfa9fce';
  },

  getTyposMistakesLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '201532365-Typos-or-Mistakes-in-a-Course';
  },

  getRefundsVouchersLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '208280266';
  },

  getTypingProblemsLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '201223679-Signature-Track-Typing-Problems';
  },

  getJoiningReqirementsLink(isAuthenticated: boolean) {
    return this._articleBaseUrl(isAuthenticated) + '201523165-Joining-Requirements';
  },

  getContactFormLink(isAuthenticated?: boolean) {
    return (isAuthenticated ? this.baseWithAuth : this.baseWithoutAuth) + '/requests/new';
  },

  getOnDemandLink(isAuthenticated: boolean) {
    return this.getNewHelpCenterHome(isAuthenticated) + '/';
  },

  getPathwayEnrollmentRestrictedLink(isAuthenticated: boolean) {
    const articleBaseUrl = this._articleBaseUrl(isAuthenticated);
    return `${articleBaseUrl}208279756`;
  },

  getRequestRefundHelpLink(isAuthenticated: boolean) {
    const articleBaseUrl = this._articleBaseUrl(isAuthenticated);
    return `${articleBaseUrl}209819043-Request-a-refund`;
  },

  getEnterpriseHelpLink(isAuthenticated: boolean) {
    const articleBaseUrl = this._articleBaseUrl(isAuthenticated);
    return `${articleBaseUrl}115003774946`;
  },

  getGradingAccomplishmentsLink(isAuthenticated: boolean) {
    return (
      this._articleBaseUrl(isAuthenticated) +
      '115001243743-Check-peer-review-grades?flash_digest=c8de9073145870dcc799547a37e0c93e61496d1d'
    );
  },

  getAccountSetupLink(isAuthenticated: boolean) {
    return this._sectionBaseUrl(isAuthenticated) + '201906856';
  },
  getPaymentsSubscriptionsLink(isAuthenticated: boolean) {
    return this._sectionBaseUrl(isAuthenticated) + '201907016';
  },
  getHowToEnrollLink(isAuthenticated: boolean) {
    return this._sectionBaseUrl(isAuthenticated) + '201906926';
  },
};
