/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import * as React from 'react';

import { SpinnerIcon } from '@coursera/cds-icons';

type Props = {
  color?: 'default' | 'error' | 'invert' | 'success' | 'support' | 'interactive' | 'warning';
  className?: string;
};

const pulse = keyframes`
  0%, 50%, 100% { transform: scale(1, 1); color: #0056D2; }
  30%, 80% { transform: scale(0.92, 0.95); color: #fff; }  
`;

const styles = {
  animated: () => css`
    animation: ${pulse} 2s infinite linear;

    && {
      height: 40px;
      width: 40px;
    }
  `,
};

function LoadingIcon({ color, className }: Props): React.ReactElement {
  return <SpinnerIcon color={color} className={className} css={styles.animated} />;
}

export default LoadingIcon;
