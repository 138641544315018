import user from 'js/lib/user';

import Naptime, { useNaptime } from 'bundles/naptimejs';
import SubscriptionsV1 from 'bundles/naptimejs/resources/subscriptions.v1';

export type PropsFromUseSubscriptionsByUser = {
  subscriptions?: Array<SubscriptionsV1>;
};

export const useSubscriptionsByUser = (skip = false) => {
  const {
    data: { subscriptions },
  } = useNaptime<PropsFromUseSubscriptionsByUser>(() => {
    if (!user.isAuthenticatedUser() || skip) {
      return {};
    }
    return {
      subscriptions: SubscriptionsV1.findByUser(user.get().id, {
        // cartId and paymentInformation for existing Lite subscription needed to fetch price for Plus
        // in the same currency as the price for the existing Lite subscription
        fields: ['cartId', 'productSku', 'paymentInformation', 'startsAt', 'status'],
      }),
    };
  }, []);

  return { subscriptions };
};

export type PropsFromWithSubscriptionsByUser = {
  subscriptions?: Array<SubscriptionsV1>;
};

// Deprecated. Use useSubscriptionsByUser instead
const withSubscriptionsByUser = () =>
  Naptime.createContainer(() => {
    if (!user.isAuthenticatedUser()) {
      return {};
    }
    return {
      subscriptions: SubscriptionsV1.findByUser(user.get().id, {
        fields: ['productSku', 'startsAt', 'status'],
      }),
    };
  });

export default withSubscriptionsByUser;
