import React from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter, useId } from '@coursera/cds-common';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import ButtonBase from '@core/ButtonBase';
import type { ButtonBaseProps } from '@core/ButtonBase';
import Tag from '@core/Tag';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import i18nMessages from './i18n';
import getControllerCss, { classes } from './styles/controllerCss';

type AriaLabelledbyFunc = (ids: {
  placeholderId: string;
  counterId: string;
}) => string;

export type Props = {
  placeholder: string;
  active?: boolean;
  selectedItemsNumber?: number;
  variant?: 'outline' | 'silent';
  width?: number;
  'aria-labelledby'?: string | AriaLabelledbyFunc;
} & Omit<ButtonBaseProps, 'aria-labelledby'>;

/**
 * Renders input like button
 */
const Controller = React.forwardRef<HTMLButtonElement, Props>(
  function Controller(props, ref) {
    const {
      id: idProps,
      active,
      placeholder,
      selectedItemsNumber = 0,
      className,
      width,
      variant = 'outline',
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      ...restProps
    } = props;
    const css = getControllerCss({ width });
    const stringFormatter = useLocalizedStringFormatter(i18nMessages);
    const id = useId(idProps);
    const placeholderId = `${id}-placeholder`;
    const counterId = `${id}-counter`;

    const selectedAriaLabel = stringFormatter.format('selectedItemsLabel', {
      number: selectedItemsNumber,
    });

    return (
      <ButtonBase
        ref={ref}
        aria-expanded={active}
        aria-haspopup="listbox"
        aria-label={
          ariaLabel
            ? `${ariaLabel} ${placeholder} ${selectedAriaLabel}`
            : undefined
        }
        aria-labelledby={
          typeof ariaLabelledby === 'function'
            ? ariaLabelledby({ placeholderId, counterId })
            : ariaLabelledby
        }
        className={clsx(className, {
          [classes.active]: active,
          [classes.outlined]: variant === 'outline',
          [classes.silent]: variant === 'silent',
        })}
        css={css}
        id={id}
        {...restProps}
      >
        <Typography
          className={classes.label}
          color="inherit"
          component="span"
          id={placeholderId}
        >
          {placeholder}
        </Typography>
        <span className={classes.itemsNumber}>
          {selectedItemsNumber > 0 ? (
            <Tag
              aria-label={selectedAriaLabel}
              id={counterId}
              priority="secondary"
              variant="status"
            >
              {selectedItemsNumber}
            </Tag>
          ) : (
            <VisuallyHidden>
              {stringFormatter.format('selectedItemsLabel', { number: 0 })}
            </VisuallyHidden>
          )}
        </span>
        <span className={classes.icon}>
          {active ? (
            <ChevronUpIcon color="default" data-testid="icon-active" />
          ) : (
            <ChevronDownIcon color="default" data-testid="icon-not-active" />
          )}
        </span>
      </ButtonBase>
    );
  }
);

export default Controller;
