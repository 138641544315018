import config from 'js/app/config';
import user from 'js/lib/user';

import { track } from '@coursera/event-pulse/sdk';

import { addUtmInfo, isMetaNavEnabled } from 'bundles/ent-website/components/MetaNavigation';
import { generateUTM } from 'bundles/page/components/shared/utils/generateUTM';
import type { ReferralOfferData } from 'bundles/referral/types';

import _t from 'i18n!nls/page';

export const mobileCarouselResponsiveProperty = [
  { breakpoint: 320, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  { breakpoint: 455, settings: { slidesToShow: 2, slidesToScroll: 2 } },
  { breakpoint: 590, settings: { slidesToShow: 3, slidesToScroll: 3 } },
  { breakpoint: 730, settings: { slidesToShow: 4, slidesToScroll: 4 } },
  { breakpoint: 840, settings: { slidesToShow: 5, slidesToScroll: 5 } },
  { breakpoint: 10000, settings: { slidesToShow: 6, slidesToScroll: 6 } },
];

export const domainButtons = () => [
  {
    label: _t('Data Science'),
    name: 'data-science',
  },
  {
    label: _t('Business'),
    name: 'business',
  },
  {
    label: _t('Computer Science'),
    name: 'computer-science',
  },
  {
    label: _t('Information Technology'),
    name: 'information-technology',
  },
  {
    label: _t('Language Learning'),
    name: 'language-learning',
  },
  {
    label: _t('Health'),
    name: 'life-sciences',
  },
  {
    label: _t('Personal Development'),
    name: 'personal-development',
  },
  {
    label: _t('Physical Science and Engineering'),
    name: 'physical-science-and-engineering',
  },
  {
    label: _t('Social Sciences'),
    name: 'social-sciences',
  },
  {
    label: _t('Arts and Humanities'),
    name: 'arts-and-humanities',
  },
  {
    label: _t('Math and Logic'),
    name: 'math-and-logic',
  },
];

export const megamenuButtons = () => {
  const buttons = [
    {
      href: '/courses?query=free',
      label: _t('Take a free course'),
      name: 'free-course',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/courses?query=free',
          megamenuItemName: 'free-course',
          megamenuItemType: 'other',
          megamenuSection: 'goals',
        });
      },
    },
    {
      label: _t('Earn a Degree'),
      name: 'degree',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-degree',
          megamenuSection: 'goals',
        });
      },
    },
    {
      label: _t('Earn a Certificate'),
      name: 'certificate',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-certificate',
          megamenuSection: 'goals',
        });
      },
    },
  ];

  return buttons;
};

export const learningGoals = () => {
  const buttons = [
    {
      label: _t('Earn a Degree'),
      name: 'degree',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-degree',
          megamenuSection: 'goals',
        });
      },
    },
    {
      label: _t('Earn a Certificate'),
      name: 'certificate',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-certificate',
          megamenuSection: 'goals',
        });
      },
    },
    {
      href: '/courses?query=free',
      label: _t('Take a free course'),
      name: 'free-course',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/courses?query=free',
          megamenuItemName: 'free-course',
          megamenuItemType: 'other',
          megamenuSection: 'goals',
        });
      },
    },
  ];
  return buttons;
};

export const goals = () => {
  const buttons = [
    {
      href: '/courses?query=free',
      label: _t('Take a free course'),
      name: 'free-course',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/courses?query=free',
          megamenuItemName: 'free-course',
          megamenuItemType: 'other',
          megamenuSection: 'goals',
        });
      },
    },
    {
      label: _t('Earn a Degree'),
      name: 'degree',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-degree',
          megamenuSection: 'goals',
        });
      },
    },
    {
      label: _t('Earn a Certificate'),
      name: 'certificate',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-certificate',
          megamenuSection: 'goals',
        });
      },
    },
    {
      href: '/career-academy',
      label: _t('Find your new career'),
      name: 'career',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'find-new-career',
          megamenuSection: 'goals',
        });
      },
    },
  ];
  return buttons;
};

export const exploreButton = () => ({
  label: _t('Explore goals and subjects'),
  name: 'exploreButton',
});

const helpCenterButtons = () => [
  {
    href: 'https://partner.coursera.help/hc',
    label: _t('Educator Resource Center'),
    name: 'partner-help',
    onButtonClick: () => {
      track('click_profile_menu_item', {
        profileItemLink: 'https://partner.coursera.help/hc',
        profileItemName: 'partner-help',
      });
    },
  },
  {
    href: 'https://learner.coursera.help',
    label: _t('Help Center'),
    name: 'help',
    onButtonClick: () => {
      track('click_profile_menu_item', {
        profileItemLink: 'https://learner.coursera.help',
        profileItemName: 'help',
      });
    },
  },
];

const updatesAndAccomplishmentsButtons = () => [
  {
    href: '/updates',
    label: _t('Updates'),
    name: 'updates',
    onButtonClick: () => {
      track('click_profile_menu_item', {
        profileItemLink: '/updates',
        profileItemName: 'updates',
      });
    },
  },
  {
    href: '/accomplishments',
    label: _t('Accomplishments'),
    name: 'accomplishments',
    onButtonClick: () => {
      track('click_profile_menu_item', {
        profileItemLink: '/accomplishments',
        profileItemName: 'accomplishments',
      });
    },
  },
];

export const browseButton = () => ({
  href: '/browse',
  label: _t('Explore'),
  name: 'browse',
  onButtonClick: () => {
    track('click_megamenu_item', {
      megamenuItemLink: '/browse',
      megamenuItemName: 'browse',
      megamenuItemType: 'view_all_link',
      megamenuSection: 'subjects',
    });
  },
});

export const browseAllSubjectsButton = () => ({
  href: '/browse',
  label: _t('Browse all subjects'),
  name: 'browse',
  onButtonClick: () => {
    track('click_megamenu_item', {
      megamenuItemLink: '/browse',
      megamenuItemName: 'browse',
      megamenuItemType: 'view_all_link',
      megamenuSection: 'subjects',
    });
  },
});

const adminButton = () => ({
  href: '/admin',
  label: _t('Educator Admin'),
  name: 'admin',
  onButtonClick: () => {
    track('click_profile_menu_item', {
      profileItemLink: '/admin',
      profileItemName: 'admin',
    });
  },
});

const enterpriseButton = () => ({
  href: '/business',
  label: _t('For Enterprise'),
  name: 'enterprise',
});

const businessButton = () => ({
  href: addUtmInfo(`${config.url.base}business`, '/business'),
  label: _t('For Businesses'),
  name: 'businesses',
  onButtonClick: () => {
    track('click_meta_nav', {
      metaNavValue: 'for_businesses',
    });
  },
});

const campusButton = () => ({
  href: addUtmInfo(`${config.url.base}campus`, '/campus'),
  label: _t('For Universities'),
  name: 'universities',
  onButtonClick: () => {
    track('click_meta_nav', {
      metaNavValue: 'for_universities',
    });
  },
});

const governmentButton = () => ({
  href: addUtmInfo(`${config.url.base}government`, '/government'),
  label: _t('For Governments'),
  name: 'government',
  onButtonClick: () => {
    track('click_meta_nav', {
      metaNavValue: 'for_governments',
    });
  },
});

const onlineDegreesButton = () => ({
  label: _t('Online Degrees'),
  name: 'onlineDegrees',
});

const forStudentButton = (): { href: string; label: string; name: string; onButtonClick?: () => void } => {
  return {
    href: generateUTM({
      href: `${config.url.base}campus`,
      utmCampaign: 'website',
      utmContent: 'corp-to-landing-for-campus',
      utmMedium: 'coursera',
      utmSource: 'header',
      utmTerm: `c-${user.isAuthenticatedUser() ? 'in' : 'out'}`,
    }),
    label: _t('For Universities'),
    name: 'student',
    onButtonClick: () => {
      track('click_meta_nav', {
        metaNavValue: 'for_universities',
      });
    },
  };
};

const forCareerAcademyButton = () => ({
  label: '',
  name: 'career-academy',
  onButtonClick: () => {
    track('click_megamenu_item', {
      megamenuItemLink: '/career-academy',
      megamenuItemName: 'career-academy',
      megamenuItemType: 'other',
      megamenuSection: 'goals',
    });
  },
});

export const loggedInNavButtons = ({
  isEnterprise,
  referralOfferData,
  shouldHideDomains,
  shouldHideMegamenu,
}: {
  isEnterprise: boolean;
  referralOfferData?: ReferralOfferData;
  shouldHideDomains?: boolean;
  shouldHideMegamenu?: boolean;
}) => {
  const enterpriseButtons = [
    browseButton(),
    adminButton(),
    {
      href: '/',
      label: _t('My Coursera'),
      name: 'dashboard',
    },
    {
      label: _t('Account'),
      name: 'account',
    },
    {
      label: _t('Cart'),
      name: 'cart',
    },
    ...helpCenterButtons(),
    enterpriseButton(),
    forStudentButton(),
    onlineDegreesButton(),
    forCareerAcademyButton(),
    ...updatesAndAccomplishmentsButtons(),
  ];
  const calculatedDomainButtons = shouldHideDomains ? [] : domainButtons();
  const calculatedMegamenuButtons = shouldHideMegamenu ? [] : megamenuButtons();
  const domainAndMegaMenuButtons = [...calculatedMegamenuButtons, ...calculatedDomainButtons];

  const regularButtons = [
    adminButton(),
    {
      label: _t('Your account'),
      name: 'account',
    },
    enterpriseButton(),
    onlineDegreesButton(),
    forCareerAcademyButton(),
    ...domainAndMegaMenuButtons,
    ...helpCenterButtons(),
    forStudentButton(),
  ];

  const metaNavButtons = [
    adminButton(),
    {
      label: _t('Your account'),
      name: 'account',
    },
    businessButton(),
    campusButton(),
    governmentButton(),
    onlineDegreesButton(),
    forCareerAcademyButton(),
    ...domainAndMegaMenuButtons,
    ...helpCenterButtons(),
  ];

  let navButtons: NavButtonType[];
  if (isEnterprise) {
    navButtons = enterpriseButtons;
  } else if (isMetaNavEnabled()) {
    navButtons = metaNavButtons;
  } else {
    navButtons = regularButtons;
  }

  if (referralOfferData?.shouldShowCourseraPlusReferralOffer) {
    navButtons.push(referralOfferData.button());
  }

  return navButtons;
};

export const getConsumerLoggedInNavButtons = ({ referralOfferData }: { referralOfferData: ReferralOfferData }) => {
  const navButtons: NavButtonType[] = [
    {
      label: _t('Your account'),
      name: 'account',
    },
    adminButton(),
    ...helpCenterButtons(),
    enterpriseButton(),
    forStudentButton(),
  ];

  if (referralOfferData.shouldShowCourseraPlusReferralOffer) {
    navButtons.push(referralOfferData.button());
  }

  return navButtons;
};

export const getEnterpriseLoggedInNavButtons = ({ referralOfferData }: { referralOfferData: ReferralOfferData }) => {
  const navButtons: NavButtonType[] = [
    {
      label: _t('Account'),
      name: 'account',
    },
    {
      label: _t('Cart'),
      name: 'cart',
    },
    adminButton(),
    ...helpCenterButtons(),
    enterpriseButton(),
    forStudentButton(),
  ];

  if (referralOfferData.shouldShowCourseraPlusReferralOffer) {
    navButtons.push(referralOfferData.button());
  }

  return navButtons;
};

export const getSimplifiedLoggedInNavButtons = (
  referralOfferData?: ReferralOfferData,
  showMetaNav?: boolean,
  programSlug?: string,
  subNavLinks?: NavButtonType[],
  hasDomains?: boolean,
  closeMenu?: () => void
) => {
  const baseUrl = programSlug ? `/programs/${programSlug}/` : `/`;
  const navButtons: NavButtonType[] = hasDomains
    ? [
        {
          label: _t('Explore'),
          name: 'simplifiedExploreButton',
        },
        {
          href: `${baseUrl}my-learning`,
          label: _t('My Learning'),
          name: 'myLearning',
          onButtonClick: () => {
            closeMenu?.();
          },
        },
      ]
    : [
        {
          href: `${baseUrl}my-learning`,
          label: _t('My Learning'),
          name: 'myLearning',
          onButtonClick: () => {
            closeMenu?.();
          },
        },
      ];

  if (subNavLinks) {
    const filteredNavLinks = subNavLinks
      .filter((link) => link.name !== 'home' && link.name !== 'my learning')
      .map((link) => {
        return {
          ...link,
          onButtonClick: () => {
            closeMenu?.();
          },
        };
      });

    navButtons.push(...filteredNavLinks);
  }
  if (referralOfferData?.shouldShowCourseraPlusReferralOffer) {
    navButtons.push(referralOfferData.button());
  }

  if (showMetaNav) {
    navButtons.push(businessButton(), governmentButton(), campusButton());
  }
  return navButtons;
};

export const getSimplifiedLoggedOutNavButtons = (showMetaNav?: boolean, hasDomains?: boolean) => {
  const navButtons: NavButtonType[] = hasDomains
    ? [
        {
          label: _t('Explore'),
          name: 'simplifiedExploreButton',
        },
      ]
    : [];

  if (showMetaNav) {
    navButtons.push(businessButton(), governmentButton(), campusButton());
  }
  return navButtons;
};

export const loggedInAccountButtons = ({ isEnterprise }: { isEnterprise: boolean }) => {
  let navButtons: NavButtonType[] = [
    {
      href: '/account-profile',
      label: _t('Profile'),
      name: 'profile',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/account-profile',
          profileItemName: 'profile',
        });
      },
    },
    {
      href: '/account-settings',
      label: _t('Settings'),
      name: 'settings',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/account-settings',
          profileItemName: 'settings',
        });
      },
    },
    {
      href: '/my-purchases/transactions',
      label: _t('My Purchases'),
      name: 'purchases',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/my-purchases/transactions',
          profileItemName: 'purchases',
        });
      },
    },
  ];

  if (isEnterprise) {
    navButtons = [...updatesAndAccomplishmentsButtons(), ...navButtons];
  }
  return navButtons;
};

export const getLoggedInAccountButtons = () => {
  const navButtons: NavButtonType[] = [
    {
      href: '/account-profile',
      label: _t('Profile'),
      name: 'profile',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/account-profile',
          profileItemName: 'profile',
        });
      },
    },
    {
      href: '/my-purchases/transactions',
      label: _t('My Purchases'),
      name: 'purchases',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/my-purchases/transactions',
          profileItemName: 'purchases',
        });
      },
    },
    {
      href: '/account-settings',
      label: _t('Settings'),
      name: 'settings',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/account-settings',
          profileItemName: 'settings',
        });
      },
    },
    {
      href: '/accomplishments',
      label: _t('Accomplishments'),
      name: 'accomplishments',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: '/accomplishments',
          profileItemName: 'accomplishments',
        });
      },
    },
    {
      href: 'https://learner.coursera.help',
      label: _t('Help Center'),
      name: 'help',
      onButtonClick: () => {
        track('click_profile_menu_item', {
          profileItemLink: 'https://learner.coursera.help',
          profileItemName: 'help',
        });
      },
    },
  ];
  return navButtons;
};

export const authButtonClasses = {
  signup: 'signUp-btn',
  login: 'logIn-btn',
};

export const loggedOutAuthButtons = () => [
  {
    label: _t('Join for Free'),
    authMode: 'signup' as const,
    wrapperClassName: 'mobile-header-btn',
    linkClassName: `${authButtonClasses.signup} mobile-auth-btn`,
  },
  {
    label: _t('Log In'),
    authMode: 'login' as const,
    wrapperClassName: 'mobile-header-btn',
    linkClassName: `${authButtonClasses.login} mobile-auth-btn`,
  },
];

export const loggedOutSignUpButton = () => [
  {
    label: _t('Join for Free'),
    authMode: 'signup' as const,
    wrapperClassName: 'mobile-header-btn',
    linkClassName: `${authButtonClasses.signup} mobile-auth-btn`,
  },
];
export const loggedOutLogInButton = () => [
  {
    label: _t('Log In'),
    authMode: 'login' as const,
    wrapperClassName: 'mobile-header-btn',
    linkClassName: `${authButtonClasses.login} mobile-auth-btn`,
  },
];

export const loggedOutNavButtons = (
  isEnterprise: boolean,
  shouldHideDomains?: boolean,
  shouldHideMegamenu?: boolean
) => {
  const enterpriseButtons = [
    browseButton(),
    enterpriseButton(),
    forStudentButton(),
    onlineDegreesButton(),
    forCareerAcademyButton(),
  ];
  const calculatedDomainButtons = shouldHideDomains ? [] : domainButtons();
  const calculatedMegamenuButtons = shouldHideMegamenu ? [] : megamenuButtons();

  const regularButtons = [
    enterpriseButton(),
    forStudentButton(),
    onlineDegreesButton(),
    forCareerAcademyButton(),
    ...calculatedMegamenuButtons,
    ...calculatedDomainButtons,
    browseAllSubjectsButton(),
  ];

  const metaNavButtons = [
    businessButton(),
    campusButton(),
    governmentButton(),
    onlineDegreesButton(),
    forCareerAcademyButton(),
    ...calculatedMegamenuButtons,
    ...calculatedDomainButtons,
    browseAllSubjectsButton(),
  ];
  if (isEnterprise) {
    return enterpriseButtons;
  } else if (isMetaNavEnabled()) {
    return metaNavButtons;
  } else {
    return regularButtons;
  }
};

export const MOBILE_TOPIC_COLUMN_HEIGHT = 100;

export const NUMBER_OF_DOMAINS = 11;

export const TRANSITION_DELAY = 300;

export const ACCOUNT_PAGE_IDENTIFIER = 'ACCOUNT_MENU';

export const EXPLORE_PAGE_IDENTIFIER = 'EXPLORE_MENU';

export const EXPLORE_SUBMENU_PAGE_IDENTIFIER = 'EXPLORE_SUBMENU';

export const DEGREES_PAGE_IDENTIFIER = 'DEGREES_MENU';

export const CERTIFICATE_PAGE_IDENTIFIER = 'CERTIFICATE_MENU';

export const CERTS_PAGE_IDENTIFIER = 'CERTS_MENU';

export const DOMAIN_PAGE_IDENTIFIER = 'DOMAIN_MENU';

export const PREMIUM_PROGRAMS_PAGE_IDENTIFIER = 'PREMIUM_PROGRAMS_MENU';

export const ENTERPRISE_EXPLORE_PAGE_IDENTIFIER = 'ENTERPRISE_EXPLORE_SUBMENU';

export const CONSUMER_EXPLORE_PAGE_IDENTIFIER = 'CONSUMER_EXPLORE_SUBMENU';

export const MD_SCREEN_BREAKPOINT_PX = 992;

export type NavButtonType = {
  href?: string | null;
  label: string;
  name: string;
  onButtonClick?: () => void;
};

export type SwitcherSelectionType = {
  id: string;
  selectionType: string;
  programId?: string | null;
  degreeId?: string | null;
};

const exported = {
  /**
   * List of definitions for deep-linkable pages.
   * ORDER MATTERS! If there are multiple pattern matches, the first definition
   *   will take effect.
   *
   * @type {Array} List of {
   *   page: <String> human-readable, unique identifier for the page
   *   pattern: <RegExp> window pathname that the definition applies to
   *   webToMobilePathTranslator: <Function> that changes the window pathname
   *     to mobile deep-link target pathname.
   * }
   */
  deepLinkablePages: [
    {
      page: 'browse-subdomain',
      pattern: /\/browse\/(.+)\/(.+)/,
      webToMobilePathTranslator: (path: $TSFixMe) => path,
    },
    {
      page: 'browse-domain',
      pattern: /\/browse\/([a-z]+)(.+)/,
      webToMobilePathTranslator: (path: $TSFixMe) => path,
    },
    {
      page: 'browse-root',
      pattern: /\/browse/,
      webToMobilePathTranslator: (path: $TSFixMe) => path.split('/').slice(0, 2).join('/'), // watch out for trailing '/'
    },
    {
      page: 'sdp',
      pattern: /\/specializations\/([a-z]+)(.+)/,
      webToMobilePathTranslator: (path: $TSFixMe) => path,
    },
    {
      page: 'ondemand-cdp-resource',
      pattern: /\/learn\/(.+)\/(supplement|exam|quiz|lecture)\/(.+)\/(.+)/,
      webToMobilePathTranslator: (path: $TSFixMe) => path.split('/').slice(0, 5).join('/'),
    },
    {
      page: 'ondemand-cdp-home-welcome',
      pattern: /\/learn\/(.+)\/home\/welcome/,
      webToMobilePathTranslator: (path: $TSFixMe) => path.split('/').slice(0, 5).join('/'),
    },
    {
      page: 'ondemand-cdp',
      pattern: /\/learn\/([^\/]+)/,
      webToMobilePathTranslator: (path: $TSFixMe) => path,
    },
  ],

  bannerCourseraLogoPath: 'images/favicons/apple-touch-icon-144x144.png',
};

export default exported;

export const { deepLinkablePages, bannerCourseraLogoPath } = exported;
