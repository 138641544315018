import * as React from 'react';
import Media from 'react-media';

import config from 'js/app/config';
import user from 'js/lib/user';

import { isMetaNavEnabled } from 'bundles/ent-website/components/MetaNavigation';
import HeaderRightNavButton from 'bundles/page-header/components/HeaderRightNavButton';
import { generateUTM } from 'bundles/page/components/shared/utils/generateUTM';

import _t from 'i18n!nls/page';

type Props = {
  hideEnterprise?: boolean;
  showAdminLinks?: boolean;
  showExploreCatalog?: boolean;
  isEnterprise?: boolean;
};

const StudentLink = ({ hideEnterprise, showAdminLinks, showExploreCatalog, isEnterprise }: Props) => {
  const isEnabled = isMetaNavEnabled();

  if (isEnabled) {
    return null;
  }

  const shouldShowStudentLink = !hideEnterprise && !showAdminLinks && !showExploreCatalog && !isEnterprise;

  if (shouldShowStudentLink) {
    const label = _t('For Universities');
    const shortLabel = _t('Universities');
    const href = generateUTM({
      href: `${config.url.base}campus`,
      utmCampaign: 'website',
      utmContent: 'corp-to-landing-for-campus',
      utmMedium: 'coursera',
      utmSource: 'header',
      utmTerm: `a-${user.isAuthenticatedUser() ? 'in' : 'out'}`,
    });
    const forStudentsButtonProps = {
      href,
      label,
      name: 'student',
      noBorder: true,
      wrapperClassName: 'c-ph-student',
    };
    return (
      <Media query={{ maxWidth: 1200, minWidth: 925 }} defaultMatches={false}>
        {(matches) =>
          matches ? (
            <HeaderRightNavButton {...forStudentsButtonProps} key={label} buttonWrapperType="span" label={shortLabel} />
          ) : (
            <HeaderRightNavButton {...forStudentsButtonProps} key={label} buttonWrapperType="span" />
          )
        }
      </Media>
    );
  } else {
    return null;
  }
};

export default StudentLink;
