import type { Moment } from 'moment';
import moment from 'moment';

import { LONG_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import type { ProgramAdmissionDeadlines } from 'bundles/degree-description/types';
import { isPerformancePathwayDegree } from 'bundles/expression-of-interest/utils/util';

import _t from 'i18n!nls/degree-description';

export const DEADLINE_TYPES = {
  priorityDeadline: 'priorityDeadline',
  subtermDeadline: 'subtermDeadline',
  extendedSubtermDeadline: 'extendedSubtermDeadline',
  upcomingAdmissions: 'upcomingAdmissions',
  noUpcomingAdmissions: 'noUpcomingAdmissions',
  openAdmissionsNoDeadline: 'openAdmissionsNoDeadline',
  performanceBasedAdmission: 'performanceBasedAdmission',
  pbaUpcomingAdmissions: 'pbaUpcomingAdmissions',
} as const;

type DeadlineType = keyof typeof DEADLINE_TYPES;
type DeadlineCopy = {
  header?: string;
  body: string;
};

export const adjustForTimezone = (date: Moment, slug: string, timezones: Record<string, number>) => {
  const timezone = timezones[slug];
  if (timezone) {
    return date.utcOffset(timezone);
  }
  return date;
};

export const getCopy = (deadlineType: DeadlineType, date: string | null): DeadlineCopy => {
  const copy = {
    priorityDeadline: {
      header: _t('The priority deadline to apply for the upcoming cohort is #{priorityDeadlineDate}.', {
        priorityDeadlineDate: date,
      }),
      body: _t('Start your application or request more information.'),
    },
    subtermDeadline: {
      header: _t('The deadline to apply for the upcoming cohort is #{subtermDeadlineDate}.', {
        subtermDeadlineDate: date,
      }),
      body: _t('Start your application or request more information.'),
    },
    extendedSubtermDeadline: {
      header: _t(
        'The application deadline has been extended! Apply for the upcoming cohort by #{extendedSubtermDeadlineDate}.',
        { extendedSubtermDeadlineDate: date }
      ),
      body: _t('Start your application or request more information.'),
    },
    upcomingAdmissions: {
      header: _t('Applications for the upcoming cohort open #{admissionStartDate}.', {
        admissionStartDate: date,
      }),
      body: _t('Request info to learn more.'),
    },
    pbaUpcomingAdmissions: {
      header: _t('Enrollments for the upcoming cohort open #{admissionStartDate}.', {
        admissionStartDate: date,
      }),
      body: _t('Request info to learn more.'),
    },
    noUpcomingAdmissions: {
      header: _t('Applications for the upcoming cohort open soon.'),
      body: _t('Request info to learn more.'),
    },
    openAdmissionsNoDeadline: {
      header: _t('Applications are now open for the upcoming cohort.'),
      body: _t('Apply or request more information.'),
    },
    performanceBasedAdmission: {
      header: _t('Enroll by #{subtermDeadlineDate} to join the upcoming cohort.', {
        subtermDeadlineDate: date,
      }),
      body: _t('Start your enrollment today or request more information.'),
    },
  };

  return copy[deadlineType];
};

export const getDeadlineCopyForDegreeCard = (deadlineType: DeadlineType, date: string | null): DeadlineCopy => {
  const copy = {
    priorityDeadline: {
      body: _t('Application due #{priorityDeadlineDate}', { priorityDeadlineDate: date }),
    },
    subtermDeadline: {
      body: _t('Due #{subtermDeadlineDate}', { subtermDeadlineDate: date }),
    },
    extendedSubtermDeadline: {
      body: _t('Due #{extendedSubtermDeadlineDate}', { extendedSubtermDeadlineDate: date }),
    },
    upcomingAdmissions: {
      body: _t('Due #{admissionStartDate}', { admissionStartDate: date }),
    },
    pbaUpcomingAdmissions: {
      body: _t('Due #{admissionStartDate}', { admissionStartDate: date }),
    },
    noUpcomingAdmissions: {
      body: _t('Application deadline to be announced'),
    },
    openAdmissionsNoDeadline: {
      body: _t('Applications now open'),
    },
    performanceBasedAdmission: {
      body: _t('Due #{subtermDeadlineDate}', { subtermDeadlineDate: date }),
    },
  };
  return copy[deadlineType];
};

export const getCtaModuleDeadlineCopy = (
  slug: string,
  programAdmissionDeadlines: ProgramAdmissionDeadlines,
  degreeTimezones: Record<string, number>,
  customGetCopy: (datelineType: DeadlineType, date: string | null) => DeadlineCopy
): DeadlineCopy => {
  if (programAdmissionDeadlines.length > 0) {
    const now = moment();
    const firstDeadline = programAdmissionDeadlines[0];
    const admissionStartDate = adjustForTimezone(
      moment.utc(firstDeadline.admissionStartDate).endOf('day'),
      slug,
      degreeTimezones
    );
    const subtermDeadlineDate = firstDeadline.subtermDeadlineDate
      ? adjustForTimezone(moment.utc(firstDeadline.subtermDeadlineDate).endOf('day'), slug, degreeTimezones)
      : null;
    const extendedSubtermDeadlineDate = firstDeadline.extendedSubtermDeadlineDate
      ? adjustForTimezone(moment.utc(firstDeadline.extendedSubtermDeadlineDate).endOf('day'), slug, degreeTimezones)
      : null;
    const priorityDeadlineDate = firstDeadline.priorityDeadlineDate
      ? adjustForTimezone(moment.utc(firstDeadline.priorityDeadlineDate).endOf('day'), slug, degreeTimezones)
      : null;
    const secondaryPriorityDeadlineDate = firstDeadline.secondaryPriorityDeadlineDate
      ? adjustForTimezone(moment.utc(firstDeadline.secondaryPriorityDeadlineDate).endOf('day'), slug, degreeTimezones)
      : null;

    // pbaUpcomingAdmissions
    if (now < admissionStartDate && isPerformancePathwayDegree(slug)) {
      return customGetCopy(
        DEADLINE_TYPES.pbaUpcomingAdmissions,
        formatDateTimeDisplay(admissionStartDate, LONG_DATE_ONLY_DISPLAY)
      );
    }
    // performanceBasedAdmission
    if (subtermDeadlineDate && now < subtermDeadlineDate && isPerformancePathwayDegree(slug)) {
      return customGetCopy(
        DEADLINE_TYPES.performanceBasedAdmission,
        formatDateTimeDisplay(subtermDeadlineDate, LONG_DATE_ONLY_DISPLAY)
      );
    }
    // upcomingAdmissions
    if (now < admissionStartDate) {
      return customGetCopy(
        DEADLINE_TYPES.upcomingAdmissions,
        formatDateTimeDisplay(admissionStartDate, LONG_DATE_ONLY_DISPLAY)
      );
    }

    // priorityDeadline
    if (priorityDeadlineDate && now < priorityDeadlineDate) {
      return customGetCopy(
        DEADLINE_TYPES.priorityDeadline,
        formatDateTimeDisplay(priorityDeadlineDate, LONG_DATE_ONLY_DISPLAY)
      );
    }
    // priorityDeadline (secondary)
    if (secondaryPriorityDeadlineDate && now < secondaryPriorityDeadlineDate) {
      return customGetCopy(
        DEADLINE_TYPES.priorityDeadline,
        formatDateTimeDisplay(secondaryPriorityDeadlineDate, LONG_DATE_ONLY_DISPLAY)
      );
    }
    // subtermDeadline
    if (subtermDeadlineDate && now < subtermDeadlineDate) {
      return customGetCopy(
        DEADLINE_TYPES.subtermDeadline,
        formatDateTimeDisplay(subtermDeadlineDate, LONG_DATE_ONLY_DISPLAY)
      );
    }
    // extendedSubtermDeadline
    if (extendedSubtermDeadlineDate && now < extendedSubtermDeadlineDate) {
      return customGetCopy(
        DEADLINE_TYPES.extendedSubtermDeadline,
        formatDateTimeDisplay(extendedSubtermDeadlineDate, LONG_DATE_ONLY_DISPLAY)
      );
    }
    // openAdmissionsNoDeadline
    return customGetCopy(DEADLINE_TYPES.openAdmissionsNoDeadline, null);
  }
  // noUpcomingAdmissions
  return customGetCopy(DEADLINE_TYPES.noUpcomingAdmissions, null);
};

const updateNextDeadline = (
  nextDeadlineDate: moment.Moment | undefined,
  currentDateValue: moment.Moment | null
): moment.Moment | undefined => {
  const now = moment();
  if (currentDateValue !== null && now.isBefore(currentDateValue)) {
    return !nextDeadlineDate ? currentDateValue : moment.min(nextDeadlineDate, currentDateValue);
  }
  return nextDeadlineDate;
};

export const getDeadlineCopyForHubPage = (
  programAdmissionDeadlines: ProgramAdmissionDeadlines,
  customGetCopy: (datelineType: DeadlineType, date: string | null) => DeadlineCopy
): DeadlineCopy => {
  if (programAdmissionDeadlines.length > 0) {
    // match the logic on BE, pick the min date of the priority, secondary priority, subterm, extended subterm
    let nextDt;
    for (const current of programAdmissionDeadlines) {
      nextDt = updateNextDeadline(nextDt, moment(current.priorityDeadlineDate));
      nextDt = updateNextDeadline(nextDt, moment(current.secondaryPriorityDeadlineDate));
      nextDt = updateNextDeadline(nextDt, moment(current.subtermDeadlineDate));
      nextDt = updateNextDeadline(nextDt, moment(current.extendedSubtermDeadlineDate));
    }

    if (nextDt) {
      return customGetCopy(DEADLINE_TYPES.priorityDeadline, formatDateTimeDisplay(nextDt, LONG_DATE_ONLY_DISPLAY));
    }
  }
  // no deadline
  return { body: '' };
};

export const getDegreeDeadlineType = (slug: string, programAdmissionDeadlines: ProgramAdmissionDeadlines) => {
  if (programAdmissionDeadlines.length > 0) {
    const now = moment();
    const firstDeadline = programAdmissionDeadlines[0];
    const admissionStartDate = moment.utc(firstDeadline.admissionStartDate);
    const subtermDeadlineDate = firstDeadline.subtermDeadlineDate
      ? moment.utc(firstDeadline.subtermDeadlineDate)
      : null;
    const extendedSubtermDeadlineDate = firstDeadline.extendedSubtermDeadlineDate
      ? moment.utc(firstDeadline.extendedSubtermDeadlineDate)
      : null;
    const priorityDeadlineDate = firstDeadline.priorityDeadlineDate
      ? moment.utc(firstDeadline.priorityDeadlineDate)
      : null;
    const secondaryPriorityDeadlineDate = firstDeadline.secondaryPriorityDeadlineDate
      ? moment.utc(firstDeadline.secondaryPriorityDeadlineDate)
      : null;

    // upcomingAdmissions
    if (now < admissionStartDate) {
      return DEADLINE_TYPES.upcomingAdmissions;
    }
    // performanceBasedAdmission
    if (subtermDeadlineDate && now < subtermDeadlineDate && isPerformancePathwayDegree(slug)) {
      return DEADLINE_TYPES.performanceBasedAdmission;
    }
    // priorityDeadline
    if (priorityDeadlineDate && now < priorityDeadlineDate) {
      return DEADLINE_TYPES.priorityDeadline;
    }
    // priorityDeadline (secondary)
    if (secondaryPriorityDeadlineDate && now < secondaryPriorityDeadlineDate) {
      return DEADLINE_TYPES.priorityDeadline;
    }
    // subtermDeadline
    if (subtermDeadlineDate && now < subtermDeadlineDate) {
      return DEADLINE_TYPES.subtermDeadline;
    }
    // extendedSubtermDeadline
    if (extendedSubtermDeadlineDate && now < extendedSubtermDeadlineDate) {
      return DEADLINE_TYPES.extendedSubtermDeadline;
    }
    // openAdmissionsNoDeadline
    return DEADLINE_TYPES.openAdmissionsNoDeadline;
  }
  // noUpcomingAdmissions
  return DEADLINE_TYPES.noUpcomingAdmissions;
};
