/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import DegreesStickyActionBar from 'bundles/premium-hub/components/degrees/DegreesStickyActionBar';
import Container from 'bundles/premium-hub/components/shared/Container';
import HubBreadcrumbs from 'bundles/premium-hub/components/shared/HubBreadcrumbs';
import { HubPages } from 'bundles/premium-hub/constants';
import type { ProductType } from 'bundles/premium-hub/constants';

type Props = {
  slug: string;
  productId: string;
  pageHeading?: Maybe<string>;
  pageSubheading?: Maybe<string>;
  categoryName?: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  productType?: (typeof ProductType)[keyof typeof ProductType];
  surveyProductType: string;
  cta?: React.ReactNode;
};

const styles: Record<string, CSSProperties> = {
  hubBannerWrapper: (bannerColor: string, isEOIPresent: boolean, theme: Theme) => ({
    background: bannerColor,
    width: '100vw',
    position: 'relative',
    maxWidth: '100%',

    '.BannerContainer': {
      position: 'relative',
      maxWidth: 1440,
    },

    '.BannerRow': {
      padding: 'var(--cds-spacing-400) 0 var(--cds-spacing-600)',
      [breakpoints.down('md')]: {
        padding: 'var(--cds-spacing-150) 0 var(--cds-spacing-300)',
      },
      [breakpoints.down('sm')]: {
        padding: 'var(--cds-spacing-150) 0',
      },
    },

    '.BannerContentCol': {
      paddingLeft: '0',
      paddingRight: '0',
      margin: '48px 0px 16px 0px',
      ...(!isEOIPresent && { marginBottom: 'var(--cds-spacing-400)' }),

      [breakpoints.down('md')]: {
        padding: 0,
      },

      [breakpoints.down('xs')]: {
        margin: '10px 0px 0px 0px',
      },
    },

    '.BannerEOICol': {
      [breakpoints.down('md')]: {
        marginTop: 'var(--cds-spacing-150)',
        width: '100%',
      },

      '.rc-DegreesFilterAndEoiContainer': {
        width: '350px',
        [breakpoints.down('sm')]: {
          width: '100%',
          '.rc-EmbeddedRegForm': {
            width: '100%',
          },
        },
        '.rc-DegreesFilterContainer': {
          margin: 'auto',
        },
      },
    },
  }),
  headingMobile: (theme: Theme) => ({
    margin: '0 0 var(--cds-spacing-400) 0',
    padding: '0',
    [breakpoints.down('sm')]: {
      textAlign: 'left',
    },

    [breakpoints.down('xs')]: {
      margin: '0 0 var(--cds-spacing-150) 0',
    },
  }),
  horizontalWidgetContainer: () => css`
    position: relative;
    top: -80px;
    margin-bottom: -80px;
    ${breakpoints.down('md')} {
      top: -64px;
      margin-bottom: -64px;
    }
    ${breakpoints.down('sm')} {
      top: 0;
      margin-bottom: 0;
      margin-top: var(--cds-spacing-150);
    }
  `,
};

const getBannerColor = (theme: Theme, currentHubPage: Props['currentHubPage']) => {
  switch (currentHubPage) {
    case HubPages.MainHubPage:
      return 'var(--cds-color-blue-950)';
    case HubPages.ProductVariantHubPage:
      return 'var(--cds-color-blue-50)';
    case HubPages.DegreesCategoryHubPage:
      return 'var(--cds-color-purple-25)';
    default:
      return 'var(--cds-color-blue-950)';
  }
};

const HubBanner: React.FunctionComponent<Props> = ({
  slug,
  pageHeading,
  pageSubheading,
  categoryName,
  currentHubPage,
  productType,
  productId,
  surveyProductType,
  cta,
}) => {
  const theme = useTheme();

  if (!pageHeading) {
    return null;
  }
  const bannerColor = getBannerColor(theme, currentHubPage);
  const headingColor = currentHubPage === HubPages.MainHubPage ? 'invertBody' : 'body';

  const filterWidget = (
    <DegreesStickyActionBar
      slug={slug}
      pageHeading={pageHeading}
      categoryName={categoryName}
      productId={productId}
      surveyProductType={surveyProductType}
    />
  );

  const bannerContent = (
    <>
      <Grid item className="BannerContentCol" md={12} lg={12}>
        <HubBreadcrumbs productName={categoryName || pageHeading} productType={productType} />
        <Typography variant="d2semibold" component="h1" color={headingColor} css={styles.headingMobile(theme)}>
          {pageHeading}
        </Typography>
        {pageSubheading && (
          <Typography variant="h2" component="p" color={headingColor} css={styles.headingMobile}>
            {pageSubheading}
          </Typography>
        )}
        {cta}
      </Grid>
    </>
  );

  return (
    <>
      <Grid
        container
        justifyContent="center"
        id="HubBanner"
        data-test="HubBanner"
        css={styles.hubBannerWrapper(bannerColor, !!productId, theme)}
      >
        <Grid container className="BannerContainer BannerRow">
          <Container>{bannerContent}</Container>
        </Grid>
      </Grid>
      <Container>
        <Grid container>{filterWidget}</Grid>
      </Container>
    </>
  );
};

export default React.memo(HubBanner);
