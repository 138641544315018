/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';
import { compose, setDisplayName } from 'recompose';

import { Typography2, breakpoints } from '@coursera/cds-core';

import withFragments from 'bundles/graphql/components/withFragments';
import ReviewsRatingBarGraph from 'bundles/reviews-common/components/ReviewsRatingBarGraph';
import TopReviewCards from 'bundles/reviews-common/components/cds/TopReviewCards';
import CourseTotalRatings from 'bundles/video-logged-out-page/components/course-content/CourseTotalRatings';
import type { Rating, ReviewOverview, TopReview } from 'bundles/video-logged-out-page/types/vlpSharedTypes';

import _t from 'i18n!nls/video-logged-out-page';

type Props = {
  ratings?: Rating | null;
  topReviews?: Array<TopReview> | null;
  reviewOverview?: ReviewOverview;
};

const styles = {
  courseReviews: css`
    .rc-CourseReviews {
      .chart-and-info-container {
        margin-bottom: 22px;

        .rc-CourseTotalRatings.extended-review-data {
          display: flex;
          align-items: center;

          ${breakpoints.down('sm')} {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }

          .rating-data {
            font-size: 14px;
            font-weight: bold;
            padding-left: 0.5rem;
            padding-bottom: 4px;
          }
        }
      }
    }
  `,
};

export const CourseReviews: React.FC<Props> = ({ ratings, reviewOverview, topReviews }) => {
  if ((!topReviews && !reviewOverview) || !ratings) return null;

  let lastTwoTopReviews;
  if (topReviews && topReviews.length > 1) {
    lastTwoTopReviews = topReviews.slice(-2);
  }

  return (
    <div className="rc-CourseReviews vlp-container-section" css={styles.courseReviews}>
      <Typography2 component="h3" variant="subtitleMedium">
        {_t('Reviews')}
      </Typography2>
      {reviewOverview && ratings && (
        <section data-unit="ratings-and-graph" className="chart-and-info-container border-bottom">
          <CourseTotalRatings
            rootClassName="extended-review-data"
            ratings={ratings}
            totalReviewCount={reviewOverview.totalReviewCount}
            a11yIdentifier="CourseReview"
          />
          <ReviewsRatingBarGraph
            totalReviewCount={reviewOverview.totalReviewCount}
            countsByStar={reviewOverview.countsByStar}
          />
        </section>
      )}
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'CDPPageQuery_XdpV1Resource_slug_elements_xdp... Remove this comment to see the full error message */}
      {lastTwoTopReviews && <TopReviewCards topReviews={lastTwoTopReviews} />}
    </div>
  );
};

export const StarCountBreakdownFragment = gql`
  fragment StarCountBreakdownFragment on ProductReviewOverviewV1 {
    countsByStar
    totalReviewCount
  }
`;

export default compose<Props, Props>(
  setDisplayName('VLPReviewsSection'),
  withFragments({
    starCountBreakdown: StarCountBreakdownFragment,
  })
)(CourseReviews);
