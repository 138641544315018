import * as React from 'react';

import className from 'classnames';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import { stringify } from 'query-string';
import { compose } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import cookie from 'js/lib/cookie';
import user from 'js/lib/user';

import type { Namespaces } from 'bundles/epic/client';
import SurveysExperiments from 'bundles/epic/clients/Surveys';
import {
  getDefaultSurveyDescription,
  getDefaultSurveyNoButtonText,
  getDefaultSurveyTitle,
  getDefaultSurveyYesButtonText,
} from 'bundles/growth-upsell/user-intent-survey/surveyConstants';
import { surveyUrls } from 'bundles/growth-upsell/user-intent-survey/surveyUrls';
import Modal from 'bundles/phoenix/components/Modal';

import 'css!./__styles__/SurveyModal';

declare global {
  interface Window {
    requestCountry: string;
  }
}

type DateList = { startDate: string; endDate: string };

type CountryCode = 'US' | 'GB' | 'IN';

type PropsFromCaller = {
  pageType: 'degreesHub' | 'homePage' | 'XDP';
  epicParam: keyof Namespaces['Surveys'];
  cookieName: string;
  dateList?: Array<DateList>;
  showForCountryCode?: CountryCode;
  getCustomTitle?: () => string;
  getCustomDescription?: () => string;
  getCustomNoButtonText?: () => string;
  getCustomYesButtonText?: () => string;
};

type PropsToComponent = PropsFromCaller & {
  requestCountryCode: string;
  authenticated: boolean;
};

type State = {
  showSurveyModal: boolean;
  slideModalUp: boolean;
};

class SurveyModal extends React.Component<PropsToComponent, State> {
  state = { showSurveyModal: false, slideModalUp: false };

  previousActiveElement: Element | null = null;

  componentDidMount() {
    const { requestCountryCode, epicParam, cookieName, dateList, showForCountryCode } = this.props;
    const countryCodeMatches = showForCountryCode === requestCountryCode;

    let showSurvey;
    if (dateList !== undefined) {
      showSurvey = countryCodeMatches && SurveysExperiments.get(epicParam) && this.isInDefinedDateRange();
    } else {
      showSurvey = countryCodeMatches && SurveysExperiments.get(epicParam);
    }

    if (showSurvey) {
      const userCookie = cookie.get(cookieName);
      // cookie used to only show survey once per qualified user
      if (userCookie !== 'true') {
        setTimeout(this.handleOpen, 4000);
        cookie.set(cookieName, 'true', { days: 30 });
      }
    }
  }

  handleYesResponse = () => {
    const { pageType, requestCountryCode, authenticated, showForCountryCode } = this.props;
    // Ensures that only the typed country codes are used
    if (showForCountryCode !== requestCountryCode) return;

    const sessionId = cookie.get('__204u');
    const baseUrl = authenticated
      ? surveyUrls[pageType][requestCountryCode as CountryCode]?.loggedIn
      : surveyUrls[pageType][requestCountryCode as CountryCode]?.loggedOut;
    const userId = authenticated ? user.get().id : '';
    const customVariables = userId
      ? stringify({ requestCountryCode, sessionId, userId })
      : stringify({ requestCountryCode, sessionId });
    const surveyUrl = baseUrl + customVariables;
    this.handleClose();
    const surveyTab = window.open(surveyUrl);
    if (surveyTab) {
      surveyTab.focus();
    }
  };

  handleNoResponse = () => {
    (this.previousActiveElement as HTMLElement)?.focus?.();
    this.handleClose();
  };

  handleClose = () => {
    this.previousActiveElement = null;
    this.setState({ slideModalUp: false }, () => {
      // stop rendering the modal once the close animation is finished
      setTimeout(() => {
        this.setState({ showSurveyModal: false });
      }, 700);
    });
  };

  handleOpen = () => {
    this.previousActiveElement = document.activeElement;
    this.setState({ showSurveyModal: true });
    setTimeout(() => {
      this.setState({ slideModalUp: true });
    }, 1000);
  };

  isInDefinedDateRange = () => {
    const { dateList } = this.props;
    if (dateList === undefined) {
      return false;
    }

    const currDate = new Date();

    const matchingRange = dateList.find((dateRange) => {
      const { startDate: startDateString, endDate: endDateString } = dateRange;

      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      if (currDate >= startDate && currDate <= endDate) {
        return true;
      } else {
        return false;
      }
    });

    return matchingRange !== undefined;
  };

  render() {
    const { showSurveyModal, slideModalUp } = this.state;
    const { getCustomTitle, getCustomDescription, getCustomNoButtonText, getCustomYesButtonText } = this.props;

    if (showSurveyModal) {
      return (
        <div className={className('rc-SurveyModal', { slideModalUp })}>
          <Modal
            type="popup"
            modalName="SurveyModal"
            labelledById="surveyModal~title"
            describedById="surveyModal~description"
            handleClose={this.handleNoResponse}
          >
            <div className="c-modal-top-border" />
            <div className="vertical-box">
              <h2 id="surveyModal~title" className="c-modal-title text-xs-center m-t-2 m-b-1 h3">
                {getCustomTitle ? getCustomTitle() : getDefaultSurveyTitle()}
              </h2>
              <p id="surveyModal~description" className="c-modal-text">
                {getCustomDescription ? getCustomDescription() : getDefaultSurveyDescription()}
              </p>
              <div className="c-modal-actions horizontal-box align-items-spacearound">
                <button
                  type="button"
                  className="c-modal-button passive c-survey-modal-btn"
                  onClick={this.handleNoResponse}
                >
                  {getCustomNoButtonText ? getCustomNoButtonText() : getDefaultSurveyNoButtonText()}
                </button>
                <button
                  type="button"
                  className="c-modal-button primary c-survey-modal-btn"
                  onClick={this.handleYesResponse}
                >
                  {getCustomYesButtonText ? getCustomYesButtonText() : getDefaultSurveyYesButtonText()}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default compose<PropsToComponent, PropsFromCaller>(
  connectToStores<PropsToComponent, PropsFromCaller>(['ApplicationStore'], ({ ApplicationStore }, props) => ({
    ...props,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
    authenticated: ApplicationStore.isAuthenticatedUser(),
  }))
)(SurveyModal);
