import type { ReactText } from 'react';
import { useState } from 'react';

const valueLength = (
  defaultValue?: string | number,
  value?: string | number
) => {
  if (typeof value !== 'undefined') {
    return String(value).length;
  }

  if (typeof defaultValue !== 'undefined') {
    return String(defaultValue).length;
  }

  return 0;
};

const useCharacterLimit = (options: {
  characterLimit?: number;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /**
   * Callback fired when the limit is reached and after it.
   * @param charactersTooMany characters over the limit
   */
  onLimitReached?: (charactersTooMany: number) => void;

  value?: ReactText;
  defaultValue?: ReactText;
}): {
  charactersLeft?: number;
  isLimitReached: boolean;

  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
} => {
  const {
    characterLimit,
    defaultValue,
    value,
    onLimitReached,
    onChange,
  } = options;

  const isControlled = typeof value !== 'undefined';

  const [charactersLeft, setCharactersLeft] = useState(() => {
    if (characterLimit) {
      return characterLimit - valueLength(defaultValue, value);
    }

    return characterLimit;
  });

  let internalCharactersLeft = charactersLeft;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Update state only if not controlled
    if (!isControlled) {
      const charactersEntered = event.target.value.length;

      if (characterLimit) {
        const left = characterLimit - charactersEntered;

        setCharactersLeft(left);

        if (left <= 0) {
          onLimitReached?.(Math.abs(left));
        }
      }
    }

    onChange?.(event);
  };

  if (characterLimit && isControlled) {
    internalCharactersLeft = characterLimit - valueLength(defaultValue, value);
  }

  return {
    isLimitReached: internalCharactersLeft ? internalCharactersLeft < 0 : false,
    charactersLeft: internalCharactersLeft,
    onChange: handleChange,
  };
};

export default useCharacterLimit;
