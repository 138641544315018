/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import { useTheme } from '@coursera/cds-core';

type Props = {
  isCompact?: boolean;
};

const placeholderShimmer = keyframes`
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
`;

const useStyle = () => {
  const { palette } = useTheme();

  return {
    shimmer: css`
      animation: ${placeholderShimmer} 1.3s linear 0s infinite normal forwards;
      background: linear-gradient(
        to right,
        ${palette.gray[200]} 8%,
        ${palette.gray[300]} 18%,
        ${palette.gray[200]} 33%
      );
      background-size: 1000px 36px;
      overflow: hidden;
      width: 160px;
      height: 36px;
      margin-right: 14px;
      margin-top: 30px;
      background-color: ${palette.gray[200]};
    `,
    compactMode: css`
      margin-top: var(--cds-spacing-150) !important;
    `,
  };
};

const SearchFilterPlaceholder = ({ isCompact }: Props) => {
  const styles = useStyle();
  return <div css={[styles.shimmer, isCompact && styles.compactMode]} data-testid="placeholder" />;
};

export default SearchFilterPlaceholder;
