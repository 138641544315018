import React from 'react';

export type DialogContextType = {
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  contentWidth: string;
  headingGroupId?: string;
};

const defaultContext: DialogContextType = {
  contentWidth: 'auto',
};

const DialogContext = React.createContext<DialogContextType>(defaultContext);

export const useDialogContext = (): DialogContextType => {
  return React.useContext(DialogContext);
};

type Props = {
  children?: React.ReactNode;
} & DialogContextType;

export const DialogContextProvider = ({
  onClose,
  contentWidth,
  headingGroupId,
  children,
}: Props) => {
  return (
    <DialogContext.Provider value={{ onClose, contentWidth, headingGroupId }}>
      {children}
    </DialogContext.Provider>
  );
};
