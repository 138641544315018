/** @jsxFrag React.Fragment */
import * as React from 'react';

import { useQuery } from '@apollo/client/react';
import * as Sentry from '@sentry/react';
import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import { useLocation } from 'js/lib/useRouter';
import type UserAgentInfo from 'js/lib/useragent';

import { useLocalizedContent } from 'bundles/internationalization-lib/hooks/useLocalizedContent';
import useUserAgent from 'bundles/page/hooks/useUserAgent';
import type { HubPageContentFieldsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import ExpandedCertificatesContainer from 'bundles/premium-hub/components/certificates/ExpandedCertificatesContainer';
import Container from 'bundles/premium-hub/components/shared/Container';
import Faqs from 'bundles/premium-hub/components/shared/Faqs';
import HubBanner from 'bundles/premium-hub/components/shared/HubBanner';
import InfoCard from 'bundles/premium-hub/components/shared/InfoCard';
import LearnMore from 'bundles/premium-hub/components/shared/LearnMore';
import LearningCommunitySection from 'bundles/premium-hub/components/shared/LearningCommunitySection';
import UnifiedCategoryIntro from 'bundles/premium-hub/components/shared/UnifiedCategoryIntro';
import UnifiedInstructorSpotlight from 'bundles/premium-hub/components/shared/UnifiedInstructorSpotlight';
import UnifiedInstructorsList from 'bundles/premium-hub/components/shared/UnifiedInstructorsList';
import VideoTestimonials from 'bundles/premium-hub/components/shared/VideoTestimonials';
import {
  CATEGORY_ORDER,
  CERTIFICATE_HUB_SLUG_TO_PRODUCT_VARIANTS,
  HubPages,
  LOCALIZED_PAGE_CONTENT_FIELDS,
  ProductType,
  UnifiedHubProductVariant,
} from 'bundles/premium-hub/constants';
import { getCertificatesByProductVariant } from 'bundles/premium-hub/queries';
import { HubPageContentFieldsFragmentDoc } from 'bundles/premium-hub/queries/contentfulQueries';
import type { CertificateProps } from 'bundles/premium-hub/types';
import { getSlugForCategory, getTitleForCategory } from 'bundles/premium-hub/utils';
import { CourseraMetatags } from 'bundles/seo';
import HubPageGoogleSchemaMarkup from 'bundles/seo/components/HubPageGoogleSchemaMarkup';
import { getSubdomainDetails } from 'bundles/seo/utils/domainAndMetadataUtils';

type CertificatesByProductVariantData = {
  CertificatesByProductVariantResponse?: { certificateList: Array<CertificateProps> };
};

type CertificatesByProductVariantVariables = { productVariantsQueryParam: string };

type CertificateCategories = Record<
  string,
  {
    title: string;
    slug: string;
    certificates: Array<CertificateProps>;
  }
>;

type Props = {
  pageContent?: HubPageContentFieldsFragment | null;
  certificatesList?: Array<CertificateProps>;
  categorizedCertificates?: CertificateCategories;
  userAgent: UserAgentInfo | undefined;
};

const SLUG_PROFESSIONAL_CERTIFICATE = 'professional-certificates';

const SURVEY_PRODUCT_ID = {
  mastertrack: 'mastertrack',
  learn: 'ucert',
  'professional-certificate': undefined,
};

export const generateProductVariantsQueryParam = (productVariants: Array<string>) => {
  // In order to query multiple product variants for the GetCertificateLists gRPC
  // we must repeat the property 'productVariants' with each product variant in the URL query params.
  // Source: https://github.com/googleapis/googleapis/blob/7cde5d0df08801e00fc45df1546942aa2692d5c3/google/api/http.proto#L119
  return productVariants
    .map((variant) => {
      return `productVariants=${variant}`;
    })
    .join('&');
};

export const categorizeCertificates = (certificatesList?: Array<CertificateProps>, productVariants?: Array<string>) => {
  if (
    !certificatesList ||
    certificatesList.length === 0 ||
    !productVariants ||
    !productVariants?.includes(UnifiedHubProductVariant.ProfessionalCertificate)
  ) {
    return {
      certificatesList,
    };
  }

  const categorizedCertificates: CertificateCategories = CATEGORY_ORDER.reduce((total, current) => {
    return {
      ...total,
      [current]: {
        title: getTitleForCategory(current),
        slug: getSlugForCategory(current),
        certificates: [],
      },
    };
  }, {});

  certificatesList.forEach((certificateInfo) => {
    const { categories } = certificateInfo;

    if (!categories) {
      return;
    }

    categories.forEach((category) => {
      if (categorizedCertificates[category]) {
        categorizedCertificates[category].certificates.push(certificateInfo);
      }
    });
  });

  return {
    categorizedCertificates,
    certificatesList,
  };
};

export const CertificatesVariantHubPage = ({
  pageContent,
  certificatesList,
  categorizedCertificates,
  userAgent,
}: Props) => {
  const location = useLocation();

  if (!pageContent) {
    return null;
  }

  const {
    slug,
    pageHeading,
    pageSubheading,
    associatedProgramsHeading,
    categoryLinksCollection,
    productVariantCardsCollection,
    learnMoreInfoHeading,
    learnMoreCardsCollection,
    learningCommunityTitle,
    communityStatsCollection,
    faqSectionHeader,
    faqsCollection,
    extraInformation,
    videoTestimonialsHeader,
    primaryVideoTestimonialOverride,
    secondaryVideoTestimonialsOverrideCollection,
    instructorMosaicHeader,
    instructorsCollection,
    instructorSpotlightSectionTitle,
    instructorSpotlightDescription,
    instructorHeadshot,
    seoInformation,
    categoryIntroDescription,
    profilePicture,
    learnerName,
    enrolledProgram,
  } = pageContent;

  const { isInternationalSubdomain } = getSubdomainDetails(location);
  const shouldDisableHrefLangGeneration = CERTIFICATE_HUB_SLUG_TO_PRODUCT_VARIANTS[slug || '']?.includes(
    UnifiedHubProductVariant.Mastertrack
  );

  return (
    <>
      <main data-test="CertificatesVariantHubPage">
        <HubPageGoogleSchemaMarkup productList={certificatesList} />
        <CourseraMetatags
          metaNameAndProperties={{
            title: seoInformation?.metaTitle ?? undefined,
            description: seoInformation?.metaDescription ?? undefined,
            disableCourseraSuffix: !seoInformation?.enableCourseraSuffix,
            disableCourseraDescriptionSuffix: !seoInformation?.enableCourseraDescriptionSuffix,
            disableDescLimit: !seoInformation?.enableDescriptionLimit,
            disableCrawlerIndexing: isInternationalSubdomain && shouldDisableHrefLangGeneration,
          }}
          hrefLangOverrides={(links) => (shouldDisableHrefLangGeneration ? [] : links)}
        />
        <HubBanner
          slug={slug}
          pageHeading={pageHeading}
          pageSubheading={pageSubheading}
          currentHubPage={HubPages.ProductVariantHubPage}
          productType={ProductType.Certificates}
          productId={SURVEY_PRODUCT_ID[slug as keyof typeof SURVEY_PRODUCT_ID]}
          surveyProductType="GenericPage"
        />
        <Container>
          <ExpandedCertificatesContainer
            categorizedCertificates={categorizedCertificates}
            certificatesList={certificatesList}
            title={associatedProgramsHeading}
            categoryLinks={categoryLinksCollection?.items}
            productVariantCards={productVariantCardsCollection?.items}
            productId={CERTIFICATE_HUB_SLUG_TO_PRODUCT_VARIANTS[slug as string][0]}
            userAgent={userAgent}
          />
          <UnifiedCategoryIntro
            categoryIntroDescription={categoryIntroDescription}
            profilePicture={profilePicture}
            learnerName={learnerName}
            enrolledProgram={enrolledProgram}
          />
          <LearnMore
            userAgent={userAgent}
            learnMoreInfoHeading={learnMoreInfoHeading}
            learnMoreCards={learnMoreCardsCollection?.items}
          />
          <VideoTestimonials
            videoTestimonialsHeader={videoTestimonialsHeader}
            primaryVideoTestimonialOverride={primaryVideoTestimonialOverride}
            secondaryVideoTestimonialsOverride={secondaryVideoTestimonialsOverrideCollection?.items}
            productTypes={[slug] as Array<string>}
          />
          <LearningCommunitySection
            learningCommunityTitle={learningCommunityTitle}
            communityStats={communityStatsCollection?.items}
          />
          <UnifiedInstructorsList
            instructorMosaicHeader={instructorMosaicHeader}
            instructors={instructorsCollection?.items}
          />
          <UnifiedInstructorSpotlight
            sectionTitle={instructorSpotlightSectionTitle}
            profileDescription={instructorSpotlightDescription}
            profileImage={instructorHeadshot}
          />
          <InfoCard userAgent={userAgent} marketingContent={extraInformation} />
        </Container>
        <Faqs faqSectionHeader={faqSectionHeader} faqs={faqsCollection?.items} />
      </main>
    </>
  );
};

const CertificatesVariantHubPageDataFetcher = () => {
  const userAgent = useUserAgent();
  const location = useLocation();
  const isPreview = Number(location.query.preview ?? 0) === 1;
  const pathName = location.pathname.replace(/\/+$/, '');
  let slug = pathName.split('/').pop();

  if (slug === SLUG_PROFESSIONAL_CERTIFICATE) {
    slug = slug.slice(0, -1);
  }

  if (!slug) slug = '';

  const { data: certificatesByProductVariantData, loading: certificatesLoading } = useQuery<
    CertificatesByProductVariantData,
    CertificatesByProductVariantVariables
  >(getCertificatesByProductVariant, {
    variables: {
      productVariantsQueryParam: generateProductVariantsQueryParam(CERTIFICATE_HUB_SLUG_TO_PRODUCT_VARIANTS[slug]),
    },
    errorPolicy: 'all',
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching certificates by product variant',
        },
      }),
  });

  const { certificatesList, categorizedCertificates } = categorizeCertificates(
    certificatesByProductVariantData?.CertificatesByProductVariantResponse?.certificateList,
    CERTIFICATE_HUB_SLUG_TO_PRODUCT_VARIANTS[slug]
  );

  const { data: pageContent, loading: pageContentLoading } = useLocalizedContent<HubPageContentFieldsFragment>({
    isPreview,
    contentType: LOCALIZED_PAGE_CONTENT_FIELDS.contentType,
    fragmentDoc: HubPageContentFieldsFragmentDoc,
    fragmentFieldsName: LOCALIZED_PAGE_CONTENT_FIELDS.fragmentFieldsName,
    filters: {
      slug,
    },
    skipQuery: !slug,
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching certificate variant hub content',
        },
      }),
  });

  if (pageContentLoading || !pageContent || certificatesLoading) return null;

  return (
    <CertificatesVariantHubPage
      pageContent={pageContent}
      certificatesList={certificatesList}
      categorizedCertificates={categorizedCertificates}
      userAgent={userAgent}
    />
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_hub',
      page: 'product_variant_hub',
    },
  }))
)(CertificatesVariantHubPageDataFetcher);
