import * as React from 'react';
import type { CSSProperties } from 'react';

import classNames from 'classnames';

import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { useTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/page';

import 'css!./__styles__/HeaderRightNavButton';

type Props = {
  href?: string;
  label: string;
  name?: string;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  mobileOnly?: boolean;
  wrapperClassName?: string;
  linkClassName?: string;
  linkRel?: string;
  htmlAttributes?: CSSProperties;
  noBorder?: boolean;
  openInNewWindow?: boolean;
  isEnterpriseLink?: boolean;
  linkRole?: string;
  buttonWrapperType?: 'li' | 'span';

  // below props injected by withKeyboardControls HOC in ./AuthenticatedAccountDropdown.jsx
  tabIndex?: number;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLElement>) => void;
  targetRef?: () => void;
  domRef?: (ref: HTMLElement | null) => void;
};

const HeaderRightNavButton = ({
  mobileOnly,
  noBorder,
  wrapperClassName,
  isEnterpriseLink,
  domRef,
  href = '#',
  name,
  onClick,
  buttonWrapperType = 'li',
  linkClassName,
  label,
  linkRel,
  htmlAttributes,
  tabIndex,
  onKeyDown,
  targetRef,
  openInNewWindow,
  linkRole,
}: Props) => {
  const track = useTracker();

  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    if (label && href) {
      track('click_profile_menu_item', {
        profileItemName: name ?? '',
        profileItemLink: href,
      });
    }

    if (typeof onClick === 'function') {
      ev.preventDefault();
      onClick?.(ev);
    }
  };

  const renderButton = () => {
    // NOTE: Ensure that label prop has already been localized e.g <HeaderRightNavButton label=_t(...) />
    const ariaLabel =
      label === _t('For Enterprise') ? _t('For Enterprise. See information about Coursera for Business') : label;

    const role = linkRole;
    const newHtmlAttributes = openInNewWindow ? { target: '_blank', ...htmlAttributes } : htmlAttributes;

    return (
      <TrackedA
        aria-label={ariaLabel}
        href={href}
        id={`${name}-link`}
        className={linkClassName}
        role={role}
        trackingName={name ? `header_right_nav_button_${name.split('-').join('_')}` : 'header_right_nav_button'}
        data={{ name }}
        rel={linkRel}
        onClick={(ev) => handleClick(ev)}
        onKeyDown={onKeyDown}
        refAlt={targetRef}
        tabIndex={tabIndex}
        {...newHtmlAttributes}
      >
        {label}
      </TrackedA>
    );
  };

  const elClassName = classNames('rc-HeaderRightNavButton', 'c-ph-right-nav-button', wrapperClassName, {
    'c-ph-right-nav-mobile-only': mobileOnly,
    'c-ph-right-nav-no-border': noBorder,
    'c-ph-enterprise': isEnterpriseLink,
    isLohpRebrand: true,
  });

  switch (buttonWrapperType) {
    case 'span':
      return <span className={elClassName}>{renderButton()}</span>;
    case 'li':
    default:
      return (
        <li role="menuitem" className={elClassName} ref={domRef}>
          {renderButton()}
        </li>
      );
  }
};

export default HeaderRightNavButton;
