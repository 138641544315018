import { getContrastRatio } from 'bundles/coursera-ui/utils/colorUtils';

const getAccessibleTextColor = (backgroundColor: string, textColor1: string, textColor2: string): string => {
  const contrast1 = getContrastRatio(textColor1, backgroundColor);
  const contrast2 = getContrastRatio(textColor2, backgroundColor);

  return contrast1 > contrast2 ? textColor1 : textColor2;
};

export default getAccessibleTextColor;
