/** @jsx jsx */
import { jsx } from '@emotion/react';

import gql from 'graphql-tag';
import _ from 'lodash';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Link, Typography2 } from '@coursera/cds-core';

import withFragments from 'bundles/graphql/components/withFragments';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { getS12nVariantProCertLabel, getSdpUrl } from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import getXDPPageLink from 'bundles/unified-description-page-common/utils/getXDPPageLink';
import type { S12nInfo } from 'bundles/video-logged-out-page/types/vlpSharedTypes';

import _t from 'i18n!nls/phoenix-cdp';

type Props = {
  courseId?: string | null;
  s12n: S12nInfo;
  subfolderLocaleCode?: string;
};

export const CourseS12nInfo = ({ courseId, s12n, subfolderLocaleCode }: Props) => {
  if (!s12n || !s12n?.name || !s12n?.slug || !s12n?.courses || !courseId) {
    return null;
  }

  const courseNumber = _.map(s12n.courses, 'id').indexOf(courseId) + 1;
  const numS12nCourses = s12n.courseCount;

  const parentProductType = getS12nVariantProCertLabel(s12n.productVariant);

  let s12nLink = '';
  if (s12n.slug) {
    s12nLink = getXDPPageLink({ productPageUrlPath: getSdpUrl(s12n.productVariant, s12n.slug), subfolderLocaleCode });
  }

  const urlLink = (
    <Link
      trackingName="vlp_course_s12n_info_link"
      href={s12nLink}
      rel="noopener noreferrer"
      target="_blank"
      data-e2e="course-s12n-info-link"
      component={TrackedA}
      variant="quiet"
    >
      {s12n.name} {parentProductType}
    </Link>
  );

  return (
    <div>
      <Typography2 component="p">
        <FormattedMessage
          // eslint-disable-next-line no-restricted-syntax
          message={_t('Course {courseNumber} of {numS12nCourses} in the {urlLink}')}
          parentProductType={parentProductType}
          urlLink={urlLink}
          courseNumber={courseNumber}
          numS12nCourses={numS12nCourses}
        />
      </Typography2>
    </div>
  );
};

export const CourseS12nInfoV2Fragment = gql`
  fragment CourseS12nInfoV2Fragment on XdpV1_org_coursera_xdp_cdp_CDPS12n {
    id
    name
    slug
    productVariant
    courses {
      id
    }
    courseCount
  }
`;

export default withFragments({
  s12nInfo: CourseS12nInfoV2Fragment,
})(CourseS12nInfo);
