import { Option, Group } from './Collection';
import Root from './MultiSelect';

type RootType = typeof Root;

export interface MultiSelectType extends RootType {
  /**
   * The MultiSelect option component [Optional]
   *
   * See [Props](__storybookUrl__/components-navigation-multiselect--default#props)
   *
   * @see MultiSelect
   * @see MultiSelect.Group
   */
  Option: typeof Option;

  /**
   * The MultiSelect group component for grouping options [Optional]
   *
   * See [Props](__storybookUrl__/components-navigation-multiselect--default#props)
   *
   * @see MultiSelect
   * @see MultiSelect.Option
   */
  Group: typeof Group;
}

/**
 * MultiSelect presents a menu of checkbox options where multiple items can be selected and are used to filter or sort content on a page. The MultiSelect is ideal when displaying a selection of 3-15 options.
 *
 * See [Props](__storybookUrl__/components-navigation-multiselect--default#props)
 *
 * @example
 * ## Anatomy:
 *
 * ### Static:
 * ```jsx
 * <MultiSelect>
 *   <MultiSelect.Option key="1">Option 1</MultiSelect.Option>
 *   <MultiSelect.Option key="2">Option 2</MultiSelect.Option>
 *   <MultiSelect.Option key="3">Option 3</MultiSelect.Option>
 * </MultiSelect>
 * ```
 *
 * ### Dynamic:
 * ```jsx
 * <MultiSelect items={options}>
 *   {(option) => (<MultiSelect.Option key={option.value}>{option.label}</MultiSelect.Option>)}
 * </MultiSelect>
 * ```
 * #### Grouped
 * ```jsx
 * <MultiSelect items={groups}>
 *   {(group) => (
 *      <MultiSelect.Group
 *         key={group.title}
 *         items={group.children}
 *         title={group.title}
 *       >
 *         {(item) => <MultiSelect.Option key={item.id}>{item.label}</MultiSelect.Option>}
 *       </MultiSelect.Group>
 *     )}
 * </MultiSelect>
 * ```
 *
 * @see MultiSelect
 * @see MultiSelect.Option
 * @see MultiSelect.Group
 */
const MultiSelect: MultiSelectType = Object.assign(Root, {
  Option,
  Group,
});

export type { Props as MultiSelectProps } from './MultiSelect';

export type { OptionProps, GroupProps } from './Collection';

export { MultiSelect };
