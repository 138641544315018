import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('Divider', ['dark', 'white']);

const getDividerCss = css`
  --divider-color-stroke: var(--cds-color-neutral-stroke-primary-weak);

  background: var(--divider-color-stroke);

  &.${classes.dark} {
    --divider-color-stroke: var(--cds-color-neutral-stroke-primary);
  }

  &.${classes.white} {
    --divider-color-stroke: var(--cds-color-neutral-primary-invert);
  }
`;

export default getDividerCss;
