import type { HTMLAttributes } from 'react';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';

import clsx from 'clsx';

import { usePopoverContext } from './context';
import footerCss, { classes } from './styles/footerCss';

export type Props = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

function Footer(props: Props) {
  const { children, className, ...rest } = props;

  const { layout } = usePopoverContext();

  const footerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('auto');

  useIsomorphicLayoutEffect(() => {
    const footer = footerRef.current;

    if (footer && layout === 'drawer') {
      const { height } = footer.getBoundingClientRect();
      setHeight(`${height}px`);
    }
  }, []);

  return (
    <div css={footerCss} style={{ height }}>
      <div ref={footerRef} className={clsx(classes.root, className)} {...rest}>
        {children}
      </div>
    </div>
  );
}

export default Footer;
