import { css } from '@emotion/react';

import { classes as baseClasses } from '@core/ButtonBase/getButtonBaseCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('iconButton', [
  'disabled',
  'small',
  'medium',
  'primary',
  'primaryInvert',
  'secondary',
  'ghost',
  'ghostInvert',
]);

const styles = css`
  box-shadow: inset 0 0 0 1px var(--iconButton-boxShadowColor);
  background-color: var(--iconButton-background);
  color: var(--iconButton-color);

  &.${classes.small} {
    height: 36px;
    width: 36px;
  }

  &.${classes.medium} {
    height: 48px;
    width: 48px;
  }

  &.${classes.primary} {
    --iconButton-boxShadowColor: var(--cds-color-interactive-primary);
    --iconButton-background: var(--cds-color-interactive-primary);
    --iconButton-color: var(--cds-color-neutral-primary-invert);

    &:hover,
    &.${baseClasses.focusVisible} {
      --iconButton-background: var(--cds-color-interactive-primary-hover);
      --iconButton-boxShadowColor: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      --iconButton-background: var(--cds-color-interactive-primary-pressed);
      --iconButton-boxShadowColor: var(--cds-color-interactive-primary-pressed);
    }

    &.${classes.disabled} {
      --iconButton-background: var(--cds-color-neutral-disabled-strong);
      --iconButton-boxShadowColor: var(--cds-color-neutral-disabled-strong);
      --iconButton-color: var(--cds-color-neutral-disabled);
    }
  }

  &.${classes.primaryInvert} {
    --iconButton-boxShadowColor: var(--cds-color-neutral-primary-invert);
    --iconButton-background: var(--cds-color-neutral-background-primary);
    --iconButton-color: var(--cds-color-interactive-primary);

    &:hover,
    &.${baseClasses.focusVisible} {
      --iconButton-background: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      --iconButton-boxShadowColor: var(--cds-color-neutral-primary-invert);
      --iconButton-color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      --iconButton-boxShadowColor: var(--cds-color-neutral-primary-invert);
      --iconButton-background: var(
        --cds-color-interactive-background-primary-pressed-weak
      );
      --iconButton-color: var(--cds-color-interactive-primary-pressed);
    }

    &.${classes.disabled} {
      --iconButton-boxShadowColor: var(
        --cds-color-neutral-background-primary-weak
      );
      --iconButton-background: var(--cds-color-neutral-background-primary-weak);
      --iconButton-color: var(--cds-color-neutral-disabled-strong);
    }
  }

  &.${classes.secondary} {
    --iconButton-boxShadowColor: var(--cds-color-interactive-primary);
    --iconButton-background: var(--cds-color-neutral-background-primary);
    --iconButton-color: var(--cds-color-interactive-primary);

    &:hover,
    &.${baseClasses.focusVisible} {
      --iconButton-background: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      --iconButton-boxShadowColor: var(--cds-color-interactive-primary-hover);
      --iconButton-color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      --iconButton-boxShadowColor: var(--cds-color-interactive-primary-pressed);
      --iconButton-background: var(
        --cds-color-interactive-background-primary-pressed-weak
      );
      --iconButton-color: var(--cds-color-interactive-primary-pressed);
    }

    &.${classes.disabled} {
      --iconButton-boxShadowColor: var(--cds-color-neutral-disabled-strong);
      --iconButton-background: var(--cds-color-neutral-background-primary-weak);
      --iconButton-color: var(--cds-color-neutral-disabled-strong);
    }
  }

  &.${classes.ghost} {
    --iconButton-boxShadowColor: transparent;
    --iconButton-background: unset;
    --iconButton-color: var(--cds-color-interactive-primary);

    &:hover,
    &.${baseClasses.focusVisible} {
      --iconButton-background: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      --iconButton-color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      --iconButton-background: var(
        --cds-color-interactive-background-primary-pressed-weak
      );
      --iconButton-color: var(--cds-color-interactive-primary-pressed);
    }

    &.${classes.disabled} {
      --iconButton-color: var(--cds-color-neutral-disabled-strong);
    }
  }

  &.${classes.ghostInvert} {
    --iconButton-boxShadowColor: transparent;
    --iconButton-background: unset;
    --iconButton-color: var(--cds-color-neutral-primary-invert);

    &:hover,
    &.${baseClasses.focusVisible}, &:active {
      --iconButton-boxShadowColor: var(--cds-color-neutral-primary-invert);
    }

    &.${classes.disabled} {
      --iconButton-color: var(--cds-color-neutral-disabled-strong);
    }
  }
`;

export default styles;
