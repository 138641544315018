import { useEffect, useRef, useState } from 'react';
import type { DependencyList } from 'react';

import { breakpoints, useMediaQuery } from '@coursera/cds-core';

const useInitialFocusOnSso = (deps?: DependencyList) => {
  const isMobile = useMediaQuery((theme) => breakpoints.down('sm'), { noSsr: true });
  const [initialFocusOnSSO, setInitialFocusOnSSO] = useState<boolean>(isMobile);
  const isFirstRun = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setInitialFocusOnSSO(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    initialFocusOnSSO,
  };
};

export default useInitialFocusOnSso;
