/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, Grid, Hidden, Typography, breakpoints, typography2 } from '@coursera/cds-core';

import MarkdownRender from 'bundles/cms/components/Markdown';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import SalaryChart from 'bundles/premium-hub/components/shared/SalaryChart';
import type { IndustryRelevanceContent } from 'bundles/premium-hub/data/industryRelevanceModuleContent';

import _t from 'i18n!nls/premium-hub';

type Props = IndustryRelevanceContent;
type IndustryRelevanceCTAProps = {
  ctaHref?: string;
  ctaText?: string;
  onCtaClick?: () => void;
  category?: string;
};

const styles = {
  heading: css`
    margin-bottom: var(--cds-spacing-200);
  `,

  subHeading: css`
    margin-bottom: var(--cds-spacing-200);

    ${breakpoints.down('sm')} {
      ${typography2.subtitleMedium}
    }
  `,

  description: css`
    margin-bottom: var(--cds-spacing-300);
  `,
};

const IndustryRelevanceModuleCTA = ({ ctaHref, ctaText, onCtaClick, category }: IndustryRelevanceCTAProps) => {
  const text = ctaText || _t('Explore programs');
  const trackingProps = {
    trackingName: 'industry_relevance_module_cta',
    withVisibilityTracking: false,
    requireFullyVisible: false,
    data: { category },
  };

  if (onCtaClick) {
    return (
      <Button {...trackingProps} component={TrackedButton} variant="secondary" onClick={onCtaClick}>
        {text}
      </Button>
    );
  } else {
    return (
      <Button {...trackingProps} component={TrackedLink2} variant="secondary" href={ctaHref}>
        {text}
      </Button>
    );
  }
};

export const IndustryRelevanceModule: React.FunctionComponent<Props> = ({
  title,
  industryName,
  ctaHref,
  ctaText,
  onCtaClick,
  description,
  careersTitle,
  careers,
  salaryCitation,
}) => {
  return (
    <TrackedDiv
      withVisibilityTracking
      trackingName="industry_relevance_module"
      trackClicks={false}
      data={{ category: industryName }}
    >
      <Grid container spacing={{ xs: 24, md: 64, lg: 112 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2semibold" css={styles.heading}>
            {title}
          </Typography>
          <MarkdownRender css={styles.description} source={description} />

          <Hidden smDown>
            <IndustryRelevanceModuleCTA
              ctaHref={ctaHref}
              ctaText={ctaText}
              onCtaClick={onCtaClick}
              category={industryName}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          {careersTitle && (
            <Typography variant="h2semibold" component="h3" css={styles.subHeading}>
              {careersTitle}
            </Typography>
          )}
          <SalaryChart salaries={careers} citation={salaryCitation} />

          <Hidden mdUp>
            <IndustryRelevanceModuleCTA
              ctaHref={ctaHref}
              ctaText={ctaText}
              onCtaClick={onCtaClick}
              category={industryName}
            />
          </Hidden>
        </Grid>
      </Grid>
    </TrackedDiv>
  );
};

export default IndustryRelevanceModule;
