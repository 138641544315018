import config from 'js/app/config';
import path from 'js/lib/path';

const exported = {
  config,
  shareUtmContentParam: 'cert_image',
  shareUtmMediumParam: 'certificate',
  shareUtmProductProjectParam: 'project',
  shareUtmProductCourseParam: 'course',
  shareUtmProductS12nParam: 's12n',
  shareUtmProductProfCertParam: 'prof',
  // used with /certificate, /specialization, /verify, etc routes until migration
  rootPath: '/account/accomplishments',

  accomplishmentsLink: '/accomplishments',
  certImageUrl: path.join(config.url.assets, 'images', 'signature', 'template-cert-small.png'),
  courseCertPlaceholderImageUrl: path.join(config.url.assets, 'images', 'signature', 'template-course-cert-small.png'),
  courseRecordImageUrl: path.join(config.url.assets, 'images', 'open-course', 'CourseRecord.png'),

  courseCertificateImage: 'https://d2j5ihb19pt1hq.cloudfront.net/certificates/cert-course.png',
  s12nCertificateImage: 'https://d2j5ihb19pt1hq.cloudfront.net/certificates/cert-specialization.png',
  linkedInAddCertUrl: 'https://www.linkedin.com/profile/add',

  activityStatus: {
    CURRENT: 'CURRENT',
    INACTIVE: 'INACTIVE',
    COMPLETED: 'COMPLETED',
    COMPLETED_WITH_CERT: 'COMPLETED_WITH_CERT',
    COMPLETED_WITHOUT_CERT: 'COMPLETED_WITHOUT_CERT',
  },

  getSpecCertImageUrl(specializationSlug: $TSFixMe) {
    return path.join(config.url.assets, 'images', 'specialization', 'certificate', specializationSlug + '.jpg');
  },

  getSoAUrl(sessionId: $TSFixMe, userId: $TSFixMe) {
    return path.join(
      config.url.base,
      `api/legacyCertificates.v1/spark/statementOfAccomplishment/${sessionId}~${userId}/pdf`
    );
  },

  vcCoursesLink: path.join(config.url.base, 'courses?certificates=VerifiedCert,Specialization'),

  MD_SCREEN_PX_BREAKPOINT: 824,
  // This should always be false, only make true if developing and need to see owner view
  ENABLE_DEV_OWNER_VIEW: false,
};

export default exported;

export const {
  rootPath,
  accomplishmentsLink,
  activityStatus,
  certImageUrl,
  courseCertPlaceholderImageUrl: courseCertImageUrl,
  courseRecordImageUrl,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'getSpecCertPdfUrl' does not exist on typ... Remove this comment to see the full error message
  getSpecCertPdfUrl,
  getSpecCertImageUrl,
  getSoAUrl,
  vcCoursesLink,
  courseCertificateImage,
  s12nCertificateImage,
  linkedInAddCertUrl,
  shareUtmMediumParam,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'shareUtmCampaignParam' does not exist on... Remove this comment to see the full error message
  shareUtmCampaignParam,
  shareUtmContentParam,
  shareUtmProductProjectParam,
  shareUtmProductCourseParam,
  shareUtmProductProfCertParam,
  shareUtmProductS12nParam,
  ENABLE_DEV_OWNER_VIEW,
} = exported;
