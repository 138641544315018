import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('multiSelectGroup', [
  'divider',
  'label',
  'list',
]);

const styles = css`
  .${classes.divider} {
    margin: var(--cds-spacing-200) 0;
  }

  .${classes.label} {
    padding: var(--cds-spacing-100);
    margin-bottom: var(--cds-spacing-50);
  }

  .${classes.list} {
    list-style: none;
    padding: 0;
  }
`;

export default styles;
