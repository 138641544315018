import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('formLabel', [
  'root',
  'asterisk',
  'focused',
  'error',
  'indicatorLabel',
  'onLight',
  'onDark',
]);

const getFormLabelCss = css`
  &.${classes.root} {
    position: relative;
    transform: initial;

    ${typography.subtitleMedium}
  }

  .${classes.asterisk} {
    display: none;
  }

  &.${classes.focused}, &.${classes.error} {
    &.${classes.root} {
      color: unset;
    }
  }

  .${classes.indicatorLabel} {
    display: inline-block;
  }

  &.${classes.onDark} {
    &.${classes.root} {
      color: var(--cds-color-neutral-primary-invert);
    }
  }

  &.${classes.onLight} {
    &.${classes.root} {
      color: var(--cds-color-neutral-primary);
    }
  }
`;

export default getFormLabelCss;
