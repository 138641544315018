import numberRenders from 'bundles/teach-course/lib/numberRenders';
import type {
  VLPCourseMaterialsQuery_OnDemandLearnerMaterialsV1Resource_courses_elements_learnerMaterialItems_lectures as Item,
  VLPCourseMaterialsQuery_OnDemandLearnerMaterialsV1Resource_courses_elements_learnerMaterialLessons_elements as Lesson,
  VLPCourseMaterialsQuery_OnDemandLearnerMaterialsV1Resource_courses_elements_learnerMaterialModules_elements as VLPModule,
} from 'bundles/video-logged-out-page/queries/__generated__/VLPCourseMaterialsQuery';

import _t from 'i18n!nls/video-logged-out-page';

const extractItemSlugAndId = (itemSlugAndIdString: string): Array<string> | null => {
  // itemSlugAndIdString is in the format of `itemSlug-itemId`
  const paramRegex = /(.*)-(\w+)/;
  const regexMatch = itemSlugAndIdString?.match(paramRegex);
  return regexMatch && regexMatch.slice(1, 3);
};

const millisToMinutesAndSeconds = (millis: number) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = Math.floor((millis % 60000) / 1000);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const getEnrollmentText = (courseEnrollmentCount: number, shouldCapitalizeAbbreviation = false): string => {
  if (typeof courseEnrollmentCount === 'undefined') {
    return '';
  }

  const formattedEnrollmentCount = numberRenders.Formatters.largeNumber(
    courseEnrollmentCount,
    shouldCapitalizeAbbreviation
  );

  return formattedEnrollmentCount
    ? _t('#{formattedEnrollmentCount} Students Enrolled', { formattedEnrollmentCount })
    : '';
};

const getCurrentLessonFromItem = (lessons?: Lesson[], itemId?: string) => {
  return lessons?.find((lesson) => {
    if (lesson.itemIds.findIndex((id) => id === itemId) !== -1) {
      return lesson;
    } else return false;
  });
};

const getCurrentModuleFromLesson = (modules?: Array<VLPModule>, lessonId?: string) => {
  return modules?.find((mod) => {
    if (mod.lessonIds.findIndex((id) => id === lessonId) !== -1) {
      return mod;
    } else return false;
  });
};

const getVideoItem = (items: Array<Item>, pageItemId: string) => {
  return items.find((item) => item.id === pageItemId);
};

const getNextVideoItem = (items: Array<Item>, pageItemId: string) => {
  let nextVideoItemIndex = items.findIndex((item) => item.id === pageItemId) + 1;
  if (nextVideoItemIndex > items.length - 1) {
    nextVideoItemIndex = 0;
  }

  return items[nextVideoItemIndex];
};

const extractItemsForType = (items: Array<Item>, itemType: string) => {
  return items?.filter((item) => item.contentSummary.__typename === itemType);
};

const extractVideoLectureItems = (items: Array<Item>) => {
  return extractItemsForType(items, 'OnDemandLearnerMaterialItemsV1_lectureMember');
};

const extractQuizLectureItems = (items: Array<Item>) => {
  const quizItems = extractItemsForType(items, 'OnDemandLearnerMaterialItemsV1_quizMember');
  const examItems = extractItemsForType(items, 'OnDemandLearnerMaterialItemsV1_examMember');

  return [...quizItems, ...examItems];
};

const extractReadingLectureItems = (items: Array<Item>) => {
  return extractItemsForType(items, 'OnDemandLearnerMaterialItemsV1_supplementMember');
};

const constructFallBackDescriptionForVlp = (videoTranscript: string) => {
  let trimmedDescription = '';

  const numberOfCharactersInDescription = 100;

  if (videoTranscript.length > trimmedDescription.length) {
    trimmedDescription = videoTranscript.substr(0, numberOfCharactersInDescription);

    trimmedDescription = trimmedDescription.substr(
      0,
      Math.min(trimmedDescription.length, trimmedDescription.lastIndexOf(' '))
    );
  }

  const fallBackMetaDescription = trimmedDescription;

  return fallBackMetaDescription + ' ...';
};

const filterItemsForLessonId = (items?: Array<Item>, lessonId?: string) => {
  return items?.filter((item) => item.lessonId === lessonId);
};

export {
  millisToMinutesAndSeconds,
  extractItemSlugAndId,
  getEnrollmentText,
  getCurrentLessonFromItem,
  getCurrentModuleFromLesson,
  getVideoItem,
  getNextVideoItem,
  extractVideoLectureItems,
  constructFallBackDescriptionForVlp,
  filterItemsForLessonId as getNonVideoCourseItems,
  extractQuizLectureItems,
  extractReadingLectureItems,
};
