/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import config from 'js/app/config';
import { getMobileBadgeLanguageCode } from 'js/lib/language';
import path from 'js/lib/path';

import { Grid, breakpoints } from '@coursera/cds-core';

import mobileAppLink from 'bundles/page-header/components/mobile/lib/link';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/page';

import 'css!bundles/page/components/__styles__/MobileAppBadges';

type Props = {
  onClick?: Function;
  noborder?: boolean;
  mdJustifyStart?: boolean;
  isColumn?: boolean;
  badgeHeight: number;
  badgeWidth: number;
  justifyContentBetween?: boolean;
  isOnSubfooter?: boolean;
  target?: '_self' | '_blank';
};

const styles1 = {
  badge: css`
    ${breakpoints.down('xs')} {
      display: flex;
      justify-content: space-between;
    }
  `,
  link: css`
    :focus {
      outline: auto 3px var(--cds-color-interactive-primary);
    }
  `,
};
class MobileAppBadges extends React.Component<Props> {
  static propTypes = {
    onClick: PropTypes.func,
    noborder: PropTypes.bool,
    mdJustifyStart: PropTypes.bool,
    isColumn: PropTypes.bool,
    badgeHeight: PropTypes.number,
    badgeWidth: PropTypes.number,
    justifyContentBetween: PropTypes.bool,
    isOnSubfooter: PropTypes.bool,
    target: PropTypes.oneOf(['_self', '_blank']),
  };

  static contextTypes = {
    requestCountryCode: PropTypes.string,
  };

  declare context: LegacyContextType<typeof MobileAppBadges.contextTypes>;

  static defaultProps: Partial<Props> = {
    noborder: false,
    mdJustifyStart: false,
    badgeHeight: 45,
    badgeWidth: 152,
  };

  onClick = (ev: React.MouseEvent<HTMLElement>) => {
    const { onClick } = this.props;
    if (typeof onClick === 'function') {
      ev.preventDefault();
      const platform = ev.currentTarget.getAttribute('data-platform');
      const href = ev.currentTarget.getAttribute('href');
      onClick(platform, href);
    }
  };

  getAndroidAppBadgeImg() {
    const { noborder, badgeHeight, badgeWidth } = this.props;
    let imgSrc;
    if (noborder) {
      imgSrc = path.join(config.url.app_assets, 'images/icons/download_from_play_store_noborder.svg');
    } else {
      imgSrc = path.join(
        config.url.app_assets,
        'images/icons/',
        getMobileBadgeLanguageCode() + '_generic_rgb_wo_45.png'
      );
    }

    return (
      <LazyImgix
        src={imgSrc}
        maxHeight={badgeHeight}
        maxWidth={badgeWidth}
        height={badgeHeight}
        width={badgeWidth}
        alt={_t('Get it on Google Play')}
      />
    );
  }

  render() {
    const {
      isOnSubfooter,
      noborder,
      mdJustifyStart,
      justifyContentBetween,
      isColumn,
      badgeHeight,
      target,
      badgeWidth,
    } = this.props;
    const rel = target === '_blank' ? 'noopener' : undefined;
    const iosSrc =
      config.url.assets +
      (noborder
        ? 'images/icons/download_from_apple_noborder.svg'
        : 'images/icons/download_on_the_app_store_badge_' + getMobileBadgeLanguageCode() + '.svg');

    const mobileAppBadgesClasses = classNames(
      'mobile-app-badges',
      { 'mobile-app-badges--column': isColumn },
      { 'mobile-app-badges--justify-start': mdJustifyStart },
      { 'mobile-app-badges--justify-between': justifyContentBetween }
    );

    const mobileBadgeClasses = classNames('mobile-badge', {
      'subfooter-mobile-badge': isOnSubfooter,
    });

    return (
      <Grid item className={mobileAppBadgesClasses} css={styles1.badge}>
        <TrackedA
          css={styles1.link}
          trackingName="mobile_app_badges_ios"
          className={mobileBadgeClasses}
          href={mobileAppLink.storeUrls.ios}
          onClick={this.onClick}
          data-platform="ios"
          target={target}
          rel={rel}
        >
          <LazyImgix
            src={iosSrc}
            maxHeight={badgeHeight}
            maxWidth={badgeWidth}
            height={badgeHeight}
            width={badgeWidth}
            alt={_t('Download on the App Store')}
          />
        </TrackedA>
        {!mobileAppLink.shouldAvoidGooglePlay() && (
          <TrackedA
            css={styles1.link}
            className={mobileBadgeClasses}
            trackingName="mobile_app_badges_android"
            href={mobileAppLink.storeUrls.android}
            onClick={this.onClick}
            data-platform="android"
            target={target}
            rel={rel}
          >
            {this.getAndroidAppBadgeImg()}
          </TrackedA>
        )}
      </Grid>
    );
  }
}

export default MobileAppBadges;
