/** @jsxFrag React.Fragment */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useState } from 'react';
import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Button, Grid, Typography, Typography2, breakpoints, useMediaQuery } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import ActionBar from 'bundles/expression-of-interest/components/ActionBar';
import { EoiDialog } from 'bundles/expression-of-interest/components/EoiDialog';
import { EoiOptimizationsContextProvider } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { HubPages, REGIONAL_CATEGORIES } from 'bundles/premium-hub/constants';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import _t from 'i18n!nls/premium-hub';

type EoiModuleProps = {
  slug: string;
  productId: string;
  categoryName?: string;
  surveyProductType: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
};

const styles = {
  wrapperDiv: css`
    position: relative;
    width: 100%;
    margin-top: var(--cds-spacing-200);
    margin-bottom: var(--cds-spacing-600);

    ${breakpoints.down('sm')} {
      display: none;
    }
  `,
  inlineContainer: css`
    margin: 0 auto;
    max-width: 1118px;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 40px -8px rgb(0 0 0 / 32%);
    padding: 24px 0 24px;
    position: relative;
    width: 100%;
  `,
  textWrap: css`
    align-items: center;
    padding-left: var(--cds-spacing-400);
    padding-right: var(--cds-spacing-200);
    width: 70%;

    ${breakpoints.down('md')} {
      width: 60%;
    }
    ${breakpoints.down('xs')} {
      margin-bottom: var(--cds-spacing-200);
      padding-left: var(--cds-spacing-300);
      padding-right: var(--cds-spacing-300);
      width: 100%;
    }
  `,
  buttonWrap: css`
    align-content: space-between;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding-right: var(--cds-spacing-400);
    width: 30%;

    .rc-EmailMeButton {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;

      span {
        margin: 0 auto;
        padding: 0 16px;
      }
    }

    ${breakpoints.down('md')} {
      width: 40%;
    }
    ${breakpoints.down('xs')} {
      padding-left: var(--cds-spacing-200);
      width: 100%;
    }
  `,
  buttonItem: css`
    min-width: 50%;

    ${breakpoints.down('sm')} {
      width: 100%;
    }
    ${breakpoints.down('xs')} {
      width: 100%;
    }
  `,
};

const EoiModule = ({ slug, categoryName, productId, surveyProductType, currentHubPage }: EoiModuleProps) => {
  const [isDcatEoiOpen, setIsDcatEoiOpen] = useState<boolean>(false);

  const trackComponent = useRetracked();
  const trackV3 = useTracker();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const handleEoiClick = (v2TrackingName: string) => {
    trackComponent({
      trackingName: v2TrackingName,
      trackingData: { currentPage: currentHubPage },
      action: 'click',
    });
    trackV3('interact_eoi', {
      eoiInteraction: 'open_form',
    });

    setIsDcatEoiOpen(true);
  };

  const isRegionPage = categoryName && REGIONAL_CATEGORIES.includes(categoryName);
  const defaultCategory = isRegionPage ? categoryName : (categoryName ?? 'online').toLowerCase();
  const categoryType = defaultCategory.includes('degrees') ? defaultCategory : defaultCategory + ' degrees';

  const completeProductId = tupleToStringKey([surveyProductType, productId]);
  const productType = currentHubPage === HubPages.MainHubPage ? ProductTypes.DEGREE_HUB : ProductTypes.DEGREE_CATEGORY;

  return (
    <>
      <div css={styles.wrapperDiv}>
        <Grid container css={styles.inlineContainer}>
          <Grid css={styles.textWrap} item>
            <Typography variant="h2semibold" component="h3">
              {_t('Learn more about #{categoryType} on Coursera', { categoryType })}
            </Typography>
            <Typography2 component="p" variant="bodyPrimary">
              {_t('Submit our Request Info form to get details on degree programs that may interest you.')}
            </Typography2>
          </Grid>

          <Grid container item spacing={16} css={styles.buttonWrap}>
            <Button
              id="RequestInfoButton"
              component={TrackedButton}
              variant="primary"
              onClick={() => handleEoiClick('hero_banner_request_info_button')}
              withVisibilityTracking={false}
              requireFullyVisible={false}
            >
              {_t('Request info')}
            </Button>

            <EoiOptimizationsContextProvider slug={slug} productType={productType}>
              <EoiDialog
                isOpen={isDcatEoiOpen}
                onClose={() => setIsDcatEoiOpen(false)}
                onOpen={() => setIsDcatEoiOpen(true)}
                productId={completeProductId}
              />
            </EoiOptimizationsContextProvider>
          </Grid>
        </Grid>
      </div>
      {isMobile && (
        <ActionBar
          actionBarData={{
            isFixed: true,
          }}
          isDesktop={false}
        >
          <div className="eoi-buttons-container">
            <Button
              variant="primary"
              className="rc-EmailMeButton"
              component={TrackedButton}
              withVisibilityTracking={false}
              requireFullyVisible={false}
              onClick={() => handleEoiClick('hero_banner_request_info_sticky_button')}
              fullWidth
            >
              {_t('Request info')}
            </Button>
          </div>
        </ActionBar>
      )}
    </>
  );
};

export default React.memo(EoiModule);
