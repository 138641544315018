import { useLocation } from 'js/lib/useRouter';

const useSimplifiedNav = () => {
  const paths = [/^\/admin/, /^\/admin-v2/, /^\/learn\/[^/]+\/[^(/|paidmedia)]+(\/[^/]+)*/];
  const location = useLocation();
  const isValidPath = !paths.some((pathRegex) => location.pathname.match(pathRegex));

  return isValidPath;
};

export default useSimplifiedNav;
