import React from 'react';

import { MenuItem as MuiMenuItem } from '@material-ui/core';

import type { ComponentPropsWithRef } from '@coursera/cds-common';
import { useLocalizedStringFormatter, useId } from '@coursera/cds-common';
import { CheckIcon } from '@coursera/cds-icons';

import i18nMessages from '@core/forms/i18n';

import getSelectOptionCss, { classes } from './getSelectOptionCss';

export type Props = {
  /**
   * Defines option value.
   */
  value: React.ReactText;

  /**
   * Option content.
   */
  children: React.ReactNode;

  /**
   * Defines if options shouldn't be rendered in the dropdown.
   * Note: this is for internal usage only.
   * @ignore
   */
  hidden?: boolean;

  /**
   * Use to apply selected styling.
   * @default false
   */
  selected?: boolean;

  /**
   * Used to disable pseudo placeholder
   * Note: this is for internal usage only.
   * @ignore
   */
  disabled?: boolean;
} & ComponentPropsWithRef<'li'>;

const SelectOption = (props: Props, ref: React.Ref<HTMLLIElement>) => {
  const { selected = false, children, ...rest } = props;
  const isSelected = selected && !props.disabled;
  const id = useId(rest.id);
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const labelId = `${id}-label`;
  const selectedLabelId = `${id}-selected-label`;

  return !props.hidden ? (
    <MuiMenuItem
      ref={ref}
      button
      disableRipple
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-expect-error For some reason Material UI type def doesn't allow me to pass this prop
      ListItemClasses={{
        button: classes.button,
      }}
      classes={{
        root: classes.root,
        selected: classes.selected,
      }}
      css={getSelectOptionCss}
      focusVisibleClassName={classes.focusVisible}
      selected={isSelected}
      {...rest}
      aria-labelledby={
        isSelected ? [labelId, selectedLabelId].join(' ') : labelId
      }
      aria-selected={isSelected}
      id={id}
    >
      <div className={classes.container}>
        <div id={labelId}>{children}</div>

        {selected && (
          <div className={classes.icon} id={selectedLabelId}>
            <CheckIcon
              size="medium"
              title={stringFormatter.format('selected')}
            />
          </div>
        )}
      </div>
    </MuiMenuItem>
  ) : null;
};

export default React.forwardRef(SelectOption);
