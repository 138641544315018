/**
 * This utils file is for helpers related to experiments run on this app.
 */
import DegreesConversionExperiments from 'bundles/epic/clients/DegreesConversion';

import _t from 'i18n!nls/degree-description';

export const slugIsInPersonalizationExpt = (degreeSlug?: string | null) => {
  const enabledDegreeSlugs: string[] = DegreesConversionExperiments.get('personalizedDdpPrograms');

  if (typeof degreeSlug === 'string') {
    return enabledDegreeSlugs.includes(degreeSlug);
  } else {
    return false;
  }
};

export const getPersonalizedDdpApplyVariant = (degreeSlug?: string | null) =>
  slugIsInPersonalizationExpt(degreeSlug)
    ? DegreesConversionExperiments.get('personalizedDdpApplyMessageType')
    : 'none';

export const previewPersonalizedDdpApplyVariant = (degreeSlug?: string | null) =>
  slugIsInPersonalizationExpt(degreeSlug)
    ? DegreesConversionExperiments.preview('personalizedDdpApplyMessageType')
    : 'none';

export const isInPersonalizedExperiment = (degreeSlug?: string | null) => {
  const applyPreview = previewPersonalizedDdpApplyVariant(degreeSlug);

  return applyPreview !== 'none';
};

export const isInLightweightDdp = (slug: string) => {
  return DegreesConversionExperiments.get(
    'lightweightDdp',
    // eslint-disable-next-line camelcase
    { product_id: slug }
  );
};

export const showAppFeeWaiverExperiment = () => DegreesConversionExperiments.get('showAppFeeWaiver');

export const showCtaModuleDeadline = () => DegreesConversionExperiments.get('showCtaModuleDeadline');

export const showCourseStarRatings = () => DegreesConversionExperiments.get('showCourseStarRatings');

export const moveStackabilityModule = () => DegreesConversionExperiments.get('moveStackabilityModule');

export const getCtaSocialProofText = () => {
  const variant = DegreesConversionExperiments.get('showCtaSocialProof');
  if (variant === 'variantA') {
    return _t('Join the <strong>900 learners</strong> who recently met with an enrollment counselor!');
  } else if (variant === 'variantB') {
    return _t(
      'Join the over <strong>18,000 learners</strong> who have recently expressed interest in pursuing a degree on Coursera!'
    );
  } else return null;
};

export const getQualifyingQuestionsExpt = (slug: string, preview: boolean = false) => {
  if (!preview) {
    return DegreesConversionExperiments.get('qualifyingQuestions', {
      // eslint-disable-next-line camelcase
      product_id: slug,
    });
  } else {
    return DegreesConversionExperiments.preview('qualifyingQuestions', {
      // eslint-disable-next-line camelcase
      product_id: slug,
    });
  }
};
