/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { compose } from 'recompose';

import user from 'js/lib/user';

import { breakpoints } from '@coursera/cds-core';

import TopReviewCard from 'bundles/reviews-common/components/TopReviewCard';
import type { TopReview, TopReviewIdAndVoteType } from 'bundles/reviews-common/types/reviewsSharedTypes';

type InputProps = {
  topReviews: Array<TopReview>;
};

type Props = {
  topReviews: Array<TopReview>;
  topReviewIdsAndVoteTypes: Array<TopReviewIdAndVoteType>;
};

const styles = {
  topReviewsModule: css`
    .top-reviews-container-cds {
      display: flex;
      justify-content: space-between;

      ${breakpoints.down('xs')} {
        flex-direction: column;
        align-items: center;
      }

      .top-review-cds {
        width: 50%;
        margin: 0 0 0 12px;
        padding: 20px;
        box-shadow: 3px 3px 14px -2px rgb(0 0 0 / 30%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:first-of-type {
          margin: 0 12px 0 0;
        }

        ${breakpoints.down('xs')} {
          width: 100%;
          margin: 0;

          &:first-of-type {
            margin: 0 0 20px 0;
          }
        }

        .top-review-author-cds {
          margin: 0;
        }

        .top-review-date-cds {
          margin: 0;
          color: var(--cds-color-grey-600);
        }

        .top-review-comment-cds {
          margin: 10px 0 15px 0;
          overflow-wrap: break-word;
        }

        .top-review-helpful-button-cds {
          margin-top: auto;
        }
      }
    }
  `,
};

const TopReviewCards: React.FC<Props> = ({ topReviews, topReviewIdsAndVoteTypes }) => {
  if (!topReviews || topReviews.length < 2) {
    return null;
  }

  return (
    <section className="top-reviews-module-cds" css={styles.topReviewsModule}>
      <div className="top-reviews-container-cds">
        {topReviews.slice(0, 2).map((topReview) => {
          const { reviewId } = topReview;
          const reviewData =
            topReviewIdsAndVoteTypes && topReviewIdsAndVoteTypes.find((review) => review.id === reviewId);

          return (
            <TopReviewCard
              topReview={topReview}
              originalReviewData={reviewData}
              userId={user.get().id}
              key={`top-review-${reviewId}`}
            />
          );
        })}
      </div>
    </section>
  );
};

const VLPHelpfulReviewsQuery = gql`
  query VLPHelpfulReviewsQuery($productReviewIds: [String!]!) {
    ProductReviewsV1Resource {
      multiGet(ids: $productReviewIds) {
        elements {
          id
          isMarkedHelpful
        }
      }
    }
  }
`;

export default compose<Props, InputProps>(
  graphql(VLPHelpfulReviewsQuery, {
    // @ts-expect-error TSMIGRATION
    props: ({ data: { ProductReviewsV1Resource } = {} }) => {
      const topReviewIdsAndVoteTypes =
        ProductReviewsV1Resource && ProductReviewsV1Resource.multiGet && ProductReviewsV1Resource.multiGet.elements;

      return { topReviewIdsAndVoteTypes };
    },
    // @ts-expect-error TSMIGRATION
    options: ({ topReviews }) => {
      const reviewIds = topReviews.map((review: $TSFixMe) => review.reviewId);

      return {
        variables: {
          productReviewIds: reviewIds,
        },
      };
    },
    skip: () => !user.get().id,
  })
)(TopReviewCards);
