import type { BaseComponentProps } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';
import Typography from '@core/Typography2';

type Props = {
  /**
   * @example
   * metadata={[
   *   'Intermediate',
   *   'Professional Certificate',
   *   '6-12 months'
   * ]}
   */
  metadata: string[];
} & BaseComponentProps<'div'>;

/**
 * Common component to render card metadata such as product type, product duration, difficulty level.
 */
const CardMetadata = ({ metadata, ...rest }: Props) => {
  return (
    <div className={classes.metadata} {...rest}>
      <Typography color="supportText" component="p" variant="bodySecondary">
        {metadata.join(' · ')}
      </Typography>
    </div>
  );
};

export default CardMetadata;
