import type * as React from 'react';

import config from 'js/app/config';
import type { InjectedRouter } from 'js/lib/connectToRouter';

export type HttpParams = Record<string, string | number>;

/* eslint-disable camelcase */
const addUtmParams = (urlParams: URLSearchParams, params: HttpParams): HttpParams => {
  const previousUtmCampaign = urlParams.get('utm_campaign');
  const previousUtmContent = urlParams.get('utm_content');
  const previousUtmMedium = urlParams.get('utm_medium');
  const previousUtmSource = urlParams.get('utm_source');
  const previousPromoCode = urlParams.get('promoCode');

  const ret: HttpParams = { ...params };
  if (previousUtmCampaign) {
    ret.utm_campaign = previousUtmCampaign;
  }
  if (previousUtmContent) {
    ret.utm_content = previousUtmContent;
  }
  if (previousUtmMedium) {
    ret.utm_medium = previousUtmMedium;
  }
  if (previousUtmSource) {
    ret.utm_source = previousUtmSource;
  }

  // Intentional links should override an existing promo
  if (previousPromoCode && !params.promoCode) {
    ret.promoCode = previousPromoCode;
  }

  return ret;
};
/* eslint-enable */

const getPathname = (router: InjectedRouter): string => {
  return `${router.location.pathname}${router.location.search}${router.location.hash ?? ''}`;
};

export const getUrl = (router: InjectedRouter, path: string, params?: HttpParams) => {
  const baseUrl = new URL(path, config.url.base);
  const currentURL = new URL(getPathname(router), config.url.base);

  const paramsWithUtm = addUtmParams(currentURL.searchParams, params ?? {});
  Object.keys(paramsWithUtm).forEach((key) => {
    baseUrl.searchParams.set(key, paramsWithUtm[key] as string);
  });

  return baseUrl.toString();
};

export const onClickNavUrl = (router: InjectedRouter, path: string, params?: HttpParams) => {
  return (e: React.MouseEvent<HTMLElement>) => {
    window.location.href = getUrl(router, path, params) ?? '#';

    e.preventDefault();
    return false;
  };
};
