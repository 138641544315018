import type React from 'react';

import { Item, Section } from '@react-stately/collections';
import type { LinkDOMProps } from '@react-types/shared';

export type OptionProps = {
  /**
   * Additional context for an option.
   */
  supportText?: string;

  /**
   * Additional element rendered at the start of option element.
   */
  prefix?: React.ReactNode;

  /**
   * An accessibility label for this option.
   */
  'aria-label'?: string;

  /**
   * Option label.
   */
  children: React.ReactNode;

  /**
   * Additional class name.
   */
  className?: string;

  /**
   * Custom text value to use for matching or selection. Required when `children` is not a string.
   */
  textValue?: string;
};

export type LinkProps = OptionProps & {
  /**
   * Additional element rendered at the end of option element.
   */
  suffix?: React.ReactNode;
} & LinkDOMProps;

const Option = Item as (props: OptionProps) => React.ReactElement;

const Link = Item as (props: LinkProps) => React.ReactElement;

export type LinkElement = React.ReactElement<LinkProps>;
export type LinkRenderer<T> = (item: T) => LinkElement;
export type OptionElement = React.ReactElement<OptionProps>;
export type OptionRenderer<T> = (item: T) => OptionElement;

export type GroupProps<T> = {
  /**
   * Group title.
   */
  title: string;

  /**
   * An accessibility label for the group.
   */
  'aria-label'?: string;

  /**
   * Static child items or a function to render children.
   */
  children:
    | OptionElement
    | OptionElement[]
    | OptionRenderer<T>
    | LinkElement
    | LinkElement[]
    | LinkRenderer<T>;

  /**
   * Option objects in the group.
   */
  items?: Iterable<T>;
};

const Group = Section as <T>(props: GroupProps<T>) => React.ReactElement;

export { Option, Group, Link };
