import * as React from 'react';
import { useEffect, useState } from 'react';

import { getActiveConversationId, getChatTargetingRule } from 'bundles/common/utils/chatLoaderUtils';
import type { ChatTargetingRule } from 'bundles/common/utils/chatLoaderUtils';
import inServerContext from 'bundles/ssr/util/inServerContext';

import BoostChat from './BoostChat';
import ErrorBoundaryWithTagging from './ErrorBoundaryWithTagging';

type Props = {
  /** Set isInlineButton to true when you are inserting a button into content that should not be floating on the bottom right and should always be shown */
  isInlineButton?: boolean;
};

/**
 * This component decides whether or not to show a chat button to talk to a chatbot or support agent.
 * If we ever want to do an A/B test such as bot vs human, or Boost chat vs. Salesforce chat, that logic should live here.
 */
const ChatLoader = ({ isInlineButton = false }: Props) => {
  const [conversationId, setConversationId] = useState<string | undefined>(undefined);
  const [targetingRule, setTargetingRule] = useState<ChatTargetingRule | undefined>(undefined);
  useEffect(() => {
    // Effect code is not run in SSR, which is what we want.  Setting the following using state (rather than a local variables) causes a 2nd render pass in CSR and avoids SSR<>CSR mismatch.
    setConversationId(getActiveConversationId());
    setTargetingRule(getChatTargetingRule(location.href));
  }, []);

  const showChatButton = !inServerContext && (isInlineButton || !!conversationId || !!targetingRule); // If there is an active chat conversation we always show the chat button on all pages because we want the learner to be able to easily resume that conversation.

  if (!showChatButton) {
    return null;
  }

  return (
    <div id="chat-loader">
      {/* We don't want any chance of the whole page crashing if something goes wrong with chat.  This error boundary logs and renders nothing in case of error. */}
      <ErrorBoundaryWithTagging componentName="common_chat_loader">
        <BoostChat
          conversationId={conversationId}
          initialActionId={targetingRule?.initialActionId}
          isInlineButton={isInlineButton}
        />
      </ErrorBoundaryWithTagging>
    </div>
  );
};

export default ChatLoader;
