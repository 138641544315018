import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'underscore';

import _t from 'i18n!nls/survey-form';

import 'css!./__styles__/CheckboxesElement';

const OTHER_ID = 'other';

class CheckboxesElement extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholderForOther: PropTypes.string,
    enableErrorState: PropTypes.bool,
    helpText: PropTypes.string,
    includeOther: PropTypes.bool,
    options: PropTypes.array.isRequired,
    inputRef: PropTypes.func,
  };

  state = {
    ids: [],
  };

  handleSelect = (event: $TSFixMe) => {
    const checkboxId = event.target.id;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'otherText' does not exist on type '{ ids... Remove this comment to see the full error message
    const { ids, otherText } = this.state;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    if (ids.indexOf(checkboxId) > -1) {
      // uncheck checkboxId
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      ids.splice(ids.indexOf(checkboxId), 1);
    } else {
      // check checkboxId
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      ids.push(checkboxId);
    }
    this.setState({ ids });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange({ ids, otherText });
  };

  handlerInputTextChange = (event: $TSFixMe) => {
    const otherText = event.target.value === '' ? undefined : event.target.value;
    this.setState({ otherText });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange({ ids: this.state.ids, otherText });
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'helpText' does not exist on type 'Readon... Remove this comment to see the full error message
    const { helpText, options, includeOther, placeholderForOther, enableErrorState, inputRef } = this.props;
    const className = classNames('rc-CheckboxesElement', {
      'error-state': enableErrorState,
    });
    // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    const optionGroups = _(options).groupBy((option, index) => Math.floor(index / 2));
    return (
      <div className={className}>
        {helpText && <div className="checkbox-help-text color-secondary-text">{helpText}</div>}

        <div className="checkbox-options">
          {_(optionGroups).map((optionGroup: $TSFixMe, rowIndex: $TSFixMe) => (
            <div className="checkbox-row" key={rowIndex}>
              {_(optionGroup).map((option, optionIndex) => (
                <label htmlFor={option.id} className="checkbox-option" key={optionIndex}>
                  <input
                    className="checkbox-small"
                    type="checkbox"
                    id={option.id}
                    onChange={this.handleSelect}
                    ref={inputRef}
                  />
                  {option.text}
                </label>
              ))}
            </div>
          ))}

          {includeOther && (
            <div className="checkbox-row other-row horizontal-box">
              <label htmlFor={OTHER_ID} className="checkbox-option other-option body-1-text">
                <input className="checkbox-small" type="checkbox" id={OTHER_ID} onChange={this.handleSelect} />
                {_t('Other')}:
              </label>
              <label htmlFor="text-other" className="sr-only">
                {placeholderForOther}
              </label>
              <input
                id="text-other"
                type="text"
                className="c-input flex-1"
                placeholder={placeholderForOther}
                onChange={this.handlerInputTextChange}
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                disabled={this.state.ids.indexOf(OTHER_ID) === -1}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CheckboxesElement;
