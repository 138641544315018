import requestCountry from 'js/lib/requestCountry';
import { get as getUser } from 'js/lib/user';

import { isSubscriptionTiersEnabled } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import { useSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';
import GrowthAcquisitionEpicClient from 'bundles/epic/clients/GrowthAcquisition';
import pageHeaderExperiments from 'bundles/epic/clients/pageHeader';
import {
  REFERRAL_ACCOUNT_DROPDOWN_UTM_PARAM,
  REFERRAL_BUTTON_NAME,
  REFER_A_FRIEND_PAGE_CPLUS_ALTRUISTIC,
  REFER_A_FRIEND_PAGE_URL,
  REFER_A_FRIEND_PAGE_URL_UK,
} from 'bundles/referral/constants';
import type { ReferralOfferData } from 'bundles/referral/types';

import _t from 'i18n!nls/page';

export const isCourseraPlusReferralOfferEnabled = () => pageHeaderExperiments.get('showCourseraPlusReferralOffer');

export const isCourseraPlusAltruisticNavDropdownReferralEnabled = () =>
  GrowthAcquisitionEpicClient.get('enableCPlusReferralNavDropdown');

export const useReferralOfferData = (shouldHideOffer = false): ReferralOfferData => {
  const { subscriptions } = useSubscriptionsByUser(shouldHideOffer);

  const hasOwnedLiteOrPlusBefore = subscriptions?.find(
    (subscription) => subscription.isCourseraPlusSubscription || subscription.isCourseraLiteSubscription
  );
  const hasActiveCourseraPlusSubscription = subscriptions?.find(
    (subscription) => subscription.isActive && subscription.isCourseraPlusSubscription
  );

  const isSubsTiersEnabled = isSubscriptionTiersEnabled({ skipProductTypeCheck: true });

  if (
    hasActiveCourseraPlusSubscription &&
    !isSubsTiersEnabled &&
    isCourseraPlusAltruisticNavDropdownReferralEnabled()
  ) {
    return {
      shouldShowCourseraPlusReferralOffer: !shouldHideOffer,
      button: () => ({
        href: REFER_A_FRIEND_PAGE_CPLUS_ALTRUISTIC,
        name: REFERRAL_BUTTON_NAME,
        label: _t('Friends join for $1'),
      }),
    };
  }

  const isReferralEpicEnabled = isCourseraPlusReferralOfferEnabled() && !isSubsTiersEnabled;
  // For Canada, only show the referral offer if the user's language is English, no language restrictions for other countries
  const isValidUserLanguage = requestCountry.get() === 'CA' ? getUser().locale === 'en_US' : true;

  let href = `${REFER_A_FRIEND_PAGE_URL}?${new URLSearchParams(REFERRAL_ACCOUNT_DROPDOWN_UTM_PARAM).toString()}`;

  if (requestCountry.get() === 'GB') href = REFER_A_FRIEND_PAGE_URL_UK;

  return {
    shouldShowCourseraPlusReferralOffer:
      !shouldHideOffer && isReferralEpicEnabled && !hasOwnedLiteOrPlusBefore && isValidUserLanguage,
    button: () => ({
      href,
      name: REFERRAL_BUTTON_NAME,
      label: _t('Get Coursera Plus for $1'),
    }),
  };
};
