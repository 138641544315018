import {
  CERTIFICATE_SECTION_TYPE,
  DEGREES_SECTION_TYPE,
  GET_STARTED_SECTION_TYPE,
  OTHER_SECTION_TYPE,
  POPULAR_SKILLS_SECTION_TYPE,
} from 'bundles/megamenu/constants/constants';

export type MegaMenuContent = {
  href: string;
  label: string;
  name: string;
  sectionType?: MegaMenuSectionType;
  type: MegaMenuContentType;
  onButtonClick?: () => void;
  footer?: MegaMenuContent;
  children?: MegaMenuContent[];
};

export const MEGAMENU_CONTENT_TYPE = {
  section: 'section',
  link: 'link',
  header: 'header',
} as const;

export type MegaMenuContentType = (typeof MEGAMENU_CONTENT_TYPE)[keyof typeof MEGAMENU_CONTENT_TYPE];

export const MEGAMENU_SECTION_TYPE = {
  GET_STARTED_SECTION_TYPE,
  POPULAR_SKILLS_SECTION_TYPE,
  CERTIFICATE_SECTION_TYPE,
  DEGREES_SECTION_TYPE,
  OTHER_SECTION_TYPE,
} as const;

export type MegaMenuSectionType = (typeof MEGAMENU_SECTION_TYPE)[keyof typeof MEGAMENU_SECTION_TYPE];
