import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('pagination', [
  'nav',
  'navControl',
  'navPageSizeContainer',
  'justified',
]);

export type VisualProps = {
  pageSizeInputAlignment: 'start' | 'center' | 'end';
};

const getPaginationCss = ({ pageSizeInputAlignment }: VisualProps) => css`
  display: inline-flex;
  align-items: center;

  &.${classes.justified} {
    ${breakpoints.up('md')} {
      width: 100%;
      justify-content: space-between;
    }
  }

  .${classes.nav} {
    display: flex;
    gap: var(--cds-spacing-150);
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .${classes.navPageSizeContainer} {
    margin-right: var(--cds-spacing-300);
  }

  .${classes.navControl} {
    display: flex;
    flex-direction: column;
  }

  ${breakpoints.down('sm')} {
    flex-direction: column;

    .${classes.navPageSizeContainer} {
      align-self: ${pageSizeInputAlignment === 'center'
        ? 'center'
        : `flex-${pageSizeInputAlignment}`};
      margin-bottom: var(--cds-spacing-100);
      margin-right: ${pageSizeInputAlignment === 'end'
        ? `calc(var(--cds-spacing-100) * -1)`
        : 0};
      margin-left: ${pageSizeInputAlignment === 'start'
        ? `calc(var(--cds-spacing-100) * -1)`
        : 0};
    }
  }

  ${breakpoints.down('xs')} {
    width: 100%;
  }
`;

export { getPaginationCss };
