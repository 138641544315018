import getSearchLoaderCss, { classes } from './getSearchLoaderCss';

/**
 * Custom loader for search loading state
 */
export const SearchLoader = () => {
  return (
    <div css={getSearchLoaderCss}>
      <svg aria-hidden className={classes.searchLoader} viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="36" />
      </svg>
    </div>
  );
};
