/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useState } from 'react';

import { Chip, ChipGroup, breakpoints, useMediaQuery } from '@coursera/cds-core';

import ShowMoreGridSection from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSection';
import type {
  BaseItem,
  ShowMoreGridSectionProps,
} from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSection';
import { MAXIMUM_FILTERS_ITEM_COUNT_MOBILE } from 'bundles/cds-labs/components/ShowMoreGridSection/constants';

import _t from 'i18n!nls/cds-labs';

type Filter = {
  /**
   * Id of the filter.
   */
  id: string;

  /**
   * Label of the filter to be displayed.
   */
  label: string;
};
export type FilterableShowMoreGridSectionProps<Item extends BaseItem> = ShowMoreGridSectionProps<Item> & {
  /**
   * Array of filter item to be displayed.
   */
  filters: Filter[];

  /**
   * Function to be called on filter select.
   */
  onFilterSelect: (id?: string) => void;

  /**
   * Currently selected filter id to control the component
   */
  selectedFilterId?: string;
};

export const styles = {
  chipGroup: css`
    padding-top: var(--cds-spacing-100);
  `,
};

export function FilterableShowMoreGridSection<Item extends BaseItem>({
  filters,
  onFilterSelect,
  selectedFilterId: controlledSelectedFilterId,
  ...props
}: FilterableShowMoreGridSectionProps<Item>) {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const [selectedFilterId, setSelectedFilterId] = useState<string | undefined>(controlledSelectedFilterId);
  const handleFilterChange = (id: string) => {
    const selectedId = id === selectedFilterId ? undefined : id; // unselect if same id
    setSelectedFilterId(selectedId);
    onFilterSelect?.(selectedId);
  };
  const filtersToShow = isMobile ? filters.slice(0, MAXIMUM_FILTERS_ITEM_COUNT_MOBILE) : filters;
  return (
    <ShowMoreGridSection<Item>
      {...props}
      renderSubSection={() =>
        filtersToShow && (
          <ChipGroup aria-label={_t('Filter By')} css={styles.chipGroup}>
            {filtersToShow.map(({ id, label }) => (
              <Chip
                key={id}
                selected={(controlledSelectedFilterId || selectedFilterId) === id}
                onClick={() => handleFilterChange(id)}
              >
                {label}
              </Chip>
            ))}
          </ChipGroup>
        )
      }
    />
  );
}

export default FilterableShowMoreGridSection;
