import { useMemo } from 'react';

import { snakeCase } from 'lodash';

import { useLocation, useNavigate } from 'js/lib/useRouter';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import { HubPages } from 'bundles/premium-hub/constants';

const LOCALSTORAGE_PREFIX = 'previous_tab_';

type TabName = 'overview' | 'browse';

export const getInfoFromPathname = (pathname: string) => {
  const urlParts = pathname.split('/');
  if (urlParts[urlParts.length - 1] === '') {
    urlParts.pop();
  }
  const inBrowse = urlParts[urlParts.length - 1] === 'browse';
  const slug = inBrowse ? urlParts[urlParts.length - 2] : urlParts[urlParts.length - 1];
  return {
    urlParts,
    inBrowse,
    slug,
  };
};

const getLocalStorageKey = (slug: string) => `${LOCALSTORAGE_PREFIX}${snakeCase(slug)}`;

export const setLastTabLocalStorage = (pathname: string, tab: TabName) => {
  const { slug } = getInfoFromPathname(pathname);
  localStorageEx.setItem<TabName>(getLocalStorageKey(slug), tab, JSON.stringify);
};

/**
 * Automatically redirects the user if not in the experiment and also determines the page slug
 * @param pageType
 * @returns
 */
const useLearnBrowse = (pageType: PremiumHubPageType) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { urlParts, inBrowse, slug } = useMemo(() => getInfoFromPathname(location.pathname), [location.pathname]);

  const onTabbedPage = pageType === HubPages.LandingPage;

  // If a user lands on /browse route and the tab is not available, redirect to the base url
  if (inBrowse && !onTabbedPage) {
    const newURL = urlParts.slice(0, urlParts.length - 1).join('/');
    navigate(`${newURL}`, { replace: true });
  }

  return {
    slug,
    inBrowse,
  };
};

export default useLearnBrowse;
