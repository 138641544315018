import * as React from 'react';

import classNames from 'classnames';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import LazyLoadingHandler from 'bundles/page/components/shared/LazyLoadingHandler';
import { getShowNewAutocomplete } from 'bundles/search-common/utils/experimentUtils';

import 'css!bundles/page-header/components/mobile/__styles__/MobileSearchPage';

const MobileSearchContents = createLoadableComponent(
  () => import('bundles/page-header/components/mobile/MobileSearchContents'),
  LazyLoadingHandler
);

type Props = {
  searchIsOpen: boolean;
  searchIsFocused: boolean;
  isSearchPage?: boolean;
  onFocus: () => void;
  onBlur: () => void;
  hideMobileSearchPage: () => void;
};

type State = {
  firstMount: boolean;
  searchHasBeenOpen: boolean;
};

class MobileSearchContainerLite extends React.Component<Props, State> {
  state = {
    firstMount: true,
    searchHasBeenOpen: false,
  };

  componentDidMount() {
    this.setState({ firstMount: false });
  }

  componentDidUpdate() {
    const { searchHasBeenOpen } = this.state;
    const { searchIsOpen } = this.props;

    // Record if search has been open ie: JS for content has been loaded
    if (searchIsOpen && !searchHasBeenOpen) {
      this.setState(() => ({
        searchHasBeenOpen: true,
      }));
    }
  }

  render() {
    const { firstMount, searchHasBeenOpen } = this.state;
    const { searchIsOpen, onFocus, onBlur, hideMobileSearchPage, isSearchPage } = this.props;

    const showNewAutocomplete = getShowNewAutocomplete();
    const shouldShowSearchContents = showNewAutocomplete ? searchIsOpen : searchIsOpen || searchHasBeenOpen;

    return (
      <div
        className={classNames('rc-MobileSearchPage', { 'open-mobile-search': searchIsOpen && !firstMount })}
        style={{ zIndex: 3001 }}
      >
        <div className="mobile-search-body-wrapper">
          {/* Keep contents in DOM if search has been loaded to support client-side routing links */}
          {shouldShowSearchContents && (
            <MobileSearchContents
              searchIsOpen={searchIsOpen}
              isSearchPage={isSearchPage}
              onBlur={onBlur}
              onFocus={onFocus}
              hideMobileSearchPage={hideMobileSearchPage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default MobileSearchContainerLite;
