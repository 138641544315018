/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { forwardRef } from 'react';

type Type = {
  type?: 'button' | 'reset' | 'submit';
};

type Variants =
  | { ghost: true; primary?: false | never; secondary?: false | never }
  | { ghost?: false | never; primary: true; secondary?: false | never }
  | { secondary: true; ghost?: false | never; primary?: false | never };

type OwnProps = Variants & Type;

export type Props = OwnProps & Omit<React.ComponentPropsWithRef<'button'>, keyof OwnProps>;

const styles = {
  default: css`
    background: transparent;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    transition: background-color 250ms ease-out, box-shadow 250ms ease-out, color 250ms ease-out;

    &:focus {
      outline: none;
    }
  `,

  ghost: css`
    color: #0056d2;
    padding: 12px 16px;

    @media (hover: hover) {
      &:hover {
        background-color: #ebf3ff;
        color: #00419e;
      }
    }

    &:focus {
      background-color: #ebf3ff;
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px #6a52e9, 0 0 0 4px #f3f8ff;
      color: #00419e;
      text-decoration: underline;
    }

    &:active {
      background-color: #f7f6fe;
      color: #382d8b;
    }

    &[disabled] {
      background-color: #bdbdbd;
      color: #e0e0e0;
    }
  `,

  primary: css`
    background-color: #0056d2;
    color: #fff;
    padding: 12px 32px;

    @media (hover: hover) {
      &:hover {
        background-color: #1859a9;
      }
    }

    &:focus {
      background-color: #1859a9;
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px #6a52e9, 0 0 0 4px #f3f8ff;
    }

    &:active {
      background-color: #382d8b;
    }

    &[disabled] {
      background-color: #bdbdbd;
      color: #e0e0e0;
    }
  `,
  secondary: css`
    color: #0056d2;
    padding: 12px 32px;
    background-color: white;
    border: 1px solid #0056d2;

    @media (hover: hover) {
      &:hover {
        background-color: #ebf3ff;
        color: #00419e;
      }
    }

    &:focus {
      background-color: #ebf3ff;
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px #6a52e9, 0 0 0 4px #f3f8ff;
      color: #00419e;
    }

    &:active {
      background-color: #f7f6fe;
      color: #382d8b;
    }

    &[disabled] {
      background-color: #bdbdbd;
      color: #e0e0e0;
    }
  `,
};

const Button = forwardRef<HTMLButtonElement, Props>(({ ghost, primary, secondary, type = 'button', ...props }, ref) => (
  <button
    {...props}
    css={[styles.default, ghost && styles.ghost, primary && styles.primary, secondary && styles.secondary]}
    ref={ref}
    type={type}
  />
));

export default Button;
