/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';
import { useLayoutEffect, useState } from 'react';

import { typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import TooltipArrow from 'bundles/common/components/Tooltip/TooltipArrow';
import { TRANSITION_MS } from 'bundles/common/components/Tooltip/constants';
import type { Placement, Variant } from 'bundles/common/components/Tooltip/types';

const styles = {
  tooltip: css`
    position: absolute;
    z-index: 100001;
    box-sizing: border-box;
    padding: var(--cds-spacing-50) var(--cds-spacing-100);
    background-color: var(--cds-color-grey-975);
    border-radius: 4px;
    display: inline-block;
    max-width: 200px;
    transition: opacity ${TRANSITION_MS}ms ease-in-out;
    opacity: 0;
    pointer-events: none;
    outline: none;
  `,
  visible: css`
    opacity: 1;
    pointer-events: auto;
  `,
  focusVisible: css`
    &:focus {
      ::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border-radius: 4px;
        box-shadow: 0 0 0 1px var(--cds-color-purple-700), 0 0 0 2px var(--cds-color-blue-25);
      }
    }
  `,
  secondaryTooltip: css`
    background-color: var(--cds-color-white-0);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    border: 1px solid var(--cds-color-grey-200);
  `,
  label: css`
    ${typography2.bodySecondary};
    color: var(--cds-color-white-0);
    text-align: center;
    overflow-wrap: break-word;
  `,
  secondaryLabel: css`
    color: var(--cds-color-grey-975);
  `,
};

export type Props = React.HTMLAttributes<HTMLElement> & {
  show?: boolean;
  arrow?: React.ReactNode;
  variant?: Variant;
  placement?: Placement;
  focusVisible?: boolean;
  arrowClassName?: string;
  arrowCss?: Interpolation<Theme>;
  enableArrow?: boolean;
};

const Tooltip = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    show = true,
    variant = 'primary',
    children,
    arrow,
    placement = 'top',
    focusVisible,
    arrowClassName,
    arrowCss,
    enableArrow = true,
    ...attributes
  } = props;
  const secondary = variant === 'secondary';

  // update visibility after the initial render so we can fade-in properly
  useLayoutEffect(() => {
    setIsVisible(show);
  }, [show]);

  return (
    <div
      ref={ref}
      css={[
        styles.tooltip,
        isVisible && styles.visible,
        secondary && styles.secondaryTooltip,
        focusVisible && styles.focusVisible,
      ]}
      data-variant={variant}
      {...attributes}
    >
      {enableArrow && (
        <React.Fragment>
          {arrow || (
            <TooltipArrow
              placement={placement}
              variant={variant}
              className={arrowClassName}
              css={arrowCss}
              data-testid="tooltip-arrow"
            />
          )}
        </React.Fragment>
      )}
      <div css={[styles.label, secondary && styles.secondaryLabel]}>{children}</div>
    </div>
  );
});

export default Tooltip;
