import type React from 'react';

import Typography from '@core/Typography2';

export type Props = {
  id?: string;
  'aria-label'?: string;
  children: React.ReactText;
};

const HeaderLabel = (props: Props) => {
  const { children, ...rest } = props;

  return (
    <Typography component="span" variant="subtitleMedium" {...rest}>
      {children}
    </Typography>
  );
};

export default HeaderLabel;
