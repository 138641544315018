/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useQuery } from '@apollo/client';

import Imgix from 'js/components/Imgix';

import type { DegreesV1 } from 'bundles/naptimejs/resources/__generated__/DegreesV1';
import type { Course } from 'bundles/page-header/components/PageHeader';
import PartnerLogoQuery from 'bundles/page-header/components/queries/PartnerLogoQuery.graphql';
import type {
  PartnerLogoQuery as PartnerLogoQueryData,
  PartnerLogoQueryVariables,
} from 'bundles/page-header/components/queries/__generated__/PartnerLogoQuery';
import { useIsSimplifiedPageHeader } from 'bundles/page-header/contexts/PageHeaderContext';
import CourseraLogo from 'bundles/page/components/CourseraLogo';

type PropsFromCaller = {
  course?: Course;
  degree?: DegreesV1;
};

const styles = {
  wrapper: () => css`
    border-left: solid 2px rgb(0 0 0 / 12%);
    margin-left: 24px;
    padding-left: 24px;
    max-height: 25px;
    max-width: 300px;
    display: flex;
    align-items: center;
  `,
  brandingImage: () => css`
    padding-right: 16px;
  `,
  logoWrapper: () => css`
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  `,
};

const UniversityLogo = ({ course, degree }: PropsFromCaller) => {
  const isSimplifiedPageHeader = useIsSimplifiedPageHeader();
  const { data } = useQuery<PartnerLogoQueryData, PartnerLogoQueryVariables>(PartnerLogoQuery, {
    variables: {
      id: String(degree?.partnerIds?.[0]),
    },
    context: { clientName: 'gatewayGql' },
    skip: !degree,
  });
  const partner = data?.Partner?.queryById;

  if (course?.brandingImageUrl) {
    return (
      <div css={styles.wrapper}>
        <div className="branding-image" css={styles.brandingImage}>
          <Imgix maxWidth={256} maxHeight={32} alt="" src={course.brandingImageUrl} className="w-100" />
        </div>
      </div>
    );
  }

  if (partner && partner.rectangularLogo) {
    return (
      <div css={[styles.wrapper, isSimplifiedPageHeader && styles.logoWrapper]} data-e2e="UniversityLogo">
        <div className="branding-image" css={styles.brandingImage}>
          <Imgix
            src={partner.rectangularLogo}
            maxWidth={isSimplifiedPageHeader ? 160 : 74}
            maxHeight={30}
            alt=""
            className="w-100"
          />
        </div>
      </div>
    );
  }

  return isSimplifiedPageHeader ? <CourseraLogo key="courseraLogo" ariaHidden /> : null;
};

export default UniversityLogo;
