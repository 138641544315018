import URI from 'jsuri';

type GenerateUTMObject = {
  href: string;
  utmCampaign?: string;
  utmContent?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTerm?: string;
};

export const generateUTM = ({ href, utmCampaign, utmContent, utmMedium, utmSource, utmTerm }: GenerateUTMObject) => {
  const uri = new URI(href);

  // Manually writing these ifs seems easier to change params to use underscores
  // because of eslint rules.
  if (utmCampaign) {
    uri.addQueryParam('utm_campaign', utmCampaign);
  }
  if (utmContent) {
    uri.addQueryParam('utm_content', utmContent);
  }
  if (utmMedium) {
    uri.addQueryParam('utm_medium', utmMedium);
  }
  if (utmSource) {
    uri.addQueryParam('utm_source', utmSource);
  }
  if (utmTerm) {
    uri.addQueryParam('utm_term', utmTerm);
  }

  return uri.toString();
};
