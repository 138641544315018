import memoize from 'lodash/memoize';

import socialPlugins from 'bundles/socialPlugins/lib';
import constants from 'bundles/third-party-auth/constants';
import type { ThirdPartyVendor } from 'bundles/third-party-auth/types';

type AppleType = {
  auth: { init: (options: object) => void };
};
// Memoized so initialization will only run once
const apple: ThirdPartyVendor = {
  init: memoize(async () => {
    const Apple = (await socialPlugins.apple()) as AppleType;

    const options = constants.apple.init;

    if (window) {
      options.redirectURI = window.location.origin;
    }

    Apple.auth.init(options);

    return Apple.auth;
  }),

  getStatusOrLogin() {
    return apple
      .init()
      .then((AppleID) => {
        return AppleID.signIn();
      })
      .catch(({ error }) => {
        // @link https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
        if (error === 'user_cancelled_authorize') {
          return Promise.reject({ code: 'notAuthorized' });
        } else {
          // Handles `popup_closed_by_user`
          return Promise.reject({ code: 'unknownStatus' });
        }
      });
  },
};

export default apple;
