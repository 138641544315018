/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, ContextualHelp, Dialog, Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/search-common';

const styles = {
  filterLabelCount: css`
    color: var(--cds-color-grey-600);
    margin-left: var(--cds-spacing-50);
  `,

  dialogText: css`
    margin-bottom: var(--cds-spacing-300);
  `,

  dialogCta: css`
    margin-bottom: var(--cds-spacing-200);
  `,
};

type SearchFilterLabelDialogProps = {
  label: string | JSX.Element;
  count: number;
  dialogText: string;
};

const SearchFilterLabelDialog = ({ label, count, dialogText }: SearchFilterLabelDialogProps) => {
  // If the label is a string, we can use it for the dialog heading
  const labelText = typeof label === 'string' ? label : '';

  return (
    <ContextualHelp
      helpToggleProps={{
        'aria-label': _t('Information about this filter'),
      }}
      label={
        <>
          {label}
          {count > 0 && <span css={styles.filterLabelCount}>({count.toLocaleString()})</span>}
        </>
      }
    >
      {({ onClose, isExpanded = false }) => (
        <Dialog aria-describedby="dialog-content" onClose={onClose} open={isExpanded}>
          {labelText && <Dialog.HeadingGroup>{labelText}</Dialog.HeadingGroup>}
          <Dialog.Content id="dialog-content">
            <Typography2 component="p" css={styles.dialogText}>
              {dialogText}
            </Typography2>
          </Dialog.Content>

          <Dialog.Actions>
            <Button size="medium" variant="primary" onClick={onClose} css={styles.dialogCta}>
              {_t('Got it')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </ContextualHelp>
  );
};

export default SearchFilterLabelDialog;
