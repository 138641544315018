/**
 * This file exposes RESTful functions that override jQuery AJAX functionality to:
 *  - create and clear CSRF tokens
 *  - show loading and loaded messages during requests
 *  - provide support for PATCH via the HTTP method override header
 *  Usage example:
 *    Coursera.api = API('api/');
 *  You can also pass in options, see the DEFAULTS hash for the possibilities.
 *
 */
import $ from 'jquery';

import Cookie from 'js/lib/cookie';
import { getIetfLanguageTag } from 'js/lib/language';
import pathUtil from 'js/lib/path';

declare const COURSERA_APP_VERSION: string;
declare const COURSERA_APP_NAME: string;

export type ApiOptions = {
  type?: 'normal' | 'rest' | 'post';

  contentType?: string;
  processData?: boolean;
  data?: Record<string, unknown> | Record<string, unknown>[] | string | string[] | any;
  headers?: Record<string, string>;
};

export interface API {
  get<T = any>(path: string | number, options?: ApiOptions): Promise<T>;

  patch(path: string, options?: ApiOptions): Promise<$TSFixMe>;

  delete(path: string, options?: ApiOptions): Promise<$TSFixMe>;

  post<T = any>(path: string, options?: ApiOptions): Promise<T>;

  put(path: string, options?: ApiOptions): Promise<$TSFixMe>;
}

type Headers = Record<string, string>;

type RestifiedOptions = Omit<ApiOptions, 'type'> & {
  type?: 'normal' | 'rest' | 'post' | 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  data: Record<string, unknown> | Record<string, unknown>[] | string | string[];
  headers: Record<string, string>;
};

type Options = {
  type: 'normal' | 'rest' | 'post';
  headers?: Headers;
  data?: unknown;
};

// Count of total inflight XmlHttpRequests. We set the CSRF cookie at the start of the first request, but don't
// change it for new requests if others are still in flight because Safari gets confused and sends the wrong
// cookies. When this count reaches 0, we clear the CSRF Cookie.
let globalActiveAjaxCount = 0;

const Constants = {
  'csrf.token': 'X-CSRFToken',
  'csrf.cookie': 'csrftoken',
  'csrf.path': undefined,
  'csrf.domain': undefined,
  'csrf.secure': true,
  'csrf2.token_header': 'X-CSRF2-Token',
  'csrf2.cookie_name_header': 'X-CSRF2-Cookie',
  'csrf2.cookie_prefix': 'csrf2_token_',
  'csrf3.token_header': 'X-CSRF3-Token',
  'csrf3.cookie_name': 'CSRF3-Token',
  'r2.app_name_header': 'X-Coursera-Application',
  'r2.app_version_header': 'X-Coursera-Version',
} as const;

const DEFAULTS = Object.freeze({
  type: 'normal',
}) satisfies Options;

class ApiImpl implements API {
  root: string;

  options: Options;

  private csrfGet() {
    // If there is an existing CSRF cookie, there must be an inflight AJAX request (see globalActiveAjaxCount),
    // so we leave that cookie to avoid confusing Safari and have it send the wrong cookie with previous AJAX calls.
    // Otherwise, generate a new CSRF.
    const token = Cookie.get(Constants['csrf.cookie']);
    if (token) {
      return token;
    } else {
      return this.csrfSet(this.csrfMake());
    }
  }

  private csrfClear() {
    Cookie.remove(Constants['csrf.cookie'], {
      secure: Constants['csrf.secure'],
      path: Constants['csrf.path'],
      domain: Constants['csrf.domain'],
    });
  }

  private csrfSet(token: string) {
    Cookie.set(Constants['csrf.cookie'], token, {
      secure: Constants['csrf.secure'],
      path: Constants['csrf.path'],
      domain: Constants['csrf.domain'],
      expires: new Date(new Date().getTime() + 60000),
    });
    return token;
  }

  private csrfMake(length = 24, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
    const output = [];

    for (let i = 0; i < length; i += 1) {
      output.push(chars[Math.floor(Math.random() * chars.length)]);
    }

    return output.join('');
  }

  private csrf2SetUp(ajaxOptions: RestifiedOptions): void {
    if (ajaxOptions.type === 'GET') {
      return;
    }

    const cookieName = Constants['csrf2.cookie_prefix'] + this.csrfMake(8);
    const token = this.csrfMake();

    Cookie.set(cookieName, token, {
      secure: Constants['csrf.secure'],
      path: Constants['csrf.path'],
      domain: Constants['csrf.domain'],
      expires: new Date(new Date().getTime() + 60000),
    });

    ajaxOptions.headers[Constants['csrf2.cookie_name_header']] = cookieName;
    ajaxOptions.headers[Constants['csrf2.token_header']] = token;
  }

  private csrf2TearDown(ajaxHeaders: Record<string, string | null | undefined>) {
    const cookieName = ajaxHeaders[Constants['csrf2.cookie_name_header']];
    if (cookieName) {
      Cookie.remove(cookieName, {
        secure: Constants['csrf.secure'],
        path: Constants['csrf.path'],
        domain: Constants['csrf.domain'],
      });
    }
  }

  private invoke(path: string, _options: RestifiedOptions) {
    const options: JQuery.AjaxSettings = Object.assign({}, _options) || {};
    let url;
    if (path.indexOf('http') !== 0) {
      url = pathUtil.join(this.root, path);
    } else {
      url = path;
    }

    // For non-GET requests, decide how to format the data for the server
    // We convert to JSON if the API type is 'rest' and the AJAX call options
    // didn't set processData to true.
    if (options.type !== 'GET' && typeof options.processData === 'undefined' && this.options.type === 'rest') {
      options.contentType = 'application/json; charset=utf-8';
      options.processData = false;
      options.data = JSON.stringify(options.data);
    }

    options.beforeSend = function () {
      globalActiveAjaxCount += 1;
    };

    const jqXHR = $.ajax(url, options);

    jqXHR.always(() => {
      globalActiveAjaxCount -= 1;
      if (globalActiveAjaxCount === 0) {
        this.csrfClear();
      }

      this.csrf2TearDown(options.headers || {});
    });

    return Promise.resolve(jqXHR);
  }

  private restify(method: string, _options?: Readonly<ApiOptions>): RestifiedOptions {
    const options: RestifiedOptions = Object.assign(
      {
        data: {},
        headers: { 'Accept-Language': getIetfLanguageTag() },
      },
      _options
    );

    switch (method) {
      case 'POST':
        options.type = 'POST';
        break;

      case 'PATCH':
        options.type = 'POST';
        options.headers['X-HTTP-Method-Override'] = 'PATCH';

        break;

      case 'PUT':
        options.type = 'PUT';

        break;

      case 'DELETE':
        options.type = 'DELETE';

        break;

      default:
        options.type = 'GET';
        break;
    }

    if (options.type !== 'GET') {
      options.headers[Constants['csrf.token']] = this.csrfGet();

      this.csrf2SetUp(options);

      const csrf3Token = Cookie.get(Constants['csrf3.cookie_name']);
      if (csrf3Token) {
        options.headers[Constants['csrf3.token_header']] = csrf3Token;
      }
    }

    const r2AppName = COURSERA_APP_NAME;
    const r2AppVersion = COURSERA_APP_VERSION;
    if (r2AppName) {
      options.headers[Constants['r2.app_name_header']] = r2AppName;
    }
    if (r2AppVersion) {
      options.headers[Constants['r2.app_version_header']] = r2AppVersion;
    }
    return options;
  }

  constructor(root: string, apiConfig?: ApiOptions) {
    this.root = root;
    this.options = Object.assign({}, DEFAULTS, apiConfig);
  }

  customize(apiConfig?: ApiOptions) {
    this.options = Object.assign(this.options, apiConfig);
    return this;
  }

  // options include any $.ajax options you would normally include
  // just be aware that this library takes care of csrf headers, GET/POST types, X-HTTP-METHOD-OVERRIDE, and converting data to json

  get(path: string, options?: ApiOptions) {
    return this.invoke(path, this.restify('GET', options));
  }

  patch(path: string, options?: ApiOptions) {
    return this.invoke(path, this.restify('PATCH', options));
  }

  delete(path: string, options?: ApiOptions) {
    return this.invoke(path, this.restify('DELETE', options));
  }

  post(path: string, options?: ApiOptions) {
    return this.invoke(path, this.restify('POST', options));
  }

  put(path: string, options?: ApiOptions) {
    return this.invoke(path, this.restify('PUT', options));
  }
}

export default function (root: string, apiConfig?: ApiOptions): API {
  return new ApiImpl(root, apiConfig);
}
