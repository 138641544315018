import * as React from 'react';

import { Button, Dialog } from '@coursera/cds-core';

import { useEoiOptimizationsContext } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';
import { isDestinationDegree, isPerformancePathwayDegree } from 'bundles/expression-of-interest/utils/util';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import _t from 'i18n!nls/expression-of-interest';

type Props = {
  email: string;
  handleClose: () => void;
  applicationUrl?: string | null;
  trackingProductId: string;
};

export const ConfirmationSection = (props: Props): React.ReactElement => {
  const { email, applicationUrl, handleClose, trackingProductId } = props;
  const { slug, productType, applyButtonData } = useEoiOptimizationsContext();
  const isPbaDegree = isPerformancePathwayDegree(slug);
  const isDestinationDegreeEoi = isDestinationDegree(slug);

  const showApplyButton =
    !!applicationUrl || (productType === ProductTypes.SPECIALIZATION && applyButtonData?.doAction);

  const { trackClickButton } = useEoiEventing(trackingProductId);

  const onClick = () => {
    trackClickButton('degree_apply_from_eoi');
    handleClose();

    applyButtonData?.doAction?.();

    if (applicationUrl) {
      window.open(applicationUrl, '_blank', 'noopener noreferrer');
    }
  };

  const buttonText = () => {
    return (
      applyButtonData?.buttonOptions?.label ||
      (isPbaDegree && _t('Enroll now')) ||
      (isDestinationDegreeEoi && _t('View application')) ||
      _t('Apply now')
    );
  };
  const getStartedWithNextStepText = isPbaDegree
    ? _t('In the meantime, you can also get started with your enrollment process.')
    : _t('In the meantime, you can also get started with your application!');

  const { HeadingGroup, Content, Actions } = Dialog;

  const isDegree = productType === ProductTypes.DEGREE;
  const confirmationText = isDegree
    ? _t(
        'You can expect an email sent to #{email} that includes more information about this specific program. #{getStartedWithNextStepText}',
        { email, getStartedWithNextStepText }
      )
    : _t('You can expect an email sent to #{email} that includes more information.', {
        email,
      });

  return (
    <>
      <HeadingGroup>{_t("You're all set!")}</HeadingGroup>

      <Content>{confirmationText}</Content>

      <Actions>{showApplyButton && <Button onClick={onClick}>{buttonText()}</Button>}</Actions>
    </>
  );
};

export default ConfirmationSection;
