/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, breakpoints } from '@coursera/cds-core';
import type { Button } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { BasicCard } from 'bundles/cds-labs/components';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import { getSlugFromHref } from 'bundles/premium-hub/utils';

type Props = {
  title: string;
  slug?: string;
  callToActionLink: string;
};

const styles = {
  categoryCard: css`
    background-color: var(--cds-color-emphasis-primary-background-xweak);
    padding: var(--cds-spacing-150);
    display: flex;
    align-items: center;
    height: 100%;

    ${breakpoints.up('md')} {
      padding: var(--cds-spacing-400);
      border: 0;
    }
  `,

  title: css`
    ${breakpoints.down('sm')} {
      font-size: var(--cds-font-size-175);
      font-weight: var(--cds-font-weight-600);
    }
  `,
};

const CategoryCard: React.FunctionComponent<Props> = ({ slug, callToActionLink, title }) => {
  const trackV3 = useTracker();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const finalSlug = slug || getSlugFromHref(callToActionLink);

  return (
    <BasicCard
      component={TrackedLink2}
      css={styles.categoryCard}
      href={callToActionLink}
      aria-label={`${title}`}
      data-e2e={`${finalSlug}-category-card`}
      trackingName="category_link"
      withVisibilityTracking={true}
      requireFullyVisible={true}
      data={{
        entityId: finalSlug,
        link: callToActionLink,
        title,
      }}
      onClick={() =>
        trackV3('click_button', {
          button: {
            name: 'degrees_category_hub',
            linkType: 'internal',
            linkName: title,
            linkURL: callToActionLink,
          } as Button,
          pageSection: {
            sectionName: 'browse_by',
            index: sectionIndex,
          },
        })
      }
    >
      <div>
        <Typography variant="h2semibold" component="p" css={styles.title}>
          {title}
        </Typography>
      </div>
    </BasicCard>
  );
};

export default CategoryCard;
