import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('popoverFooter', ['root']);

const styles = css`
  box-sizing: border-box;

  .${classes.root} {
    box-sizing: inherit;
    padding: var(--popover-padding);
    border-top: 1px solid var(--cds-color-neutral-stroke-primary);
    background: var(--cds-color-neutral-background-primary);
  }
`;

export default styles;
