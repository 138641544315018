import type React from 'react';
import { useEffect } from 'react';

/**
 * useFocusTrap hook traps focus within a specified element, preventing focus from leaving the element when
 * using the 'Tab' key. If the last focusable element is reached, it cycles back to the first focusable element,
 * and vice versa when 'Shift + Tab' is used.
 *
 * @param elementRef - A reference to the DOM element where focus should be trapped.
 * @param isFocusTrapEnabled - A flag to enable or disable the focus trap.
 */
export const useFocusTrap = (elementRef: React.RefObject<HTMLElement>, isFocusTrapEnabled = false) => {
  useEffect(() => {
    if (!isFocusTrapEnabled) {
      return () => {}; // Skip activating the focus trap if it's not enabled
    }

    const handleFocus = (e: KeyboardEvent) => {
      const element = elementRef.current;

      if (e.key !== 'Tab' || !element) {
        return;
      }

      const focusableElements = (element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      ) || []) as NodeListOf<HTMLElement>;

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleFocus);

    return () => document.removeEventListener('keydown', handleFocus);
  }, [elementRef, isFocusTrapEnabled]);
};
