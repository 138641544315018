import { supportedSubfolderLocaleCodes } from 'bundles/internationalization-lib/constants/subfolderLocaleCodes';

const getXDPPageLink = ({
  subfolderLocaleCode,
  productPageUrlPath,
}: {
  subfolderLocaleCode?: string;
  productPageUrlPath: string;
}) => {
  if (subfolderLocaleCode && supportedSubfolderLocaleCodes.includes(subfolderLocaleCode)) {
    return `/${subfolderLocaleCode}${productPageUrlPath}`;
  }

  return productPageUrlPath;
};

export default getXDPPageLink;
