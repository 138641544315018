import _ from 'lodash';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'vend... Remove this comment to see the full error message
import createStore from 'vendor/cnpm/fluxible.v0-4/addons/createStore';

import { getAnswerType, isResponseEmpty } from 'bundles/survey-form/utils/SurveyFormUtils';
import { validateResponse } from 'bundles/survey-form/utils/ValidationUtils';
import { SectionTypes } from 'bundles/university-program-qualification/utils/constants';

const ProgramQualificationStore = createStore({
  storeName: 'ProgramQualificationStore',

  initialize() {
    this.reset();
  },

  getProductId() {
    return this.productId;
  },

  getResponseId() {
    return this.responseId;
  },

  getResponse() {
    return this.response;
  },

  getResponseValidation() {
    return this.responseValidation;
  },

  getSectionId() {
    return this.sectionId;
  },

  getSectionData() {
    return this.sectionData;
  },

  getTrackingFields() {
    return this.trackingFields;
  },

  getIsValidResponse() {
    return this.isValidResponse;
  },

  getIsSubmitting() {
    return this.isSubmitting;
  },

  getIsTerminal() {
    return this.isTerminal;
  },

  reset() {
    this.sectionData = undefined;
    this.trackingFields = {};
    this.response = {};
    this.responseValidation = {};
    this.validatedBefore = false;
    this.isSubmitting = false;
    this.isTerminal = false;
  },
});

ProgramQualificationStore.handlers = {
  SUBMITTING_QUALIFICATION_RESPONSE() {
    this.isSubmitting = true;
    this.emitChange();
  },

  RECEIVE_SECTION_DATA(data: $TSFixMe) {
    const {
      id,
      section: { definition, typeName },
      sectionId,
    } = data;

    this.reset();
    this.responseId = id;
    this.sectionData = definition;
    this.sectionData.sectionType = typeName;
    this.sectionId = sectionId;
    this.isSubmitting = false;

    if (typeName === SectionTypes.TERMINAL) this.isTerminal = true;
    this.emitChange();
  },

  PREVIEW_QUALIFICATION(data: $TSFixMe) {
    const {
      productId,
      section: { definition, typeName },
      sectionId,
    } = data;

    this.reset();
    this.productId = productId;
    this.sectionData = definition;
    this.sectionData.sectionType = typeName;
    this.sectionId = sectionId;
    this.emitChange();
  },

  SET_TRACKING_FIELD(response: $TSFixMe) {
    this.trackingFields[response.id] = response.value;
    this.emitChange();
  },

  UPDATE_RESPONSE(data: $TSFixMe) {
    _.forEach(data, (value, id) => {
      const responseType = getAnswerType(value);
      this.response[id] = isResponseEmpty(value, responseType) ? undefined : value;
    });

    if (this.validatedBefore) {
      this.responseValidation = validateResponse(this.sectionData, this.response);
    }

    this.emitChange();
  },

  UPDATE_VALIDATION_ERROR(data: $TSFixMe) {
    this.responseValidation = data;
    this.validatedBefore = true;
    this.isSubmitting = false;
    this.emitChange();
  },

  RESET_RESPONSE() {
    this.response = {};
    this.emitChange();
  },
};

export default ProgramQualificationStore;

export const { handlers } = ProgramQualificationStore;
