import * as React from 'react';

import { Grid } from '@coursera/cds-core';

import type { CommunityStatProps, LearningCommunitySectionProps } from 'bundles/cms/types';
import CommunityStats from 'bundles/premium-hub/components/shared/CommunityStats';
import LearnerProfile from 'bundles/premium-hub/components/shared/LearnerProfile';

class LearningCommunitySection extends React.Component<LearningCommunitySectionProps> {
  renderCommunityStat(communityStat: CommunityStatProps) {
    if (!communityStat) {
      return null;
    }

    const { name, profilePicture, enrolledProgram, highlightLabel, highlightStats, imageOnTop } = communityStat;

    return (
      <Grid item xs={12} sm={6} lg={3} key={name}>
        {imageOnTop && <LearnerProfile name={name} profilePicture={profilePicture} enrolledProgram={enrolledProgram} />}
        <CommunityStats highlightLabel={highlightLabel} highlightStats={highlightStats} />
        {!imageOnTop && (
          <LearnerProfile name={name} profilePicture={profilePicture} enrolledProgram={enrolledProgram} />
        )}
      </Grid>
    );
  }

  render() {
    const { learningCommunityTitle, communityStats } = this.props;

    if (!communityStats || !communityStats.length) {
      return null;
    }

    return (
      <Grid container direction="column">
        <h2 className="text-xs-center m-y-3">{learningCommunityTitle}</h2>
        <Grid container className="m-b-3">
          {communityStats.map(this.renderCommunityStat)}
        </Grid>
      </Grid>
    );
  }
}

export default LearningCommunitySection;
