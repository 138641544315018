/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid } from '@coursera/cds-core';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import Container from 'bundles/premium-hub/components/shared/Container';
import useTrackLinks from 'bundles/premium-hub/hooks/useTrackLinks';

type Props = {
  content: React.ReactElement;
  sidebarContent?: React.ReactElement;
  category?: string;
  subBenefits?: React.ReactElement;
};

const styles = {
  container: css`
    margin: var(--cds-spacing-600) 0;
    cursor: default;
  `,
};

const InformationalSectionContainer = ({ content, sidebarContent, category, subBenefits }: Props) => {
  const handleEventingClick = useTrackLinks('degree_info_link', 'why_pursue_online_degree');

  return (
    <TrackedDiv
      withVisibilityTracking
      requireFullyVisible={false}
      trackingName="informational_section"
      data={{ category }}
      css={styles.container}
      onClick={handleEventingClick}
    >
      <Container>
        <Grid container spacing={32}>
          <Grid item xs={12} md={sidebarContent ? 8 : 12}>
            {content}
          </Grid>
          {sidebarContent && (
            <Grid item xs={12} md={4}>
              {sidebarContent}
            </Grid>
          )}
          <Grid item xs={12}>
            {subBenefits}
          </Grid>
        </Grid>
      </Container>
    </TrackedDiv>
  );
};

export default React.memo(InformationalSectionContainer);
