import { css } from '@emotion/react';

const styles = css`
  margin: 0;
  outline: none;
  list-style: none;
  box-sizing: content-box;

  li + li {
    margin-top: var(--cds-spacing-50);
  }
`;

export default styles;
