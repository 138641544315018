/** @jsx jsx */
import { jsx } from '@emotion/react';

import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import libphonenumber from 'google-libphonenumber';

import { TextField } from '@coursera/cds-core';

import formStyles from 'bundles/account-profile/components/i18n-phone-number-input/styles/FormStyles';
import type {
  CustomStyles,
  E164PhoneNumber,
  InternationalPhoneNumber,
  PhoneNumberRegion,
  ValidPhone,
} from 'bundles/account-profile/components/i18n-phone-number-input/types';
import {
  formatCountryCodePrefix,
  getExampleNumber,
  inputStringIntoFormatter,
  isValidInternationalPhoneNumber,
  stripCountryCodeFromE164PhoneNumber,
  stripCountryCodeFromInternationalPhoneNumber,
  toValidPhone,
} from 'bundles/account-profile/components/i18n-phone-number-input/utils';

import _t from 'i18n!nls/account-profile';

const baseTextFieldStyles = [formStyles.textArea, formStyles.hideChildLabel, formStyles.removeChildInputBottomMargin];

type Props = {
  customStyles?: CustomStyles;
  selectedRegion: PhoneNumberRegion;
  phoneNumber?: E164PhoneNumber | '';
  showCustomError: boolean;
  isEmptyValid: boolean;
  setTextFieldTopOffset: Dispatch<SetStateAction<number>>;
  setCurrentInputValue: Dispatch<SetStateAction<string | undefined>>;
  setTextFieldHasFocus: Dispatch<SetStateAction<boolean>>;
  onPhoneNumberChange: (phone?: ValidPhone) => void;
};

const useWindowWidth = () => {
  // Taken from https://usehooks.com/useWindowSize/
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowWidth;
};

const InternationalPhoneNumberTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    customStyles,
    selectedRegion,
    phoneNumber,
    showCustomError,
    isEmptyValid,
    setTextFieldTopOffset,
    setCurrentInputValue,
    setTextFieldHasFocus,
    onPhoneNumberChange,
  } = props;

  const formatter = new libphonenumber.AsYouTypeFormatter(selectedRegion);
  const inputRef = ref as MutableRefObject<HTMLInputElement | null>;
  const windowWidth = useWindowWidth();

  const [displayValue, setDisplayValue] = useState(() => {
    if (phoneNumber) {
      return (
        formatCountryCodePrefix(selectedRegion) +
        ' ' +
        inputStringIntoFormatter(formatter, stripCountryCodeFromE164PhoneNumber(phoneNumber, selectedRegion) ?? '')
      );
    }
    return undefined;
  });

  const examplePhoneNumber = getExampleNumber(selectedRegion);
  const calculatedTextFieldStyles = useMemo(() => {
    return [
      ...baseTextFieldStyles,
      ...(showCustomError ? [customStyles?.phoneNumberTextField] : [formStyles.removeTopMarginImmediateChildDiv]),
    ];
  }, [customStyles?.phoneNumberTextField, showCustomError]);

  const repositionCursor = () => {
    if (displayValue && inputRef && inputRef.current) {
      const index = displayValue.length;
      const finalIndex = index === -1 ? displayValue.length : index;
      inputRef.current.setSelectionRange(finalIndex, finalIndex);
    }
  };

  const handleChange = (currentValue?: string, appendCountryCode?: boolean) => {
    formatter.clear();
    const nationalNumber = stripCountryCodeFromInternationalPhoneNumber(currentValue) ?? '';
    const onlyDigits = nationalNumber.split('').filter((ch) => ch.match(/[0-9]/));
    const formattedValue = inputStringIntoFormatter(formatter, onlyDigits?.join(''));

    // Saving phone number if it is an international number or empty string
    let currentPhoneNumber: string;
    if (currentValue === '') {
      // Allows learner to save if the field is empty
      currentPhoneNumber = '';
    } else {
      currentPhoneNumber = formatCountryCodePrefix(selectedRegion) + ' ' + formattedValue;
    }

    const isValid = isValidInternationalPhoneNumber(
      currentPhoneNumber as InternationalPhoneNumber,
      selectedRegion,
      isEmptyValid
    );
    if (!isEmptyValid || isValid) {
      onPhoneNumberChange(toValidPhone(currentPhoneNumber as InternationalPhoneNumber, selectedRegion, isEmptyValid));
    }

    setDisplayValue(formattedValue || appendCountryCode ? currentPhoneNumber : currentValue);
    setCurrentInputValue(currentValue);
  };

  const handleFocus = () => {
    repositionCursor();
    setTextFieldHasFocus(true);

    if (displayValue?.charAt(0) !== '+') {
      // Appends a country code if the field is empty and the learner clicks into it
      const nationalNumber = displayValue ?? '';
      const desiredValue = formatCountryCodePrefix(selectedRegion) + ' ' + nationalNumber;
      handleChange(desiredValue, true);
    }
  };

  const handleBlur = () => {
    setTextFieldHasFocus(false);

    if (displayValue?.charAt(0) !== '+' && !isEmptyValid) {
      // Appends a country code if the learner deleted it and entered a phone number
      const desiredValue = formatCountryCodePrefix(selectedRegion) + ' ' + displayValue;
      handleChange(desiredValue, true);
    }
  };

  useEffect(() => {
    if (displayValue?.charAt(0) === '+') {
      // Only want to update displayValue if a learner has already entered something into the field
      const nationalNumber = stripCountryCodeFromInternationalPhoneNumber(displayValue) ?? '';
      const desiredValue = formatCountryCodePrefix(selectedRegion) + ' ' + nationalNumber;
      handleChange(desiredValue, true);
    }
    // Only want this to run if the learner changes the country
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    if (
      inputRef &&
      inputRef.current &&
      inputRef.current.parentElement &&
      inputRef.current.parentElement.previousElementSibling &&
      inputRef.current.parentElement.previousElementSibling.classList.contains('cds-text-field-formValidationStatus')
    ) {
      const validationLabelHeight = inputRef.current.parentElement.previousElementSibling.clientHeight;
      const validationLabelOffset = 4;
      setTextFieldTopOffset(validationLabelHeight - validationLabelOffset);
    } else {
      setTextFieldTopOffset(0);
    }
    // We only want to update this when the position of the input field changes
    // and it only changes when an error occurs and when the window width changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomError, windowWidth]);

  return (
    <TextField
      data-testid="international_phone_number_text_field"
      optional
      css={calculatedTextFieldStyles}
      type="tel"
      label={_t('Phone Number')}
      value={displayValue}
      placeholder={_t('ex. #{examplePhoneNumber}', { examplePhoneNumber })}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      validationStatus={showCustomError ? 'error' : undefined}
      validationLabel={_t('Invalid phone number')}
      inputRef={inputRef}
      inputProps={{ onClick: repositionCursor }}
    />
  );
});

export default InternationalPhoneNumberTextField;
