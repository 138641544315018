/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import requestCountry from 'js/lib/requestCountry';

import {
  FRENCH_CANADIAN_BCORP_LOGO_WIDTH,
  US_BCORP_LOGO_WIDTH,
} from 'bundles/page-footer/components/global-footer/constants';
import { FRENCH_CANADIAN_BCORP_LOGO_SRC, US_BCORP_LOGO_SRC } from 'bundles/page/common/constants';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/page';

const styles = {
  bCorpLogoContainer: css`
    margin-top: 25px;
    max-width: 151px;
    max-height: 120px;

    img {
      display: block;
      margin: 0 auto;
    }
  `,
};

const BCorpLogo = () => {
  const requestCountryCode = requestCountry.get();
  const useFrenchCanadianLogo = requestCountryCode === 'FR' || requestCountryCode === 'CA';
  const BcorpLogoSrc = useFrenchCanadianLogo ? FRENCH_CANADIAN_BCORP_LOGO_SRC : US_BCORP_LOGO_SRC;
  const BcorpLogoWidth = useFrenchCanadianLogo ? FRENCH_CANADIAN_BCORP_LOGO_WIDTH : US_BCORP_LOGO_WIDTH;

  return (
    <div className="b-corp-logo" css={styles.bCorpLogoContainer}>
      <LazyImgix
        src={BcorpLogoSrc}
        maxWidth={151}
        maxHeight={120}
        width={BcorpLogoWidth}
        height={120}
        alt={_t('Logo of Certified B Corporation')}
      />
    </div>
  );
};

export default BCorpLogo;
