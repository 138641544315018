import config from 'js/app/config';

export const MASTERS_DEGREES_LIST_HASH = '#mastersdegreelist';
export const BACHELORS_DEGREES_LIST_HASH = '#bachelorsdegreelist';
export const EOI_HASH = '#eoi';

export const APPLICATION_DATE_FORMAT = 'MMMM Do, YYYY';
// Degree application date is not accurate to the hour and minute, so no need to convert it to the user's timezone
export const APPLICATION_DATE_TIMEZONE_OVERRIDE = 'UTC';

// This field is more generalized in DISPLAYED_COUNT and will be removed later
export const DISPLAYED_DEGREES_COUNT = 3;
export const DISPLAYED_COUNT = 3;
export const DISPLAYED_TOP_PRODUCTS_COUNT = 6;
export const FILTERED_DEGREES_PER_PAGE_COUNT = 5;

export const SALESFORCE_PARTNER = {
  id: 695,
  name: 'Salesforce',
  squareLogo:
    'http://coursera-university-assets.s3.amazonaws.com/72/51d0a967254363b3c2c35be6c09b99/Salesforce-Institution-Square-180px-.png',
};

export const ProductType = {
  Degrees: 'Degrees',
  Certificates: 'Certificates',
};

export const ProductVariant = {
  BachelorsDegree: 'BachelorsDegree',
  MastersDegree: 'MastersDegree',
  Mastertrack: 'MasterTrack',
  PostgraduateDiploma: 'PostgraduateDiploma',
};

export const CertificateCategory = {
  Entry: 'entry_level_pc_hub',
  Advanced: 'advanced_level_pc_hub',
  Certification: 'certification_program_pc_hub',
  DataScience: 'data_science_certificate_pc_hub',
  Business: 'business_certificate_pc_hub',
  ComputerScience: 'computer_science_IT_certificate_pc_hub',
};

export const HubPages = {
  MainHubPage: 'MainHubPage',
  DegreesCategoryHubPage: 'DegreesCategoryHubPage',
  ProductVariantHubPage: 'ProductVariantHubPage',
  LandingPage: 'LandingPage',
} as const;

export type PremiumHubPageType = keyof typeof HubPages;

export const UnifiedHubProductVariant = {
  ProfessionalCertificate: 'CERTIFICATE_PRODUCT_VARIANT_PROFESSIONAL_CERTIFICATE',
  Mastertrack: 'CERTIFICATE_PRODUCT_VARIANT_MASTER_TRACK',
  UniversityCertificate: 'CERTIFICATE_PRODUCT_VARIANT_UNIVERSITY_CERTIFICATE',
  GraduateCertificate: 'CERTIFICATE_PRODUCT_VARIANT_GRADUATE_CERTIFICATE',
  Degree: 'Degree',
};

export const HUB_TYPE_TO_DOTS_IMAGE = {
  [HubPages.MainHubPage]: {
    left: `${config.url.resource_assets}premium-hub/brand-dots/DegHub-Left.png`,
    right: `${config.url.resource_assets}premium-hub/brand-dots/DegHub-Right.png`,
  },
  [HubPages.DegreesCategoryHubPage]: `${config.url.resource_assets}premium-hub/brand-dots/DCAT-Right.png`,
  [HubPages.ProductVariantHubPage]: `${config.url.resource_assets}premium-hub/brand-dots/Bach-Mast-Hub-Right.png`,
  [HubPages.LandingPage]: `${config.url.resource_assets}premium-hub/brand-dots/Bach-Mast-Hub-Right.png`,
};

export const HUB_TYPE_TO_BANNER_IMAGE = {
  [HubPages.MainHubPage]: `${config.url.resource_assets}premium-hub/banner/Banner_Logo_Main_Hub.png`,
  [HubPages.DegreesCategoryHubPage]: `${config.url.resource_assets}premium-hub/banner/Banner_Logo_Category_Hub_Page.png`,
  [HubPages.ProductVariantHubPage]: `${config.url.resource_assets}premium-hub/banner/Banner_Logo_Category_Hub_Page.png`,
  [HubPages.LandingPage]: `${config.url.resource_assets}premium-hub/banner/Banner_Logo_Category_Hub_Page.png`,
};

export const DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT = {
  masters: ProductVariant.MastersDegree,
  bachelors: ProductVariant.BachelorsDegree,
  'postgraduate-studies': ProductVariant.PostgraduateDiploma,
};

export const CERTIFICATE_HUB_SLUG_TO_PRODUCT_VARIANTS: { [key: string]: string[] } = {
  'professional-certificate': [UnifiedHubProductVariant.ProfessionalCertificate],
  mastertrack: [UnifiedHubProductVariant.Mastertrack],
  learn: [UnifiedHubProductVariant.UniversityCertificate, UnifiedHubProductVariant.GraduateCertificate],
};

export const SLUG_TO_CERTIFICATE_CATEGORY = {
  'launch-your-career': CertificateCategory.Entry,
  'advance-your-career': CertificateCategory.Advanced,
  'prepare-for-a-certification': CertificateCategory.Certification,
  'data-science': CertificateCategory.DataScience,
  business: CertificateCategory.Business,
  'computer-science-it': CertificateCategory.ComputerScience,
};

export const PRODUCT_VARIANT_TO_PRODUCT_NAME = {
  [UnifiedHubProductVariant.ProfessionalCertificate]: 'Professional Certificate',
};

export const PRODUCT_TYPE_TO_SLUG = {
  [ProductType.Degrees]: 'degrees',
  [ProductType.Certificates]: 'certificates',
};

export const CATEGORY_ORDER = [
  CertificateCategory.Entry,
  CertificateCategory.Advanced,
  CertificateCategory.Certification,
  CertificateCategory.DataScience,
  CertificateCategory.Business,
  CertificateCategory.ComputerScience,
];

export const VARIANT_ORDER = [
  UnifiedHubProductVariant.ProfessionalCertificate,
  UnifiedHubProductVariant.Mastertrack,
  UnifiedHubProductVariant.UniversityCertificate,
  UnifiedHubProductVariant.GraduateCertificate,
];

export const VARIANT_TO_ELEMENT_ID = {
  [UnifiedHubProductVariant.ProfessionalCertificate]: 'professional-certificates',
  [UnifiedHubProductVariant.Mastertrack]: 'mastertrack',
  [UnifiedHubProductVariant.UniversityCertificate]: 'university-certificates',
};

export const DEGREE_HUB_PRODUCT_VARIANT_FILTERS = [ProductVariant.MastersDegree, ProductVariant.BachelorsDegree];

export const TEST_CATEGORY_HUB_SLUG = 'test-category-hub';

export const HUB_MODULES = {
  degreesHubBanner: 'degreesHubBanner',
  hubBanner: 'hubBanner',
  heroValueProp: 'heroValueProp',
  heroBanner: 'heroBanner',
  heroBannerRedesign: 'heroBannerRedesign',
  browseDegrees: 'browseDegrees',
  faqs: 'faqs',
  callToAction: 'callToAction',
  testimonials: 'testimonials',
  relatedArticles: 'relatedArticles',
  valueProposition: 'valueProposition',
  columnAndList: 'columnAndList',
  programLevelCards: 'programLevelCards',
  categoryCards: 'categoryCards',
  industryRelevance: 'industryRelevance',
  industryRelevanceInformation: 'industryRelevanceInformation',
  instructors: 'instructors',
  webinars: 'webinars',
  recommendedDegreesCarousel: 'recommendedDegreesCarousel',
  eoiBlock: 'eoiBlock',
} as const;

export type HubModuleValue = (typeof HUB_MODULES)[keyof typeof HUB_MODULES];

export const CATEGORY_HUB_MODULE_ORDER: Record<string, Array<HubModuleValue>> = {
  default: [
    HUB_MODULES.hubBanner,
    HUB_MODULES.callToAction,
    HUB_MODULES.industryRelevance,
    HUB_MODULES.browseDegrees,
    HUB_MODULES.industryRelevanceInformation,
    HUB_MODULES.valueProposition,
    HUB_MODULES.testimonials,
    HUB_MODULES.relatedArticles,
    HUB_MODULES.columnAndList,
    HUB_MODULES.categoryCards,
    HUB_MODULES.faqs,
  ],
};

export const DEFAULT_MODULE_ORDER: Array<HubModuleValue> = [
  HUB_MODULES.hubBanner,
  HUB_MODULES.recommendedDegreesCarousel,
  HUB_MODULES.programLevelCards,
  HUB_MODULES.relatedArticles,
  HUB_MODULES.faqs,
];

export const PREMIUM_HUB_MODULE_ORDER: Record<PremiumHubPageType, Record<string, Array<HubModuleValue>>> = {
  [HubPages.MainHubPage]: {
    default: [
      HUB_MODULES.degreesHubBanner,
      HUB_MODULES.browseDegrees,
      HUB_MODULES.programLevelCards,
      HUB_MODULES.categoryCards,
      HUB_MODULES.valueProposition,
      HUB_MODULES.testimonials,
      HUB_MODULES.instructors,
      HUB_MODULES.relatedArticles,
      HUB_MODULES.faqs,
    ],
  },
  [HubPages.DegreesCategoryHubPage]: {
    default: [
      HUB_MODULES.hubBanner,
      HUB_MODULES.browseDegrees,
      HUB_MODULES.industryRelevanceInformation,
      HUB_MODULES.programLevelCards,
      HUB_MODULES.testimonials,
      HUB_MODULES.instructors,
      HUB_MODULES.relatedArticles,
      HUB_MODULES.faqs,
    ],
  },
  [HubPages.ProductVariantHubPage]: {
    default: [
      HUB_MODULES.hubBanner,
      HUB_MODULES.browseDegrees,
      HUB_MODULES.categoryCards,
      HUB_MODULES.webinars,
      HUB_MODULES.valueProposition,
      HUB_MODULES.testimonials,
      HUB_MODULES.instructors,
      HUB_MODULES.relatedArticles,
      HUB_MODULES.faqs,
    ],
  },
  [HubPages.LandingPage]: {
    default: [
      HUB_MODULES.heroBanner,
      HUB_MODULES.recommendedDegreesCarousel,
      HUB_MODULES.industryRelevanceInformation,
      HUB_MODULES.valueProposition,
      HUB_MODULES.testimonials,
      HUB_MODULES.relatedArticles,
      HUB_MODULES.faqs,
    ],
  },
};

export const CATEGORY_TIERS = {
  one: 'TIER_1',
  two: 'TIER_2',
};

export const SLUG_PROFESSIONAL_CERTIFICATE = 'professional-certificates';

export const HERO_BANNER_CATEGORY_SLUGS = [
  'business',
  'computer-science',
  'data-science',
  'public-health',
  'management',
  'data-analytics',
  'social-sciences',
];

export const REGIONAL_CATEGORIES = ['Top European', 'Top Indian', 'Maestrías en línea de Latinoamérica'];

export const CONTENTFUL_HUB_PAGE_TYPES = {
  [HubPages.MainHubPage]: 'degrees_hub',
  [HubPages.DegreesCategoryHubPage]: 'degrees_category_hub',
  [HubPages.ProductVariantHubPage]: 'degrees_variant_hub',
  [HubPages.LandingPage]: 'landing_page',
};

export const LOCALIZED_PAGE_CONTENT_FIELDS = {
  contentType: 'programHubPageContent',
  fragmentFieldsName: 'HubPageContentFields',
  fragmentFieldsAboveTheFoldName: 'PremiumHubAboveTheFoldFields',
  fragmentFieldsBelowTheFoldName: 'PremiumHubBelowTheFoldFields',
};

export const LOCALIZED_VISUAL_CONSISTENCY_FIELDS = {
  contentType: 'visualConsistency',
  fragmentFieldsName: 'VisualConsistencyFields',
};

export const LOCALIZED_HERO_VALUE_PROP = {
  contentType: 'premiumHubHeroValueProp',
  fragmentFieldsName: 'HeroValuePropFields',
};
// Show only top three FAQs https://coursera.atlassian.net/browse/ACQU-1457
export const MAX_FAQS_TO_SHOW = 3;

export const DEGREE_HUB_APPLICATION_OPEN_OPTIONS = [
  { name: 'On Platform', id: 'onPlatform' },
  { name: 'Off Platform', id: 'offPlatform' },
];

export const PRODUCT_CARD_PARTNER_LOGO_PARAMS = {
  auto: 'format,compress',
  format: 'avif',
  fit: 'fill',
  width: 48,
  height: 48,
};

export default {
  EOI_HASH,
  APPLICATION_DATE_FORMAT,
  HUB_TYPE_TO_DOTS_IMAGE,
};
