import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRetracked } from 'js/lib/retracked';

import type { CourseItem, Product } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { setBadgeCount } from 'bundles/ai-course-coach/store/actions';
import type { GlobalState } from 'bundles/ai-course-coach/store/types';
import { useCoachTrackingContext } from 'bundles/ai-course-coach/utils/tracking';

import { OnDemandButton } from '../common';

type Props = {
  toggle: () => void;
  chatId: string;
};

function Launcher({ toggle, chatId }: Props) {
  const dispatch = useDispatch();
  const { showChat, badgeCount } = useSelector((state: GlobalState) => ({
    showChat: state.behavior.showChat,
    badgeCount: state.messages.badgeCount,
  }));
  const trackRetracked = useRetracked();
  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;
  const sharedEventingData = {
    product: {
      type: 'course',
      id: trackingContext?.courseId,
    } as Product,
    courseItem: {
      id: trackingContext?.itemId,
    } as CourseItem,
  };

  const buttonVisibilityTrackingRef: React.MutableRefObject<HTMLButtonElement | null> = useVisibilityTracker(
    'view_coach',
    {
      coachViewType: 'view_coach_launcher',
      coach: {
        mode: trackingContext?.mode,
      },
      ...(trackingContext?.courseId && { product: sharedEventingData.product }),
      ...(trackingContext?.itemId && { courseItem: sharedEventingData.courseItem }),
    }
  );

  const toggleChat = () => {
    trackRetracked({
      trackingData: {
        badge_count: badgeCount,
        timestamp: Date.now(),
      },
      trackingName: 'ai_course_coach_chat_toggle',
      action: `click_${showChat ? 'close' : 'open'}`,
    });

    // v3 tracking - tracks only chat opening, per current requirement
    if (!showChat) {
      track('interact_coach', {
        coachInteractionType: 'launch_coach',
        coach: {
          mode: trackingContext?.mode,
        },
        ...(trackingContext?.courseId && { product: sharedEventingData.product }),
        ...(trackingContext?.itemId && { courseItem: sharedEventingData.courseItem }),
      });
    }

    toggle();
    if (!showChat) {
      dispatch(setBadgeCount(0));
    }
  };

  return (
    <OnDemandButton
      ref={buttonVisibilityTrackingRef}
      aria-expanded={showChat}
      onClick={toggleChat}
      aria-controls={chatId}
    />
  );
}

export default Launcher;
