/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Avatar } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/page';

type Props = {
  user: {
    full_name: string;
    photo_120: string;
  };
  showCaret?: boolean;
  isCaretUp?: boolean;
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,
  chevron: css`
    margin-left: var(--cds-spacing-50);
  `,
};

const UserPortraitV2 = ({ user, showCaret, isCaretUp }: Props) => {
  const { full_name: fullName, photo_120: photoUrl } = user;
  const firstName = fullName && fullName.split(' ')[0];

  let chevron;
  if (showCaret && isCaretUp) {
    chevron = <ChevronUpIcon data-testid="svg-chevron-up" size="small" color="default" css={styles.chevron} />;
  } else {
    chevron = <ChevronDownIcon data-testid="svg-chevron-down" size="small" color="default" css={styles.chevron} />;
  }

  return (
    <div className="pii-hide" css={styles.wrapper}>
      <Avatar
        variant="user"
        data-testid="user-avatar"
        initial={firstName?.[0]}
        imgProps={{ src: photoUrl, alt: _t('Profile image for #{learnerName}', { learnerName: fullName }) }}
      />
      {chevron}
    </div>
  );
};

export default UserPortraitV2;
