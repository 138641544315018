import * as React from 'react';

import initBem from 'js/lib/bem';

import _t from 'i18n!nls/page';

import 'css!bundles/page-footer/components/seo-sub-footer/__styles__/CopyrightV2';

const bem = initBem('CopyrightV2');

export default function CopyrightV2() {
  const currentYear = new Date().getFullYear();

  return (
    <div className={bem('', '', 'lohp-rebrand')}>
      <span className={bem('text')}>{_t('© #{currentYear} Coursera Inc. All rights reserved.', { currentYear })}</span>
    </div>
  );
}
