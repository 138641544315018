import { css } from '@emotion/react';

import type { SpacingValue } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export type AccordionGroupSpacing = Extract<
  SpacingValue,
  8 | 12 | 16 | 24 | 32
>;

export const classes = generateEmotionClasses('AccordionGroup', [
  'itemSpacing',
]);

const spacingMap: Record<AccordionGroupSpacing, string> = {
  8: 'var(--cds-spacing-100)',
  12: 'var(--cds-spacing-150)',
  16: 'var(--cds-spacing-200)',
  24: 'var(--cds-spacing-300)',
  32: 'var(--cds-spacing-400)',
};

export const getAccordionGroupCss = ({
  spacing,
}: {
  spacing: AccordionGroupSpacing;
}) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .${classes.itemSpacing} {
      margin-top: ${spacingMap[spacing]};
    }
  `;
};
