import * as React from 'react';

import { useQuery } from '@apollo/client';
import { flow } from 'lodash';

import user from 'js/lib/user';

import SeoPathConfigurationQuery from 'bundles/seo/api/SeoPathConfigurationQuery.graphql';
import type { SeoPathConfigurationQueryQuery as Response } from 'bundles/seo/api/__generated__/SeoPathConfigurationQuery';
import { SeoPageDataContextProvider } from 'bundles/seo/contexts/SeoPageDataContext';

type Props = {
  path: string;
  shouldSkip?: boolean;
  shouldNotSkipIfAuthenticated?: boolean;
  children: React.ReactElement | null;
};
const ensureProtocol = (url: string): string | null => {
  if (!url) return null;
  return !url.startsWith('http://') && !url.startsWith('https://') ? 'https://' + url : url;
};

// Replace 'mock.dev-' with 'www.' in the hostname
const replaceMockDevHostname = (url: URL): URL => {
  const parsedUrl = new URL(url.toString());
  if (parsedUrl.hostname.includes('mock.dev-')) {
    parsedUrl.hostname = parsedUrl.hostname.replace('mock.dev-', 'www.');
  }
  return parsedUrl;
};

// Remove all 'renderMode' query parameters
const removeRenderModeParams = (url: URL): URL => {
  const parsedUrl = new URL(url.toString());
  parsedUrl.searchParams.forEach((value, key) => {
    if (key === 'renderMode') {
      parsedUrl.searchParams.delete(key);
    }
  });
  return parsedUrl;
};

// Format the final URL string without the protocol
const formatURL = (url: URL): string => url.hostname + url.pathname.replace(/\/$/, '') + url.search;

// get the path without renderMode so we can use renderMode=skipServer for debugging
// also remove mock.dev- from the hostname so local dev mirrors production
const removeMockDevAndRenderMode = flow([
  (url) => new URL(url),
  replaceMockDevHostname,
  removeRenderModeParams,
  formatURL,
]);
export const getPathWithoutRenderModeAndMockDev = (url: string): string => {
  const urlWithProtocol = ensureProtocol(url);
  if (!urlWithProtocol) return url;
  return removeMockDevAndRenderMode(urlWithProtocol);
};

export const getRootPathWithQueryParam = (url: string): string => {
  // Keep only the 'query' parameter
  const urlWithProtocol = ensureProtocol(url);
  if (!urlWithProtocol) return url;
  const parsedUrl = new URL(urlWithProtocol);
  const rootUrl = new URL(parsedUrl.toString());
  rootUrl.search = ''; // Remove all search parameters first
  if (parsedUrl.searchParams.has('query')) {
    rootUrl.searchParams.set('query', parsedUrl.searchParams.get('query')!);
  }

  return formatURL(rootUrl);
};

const SeoPathConfigurationQueryWrapper: React.FC<Props> = ({
  path,
  children,
  shouldSkip = false,
  shouldNotSkipIfAuthenticated = false,
}) => {
  const processedPath = getPathWithoutRenderModeAndMockDev(path);
  const rootPathWithQuery = getRootPathWithQueryParam(processedPath);
  const skipQuery = (shouldNotSkipIfAuthenticated ? false : user.isAuthenticatedUser()) || shouldSkip;
  const querySettings = {
    context: { clientName: 'gatewayGql' },
    skip: skipQuery,
    ssr: true,
    errorPolicy: 'all' as const,
  };

  const { data } = useQuery<Response>(SeoPathConfigurationQuery, {
    variables: { path: processedPath },
    ...querySettings,
  });

  // also get rootPathWithQuery in case no data found for the current path
  // this fixes the issue where the title and description are missing after applying filters
  const { data: rootData } = useQuery<Response>(SeoPathConfigurationQuery, {
    variables: { path: rootPathWithQuery },
    ...querySettings,
    skip: processedPath === rootPathWithQuery || skipQuery,
  });

  const seoPageData =
    data?.Seo?.seoPathConfigurationQuery?.pageData || rootData?.Seo?.seoPathConfigurationQuery?.pageData;
  const eqpData = data?.Seo?.seoPathConfigurationQuery?.eqpData || rootData?.Seo?.seoPathConfigurationQuery?.eqpData;

  const subfooter = seoPageData?.subfooter?.categories;
  const globalFooterLinksForCategory = seoPageData?.globalFooter?.categories?.reduce((accumulator, currentCategory) => {
    return { ...accumulator, [currentCategory.header]: currentCategory.links };
  }, {});
  const metaTags = seoPageData?.metaTags ?? undefined;
  const megaMenu = seoPageData?.megaMenu ?? undefined;

  return (
    <SeoPageDataContextProvider
      subfooter={subfooter}
      globalFooterLinksForCategory={globalFooterLinksForCategory}
      metaTags={metaTags}
      megaMenu={megaMenu}
      eqpData={eqpData}
    >
      {children}
    </SeoPageDataContextProvider>
  );
};

export default SeoPathConfigurationQueryWrapper;
