import gql from 'graphql-tag';

import type { QuestionType, ValidationInputType } from 'bundles/survey-form/constants/SurveyFormConstants';

type QuestionContent = {
  options: any[];
  validations: Record<ValidationInputType, {}>[];
  prompt: string;
};

type Question = {
  isRequired: boolean;
  content: Record<QuestionType, QuestionContent>;
  id: string;
  displayHalfLine: boolean;
};

type Definition = {
  questions: Question[];
  nextMessage: string;
  title: string;
};

type Section = {
  typeName: string;
  definition: Definition;
};

export type PreviewStartSectionResponse = {
  QualificationSurveySectionResponse: {
    section: Section;
    sectionId: string;
    __typename: 'QualificationSurveySectionResponsesV2';
  };
  errors: unknown;
};

export type PreviewStartSectionVariables = {
  productId: string;
  input: Record<string, unknown>;
};

export const PREVIEW_START_SECTION = gql`
  mutation PreviewStartSection($productId: String!, $input: DataMap) {
    QualificationSurveySectionResponse(productId: $productId, input: $input)
      @rest(
        type: "QualificationSurveySectionResponsesV2"
        path: "qualificationSurveySectionResponses.v2?action=previewStartSection&productId={args.productId}"
        method: "POST"
      ) {
      sectionId
      section {
        typeName
        definition {
          title
          questions
          nextMessage
        }
      }
    }
  }
`;
