/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import useScrollInfo from 'bundles/page-header/components/hooks/useScrollInfo';

const DELTA = 50;
const NAVBAR_HEIGHT = 60;

const styles = {
  SmartScrollWrapper: css`
    min-width: 360;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    transition: top 0.2s ease-in-out;
    z-index: 4000;
  `,
  container: css`
    width: 100%;
  `,
};

type Props = {
  // Override the inline-styles of the root element
  style?: Record<string, unknown>;

  children?: React.ReactNode;
  // The height of the container, may be different if the window resizes
  // The parent container needs to pass the correct height
  containerHeight?: number;

  // Make the wrapper always not visible
  alwaysHide?: boolean;

  // Customize zIndex when showing the content inside, defualt to zIndex.md,
  zIndex?: number;

  // Disable the scrolling effect
  disableScrollEffect?: boolean;

  // Delta scroll distance
  delta?: number;

  isAutoScroll?: boolean;

  className?: string;
};

function getStyles({ containerHeight, zIndexProp }: { containerHeight: number; zIndexProp?: number }) {
  return {
    SmartScrollWrapper: {
      // height: containerHeight,
      zIndex: zIndexProp || 3000,
    },
    hideContainer: {
      top: -containerHeight,
    },
  };
}

/**
 *  A wrapper component to create medium nav style container
 * 1. When user scroll down, the container will go up as usual
 * 2. As soon as user starts to scroll up, we'll show the container
 * User can config what's the delta scroll distance they want to trigger the update
 * and the delta (takes priority) can be prop or function argument
 */
const SmartScrollWrapper = ({
  children,
  containerHeight = NAVBAR_HEIGHT,
  zIndex: zIndexProp,
  alwaysHide,
  style = {},
  delta = DELTA,
  className,
}: Props) => {
  const { isScrollingDown, lastScrollPosition } = useScrollInfo({ delta });
  const hideContainer = (lastScrollPosition >= containerHeight && isScrollingDown) || alwaysHide;
  const dynamicStyles = getStyles({ containerHeight, zIndexProp });
  const hideStyle = (hideContainer && dynamicStyles.hideContainer) || {};

  // mergedStyles is combination of component height and top offset
  const mergedStyles = {
    ...dynamicStyles.SmartScrollWrapper,
    ...hideStyle,
    ...style,
  };

  return (
    <div
      css={styles.SmartScrollWrapper}
      // "mui-fixed" class is to fix the shifting of elements that occurs when a modal is opened (i.e., when scrolling is disabled)
      className={`${className} ${
        hideContainer ? 'hide-smart-scroll-container' : 'show-smart-scroll-container'
      } mui-fixed`}
      style={mergedStyles}
    >
      {children}
    </div>
  );
};

export default SmartScrollWrapper;
