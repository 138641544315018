/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment } from 'react';
import type { MutableRefObject } from 'react';

import { processImage } from 'js/lib/imgix';
import { useRetracked } from 'js/lib/retracked';

import { CardMetadata, ProductCard, Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { ClockOutlineIcon } from '@coursera/cds-icons';
import type { ProductType, SectionName } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { buildDegreeUrlAbsolute } from 'bundles/common/utils/urlUtils';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { UnifiedProductCardProps } from 'bundles/premium-hub/types';
import { removeProductIdPrefix } from 'bundles/premium-hub/utils/dataTransformationUtils';
import { filterNullOrUndefined } from 'bundles/premium-hub/utils/index';

import _t from 'i18n!nls/premium-hub';

type Props = {
  degree: UnifiedProductCardProps;
  entityIndex: number;
  displayDeadline?: boolean;
  sectionName: SectionName;
};

const styles = {
  productCardContainer: (theme: Theme) => ({
    width: '100%',
    height: 'inherit',
    [`${breakpoints.up('sm')}`]: {
      '& .gridVariant': {
        display: 'block',
      },
      '& .listVariant': {
        display: 'none',
      },
    },
    [`${breakpoints.down('xs')}`]: {
      '& .gridVariant': {
        display: 'none',
      },
      '& .listVariant': {
        display: 'block',
      },
    },
  }),
};

const DegreeProductCard: React.FunctionComponent<Props> = ({
  degree,
  entityIndex: degreeRankBaseZero,
  displayDeadline = false,
  sectionName,
}) => {
  const theme = useTheme();
  const trackComponent = useRetracked();
  const trackV3 = useTracker();

  const { id, title, slug, bannerImage, partnerInfo, programSummary, programDeadlineText, programTimeFrame, language } =
    degree;

  const degreeCardTrackingDataV3 = {
    productCard: {
      index: degreeRankBaseZero,
    },
    product: { id: removeProductIdPrefix(id ?? ''), slug: slug ?? '', type: 'degree' as ProductType },
    pageSection: { sectionName },
  };
  const degreeCardRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    degreeCardTrackingDataV3
  );

  if (!id || !title || !slug) {
    return null;
  }

  const degreeLink = buildDegreeUrlAbsolute(slug);
  const deadlineText = programDeadlineText || _t('No Active Deadline');
  const footerStrings = [partnerInfo?.country, programTimeFrame, language].filter(filterNullOrUndefined);
  const partnerImgixParams = {
    auto: 'format,compress',
    fit: 'fill',
    width: 24,
    height: 24,
  };

  const bodyComponent = (
    <Fragment>
      {programSummary && (
        <Typography2 component="p" data-test="programSummary" variant="bodySecondary" color="supportText">
          {programSummary}
        </Typography2>
      )}
    </Fragment>
  );

  const footerComponent = (
    <Fragment>
      {displayDeadline && (
        <Typography2 component="p" variant="bodySecondary" css={{ display: 'flex' }}>
          <span css={{ marginRight: '10px' }}>
            <ClockOutlineIcon />
          </span>
          {deadlineText}
        </Typography2>
      )}
      {footerStrings.length && <CardMetadata data-testid="DegreeFooterStrings" metadata={footerStrings} />}
    </Fragment>
  );

  const trackHoverProductCard = () => {
    trackComponent({
      trackingData: { degreeId: id, degreeRankBaseZero },
      trackingName: 'degree_product_card_link',
      action: 'hover',
    });
  };

  return (
    <div
      aria-label={_t('#{programSummary}, #{deadlineText},', { programSummary, deadlineText })}
      data-testid="DegreeProductCard"
      css={styles.productCardContainer(theme)}
      ref={degreeCardRef}
    >
      <div
        className="gridVariant"
        css={css`
          height: 100%;
          width: 100%;
        `}
        onMouseEnter={trackHoverProductCard}
        onFocus={trackHoverProductCard}
      >
        <ProductCard
          variant="grid"
          productType="Degree"
          previewImageSrc={processImage(bannerImage, {
            auto: 'format,compress',
            fit: 'fill',
            width: 430,
            height: 242,
          })}
          partners={[
            {
              name: partnerInfo?.partnerName || '',
              logoUrl: processImage(partnerInfo?.partnerLogo || '', partnerImgixParams),
            },
          ]}
          title={{
            name: title,
            linkProps: {
              href: degreeLink,
              component: TrackedLink2,
              onClick: () => trackV3('click_product_card', degreeCardTrackingDataV3),
            },
            customLinkProps: {
              trackingName: 'degree_product_card_link_gridview',
              data: { degreeId: id, degreeRankBaseZero },
            },
          }}
          body={bodyComponent}
          footer={footerComponent}
        />
      </div>
      <div className="listVariant">
        <ProductCard
          variant="list"
          productType="Degree"
          previewImageSrc={processImage(bannerImage, {
            auto: 'format,compress',
            fit: 'fill',
            width: 64,
            height: 64,
          })}
          partners={[
            {
              name: partnerInfo?.partnerName || '',
              logoUrl: processImage(partnerInfo?.partnerLogo || '', partnerImgixParams),
            },
          ]}
          title={{
            name: title,
            linkProps: {
              href: degreeLink,
              component: TrackedLink2,
              onClick: () => trackV3('click_product_card', degreeCardTrackingDataV3),
            },
            customLinkProps: {
              trackingName: 'degree_product_card_link_listview',
              data: { degreeId: id, degreeRankBaseZero },
            },
          }}
          body={bodyComponent}
          footer={footerComponent}
        />
      </div>
    </div>
  );
};

export default DegreeProductCard;
