import _t from 'i18n!nls/growth-upsell';

const getDefaultSurveyTitle = () => _t('Thanks for visiting!');
const getDefaultSurveyDescription = () =>
  _t('Do you have 5 minutes to answer a few survey questions about how you heard about Coursera?');
const getDefaultSurveyYesButtonText = () => _t('Yes, I want to help');
const getDefaultSurveyNoButtonText = () => _t('No thanks');

export {
  getDefaultSurveyTitle,
  getDefaultSurveyDescription,
  getDefaultSurveyYesButtonText,
  getDefaultSurveyNoButtonText,
};
