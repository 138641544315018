// Filters that will be used on all degree hub pages
export const DEFAULT_SEARCH_FILTERS = [
  'degreeLevel',
  'topic',
  'degreePathwayType',
  'partners',
  'degreeMonthsTillNextAdmission',
  'partnerCountry',
  'degreeHasScholarship',
];

// Filters that will be used on landing pages
export const LANDING_PAGE_SEARCH_FILTERS = ['degreeLevel', 'topic', 'partners', 'partnerCountry'];

/* 
  We currently load all results from Algolia, and handle pagination on the client side.
  This is so that we can re-order the results based on the DRE ordered items from PremiumProductCollection.
*/
export const RESULTS_PER_PAGE = 500;

/*
  Preset/hidden filter configuration
  These are filters that are always applied to the search query because they're required by context of the page,
  for example, on the Computer Science degrees page:
    - The preset filters should be 'productTypeDescription:Degrees' and 'topic:Computer Science'
    - The hidden filters should be 'productTypeDescription' and 'topic' so they can't be updated by the user
*/
export const ALL_PAGES_HIDDEN_FILTERS = ['productTypeDescription'];
export const ALL_PAGES_PRESET_FILTER_VALUES = [
  'productTypeDescription:Degrees',
  'productTypeDescription:Postgraduate Diploma',
];

export const PROGRAM_LEVEL_HIDDEN_FILTERS = ['degreeLevel'];
export const PROGRAM_LEVEL_SLUG_TO_FILTER_VALUES = {
  bachelors: ['degreeLevel:bachelor'],
  masters: ['degreeLevel:master'],
  'postgraduate-studies': ['degreeLevel:postgraduate'],
};

// Domain category page filter configuration
export const DOMAIN_CATEGORY_HIDDEN_FILTERS = ['topic'];
export const TOP_REGIONAL_HIDDEN_FILTERS = ['partnerCountry'];
export const TOP_REGIONAL_SLUGS = ['top-european', 'top-indian', 'maestria-en-linea-latinoamerica'];
export const DOMAIN_CATEGORY_SLUG_TO_FILTER_VALUES_MAP = {
  business: ['topic:Business'],
  management: ['topic:Business'],
  'computer-science': ['topic:Computer Science'],
  'data-science': ['topic:Data Science'],
  'data-analytics': ['topic:Data Analytics', 'topic:Data Science'],
  'public-health': ['topic:Health'],
  'social-sciences': ['topic:Social Sciences'],
  // TODO: Configure these filters when we have the partner country filter
  'top-european': ['partnerCountry:GB', 'partnerCountry:FR'],
  'top-indian': ['partnerCountry:IN'],
  'maestria-en-linea-latinoamerica': [
    'partnerCountry:AR',
    'partnerCountry:CL',
    'partnerCountry:CO',
    'partnerCountry:PE',
  ],
};
