/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useLocation } from 'js/lib/useRouter';

import type { PageNavLinkElement } from 'bundles/page-header/components/page-navigation/PageNavLink';

type Props = {
  children: PageNavLinkElement[];
};

const styles = {
  links: css`
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: var(--cds-spacing-400);
    flex-wrap: nowrap;
    overflow-x: auto;

    /* hide scrollbar for Firefox */
    scrollbar-width: none;

    /* hide scrollbar for Chrome, Safari and Edge */
    ::-webkit-scrollbar {
      display: none;
    }
  `,
};

const PageNavigation = ({ children }: Props) => {
  const location = useLocation();

  return (
    <nav>
      <ul css={styles.links}>
        {children.filter(Boolean).map((child, index) => {
          // if isCurrent is not passed in, use location.pathname to determine if the link is current
          let href = child.props.href;
          href = href?.indexOf('?') !== -1 ? child.props.href?.substring(0, child.props.href?.indexOf('?')) : href;

          let isCurrent = child.props.isCurrent;
          if (isCurrent === undefined && href) {
            isCurrent = href === '/' ? location?.pathname === '/' : location?.pathname?.startsWith(href);
          }

          // eslint-disable-next-line react/no-array-index-key
          return <li key={index}>{React.cloneElement(child, { isCurrent })}</li>;
        })}
      </ul>
    </nav>
  );
};

export default PageNavigation;
