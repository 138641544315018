import { css } from '@emotion/react';

import { classes as buttonBaseClasses } from '@core/ButtonBase/getButtonBaseCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('multiSelectController', [
  'active',
  'label',
  'icon',
  'itemsNumber',
  'outlined',
  'silent',
]);

export type VisualProps = {
  width?: number;
};

const setFixedWidth = (width: VisualProps['width']) =>
  width
    ? css`
        max-width: unset;
        width: ${width}px;
      `
    : undefined;

const styles = (props: VisualProps) => css`
  max-width: 400px;
  padding: var(--cds-spacing-150);
  color: var(--cds-color-neutral-primary-weak);
  background: var(--cds-color-neutral-background-primary);
  justify-content: flex-start;

  ${setFixedWidth(props.width)}

  .${classes.label} {
    text-align: left;
  }

  .${classes.itemsNumber} {
    min-width: 32px;
    margin-left: var(--cds-spacing-50);
    margin-right: var(--cds-spacing-150);
  }

  .${classes.icon} {
    display: inline-flex;
    margin-right: 0;
    margin-left: auto;
  }

  &:hover,
  &.${buttonBaseClasses.focusVisible}, &.${classes.active} {
    color: var(--cds-color-neutral-primary);
  }

  &.${classes.outlined} {
    box-shadow: inset 0 0 0 1px var(--cds-color-neutral-stroke-primary);

    &:hover,
    &.${buttonBaseClasses.focusVisible} {
      background: var(--cds-color-interactive-background-primary-hover-weak);
      box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-hover);
    }

    &.${classes.active} {
      box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-hover);
    }
  }

  &.${classes.silent} {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:hover:not(.${buttonBaseClasses.focusVisible}) {
      background: var(--cds-color-interactive-background-primary-hover-weak);

      &::before {
        border-bottom: 2px solid var(--cds-color-interactive-primary-hover);
      }
    }

    &.${classes.active} {
      &::before {
        border-bottom: 2px solid var(--cds-color-interactive-primary-hover);
      }
    }
  }
`;

export default styles;
