import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props as TabProps } from './Tab';

export const classes = generateEmotionClasses('tab', [
  'focusVisible',
  'wrapper',
  'selected',
]);

const variants = {
  primary: css`
    margin-right: var(--cds-spacing-400);
  `,

  section: css`
    margin-right: var(--cds-spacing-300);
  `,
};

export default (props: TabProps) => css`
  opacity: 1;
  min-height: 48px;
  min-width: 44px;
  max-width: none;
  overflow: visible;
  text-align: left;
  text-transform: none;
  align-self: flex-end;
  padding: var(--cds-spacing-150) var(--cds-spacing-100);
  margin-bottom: var(--cds-spacing-50);
  color: var(--cds-color-neutral-primary);

  ${typography.actionPrimary};
  ${variants[props.variant as NonNullable<TabProps['variant']>]};

  &:focus {
    outline: none;
  }

  &:hover {
    border-radius: var(--cds-border-radius-50);
    text-decoration: none;
    color: var(--cds-color-interactive-primary-hover);
    background-color: var(
      --cds-color-interactive-background-primary-hover-weak
    );
  }

  &.${classes.selected} {
    color: var(--cds-color-interactive-primary);
  }

  &.${classes.focusVisible} {
    flip: false;
    border: none;
    border-radius: var(--cds-border-radius-50);
    color: var(--cds-color-interactive-primary-hover);
    background-color: var(
      --cds-color-interactive-background-primary-hover-weak
    );
    box-shadow: inset var(--cds-color-interactive-stroke-primary-focus-invert) 0
        0 0 1px,
      inset 0 0 0 2px var(--cds-color-interactive-stroke-primary-focus);
  }

  .${classes.wrapper} {
    flex-direction: row;

    & > *:first-of-type {
      min-width: 20px;
      margin: 0 var(--cds-spacing-100) 0 0;
    }
  }

  ${breakpoints.down('xs')} {
    max-width: 160px;
  }

  @media (forced-colors: active) {
    &.${classes.focusVisible} {
      text-decoration: underline;
    }
  }
`;
