import requestCountry from 'js/lib/requestCountry';

import growthAcquisitionExperiments from 'bundles/epic/clients/GrowthAcquisition';

declare const COURSERA_APP_NAME: string;

const INELIGIBLE_REGIONS_FOR_VISUAL_CHANGES_TO_AUTH_MODAL = ['US', 'CA', 'MX'];

export const isArkoseBotManagerForSignupEnabled = () =>
  growthAcquisitionExperiments.preview('enableArkoseBotManagerForSignup');

export const isInPasswordlessSignupVariant = () => growthAcquisitionExperiments.preview('passwordlessSignup');

export const generateImpressionForPasswordlessSignup = () => growthAcquisitionExperiments.get('passwordlessSignup');

export const isEligibleForVisualChangesToAuthModal = () => {
  return (
    !INELIGIBLE_REGIONS_FOR_VISUAL_CHANGES_TO_AUTH_MODAL.includes(requestCountry.get()) &&
    COURSERA_APP_NAME !== 'authentication'
  );
};

export const generateImpressionForVisualChangesToAuthModal = () =>
  isEligibleForVisualChangesToAuthModal() && growthAcquisitionExperiments.get('visualChangesToAuthModal');

export const getVisualChangesToAuthModalVariant = () =>
  growthAcquisitionExperiments.preview('visualChangesToAuthModal');

export const shouldFlattenSocialSSOButtons = () =>
  isEligibleForVisualChangesToAuthModal() &&
  (getVisualChangesToAuthModalVariant() === 'A' || getVisualChangesToAuthModalVariant() === 'B');

export const shouldShowSocialSSOButtonsAboveEmailSignup = () =>
  isEligibleForVisualChangesToAuthModal() && getVisualChangesToAuthModalVariant() === 'B';

export const shouldShowSocialSSOButtonsBelowEmailSignup = () =>
  !isEligibleForVisualChangesToAuthModal() ||
  (getVisualChangesToAuthModalVariant() !== 'C' && !shouldShowSocialSSOButtonsAboveEmailSignup());
