import Retracked from 'js/app/retracked';

export type EventData = {
  namespace: {
    app: string;
    page: string;
  };
};

export const trackAuthAttempts = (_eventData: EventData, authType = 'auth') => {
  if (_eventData && _eventData.namespace) {
    Retracked.trackComponent(_eventData, {}, `${authType}_attempt`, 'submit');
  }
};
