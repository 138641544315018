/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';
import type { MutableRefObject } from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Pagination, breakpoints } from '@coursera/cds-core';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { scrollToAnchor } from 'bundles/premium-hub/components/degrees/DegreeHubScrollAnchor';
import TopProductsList from 'bundles/premium-hub/components/shared/TopProductsList';
import { ProductType } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { PremiumProductWithMetadata, ProductCardProps } from 'bundles/premium-hub/types';
import { convertPremiumProductWithMetadataToPremiumHubProductCardProps } from 'bundles/premium-hub/utils/dataTransformationUtils';

type Props = {
  degrees: PremiumProductWithMetadata[];
};

const styles = {
  cdsPaginationContainerStyles: css`
    margin-bottom: var(--cds-spacing-300);
    margin-top: var(--cds-spacing-300);
    ${breakpoints.up('sm')} {
      & .desktopPagination {
        display: flex;
        justify-content: center;
      }

      & .mobilePagination {
        display: none;
      }
    }
    ${breakpoints.down('xs')} {
      & .desktopPagination {
        display: none;
      }

      & .mobilePagination {
        display: block;
      }
    }
  `,
};

const sectionName = 'top_degrees_list';

const TopDegreesList = ({ degrees }: Props) => {
  const PRODUCT_CARD_DEGREES_COUNT = 8;
  const FILTERED_DEGREES_PER_PAGE_COUNT = PRODUCT_CARD_DEGREES_COUNT;
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const topDegreesListRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName,
      index: sectionIndex,
    },
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const trackComponent = useRetracked();

  useEffect(() => {
    setCurrentPage(1); // reset current page when degrees data change
  }, [degrees]);

  if (!degrees || !degrees.length) {
    return null;
  }

  const totalResults = degrees?.length || 0;
  const pageCount = Math.ceil(totalResults / FILTERED_DEGREES_PER_PAGE_COUNT);
  const currentIndex = currentPage - 1;
  const startIndex = currentIndex * FILTERED_DEGREES_PER_PAGE_COUNT;
  const endIndex = (currentIndex + 1) * FILTERED_DEGREES_PER_PAGE_COUNT;

  const degreesOnCurrentPage: ProductCardProps[] = degrees
    .map(convertPremiumProductWithMetadataToPremiumHubProductCardProps)
    .slice(startIndex, endIndex);

  const onDesktopCdsPaginationChange = (_event: React.ChangeEvent<unknown>, pageNum: number) => {
    trackComponent({
      trackingData: { newPage: pageNum },
      trackingName: 'pagination_tool',
      action: 'click',
    });
    setCurrentPage(pageNum);
    scrollToAnchor({
      offset: -90,
      smooth: true,
    });
  };

  const onMobileCdsPaginationChange = (_event: React.ChangeEvent<unknown>, pageNum: number) => {
    trackComponent({
      trackingData: { newPage: pageNum },
      trackingName: 'pagination_tool',
      action: 'click',
    });
    setCurrentPage(pageNum);
    scrollToAnchor({
      offset: -30,
      smooth: true,
    });
  };

  return (
    <div ref={topDegreesListRef}>
      <TrackedDiv
        data-testid="top-degrees-list"
        trackingName="top_degrees_list"
        withVisibilityTracking={true}
        atMostOnce={true}
        trackClicks={false}
      >
        <TopProductsList
          productsList={degreesOnCurrentPage}
          productType={ProductType.Degrees}
          data-testid="topdegreeslist-container"
          sectionName={sectionName}
        />
        {pageCount > 1 && (
          <div css={styles.cdsPaginationContainerStyles}>
            <div className="desktopPagination">
              <Pagination
                pageSize={FILTERED_DEGREES_PER_PAGE_COUNT}
                total={totalResults}
                page={currentPage}
                hidePageSizeInput
                onPageChange={onDesktopCdsPaginationChange}
              />
            </div>
            <div className="mobilePagination">
              <Pagination
                pageSize={FILTERED_DEGREES_PER_PAGE_COUNT}
                total={totalResults}
                page={currentPage}
                hidePageSizeInput
                onPageChange={onMobileCdsPaginationChange}
              />
            </div>
          </div>
        )}
      </TrackedDiv>
    </div>
  );
};

export default React.memo(TopDegreesList);
