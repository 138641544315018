/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

type Props = {
  content: string;
  component?: 'h3' | 'h4';
};

const styles = {
  title: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
};

export const NavigationTitle = (props: Props): React.ReactElement => {
  const { content, component = 'h3' } = props;

  return (
    <Typography2 variant="subtitleMedium" component={component} css={styles.title}>
      {content}
    </Typography2>
  );
};

export default NavigationTitle;
