import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { InputProps } from './Input';

export const classes = generateEmotionClasses('input', [
  'focused',
  'notchedOutline',
  'root',
  'input',
  'multiline',
  'adornedStart',
  'adornedEnd',
  'valid',
  'invalid',
  'error',
  'onDark',
  'onLight',
  'placeholder',
  'readOnly',
]);

export const getOutlineStyles = css`
  position: relative;
  outline: 1px solid transparent;
  outline-offset: 2px;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: var(--cds-border-radius-50);
    z-index: 0;
    user-select: none;
    transform: scale(1, 1);
    box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
      0 0 0 2px var(--cds-color-interactive-background-primary-hover-weak);
  }
`;

export const getFocusedStyle = (props: InputProps) => {
  return !props.hideOutline ? getOutlineStyles : undefined;
};

/**
 * Return maxWidth rule value as string
 */
export const calculateMaxWidth = (maxLength?: string | number): string => {
  // as per the design spec, there should be space for an additional W
  const numberOfCharacters = Math.ceil(Number(maxLength)) + 1;

  // TODO: orest - remove this once https://coursera.atlassian.net/browse/CDS-578 is resolved
  // 4.624 (px) is the difference in width between '0' and 'W', which is defined by the design spec as the <purpose>(example: estimated single character space)
  const adjustment = (numberOfCharacters * 4.624) / 16;

  return maxLength
    ? `calc(${numberOfCharacters}ch + ${adjustment}rem)`
    : '100%';
};

export const getRootStyles = (props: InputProps) => css`
  font-family: var(---cds-font-family-body);
  background: var(--cds-color-neutral-background-primary);
  align-self: ${props.inputProps?.maxLength && !props.fullWidth
    ? 'flex-start'
    : 'stretch'};

  &:focus {
    outline: none;
  }

  &:hover {
    background: var(--cds-color-interactive-background-primary-hover-weak);
    color: var(--cds-color-neutral-primary);

    .${classes.input} {
      color: inherit;

      &::placeholder,
      .${classes.placeholder} {
        color: inherit;
      }

      &:focus {
        &::placeholder {
          color: var(--cds-color-neutral-primary-weak);
        }
      }
    }

    .${classes.notchedOutline} {
      border-color: var(--cds-color-interactive-primary-hover);
    }
  }

  &.${classes.focused} {
    .${classes.notchedOutline} {
      border-color: var(--cds-color-neutral-stroke-primary);
      border-width: 1px;
    }

    &:hover {
      background: var(--cds-color-neutral-background-primary);

      .${classes.notchedOutline} {
        border-color: var(--cds-color-neutral-stroke-primary);
      }
    }
  }
`;

export const getInputStyles = (props: InputProps) => css`
  position: relative;
  padding: var(--cds-spacing-150);
  color: var(--cds-color-neutral-primary);
  height: var(--cds-spacing-300);
  width: ${props.fullWidth
    ? '100%'
    : calculateMaxWidth(props.inputProps?.maxLength)};

  ${typography.bodyPrimary}

  &::placeholder,
  .${classes.placeholder} {
    color: var(--cds-color-neutral-primary-weak);
    opacity: 1;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }

  &:focus {
    color: var(--cds-color-neutral-primary);
    background: unset;

    &::placeholder {
      color: var(--cds-color-neutral-primary-weak);
    }

    .${classes.placeholder} {
      color: inherit;
    }
  }
`;

const getInputCss = (props: InputProps) => css`
  ${getRootStyles(props)}

  .${classes.input} {
    ${getInputStyles(props)}
  }

  &.${classes.multiline} {
    padding: 0;

    .${classes.input} {
      height: auto;
    }
  }

  .${classes.notchedOutline} {
    border-color: var(--cds-color-neutral-stroke-primary);
    border-width: 1px;
  }

  &.${classes.adornedStart} {
    padding-left: 0;
  }

  &.${classes.adornedEnd} {
    padding-right: 0;

    &.${classes.multiline} .${classes.input} {
      padding-right: 42px;
    }
  }

  &.${classes.focused} {
    ${getFocusedStyle(props)}
  }

  &.${classes.readOnly} {
    .${classes.notchedOutline} {
      border-color: var(--cds-color-neutral-disabled-strong);
    }

    &:hover {
      background: var(--cds-color-neutral-background-primary-weak);

      .${classes.notchedOutline} {
        border-color: var(--cds-color-neutral-stroke-primary);
      }
    }

    &.${classes.focused} {
      background: var(--cds-color-neutral-background-primary-weak);

      .${classes.notchedOutline} {
        border-color: var(--cds-color-neutral-stroke-primary);
      }

      &:hover {
        .${classes.notchedOutline} {
          border-color: var(--cds-color-neutral-stroke-primary);
        }
      }
    }
  }

  &.${classes.onDark} {
    --border-color-on-dark: transparent;

    .${classes.notchedOutline} {
      border-color: var(--border-color-on-dark);
    }

    &:hover {
      .${classes.notchedOutline} {
        border-color: var(--border-color-on-dark);
      }
    }

    &.${classes.focused} {
      .${classes.notchedOutline} {
        border-color: var(--border-color-on-dark);
      }

      &:hover {
        .${classes.notchedOutline} {
          border-color: var(--border-color-on-dark);
        }
      }
    }

    &.${classes.valid} {
      --border-color-on-dark: var(--cds-color-feedback-success-invert-weak);

      &:hover {
        background: var(--cds-color-feedback-success-invert-weak);
      }

      &.${classes.focused} {
        background: var(--cds-color-neutral-background-primary);

        &:hover {
          background: var(--cds-color-neutral-background-primary);
        }
      }
    }

    &.${classes.invalid} {
      --border-color-on-dark: var(--cds-color-feedback-error-invert-weak);

      &:hover {
        background: var(--cds-color-feedback-error-invert-weak);
      }

      &.${classes.focused} {
        background: var(--cds-color-neutral-background-primary);

        &:hover {
          background: var(--cds-color-neutral-background-primary);
        }
      }
    }
  }

  &.${classes.onLight} {
    &.${classes.valid} {
      .${classes.notchedOutline} {
        border-color: var(--cds-color-feedback-success);
      }

      &:hover {
        background: var(--cds-color-feedback-background-success-weak);

        .${classes.notchedOutline} {
          border-color: var(--cds-color-feedback-success-hover);
        }
      }

      &.${classes.focused} {
        background: var(--cds-color-neutral-background-primary);

        .${classes.notchedOutline} {
          border-color: var(--cds-color-feedback-success-hover);
        }

        &:hover {
          .${classes.notchedOutline} {
            border-color: var(--cds-color-feedback-success-hover);
          }
        }
      }
    }

    &.${classes.invalid} {
      .${classes.notchedOutline} {
        border-color: var(--cds-color-feedback-error);
      }

      &:hover {
        background: var(--cds-color-feedback-background-error-weak);

        .${classes.notchedOutline} {
          border-color: var(--cds-color-feedback-error-hover);
        }
      }

      &.${classes.focused} {
        background: var(--cds-color-neutral-background-primary);

        .${classes.notchedOutline} {
          border-color: var(--cds-color-feedback-error-hover);
        }

        &:hover {
          .${classes.notchedOutline} {
            border-color: var(--cds-color-feedback-error-hover);
          }
        }
      }
    }
  }
`;

export default getInputCss;
