import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import { cloneDeep, find, isEqual, keyBy } from 'lodash';

import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { useTracker } from '@coursera/event-pulse/react';

import {
  DEFAULT_SORTED_VALUE,
  NUMBER_OF_RESULTS_PER_PAGE,
  SEARCH_INDEX_ID_BY_SORT_VALUE,
} from 'bundles/search-common/constants';
import { SearchContext } from 'bundles/search-common/providers/SearchContext';
import type {
  SearchConfig,
  SearchResult,
  SearchResults,
  SearchState,
  SearchStates,
  SearchURLParameters,
} from 'bundles/search-common/providers/searchTypes';
import useSearchParameters from 'bundles/search-common/providers/useSearchParameters';
import useSearchProviderQuery from 'bundles/search-common/providers/useSearchProviderQuery';
import type { SearchSortType } from 'bundles/search-common/types';
import { manipulateFiltersForEventing } from 'bundles/search-common/utils/providerEventingUtils';
import {
  addFiltersToFacetFilters,
  clearFacetFilters,
  combineConfigWithUrlParam,
  setFiltersForFacet,
  syncSearchStateToUrl,
} from 'bundles/search-common/utils/providerUtils';
import { getDefaultSortByValue } from 'bundles/search-common/utils/utils';

type Props = {
  searchConfigs: SearchConfig[];
  children: ReactNode;
  shouldReplaceRoute?: boolean;
  ssr?: boolean;
};

function SearchProviderInternal({ searchConfigs, children, shouldReplaceRoute = false, ssr = true }: Props) {
  const logEvent = useRetracked();
  const params = useSearchParameters();
  const searchStatesHashMap = keyBy(combineConfigWithUrlParam(searchConfigs, params), 'id');
  const [searchStates, setSearchStates] = useState<SearchStates>(searchStatesHashMap);
  const defaultSortValue = getDefaultSortByValue(params.sortBy ?? 'BEST_MATCH');
  const [activeIndex, setActiveIndex] = useState(SEARCH_INDEX_ID_BY_SORT_VALUE[defaultSortValue]);
  const [sortByValue, setSortByValue] = useState<SearchSortType>(defaultSortValue);
  const prevParams = useRef<SearchURLParameters | undefined>(params);
  const { isLoading, error, results, loadMore } = useSearchProviderQuery(searchStates, searchConfigs, ssr);

  const router = useRouter();
  const track = useTracker();

  useEffect(() => {
    if (!isEqual(prevParams.current, params)) {
      // update state according to URL param change
      setSearchStates(searchStatesHashMap);
    }

    prevParams.current = params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const replaceSearchState = (searchConfigId: string, newSearchState: SearchState) => {
    const existSearchState = find(searchStates, (s: SearchState) => s.id === searchConfigId);
    if (existSearchState) {
      const newSearchStates = { ...(searchStates || {}) };
      const { syncQueryWithUrl, syncFiltersWithUrl } = existSearchState;
      if (syncQueryWithUrl || syncFiltersWithUrl) {
        syncSearchStateToUrl(router, newSearchState, shouldReplaceRoute);
        if (!(syncQueryWithUrl && syncFiltersWithUrl)) {
          // if only one synced, the non synced item will not trigger rerender
          // retriggering manually here by changing the local state
          newSearchStates[searchConfigId] = newSearchState;
          setSearchStates(newSearchStates);
        }
      } else {
        newSearchStates[searchConfigId] = newSearchState;
        setSearchStates(newSearchStates);
      }
    }
  };

  const updateQuery = (query: string) => {
    const newSearchStates = cloneDeep(searchStates);
    let haveSynced = false;
    Object.keys(newSearchStates).forEach((indexId) => {
      newSearchStates[indexId].query = query;
      if (newSearchStates[indexId].syncQueryWithUrl && !haveSynced) {
        syncSearchStateToUrl(router, newSearchStates[indexId], shouldReplaceRoute);
        haveSynced = true;
      }
    });

    setSearchStates(newSearchStates);

    logEvent({
      action: 'search',
      trackingName: 'update_query',
      trackingData: { query },
    });
  };

  const updateQueryForIndex = (searchConfigId: string, query: string) => {
    const newSearchState: SearchState = { ...searchStates[searchConfigId], query, page: undefined };
    replaceSearchState(searchConfigId, newSearchState);

    logEvent({
      action: 'search',
      trackingName: 'update_query',
      trackingData: { id: searchConfigId, query },
    });
  };

  const addFilters = (searchConfigId: string, filters: string[]) => {
    if (filters.length < 1) return;

    const newSearchState: SearchState = { ...searchStates[searchConfigId], page: undefined };
    newSearchState.facetFilters = addFiltersToFacetFilters(filters, newSearchState.facetFilters);
    replaceSearchState(searchConfigId, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'add_filters',
      trackingData: { id: searchConfigId, filters },
    });
  };

  const setFiltersByFacet = (searchConfigId: string, facet: string, filters: string[]) => {
    const newSearchState: SearchState = { ...searchStates[searchConfigId], page: undefined };
    newSearchState.facetFilters = setFiltersForFacet(facet, filters, newSearchState.facetFilters);

    replaceSearchState(searchConfigId, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'apply_filters',
      trackingData: { id: searchConfigId, filters },
    });
  };

  const setFiltersAndSortBy = (sortBy: SearchSortType, newFacetFilters: string[][]) => {
    const hasSortByValueChanged = sortBy !== sortByValue;
    setSortByValue(sortBy);
    const newActiveIndex = SEARCH_INDEX_ID_BY_SORT_VALUE[sortBy];
    setActiveIndex(newActiveIndex);

    const newSearchState: SearchState = {
      ...searchStates[newActiveIndex],
      sortBy,
      facetFilters: newFacetFilters,
      page: undefined,
    };

    replaceSearchState(newActiveIndex, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'apply_filters',
      trackingData: { id: newActiveIndex, newFacetFilters },
    });

    if (hasSortByValueChanged) {
      logEvent({
        action: 'search',
        trackingName: 'set_sort_by',
        trackingData: { sortBy },
      });
    }
  };

  const removeFilters = (searchConfigId: string, filters: string[]) => {
    const newSearchState: SearchState = { ...searchStates[searchConfigId], page: undefined };
    if (newSearchState.facetFilters) {
      newSearchState.facetFilters = newSearchState.facetFilters
        .map((fs) => fs.filter((f) => !filters.includes(f)))
        .filter((fs) => fs.length > 0);
      replaceSearchState(searchConfigId, newSearchState);
    }

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'remove_filters',
      trackingData: { id: searchConfigId, filters },
    });
  };

  const clearFilters = (searchConfigId: string) => {
    const newSearchState: SearchState = {
      ...searchStates[searchConfigId],
      facetFilters: clearFacetFilters(searchStates[searchConfigId]?.facetFilters),
      page: undefined,
    };

    replaceSearchState(searchConfigId, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'clear_filters',
      trackingData: { id: searchConfigId },
    });
  };

  const clearFiltersAndSortBy = () => {
    setSortByValue(DEFAULT_SORTED_VALUE);
    const newActiveIndex = SEARCH_INDEX_ID_BY_SORT_VALUE[DEFAULT_SORTED_VALUE];
    setActiveIndex(newActiveIndex);

    const newSearchState: SearchState = {
      ...searchStates[newActiveIndex],
      facetFilters: clearFacetFilters(searchStates[newActiveIndex]?.facetFilters),
      sortBy: DEFAULT_SORTED_VALUE,
      page: undefined,
    };

    replaceSearchState(newActiveIndex, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'reset',
      trackingName: 'clear_filters_and_sort_by',
      trackingData: { id: newActiveIndex },
    });
  };

  const setPage = (searchConfigId: string, page: number) => {
    const newSearchState: SearchState = { ...searchStates[searchConfigId], page };

    replaceSearchState(searchConfigId, newSearchState);

    logEvent({
      action: 'search',
      trackingName: 'set_page',
      trackingData: { id: searchConfigId, page },
    });
  };

  const loadNextPage = (searchIndexId?: string) => {
    const indexId = searchIndexId || activeIndex;
    const inferredNextPage = Math.floor(
      (results?.find((s) => s.id === indexId)?.elements?.length || NUMBER_OF_RESULTS_PER_PAGE) /
        NUMBER_OF_RESULTS_PER_PAGE
    );

    if (loadMore) {
      loadMore(inferredNextPage, indexId);
      logEvent({
        action: 'search',
        trackingName: 'set_page',
        trackingData: { id: indexId, page: inferredNextPage },
      });
    }
  };

  function getSearchResults(): SearchResults | undefined;
  function getSearchResults(id: string): SearchResult | undefined;
  function getSearchResults(id?: string) {
    if (id) {
      return results?.find((s) => s.id === id);
    } else {
      return results;
    }
  }

  const setSortBy = (sortBy: SearchSortType) => {
    setSortByValue(sortBy);
    const newActiveIndex = SEARCH_INDEX_ID_BY_SORT_VALUE[sortBy];
    setActiveIndex(newActiveIndex);
    const newSearchState: SearchState = { ...searchStates[newActiveIndex], sortBy, page: undefined };
    replaceSearchState(newActiveIndex, newSearchState);
    logEvent({
      action: 'search',
      trackingName: 'set_sort_by',
      trackingData: { sortBy },
    });
  };

  return (
    <SearchContext.Provider
      value={{
        getSearchResults,
        isLoading,
        error,
        updateQuery,
        updateQueryForIndex,
        addFilters,
        removeFilters,
        setFiltersAndSortBy,
        clearFilters,
        clearFiltersAndSortBy,
        setPage,
        setFiltersByFacet,
        setActiveIndex,
        setSortBy,
        activeIndex,
        sortBy: sortByValue,
        loadNextPage,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

function SearchProvider({ children, ...props }: Props) {
  // initiate provider with default value so other hooks (e.g. useSearchProviderQuery) can check if provider is defined
  return (
    <SearchContext.Provider
      value={{
        getSearchResults: () => undefined,
        updateQuery: () => undefined,
        updateQueryForIndex: () => undefined,
        addFilters: () => undefined,
        setFiltersByFacet: () => undefined,
        removeFilters: () => undefined,
        setFiltersAndSortBy: () => undefined,
        clearFilters: () => undefined,
        clearFiltersAndSortBy: () => undefined,
        setPage: () => undefined,
        setActiveIndex: () => undefined,
        setSortBy: () => undefined,
        loadNextPage: () => undefined,
        activeIndex: SEARCH_INDEX_ID_BY_SORT_VALUE.BEST_MATCH,
        sortBy: 'BEST_MATCH',
        isLoading: false,
      }}
    >
      <SearchProviderInternal {...props}>{children}</SearchProviderInternal>
    </SearchContext.Provider>
  );
}

export default SearchProvider;
