import { useControlled } from '@core/utils';

export type UsePagination = {
  boundaryCount?: number;
  total: number;

  defaultPageSize?: number;
  pageSize?: number;

  defaultPage?: number;
  disabled?: boolean;

  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  onPageSizeChange?: (event: React.ChangeEvent<unknown>, size: number) => void;

  page?: number;

  siblingCount?: number;
};

export type UsePaginationItem = {
  onClick: React.ReactEventHandler;
  type:
    | 'page'
    | 'first'
    | 'last'
    | 'next'
    | 'previous'
    | 'start-ellipsis'
    | 'end-ellipsis';
  page: number;
  selected: boolean;
  disabled: boolean;
};

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

/**
 * Pagination hook with page size handling built in
 * Based on https://github.com/mui/material-ui/blob/master/packages/mui-material/src/usePagination/usePagination.js
 * @param props
 */
const usePagination = (props: UsePagination) => {
  const {
    boundaryCount = 1,
    total,
    defaultPage = 1,
    disabled = false,
    onChange: handleChange,
    onPageSizeChange,
    page: pageProp,

    siblingCount = 1,
    pageSize: pageSizeProp,
    defaultPageSize = 10,
  } = props;

  const [page, setPageState] = useControlled({
    controlled: pageProp,
    default: defaultPage,
    name: 'usePagination',
    state: 'page',
  });

  const [pageSize, setPageSizeState] = useControlled({
    controlled: pageSizeProp,
    default: defaultPageSize,
    name: 'usePagination',
    state: 'pageSize',
  });

  const totalPages = Math.ceil(total / pageSize);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (!pageProp) {
      setPageState(value);
    }

    if (handleChange) {
      handleChange(event, value);
    }

    if (__DEV__ && pageProp && !handleChange) {
      console.warn(
        'CDS: Component is used as controlled. Consider adding `onPageChange` callback or use `defaultPage` instead of `page`.'
      );
    }
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<unknown>,
    size: number
  ) => {
    const newPagesNumber = Math.ceil(total / size);

    if (!pageSizeProp) {
      setPageSizeState(size);
    }

    if (onPageSizeChange) {
      onPageSizeChange(event, size);
    }

    if (newPagesNumber < page) {
      handlePageChange(event, 1);
    }

    if (__DEV__ && pageSizeProp && !onPageSizeChange) {
      console.warn(
        'CDS: Component is used as controlled. Consider adding `onPageSizeChange` callback or use `defaultPageSize` instead of `pageSize`.'
      );
    }
  };

  const startPages = range(1, Math.min(boundaryCount, totalPages));
  const endPages = range(
    Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
    totalPages
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : totalPages - 1
  );

  const buttonPage = (
    type: Exclude<UsePaginationItem['type'], 'start-ellipsis' | 'end-ellipsis'>
  ) => {
    const typePageMap = {
      first: 1,
      previous: Math.max(page - 1, 1),
      next: Math.min(page + 1, totalPages),
      last: totalPages,
      page: page,
    };

    return typePageMap[type];
  };

  const pagesList = [
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < totalPages - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < totalPages - boundaryCount - 1
      ? ['end-ellipsis']
      : totalPages - boundaryCount > boundaryCount
      ? [totalPages - boundaryCount]
      : []),

    ...endPages,
  ];

  const pages: UsePaginationItem[] = pagesList.map((pageItem) => {
    return typeof pageItem === 'number'
      ? ({
          onClick: (event: React.SyntheticEvent) => {
            handlePageChange(event, pageItem);
          },
          type: 'page',
          page: pageItem,
          selected: pageItem === page,
          disabled,
          'aria-current': pageItem === page ? 'true' : undefined,
        } as UsePaginationItem)
      : ({
          type: pageItem,
          selected: false,
        } as UsePaginationItem);
  });

  const nextPage: UsePaginationItem = {
    type: 'next',
    page: buttonPage('next'),
    onClick: (event: React.SyntheticEvent) => {
      handlePageChange(event, buttonPage('next'));
    },
    selected: false,
    disabled: disabled || page >= totalPages,
  };
  const previousPage: UsePaginationItem = {
    type: 'previous',
    page: buttonPage('previous'),
    onClick: (event: React.SyntheticEvent) => {
      handlePageChange(event, buttonPage('previous'));
    },
    selected: false,
    disabled: disabled || page <= 1,
  };

  const lastPage: UsePaginationItem = {
    type: 'last',
    page: buttonPage('last'),
    onClick: (event: React.SyntheticEvent) => {
      handlePageChange(event, buttonPage('last'));
    },
    selected: false,
    disabled: page >= totalPages,
  };
  const firstPage: UsePaginationItem = {
    type: 'first',
    page: buttonPage('first'),
    onClick: (event: React.SyntheticEvent) => {
      handlePageChange(event, buttonPage('first'));
    },
    selected: false,
    disabled: page <= 1,
  };

  return {
    currentPage: page,
    pageSize,
    totalPages,
    pages,
    firstPage,
    lastPage,
    nextPage,
    previousPage,
    handlePageSizeChange,
  };
};

export default usePagination;
