import { useMemo, useRef } from 'react';

import useDeepCompareEffect from 'use-deep-compare-effect';

import { useRetracked } from 'js/lib/retracked';

import reCAPTCHA from 'bundles/authentication/shared/reCAPTCHA';
import type { ReCAPTCHA, Options as RecaptchaOptions } from 'bundles/authentication/shared/reCAPTCHA';

// We omit _eventData because we are using useRetracked, which handles grabbing the _eventData
// from context already.
type Options = Omit<RecaptchaOptions, '_eventData'>;

export function useRecaptcha(
  container: string | HTMLElement,
  options: Options,
  shouldBypassRecaptcha?: boolean
): ReCAPTCHA {
  const track = useRetracked();
  const reCAPTCHARef = useRef<ReCAPTCHA | null>(null);

  useDeepCompareEffect(() => {
    if (!reCAPTCHARef.current && !shouldBypassRecaptcha) {
      reCAPTCHARef.current = reCAPTCHA(container, { trackingFn: track, ...options });
    }
  }, [track, container, options]);

  return useMemo(
    () => ({
      getResponse() {
        return reCAPTCHARef.current ? reCAPTCHARef.current.getResponse() : Promise.resolve('');
      },
      reset() {
        return reCAPTCHARef.current?.reset();
      },
    }),
    [reCAPTCHARef]
  );
}
