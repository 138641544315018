/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';

import { showBucketTestV2 } from 'bundles/front-page/utils/showBucketTestV2';
import PremiumHubPageHeader from 'bundles/premium-hub/components/shared/PremiumHubPageHeader';
import { HubPages, UnifiedHubProductVariant } from 'bundles/premium-hub/constants';
import useLearnBrowse from 'bundles/premium-hub/hooks/useLearnBrowse';
import usePremiumHubPageData from 'bundles/premium-hub/hooks/usePremiumHubPageData';
import OverviewPageContent from 'bundles/premium-hub/pages/subpages/OverviewPageContent';
import SearchPageContent from 'bundles/premium-hub/pages/subpages/SearchPageContent';
import { convertDegreeCategoryFieldsToPPCDegreeCategory } from 'bundles/premium-hub/utils/dataTransformationUtils';
import HubPageGoogleSchemaMarkup from 'bundles/seo/components/HubPageGoogleSchemaMarkup';

const CoreHubPageContent: React.FC = () => {
  const pageType = HubPages.MainHubPage;
  const { slug, inBrowse } = useLearnBrowse(pageType);
  const premiumHubPageData = usePremiumHubPageData({
    slug,
    pageType,
  });

  if (!premiumHubPageData) {
    return null;
  }

  const { degreeCategories, premiumProductList, useSimplifiedHeader = false } = premiumHubPageData;

  return (
    <>
      <PremiumHubPageHeader isSimplified={useSimplifiedHeader} />
      <main className="rc-DegreesHubPage" data-e2e="DegreeHubPage">
        <HubPageGoogleSchemaMarkup
          categories={degreeCategories?.map(convertDegreeCategoryFieldsToPPCDegreeCategory) ?? []}
          productList={
            premiumProductList?.map((product) => ({ ...product, productVariant: UnifiedHubProductVariant.Degree })) ??
            []
          }
        />
        {inBrowse ? (
          <SearchPageContent {...premiumHubPageData} currentPageType={pageType} shouldShowDriftChat={false} />
        ) : (
          <OverviewPageContent
            {...premiumHubPageData}
            currentPageType={pageType}
            shouldShowDriftChat={true}
            showDegreeEoiCtaInHubBanner={showBucketTestV2()}
          />
        )}
      </main>
    </>
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_hub',
      page: 'degree_main',
    },
  }))
)(CoreHubPageContent);
