/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { createRef } from 'react';
import LazyLoad from 'react-lazyload';

import Imgix from 'js/components/Imgix';

import { breakpoints } from '@coursera/cds-core';

import { getLowQualityImageSrc } from 'bundles/page/components/shared/utils/lazyImgixUtils';

import _t from 'i18n!nls/page';

type InputProps = {
  src: string;
  alt: string; // for accessibility
  role?: string; // for accessibility
  maxWidth: number;
  maxHeight: number;
  width?: number;
  height?: number;
  imgParams?: {}; // REF http://www.imgix.com/docs/reference
  disableDpr3SrcSet?: boolean;
  className?: string;
  onLoad?: () => {};
  style?: {};
  enableFluidWidth?: boolean;
  degradeForSsr?: boolean; // directive for SSR to render an image with a lower quality placeholder
};

const styles = {
  lazyImgixWrapper: (maxHeight: number, maxWidth: number, enableFluidWidth: boolean) => css`
    @media (max-width: ${breakpoints.values.md}px) {
      max-width: 95vw;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      max-width: 90vw;
    }

    position: relative;
    height: 0;
    width: ${enableFluidWidth ? '100%' : maxWidth};
    padding-bottom: ${(maxHeight / maxWidth) * 100}%;
  `,
  image: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
};

const LazyImgix = (props: InputProps) => {
  const defaultImgParams = {
    quality: 40,
    ignoreDpr3SrcSet: props.disableDpr3SrcSet,
  };
  const { maxHeight, maxWidth, enableFluidWidth } = props;

  const placeHolderRef = createRef<HTMLImageElement>();

  const removePlaceholder = () => {
    const placeHolderImgNode = placeHolderRef.current;
    if (placeHolderImgNode) {
      placeHolderImgNode.parentNode?.removeChild(placeHolderImgNode);
    }
  };

  const placeHolderSrc = getLowQualityImageSrc(props);

  const onImageLoad = () => {
    props.onLoad?.();
    removePlaceholder();
  };

  return (
    <div css={styles.lazyImgixWrapper(maxHeight, maxWidth, !!enableFluidWidth)}>
      <img ref={placeHolderRef} alt={_t('Placeholder')} src={placeHolderSrc} css={styles.image} />
      <LazyLoad offset={100} once>
        <Imgix {...props} imgParams={{ ...defaultImgParams, ...props.imgParams }} onLoad={onImageLoad} />
      </LazyLoad>
    </div>
  );
};

export default LazyImgix;
