import type React from 'react';
import { useEffect } from 'react';

import { useIsMobileHeader } from 'bundles/front-page/hooks/useIsMobileHeader';
import { SEARCH_BAR_LOCATION } from 'bundles/search-common/constants';
import type { SearchBarLocation } from 'bundles/search-common/constants';

export const useScrollToSearchBarOnFocused = (
  isSearchBarFocused: boolean,
  pageLocation: SearchBarLocation,
  searchBarRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const PAGE_HEADER_HEIGHT = 75;
  const isMobileHeader = useIsMobileHeader();

  useEffect(() => {
    if (pageLocation !== SEARCH_BAR_LOCATION.FrontPageBanner || !isSearchBarFocused || isMobileHeader) {
      return;
    }

    if (searchBarRef && searchBarRef.current) {
      const searchBarTop = searchBarRef.current.getBoundingClientRect().top - PAGE_HEADER_HEIGHT;
      window.scrollBy({ top: searchBarTop, behavior: 'smooth' });
    }
  }, [isSearchBarFocused, pageLocation, searchBarRef, isMobileHeader]);
};
