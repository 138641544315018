/**
 * Private module reserved for @coursera/cds-core package.
 */

import createLargeSvgIcon from '@core/utils/createLargeSvgIcon';

export default createLargeSvgIcon('CheckboxIndeterminateIcon', {
  large: (
    <>
      <rect fill="currentColor" height="24" rx="2" width="24" />
      <rect
        height="22"
        rx="1"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="2"
        width="22"
        x="1"
        y="1"
      />
      <path
        d="M4.00091 12.0001L20 12"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </>
  ),
});
