/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { range } from 'lodash';

import { StarFilledColoredBoldIcon, StarHalfFilledColoredBoldIcon, StarIcon } from '@coursera/cds-icons';

export enum StarSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type Props = {
  size?: StarSize;
  rating: number;
  maxNumStars?: number;
  a11yIdentifier?: string;
};

const StarRatings: React.FC<Props> = ({ size = StarSize.Medium, rating, maxNumStars = 5, a11yIdentifier }) => {
  const starRatings = range(maxNumStars).reduce((total, index) => {
    // Same logic as in CUI StarRating component
    const currRating = index + 1;
    const decimalPartOfRating = rating % 1;
    const integerPartOfRating = Math.floor(rating);
    const isHalf = integerPartOfRating + 1 === currRating && decimalPartOfRating >= 0.25 && decimalPartOfRating <= 0.75;
    const isFull = currRating < rating + 0.25;
    let starIcon;

    if (isFull) {
      starIcon = <StarFilledColoredBoldIcon size={size} title="Filled Star" />;
    } else if (isHalf) {
      starIcon = <StarHalfFilledColoredBoldIcon size={size} title="Half-Filled Star" />;
    } else {
      starIcon = <StarIcon size={size} title="Star" />;
    }

    total.push(<span key={`rating_${index}_${a11yIdentifier}`}>{starIcon}</span>);
    return total;
  }, [] as JSX.Element[]);

  return (
    <div className="star-ratings" data-unit="reviews-star-ratings">
      {starRatings}
    </div>
  );
};

export default StarRatings;
