import React, { useState, useRef } from 'react';

import { Collapse } from '@material-ui/core';
import useForkRef from '@material-ui/core/utils/useForkRef';

import clsx from 'clsx';

import { useId } from '@coursera/cds-common';

import ButtonBase from '@core/ButtonBase';
import type { ContextualHelpTooltipProps } from '@core/ContextualHelp/ContextualHelpTooltip';

import ContextualHelpIcon from './ContextualHelpIcon';
import ContextualHelpTooltip from './ContextualHelpTooltip';
import getContextualHelpCss, { classes } from './getContextualHelpCss';

export type CustomHelpProps = {
  isExpanded?: boolean;
  onClose: () => void;
};

export type HelpContentProps = CustomHelpProps & {
  children?: React.ReactNode | ((props: CustomHelpProps) => React.ReactNode);
};

const renderHelpContent = (props: HelpContentProps) => {
  const { isExpanded, onClose, children, ...rest } = props;

  if (typeof children === 'function') {
    return children({ isExpanded, onClose });
  }

  return (
    <Collapse in={isExpanded} timeout="auto">
      <ContextualHelpTooltip onClose={onClose} {...rest}>
        {children}
      </ContextualHelpTooltip>
    </Collapse>
  );
};

export type Props = {
  /**
   * The main label for this component.
   */
  label: React.ReactNode;

  /**
   * Additional properties for the help toggle button (i.e. the "i" icon button)
   */
  helpToggleProps: {
    /**
     * Accessible description required for the help toggle button
     */
    'aria-label': string;

    /**
     * Use this for custom rendering if the tooltip expands right below the toggle,
     * such that it makes sense for the toggle to have `aria-expanded` on it
     */
    'aria-expanded'?: boolean;
  };

  /**
   * Free-space placeholder for the help content when the component is expanded.
   *  - Passing a ReactNode will render this help content inside the default tooltip styling
   *  - Passing a function will allow custom rendering for the help content
   */
  children: React.ReactNode | ((props: CustomHelpProps) => React.ReactNode);

  /**
   * Additional properties when using the tooltip to render the help content.
   * Ignored when using custom help content rendering.
   */
  tooltipProps?: ContextualHelpTooltipProps;

  /**
   * Whether the component should load in the expanded state by default.
   * Note that this works best for the default tooltip and may not be ideal when using a
   * custom rendered component like a Dialog to be in an opened state on load.
   * @default false
   */
  defaultExpanded?: boolean;

  /**
   * Callback fired when the expanded state is changed.
   * @param {boolean} isExpanded new state after change
   */
  onChange?: (isExpanded: boolean) => void;

  /**
   * Optional additional classes for the root component
   */
  className?: string;

  /**
   * Base ID to be used for a11y, will be used as a prefix for subcomponents. By default, it will be autogenerated in CDS.
   * @ignore
   */
  id?: string;

  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;
};

/**
 * Contextual help provides the user with additional information on a particular feature, element,
 * or term within the context of the UI.
 * Use the default tooltip for a short explanation or a custom component like Dialog for longer text requirements.
 *
 * See [Props](__storybookUrl__/components-surfaces-contextual-help--default#props)
 *
 * @example
 * <ContextualHelp
 *   label={<Typography2 component="p">This is a standalone tooltip</Typography2>}
 *    helpToggleProps={{
 *     'aria-label': 'Information about standalone tooltip',
 *    }}
 *  >
 *    <Typography2 component="p">
 *      Example of support text contained within the standalone tooltip
 *    </Typography2>
 *  </ContextualHelp>
 */
const ContextualHelp = React.forwardRef<HTMLButtonElement, Props>(
  function ContextualHelp(
    {
      className,
      id,
      onChange,
      helpToggleProps,
      tooltipProps,
      label,
      children,
      defaultExpanded = false,
      invert = false,
      ...restProps
    },
    ref
  ) {
    const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);
    const toggleButtonRef = useRef<HTMLButtonElement>(null);
    const handleRef = useForkRef(ref, toggleButtonRef);

    const baseId = useId(id);
    const helpToggleId = `${baseId}-helpToggleButton`;
    const helpContentId = `${baseId}-helpContent`;

    const handleHelpToggle = () => {
      setIsExpanded((prevExpanded) => !prevExpanded);
      onChange?.(!isExpanded);
    };

    const handleClose = () => {
      setIsExpanded(false);

      // set focus back on the toggle after closed,
      // since it might get closed either from the Close button
      // inside the default tooltip, or from a custom component like Dialog.
      toggleButtonRef?.current?.focus();
    };

    const isDefaultTooltip = typeof children !== 'function';

    // only use `aria-expanded` on the default dropdown tooltip,
    // see https://coursera.atlassian.net/browse/CDS-1222?focusedCommentId=700425
    const ariaExpanded = isDefaultTooltip
      ? isExpanded
      : helpToggleProps['aria-expanded']; // custom renders can still set this

    return (
      <div
        className={className}
        css={getContextualHelpCss}
        id={baseId}
        {...restProps}
      >
        <div
          className={clsx(classes.container, {
            [classes.expanded]: isExpanded,
            [classes.tooltipExpanded]: isDefaultTooltip && isExpanded,
            [classes.inverted]: invert,
          })}
        >
          <div className={classes.label}>{label}</div>
          <ButtonBase
            ref={handleRef}
            aria-controls={helpContentId}
            aria-expanded={ariaExpanded}
            aria-label={helpToggleProps['aria-label']}
            className={classes.helpToggleButton}
            id={helpToggleId}
            onClick={handleHelpToggle}
          >
            <ContextualHelpIcon
              expanded={isExpanded}
              id={`${baseId}-contextual-help-icon`}
              invert={invert}
            />
          </ButtonBase>
        </div>
        <div className={classes.helpContent} id={helpContentId}>
          {renderHelpContent({
            isExpanded,
            onClose: handleClose,
            children,
            ...tooltipProps,
          })}
        </div>
      </div>
    );
  }
);

export default ContextualHelp;
