import { css } from '@emotion/react';

export const style = css`
  .rc-OfferExplanationTable {
    background-color: transparent;
    padding: 0;
    margin-top: 30px;

    @media screen and (max-width: 1023px) {
      margin-top: 60px;
    }

    .explanation-title {
      font-family: 'Source Sans Pro', Arial, sans-serif;
      font-size: 20px !important;
      font-weight: 600 !important;
      line-height: 32px;
      margin-bottom: 16px;
    }

    .explanation-table {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;

      @media screen and (max-width: 599px) {
        padding: 0 12px 12px 12px;
      }
    }

    .table-row {
      display: grid;
      grid-template-columns: 160px auto;
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border-bottom: none;
      }

      @media screen and (max-width: 599px) {
        display: flex;
        padding-top: 12px;
        flex-direction: column;
      }
    }

    .table-header {
      @media screen and (max-width: 599px) {
        display: grid;
      }
    }

    .desktop-only-header {
      @media screen and (max-width: 599px) {
        display: none;
      }
    }

    .span-title {
      font-size: 14px;
      font-weight: bold;
      color: #636363;
    }

    .span {
      font-size: 14px;
      color: #1f1f1f;

      @media screen and (max-width: 599px) {
        text-align: left;
      }
    }

    .first-column {
      padding: 12px 10px;
      align-self: center;
      text-align: center;
      justify-self: center;

      > * {
        margin: 0 auto;
      }

      @media screen and (max-width: 599px) {
        text-align: left;
        justify-self: flex-start;
        width: 100%;
      }
    }

    .second-column {
      padding: 24px;
      align-self: center;

      @media screen and (max-width: 599px) {
        text-align: center;
        padding: 0 12px 12px 12px;
      }
    }

    .table-header-first-column {
      padding: 12px 4px;
    }

    .top-skills-paragraph {
      font-family: 'Source Sans Pro', Arial, sans-serif;
      margin-bottom: 0;
    }

    .table-content {
      @media screen and (max-width: 599px) {
        padding-left: 0;
      }
    }
  }
`;

export default style;
