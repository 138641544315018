/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { MutableRefObject } from 'react';
import * as React from 'react';

import type { Timestamp } from '__generated__/graphql-types';

import { processImage } from 'js/lib/imgix';
import { MOMENT_FORMATS, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';
import type { ProductType as EventV3ProductType, SectionName } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import {
  APPLICATION_DATE_TIMEZONE_OVERRIDE,
  PRODUCT_VARIANT_TO_PRODUCT_NAME,
  ProductType,
} from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { ProductCardProps } from 'bundles/premium-hub/types';
import { getUrlFromProductVariant } from 'bundles/premium-hub/utils';
import {
  getEventingProductTypeFromVariant,
  removeProductIdPrefix,
} from 'bundles/premium-hub/utils/dataTransformationUtils';

import _t from 'i18n!nls/premium-hub';

const getApplicationDueText = (date?: Pick<Timestamp, 'seconds'> | null) => {
  if (!date || !date.seconds) return '';

  return _t('Application due #{date}', {
    date: formatDateTimeDisplay(
      new Date(Number(date.seconds) * 1000),
      MOMENT_FORMATS.LONG_DATE_ONLY_DISPLAY,
      APPLICATION_DATE_TIMEZONE_OVERRIDE
    ),
  });
};

type Props = {
  product: ProductCardProps;
  productType: (typeof ProductType)[keyof typeof ProductType];
  entityIndex: number;
  sectionName: SectionName;
};

const styles: Record<string, CSSProperties> = {
  productCardContainer: (theme: Theme) => ({
    display: 'flex',
    marginBottom: 'var(--cds-spacing-300)',

    ':hover': {
      textDecoration: 'none',
      h3: {
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
  }),
  partnerLogo: (theme: Theme) => ({
    maxHeight: '96px',
    borderRadius: '5px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.13)',
    padding: 'var(--cds-spacing-100)',
    margin: '0 var(--cds-spacing-200) 0 0',
    [`${breakpoints.down('md')}`]: { maxHeight: '64px', width: 'auto', margin: '0 var(--cds-spacing-150) 0 0' },
  }),
  partnerCard: css`
    padding-right: 20px;
  `,
  goToDegree: css`
    color: var(--cds-color-interactive-primary);
  `,
};

const TopProductCard: React.FunctionComponent<Props> = ({ product, productType, entityIndex, sectionName }) => {
  const theme = useTheme();
  const trackV3 = useTracker();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const { name, productVariant, slug, partner, description, upcomingTermDates } = product;

  const productCardTrackingDataV3 = {
    productCard: {
      index: entityIndex,
    },
    product: {
      id: removeProductIdPrefix(product.id ?? ''),
      slug: product.slug ?? '',
      type: getEventingProductTypeFromVariant(product.productVariant) as EventV3ProductType,
    },
    pageSection: { sectionName, index: sectionIndex },
  };

  const topDegreesCardRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    productCardTrackingDataV3
  );

  if (!name || !slug) {
    return null;
  }

  const url = getUrlFromProductVariant(productVariant, slug);
  const productName = PRODUCT_VARIANT_TO_PRODUCT_NAME[productVariant];
  const partnerLogo = processImage(partner?.squareLogo, {
    auto: 'format,compress',
    format: 'avif',
    fit: 'fill',
    width: 180,
    height: 180,
  });

  const trackingData = {
    entityId: slug,
    link: url,
    ...product,
    productRankBaseZero: entityIndex,
  };
  const applicationDueText = getApplicationDueText(upcomingTermDates?.nextAdmissionSubtermDeadlineDate);

  return (
    <div data-test="TopProductCard" key={name} css={styles.partnerCard}>
      <div ref={topDegreesCardRef}>
        <TrackedLink2
          href={url}
          data-test="DescriptionPageLink"
          aria-label={_t('Go to degree, #{productName}. #{description}', { productName: name, description })}
          trackingName="top_product_card"
          data={trackingData}
          withVisibilityTracking={true}
          requireFullyVisible={true}
          css={styles.productCardContainer(theme)}
          onClick={() => trackV3('click_product_card', productCardTrackingDataV3)}
        >
          {partnerLogo && (
            <div data-test="PartnerLogo">
              <img
                src={partnerLogo}
                alt={partner?.name || _t('Partner logo')}
                css={styles.partnerLogo(theme)}
                loading="lazy"
                width="96"
                height="96"
              />
            </div>
          )}
          <div>
            <Typography2 variant="subtitleMedium" component="p" css={{ marginBottom: 'var(--cds-spacing-200)' }}>
              {productName ? _t('#{name} #{productName}', { name, productName }) : name}
            </Typography2>
            {description && (
              <Typography2
                component="p"
                data-test="Description"
                variant="bodySecondary"
                css={{ marginBottom: 'var(--cds-spacing-200)' }}
              >
                {description}
              </Typography2>
            )}
            {applicationDueText && (
              <Typography2 component="p" variant="bodySecondary" css={{ marginBottom: 'var(--cds-spacing-200)' }}>
                {applicationDueText}
              </Typography2>
            )}
            <Typography2
              component="h3"
              variant="subtitleMedium"
              css={styles.goToDegree}
              data-test="DescriptionPageLink"
            >
              {_t('Go to #{productType}', {
                productType: productType === ProductType.Certificates ? 'certificate' : 'degree',
              })}
            </Typography2>
          </div>
        </TrackedLink2>
      </div>
    </div>
  );
};

export default TopProductCard;
