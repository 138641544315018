import { default as Actions } from './Actions';
import { default as Content } from './Content';
import { default as DialogRoot } from './FullscreenDialog';
import { default as Header } from './Header';
import { default as HeadingGroup } from './HeadingGroup';

export type { Props as FullscreenDialogProps } from './FullscreenDialog';
export type { Props as FullscreenDialogHeaderProps } from './Header';
export type { Props as FullscreenDialogHeadingGroupProps } from './HeadingGroup';
export type { Props as FullscreenDialogContentProps } from './Content';
export type { Props as FullscreenDialogActionsProps } from './Actions';

type DialogRootType = typeof DialogRoot;

/**
 * Wraps the building blocks of the fullscreen dialog into a single namespace.
 *
 * @see FullscreenDialog
 */
export interface FullscreenDialogType extends DialogRootType {
  /**
   * The full-screen dialog header. The header is always sticky and will always
   * render a Close button. Two optional slots are provided for supplementary content.
   *
   * Required by `FullscreenDialog`.
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-fullscreendialog--default#props) for details.
   *
   * @see FullscreenDialog
   * @see FullscreenDialog.HeadingGroup
   * @see FullscreenDialog.Content
   * @see FullscreenDialog.Actions
   */

  Header: typeof Header;

  /**
   * The dialog heading group.
   *
   * Required by `FullscreenDialog`.
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-fullscreendialog--default#props) for details.
   *
   * @see FullscreenDialog
   * @see FullscreenDialog.Header
   * @see FullscreenDialog.Content
   * @see FullscreenDialog.Actions
   */
  HeadingGroup: typeof HeadingGroup;

  /**
   * Container for the the full-screen dialog content.
   *
   * Required by `FullscreenDialog`.
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-fullscreendialog--default#props) for details.
   *
   * @see FullscreenDialog
   * @see FullscreenDialog.Header
   * @see FullscreenDialog.HeadingGroup
   * @see FullscreenDialog.Actions
   */
  Content: typeof Content;

  /**
   * The full-screen dialog actions container for components that interact with the
   * dialog state.
   *
   * Required by `FullscreenDialog`
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-fullscreendialog--default#props) for details.
   *
   * @see FullscreenDialog
   * @see FullscreenDialog.Header
   * @see FullscreenDialog.HeadingGroup
   * @see FullscreenDialog.Content
   */
  Actions: typeof Actions;
}

/**
 * Full-screen dialog takes over the entire current UI to create a focused canvas.
 * It emphasizes multi-step or process-driven user journeys and accommodates much
 * larger content than dialog.
 *
 * Anatomy:
 * ```
 * <FullscreenDialog>
 *   <FullscreenDialog.Header />
 *   <FullscreenDialog.HeadingGroup />
 *   <FullscreenDialog.Content />
 *   <FullscreenDialog.Actions />
 * </FullscreenDialog>
 * ```
 *
 * See
 * [Props](__storybookUrl__/components-surfaces-fullscreendialog--default#props) for details.
 *
 * @see FullscreenDialog.Header
 * @see FullscreenDialog.HeadingGroup
 * @see FullscreenDialog.Content
 * @see FullscreenDialog.Actions
 */
export const FullscreenDialog: FullscreenDialogType = Object.assign(
  DialogRoot,
  {
    Header,
    HeadingGroup,
    Content,
    Actions,
  }
);
