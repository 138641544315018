import React from 'react';

import { useListBoxSection } from '@react-aria/listbox';
import type { ListState } from '@react-stately/list';
import type { Node } from '@react-types/shared';

import { useLocalizedStringFormatter } from '@coursera/cds-common';

import Divider from '@core/Divider';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import i18nMessages from './i18n';
import Option from './Option';
import type { Props as OptionProps } from './Option';
import getGroupCss, { classes } from './styles/groupCss';

export type Props = {
  group: Node<unknown>;
  state: ListState<unknown>;
  searchQuery?: OptionProps['searchQuery'];
  comparator?: OptionProps['comparator'];
};

/**
 * Renders a group of options
 */
const Group = (props: Props) => {
  const { group, state, searchQuery, comparator } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: group.rendered,
    'aria-label': group['aria-label'],
  });

  // safe to assume that getChildren is defined
  const groupChildren = group.hasChildNodes
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Array.from(state.collection.getChildren!(group.key))
    : [];

  return (
    <React.Fragment>
      {group.key !== state.collection.getFirstKey() && (
        <li
          css={getGroupCss}
          data-testid="visual-separator"
          role="presentation"
        >
          <Divider className={classes.divider} />
        </li>
      )}

      <li {...itemProps} css={getGroupCss}>
        {group.rendered && (
          <Typography
            {...headingProps}
            className={classes.label}
            color="body"
            component="div"
            variant="subtitleMedium"
          >
            {group.rendered}
            <VisuallyHidden>
              {stringFormatter.format('itemsNumberLabel', {
                number: groupChildren.length,
              })}
            </VisuallyHidden>
          </Typography>
        )}
        <ul {...groupProps} className={classes.list} role="group">
          {groupChildren.map((item) => {
            return (
              <Option
                key={item.key}
                comparator={comparator}
                item={item}
                searchQuery={searchQuery}
                state={state}
                suffix={item.props.suffix}
                supportText={item.props.supportText}
              />
            );
          })}
        </ul>
      </li>
    </React.Fragment>
  );
};

export default Group;
