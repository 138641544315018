/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ProductCardProps } from '@coursera/cds-core';
import { Grid, ProductCard, VisuallyHidden } from '@coursera/cds-core';

import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';
import useIsConsumerSerp from 'bundles/search-common/utils/useIsConsumerSerp';

import _t from 'i18n!nls/search-common';

const styles = {
  productCard: css`
    padding: 0 var(--cds-spacing-100) var(--cds-spacing-300);
  `,
};

type CdsCardsProps = {
  resultsPerPage: number;
  variant?: ProductCardProps['variant'];
};

export const ProductCardV2Placeholder = ({ variant = 'grid' }: { variant?: ProductCardProps['variant'] }) => {
  const placeholderProps: ProductCardProps = {
    variant,
    productType: ' ',
  };

  return <ProductCard {...placeholderProps} loading={true} />;
};

export const ProductsCardsCdsPlaceholder = ({ resultsPerPage, variant }: CdsCardsProps) => {
  const searchCards = Array.from({ length: resultsPerPage }, (v, i) => i);
  const isSearchPage = useIsConsumerSerp();
  const isLearnerInFilterTestVariant = isInFilterTestVariant();

  const desktopCardSize = React.useMemo(() => {
    let lgCardSize: 3 | 4 | 12 = 4;

    if (isLearnerInFilterTestVariant && isSearchPage) {
      lgCardSize = 3;
    }

    if (variant === 'list') {
      lgCardSize = 12;
    }

    return lgCardSize;
  }, [isLearnerInFilterTestVariant, isSearchPage, variant]);

  return (
    <Grid container>
      <VisuallyHidden role="status">{_t('Loading search results')}</VisuallyHidden>

      {searchCards.map((placeholderIndex) => (
        <Grid
          key={placeholderIndex}
          item
          lg={desktopCardSize}
          md={variant === 'list' ? 12 : 4}
          sm={variant === 'list' ? 12 : 6}
          xs={12}
          css={styles.productCard}
        >
          <ProductCardV2Placeholder variant={variant} />
        </Grid>
      ))}
    </Grid>
  );
};
