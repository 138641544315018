import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('formControl', [
  'root',
  'formLabel',
  'formValidationStatus',
  'formStatusText',
  'formSupportText',
]);

const getFormCardCss = css`
  &.${classes.root} {
    max-width: 100%;
  }

  .${classes.formLabel} {
    display: inline;
  }

  .${classes.formValidationStatus} {
    max-width: 100%;
    width: max-content;
    margin: var(--cds-spacing-150) 0 0;
  }

  .${classes.formStatusText} {
    margin: var(--cds-spacing-50) 0 0;
  }

  .${classes.formSupportText} {
    margin: var(--cds-spacing-50) 0 0;
  }
`;

export default getFormCardCss;
