import type { Key, ReactNode } from 'react';
import { useRef, useState, forwardRef } from 'react';

import useForkRef from '@material-ui/core/utils/useForkRef';

import { useComboBox } from '@react-aria/combobox';
import { usePreventScroll } from '@react-aria/overlays';
import { useComboBoxState } from '@react-stately/combobox';
import type { CollectionChildren } from '@react-types/shared';
import clsx from 'clsx';

import { breakpoints, useFilter } from '@coursera/cds-common';

import Drawer from '@core/Popover/Drawer';
import type { SearchProps } from '@core/Search';
import Search from '@core/Search';
import { useMediaQuery } from '@core/utils';

import constants from './constants';
import DrawerAutocomplete from './DrawerAutocomplete';
import ListBox from './ListBox';
import Popper from './Popper';
import searchCss, { classes } from './styles/searchCss';

export type CloseReason =
  | 'backdropClick'
  | 'escapeKeyDown'
  | 'confirmedSelection';

type CommonProps = {
  className?: string;
  id?: string;
};

type InputProps = Pick<
  SearchProps,
  | 'readOnly'
  | 'disabled'
  | 'prefixIcon'
  | 'placeholder'
  | 'name'
  | 'inputProps'
  | 'autoFocus'
  | 'fullWidth'
>;

type AccessibleLabel =
  | {
      'aria-label': string;
      'aria-labelledby'?: never;
    }
  | {
      'aria-label'?: never;
      'aria-labelledby': string;
    };

export type Props<OptionType extends object> = {
  /**
   * The currently selected key in the collection (controlled mode).
   */
  selectedKey?: Key;

  /**
   * The default selected key in the collection (uncontrolled mode).
   */
  defaultSelectedKey?: Key;

  /**
   * The input value. Use when the component should be controlled.
   */
  value?: string;

  /**
   * Default input value. Used to pre select options for uncontrolled component.
   */
  defaultValue?: string;

  /**
   * Determines when the popover is shown.
   *
   * `input` - popover shows up when a user enters text in the field
   *
   * `focus` - popover shows up when the field is focussed
   *
   *
   * @default input
   */
  popoverTrigger?: 'input' | 'focus';

  /**
   * If set to `true`, the popover will render loading state.
   */
  loading?: boolean;

  /**
   * Use this prop to set all available options that also can change dynamically.
   * Prefer this prop in combination with children render prop if the list of options is huge.
   *
   * **Note**: when using this prop options will not be filtered automatically.
   */
  items?: Iterable<OptionType>;

  /**
   * Must be a `Autocomplete.Option` or `Autocomplete.Group` components when static.
   * Has to be a render prop when `items` prop is set
   *
   * @example
   * Static children:
   *
   * ```tsx
   *  <Autocomplete.Option key="1">1</Autocomplete.Option>
   *  <Autocomplete.Option key="2">2</Autocomplete.Option>
   *  <Autocomplete.Option key="3">3</Autocomplete.Option>
   * ```
   *
   * Dynamic:
   * ```tsx
   *  {(option) => (<Autocomplete.Option key={option.value}>{option.label}</Autocomplete.Option>)}
   * ```
   */
  children: CollectionChildren<OptionType>;

  /**
   * Render prop that allows to customize the message when no options are found.
   */
  renderNotFoundMessage?: () => ReactNode;

  /**
   * If set to `true` the field will preserve custom value when blurred.
   * @default false
   */
  allowsCustomValue?: boolean;

  /**
   * Allows to control the width of input.
   */
  inputWidth?: string;

  /**
   * Allows to control the width of dropdown.
   *
   * **Note:** Has no effect on XS screen size
   * @deprecated Use `dropdownProps.style.width` instead
   * @ignore
   */
  dropdownWidth?: string;

  /**
   * Allows to configure the dropdown props
   */
  dropdownProps?: {
    /**
     * If set to `true`, the dropdown will not be rendered in a portal.
     */
    disablePortal?: boolean;
    style?: {
      width?: string;
      maxHeight?: string;
    };

    className?: string;
  };

  /**
   * Slot to render action buttons inside the search field.
   *
   * **Note:** This is not applicable to the search field inside the drawer.
   */
  inputActionButtons?: ReactNode;

  /**
   * Slot to render action buttons in the drawer footer.
   *
   * **Note:** Only applicable on XS screen size
   */
  drawerActionButtons?: ReactNode;

  /**
   * Props to pass to the input inside the drawer.
   */
  drawerInputProps?: SearchProps['inputProps'];

  /**
   * Callback, fired when dropdown is closed.
   */
  onClose?: () => void;

  /**
   * Callback, fired after dropdown opens.
   */
  onOpen?: () => void;

  /**
   * Callback, fired when the input value has changed.
   */
  onInputChange?: (value: string) => void;

  /**
   * Callback, fired when one of the options has been selected
   */
  onSelectionChange?: (key: Key) => void;
  /**
   * When true, activating tab key will not trigger selection change.
   * @default false
   */
  disableSelectionChangeOnTab?: boolean;
} & CommonProps &
  InputProps &
  AccessibleLabel;

const AutocompleteComponent = <T extends object>(
  props: Props<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const {
    id,
    name,
    placeholder,
    readOnly,
    disabled,
    popoverTrigger = 'input',
    defaultValue,
    children,
    items,
    value: valueProp,
    allowsCustomValue,
    loading,
    onInputChange,
    onClose,
    onOpen,
    inputActionButtons,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    renderNotFoundMessage,
    inputWidth,
    dropdownWidth: deprecatedDropdownWidth,
    inputProps,
    onSelectionChange,
    fullWidth,
    drawerActionButtons,
    selectedKey,
    defaultSelectedKey,
    dropdownProps,
    drawerInputProps,
    disableSelectionChangeOnTab = false,

    ...restProps
  } = props;

  const isXs = useMediaQuery(breakpoints.down('sm'));
  const [minWidth, setMinWidth] = useState<number | undefined>(undefined);

  // Setup filter function and state.
  const { contains } = useFilter({ sensitivity: 'base' });

  const inputRef = useRef<HTMLInputElement>(null);
  const handleRef = useForkRef(ref, inputRef);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const dropdownWidth = dropdownProps?.style?.width || deprecatedDropdownWidth;

  const handleOpenChange = (isOpen: boolean) => {
    // Create container for popper so that the ref is available sooner
    // Workaround for: https://github.com/adobe/react-spectrum/issues/5274
    if (isOpen) {
      const el = document.createElement('div');
      document.body.appendChild(el);
      popoverRef.current = el;

      if (searchContainerRef?.current) {
        setMinWidth(searchContainerRef.current.clientWidth);
      }

      onOpen?.();
    } else {
      popoverRef.current?.remove();
      popoverRef.current = null;

      onClose?.();
    }
  };

  const comboboxState = useComboBoxState({
    items,
    children,

    selectedKey,
    defaultSelectedKey,

    defaultInputValue: defaultValue,
    inputValue: valueProp,

    isDisabled: disabled,
    isReadOnly: readOnly,

    // Force to input trigger if the field is read only
    // TODO: The issue was fixed in the latest version https://github.com/adobe/react-spectrum/pull/6693 but it requires other dependencies update and it's not worth it for now
    // We can remove this workaround after the update
    menuTrigger: readOnly ? 'input' : popoverTrigger,
    defaultFilter: contains,
    allowsCustomValue,

    // Don't close popover on blur on XS screen size (focus is moved inside Drawer)
    shouldCloseOnBlur: !isXs,

    // Render empty state only on XS screen size or if there is a custom message
    allowsEmptyCollection: isXs || !!renderNotFoundMessage,
    onOpenChange: handleOpenChange,
    onInputChange,
    onSelectionChange,
  });

  const { inputProps: ariaInputProps, listBoxProps } = useComboBox(
    {
      id,
      name,
      placeholder,
      isReadOnly: readOnly,
      isDisabled: disabled,
      items,
      inputRef,
      listBoxRef: listRef,
      popoverRef: popoverRef,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      ...inputProps,
    },
    comboboxState
  );

  const { value: searchValue, ...restAriaInputProps } = ariaInputProps;

  const isOpen = comboboxState.isOpen;

  // Prevent body scroll when the drawer is open
  // This is needed to handle iOS Safari when the drawer opens and the virtual keyboard shows up
  usePreventScroll({ isDisabled: !isOpen || !isXs });

  const handleDrawerClose = () => {
    comboboxState.close();
  };

  /**
   * Tab should not trigger selection change.
   * We are using onkeydowncapture because we need to handle events at the root level and prevent the key events from reaching child elements if the key is a Tab.
   */
  const handleKeyDownCapture = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disableSelectionChangeOnTab) {
      e.key === 'Tab' && e.stopPropagation();
    }
    inputProps?.onKeyDownCapture?.(e);
  };

  return (
    <>
      <Search
        ref={handleRef}
        disableClearOnEscape
        fullWidth
        hideSearchButton
        actionButtons={inputActionButtons}
        className={clsx({ [classes.fullWidth]: fullWidth })}
        containerRef={searchContainerRef}
        css={searchCss}
        disabled={disabled}
        inputProps={{
          ...restAriaInputProps,
          onKeyDownCapture: handleKeyDownCapture,
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        style={{ width: inputWidth }}
        value={String(searchValue)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function -- Change event is passed via input props
        onChange={() => {}}
        onClear={() => {
          comboboxState.setInputValue('');
        }}
        {...restProps}
      />
      {isOpen && !isXs && (
        <Popper
          anchorEl={searchContainerRef.current}
          className={clsx(restProps.className, dropdownProps?.className)}
          container={popoverRef.current}
          disablePortal={dropdownProps?.disablePortal}
          open={isOpen}
          placement="bottom-start"
          style={{
            width: dropdownWidth,
            minWidth: dropdownWidth ? '0' : minWidth,
            maxHeight:
              dropdownProps?.style?.maxHeight ||
              constants.SEARCHABLE_LISTBOX_HEIGHT,
          }}
        >
          <ListBox
            {...listBoxProps}
            ref={listRef}
            fixedWidth
            loading={loading}
            renderNotFoundMessage={renderNotFoundMessage}
            shouldFocusOnHover={false}
            state={comboboxState}
          >
            {children}
          </ListBox>
        </Popper>
      )}
      {isOpen && isXs && (
        <Drawer
          fullHeight
          hideCloseButton
          autoFocus={false}
          className={clsx(restProps.className)}
          container={popoverRef.current}
          open={isOpen}
          returnFocusRef={inputRef}
          onClose={handleDrawerClose}
        >
          <DrawerAutocomplete
            actionButtons={drawerActionButtons}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
            inputProps={drawerInputProps}
            listBoxProps={listBoxProps}
            loading={loading}
            placeholder={placeholder}
            renderNotFoundMessage={renderNotFoundMessage}
            state={comboboxState}
            value={comboboxState.inputValue}
            onChange={comboboxState.setInputValue}
            onClose={handleDrawerClose}
          >
            {children}
          </DrawerAutocomplete>
        </Drawer>
      )}
    </>
  );
};

// forwardRef doesn't support generic parameters, so cast the result to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const Autocomplete = forwardRef(AutocompleteComponent) as <T extends object>(
  props: Props<T> & { ref?: React.Ref<HTMLInputElement> }
) => ReturnType<typeof AutocompleteComponent>;

export default Autocomplete;
