import { useQuery } from '@apollo/client';

import type {
  GetCoursesBySlugsQuery,
  GetCoursesBySlugsQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/getCoursesBySlugs';
import type {
  GetSpecializationsBySlugsQuery,
  GetSpecializationsBySlugsQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/getSpecializationsBySlugs';
import getCoursesBySlugs from 'bundles/premium-hub/queries/getCoursesBySlugs.graphql';
import getSpecializationsBySlugs from 'bundles/premium-hub/queries/getSpecializationsBySlugs.graphql';

type data = { relatedCourses?: GetCoursesBySlugsQuery; relatedS12ns?: GetSpecializationsBySlugsQuery };

/**
 * @param courseSlugs
 * @param s12nSlugs
 * @returns
 */
const useCoursesAndSpecializations = (courseSlugs: string[], s12nSlugs: string[]): data => {
  const { data: coursesData } = useQuery<GetCoursesBySlugsQuery, GetCoursesBySlugsQueryVariables>(getCoursesBySlugs, {
    context: { clientName: 'gatewayGql' },
    skip: !courseSlugs.length,
    variables: {
      slugs: courseSlugs,
    },
  });

  const { data: s12nsData } = useQuery<GetSpecializationsBySlugsQuery, GetSpecializationsBySlugsQueryVariables>(
    getSpecializationsBySlugs,
    {
      context: { clientName: 'gatewayGql' },
      skip: !s12nSlugs.length,
      variables: {
        slugs: s12nSlugs,
      },
    }
  );

  return {
    relatedCourses: coursesData,
    relatedS12ns: s12nsData,
  };
};

export default useCoursesAndSpecializations;
