import * as React from 'react';
import { useCallback } from 'react';
import Media from 'react-media';

import type UserAgent from 'js/lib/useragent';

import { Grid, breakpoints, useTheme } from '@coursera/cds-core';

import Markdown from 'bundles/cms/components/Markdown';
import type { Maybe } from 'bundles/cms/types';
import type { InfoCardFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import LoadingIcon from 'bundles/premium-hub/components/shared/LoadingIcon';

import 'css!bundles/premium-hub/components/__styles__/shared/InfoCard';

type Props = {
  userAgent?: UserAgent;
  marketingContent?: Maybe<InfoCardFragment>;
};

const InfoCard: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const renderTextBox = useCallback((marketingContent: $TSFixMe) => {
    const { title, description } = marketingContent;

    return (
      <div className="textBox">
        {title && <h3 className="infoCardTitle">{title}</h3>}
        {description && <Markdown className="description" source={description} />}
      </div>
    );
  }, []);

  const renderImgBox = useCallback(
    (visual: string) => (
      <div className="mediaBox">
        <img className="imgBox" src={visual} alt="" />
      </div>
    ),
    []
  );

  const renderVideoBox = useCallback((visual: string) => {
    if (visual) {
      return (
        <div>
          <video
            className="videoBox hideInVisualTest"
            src={visual}
            autoPlay
            muted
            loop
            aria-hidden={true}
            tabIndex={-1}
          />
        </div>
      );
    } else {
      return (
        <div className="loadingSpinner">
          <LoadingIcon color="interactive" />
        </div>
      );
    }
  }, []);

  const renderMediaBox = useCallback(
    (marketingContent: $TSFixMe) => {
      const { visual } = marketingContent;

      if (!visual) {
        return null;
      }

      const visualText = visual?.url || visual;
      return visualText.includes('mp4') ? renderVideoBox(visualText) : renderImgBox(visualText);
    },
    [renderImgBox, renderVideoBox]
  );

  const renderInfoCard = useCallback(
    (marketingContent: $TSFixMe) => {
      const { userAgent } = props;

      const { textOnLeft, description, visual } = marketingContent;

      if (description && visual) {
        return (
          <Media query={{ maxWidth: breakpoints.values.md }} defaultMatches={userAgent?.isMobileBrowser}>
            {(matches) =>
              matches ? (
                <div className="rc-InfoCard m-b-3">
                  <Grid container spacing={8} direction="column">
                    <Grid item md={12} css={{ width: '100%' }}>
                      {renderMediaBox(marketingContent)}
                    </Grid>
                    <Grid item md={12}>
                      {renderTextBox(marketingContent)}
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div className="rc-InfoCard m-b-2">
                  <Grid container spacing={24}>
                    <Grid item sm={6} lg={6}>
                      {textOnLeft ? renderTextBox(marketingContent) : renderMediaBox(marketingContent)}
                    </Grid>
                    <Grid item sm={6} lg={6}>
                      {textOnLeft ? renderMediaBox(marketingContent) : renderTextBox(marketingContent)}
                    </Grid>
                  </Grid>
                </div>
              )
            }
          </Media>
        );
      }

      return (
        <div className="rc-InfoCard m-a-2">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              {renderTextBox(marketingContent)}
            </Grid>
            <Grid item xs={12}>
              {renderMediaBox(marketingContent)}
            </Grid>
          </Grid>
        </div>
      );
    },
    [props, renderMediaBox, renderTextBox]
  );

  const { marketingContent } = props;
  if (!marketingContent) {
    return null;
  }

  return renderInfoCard(marketingContent);
};

export default InfoCard;
