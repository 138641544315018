import { useEffect, useRef, useState } from 'react';

import _ from 'lodash';

const useScrollInfo = ({ delta = 5, updateInterval = 100 }) => {
  const [isScrollingDown, setIsScrollingDown] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const lastScrollPositionRef = useRef(0);
  const [didScroll, setDidScroll] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const handleScroll = _.throttle(() => {
      let newScrollPosition;
      if (document.body.style.top) {
        newScrollPosition = -parseInt(document.body.style.top, 10);
      } else {
        // Such a hack: http://stackoverflow.com/questions/16618785/ie8-alternative-to-window-scrolly
        newScrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      }

      const scrollDifference = Math.abs(lastScrollPositionRef.current - newScrollPosition);
      const deltaLocal = delta;

      if (scrollDifference + 1 < deltaLocal) return;

      const scrollingDown = lastScrollPositionRef.current <= newScrollPosition;

      if (isMounted) {
        setIsScrollingDown(scrollingDown);
        setDidScroll(true);
        setLastScrollPosition(newScrollPosition);
        lastScrollPositionRef.current = newScrollPosition;
      }
    }, updateInterval);

    window.addEventListener('scroll', handleScroll);

    return () => {
      isMounted = false;
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [delta, updateInterval]);

  return { isScrollingDown, didScroll, lastScrollPosition };
};

export default useScrollInfo;
