import type { EoiInteraction, EoiSection } from '@coursera/event-pulse-types/index';

import type { ThirdPartyAuthService } from 'bundles/authentication/constants';
import { ENROLL } from 'bundles/enroll/utils/enrollActionParams';

export const ACTION_TYPES = {
  apply: 'apply',
  emailMeInfo: 'emailMeInfo',
  requestInfo: 'requestInfo',
  loading: 'loading',
  initial: 'initial',
  nextSteps: 'nextSteps',
  qualifyingQuestions: 'qualifyingQuestions',
};

const EMAIL_ME_INFO = 'email-me-info';
const CONTINUE_FROM_LOGIN = 'login';
const NEXT_STEPS = 'next-steps';

export const ACTION_QUERY_PARAMS = {
  EMAIL_ME_INFO,
  ENROLL, // Use the same parameter withEnrollmentModal for consistency
  NEXT_STEPS,
};

export const CONTINUE_EOI_PARAMS = {
  CONTINUE_FROM_LOGIN,
};

export const USER_SOURCE_TYPE = {
  partner: {
    id: 'partner',
    text: 'Partner',
  },
  coursera: {
    id: 'coursera',
    text: 'Coursera',
  },
};

export const PRODUCT_TYPE = {
  degree: 'degree',
  mastertrack: 'mastertrack',
  premiumHub: 'premiumHub',
};

export const THIRD_PARTY_AUTH_SERVICES_ENABLED: Array<ThirdPartyAuthService> = ['google', 'facebook'];

export const SECTION_EVENT_MAP = {
  initialSection: 'initial_section' as EoiSection,
  phoneNumberSection: 'phone_number_section' as EoiSection,
  signupSection: 'signup_section' as EoiSection,
};

export const INTERACTION_EVENTS_MAP = {
  apply: { v2TrackingName: 'apply', v3EventKey: 'open_application' as EoiInteraction },
  emailMeInfo: { v2TrackingName: 'email_me_info', v3EventKey: 'open_form' as EoiInteraction },
  requestInfo: { v2TrackingName: 'request_info', v3EventKey: 'open_form' as EoiInteraction },
  closeRegForm: { v2TrackingName: 'close_embedded_reg_form', v3EventKey: 'close_reg_form' as EoiInteraction },
  eoiOptimizationsFormSignup: { v2TrackingName: 'eoi_optimizations_form', v3EventKey: 'signup' as EoiInteraction },
  contactUs: { v2TrackingName: 'contact_us', v3EventKey: 'contact_us' as EoiInteraction },
  loginFormSubmitButton: { v2TrackingName: 'login_form_submit_button', v3EventKey: 'login' as EoiInteraction },
  signupFormSubmitButton: { v2TrackingName: 'signup_form_submit_button', v3EventKey: 'signup' as EoiInteraction },
  eoiOptimizationsFormDialogClose: {
    v2TrackingName: 'eoi_optimizations_form',
    v3EventKey: 'close_form' as EoiInteraction,
  },
  // Qualifying Questions experiment
  qualifyingQuestions: { v3EventKey: 'open_qualifying_questions_form' as EoiInteraction },
  nextSteps: { v3EventKey: 'open_next_steps' as EoiInteraction },
};

export const BROWSER_NOTIFICATION_ACTION = 'browser_notification_action';
export const REQUESTING_PERMISSION_EVENT_NAME = 'browser_notification_requesting_permission';
export const PERMISSION_SELECTED_EVENT_NAME = 'browser_notification_permission_selected';
export const NOTIFY_DEGREE_APPLICATION_REMINDER = 'degreeApplicationReminder';

export const EOI_PMF_BUTTON_LOCALSTORAGE_PREFIX = 'pmf_eoi_button';

export const EMBEDDED_DEGREE_FORM_TRACKER = {
  name: 'degrees_embedded_auth_content',
  actions: {
    OPEN: 'open',
    HANDLE_SUCCESSFUL_LOGIN: 'handle_successful_signup',
    HANDLE_SUCCESSFUL_SIGNUP: 'handle_successful_login',
  },
};

export const EOI_OPTIMIZATIONS_FORM_TRACKER = {
  name: 'eoi_optimizations_form',
  actions: {
    HANDLE_SUCCESSFUL_SIGNUP: 'handle_successful_signup',
    HANDLE_DIALOG_CLOSE: 'handle_dialog_close',
  },
};

export const EOI_LOCAL_STORAGE_SUBMISSION_TRACKER = 'eoi_submission_from_local_storage';
export const EOI_SUBMISSION_TRACKER = 'eoi_submission';

export const EOI_ACTIONS = {
  LOCAL_STORAGE_SUBMISSION_START: 'local_storage_submission_start',
  POST_START: 'post_start',
  POST_SUCCESS: 'post_success',
  POST_ERROR: 'post_error',
  VALIDATION_ERROR: 'validation_error',
  FLOW_COMPLETE_WITHIN_ONE_HOUR: 'flow_completed_within_one_hour',
};
