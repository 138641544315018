import { graphql } from '@apollo/client/react/hoc';
import flowRight from 'lodash/flowRight';

import waitFor from 'js/lib/waitFor';

// We want to completely hide the `loading` state from the underlying component, so until that happens,
// we're not going to pre-process our data downstream. In particular, this allows us to do safe destructuring
// of our data in the component itself (which would not normally work if the data hasn't arrived yet.)
const filterPropsUntilLoad =
  (operationOptions = {}) =>
  ({ data: { loading, ...restOfData }, ...restOfArgs }) => {
    if (loading) {
      return { loading };
    } else if (operationOptions.props) {
      // operationOptions.props allows us to modify props before they passed into the child component
      // http://dev.apollodata.com/react/higher-order-components.html#graphql-api
      return operationOptions.props({ data: restOfData, ...restOfArgs });
    } else {
      return { data: restOfData, ...restOfArgs };
    }
  };

// Signal to SSR that we would like it to wait on this query when performing concurrent data loading. This will maintain
// the illusion on the server just as it does on the client.
const addSsrBlockingFlag =
  (options) =>
  (...args) => ({
    ...(typeof options === 'function' ? options(...args) : options),
    ssrBlocking: true,
  });

const waitForGraphQL = (query, operationOptions, loadingComponent) => {
  return flowRight(
    graphql(query, {
      ...operationOptions,
      options: addSsrBlockingFlag(operationOptions?.options),
      props: filterPropsUntilLoad(operationOptions),
    }),
    waitFor(({ loading }) => !loading, loadingComponent)
  );
};

export default waitForGraphQL;
export { filterPropsUntilLoad };
