import type React from 'react';

import config from 'js/app/config';

import type { ButtonName, SectionName } from '@coursera/event-pulse-types';

import { useTracker } from 'bundles/page/lib/event-pulse/react';

const useTrackLinks = (buttonName: ButtonName, sectionName: SectionName) => {
  const trackV3 = useTracker();

  const handleTrackClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLAnchorElement;
    if (!target || target.nodeName !== 'A') return;

    const href = target.href;

    if (!href) return;

    trackV3('click_button', {
      button: {
        name: buttonName,
        linkURL: href,
        linkName: target.innerText,
        linkType: href.includes(config.url.base) ? 'internal' : 'external',
      },
      pageSection: {
        sectionName,
      },
    });
  };

  return handleTrackClick;
};

export default useTrackLinks;
