/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  isInteractive?: boolean;
};

const styles = {
  isInteractive: css`
    cursor: pointer;
  `,
};

function Card({ children, className, isInteractive }: Props): React.ReactElement {
  return (
    <div className={className} css={isInteractive && styles.isInteractive}>
      {children}
    </div>
  );
}

export default Card;
