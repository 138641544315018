import * as React from 'react';

import classNames from 'classnames';

import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

type Props = {
  href?: string;
  label: string;
  name: string;
  wrapperClassName: string;
  pathname: string;
  onClick?: () => void;
  openInNewWindow?: boolean;
};

const AdminRightNavLink: React.FunctionComponent<Props> = ({
  href,
  label,
  name,
  wrapperClassName,
  pathname,
  onClick,
  openInNewWindow,
}) => {
  const elClassName = classNames('rc-HeaderRightNavButton', 'c-ph-right-nav-button', wrapperClassName, {
    'c-ph-right-nav-mobile-only': false,
    'c-ph-right-nav-no-border': true,
    isLohpRebrand: true,
  });

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  if (!href) {
    return null;
  }

  return (
    <li role="none" className={elClassName}>
      <TrackedLink2
        aria-label={label}
        href={href}
        id={`${name}-link`}
        trackingName={name ? `header_right_nav_button_${name.split('-').join('_')}` : 'header_right_nav_button'}
        data={{ name }}
        onClick={handleClick}
        {...(openInNewWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        {label}
      </TrackedLink2>
    </li>
  );
};

export default AdminRightNavLink;
