/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import * as React from 'react';

const wave = keyframes`
  0%, 100% {
    height: 6px;
 }

  50% {
    height: 16px;
  }
`;

const styles = {
  root: css`
    display: inline-grid;
    width: 4px;
    animation: ${wave} 1s infinite;
  `,
  bar: css`
    border-radius: var(--cds-spacing-50);
    grid-area: 1 / 1;
  `,
  background: css`
    background-color: white;
  `,
  foreground: css`
    background-color: var(--cds-color-blue-500);
  `,
};

type Props = {
  animationDelay?: string;
  opacity?: number;
};

/**
 * Represents a single bar in the AI loading indicator. A white bar is rendered behind
 * the colored bar to ensure the color's opacity is visible on dark backgrounds.
 */
const AiLoadingIndicatorBar: React.FC<Props> = React.memo(({ opacity, animationDelay }) => {
  return (
    <span role="presentation" css={styles.root} style={{ animationDelay }}>
      <span css={[styles.bar, styles.background]} />
      <span css={[styles.bar, styles.foreground]} style={{ opacity }} />
    </span>
  );
});

export default AiLoadingIndicatorBar;
