import extend from 'lodash/extend';
import memoize from 'lodash/memoize';

import requestCountryCode from 'bundles/phoenix/template/models/requestCountryCode';
import socialPlugins from 'bundles/socialPlugins/lib';
import constants from 'bundles/third-party-auth/constants';
import type { ThirdPartyVendor } from 'bundles/third-party-auth/types';

const isRequestFromChina = requestCountryCode === 'CN';

type FacebookType = {
  init: (options: object) => void;
};

const facebook: ThirdPartyVendor = {
  enabled: !isRequestFromChina,
  // Memoized so initialization will only run once

  init: memoize(async () => {
    const FB = (await socialPlugins.facebook()) as FacebookType;

    FB.init(constants.facebook.init);

    return FB;
  }),
  getStatusOrLogin(loginParams: $TSFixMe) {
    return new Promise((resolve, reject) => {
      facebook
        .init()
        .then(function (FBID: $TSFixMe) {
          FBID.getLoginStatus((response: { status: string }) => {
            if (response.status === 'connected') {
              resolve(response);
            } else {
              FBID.login((response2: $TSFixMe) => {
                if (response2.status === 'connected') {
                  resolve(response2);
                } else if (response2.status === 'not_authorized') {
                  // The person is logged into Facebook, but not your app.
                  reject({ code: 'notAuthorized' });
                } else {
                  // The person is not logged into Facebook, so we're not sure if
                  // they are logged into this app or not.
                  reject({ code: 'unknownStatus' });
                }
              }, extend({}, constants.facebook.loginParams, loginParams));
            }
          });
        })
        .catch(() => reject({ code: 'unknownStatus' }));
    });
  },
};

// NOTE: As of 2/18/15, chrome and safari seem to block popups from the facebook auth flow if we use Q promises.
// However, if we switch to jQuery promises, everything seem to work. It's truly mind boggling.
// As a result, we init, login, and getLoginStatus methods return a jQuery promise and getStatusOrLogin returs a Q
// promise.

export default facebook;
