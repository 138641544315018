import { css } from '@emotion/react';

import { classes as inputClasses } from '@core/forms/Input/getInputCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('AutocompleteSearch', [
  'fullWidth',
]);

const styles = css`
  display: inline-flex;

  .${inputClasses.root} {
    min-width: 0;
  }

  &.${classes.fullWidth} {
    width: 100%;
  }
`;

export default styles;
