import { css } from '@emotion/react';

const styles = css`
  padding: var(--popover-padding);
  overflow: auto;
  flex: 1;

  ul& {
    list-style: none;
    margin: 0;
  }
`;

export default styles;
