import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('ChipGroup', [
  'chipGroup',
  'groupWrapInline',
  'label',
  'inlineLabel',
  'group',
]);

const getChipGroupCss = css`
  .${classes.chipGroup} {
    min-height: 32px;
    gap: var(--cds-spacing-200) var(--cds-spacing-100);

    &.${classes.groupWrapInline} {
      display: inline-block;
      margin-bottom: calc(var(--cds-spacing-100) * -1);

      .${classes.group} {
        display: inline;
        margin-bottom: calc(var(--cds-spacing-100) * -1);

        button {
          margin-bottom: var(--cds-spacing-100);
          margin-right: var(--cds-spacing-50);
        }
      }
    }
  }

  .${classes.inlineLabel} {
    display: inline;
    line-height: 32px;
    margin-bottom: var(--cds-spacing-100);
    margin-right: var(--cds-spacing-100);
    vertical-align: top;
  }

  .${classes.group} {
    display: flex;
    flex-wrap: wrap;
    gap: var(--cds-spacing-100) var(--cds-spacing-50);
  }
`;

export default getChipGroupCss;
