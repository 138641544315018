import _tCountries from 'i18n!js/json/nls/countries';

import countryList from 'js/json/countries';

export default countryList.map((nameAndCode: $TSFixMe) => {
  return {
    get name() {
      return _tCountries(nameAndCode.name);
    },
    code: nameAndCode.code,
  };
});
