import MuiPopper from '@material-ui/core/Popper';
import type { PopperProps } from '@material-ui/core/Popper';

import styles, { classes } from './styles/popperCss';

/**
 * Popper component that looks like a popover.
 * Key difference from Popover it doesn't block the screen scroll. I doesn't aria-hide other content on the page.
 * @param props
 */
const Popper = (props: PopperProps) => {
  const { children, style, ...restProps } = props;

  return (
    <MuiPopper {...restProps} css={styles} open={true}>
      <div className={classes.paper} style={style}>
        {children}
      </div>
    </MuiPopper>
  );
};

export default Popper;
