import { css } from '@emotion/react';

import { classes as inputClasses } from '@core/forms/Input/getInputCss';
import { classes as selectClasses } from '@core/forms/Select/getSelectCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('silentSelect', [
  'label',
  'stackedLabel',
  'container',
  'input',
  'filled',
]);

const getSilentSelectFieldCss = css`
  max-width: 400px;
  display: inline-flex;
  flex-direction: column;

  .${classes.filled} {
    color: var(--cds-color-neutral-primary);
  }

  .${classes.stackedLabel} {
    margin-bottom: var(--cds-spacing-100);
  }

  .${classes.input} {
    background: transparent;

    .${selectClasses.select} {
      padding-left: var(--cds-spacing-100);
      padding-right: 36px;
    }

    .${selectClasses.icon} {
      left: auto;
      right: var(--cds-spacing-100);
    }

    .${inputClasses.notchedOutline} {
      border-width: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover {
      .${inputClasses.notchedOutline} {
        border-bottom-width: 2px;
      }
    }

    &.${selectClasses.opened} {
      .${inputClasses.notchedOutline} {
        border-bottom-width: 2px;
        border-color: var(--cds-color-interactive-primary-hover);
      }
    }

    &.${inputClasses.focused} {
      &.${selectClasses.focusVisible} {
        .${inputClasses.notchedOutline} {
          border-width: 0;
        }
      }
    }
  }
`;

export default getSilentSelectFieldCss;
