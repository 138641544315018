import * as React from 'react';

import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import type { PageHeaderContextType } from 'bundles/page-header/contexts/PageHeaderContext';

const PageHeaderProvider = ({
  children,
  isSimplifiedPageHeader,
  subNavigationLinks,
  hasUnifiedMetaNav,
  mobileSubNavigationLinks,
  cPlusEntryPointsData,
}: PageHeaderContextType & { children: React.ReactNode }) => {
  return (
    <PageHeaderContext.Provider
      value={{
        isSimplifiedPageHeader,
        subNavigationLinks,
        hasUnifiedMetaNav,
        mobileSubNavigationLinks,
        cPlusEntryPointsData,
      }}
    >
      {children}
    </PageHeaderContext.Provider>
  );
};

export default PageHeaderProvider;
