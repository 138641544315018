import * as React from 'react';

import { branch, compose, withProps } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import connectToStores from 'js/lib/connectToStores';
import createLoadableComponent from 'js/lib/createLoadableComponent';
import isMobileApp from 'js/lib/isMobileApp';
import user from 'js/lib/user';
import type UserAgentInfo from 'js/lib/useragent';

import { shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import ChatLoader from 'bundles/common/components/ChatLoader';
import GlobalHelpButton from 'bundles/common/components/GlobalHelpButton';
import type SwitcherSelectionsType from 'bundles/naptimejs/resources/programSwitcherSelections.v1';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import DesktopHeader from 'bundles/page-header/components/desktop/DesktopHeader';
import MobileHeader from 'bundles/page-header/components/mobile/MobileHeader';
import type { NavButtonType } from 'bundles/page-header/components/mobile/constants';
import useChatWidgetSelector from 'bundles/page-header/components/useChatWidgetSelector';
import { useCoachIntroPendo } from 'bundles/page-header/components/useCoachIntroPendo';
import useNewCPlusEntryPointsEligibilityCheck from 'bundles/page-header/hooks/useNewCPlusEntryPointsEligibilityCheck';
import useSimplifiedNav from 'bundles/page-header/hooks/useSimplifiedNav';
import PageHeaderProvider from 'bundles/page-header/providers/PageHeaderProvider';
import type { GetS12nCertificateBannerProps, ProductDiscountPromoBannerProps } from 'bundles/page/types/Program';
import withHasPageLoaded from 'bundles/page/utils/withHasPageLoaded';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';
import useMarketingConsentData from 'bundles/user-consent/hooks/useMarketingConsentData';
import { useShowTermsOfUseModal } from 'bundles/user-consent/hooks/useShowTermsOfUseModal';

const AuthenticationOption = createLoadableComponent(
  () => import('bundles/user-account/components/AuthenticationOption')
);
const TermsOfServiceModal = createLoadableComponent(
  () => import('bundles/user-consent/components/TermsOfServiceModal')
);

// The breakpoint is defined here https://github.com/webedx-spark/web/blob/f365f369e565942f9990304b2d3865b32468bb5c/static/bundles/page-header/components/__styles__/PageHeader.styl#L303
// TODO: update to use CDS breakpoints
export const PAGE_HEADER_MOBILE_BREAKPOINT = 1060;

export type Course = {
  id: string;
  slug: string;
  name?: string;
  brandingImageUrl?: string;
};

export type InputProps = {
  toggleMobileMenu?: () => void;
  isScrollable?: boolean;
  isSearchPage?: boolean;
  isEnterprise?: boolean;
  productDiscountPromoBannerProps?: ProductDiscountPromoBannerProps;
  getS12nCertificateBannerProps?: GetS12nCertificateBannerProps;
  userAgent?: UserAgentInfo;
  showEnterpriseLogo?: boolean;
  showEnterpriseReturnToProgramBanner?: boolean;
  hideRightNav?: boolean;
  hideSearch?: boolean;
  hideMetaNav?: boolean;
  /**
   * When the shouldSkipOptionalExternalDataFetch flag is enabled, component skips external data queries
   * that are not essential to rendering the header. Skipped calls currently include
   * 1) programs + degrees data
   * 2) domain data
   * */
  shouldSkipOptionalExternalDataFetch?: boolean;
  preventModalClose?: boolean;
  preventRegister?: boolean;
  hideAuth?: boolean;
  switcherSelections?: SwitcherSelectionsType;
  logoWrapper?: string;
  isPageWithSecondaryCta?: boolean;
  isDegreesPage?: boolean;
  programId?: string;
  itemId?: string;
  partnerId?: number;
  showShoppingCart?: boolean;
  /**
   * When the hideDropdownOptions flag is enabled, all dropdown options are hidden except the "Log out" link
   * The use case is to remove distractions on important pages like checkout
   */
  hideDropdownOptions?: boolean;
  showGDPRBanner?: boolean;
  hideNotificationCenter?: boolean;
  hideEnterprise?: boolean;
  hideMembershipSwitcher?: boolean;
  hasCatalogButton?: boolean;
  course?: Course;
  showAdminLinks?: boolean;
  onClickHelp?: () => void;
  isHeaderFixedTop?: boolean;
  mainSearchInputSelector?: string;
  thirdPartyOrganizationId?: string;
  affiliateElement?: React.ReactElement | null;
  disableHeaderLogoUserInteraction?: boolean;
  // On default we use a lazy loading version of the mega-menu, for SEO reasons certain apps may want to pass in one that loads in SSR
  logoQueryParams?: Record<string, string | undefined>;
  enableCourseraHeaderLogoOnly?: boolean;
  showExploreCatalog?: boolean;
  showAccountDropdown?: boolean;
  injectedSearchBar?: JSX.Element | null;
  isOrgHome?: boolean;
  hideLogIn?: boolean;
  hideMobileNavLinks?: boolean;
  showLanguagesDropdown?: boolean;
  authModalRedirectOnCloseUrl?: string;
  utilizeWebRedirectOnAuthModalClose?: boolean;
  subNavigationLinks?: React.ReactNode;
  mobileSubNavigationLinks?: NavButtonType[];
  elRef?: React.RefObject<HTMLElement>;
};

type Props = InputProps & {
  userAgent: UserAgentInfo;
  showAuthModal?: boolean;
  router: InjectedRouter;
  hasPageLoaded?: boolean;
  appName: string;
  shouldShowCCPAMarketingConsent?: boolean;
  elRef?: React.RefObject<HTMLSpanElement>;
};

export const PageHeader = (props: Props) => {
  const {
    userAgent,
    showAuthModal,
    hideAuth,
    preventModalClose,
    preventRegister,
    isEnterprise,
    programId,
    router,
    isScrollable,
    isSearchPage,
    productDiscountPromoBannerProps,
    getS12nCertificateBannerProps,
    showEnterpriseLogo,
    hideSearch,
    hideMetaNav,
    shouldSkipOptionalExternalDataFetch,
    switcherSelections,
    toggleMobileMenu,
    hideRightNav,
    hasPageLoaded,
    showAccountDropdown,
    thirdPartyOrganizationId,
    hideMobileNavLinks,
    disableHeaderLogoUserInteraction,
    appName,
    injectedSearchBar,
    authModalRedirectOnCloseUrl,
    utilizeWebRedirectOnAuthModalClose,
    subNavigationLinks,
    mobileSubNavigationLinks,
    shouldShowCCPAMarketingConsent,
  } = props;

  const mobileHeaderProps = {
    userAgent,
    isSearchPage,
    isScrollable,
    hideMetaNav,
    toggleMobileCourseMenu: toggleMobileMenu,
    productDiscountPromoBannerProps,
    getS12nCertificateBannerProps,
    showEnterpriseLogo,
    hideNav: hideRightNav,
    hideSearch,
    shouldSkipOptionalExternalDataFetch,
    switcherSelections,
    showAccountDropdown,
    thirdPartyOrganizationId,
    isEnterprise,
    programId,
    hideMobileNavLinks,
    disableHeaderLogoUserInteraction,
    injectedSearchBar,
  };
  const isSimplifiedPageHeader = useSimplifiedNav();
  const shouldLoadAuthModal = !hideAuth && (showAuthModal || (hasPageLoaded && !user.isAuthenticatedUser()));
  const {
    showTermsOfUseModal,
    setShowTermsOfUseModal,
    modalType,
    shouldShowModal: shouldLoadTermsOfUseModal,
  } = useShowTermsOfUseModal(shouldShowCCPAMarketingConsent);

  const chatWidgetToRender = useChatWidgetSelector();
  const { shouldShowCoachGuide, launchPendoCoachGuide } = useCoachIntroPendo(appName);

  const cPlusEntryPointsData = useNewCPlusEntryPointsEligibilityCheck();

  if (shouldShowCoachGuide) {
    launchPendoCoachGuide();
  }

  // We don't want to show header if a page is being opened in a mobile web view. Mobile apps have their own header.
  if (isMobileApp.get()) {
    return null;
  }

  return (
    /*
      TODO (Connor): There is a selector naming discrepency where the rc-PageHeader selector is used in DesktopHeaderControls,
      this is an important selector as it's overridden in many places. We need to come up with a migration plan to properly name
      and style the header components.
    */
    <PageHeaderProvider
      isSimplifiedPageHeader={isSimplifiedPageHeader}
      hasUnifiedMetaNav={isSimplifiedPageHeader && !hideMetaNav}
      subNavigationLinks={subNavigationLinks}
      mobileSubNavigationLinks={mobileSubNavigationLinks}
      cPlusEntryPointsData={cPlusEntryPointsData}
    >
      <span ref={props.elRef} className="rc-PageHeaderWrapper" data-testid="page-header-wrapper">
        <DesktopHeader
          {...props}
          hideMetaNav={isEnterprise || hideMetaNav}
          hideEnterprise={props.hideEnterprise || userAgent.isOculusQuest}
        />
        <MobileHeader {...mobileHeaderProps} />

        {shouldLoadAuthModal && (
          <AuthenticationOption
            redirectOnCloseUrl={authModalRedirectOnCloseUrl}
            utilizeWebRedirectOnClose={utilizeWebRedirectOnAuthModalClose}
            preventModalClose={preventModalClose}
            preventRegister={preventRegister}
            query={router && router.location.query}
            key="authenticationOption"
            isEnterprise={isEnterprise}
            programId={programId}
          />
        )}
        {shouldLoadTermsOfUseModal && (
          <TermsOfServiceModal
            setShowTermsOfUseModal={setShowTermsOfUseModal}
            showTermsOfUseModal={showTermsOfUseModal}
            modalType={modalType}
          />
        )}
        {chatWidgetToRender === 'BoostChat' && <ChatLoader />}
        {chatWidgetToRender === 'GlobalHelp' && <GlobalHelpButton />}
      </span>
    </PageHeaderProvider>
  );
};

export default compose<Props, InputProps>(
  connectToStores<Props, InputProps>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    userAgent: ApplicationStore.getUserAgent(),
    appName: ApplicationStore.appName,
  })),
  connectToRouter((router) => ({
    showAuthModal: router.location.query.authMode,
    router,
  })),
  withHasPageLoaded,
  branch(
    () => user.isAuthenticatedUser() && shouldShowLegalConsolidationAuthFlow(),
    withProps((props) => {
      const { shouldShowCCPAMarketingConsent } = useMarketingConsentData();
      return { ...props, shouldShowCCPAMarketingConsent };
    })
  )
)(PageHeader);
