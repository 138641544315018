/**
 * Private module reserved for @coursera/cds-core package.
 */

import { createLargeSvgIcon } from '@core/utils';

import { classes } from './getRadioInputCss';

export default createLargeSvgIcon('RadioCheckedIcon', {
  large: (
    <>
      <circle
        className={classes.checkedIconBg}
        cx="12"
        cy="12"
        fill="white"
        r="11.5"
        stroke="currentColor"
      />

      <circle cx="12" cy="12" fill="currentColor" r="7" />
    </>
  ),
});
