import * as React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';

import LinkButton from 'bundles/authentication/shared/components/authentication-modal/LinkButton';

import _t from 'i18n!nls/authentication';

export const errorMessageFactory = () => {
  return {
    geoRestriction: _t('Access from your country/region is restricted.'),
    invalidCredential: (link?: () => void) => (
      <FormattedMessage
        message={_t("Sorry, we don't recognize that username or password. You can try again or {reset}")}
        reset={link ? <LinkButton onClick={link}>{_t('reset your password')}</LinkButton> : _t('reset your password')}
      />
    ),

    invalidRequest: _t("We don't recognize that username or password. You can try again or use another login option."),
    invalidWebToken: _t('Your invitation is invalid.'),
    missingReCaptcha: _t('reCaptcha must be checked'),
    recaptchaFailed: (
      <FormattedHTMLMessage
        message={_t(
          'There was a reCAPTCHA failure, which means you either failed the challenge or there was an issue loading reCAPTCHA. Please try again and visit <a href="https://support.google.com/recaptcha">Google\'s reCAPTCHA support page</a> for more troubleshooting tips.'
        )}
      />
    ),
    twoFactorInvalid: _t('Wrong two-factor verification code or password. Please try again!'),
    twoFactorRequired: _t('Please enter your two-factor verification code generated by your mobile app.'),
    tooManyRequests: _t("You've made too many login attempts. Please try again in a few minutes."),
  };
};

// Constants
export const NEEDS_2FA_ERRORS = ['twoFactorRequired', 'twoFactorInvalid'];

export const HANDLED_ERRORS = [
  'geoRestriction',
  'invalidCredential',
  'invalidRequest',
  'invalidWebToken',
  'recaptchaFailed',
  'tooManyRequests',
  ...NEEDS_2FA_ERRORS,
];
