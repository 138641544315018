import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import Root from './Popover';

export { usePopoverContext } from './context';

export type { Props as PopoverProps } from './Popover';

export type { Props as PopoverHeaderProps } from './Header';
export type { Props as PopoverBodyProps } from './Body';
export type { Props as PopoverFooterProps } from './Body';

type RootType = typeof Root;

export interface PopoverType extends RootType {
  /**
   * The Popover heading component [Optional]
   *
   * See [Props](__storybookUrl__/components-utility-popover--default#props)
   *
   * @see Popover
   * @see Popover.Body
   * @see Popover.Footer
   */
  Header: typeof Header;

  /**
   * The Popover body component [Required]
   *
   * See [Props](__storybookUrl__/components-utility-popover--default#props)
   *
   * @see Popover
   * @see Popover.Header
   * @see Popover.Footer
   */
  Body: typeof Body;

  /**
   * The Popover footer component [Optional]
   *
   * See [Props](__storybookUrl__/components-utility-popover--default#props)
   *
   * @see Popover
   * @see Popover.Header
   * @see Popover.Body
   */
  Footer: typeof Footer;
}

/**
 * The popover utility is a responsive container that can be used to display a menu list of menu options or a custom component.
 *
 * See [Props](__storybookUrl__/components-utility-popover--default#props)
 *
 *
 *
 * @see Popover.Header
 * @see Popover.Body
 * @see Popover.Footer
 *
 */
const Popover: PopoverType = Object.assign(Root, {
  Header,
  Body,
  Footer,
});

export { Popover };
