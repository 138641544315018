import { css } from '@emotion/react';

import * as React from 'react';

import { Grid, PageGridContainer } from '@coursera/cds-core';

import { FooterWrapper } from 'bundles/page-footer/components/FooterWrapper';
import Copyright from 'bundles/page-footer/components/global-footer/common/Copyright';
import type { ColumnHeaderProps } from 'bundles/page-footer/components/global-footer/common/LinkColumn';
import LinkColumn from 'bundles/page-footer/components/global-footer/common/LinkColumn';
import LogoBadgeColumn from 'bundles/page-footer/components/global-footer/common/LogoBadgeColumn';
import MoreLinkColumn from 'bundles/page-footer/components/global-footer/common/MoreLinkColumn';
import SocialMediaLinks from 'bundles/page-footer/components/global-footer/common/SocialMediaLinks';
import getFooterLinks from 'bundles/page-footer/components/global-footer/data/footerLinks';
import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';

import _t from 'i18n!nls/page';

import 'css!bundles/page-footer/components/global-footer/__styles__/GlobalFooter';

type PropsFromCaller = {
  isStickyBarPresent?: boolean;
  hideMobileAppLogoHeader?: boolean;
  hideTopBorder?: boolean;
};

type Props = PropsFromCaller & PropsFromWithUserAgent;

const styles = {
  bottomMobile: css`
    margin: auto;
  `,
  bottomDesktop: css`
    padding-top: 15px;
    padding-bottom: 50px;
    margin: 0;
    border-top: 1px solid #e4e4e4;
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
};

export const CourseraLinkColumn: React.FC<ColumnHeaderProps> = ({ columnHeader, extraClassName }) => {
  const links = getFooterLinks();

  return (
    <LinkColumn
      columnHeader={columnHeader}
      header="Coursera"
      links={[
        links.about,
        links.whatWeOffer,
        links.leadership,
        links.careers,
        links.catalog,
        links.courseraPlus,
        links.certificates,
        links.mastertrack,
        links.degrees,
        links.enterprise,
        links.government,
        links.campus,
        links.becomeAPartner,
        links.socialImpact,
      ]}
      extraClassName={extraClassName}
    />
  );
};

export const CommunityLinkColumn: React.FC<ColumnHeaderProps> = ({ columnHeader, extraClassName }) => {
  const links = getFooterLinks();

  return (
    <LinkColumn
      columnHeader={columnHeader}
      header={_t('Community')}
      links={[
        links.learners,
        links.partners,
        links.betaTesters,
        links.blog,
        links.podcast,
        links.techBlog,
        links.teachingCenter,
      ]}
      extraClassName={extraClassName}
    />
  );
};

type DesktopFooterProps = { hideMobileAppLogoHeader?: boolean } & ColumnHeaderProps;

export function DesktopFooter({ hideMobileAppLogoHeader, columnHeader }: DesktopFooterProps) {
  const extraClassName = 'lohp-rebrand';
  return (
    <PageGridContainer>
      <Grid item sm={3}>
        <CourseraLinkColumn columnHeader={columnHeader} extraClassName={extraClassName} />
      </Grid>
      <Grid item sm={3}>
        <CommunityLinkColumn columnHeader={columnHeader} extraClassName={extraClassName} />
      </Grid>
      <Grid item sm={3}>
        <MoreLinkColumn columnHeader={columnHeader} extraClassName={extraClassName} />
      </Grid>
      <Grid item sm={3}>
        <LogoBadgeColumn
          badgeColumn
          hideDescription
          header={!hideMobileAppLogoHeader ? _t('Mobile App') : undefined}
          columnHeader={columnHeader}
          extraClassName={extraClassName}
        />
      </Grid>

      <Grid container css={styles.bottomDesktop}>
        <Copyright />
        <SocialMediaLinks />
      </Grid>
    </PageGridContainer>
  );
}

const MobileFooter: React.FC = () => {
  const extraClassName = 'lohp-rebrand';
  return (
    <PageGridContainer>
      <Grid item sm={6}>
        <CourseraLinkColumn extraClassName={extraClassName} />
      </Grid>
      <Grid item sm={6}>
        <CommunityLinkColumn extraClassName={extraClassName} />
      </Grid>
      <Grid item sm={12}>
        <MoreLinkColumn extraClassName={extraClassName} />
      </Grid>
      <LogoBadgeColumn extraClassName={extraClassName} hideDescription isMobile header={_t('Learn Anywhere')} />
      <Grid css={styles.bottomMobile}>
        <SocialMediaLinks leftAligned header={_t('Follow Us')} />
        <Copyright marginTop marginBottom />
      </Grid>
    </PageGridContainer>
  );
};

export const GlobalFooter: React.FC<Props> = ({
  isStickyBarPresent,
  hideMobileAppLogoHeader = false,
  hideTopBorder,
}) => {
  const classNamesFooter = `rc-GlobalFooter rc-PageFooter ${isStickyBarPresent ? 'sticky-bar' : ''} ${
    hideTopBorder ? 'hide-top-border' : ''
  } lohp-rebrand`;

  return (
    <FooterWrapper
      footerComponentMobile={<MobileFooter />}
      footerComponentDesktop={<DesktopFooter hideMobileAppLogoHeader={hideMobileAppLogoHeader} columnHeader="h3" />}
      extraClassNames={classNamesFooter}
    />
  );
};

export default GlobalFooter;
