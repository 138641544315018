import type { IconProps } from '@coursera/cds-common';
import {
  SuccessOutlineIcon,
  WarningOutlineIcon,
  ErrorIcon,
  InfoOutlineIcon,
} from '@coursera/cds-icons';

import type { NotificationSeverity } from '@core/notifications/types';

import { classes } from './inlineNotificationCss';

const InlineSeverityIcon = ({
  severity,
}: {
  severity: NotificationSeverity;
}) => {
  const commonProps: IconProps = {
    size: 'small',
    display: 'block',
    className: classes.inlineSeverityIcon,
  };

  switch (severity) {
    case 'success':
      return <SuccessOutlineIcon color="success" {...commonProps} />;
    case 'warning':
      return <WarningOutlineIcon color="warning" {...commonProps} />;
    case 'error':
      return <ErrorIcon color="error" {...commonProps} />;
    case 'information':
    default:
      return <InfoOutlineIcon color="interactive" {...commonProps} />;
  }
};

export default InlineSeverityIcon;
