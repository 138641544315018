/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useEffect, useMemo, useRef, useState } from 'react';

import throttle from 'lodash/throttle';

import { useRetracked } from 'js/lib/retracked';
import { useLocation, useNavigate } from 'js/lib/useRouter';

import { Tab, TabContext, Tabs, breakpoints, useMediaQuery } from '@coursera/cds-core';

import { scrollToAnchor } from 'bundles/premium-hub/components/degrees/DegreeHubScrollAnchor';
import Container from 'bundles/premium-hub/components/shared/Container';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import { HubPages } from 'bundles/premium-hub/constants';
import { getInfoFromPathname, setLastTabLocalStorage } from 'bundles/premium-hub/hooks/useLearnBrowse';

import _t from 'i18n!nls/premium-hub';

const NAV_CONTAINER_HEIGHT_PX = 72;

type NavItemId = ReturnType<typeof getNavigationItems>[number]['id'];
export type NavigationItem = { id: 'overview' | 'browse'; name: string; isIndexPage: boolean };

export const getNavigationItems = (pageType?: PremiumHubPageType): Array<NavigationItem> => [
  {
    id: 'overview',
    name: pageType === HubPages.MainHubPage ? _t('Learn about degrees') : _t('Overview'),
    isIndexPage: true,
  },
  {
    id: 'browse',
    name: pageType === HubPages.MainHubPage ? _t('Find a degree') : _t('Explore degrees'),
    isIndexPage: false,
  },
];

const styles = {
  navigationContainer: (isStuck: boolean) => css`
    position: sticky;
    top: 0;
    left: 0;
    background: var(--cds-color-neutral-primary-invert);
    margin: 0 0 var(--cds-spacing-400);
    z-index: 4;
    transition: all 0.3s;

    /* We only want to show the box-shadow when it's in the fixed/sticky position */
    box-shadow: ${isStuck ? '0 8px 12px -12px rgba(0, 0, 0, 0.16)' : 'none'};

    ${breakpoints.up('md')} {
      top: ${NAV_CONTAINER_HEIGHT_PX}px;
      margin: var(--cds-spacing-200) 0 var(--cds-spacing-400);
    }
  `,

  tabs: css`
    border-color: var(--cds-color-neutral-stroke-primary-weak);
    padding: var(--cds-spacing-100) 0 0;
  `,

  navElement: css`
    &:hover,
    &:visited,
    &:focus {
      border: none;
      color: none;
      outline: none;
    }
  `,
};

type InlineNavigationProps = {
  pageType?: PremiumHubPageType;
};
const InlineNavigation = ({ pageType }: InlineNavigationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const trackComponent = useRetracked();
  const [position, setPosition] = useState<string>('static');
  const navContainerRef = useRef<HTMLDivElement | null>(null);
  const activeNavItemRef = useRef<HTMLDivElement | null>(null);
  const navItems = getNavigationItems(pageType);

  const { inBrowse, urlParts } = getInfoFromPathname(location.pathname);
  const isDesktopView = useMediaQuery(breakpoints.up('md'));
  const currentNavItem = inBrowse ? navItems[1] : navItems[0];

  const menuItems = useMemo(() => {
    const handleClick = (navigationItemId: NavItemId, link: string, isCurrentElement: boolean) => {
      setLastTabLocalStorage(location.pathname, navigationItemId);
      trackComponent({
        trackingName: `tab_${navigationItemId}`,
        trackingData: {},
        action: 'click',
      });
      if (isCurrentElement) return;
      scrollToAnchor({ offset: -30, smooth: true });
      navigate(link);
    };

    return navItems.map((navigationItem) => {
      const parentUrl = urlParts.filter((part) => part !== 'browse').join('/');
      const linkHref = navigationItem.isIndexPage ? parentUrl : `${parentUrl}/${navigationItem.id}`;
      const isCurrentElement = navigationItem?.id === currentNavItem?.id;
      const showSelected = isCurrentElement;

      return (
        <Tab
          key={navigationItem.id}
          css={styles.navElement}
          data-testid="nav-element"
          ref={(e) => {
            if (showSelected) {
              activeNavItemRef.current = e;
            }
          }}
          label={navigationItem?.name}
          value={navigationItem?.id}
          aria-label={navigationItem?.name}
          onClick={() => handleClick(navigationItem.id, linkHref, isCurrentElement)}
        />
      );
    });
  }, [navItems, location.pathname, trackComponent, navigate, urlParts, currentNavItem?.id]);

  useEffect(() => {
    const onScrollHandler = throttle(() => {
      const refElementTop = navContainerRef?.current?.getBoundingClientRect().top ?? 0;
      const scrollYTrack = window.scrollY;
      if (scrollYTrack > 0 && refElementTop < 80) {
        setPosition('sticky');
      } else {
        setPosition('static');
      }
    }, 50);

    window.addEventListener('scroll', onScrollHandler);
    return () => window.removeEventListener('scroll', onScrollHandler);
  }, [isDesktopView, urlParts]);

  return (
    <div css={styles.navigationContainer(position === 'sticky')} ref={navContainerRef}>
      <Container data-testid="navigation">
        <TabContext value={currentNavItem?.id}>
          <Tabs css={styles.tabs}>{menuItems}</Tabs>
        </TabContext>
      </Container>
    </div>
  );
};

export default InlineNavigation;
