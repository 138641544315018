/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { useLocation } from 'js/lib/useRouter';

import ErrorBoundaryWithTagging from 'bundles/common/components/ErrorBoundaryWithTagging';
import DegreesDiscoveryExperiments from 'bundles/epic/clients/DegreesDiscovery';
import { addDriftChatWidget } from 'bundles/expression-of-interest/utils/driftUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { Article } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import DegreeEoiButton from 'bundles/premium-hub/components/degrees/DegreeEoiButton';
import DegreeHubScrollAnchor, { scrollToAnchor } from 'bundles/premium-hub/components/degrees/DegreeHubScrollAnchor';
import DegreesCategoryCards from 'bundles/premium-hub/components/degrees/DegreesCategoryCards';
import DegreesHubBanner from 'bundles/premium-hub/components/degrees/DegreesHubBanner';
import HeroBanner from 'bundles/premium-hub/components/degrees/HeroBanner';
import HeroBannerRedesign from 'bundles/premium-hub/components/degrees/HeroBannerRedesign';
import useMultiSelectDegreeFilterStateManager from 'bundles/premium-hub/components/degrees/useMultiSelectDegreeFilterStateManager';
import ArticlesSection from 'bundles/premium-hub/components/shared/ArticlesSection';
import Container from 'bundles/premium-hub/components/shared/Container';
import DegreeCarousel from 'bundles/premium-hub/components/shared/DegreeCarousel';
import EoiModule from 'bundles/premium-hub/components/shared/EoiModule';
import Faqs from 'bundles/premium-hub/components/shared/Faqs';
import HubBanner from 'bundles/premium-hub/components/shared/HubBanner';
import IndustryRelevanceModuleContainer from 'bundles/premium-hub/components/shared/IndustryRelevanceModuleContainer';
import InformationalSectionContainer from 'bundles/premium-hub/components/shared/InformationalSectionContainer';
import InformationalSectionContent from 'bundles/premium-hub/components/shared/InformationalSectionContent';
import InformationalSectionSidebar from 'bundles/premium-hub/components/shared/InformationalSectionSidebar';
import InformationalSectionSubBenefits from 'bundles/premium-hub/components/shared/InformationalSectionSubBenefits';
import InlineNavigation from 'bundles/premium-hub/components/shared/InlineNavigation';
import ProductVariantCards from 'bundles/premium-hub/components/shared/ProductVariantCards';
import TestimonialsSection from 'bundles/premium-hub/components/shared/TestimonialsSection';
import TopDegreeListSection from 'bundles/premium-hub/components/shared/TopDegreeListSection';
import UnifiedInstructorsListRedesign from 'bundles/premium-hub/components/shared/UnifiedInstructorsListRedesign';
import ValuePropositions from 'bundles/premium-hub/components/shared/ValuePropositions';
import WebinarsSection from 'bundles/premium-hub/components/shared/WebinarsSection';
import type { HubModuleValue } from 'bundles/premium-hub/constants';
import { HUB_MODULES, HubPages, ProductType } from 'bundles/premium-hub/constants';
import { GetModuleSectionEventTrackingDataContext } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { PropsFromHubPageContent } from 'bundles/premium-hub/types';
import { getSurveyProductType } from 'bundles/premium-hub/utils';
import { convertDegreeCategoryFieldsToPPCDegreeCategory } from 'bundles/premium-hub/utils/dataTransformationUtils';
import CourseraMetatags from 'bundles/seo/components/CourseraMetatags';

import _t from 'i18n!nls/premium-hub';

type Props = PropsFromHubPageContent & {
  showDegreeEoiCtaInHubBanner?: boolean;
};

const getModuleOrderBasedOnExperiment = (moduleOrder: HubModuleValue[]) => {
  const heroBannerUnificationEpic = DegreesDiscoveryExperiments.get('heroBannerUnification');
  const heroBannerUnificationEpicIsControl = heroBannerUnificationEpic === 'control';
  if (heroBannerUnificationEpicIsControl) return moduleOrder;
  const moduleOrderToUse = [...moduleOrder];
  if (moduleOrder[0] === HUB_MODULES.degreesHubBanner || moduleOrder[0] === HUB_MODULES.hubBanner) {
    moduleOrderToUse[0] = HUB_MODULES.heroBannerRedesign;
  }
  return moduleOrderToUse;
};

const OverviewPageContent = (props: Props) => {
  const location = useLocation();
  const {
    isInternationalSubdomain,
    i18nSupportedLocales,
    pageContent,
    moduleOrder,
    domains,
    degreeCategories,
    degreeListWithMetadata,
    shouldShowDriftChat,
    categoryData,
    currentPageType,
    informationSectionContent,
    webinars,
    industryRelevance,
    displayLearnBrowseTabs,
    showDegreeEoiCtaInHubBanner,
  } = props;

  const { degreeFilterState, filteredDegreeList } = useMultiSelectDegreeFilterStateManager({
    degreeListWithMetadata,
    pathname: location.pathname,
    domains,
    currentPageType,
  });

  useEffect(() => {
    if (shouldShowDriftChat) {
      addDriftChatWidget();
    }
  }, [shouldShowDriftChat]);

  if (!pageContent) return null;

  const {
    seoInformation,
    slug,
    pageHeading,
    pageSubheading,
    expressionOfInterestContainer,
    productVariantCardsCollection,
    categoryLinksCollection,
    learnMoreInfoHeading,
    learnMoreValuePropositionsCollection,
    visualConsistency,
    faqSectionHeader,
    faqsCollection,
    coreHubHeroValuePropContent,
    relatedArticles,
  } = pageContent;

  const moduleOrderToUse = getModuleOrderBasedOnExperiment(moduleOrder);

  const getModule = (module: HubModuleValue, index: number) => {
    switch (module) {
      case HUB_MODULES.degreesHubBanner:
        return (
          <>
            <DegreesHubBanner
              slug={slug ?? ''}
              pageHeading={pageHeading}
              pageSubheading={pageSubheading}
              currentHubPage={HubPages.MainHubPage}
              productId="degrees"
              surveyProductType="GenericPage"
              cta={
                showDegreeEoiCtaInHubBanner &&
                slug && (
                  <DegreeEoiButton
                    currentPageType={currentPageType}
                    slug={slug}
                    productId="degrees"
                    surveyProductType="GenericPage"
                  />
                )
              }
            />
            <DegreeHubScrollAnchor />
            {displayLearnBrowseTabs && <InlineNavigation pageType={currentPageType} />}
          </>
        );

      case HUB_MODULES.hubBanner:
        return (
          <>
            <HubBanner
              slug={slug}
              pageHeading={pageHeading}
              pageSubheading={pageSubheading}
              categoryName={categoryData?.name ?? ''}
              currentHubPage={currentPageType}
              productId={categoryData?.id ?? ''}
              productType={ProductType.Degrees}
              surveyProductType={getSurveyProductType(currentPageType)}
            />

            {displayLearnBrowseTabs && <InlineNavigation />}
          </>
        );

      case HUB_MODULES.heroBanner:
        return (
          <>
            <HeroBanner
              slug={slug ?? ''}
              pageHeading={coreHubHeroValuePropContent?.heading ?? pageHeading}
              pageSubheading={coreHubHeroValuePropContent?.subheading ?? pageSubheading}
              categoryName={categoryData?.name}
              heroImage={coreHubHeroValuePropContent?.heroImage?.url}
              currentHubPage={currentPageType}
              productId={categoryData?.id ?? ''}
              productType={ProductType.Degrees}
              surveyProductType={getSurveyProductType(currentPageType)}
            />
            <DegreeHubScrollAnchor />
            {displayLearnBrowseTabs && <InlineNavigation />}
          </>
        );

      case HUB_MODULES.heroBannerRedesign:
        return (
          <>
            <HeroBannerRedesign
              slug={slug ?? ''}
              pageHeading={coreHubHeroValuePropContent?.heading ?? pageHeading}
              pageSubheading={coreHubHeroValuePropContent?.subheading ?? pageSubheading}
              categoryName={categoryData?.name}
              currentHubPage={currentPageType}
              productId={categoryData?.id ?? ''}
              productType={ProductType.Degrees}
              surveyProductType={getSurveyProductType(currentPageType)}
              expressionOfInterestContainer={expressionOfInterestContainer}
            />
            {displayLearnBrowseTabs && <InlineNavigation />}
          </>
        );

      case HUB_MODULES.browseDegrees:
        return (
          <TopDegreeListSection
            degreeFilterState={degreeFilterState}
            slug={slug ?? ''}
            categoryName={categoryData?.name}
            currentPageType={currentPageType}
            domains={domains ?? []}
            filteredDegreeList={filteredDegreeList ?? []}
            degreeListWithMetadata={degreeListWithMetadata}
            hideDegreeEoiCta={showDegreeEoiCtaInHubBanner}
          />
        );

      case HUB_MODULES.recommendedDegreesCarousel:
        return (
          <DegreeCarousel
            degreeListWithMetadata={degreeListWithMetadata}
            currentPageType={currentPageType}
            slug={slug ?? ''}
          />
        );

      case HUB_MODULES.programLevelCards:
        return (
          <Container>
            <div
              css={css`
                margin-top: var(--cds-spacing-400);
              `}
            >
              <ProductVariantCards
                title={_t('Browse by program level')}
                productVariantCards={productVariantCardsCollection?.items}
              />
            </div>
          </Container>
        );

      case HUB_MODULES.categoryCards:
        return (
          <Container>
            <div
              css={css`
                margin-top: var(--cds-spacing-400);
              `}
            >
              <DegreesCategoryCards
                categoryLinks={categoryLinksCollection?.items}
                categories={degreeCategories?.map(convertDegreeCategoryFieldsToPPCDegreeCategory) ?? []}
                displayVariantCards={false}
              />
            </div>
          </Container>
        );

      case HUB_MODULES.industryRelevance:
        return industryRelevance ? (
          <IndustryRelevanceModuleContainer
            heading={Array.isArray(industryRelevance) ? _t('Prepare for a career in a growing field') : undefined}
            modules={
              Array.isArray(industryRelevance)
                ? industryRelevance
                : [
                    {
                      ...industryRelevance,
                      onCtaClick: () => {
                        scrollToAnchor({ smooth: true });
                      },
                      ctaText: _t('Explore programs'),
                    },
                  ]
            }
          />
        ) : null;

      case HUB_MODULES.industryRelevanceInformation:
        return informationSectionContent ? (
          <div
            css={css`
              margin-bottom: var(--cds-spacing-400);
            `}
          >
            <InformationalSectionContainer
              content={<InformationalSectionContent content={informationSectionContent} />}
              sidebarContent={
                informationSectionContent?.sidebarContent ? (
                  <InformationalSectionSidebar content={informationSectionContent?.sidebarContent} />
                ) : undefined
              }
              category={categoryData?.name ?? ''}
              subBenefits={<InformationalSectionSubBenefits infoModuleContent={informationSectionContent} />}
            />
          </div>
        ) : null;

      case HUB_MODULES.valueProposition:
        return (
          <ValuePropositions
            heading={learnMoreInfoHeading ?? ''}
            valuePropositions={learnMoreValuePropositionsCollection?.items}
            degreeListWithMetadata={degreeListWithMetadata}
            currentPageType={currentPageType}
          />
        );

      case HUB_MODULES.testimonials:
        return visualConsistency?.testimonialListCollection?.items ? (
          <TestimonialsSection
            testimonials={visualConsistency?.testimonialListCollection?.items ?? null}
            testimonialsHeader={visualConsistency?.testimonialHeader ?? null}
          />
        ) : null;

      case HUB_MODULES.instructors:
        return (
          <Container>
            <UnifiedInstructorsListRedesign
              instructorHeader={visualConsistency?.instructorHeader}
              instructors={visualConsistency?.instructorListCollection?.items}
              degreeListWithMetadata={degreeListWithMetadata}
            />
          </Container>
        );

      case HUB_MODULES.relatedArticles:
        return visualConsistency?.topTopicsListCollection?.items?.length ? (
          <ArticlesSection
            articlesHeader={visualConsistency?.topTopicsHeader}
            articles={visualConsistency?.topTopicsListCollection?.items}
          />
        ) : (
          <ArticlesSection
            articlesHeader={relatedArticles?.title}
            articles={relatedArticles?.contentItemsCollection?.items as Array<Article>}
          />
        );

      case HUB_MODULES.faqs:
        return <Faqs faqSectionHeader={faqSectionHeader} faqs={faqsCollection?.items} />;

      case HUB_MODULES.webinars:
        return (
          <WebinarsSection
            pastWebinars={webinars.pastWebinars || []}
            upcomingWebinars={webinars.upcomingWebinars || []}
          />
        );

      case HUB_MODULES.eoiBlock:
        return (
          <EoiModule
            slug={slug ?? ''}
            categoryName={categoryData?.name}
            productId={categoryData?.id ?? ''}
            surveyProductType={getSurveyProductType(currentPageType)}
            currentHubPage={currentPageType}
            key={index}
          />
        );

      default:
        return null;
    }
  };

  return (
    <TrackedDiv
      trackingName="overview_page_content"
      withVisibilityTracking
      atMostOnce
      requireFullyVisible={false}
      trackClicks={false}
    >
      <CourseraMetatags
        metaNameAndProperties={{
          title: seoInformation?.metaTitle || '',
          description: seoInformation?.metaDescription || '',
          disableCourseraSuffix: !seoInformation?.enableCourseraSuffix,
          disableCourseraDescriptionSuffix: !seoInformation?.enableCourseraDescriptionSuffix,
          disableDescLimit: !seoInformation?.enableDescriptionLimit,
          disableCrawlerIndexing: isInternationalSubdomain,
          productPrimaryLanguage: !i18nSupportedLocales?.length ? 'en' : undefined,
          // force canonical to www for slugs without i18n support
        }}
        hrefLangOverrides={() => []}
      />
      {moduleOrderToUse.map((module, index) => (
        <ErrorBoundaryWithTagging key={module} componentName={module}>
          <GetModuleSectionEventTrackingDataContext.Provider value={{ index: index + 1 }}>
            <>{getModule(module, index)}</>
          </GetModuleSectionEventTrackingDataContext.Provider>
        </ErrorBoundaryWithTagging>
      ))}
    </TrackedDiv>
  );
};

export default OverviewPageContent;
