import user from 'js/lib/user';
import UserAgent from 'js/lib/useragent';

import BlueJaysExperiments from 'bundles/epic/clients/BlueJays';

import type { BoostChatPanel, BoostConfig, WindowWithBoost } from '../types/Boost';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { addApiEventListener, getLearnerTypes } from '../utils/BoostApiEvents';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import {
  addClearCustomPayloadEventListener,
  addConversationIdChangedEventListener,
  addEpicParamEventListener,
} from './BoostUtilEvents';

export type SetChatButtonVisibility = (isVisible: boolean) => void;

// See sendHiddenMessage() below for the usage and meaning of the HiddenMessages strings
export const HiddenMessages = {
  EVENT_HANDLER_SUCCESS: 'EVENT_HANDLER_SUCCESS',
  EVENT_HANDLER_FAILURE: 'EVENT_HANDLER_FAILURE',
  // If adding more, please follow the EVENT_ naming convention for the string value (starting all of them with EVENT_ will make it easier to identify all occurrences in logs / chat transcripts)
  // We might not need specific messages for specific events or their outcomes (e.g. no need for "EVENT_API_RESPONSE_BAD_REQUEST") because ultimately the chat flow just needs to know whether to go down the happy path or error path, and the customPayload can provide additional detail where needed.
} as const;

export type HiddenMessageValues = (typeof HiddenMessages)[keyof typeof HiddenMessages];

// Taken from static/bundles/authoring/common/utils/userNameParser.ts, where it has tests
// It assumes everything after the first space is part of the last name
// Note: I'm intentionally avoiding importing from another bundle, and I also don't want to put it in js/lib/user because it might make it seem like we have first and last names in our system, when really this is just a best guess approximation.
const getSplitName = (fullName: string) => {
  const trimmedFullName = fullName.trim();
  const splitName = trimmedFullName.split(' ');
  const firstName = splitName.shift() || '';
  const lastName = splitName.length > 0 ? splitName.join(' ') : undefined;

  return {
    firstName,
    lastName,
  };
};

const getUserBrowser = () => {
  const userAgent = new UserAgent(navigator?.userAgent);
  return userAgent.browser;
};

export const getBoostInstanceName = () => BlueJaysExperiments.get('ChatBoostInstanceName') || '845coursera'; // 845coursera is the prod instance of Boost.  We might want to opt users in to a staging instance of Boost with different flows/behavior configured.  Or for development, you can override this locally to "coursera-poc" to target our Proof Of Concept instance of Boost.

export const getBoostPayload = async () => {
  const userData = user.get();
  const { firstName, lastName } = getSplitName(userData.fullName || '');
  const userBrowser = getUserBrowser();
  const userLearnerTypes = await getLearnerTypes(userData.id);

  /* eslint-disable camelcase */
  return {
    user_user_id: userData.external_id, // We avoid sharing user_id with 3rd parties, which is why we're supplying external_id here.  We need to keep the field name as user_user_id because that is the field name that is recognized by Boost (e.g. in the Export API).
    user_name_full: userData.fullName,
    user_name_first: userData.id_verification?.first_name || firstName,
    user_name_last: userData.id_verification?.last_name || lastName || '',
    user_email: userData.email_address,
    // The rest of the fields are not standard fields in Boost, but they can be useful in certain chat flows.  We keep the same naming convention.
    user_email_is_verified: userData.email_verified,
    user_is_identity_verified: !!userData.id_verification,
    user_locale: userData.locale,
    user_timezone: userData.timezone,
    user_is_poweruser: userData.is_poweruser, // useful for logging, and for branching chat flows for internal users for previewing
    user_user_id_last_digit: +userData.id % 10, // useful for targeting flows or flow branches to a fraction of users (e.g. 10% of users could be user_id_last_digit == 3)
    user_browser_name: userBrowser.name,
    user_browser_version: userBrowser?.version,
    user_is_consumer_learner: userLearnerTypes.consumer,
    user_is_degree_learner: userLearnerTypes.degree,
    user_is_enterprise_learner: userLearnerTypes.enterprise,
  };
  /* eslint-enable camelcase */
};

export const getInitBoost =
  (setChatButtonVisibility?: SetChatButtonVisibility, boostConfig?: BoostConfig) => async () => {
    const windowWithBoost = window as unknown as WindowWithBoost; // safe to use 'window' because we skip rendering this component in SSR (see ChatLoader.tsx)
    const boostInit = windowWithBoost.boostInit;
    const boostInstanceName = getBoostInstanceName();

    const boost = boostInit(boostInstanceName, boostConfig ?? {});
    const boostPayload = await getBoostPayload();

    boost.chatPanel.setCustomPayload(boostPayload);
    addConversationIdChangedEventListener(boost.chatPanel);
    addClearCustomPayloadEventListener(boost.chatPanel);
    addEpicParamEventListener(boost.chatPanel);
    addApiEventListener(boost.chatPanel);

    windowWithBoost.boost = boost;

    // Unhide the chat button now that it's initialized and ready to use:
    setChatButtonVisibility?.(true);
  };

export const getAddBoostChatScriptToPage =
  (setChatButtonVisibility?: SetChatButtonVisibility, boostConfig?: BoostConfig) => () => {
    if (document.getElementById('boost-chat-script')) {
      // Script already on page.  Maybe another component loaded it already.
      setChatButtonVisibility?.(true);
      return;
    }

    const boostScriptElement = document.createElement('script');
    boostScriptElement.id = 'boost-chat-script';
    boostScriptElement.src = `https://${getBoostInstanceName()}.boost.ai/chatPanel/chatPanel.js`;
    boostScriptElement.async = true;
    boostScriptElement.defer = true;
    boostScriptElement.onload = getInitBoost(setChatButtonVisibility, boostConfig);
    document.body.appendChild(boostScriptElement);
  };

export const getChatPanel = (): BoostChatPanel | undefined => {
  return (window as unknown as WindowWithBoost).boost?.chatPanel;
};

// Internal helper method.  See disableMessageComposer / enableMessageComposer
const setMessageComposerDisplay = (displayMode: 'none' | 'block') => {
  // We have to settle for DOM manipulation because there is no other way at the moment
  // We will check with Boost to see if they can provide supported methods on chatPanel for disabling the 'composer' (or at least static class names we could use)
  const messageComposerTextAreaElement = document.querySelector('#chat-container form textarea') as HTMLTextAreaElement;
  if (!messageComposerTextAreaElement) {
    return;
  }
  messageComposerTextAreaElement.style.display = displayMode;
};

/** Prevents users from submitting messages in the chat window by hiding the text input UI (aka composer) */
export const disableMessageComposer = () => setMessageComposerDisplay('none');

/** Re-enables users to submit chat messages by unhiding the text input UI (aka composer) */
export const enableMessageComposer = () => setMessageComposerDisplay('block');

/**
 * Sends a hidden chat message from the user that does not appear in the end user chat panel UI.
 * This enables our JS code to resume a chat flow on the Boost side.  We can use chatPanel.setCustomPayload() to send data to the bot, but after that we also need to fire an event that will cause the bot to respond, so we send a chat message.
 * On the Boost side, in the flow you would use an "Entity Extraction" action to wait for "user input" (which will actually come from JS).
 * @remarks An alternate option might have been to use chatPanel.triggerAction(actionId), but there would be complexity in keeping track of the actionId numbers.
 * @param message The hidden system message to send e.g. SYS_EVENT_HANDLE_OK
 * @param chatPanel By default we take the global chatPanel object from window, but you can override that
 */
export const sendHiddenMessage = (
  message: HiddenMessageValues,
  chatPanel: BoostChatPanel | undefined = getChatPanel()
) => {
  if (!chatPanel) {
    return;
  }
  chatPanel.sendMessage(message);
};

/**
 * Removes hidden chat messages from the given array of MutationRecords.
 *
 * @param chatMutations - The array of MutationRecords representing chat mutations.
 */
export const removeHiddenChatMessages = (chatMutations: MutationRecord[]) => {
  const hiddenMessages = [
    HiddenMessages.EVENT_HANDLER_SUCCESS,
    HiddenMessages.EVENT_HANDLER_FAILURE,
    '...', // This is a placeholder for the Boost chat loading message
  ];

  chatMutations
    .flatMap((mutation: MutationRecord) => Array.from(mutation.addedNodes))
    .forEach((addedNode: Node) => {
      const addedElement = addedNode as HTMLElement;

      if (!hiddenMessages.includes(addedElement.innerText.trim())) {
        return;
      }

      // We have to settle for DOM manipulation because there is no other way at the moment
      // We will check with Boost to see if they can provide supported methods on chatPanel for this type of thing (or at least static class names we could use)
      const messageContainer = addedElement.closest('#chat-container .MessageList > div > div') as HTMLDivElement;
      const messageElements = messageContainer?.querySelector('section');
      const hasSingleMessageElement = (messageElements?.children?.length || 0) <= 1;

      if (messageContainer && hasSingleMessageElement) {
        messageContainer.style.display = 'none';
        return;
      }

      addedElement.style.display = 'none';
    });
};
