import * as React from 'react';

import config from 'js/app/config';
import requestCountry from 'js/lib/requestCountry';

import type { CourseraPlusProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import CourseraPlusProductVariants, {
  isMonthly,
  isYearly,
} from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type SubscriptionTrialsV1 from 'bundles/naptimejs/resources/subscriptionTrials.v1';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import { currencyCodeMap } from 'bundles/payments/lib/currencies';

import PlusFullWhiteBluePill from 'images/coursera-plus/coursera-plus-logo-whitetext-blueplus.png';
import CourseraLite from 'images/coursera-plus/subscriptionTiers/CourseraLite.png';
import CourseraLiteBlueWithBluePill from 'images/coursera-plus/subscriptionTiers/CourseraLiteBlueWithBluePill.png';
import liteFullWhite from 'images/coursera-plus/subscriptionTiers/coursera-lite-white-logo.png';
import litePill from 'images/coursera-plus/subscriptionTiers/courseraLitePill.png';
import liteFullBlackBlue from 'images/coursera-plus/subscriptionTiers/liteFullBlackBlue.png';
import litePillWhiteLetters from 'images/coursera-plus/subscriptionTiers/litePillWhiteLetters.png';
import plusFullBlackBlue from 'images/coursera-plus/subscriptionTiers/plusFullBlackBlue.png';

import _t from 'i18n!nls/enroll';

export const previewIsS12nUpsell49NoFreeTrialEnabled = () => {
  return courseraPlusExperiments.preview('isS12nUpsell49NoFreeTrialEnabled');
};

export const previewHideS12nUpsellFromWesternEuropeVariant = () => {
  return courseraPlusExperiments.preview('hideS12nUpsellFromWesternEuropeVariant');
};

export const getCourseraPlusDisplayNameWithBillingCycle = (productSku: string): string => {
  if (isMonthly(productSku)) {
    return _t('Coursera Plus monthly');
  }
  if (isYearly(productSku)) {
    return _t('Coursera Plus annual');
  }
  // just in case there's something new
  return _t('Coursera Plus');
};

export const getOrderedEnrollmentChoiceTypes = (
  choiceTypes: Array<keyof typeof EnrollmentChoiceTypes>,
  isMobile: boolean
): Array<keyof typeof EnrollmentChoiceTypes> => {
  const withoutSubscribeToCourseraPlus = choiceTypes.filter(
    (choiceType) => choiceType !== EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS
  );

  if (isMobile) {
    return [...withoutSubscribeToCourseraPlus, EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS];
  } else {
    return [
      withoutSubscribeToCourseraPlus[0],
      EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS,
      ...withoutSubscribeToCourseraPlus.slice(1),
    ];
  }
};

export const fractionValueRoundedUp = (numerator: number, denominator: number, decimals: number): number => {
  // the reason we use this strange formula for the computation is because native js has issues with floating point
  // math, e.g. 0.1 * 0.2 => 0.020000000000000004
  const fp = 10 ** decimals;
  return Math.ceil(Math.trunc((100 * fp * numerator) / denominator) / 100) / fp;
};

const priceTwelfth = (currencyCode: string, annualAmount: number): number => {
  const decimals: number | undefined = currencyCodeMap[currencyCode]?.decimalPlaces;
  return fractionValueRoundedUp(annualAmount, 12, decimals ?? 2);
};

export const discountedPrice = (amount: number, percents: number, decimals?: number): number =>
  fractionValueRoundedUp(amount * (100 - percents), 100, decimals ?? 0);

export const discountedPriceForCurrency = (price: number, percents: number, currencyCode: string) => {
  const decimals: number | undefined = currencyCodeMap[currencyCode]?.decimalPlaces;
  return discountedPrice(price, percents, decimals);
};

export const getCourseraPlusMonthlyFromAnnualPrice = (
  currencyCode: string,
  annualAmount: number
): React.ReactElement<typeof ReactPriceDisplay> => {
  const monthlyPrice = priceTwelfth(currencyCode, annualAmount);
  return <ReactPriceDisplay currency={currencyCode} value={monthlyPrice} hideCurrencyCode={true} />;
};

export const getSuccessPercentage = () => _t('77%');

export const numberOfProductsNoPlusSign = '7,000';
export const numberOfProductsWithPlusSign = '7,000+';
export const numberOfPartnersNoPlusSign = '325+';
export const newNumberOfProductsCourseraPlusNoPlusSign = '6,100';
export const numberOfProducts = `${numberOfProductsNoPlusSign}+`;
export const numberOfProductsInCourseraLiteNoPlusSign = `5,000`;
export const numberOfProductsInCourseraLite = `${numberOfProductsInCourseraLiteNoPlusSign}+`;

export const getCopy = () => ({
  numberOfProducts, // REQUIRED for `headlineExpanded` and `headline`
  numberOfProductsInCourseraLiteNoPlusSign,
  newNumberOfProductsCourseraPlusNoPlusSign,
  numberOfProductsInCourseraLite,
  numberOfPartnersNoPlusSign,
  headlineExpanded: _t(
    'Get unlimited access to {numberOfProducts} courses, Projects, Specializations, and Professional Certificates with Coursera Plus'
  ),
  upgradeCourseraPlus: _t(
    'Upgrade for unlimited access to #{numberOfProducts} courses, Projects, Specializations, and Professional Certificates',
    { numberOfProducts }
  ),
  headline: _t(
    'Unlimited access to {numberOfProducts} courses, Projects, Specializations, and Professional Certificates'
  ),
  headlineV4: _t(`Unlimited access to {numberOfProducts} world-class courses, 
    hands-on projects, and job-ready certificate programs - all included in your subscription`),
  collectionFooterText: _t(
    'Unlimited access to {numberOfProducts} world-class courses, hands-on projects, and job-ready certificate programs—all included in your subscription.'
  ),
  courseraLiteHeadline: _t(
    `Introducing Coursera Lite—a new subscription that offers access to {numberOfProducts} videos in world-class courses and job-ready certificate programs`
  ),
  moneyBack: _t('14-day money-back guarantee'),
  freeTrial: _t('7-day free trial'),
  mainValueProps: [
    _t('Explore trending topics and skills in data science, computer science, business, health, and more'),
    _t('Learn without limits — move between and switch courses at any time'),
    _t('Earn shareable certificates for every course you complete at no extra cost'),
  ],
});

export const getCtaAnnualNoteCopy = (
  productItemId: CourseraPlusProductVariant,
  subscriptionTrials?: Array<SubscriptionTrialsV1>
): string | undefined => {
  const { moneyBack, freeTrial } = getCopy();
  if (productItemId === CourseraPlusProductVariants.ANNUAL_SEVEN_DAY_FREE_TRIAL) {
    return subscriptionTrials && subscriptionTrials.length > 0 ? undefined : freeTrial;
  } else {
    return moneyBack;
  }
};

export type EnrollButtonCopy = {
  title: string;
  verboseTitleWithPlaceholder: (product: string) => string;
  subtitle: string;
};

export const getEnrollButtonCopy = (): Record<string, EnrollButtonCopy> => {
  return {
    freeTrial: {
      title: _t('Start free trial'),
      verboseTitleWithPlaceholder: (product) => _t('Start #{product} free trial', { product }), // primarily for accessibility
      subtitle: _t('Cancel anytime'),
    },
    freeTrialWithDays: {
      title: _t('Start 7-day free trial'),
      verboseTitleWithPlaceholder: (product) => _t('Start 7-day free trial of #{product}', { product }), // primarily for accessibility
      subtitle: _t('No commitment. Cancel anytime.'),
    },
    subscription: {
      title: _t('Subscribe'),
      verboseTitleWithPlaceholder: (product) => _t('Subscribe to #{product}', { product }), // primarily for accessibility
      subtitle: _t('Cancel anytime'),
    },
    subscriptionNoCommitment: {
      title: _t('Subscribe'),
      verboseTitleWithPlaceholder: (product) => _t('Subscribe to #{product}', { product }), // primarily for accessibility
      subtitle: _t('No commitment. Cancel anytime.'),
    },
  };
};

const awsFolder = `${config.url.resource_assets}coursera_plus/`;

export const logo = {
  PLUS_PILL_BLUE: `${awsFolder}coursera-plus-badge-blue.png`,
  PLUS_PILL_WHITE: `${awsFolder}courseraplus-badge-white-rgb-cropped.png`,
  PLUS_FULL_BLUE: `${awsFolder}landing_page/coursera-plus-blue.png`,
  PLUS_FULL_WHITE: `${awsFolder}courseraplus-reversed-rgb-cropped.png`,
  PLUS_FULL_WHITE_BLUE_PILL: PlusFullWhiteBluePill,
  PLUS_FULL_PURPLE: `${awsFolder}courseraplus-transparent-with-purple-pill.png`,
  LITE_FULL_BLUE: CourseraLite,
  LITE_FULL_BLUE_HD: CourseraLiteBlueWithBluePill,
  LITE_PILL_BLUE: litePill,
  LITE_PILL_WHITE: litePillWhiteLetters,
  LITE_FULL_WHITE: liteFullWhite,
  LITE_FULL_BLACK_BLUE: liteFullBlackBlue,
  PLUS_FULL_BLACK_BLUE: plusFullBlackBlue,
};

export const constants = {
  CATALOG_CONTENT_LINK: 'https://learner.coursera.help/hc/articles/360036151932',
  REFUND_POLICY_LINK: 'https://learner.coursera.help/hc/articles/208280266-Refund-policies',
  LEARN_MORE_LINK: 'https://learner.coursera.help/hc/articles/360036151872',
  LIST_OF_INCLUDED_CONTENT: 'https://learner.coursera.help/hc/articles/360036151932',
  MY_PURCHASES_LINK: '/my-purchases/transactions',
  HOW_TO_ADD_RESUME_LINK: 'https://blog.coursera.org/how-to-add-coursera-credentials-to-resume/',
  // There are four different Coursera Plus subscription permutations that the user can enroll / own - see CourseraPlusProductVariants.ts
  COURSERA_PLUS_SUBSCRIPTION_UNDERLYING_PRODUCT_ITEM_ID: 'Nf1hONLKQjyXSmwOh0KGyQ',
  COURSERA_TIER_LITE_UNDERLYING_PRODUCT_ID: 'BcioFh8tQUakR39Fb0LKyQ',
  COURSERA_PLUS_PREPAID_UNDERLYING_PRODUCT_ITEM_ID: 'Nf1hONLKQjyXSmwOh0KGyQ',
  LANDING_PAGE_LEARN_MORE_SECTION: 'learnMore',
};

export const getLandingPageLink = (): string => '/courseraplus';

// Check if slug matches Generative Ai promotion slug
export const isGenerativeAILandingPage = (slug: string) => slug === 'generative-ai';

export const generateCourseraPlusLandingPageAnnualPricePresentationImpression = (isDefaultLandingPage?: boolean) => {
  if (isDefaultLandingPage) {
    courseraPlusExperiments.get('courseraPlusLandingPageAnnualPricePresentation');
  }
};

export const previewCourseraPlusLandingPageAnnualPricePresentationVariant = (isDefaultLandingPage?: boolean) => {
  if (!isDefaultLandingPage) {
    return 'control';
  }
  return courseraPlusExperiments.preview('courseraPlusLandingPageAnnualPricePresentation');
};

export const previewCourseraPlusLandingPageAnnualPricePresentationV2Variant = (isDefaultLandingPage?: boolean) => {
  if (!isDefaultLandingPage) {
    return 'control';
  }

  return courseraPlusExperiments.preview('courseraPlusLandingPageAnnualPricePresentationVersion2');
};

export const getIsLearnerInEligibleCountry = ({
  countriesToExclude,
  countriesToInclude,
}: {
  countriesToExclude?: string | null;
  countriesToInclude?: string | null;
}) => {
  const countryCode = requestCountry.get();
  const countriesToExcludeList = countriesToExclude
    ?.trim()
    .split(',')
    .map((code) => code.trim());
  const countriesToIncludeList = countriesToInclude
    ?.trim()
    .split(',')
    .map((code) => code.trim());

  // If a country appears in both the exclude and include list
  // we prioritize excluding the country. A nice-to-have feature
  // would be to show a validation error if a country appears in both lists.
  if (countriesToExcludeList?.includes(countryCode)) {
    return false;
  } else if (countriesToIncludeList && !countriesToIncludeList?.includes(countryCode)) {
    return false;
  } else {
    return true;
  }
};

export const getShouldUseNewFormatForCurrency = (currencyCode: string) => {
  // for now only USD and CAD are eligible for
  return ['USD', 'CAD'].includes(currencyCode);
};
