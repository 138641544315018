import type React from 'react';
import { useCallback } from 'react';

type useKeyboardNavigationHookOptions<T> = {
  /**
   * Elements that participate in keyboard navigation.
   */
  elements?: T[];
  /**
   * Disables ability to move up and down the elements using arrow keys.
   */
  disableArrowsNavigation?: boolean;
};

/**
 * Returns keydown handler to assign on a parent container.
 *
 * Pass a list of elements that participate in keyboard navigation.
 * useKeyboardNavigation will move focus up and down on Tab/Arrows and blur on Escape.
 */
const useKeyboardNavigation = <
  W extends HTMLElement = HTMLDivElement,
  T extends HTMLElement = HTMLButtonElement
>({
  elements,
  disableArrowsNavigation,
}: useKeyboardNavigationHookOptions<T>): {
  onKeyDown: (event: React.KeyboardEvent<W>) => T | null;
} => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!elements) {
        return null;
      }

      const focusedElementIndex = elements.findIndex(
        (element) => element === document.activeElement
      );

      if (focusedElementIndex === -1) {
        return null;
      }

      if (event.key === 'Escape') {
        event.preventDefault();
        elements[focusedElementIndex].blur();

        return null;
      }

      const arrowNavigation =
        !disableArrowsNavigation &&
        !event.altKey &&
        !event.metaKey &&
        !event.shiftKey;

      if (
        arrowNavigation &&
        (event.key === 'ArrowDown' || event.key === 'ArrowRight')
      ) {
        event.preventDefault();

        const nextElementIndex = (focusedElementIndex + 1) % elements.length;
        elements[nextElementIndex].focus();

        return elements[nextElementIndex];
      }

      if (
        arrowNavigation &&
        (event.key === 'ArrowUp' || event.key === 'ArrowLeft')
      ) {
        event.preventDefault();

        const prevElementIndex =
          focusedElementIndex === 0
            ? elements.length - 1
            : focusedElementIndex - 1;

        elements[prevElementIndex].focus();

        return elements[prevElementIndex];
      }

      return null;
    },
    [elements, disableArrowsNavigation]
  );

  return { onKeyDown: handleKeyDown };
};

export default useKeyboardNavigation;
