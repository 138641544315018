import { animateScroll } from 'react-scroll';

import type { ErrorPolicy } from '@apollo/client';
import * as Sentry from '@sentry/react';
import type { ApolloError } from 'apollo-client';

import { supportedSubfolderLocaleCodes } from 'bundles/internationalization-lib/constants/subfolderLocaleCodes';
import {
  ENTITY_PATH_NAMES,
  ENTITY_TYPES,
  PAGE_TYPE,
  SEO_CONTENT_PAGE_SLUG,
  SEO_CONTENT_PAGE_TYPE,
  getEntityTypeToLabelMapping,
  richTextEmbedTypes,
} from 'bundles/unified-seo-content-common/constants';
import type {
  EntityType,
  Faq,
  PageType,
  SEOContentPageType,
  SeoContentLinksFragment,
} from 'bundles/unified-seo-content-common/types';

export const getEntityTypeLabel = (entityType: EntityType): string => {
  switch (entityType) {
    case ENTITY_TYPES.course:
      return getEntityTypeToLabelMapping().course;

    case ENTITY_TYPES.s12n:
      return getEntityTypeToLabelMapping().s12n;

    case ENTITY_TYPES.profS12n:
      return getEntityTypeToLabelMapping().profS12n;

    case ENTITY_TYPES.project:
      return getEntityTypeToLabelMapping().project;

    case ENTITY_TYPES.degree:
      return getEntityTypeToLabelMapping().degree;

    case ENTITY_TYPES.mastertrack:
      return getEntityTypeToLabelMapping().mastertrack;

    default:
      return getEntityTypeToLabelMapping().course;
  }
};

export const getEntityTypeFromEntityPathName = (entityPathName: string): EntityType => {
  switch (entityPathName) {
    case ENTITY_PATH_NAMES.course:
      return ENTITY_TYPES.course;

    case ENTITY_PATH_NAMES.s12n:
      return ENTITY_TYPES.s12n;

    case ENTITY_PATH_NAMES.profS12n:
      return ENTITY_TYPES.profS12n;

    case ENTITY_PATH_NAMES.project:
      return ENTITY_TYPES.project;

    case ENTITY_PATH_NAMES.degree:
      return ENTITY_TYPES.degree;

    case ENTITY_PATH_NAMES.mastertrack:
      return ENTITY_TYPES.mastertrack;

    default:
      return ENTITY_TYPES.course;
  }
};

export const scrollPageToTop = () => {
  animateScroll.scrollToTop({ smooth: false, duration: 500 });
};

export const getAllSupportedLocalizedCountryCode = () => ['ca', 'in', 'mx', 'gb'];

export const getSearchParametersFromSearchURL = (searchUrl: string) => {
  const queryURL = new URL(searchUrl);

  const filters = Array.from(queryURL.searchParams.entries())
    .filter(([key]) => key !== 'query')
    .map(([key, value]) => `${key}:"${decodeURI(value)}"`)
    .join('AND ');

  return {
    query: queryURL.searchParams.get('query') || '',
    filters,
  };
};

export const extractSlugFromArticlesAlgoliaObjectUrl = (objectUrl: string) => {
  // Algolia Object URL for Articles index is of format /articles/{articleSlug}
  return objectUrl.split('/')[objectUrl.split('/').length - 1];
};

export const getDeferToCSRQueryOptions = (): { errorPolicy: ErrorPolicy; ssr: boolean } => ({
  errorPolicy: 'all',
  ssr: false,
});

export const getAllFaqEntries = (links?: SeoContentLinksFragment) =>
  links?.entries?.block?.map((link): Faq | null => {
    if (link?.__typename === richTextEmbedTypes.faq) {
      return { question: link.question, answer: link.answer, sys: link.sys };
    }
    return null;
  });

export const getPagePath = ({
  slug,
  pageType,
  seoContentPageType,
}: {
  slug?: string;
  pageType: PageType;
  seoContentPageType: SEOContentPageType;
}) => {
  const pageTypeSlug = SEO_CONTENT_PAGE_SLUG[seoContentPageType];
  switch (pageType) {
    case PAGE_TYPE.content:
      return `${pageTypeSlug}/${slug}`;
    case PAGE_TYPE.categoryHub:
      return `${pageTypeSlug}/category/${slug}`;
    case PAGE_TYPE.hubWithCategories:
    default:
      return pageTypeSlug;
  }
};

export const getPageSubfolder = ({
  localizedCountryCode,
  localizedLanguageCode,
}: {
  localizedCountryCode?: string | null;
  localizedLanguageCode?: string | null;
}) => {
  // try language and country code subfolder
  const languageCountrySubfolder = `${localizedLanguageCode}-${localizedCountryCode}`;
  if (supportedSubfolderLocaleCodes.includes(languageCountrySubfolder)) return languageCountrySubfolder;

  // try country code subfolder
  const countrySubfolder = localizedCountryCode?.toLowerCase();
  const allSupportedCountry = getAllSupportedLocalizedCountryCode();
  if (countrySubfolder && allSupportedCountry.includes(countrySubfolder)) return countrySubfolder;

  return undefined;
};

export const getPageLink = ({
  pageType,
  slug,
  localizedCountryCode,
  localizedLanguageCode,
  trackingQueryParam,
  seoContentPageType = SEO_CONTENT_PAGE_TYPE.articles,
}: {
  pageType: PageType;
  slug?: string;
  localizedCountryCode?: string | null;
  localizedLanguageCode?: string | null;
  trackingQueryParam?: string;
  seoContentPageType?: SEOContentPageType;
}): string => {
  let pageLink;
  const path = getPagePath({ slug, pageType, seoContentPageType });
  const subfolder = getPageSubfolder({ localizedCountryCode, localizedLanguageCode });

  if (subfolder) {
    pageLink = `/${subfolder}/${path}`;
  } else {
    pageLink = `/${path}`;
  }

  if (trackingQueryParam) {
    const params = new URLSearchParams();
    params.append('trk_ref', trackingQueryParam);
    pageLink = `${pageLink}?${params.toString()}`;
  }

  return pageLink;
};

export const queryErrorHandler = ({ extraMessage }: { extraMessage?: string }) => {
  return (errorInfo: ApolloError) => {
    Sentry.captureException(errorInfo, {
      extra: { message: extraMessage },
    });
  };
};
