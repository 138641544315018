import { useLocalizedStringFormatter } from '@coursera/cds-common';

import Button from '@core/Button';
import PopoverFooter from '@core/Popover/Footer';

import i18nMessages from './i18n';
import getToolbarStyles from './styles/toolbarCss';

type Props = {
  onApply?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hideClear?: boolean;
};

const Toolbar = (props: Props) => {
  const { onApply, onClear, hideClear } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  return (
    <PopoverFooter
      aria-label={stringFormatter.format('toolbarLabel')}
      css={getToolbarStyles}
      role="toolbar"
    >
      <Button
        aria-label={stringFormatter.format('applyAriaLabel')}
        size="small"
        onClick={onApply}
      >
        {stringFormatter.format('apply')}
      </Button>
      {!hideClear && (
        <Button
          aria-label={stringFormatter.format('clearAriaLabel')}
          size="small"
          variant="ghost"
          onClick={onClear}
        >
          {stringFormatter.format('clear')}
        </Button>
      )}
    </PopoverFooter>
  );
};

export default Toolbar;
