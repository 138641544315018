import React from 'react';

import { useId } from '@coursera/cds-common';
import type { ForwardRefComponent } from '@coursera/cds-common';

import { BaseDialog } from '@core/dialogs/common/BaseDialog';
import type { CommonDialogProps } from '@core/dialogs/common/types';
import Divider from '@core/Divider';
import { StickyScroll } from '@core/StickyScroll';

import { DialogContextProvider } from './DialogContextProvider';
import { dialogClasses, getFullscreenDialogCss } from './styles';

type FullscreenDialogType = ForwardRefComponent<HTMLDivElement, Props>;

export type Props = CommonDialogProps & {
  /**
   * Use the subcomponents to compose a FullscreenDialog:
   *
   * ```
   * <FullscreenDialog>
   *   <FullscreenDialog.Header />
   *   <FullscreenDialog.HeadingGroup />
   *   <FullscreenDialog.Content />
   *   <FullscreenDialog.Actions />
   * </FullscreenDialog>
   * ```
   *
   * @see FullscreenDialog.Header
   * @see FullscreenDialog.HeadingGroup
   * @see FullscreenDialog.Content
   * @see FullscreenDialog.Actions
   */
  children: React.ReactNode | React.ReactNode[];

  /**
   * The width of the content for `LG` & `MD` breakpoints. Content is always
   * full-width on smaller breakpoints.
   *
   * @default auto
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/width
   */
  contentWidth?: string;
};

const getHeadingGroupId = (node: React.ReactNode, baseId?: string) => {
  const configuredId = React.isValidElement(node) ? node.props.id : undefined;
  return configuredId || `${baseId}-headingGroup`;
};

const styles = getFullscreenDialogCss();

const FullscreenDialog: FullscreenDialogType = React.forwardRef(
  function FullscreenDialog(props: Props, ref: React.Ref<HTMLDivElement>) {
    const {
      children,
      sticky = 'none',
      id: baseId,
      onClose,
      'aria-labelledby': labelledBy,
      contentWidth = 'auto',
      ...modalProps
    } = props;

    const id = useId(baseId);

    const [header, ...dialogContent] = React.Children.toArray(children);
    const [headingGroup] = dialogContent;
    const headingGroupId = getHeadingGroupId(headingGroup, id);

    return (
      <BaseDialog
        ref={ref}
        aria-labelledby={labelledBy || headingGroupId}
        classes={{ dialog: dialogClasses.dialog }}
        css={styles}
        id={id}
        transition="slide-up"
        onClose={onClose}
        {...modalProps}
      >
        <DialogContextProvider
          contentWidth={contentWidth}
          headingGroupId={headingGroupId}
          onClose={onClose}
        >
          {header}

          <Divider color="dark" orientation="horizontal" />

          <StickyScroll
            className={dialogClasses.scrollContainer}
            sticky={sticky}
          >
            {dialogContent}
          </StickyScroll>
        </DialogContextProvider>
      </BaseDialog>
    );
  }
);

export default FullscreenDialog;
