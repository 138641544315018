import * as React from 'react';
import { Query, graphql } from 'react-apollo';

import { ProductFeatureSet } from './classes';
import { productFeaturesQuery } from './queries';
import type {
  ProductFeaturesQueryData,
  ProductFeaturesQueryVariables,
  ProductFeaturesRequest,
  ProductFeaturesResponse,
  Scope,
} from './types';
import { scopeToFeatureIdentifications } from './utils';

type PropsForProductFeaturesQuery<S extends Scope<{}>> = ProductFeaturesRequest & {
  children: (response: ProductFeaturesResponse<S>) => React.ReactNode;
};

export const createProductFeaturesQuery = <S extends Scope<{}>>(
  scope: S
): ((props: PropsForProductFeaturesQuery<S>) => JSX.Element) => {
  const featureIdentifications = scopeToFeatureIdentifications(scope);
  const ProductFeaturesQuery = ({ idType, id, ssr, children }: PropsForProductFeaturesQuery<S>): JSX.Element => (
    <Query<ProductFeaturesQueryData, ProductFeaturesQueryVariables>
      query={productFeaturesQuery}
      variables={{ input: { idType, id, featureIdentifications } }}
      ssr={ssr}
      skip={!id}
    >
      {({ loading, error, data }) => {
        const features = ProductFeatureSet.createWithMemoize<S>(data?.GetConfiguredFeatures);
        const result = children({ loading, error, features });
        return result ? <>{result}</> : null;
      }}
    </Query>
  );
  return ProductFeaturesQuery;
};

export const createWithProductFeatures = <S extends Scope<{}>>(scope: S) => {
  const featureIdentifications = scopeToFeatureIdentifications(scope);
  const withProductFeatures =
    <TInner extends {}, TOuter extends {}>(
      options: (props: TOuter) => ProductFeaturesRequest,
      props: (response: ProductFeaturesResponse<S>, props: TOuter) => TInner
    ) =>
    (Component: React.ComponentType<TOuter & TInner>) =>
      graphql<TOuter, ProductFeaturesQueryData, ProductFeaturesQueryVariables, TInner>(productFeaturesQuery, {
        options: (ownProps) => {
          const { idType, id, ssr, ssrBlocking = false } = options(ownProps);
          return {
            ssr,
            ssrBlocking,
            variables: { input: { idType, id, featureIdentifications } },
          };
        },
        skip: (ownProps) => !options(ownProps).id,
        props: ({ data, ownProps }) => {
          const response = {
            loading: data?.loading ?? true,
            error: data?.error,
            features: ProductFeatureSet.createWithMemoize<S>(data?.GetConfiguredFeatures),
          };
          return props(response, ownProps);
        },
      })(Component);
  return withProductFeatures;
};
