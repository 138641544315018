/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

const styles = {
  placeholder: css`
    width: 360px;
  `,
};

const ProgramSwitcherPlaceholder = () => {
  return <div css={styles.placeholder} />;
};

export default ProgramSwitcherPlaceholder;
