import gql from 'graphql-tag';

import { StarCountBreakdownFragment } from 'bundles/video-logged-out-page/components/course-content/CourseReviews';
import CourseS12nInfo from 'bundles/video-logged-out-page/components/course-content/CourseS12nInfo';

export const ArticleEstimatedReadTimeQuery = gql`
  query ArticleEstimatedReadTimeQuery($slug: String!) {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "articles_estimated_read_time", keys: $slug) {
        elements {
          id
          content
        }
      }
    }
  }
`;

export const MasterTrackEntityQuery = gql`
  query MasterTrackEntityQuery($slug: String!, $liveOnly: Boolean) {
    CatalogMastertracksV1Resource {
      bySlug(slug: $slug, liveOnly: $liveOnly) {
        elements {
          id
          name
          slug
          bannerImage
          thumbnail
          partners {
            elements {
              id
              name
              squareLogo
            }
          }
        }
      }
    }
  }
`;

export const CourseDetailsQuery = gql`
  query VLPCourseDetailsQuery($courseSlug: String!) {
    XdpV1Resource {
      slug(productType: "COURSE", slug: $courseSlug) {
        elements {
          id
          xdpMetadata {
            __typename
            ... on XdpV1_cdpMetadataMember {
              cdpMetadata {
                id
                name
                slug
                totalEnrollmentCount
                primaryS12n {
                  ...CourseS12nInfoV2Fragment
                }
                ratings {
                  averageFiveStarRating
                  ratingCount
                }
                partners {
                  id
                  shortName
                  name
                  squareLogo
                }
              }
            }
          }
        }
      }
    }
  }
  ${CourseS12nInfo.fragments.s12nInfo}
`;

export const FetchProductOverviewQuery = gql`
  query VLPFetchProductOverviewQuery($courseSlug: String!) {
    XdpV1Resource {
      slug(productType: "COURSE", slug: $courseSlug) {
        elements {
          id
          fetchProductOverview {
            elements {
              ...StarCountBreakdownFragment
            }
          }
        }
      }
    }
  }
  ${StarCountBreakdownFragment}
`;
