type Options = {
  body?: string;
  timeout?: number;
  url: string;
  retries?: number; // Number of retries before rejecting the promise
  attributes?: { [key: string]: string | boolean }; // Add attributes option for custom attributes
};

const TIMEOUT = 30 * 1000; // 30s in ms

/**
 *
 * @param {Options} options
 * @param {string} options.body - (optional) Body of the script tag to inject <script>[body]</script>
 * @param {number} options.timeout - (optional) Milliseconds before promise is rejected due to failure loading the script
 * @param {string} options.url - URL of the script to inject
 * @param {Object} options.attributes - (optional) Key-value pairs of additional attributes to add to the script tag (e.g., `data-callback`)
 * @param {number} options.retries - (optional) Number of retries allowed in case of failure (default 3)
 * @return {Promise} - Return a promise that resolves when script is loaded, rejects when there is some connection errors or timeout is reached
 */
function loadScript(options: Options): Promise<void> {
  const { body, timeout = TIMEOUT, url, attributes = {}, retries } = options;

  if (document.querySelector(`script[src='${url}']`)) {
    return Promise.resolve();
  }

  return new Promise((ok, ko) => {
    const script = document.createElement('script');

    script.async = true;
    script.type = 'text/javascript';

    if (body) {
      script.innerHTML = body;
    }

    // Apply additional attributes (e.g., data-callback)
    Object.entries(attributes)
      // replace boolean attribute with empty string
      .map(([key, value]) => [key, typeof value === 'boolean' ? '' : value])
      .forEach(([key, value]) => {
        script.setAttribute(key, value);
      });

    // Helper function to handle errors and cleanup
    const handleFailure = (errorMessage: string) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      clearTimeout(timer);
      // Remove the script tag if it fails to load
      script.remove();

      if (retries && retries > 0) {
        // Retry the script load with retries - 1
        ok(loadScript({ ...options, retries: retries - 1 }));
      } else {
        const error = new Error(errorMessage);
        Object.assign(error, { url, body });
        ko(error);
      }
    };

    const timer = setTimeout(() => {
      handleFailure(`${timeout}ms timeout elapsed loading third-party script: ${url}`);
    }, timeout);

    script.onload = () => {
      clearTimeout(timer);

      ok();
    };

    script.onerror = (error) => {
      const errorMessage = `Failed to load script: ${url}, ${JSON.stringify(error)}`;
      handleFailure(errorMessage);
    };

    script.src = url;

    const [sibling] = document.getElementsByTagName('script');
    const { parentNode } = sibling ?? {};

    if (parentNode) {
      parentNode.insertBefore(script, sibling);
    } else {
      document.body.appendChild(script);
    }
  });
}

/**
 * Removes a script tag from the DOM by its URL.
 *
 * @param {string} url - URL of the script to be removed
 * @return {void}
 */
function removeScript(url: string): void {
  const scripts = document.querySelectorAll(`script[src="${url}"]`);
  for (const script of scripts) {
    script.remove();
  }
}

export default { loadScript, removeScript };
