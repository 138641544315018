import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { getItemId } from '@react-aria/listbox';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import type { ListState } from '@react-stately/list';

import { useLocalizedStringFormatter, mergeProps } from '@coursera/cds-common';

import i18nMessages from '@core/Autocomplete/i18n';
import Button from '@core/Button';
import Grid from '@core/Grid';
import { Popover } from '@core/Popover';
import type { SearchProps } from '@core/Search';
import Search from '@core/Search';

import ListBox from './ListBox';
import type { Props as ListBoxProps } from './ListBox';
import headerCss from './styles/headerCss';
import toolBarCss from './styles/toolbarCss';
import useCloseVirtualKeyboard from './useCloseVirtualKeyboard';

type Props<OptionType extends object> = {
  value: SearchProps['value'];
  onChange: SearchProps['onChange'];
  onClose: (e: React.SyntheticEvent) => void;
  state: ListState<OptionType> & { setFocused: (isFocused: boolean) => void };
  listBoxProps: AriaListBoxOptions<OptionType>;
  children: ListBoxProps<OptionType>['children'];
  actionButtons?: ReactNode;
  loading?: boolean;
  renderNotFoundMessage?: () => React.ReactNode;
  placeholder?: SearchProps['placeholder'];
  'aria-label'?: string;
  'aria-labelledby'?: string;
  inputProps?: SearchProps['inputProps'];
};

const DrawerAutocomplete = <T extends object>(props: Props<T>) => {
  const {
    value,
    onChange,
    onClose,
    listBoxProps,
    state,
    children,
    actionButtons,
    loading,
    renderNotFoundMessage,
    placeholder,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    inputProps,
  } = props;

  const listBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const activeDescendant =
    state.selectionManager.focusedKey != null
      ? getItemId(state, state.selectionManager.focusedKey)
      : undefined;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        onClose(e);
      }
    },
    [onClose]
  );

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown' && state.collection.size > 0) {
        e.preventDefault();
        listBoxRef.current?.focus();
      }
    },
    [state.collection.size]
  );

  /**
   * Handle autoFocus internally. Even though Search component also handles autoFocus, this prevents
   * a scroll animation when the drawer opens.
   */
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  // Close the software keyboard on scroll to give the user a bigger area to scroll.
  // But only do this if scrolling with touch, otherwise it can cause issues with touch
  // screen readers.
  const { onScroll, onTouchEnd, onTouchStart } = useCloseVirtualKeyboard({
    inputRef,
  });

  return (
    <>
      <Popover.Header css={headerCss}>
        <Grid container spacing={8} wrap="nowrap">
          <Grid item xs={12}>
            <Search
              ref={inputRef}
              autoFocus
              disableClearOnEscape
              fullWidth
              hideSearchButton
              inputProps={mergeProps(
                {
                  role: 'searchbox',
                  'aria-haspopup': 'listbox',
                  autoCorrect: 'off',
                  spellCheck: false,
                  autoComplete: 'off',
                  'aria-autocomplete': 'list',
                  type: 'text',
                  'aria-controls': listBoxProps.id,
                  'aria-activedescendant': activeDescendant,
                  'aria-label': ariaLabel,
                  'aria-labelledby': ariaLabelledby,
                  onKeyDown: handleKeyDown,
                  onKeyUp: handleKeyUp,
                },
                inputProps
              )}
              placeholder={placeholder}
              value={value}
              variant="silent"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs>
            <Button size="medium" variant="ghost" onClick={(e) => onClose(e)}>
              {stringFormatter.format('close')}
            </Button>
          </Grid>
        </Grid>
      </Popover.Header>
      <ListBox
        {...listBoxProps}
        ref={listBoxRef}
        autoFocus
        elementProps={{ onTouchStart, onTouchEnd, onScroll }}
        loading={loading}
        renderNotFoundMessage={renderNotFoundMessage}
        shouldFocusOnHover={false}
        shouldUseVirtualFocus={false}
        state={state}
      >
        {children}
      </ListBox>
      {actionButtons && (
        <Popover.Footer css={toolBarCss} role="toolbar">
          {actionButtons}
        </Popover.Footer>
      )}
    </>
  );
};

export default DrawerAutocomplete;
