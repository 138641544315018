import logger from 'js/app/loggerSingleton';
import Cookie from 'js/lib/cookie';
import user from 'js/lib/user';

import type { BoostChatPanel, BoostEvent } from '../types/Boost';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { HiddenMessages, disableMessageComposer, enableMessageComposer, sendHiddenMessage } from './BoostUtils';

type ApiCallResponse = {
  ok: boolean;
  status: number;
  statusText: string;
  body: string | boolean;
};

const API_EVENT_TYPE = 'api_call'; // Needs to match the string given in the JSON Emit Event in Boost (naming convention is snake_case)

/**
 * Provides the necessary CSRF3 header for non-GET requests to Coursera APIs
 */
const getRequestHeaders = (url: string, method: string) => {
  const isCourseraUrl = url.indexOf('/') === 0 || /coursera\.org/.test(url);
  if (isCourseraUrl && method !== 'GET') {
    return {
      'X-CSRF3-Token': Cookie.get('CSRF3-Token') || 'csrf3_missing',
      'X-Coursera-Application': 'boost-chat',
    };
  }
  return undefined;
};

/**
 * Replaces __userid__ with the current user's ID.
 * We do this because we don't send user IDs to Boost, but sometimes the current user ID is needed in an API URL or body (e.g. for PUT /api/profiles.v1)
 */
const replaceUserIdPlaceholders = (text: string) => text.replace(/__userid__/g, String(user.get().id));

const performApiCall = async (
  url: string,
  method: string,
  headers?: HeadersInit,
  body?: BodyInit,
  isLearnerTypeRequest?: boolean
): Promise<ApiCallResponse> => {
  // Using fetch below because api.js is pretty dated (it uses jQuery AJAX), and Apollo was overkill.
  // Re: fetch compatibility, we do have it polyfilled in case of IE <= 11.
  const response = await fetch(url, { method, headers, body });

  let responseBody;
  if (isLearnerTypeRequest) {
    // A user is Degree Learner if they have 1/more records via the degreeLearnerMemberships.v1 api
    // A user is an Enterprise Learner if they have 1/more records via the programMemberships.v2 api
    const learnerResponse = await response.json(); // use response.json() for learner type requests because we want access the elements property to determine a user's degree or enterprise membership
    responseBody = learnerResponse.elements.length > 0;
  } else {
    responseBody = await response.text(); // not using response.json() because it throws in cases where the response body is empty.  Also, when we use setCustomPayload() below, any JSON would have to get serialized back to a string anyway in order to get to Boost.
  }

  return {
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
    body: responseBody,
  };
};

export const getApiEventListener = (chatPanel: BoostChatPanel) => async (event: BoostEvent) => {
  const { url, method, body } = event.detail;

  const headers = getRequestHeaders(url, method);

  const requestUrl = replaceUserIdPlaceholders(url);
  const requestBody = method === 'GET' || !body || body === '-' ? undefined : replaceUserIdPlaceholders(body); // setting empty variables in Boost causes hard to debug issues, so we accept "-" as no body (we do have some POST/DELETE calls that require no body)

  disableMessageComposer(); // while the API call is in progress, if a user sends a message it will resume the Boost flow prematurely, so we disable the message composer (text input)
  let apiCallResponse: ApiCallResponse | null = null;
  try {
    apiCallResponse = await performApiCall(requestUrl, method, headers, requestBody);
  } catch (error) {
    logger.error(error);
    // failure event message is sent to Boost below.  We are just logging the details in the browser console, since this should be a 'during active development only' type issue.
  } finally {
    enableMessageComposer(); // allow the user to send messages to the bot again
  }

  if (!apiCallResponse || !apiCallResponse.ok) {
    // TODO: TOOL-1629 and TOOL-1630: Add Retracked/Sentry logging for !response.ok (but in the meantime we should at least get some tracking of these occurrences within Boost)
    sendHiddenMessage(HiddenMessages.EVENT_HANDLER_FAILURE, chatPanel);
    return;
  }

  // The Boost SDK doesn't have a direct way to send data back to Boost and trigger the chat flow to resume.
  // We use a combination of setCustomPayload() and a hidden chat message to do this (also see comments in BoostUtils.ts > sendHiddenMessage()).

  // Note for Boost flow implementation: the following call completely wipes out the previous customPayload and replaces it with a new one containing only the current API response.
  // We assume that everything that was needed from the initial customPayload has already been saved into variables in the Boost flow (the initial greeting flow action is set up to do this)
  chatPanel.setCustomPayload({
    // eslint-disable-next-line camelcase
    api_call_response: apiCallResponse,
  });

  // Trigger the chat flow to resume on the Boost side using an invisible message from the user to the bot
  sendHiddenMessage(HiddenMessages.EVENT_HANDLER_SUCCESS, chatPanel);
};

export const addApiEventListener = (chatPanel: BoostChatPanel) => {
  chatPanel.addEventListener(API_EVENT_TYPE, getApiEventListener(chatPanel));
};

export const checkLearnerMembership = async (requestUrl: string) => {
  const response = await performApiCall(requestUrl, 'GET', undefined, undefined, true);
  if (!response.ok) {
    throw new Error(`Request failed: ${response.status} ${response.statusText}`);
  }
  const isMember = response.body;
  return isMember;
};

// A learner can be 1/more of the following types: consumer, degree, enterprise
export const getLearnerTypes = async (userId: number) => {
  const degreeRequestUrl = `/api/degreeLearnerMemberships.v1?q=byUser&userId=${userId}`;
  const enterpriseRequestUrl = `/api/programMemberships.v2?q=byUser&userId=${userId}`;

  let isDegreeLearner;
  let isEnterpriseLearner;

  try {
    isDegreeLearner = await checkLearnerMembership(degreeRequestUrl);
  } catch (error) {
    isDegreeLearner = false;
    logger.error(error);
  }

  try {
    isEnterpriseLearner = await checkLearnerMembership(enterpriseRequestUrl);
  } catch (error) {
    isEnterpriseLearner = false;
    logger.error(error);
  }

  // Every learner is considered a Consumer Learner by default
  return { consumer: true, degree: isDegreeLearner, enterprise: isEnterpriseLearner };
};
