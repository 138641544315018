/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import times from 'lodash/times';

import { styles as FilterableShowMoreGridSectionStyles } from 'bundles/cds-labs/components/ShowMoreGridSection/FilterableShowMoreGridSection';
import type { ShowMoreGridSectionPlaceholderProps } from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSectionPlaceholder';
import ShowMoreGridSectionPlaceholder from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSectionPlaceholder';

import _t from 'i18n!nls/cds-labs';

const styles = {
  chipGroup: css`
    min-height: 32px;
    width: 100%;
    gap: var(--cds-spacing-200) var(--cds-spacing-100);
  `,
  radioGroup: css`
    display: flex;
    flex-wrap: wrap;
    gap: var(--cds-spacing-100) var(--cds-spacing-50);
    padding-top: var(--cds-spacing-100);
  `,
  chip: css`
    height: 32px;
    width: 150px;
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    border-radius: 48px;
    background-color: var(--cds-color-neutral-stroke-primary-weak);
  `,
};

export function FilterableShowMoreGridSectionPlaceholder(props: ShowMoreGridSectionPlaceholderProps) {
  const filtersToShow = 3;
  return (
    <ShowMoreGridSectionPlaceholder
      {...props}
      renderSubSection={() =>
        filtersToShow && (
          <div aria-label={_t('Filter by')} css={[FilterableShowMoreGridSectionStyles.chipGroup, styles.chipGroup]}>
            <div css={styles.radioGroup}>
              {times(filtersToShow, (i) => (
                <div key={i} css={styles.chip} />
              ))}
            </div>
          </div>
        )
      }
    />
  );
}

export default FilterableShowMoreGridSectionPlaceholder;
