import type { DependencyList } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useId } from '@coursera/cds-common';

export type Item<Element extends HTMLElement = HTMLElement> = {
  id: string;
  element: Element;
  groupId?: string;
};

type DeregisterItemCallback = () => void;
type RegisterItemCallback<ItemType> = (
  item: ItemType
) => DeregisterItemCallback;

export type ItemsCollection<
  Element extends HTMLElement = HTMLElement,
  CommonProps = any,
  Props = any
> = {
  /**
   * Common props. Every item in the collection can access them.
   */
  commonProps: CommonProps;
  /**
   * Array of item objects. They contain an HTMLElement and a unique ID to reference them.
   */
  items: Item<Element>[];
  /**
   * Individual item props by id.
   */
  props: Record<string, Props>;
  /**
   * Accepts an item to register. Returns cleanup function.
   */
  registerItem: RegisterItemCallback<Item<Element>>;
  /**
   * Accepts an item id and updated item props.
   */
  updateProps: (id: string, props: Props) => void;
};

const ItemsCollectionContext = React.createContext<ItemsCollection<
  any,
  any,
  any
> | null>(null);

/**
 * It is context provider component that is designed to manage and store registered items.
 * To use this provider, you should wrap any group of items with the `ItemsCollectionProvider` component.
 * Each individual items should use the `useRegisterItemInCollection` hook to register itself in the collection.
 */
export const ItemsCollectionProvider = <
  Element extends HTMLElement = HTMLElement,
  CommonProps = any,
  Props = any
>({
  children,
  commonProps,
}: {
  children:
    | React.ReactNode
    | ((
        collection: ItemsCollection<Element, CommonProps, Props>
      ) => React.ReactNode);
  commonProps: CommonProps;
}) => {
  const [items, setItems] = useState<Item<Element>[]>([]);
  const [props, setProps] = useState<Record<string, Props>>({});

  const registerItem = useCallback((item) => {
    setItems((prev) => [...prev, item]);

    const deregisterItem = () => {
      setItems((prev) => prev.filter(({ id }) => id !== item.id));
      setProps((prev) => {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { [item.id]: _, ...updatedProps } = prev;

        return updatedProps;
      });
    };

    return deregisterItem;
  }, []);

  const updateProps = useCallback((id: string, nextProps: Props) => {
    setProps((prev) => ({
      ...prev,
      [id]: nextProps,
    }));
  }, []);

  const collection = { items, registerItem, commonProps, props, updateProps };

  return (
    <ItemsCollectionContext.Provider value={collection}>
      {typeof children === 'function' ? children(collection) : children}
    </ItemsCollectionContext.Provider>
  );
};

/**
 * Returns an array of all items registered in the collection.
 *
 * Use `groupId` to filter items that belong to a specific items group.
 *
 * @see ItemsCollectionProvider
 * @see useRegisterItemInCollection
 */
export function useItemsCollection<
  Element extends HTMLElement = HTMLElement,
  CommonProps = any,
  Props = any
>() {
  const context = useContext<ItemsCollection<
    Element,
    CommonProps,
    Props
  > | null>(ItemsCollectionContext);

  if (!context) {
    return null;
  }

  return {
    items: context.items,
    props: context.props,
    commonProps: context.commonProps,
  };
}

/**
 * Registers an element with in the items collection.
 *
 * @see ItemsCollectionProvider
 * @see useItemsCollection
 */
export function useRegisterItemInCollection<
  Element extends HTMLElement = HTMLElement,
  CommonProps = any,
  Props extends { id?: string; groupId?: string } = {
    id?: string;
    groupId?: string;
  }
>(props: Props, deps?: DependencyList) {
  const id = useId(props?.id);
  const ref = useRef<Element>(null);

  const context = useContext<ItemsCollection<
    Element,
    CommonProps,
    Props
  > | null>(ItemsCollectionContext);

  useEffect(() => {
    return context?.updateProps(id, props);
  }, deps || []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    return context?.registerItem({
      element: ref.current,
      id,
      groupId: props?.groupId,
    });
  }, [id, props?.groupId]);

  return {
    ref,
    id,
    commonProps: context?.commonProps,
  };
}

/**
 * Returns the index of an item in a collection or the index of the item in the specified group if groupId is provided.
 *
 * @see ItemsCollectionProvider
 */
export function useItemIndexInCollection(id: string, groupId?: string) {
  const collection = useItemsCollection();

  if (!collection) {
    return -1;
  }

  const items = groupId
    ? collection.items.filter((item) => item.groupId === groupId)
    : collection.items;

  return items.findIndex((item) => item.id === id);
}
