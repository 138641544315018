import React from 'react';

import clsx from 'clsx';

import {
  useLocalizedStringFormatter,
  useId,
  IconContext,
} from '@coursera/cds-common';
import type { IconProps } from '@coursera/cds-common';

import type { BaseCheckboxProps } from '@core/forms';
import { CheckboxInput } from '@core/forms/CheckboxInput';
import type { ValidationStatus } from '@core/forms/FormControl';
import getRadioAndCheckboxCss, {
  classes,
} from '@core/forms/getRadioAndCheckboxCss';
import i18nMessages from '@core/forms/i18n';
import Typography from '@core/Typography2';
import { useControlled } from '@core/utils';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

export type Props = {
  /**
   * The element's unique identifier.
   * @ignore
   */
  id?: string;
  /**
   * CSS classname applied to the root element.
   */
  className?: string;
  /**
   * State of the component. When used as a controlled component, this prop controls whether the component appears as checked.
   */
  checked?: boolean;
  /**
   * If true, the checkbox will be disabled.
   */
  disabled?: boolean;
  /**
   * Primary label displayed next to the checkbox
   */
  label: string;
  /**
   * Secondary label displayed sticked to the right
   */
  labelSuffix?: string;
  /**
   * Defines support text for the field.
   */
  supportText?: React.ReactNode;
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  /**
   * Render an icon next to the checkbox.
   */
  icon?: React.ReactElement<IconProps>;
  /**
   * Sets the `name` attribute of the `input` element
   */
  name?: string;
  /**
   * Sets the `required` attribute on the `input` element
   * @default false
   */
  required?: boolean;
  /**
   * The value of the component. The DOM API casts this to a string.
   */
  value?: React.ReactText;
  /**
   * Ref that points to the `input` element node
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * If `true`, the component appears indeterminate.
   * This does not set the native input element to indeterminate due
   * to inconsistent behavior across browsers.
   * However, we set a `data-indeterminate` attribute on the input.
   */
  indeterminate?: boolean;
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  /**
   * Render optional text next to the label if true
   * @ignore
   */
  showOptionalMark?: boolean;
  /**
   * Defines field success/error styling.
   */
  validationStatus?: ValidationStatus;
  /**
   * Non-interactive content space.
   */
  children?: React.ReactNode;
  /**
   * The initial checked value. Use when the component is not controlled.
   */
  defaultChecked?: boolean;
};

const BaseCheckbox = React.forwardRef<HTMLDivElement, Props>(
  function BaseCheckbox(props, ref) {
    const stringFormatter = useLocalizedStringFormatter(i18nMessages);

    const {
      id: idFromProps,
      className,
      supportText,
      showOptionalMark,
      inputProps,
      icon,
      label,
      labelSuffix,
      inputRef,
      indeterminate,
      name,
      disabled,
      value,
      onChange,
      required,
      defaultChecked = false,
      checked: checkedFromProps,
      validationStatus,
      children,
      ...rest
    } = props;

    const id = useId(idFromProps);
    const labelTextId = `${id}-label-text`;
    const labelSuffixId = labelSuffix ? `${id}-label-suffix` : undefined;
    const supportTextId = supportText ? `${id}-support-text` : undefined;

    const [checked, setChecked] = useControlled<boolean>({
      controlled: checkedFromProps,
      default: defaultChecked,
      name: 'BaseCheckbox',
      state: 'checked',
    });

    const handleChange: BaseCheckboxProps['onChange'] = (event, value) => {
      setChecked(value);
      onChange?.(event, value);
    };

    return (
      <div
        ref={ref}
        className={clsx(className, {
          [classes.checked]: checked,
          [classes.indeterminate]: indeterminate,
          [classes.disabled]: disabled,
          [classes.success]: validationStatus === 'success',
          [classes.error]: validationStatus === 'error',
        })}
        css={getRadioAndCheckboxCss}
        {...rest}
      >
        <label className={classes.label}>
          <CheckboxInput
            checked={checked}
            className={classes.input}
            disabled={disabled}
            focusVisibleClassName={classes.focusVisible}
            id={id}
            indeterminate={indeterminate}
            inputProps={{
              'aria-labelledby': ariaLabelledByForMultipleLabels(
                labelTextId,
                labelSuffixId,
                supportTextId
              ),
              ...inputProps,
            }}
            inputRef={inputRef}
            name={name}
            required={required}
            value={value}
            onChange={handleChange}
          />

          {icon && (
            <div className={classes.icon}>
              <IconContext.Provider value={{ size: 'large' }}>
                {icon}
              </IconContext.Provider>
            </div>
          )}

          <div className={classes.labelText}>
            <Typography
              className={classes.labelContent}
              color="inherit"
              component="span"
              id={labelTextId}
              variant="bodyPrimary"
            >
              {label}
              {showOptionalMark && (
                <Typography
                  color={disabled ? 'inherit' : 'supportText'}
                  component="span"
                >
                  {' '}
                  {`(${stringFormatter.format('optional')})`}
                </Typography>
              )}
            </Typography>
            {labelSuffix && (
              <Typography
                className={classes.labelSuffix}
                color="inherit"
                component="span"
                id={labelSuffixId}
              >
                {labelSuffix}
              </Typography>
            )}
          </div>
        </label>
        {supportText && (
          <Typography
            className={clsx(classes.supportText, !!icon && classes.hasIcon)}
            component="div"
            id={supportTextId}
            variant="bodySecondary"
          >
            {supportText}
          </Typography>
        )}
        {children && (
          <div className={classes.nonInteractiveSpace}>{children}</div>
        )}
      </div>
    );
  }
);

export default BaseCheckbox;
