import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Accordion, AccordionHeader, AccordionPanel } from '@coursera/cds-core';

import MarkdownRender from 'bundles/cms/components/Markdown';
import type { FaqProps } from 'bundles/cms/types';
import { useTracker } from 'bundles/page/lib/event-pulse/react';
import useTrackLinks from 'bundles/premium-hub/hooks/useTrackLinks';

type Props = FaqProps & {
  variant?: 'standard' | 'silent';
  index?: number;
};

const AccordionFaq: React.FC<Props> = ({ question, answer, defaultExpanded, variant = 'standard', index }) => {
  const retrackedTrack = useRetracked();
  const trackV3 = useTracker();
  const handleEventingClick = useTrackLinks('degree_info_link', 'faq');

  if (!answer || !question) {
    return null;
  }

  const onChange = (e: unknown, expandState: boolean) => {
    const expandStateTracking = expandState ? 'expand' : 'collapse';
    const trackingName = index ? `toggle_faq_${expandStateTracking}_${index}` : `toggle_faq_${expandStateTracking}`;
    retrackedTrack({
      trackingData: {
        timestamp: Date.now(),
        question,
      },
      trackingName,
      action: `click`,
    });

    const v3TrackingName = expandState ? 'expand_accordion' : 'collapse_accordion';
    trackV3(v3TrackingName, {
      accordion: { name: 'faq', header: question, body: answer },
    });
  };

  return (
    <Accordion variant={variant} className="faq-item" defaultExpanded={defaultExpanded} onChange={onChange}>
      <AccordionHeader label={question} />
      <AccordionPanel onClick={handleEventingClick}>
        <MarkdownRender source={answer} />
      </AccordionPanel>
    </Accordion>
  );
};

export default AccordionFaq;
