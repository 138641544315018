import * as React from 'react';

import type { Location } from 'history';

import user from 'js/lib/user';

import PageNavigation from 'bundles/unified-home-common/components/consumer/PageNavigation';

export const getHeaderProps = (isSimplified: boolean, location?: Location) => {
  const isPreUnifiedNavigation = user.isAuthenticatedUser();
  const displayPageNavigation = isPreUnifiedNavigation && !location?.pathname.startsWith('/degrees/landing/');
  const pageNavigation = displayPageNavigation ? <PageNavigation /> : null;

  const defaultHeaderProps = {
    isScrollable: true,
    logoWrapper: 'div',
    isPageWithSecondaryCta: true,
    showEnterpriseReturnToProgramBanner: true,
    isSimplified: isPreUnifiedNavigation,
    subNavigationLinks: pageNavigation,
    hideMetaNav: isPreUnifiedNavigation,
  };

  const simplifiedHeaderProps = {
    isSimplified: false,
    subNavigationLinks: null,
    disableHeaderLogoUserInteraction: true,
    enableCourseraHeaderLogoOnly: true,
    hideAdminLinks: true,
    hideEnterprise: true,
    hideLogIn: true,
    hideMetaNav: true,
    hideMobileNavLinks: true,
    hideNotificationCenter: true,
    hideSearch: true,
    isPageWithSecondaryCta: true,
    showAuthModal: undefined,
    showLanguagesDropdown: true,
    showShoppingCart: false,
  };

  return {
    ...defaultHeaderProps,
    ...(isSimplified ? simplifiedHeaderProps : {}),
  };
};
