/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import DegreesConversion from 'bundles/epic/data/defaults/DegreesConversion.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  performancePathwayDegrees: any;
  personalizedDdpApplyMessageType: 'none' | 'tooltip' | 'embedded' | 'mixed';
  personalizedDdpPrograms: any;
  lightweightDdp: boolean;
  showAppFeeWaiver: boolean;
  showCtaModuleDeadline: any;
  showMcitRenewal: boolean;
  showCourseStarRatings: boolean;
  shouldEnablePingTracker: boolean;
  moveStackabilityModule: boolean;
  masEngineeringBerkeleyCopyTest: boolean;
  showCtaSocialProof: 'control' | 'variantA' | 'variantB';
  manualCopyDartmouth: boolean;
  manualCopyGeorgetown: boolean;
  manualCopyImba: boolean;
  manualCopyLondon: boolean;
  manualCopyOmie: boolean;
  manualCopyHuddersfield: boolean;
  manualCopyNortheastern: boolean;
  manualCopyBoulder: boolean;
  manualCopyIllinoisTech: boolean;
  manualCopyCompsciIllinois: boolean;
  qualifyingQuestions: 'control' | 'A' | 'B';
};

const NAMESPACE = 'DegreesConversion';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([DegreesConversion as $TSFixMe]);

const DegreesConversionEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default DegreesConversionEpicClient;
