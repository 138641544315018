import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('paginationItem', [
  'selected',
  'default',
]);

const getPaginationItemCss = css`
  &.${classes.selected} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-interactive-primary-pressed);
    box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-pressed);

    &:hover {
      background-color: var(--cds-color-interactive-primary-hover);
      box-shadow: inset 0 0 0 1px var(--cds-color-interactive-primary-hover);
    }
  }

  &.${classes.default} {
    height: 36px;
    width: 36px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export default getPaginationItemCss;
