import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('formValidationStatus', [
  'root',
  'valid',
  'invalid',
  'icon',
  'onLight',
  'onDark',
]);

const getFormValidationStatusCss = css`
  &.${classes.root} {
    fill: transparent;
    display: flex;
    align-items: center;
    border-radius: var(--cds-border-radius-50);
    padding: var(--cds-spacing-50) var(--cds-spacing-100);
    ${typography.bodySecondary}
  }

  .${classes.icon} {
    flex: 0 0 auto;
    margin-right: var(--cds-spacing-100);
  }

  &.${classes.valid} {
    .${classes.icon} {
      color: var(--cds-color-feedback-success);
    }

    &.${classes.onDark} {
      background-color: var(--cds-color-feedback-success-invert-weak);
    }

    &.${classes.onLight} {
      background-color: var(--cds-color-feedback-background-success-weak);
    }
  }

  &.${classes.invalid} {
    .${classes.icon} {
      color: var(--cds-color-feedback-error);
    }

    &.${classes.onDark} {
      background-color: var(--cds-color-feedback-error-invert-weak);
    }

    &.${classes.onLight} {
      background-color: var(--cds-color-feedback-background-error-weak);
    }
  }
`;

export default getFormValidationStatusCss;
