import * as React from 'react';
import Media from 'react-media';

import user from 'js/lib/user';

import { isMetaNavEnabled } from 'bundles/ent-website/components/MetaNavigation';
import HeaderRightNavButton from 'bundles/page-header/components/HeaderRightNavButton';

import _t from 'i18n!nls/page';

export type Props = {
  hideEnterprise?: boolean;
  showAdminLinks?: boolean;
  showExploreCatalog?: boolean;
};

const EnterpriseLink = ({ hideEnterprise, showAdminLinks, showExploreCatalog }: Props) => {
  const isEnabled = isMetaNavEnabled();

  if (isEnabled) {
    return null;
  }

  /* Hide `For Enterprise` link only for pages under `/admin` and `/teach` route (apps) */
  const shouldShowEnterpriseLink = !hideEnterprise && !showAdminLinks && !showExploreCatalog;
  const inOut = user.isAuthenticatedUser() ? 'in' : 'out';

  if (shouldShowEnterpriseLink) {
    const label = _t('For Enterprise');
    const forEnterpriseButtonProps = {
      href: `/business/?utm_campaign=website&utm_content=corp-to-home-for-enterprise&utm_medium=coursera&utm_source=header&utm_term=a-${inOut}`,
      label,
      name: 'enterprise',
      noBorder: true,
      wrapperClassName: 'c-ph-enterprise',
    };
    return (
      <Media query={{ maxWidth: 1200, minWidth: 925 }} defaultMatches={false}>
        {(matches) =>
          matches ? (
            <HeaderRightNavButton
              {...forEnterpriseButtonProps}
              key={label}
              buttonWrapperType="span"
              label={_t('Enterprise')}
            />
          ) : (
            <HeaderRightNavButton {...forEnterpriseButtonProps} buttonWrapperType="span" key={label} />
          )
        }
      </Media>
    );
  } else {
    return null;
  }
};

export default EnterpriseLink;
