import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import { getContentWidth } from './contentWidthCss';
import type { WidthOptions } from './contentWidthCss';

export const classes = generateEmotionClasses('FullscreenDialogHeadingGroup', [
  'headerGroup',
  'freeContent',
]);

export const getHeadingGroupCss = (options: WidthOptions) => {
  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0 var(--cds-spacing-300);
    padding: var(--cds-spacing-400) var(--cds-spacing-600)
      var(--cds-spacing-600) var(--cds-spacing-600);

    ${getContentWidth(options)}

    ${breakpoints.down('sm')} {
      padding: var(--cds-spacing-400) var(--cds-spacing-200)
        var(--cds-spacing-600) var(--cds-spacing-200);
    }

    ${breakpoints.down('xs')} {
      flex-direction: column;
      gap: var(--cds-spacing-100) 0;
      padding: var(--cds-spacing-300) var(--cds-spacing-200)
        var(--cds-spacing-300) var(--cds-spacing-200);
    }

    .${classes.headerGroup} {
      flex: 1 1 auto;
    }

    .${classes.freeContent} {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-end;
      flex: 1 1 auto;

      ${breakpoints.down('xs')} {
        justify-content: flex-start;
      }
    }
  `;
};
