import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import { SvgApple } from '@coursera/coursera-ui/svg';

import SocialButton from 'bundles/authentication/components/SSOButton';
import useAuthenticationModalTracking from 'bundles/authentication/shared/hooks/useAuthenticationModalTracking';
import withSocialSSO from 'bundles/authentication/with/SocialSSO';
import withSingleTracked from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/authentication';

const TrackedSocialButton = withSingleTracked({ type: 'BUTTON' })(SocialButton);

type Props = {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  btnLabel?: string;
  customStyles?: SerializedStyles;
  iconColor?: string;
};

const Apple = React.forwardRef(
  ({ className, disabled = false, onClick, btnLabel, customStyles, iconColor }: Props, ref: React.Ref<HTMLElement>) => {
    const handleOnClick = useAuthenticationModalTracking({ onClick, buttonName: 'signup_with_apple' });
    return (
      <TrackedSocialButton
        className={className}
        customStyles={customStyles}
        disabled={disabled}
        icon={SvgApple}
        iconColor={iconColor}
        onClick={handleOnClick}
        trackingName="continue_with_apple_auth_btn"
        _refAltAsTrueRef={ref}
      >
        {btnLabel ?? _t('Continue with Apple')}
      </TrackedSocialButton>
    );
  }
);
Apple.displayName = 'Apple';

export default withSocialSSO(Apple, 'apple');
