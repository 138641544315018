import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';

import { getChatTargetingRule } from 'bundles/common/utils/chatLoaderUtils';
import BlueJaysExperiments from 'bundles/epic/clients/BlueJays';

type TargetingRule = {
  urlPattern: string;
};
const defaultUrlExclusionsPattern = '^/(?:programs|teach|admin|business|internal|learn/[a-zA-Z0-9-]+/)';

export const getUrlExclusionsRegex = () => {
  const urlExclusionsPattern: string =
    BlueJaysExperiments.preview('globalHelpButtonUrlExclusionsRegex') || defaultUrlExclusionsPattern; // using preview() because this is a config value unrelated to experimentation
  try {
    return new RegExp(urlExclusionsPattern);
  } catch (err) {
    logger.error(
      'EPIC config BlueJays.globalHelpButtonUrlExclusionsRegex is an invalid regular expression.  Using default value.',
      err
    );
    return new RegExp(defaultUrlExclusionsPattern);
  }
};

// To test admin/educator chat, run the following in the browser JS console:
/*
const epicOverrides = JSON.parse(localStorage.getItem('EpicOverrides')) || {};
epicOverrides.BlueJays = epicOverrides.BlueJays || {};
epicOverrides.BlueJays.enableAdminChat = true; // true or false
epicOverrides.BlueJays.enableEducatorChat = true; // true or false
epicOverrides.BlueJays.adminUser = true; // true or false
epicOverrides.BlueJays.educatorUser = true; // true or false
epicOverrides.BlueJays.adminChatConfig = {
  "targetingRules": [
    { "urlPattern": "^/o/[a-zA-Z0-9-]+/admin/home$" },
    { "urlPattern": "^/o/[a-zA-Z0-9-]+/admin/users/permissions$" },
    { "urlPattern": "^/o/[a-zA-Z0-9-]+/admin/users$" },
    { "urlPattern": "^/o/[a-zA-Z0-9-]+/admin/programs/[a-zA-Z0-9-]+/main$" },
    { "urlPattern": "^/o/[a-zA-Z0-9-]+/admin/analytics/learner-activity$" },
  ],
  "operatingHours": {
      "startTime": "08:00",
      "endTime": "17:00"
    }
};
epicOverrides.BlueJays.educatorChatConfig = {
  targetingRules: [
    { urlPattern: '^/teach/[a-zA-Z0-9-]+/course/overview$' },
    { urlPattern: '^/admin$' },
    { urlPattern: '^/admin/courses$' },
    { urlPattern: '^/admin/institutions/settings/staff$' },
    { urlPattern: '^/admin/institutions/settings/university-assets$' },
  ],
  "operatingHours": {
      "startTime": "08:00",
      "endTime": "17:00"
    }
};
localStorage.setItem('EpicOverrides', JSON.stringify(epicOverrides));
*/

export const getAdminChatTargetingRule = (url: string) => {
  const adminChatTargetingRule: TargetingRule[] = BlueJaysExperiments.preview('adminChatConfig')?.targetingRules ?? [];
  try {
    return adminChatTargetingRule.find((rule) => new RegExp(rule.urlPattern).test(url));
  } catch (err) {
    logger.error(
      'Error with one or more admin chat targeting rules. Check that all urlPattern regexes are valid.',
      err
    );
    return undefined;
  }
};

export const getEducatorChatTargetingRule = (url: string) => {
  const educatorChatTargetingRule: TargetingRule[] =
    BlueJaysExperiments.preview('educatorChatConfig')?.targetingRules ?? [];
  try {
    return educatorChatTargetingRule.find((rule) => new RegExp(rule.urlPattern).test(url));
  } catch (err) {
    logger.error(
      'Error with one or more educator chat targeting rules. Check that all urlPattern regexes are valid.',
      err
    );
    return undefined;
  }
};

// Default admin chat hours are 8am-5pm PST
export const previewAdminChatHours = () => {
  return BlueJaysExperiments.preview('adminChatConfig')?.operatingHours ?? { startTime: '08:00', endTime: '17:00' };
};

// Default educator chat hours are 8am-5pm PST
export const previewEducatorChatHours = () => {
  return BlueJaysExperiments.preview('educatorChatConfig')?.operatingHours ?? { startTime: '08:00', endTime: '17:00' };
};

export const previewUserAdminEducator = () => {
  const isAdmin = BlueJaysExperiments.preview('adminUser');
  const isEducator = BlueJaysExperiments.preview('educatorUser');
  return { isAdmin, isEducator };
};

export const getShowGlobalHelpButton = (urlPath = location.pathname) => {
  /*
  The global help button is used in the following:
  1. Access to Support Experiment #5 (admin/educator chat)
  2. On most learner facing pages to link a user to the help center

  The Boost chat button and global help button are in the same location on the page. The Boost chat button overrides the global help button on a pages where a user might see both, they only see the Boost button.
  */

  const isCourserian = user.isCourserian();
  // Using preview() because we want to check if a user is in an experiment without creating an impression. The impression point should be when a user clicks the global help or chat button.
  const isTargetedByBoostEpic = BlueJaysExperiments.preview('enableChat');
  const isTargetedByAdminEpic = BlueJaysExperiments.preview('enableAdminChat');
  const isTargetedByEducatorEpic = BlueJaysExperiments.preview('enableEducatorChat');
  const { isAdmin, isEducator } = previewUserAdminEducator();

  if (!user.get()?.authenticated) {
    return { showGlobalHelpButton: false }; // don't show global help button to anonymous users
  }
  // User is in educator chat experiment && on an educator chat page && is educator && is not a Courserian
  if (isTargetedByEducatorEpic && !!getEducatorChatTargetingRule(urlPath) && isEducator && !isCourserian) {
    return { showGlobalHelpButton: true, isEducatorPage: true };
  }
  // User is not in educator chat experiment && on an educator chat page && is educator && is not a Courserian
  if (!isTargetedByEducatorEpic && !!getEducatorChatTargetingRule(urlPath) && isEducator && !isCourserian) {
    return { showGlobalHelpButton: true, isEducatorPage: true };
  }
  // User is in admin chat experiment && on an admin chat page && is admin && is not a Courserian
  if (isTargetedByAdminEpic && !!getAdminChatTargetingRule(urlPath) && isAdmin && !isCourserian) {
    return { showGlobalHelpButton: true, isAdminPage: true };
  }
  // User is not in admin chat experiment && on an admin chat page && is admin && is not a Courserian
  if (!isTargetedByAdminEpic && !!getAdminChatTargetingRule(urlPath) && isAdmin && !isCourserian) {
    return { showGlobalHelpButton: true, isAdminPage: true };
  }
  // User is in boost chat experiment && not on boost chat page && on learner global help page
  if (isTargetedByBoostEpic && !getChatTargetingRule(urlPath) && !getUrlExclusionsRegex().test(urlPath)) {
    return { showGlobalHelpButton: true };
  }
  // User not in boost chat experiment && on learner global help page
  if (!isTargetedByBoostEpic && !getUrlExclusionsRegex().test(urlPath)) {
    return { showGlobalHelpButton: true };
  }
  return { showGlobalHelpButton: false };
};

export const getEnableAdminChat = () => BlueJaysExperiments.get('enableAdminChat');
export const getEnableEducatorChat = () => BlueJaysExperiments.get('enableEducatorChat');
