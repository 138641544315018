import React from 'react';

import type { ForwardRefComponent } from '@coursera/cds-common';

import type { CommonActionsProps } from '@core/dialogs/common/types';

import { useDialogContext } from './DialogContextProvider';
import { getActionsCss } from './styles';

type ActionsType = ForwardRefComponent<HTMLDivElement, Props>;

export type Props = CommonActionsProps;

const Actions: ActionsType = React.forwardRef(function Actions(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, ...rest } = props;

  const { contentWidth } = useDialogContext();

  return (
    <div ref={ref} css={getActionsCss({ contentWidth })} {...rest}>
      {children}
    </div>
  );
});

export default Actions;
