import React, { useContext } from 'react';

export const PopoverContext = React.createContext<{
  onClose?: (
    event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;

  layout: 'drawer' | 'dropdown';
}>({ layout: 'dropdown' });

export function usePopoverContext() {
  return useContext(PopoverContext);
}
