import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

export const showBucketTestV2 = () => {
  return GrowthDiscoveryEpicClient.preview('LOHPBucketTestV2') !== 'control';
};

export const getBucketTestV2Variant = () => {
  return GrowthDiscoveryEpicClient.preview('LOHPBucketTestV2');
};

export const impressIntoBucketTestV2 = () => {
  return GrowthDiscoveryEpicClient.get('LOHPBucketTestV2');
};
