/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import * as React from 'react';

import { Button } from '@coursera/cds-core';
import { CommentMultipleIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/common';

type Props = {
  onClick: () => void;
  show?: boolean;
  altText?: string;
  /** When isInlineButton is true we display a regular button where the component is inserted, instead of a button floating on the bottom right of the page */
  isInlineButton?: boolean;
};

const getDefaultAltText = () => _t('Chat with us');

const chatButtonAppearKeyframes = keyframes({
  from: {
    transform: 'scale(0)',
  },
  to: {
    transform: 'scale(1)',
  },
});

const floatingButtonStyles = {
  chatButtonContainer: css`
    /* Positioned to cover over top of the bottom right help button for now */
    z-index: 2000;
    position: fixed;
    right: var(--cds-spacing-150);
    bottom: var(--cds-spacing-150);
    animation: ${chatButtonAppearKeyframes} 0.2s ease-out both;
  `,
  chatButton: css`
    width: 56px;
    height: 56px;
    padding: var(--cds-spacing-150) !important;
    border-radius: 50% !important;
    box-shadow: 2px 2px 5px 0 rgba(31 31 31 / 50%) !important; /* cannot set reduced opacity on a shadow using CDS theme.palette colors */
    .cds-button-endIcon {
      margin: 0; /* the icon is positioned assuming there is text in the button, but our button text is only for screen readers, so we have to remove the margin */
    }
  `,
};

const inlineButtonStyles = {
  chatButtonContainer: {},
  chatButton: {},
};

/**
 * Renders a Chat icon button that will animate in from the lower right of the screen
 */
const ChatButton: React.FunctionComponent<Props> = ({
  onClick,
  show = true,
  altText = getDefaultAltText(),
  isInlineButton = false,
}) => {
  const styles = isInlineButton ? inlineButtonStyles : floatingButtonStyles;
  const iconSize = isInlineButton ? 20 : 32;

  // We use a show prop, instead of having the parent component do `{{show && <ChatButton />}}` because sudden removal from the component tree would make it hard to implement a disappearance animation in the future
  if (!show) {
    return null;
  }

  return (
    <div css={[styles.chatButtonContainer]} id="chat-button-container">
      <Button
        variant="primary"
        css={[styles.chatButton]}
        onClick={onClick}
        icon={<CommentMultipleIcon title={altText} width={iconSize} height={iconSize} color="invert" />}
        iconPosition="after"
      >
        {isInlineButton && _t('Start chat')}
      </Button>
    </div>
  );
};

export default ChatButton;
