import React, { useContext } from 'react';

import useTheme from '@common/theme/useTheme';
import useId from '@common/utils/useId';

import type { IconProps } from './context';
import { IconContext } from './context';
import getSvgIconCss from './getSvgIconCss';

type Props = IconProps & {
  children: React.ReactNode;
  /**
   * Should the icon be flipped for right-to-left languages.
   * @default false
   */
  shouldFlipForRTL?: boolean;
};

/**
 * Private module reserved for @coursera/cds-core package.
 */
const SvgIcon = (props: Props, ref: React.Ref<SVGSVGElement>) => {
  const theme = useTheme();
  const id = useId(props.id);
  const context = useContext(IconContext);

  const titleId = `${id}-title`;
  const descId = `${id}-description`;

  const {
    size = 'medium',
    shouldFlipForRTL = false,
    title,
    children,
    color,
    desc,
    ...rest
  } = { ...props, ...context };

  const sizes = {
    small: '16',
    medium: '20',
    large: '24',
  };

  const sizeInt = sizes[size];
  const viewBox = `0 0 ${sizeInt} ${sizeInt}`;
  const flipDirection = theme.direction === 'rtl' && shouldFlipForRTL;
  // using labelling strategy from https://developer.paciellogroup.com/blog/2013/12/using-aria-enhance-svg-accessibility/
  const ariaLabelledBy = [
    title ? titleId : undefined,
    desc ? descId : undefined,
  ]
    .filter((id) => Boolean(id))
    .join(' ');

  return (
    <svg
      ref={ref}
      aria-hidden={title ? undefined : true}
      aria-labelledby={ariaLabelledBy || undefined}
      css={color ? getSvgIconCss(theme, color) : undefined}
      fill="none"
      focusable="false"
      height={sizeInt}
      role={title ? 'img' : undefined}
      transform={flipDirection ? 'scale(-1,1)' : undefined}
      viewBox={viewBox}
      width={sizeInt}
      {...rest}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      {desc ? <desc id={descId}>{desc}</desc> : null}

      {children}
    </svg>
  );
};

export default React.forwardRef(SvgIcon);
