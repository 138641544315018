import React from 'react';

import type { ModalProps as MuiModalProps } from '@material-ui/core';
import { Modal as MuiModal, Backdrop as MuiBackdrop } from '@material-ui/core';

import { useTheme, useId, breakpoints } from '@coursera/cds-common';
import type { BaseComponentProps } from '@coursera/cds-common';

import ModalTransition from '@core/Modal/ModalTransition';
import type { Props as TransitionProps } from '@core/Modal/ModalTransition';
import { useMediaQuery } from '@core/utils';

import type { Props as FocusTrapProps } from './FocusTrap';
import ModalContainer from './ModalContainer';
import { classes as modalClasses, getModalCss } from './modalCss';
import { useTransitionDuration } from './useTransitionDuration';

function toggleAriaHidden(element: Element, show: boolean) {
  if (show) {
    element.setAttribute('aria-hidden', 'true');
  } else {
    element.removeAttribute('aria-hidden');
  }
}

function getHiddenChildren(container: Element) {
  const hiddenSiblings: Element[] = [];
  Array.from(container.children).forEach((element) => {
    if (
      element.getAttribute &&
      element.getAttribute('aria-hidden') === 'true'
    ) {
      hiddenSiblings.push(element);
    }
  });
  return hiddenSiblings;
}

type BaseProps = FocusTrapProps & Omit<BaseComponentProps<'div'>, 'children'>;

export type Props = BaseProps & {
  children: React.ReactNode | React.ReactNode[];

  /**
   * If `true`, the component is visible. Use with `onClose` to respond to user
   * interaction with the component.
   *
   * @see onClose
   */
  open: boolean;

  /**
   * Callback invoked when the component requests to be closed via the `esc` key,
   * background overlay click or a close button click.
   *
   * @see open
   */
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;

  /**
   * Renders the component inside the current DOM hierarchy, instead of creating
   * a new DOM root. Set this flag if rendering as an open component on the server.
   *
   * To properly handle focus behavior it's recommended to use this prop in conjunction with `keepMounted` and `returnFocusRef`.
   *
   */
  renderInPlace?: MuiModalProps['disablePortal'];

  /**
   * Always keep the children in the DOM.
   * This prop can be useful when the content of the modal is important for SEO and has to be in DOM.
   */
  keepMounted?: boolean;

  /**
   * Animation to use when transitioning between open and closed.
   *
   * @default fade-in
   */
  transition?: TransitionProps['variant'];
};

const Modal = React.forwardRef(function Modal(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    children,
    open,
    initialFocusRef,
    returnFocusRef,
    disableFocusLock,
    keepMounted,
    renderInPlace,
    transition = 'fade-in',
    onClose,
    id: idProp,
    ...rest
  } = props;

  const onCloseHandler = React.useCallback(
    (event) => onClose && onClose(event), // suppress MUI reason parameter
    [onClose]
  );
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));

  const transitionDuration = useTransitionDuration({ open });

  const id = useId(idProp);
  const contentRef = React.useRef(null);

  React.useEffect(
    function handleRenderInPlace() {
      if (typeof document !== 'undefined' && renderInPlace) {
        // we don't allow to specify container so it's always body for our modals
        // MUI hides all direct children of body even with `disablePortal`.
        // This removes aria-hidden from direct-children
        const hiddenChildren = getHiddenChildren(document.body);
        hiddenChildren.forEach((el) => {
          toggleAriaHidden(el, false);
        });
      }
    },
    [renderInPlace, open]
  );

  return (
    <MuiModal
      ref={ref}
      closeAfterTransition
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      BackdropComponent={MuiBackdrop}
      BackdropProps={{
        classes: { root: modalClasses.backdrop },
        transitionDuration,
      }}
      css={getModalCss()}
      dir={theme.direction}
      disablePortal={renderInPlace}
      id={id}
      keepMounted={keepMounted}
      open={open}
      onClose={onCloseHandler}
      {...rest}
    >
      <ModalTransition
        in={open}
        timeout={transitionDuration}
        variant={isSmallScreen ? 'slide-up' : transition}
      >
        <ModalContainer
          ref={contentRef}
          // when component is always mounted re-enable focus lock every time component opens
          disableFocusLock={keepMounted ? !open : disableFocusLock}
          initialFocusRef={initialFocusRef}
          returnFocusRef={returnFocusRef}
          onBackdropClick={onCloseHandler}
        >
          {children}
        </ModalContainer>
      </ModalTransition>
    </MuiModal>
  );
});

export default Modal;
