/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { getSearchFilterOptionItemTranslations } from 'bundles/search-common/utils/getFilterItemsTranslations';
import { formatNumberForLocale } from 'bundles/search-common/utils/utils';
import { useSeoPageData } from 'bundles/seo/contexts/SeoPageDataContext';

import _t from 'i18n!nls/search-common';

type Props = {
  searchQuery?: string;
  numberOfHits?: number;
  activeFilters?: string[];
  hitCountRounder?: (count: number) => number;
  hideHits: boolean;
};

function toDisplayType(searchQuery: string | undefined, hasActiveFilters: boolean) {
  if (searchQuery && hasActiveFilters) {
    return 'filtered';
  } else if (searchQuery) {
    return 'searched';
  } else {
    return 'default';
  }
}

export const SearchResultsHeaderText = (props: Props) => {
  const { searchQuery, numberOfHits = 0, activeFilters, hitCountRounder = (x) => x, hideHits } = props;
  const hasActiveFilters = (activeFilters?.length ?? 0) > 0;
  const { eqpData } = useSeoPageData();

  if (numberOfHits > 0) {
    const seoHeader = eqpData?.headers?.searchResultsHeader?.label;
    if (seoHeader) {
      return <FormattedMessage message={seoHeader} />;
    }
    const rounded = hitCountRounder(numberOfHits);
    const isRounded = rounded < numberOfHits;

    const displayType = toDisplayType(searchQuery, hasActiveFilters);

    switch (displayType) {
      case 'filtered': {
        const translatedFilters = activeFilters
          ?.map((filter) => {
            const [category, label] = filter.split(':');
            const optionItemTranslations = getSearchFilterOptionItemTranslations()[category];
            const translatedItem = optionItemTranslations?.[label] || label || category;
            return typeof translatedItem !== 'string' ? translatedItem?.title : translatedItem;
          })
          .join(', ');
        if (hideHits) {
          return (
            <FormattedMessage
              message={_t(
                '{numberOfHits, plural, =1 { Result} other { Results}} for "{query}" filtered by {translatedFilters}'
              )}
              numberOfHits={formatNumberForLocale(rounded)}
              query={searchQuery}
              translatedFilters={translatedFilters}
            />
          );
        } else if (isRounded) {
          return (
            <FormattedMessage
              message={_t(
                'Over {numberOfHits} {numberOfHits, plural, =1 { result} other { results}} for "{query}" filtered by {translatedFilters}'
              )}
              numberOfHits={formatNumberForLocale(rounded)}
              query={searchQuery}
              translatedFilters={translatedFilters}
            />
          );
        } else {
          return (
            <FormattedMessage
              message={_t(
                '{numberOfHits} {numberOfHits, plural, =1 { result} other { results}} for "{query}" filtered by {translatedFilters}'
              )}
              numberOfHits={formatNumberForLocale(numberOfHits)}
              query={searchQuery}
              translatedFilters={translatedFilters}
            />
          );
        }
      }
      case 'searched': {
        if (hideHits) {
          return (
            <FormattedMessage
              message={_t('{numberOfHits, plural, =1 { Result} other { Results}} for "{query}"')}
              numberOfHits={formatNumberForLocale(rounded)}
              query={searchQuery}
            />
          );
        } else if (isRounded) {
          return (
            <FormattedMessage
              message={_t('Over {numberOfHits} {numberOfHits, plural, =1 { result} other { results}} for "{query}"')}
              numberOfHits={formatNumberForLocale(rounded)}
              query={searchQuery}
            />
          );
        } else {
          return (
            <FormattedMessage
              message={_t('{numberOfHits} {numberOfHits, plural, =1 { result} other { results}} for "{query}"')}
              numberOfHits={formatNumberForLocale(numberOfHits)}
              query={searchQuery}
            />
          );
        }
      }
      case 'default':
      default: {
        if (hideHits) {
          return <React.Fragment>{_t('All results')}</React.Fragment>;
        } else if (isRounded) {
          return (
            <FormattedMessage
              message={_t('Over {numberOfHits} {numberOfHits, plural, =1 { result} other { results}}')}
              numberOfHits={formatNumberForLocale(rounded)}
            />
          );
        } else {
          return (
            <FormattedMessage
              message={_t('{numberOfHits} {numberOfHits, plural, =1 { result} other { results}}')}
              numberOfHits={formatNumberForLocale(numberOfHits)}
            />
          );
        }
      }
    }
  } else {
    return (
      <FormattedMessage
        message={searchQuery ? _t('No results found for "{query}"') : _t('No results found for your search')}
        query={searchQuery}
      />
    );
  }
};
