/** @jsxFrag React.Fragment */
import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';

import PremiumHubPageHeader from 'bundles/premium-hub/components/shared/PremiumHubPageHeader';
import { HubPages } from 'bundles/premium-hub/constants';
import useLearnBrowse from 'bundles/premium-hub/hooks/useLearnBrowse';
import usePremiumHubPageData from 'bundles/premium-hub/hooks/usePremiumHubPageData';
import type { PremiumProductProps } from 'bundles/premium-hub/hooks/withPremiumHubPremiumProductCollections';
import OverviewPageContent from 'bundles/premium-hub/pages/subpages/OverviewPageContent';
import { convertPpcPremiumProductToPremiumProductProps } from 'bundles/premium-hub/utils/dataTransformationUtils';
import DegreesCategorySchemaMarkup from 'bundles/seo/components/DegreesCategorySchemaMarkup';

import _t from 'i18n!nls/premium-hub';

const DegreeProgramLevelHubPageContent: React.FC = () => {
  const pageType = HubPages.ProductVariantHubPage;
  const { slug } = useLearnBrowse(pageType);
  const premiumHubPageData = usePremiumHubPageData({ slug, pageType });

  if (!premiumHubPageData) {
    return null;
  }

  const { degreeCategories, categoryData, premiumProductList, useSimplifiedHeader = false } = premiumHubPageData;

  const degreesForSeo: Array<{ degreeType: string; title: string; degrees: PremiumProductProps[] }> =
    degreeCategories?.map((degreeCategory) => {
      const degreesInCategory = premiumProductList
        ?.filter((degree) => degree.categoryIds.includes(degreeCategory.id))
        .map(convertPpcPremiumProductToPremiumProductProps);
      return {
        degreeType: slug,
        title: `${degreeCategory.name} ${categoryData?.name ?? _t('Degrees')}`,
        degrees: degreesInCategory ?? [],
      };
    }) ?? [];

  return (
    <>
      <PremiumHubPageHeader isSimplified={useSimplifiedHeader} />
      <main id="main" className="rc-DegreesVariantHubPage" data-testid="degree-program-level-hub-page">
        <DegreesCategorySchemaMarkup allDegreeLists={degreesForSeo} currentHubPage={pageType} />
        <OverviewPageContent {...premiumHubPageData} currentPageType={pageType} shouldShowDriftChat={false} />
      </main>
    </>
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_hub',
      page: 'degree_sub',
    },
  }))
)(DegreeProgramLevelHubPageContent);
