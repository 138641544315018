import { useContext } from 'react';

import { CourseToolBarPluginContext } from 'bundles/course-v2/components/navigation/CourseToolBar/CourseToolBarPluginProvider';

export const useCourseToolBarPlugins = () => {
  const context = useContext(CourseToolBarPluginContext);
  if (!context) {
    // Return default values or handle the lack of context gracefully
    return {
      plugins: [],
      toolSelected: null,
      setToolSelected: () => {},
      expanded: false,
      setExpanded: () => {},
      setPlugins: () => {},
      closeTools: () => {},
    };
  }

  return context;
};
