import { Search_EntityType as EntityType, Search_SortBy as SortBy } from '__generated__/graphql-types';

import {
  ALL_PAGES_PRESET_FILTER_VALUES,
  DEFAULT_SEARCH_FILTERS,
  DOMAIN_CATEGORY_HIDDEN_FILTERS,
  DOMAIN_CATEGORY_SLUG_TO_FILTER_VALUES_MAP,
  LANDING_PAGE_SEARCH_FILTERS,
  PROGRAM_LEVEL_HIDDEN_FILTERS,
  PROGRAM_LEVEL_SLUG_TO_FILTER_VALUES,
  RESULTS_PER_PAGE,
  TOP_REGIONAL_HIDDEN_FILTERS,
  TOP_REGIONAL_SLUGS,
} from 'bundles/premium-hub/components/search/searchConstants';
import { HubPages, type PremiumHubPageType } from 'bundles/premium-hub/constants';
import type { PremiumProductWithMetadata } from 'bundles/premium-hub/types';
import { SEARCH_FILTERS_INDEX_ID, SEARCH_MAIN_INDEX_ID } from 'bundles/search-common/constants';
import type { SearchHit } from 'bundles/search-common/providers/searchTypes';

export const getSearchConfigs = (presetFilters?: string[], hiddenFilters?: string[], pageType?: PremiumHubPageType) => {
  const defaultFilters = pageType === HubPages.LandingPage ? LANDING_PAGE_SEARCH_FILTERS : DEFAULT_SEARCH_FILTERS;
  const facetFilters = presetFilters
    ? [[...ALL_PAGES_PRESET_FILTER_VALUES], [...presetFilters]]
    : [[...ALL_PAGES_PRESET_FILTER_VALUES]];
  return [
    {
      id: SEARCH_MAIN_INDEX_ID,
      entityType: EntityType.Products,
      limit: RESULTS_PER_PAGE,
      facets: defaultFilters.filter((filter) => !hiddenFilters?.includes(filter)),
      facetFilters,
      syncQueryWithUrl: true,
      syncFiltersWithUrl: false,
      sortBy: SortBy.BestMatch,
    },
    {
      id: SEARCH_FILTERS_INDEX_ID,
      entityType: EntityType.Products,
      limit: RESULTS_PER_PAGE,
      facets: defaultFilters.filter((filter) => !hiddenFilters?.includes(filter)),
      facetFilters,
      syncQueryWithUrl: true,
      syncFiltersWithUrl: false,
    },
  ];
};

/*
  Strips out the base~ or degree~ prefix from the id
*/
const extractId = (id: string) => (id.includes('~') ? id.split('~')[1] : id);

/*
  Takes a list of degrees from Algolia, and updates the order of the degrees to match the DRE ordered items from PremiumProductCollection
*/
export const orderDegreesByRank = (degrees: SearchHit[] = [], dreOrderedItems: PremiumProductWithMetadata[] = []) => {
  if (dreOrderedItems.length === 0) return degrees;

  /* 
    Use the length of degrees as the default index for unlisted items. 
    This will place unranked items at the end of the list.
  */
  const maxIndex = degrees.length;

  return [...degrees].sort((a, b) => {
    const aIndex = dreOrderedItems.findIndex((dreProduct) => extractId(dreProduct.id ?? '') === extractId(a.id || ''));
    const bIndex = dreOrderedItems.findIndex((dreProduct) => extractId(dreProduct.id ?? '') === extractId(b.id || ''));

    // Assign maxIndex to items not found in dreOrderedItems
    const adjustedAIndex = aIndex !== -1 ? aIndex : maxIndex;
    const adjustedBIndex = bIndex !== -1 ? bIndex : maxIndex;

    return adjustedAIndex - adjustedBIndex;
  });
};

export const getProgramLevelHubFilterSettings = (pageSlug: string) => {
  return {
    presetFilters: PROGRAM_LEVEL_SLUG_TO_FILTER_VALUES[pageSlug as keyof typeof PROGRAM_LEVEL_SLUG_TO_FILTER_VALUES],
    hiddenFilters: PROGRAM_LEVEL_HIDDEN_FILTERS,
  };
};

export const getDomainCategoryFilterSettings = (domainSlug: string) => {
  const hiddenFilters = TOP_REGIONAL_SLUGS.includes(domainSlug)
    ? TOP_REGIONAL_HIDDEN_FILTERS
    : DOMAIN_CATEGORY_HIDDEN_FILTERS;
  return {
    presetFilters:
      DOMAIN_CATEGORY_SLUG_TO_FILTER_VALUES_MAP[domainSlug as keyof typeof DOMAIN_CATEGORY_SLUG_TO_FILTER_VALUES_MAP],
    hiddenFilters,
  };
};

export const getVflpFilterSettings = () => {
  return {
    presetFilters: ['isPathwayDegree:true'],
  };
};

export const paginateResults = (results: SearchHit[], currentPage: number, pageSize: number): SearchHit[] => {
  return results.slice((currentPage - 1) * pageSize, currentPage * pageSize);
};
