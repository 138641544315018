import type React from 'react';

import type { BaseComponentProps } from '@coursera/cds-common';

import type { ButtonProps, default as Button } from '@core/Button';

import getActionsCss from './getActionsCss';

export type ActionsProps = {
  /**
   * @deprecated Use actions props instead to wrap primary and secondary actions together.
   * Primary call to action button.
   */
  primaryAction?: React.ReactElement<ButtonProps, typeof Button>;
  /**
   * @deprecated Use actions props instead to wrap primary and secondary actions together.
   * Secondary call to action button.
   */
  secondaryAction?: React.ReactElement<ButtonProps, typeof Button>;
  /**
   * All possible action buttons.
   * One of these types is expected: React.ReactElement<ButtonGroupProps, typeof ButtonGroup>
   * | React.ReactElement<ButtonProps, typeof Button>[]
   */
  actions?: React.ReactNode;
} & BaseComponentProps<'div'>;

const Actions = (
  props: ActionsProps
): React.ReactElement<ActionsProps> | null => {
  const { primaryAction, secondaryAction, actions, ...rest } = props;

  if (!primaryAction && !secondaryAction && !actions) {
    return null;
  }

  // The 'actions' prop takes precedence. If provided, (primary/secondary)Action will be ignored.
  const toBeReturnedComponent = actions ? (
    <div {...rest} css={getActionsCss}>
      {actions}
    </div>
  ) : (
    <div {...rest} css={getActionsCss}>
      {primaryAction}

      {secondaryAction}
    </div>
  );

  return toBeReturnedComponent;
};

export default Actions;
