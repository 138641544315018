import React from 'react';

import type { IconProps, SVGPaths } from '@coursera/cds-common';
import { createSvgIcon } from '@coursera/cds-common';

/**
 * Private module reserved for @coursera/cds-core package.
 */
const createLargeSvgIcon = (
  displayName: string,
  paths: { large: SVGPaths },
  shouldFlipForRTL?: boolean
): React.ComponentType<IconProps> => {
  const Icon = createSvgIcon(
    displayName,
    {
      ltr: {
        ...paths,
      },
    },
    shouldFlipForRTL
  );

  return React.forwardRef(function LargeIcon(
    props: IconProps,
    ref: React.Ref<SVGSVGElement>
  ) {
    return <Icon {...props} ref={ref} size="large" />;
  });
};

export default createLargeSvgIcon;
