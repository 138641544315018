import { useRetracked } from 'js/lib/retracked';

import { useTracker } from '@coursera/event-pulse/react';

import { autocompletePrefixes } from 'bundles/search-common/constants/autocomplete';
import type { AutocompleteMode } from 'bundles/search-common/constants/autocomplete';
import { getAutocompleteEventingData, isExactPartnerNameMatch } from 'bundles/search-common/utils/autocomplete';
import type { AutocompleteItems } from 'bundles/search-common/utils/autocomplete';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';
import { extractFiltersForEventing } from 'bundles/search-common/utils/providerEventingUtils';

const useTrackSearch = () => {
  const track = useRetracked();
  const tracker = useTracker();
  return (rawQuery: string, autocompleteMode: AutocompleteMode, recentlyViewedItems: AutocompleteItems) => {
    const sectionPrefix = autocompletePrefixes.find((prefix) => rawQuery.startsWith(prefix));
    let displayedQuery = sectionPrefix ? rawQuery.replace(sectionPrefix, '') : rawQuery;
    const filterData = isInFilterTestVariant()
      ? { filter: extractFiltersForEventing(window.location.search || '') }
      : {};

    if (sectionPrefix) {
      displayedQuery = rawQuery.replace(sectionPrefix, '');

      track({
        action: 'click',
        trackingName: 'autocomplete_item',
        trackingData: { name: displayedQuery, autocompleteSection: sectionPrefix },
      });
      const trackingData = getAutocompleteEventingData(rawQuery, autocompleteMode, recentlyViewedItems);
      tracker('click_autocomplete_suggestions', trackingData);
      tracker('perform_search', {
        searchedQuery: displayedQuery,
        sourceLinkCategory: 'autocomplete_suggestions',
        ...filterData,
      });
    } else if (isExactPartnerNameMatch(rawQuery)) {
      track({
        action: 'click',
        trackingName: 'autocomplete_item',
        trackingData: { name: displayedQuery, autocompleteSection: 'Partner' },
      });
      const trackingData = getAutocompleteEventingData(rawQuery, autocompleteMode, recentlyViewedItems);
      tracker('click_autocomplete_suggestions', trackingData);
      tracker('perform_search', {
        searchedQuery: displayedQuery,
        sourceLinkCategory: 'autocomplete_suggestions',
        ...filterData,
      });
    } else {
      const trackingData = { searchText: rawQuery, page: window.location.pathname };
      track({ action: 'search', trackingName: 'algolia_search_page_query_updated', trackingData });
      tracker('perform_search', { searchedQuery: rawQuery, ...filterData });
    }
  };
};
export default useTrackSearch;
