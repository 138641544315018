import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('multiSelectToolbar', []);

const styles = css`
  display: flex;
  gap: var(--cds-spacing-200);
`;

export default styles;
