import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import type { DegreeWebinar_DegreeWebinar as DegreeWebinar, Maybe } from '__generated__/graphql-types';
import { snakeCase } from 'lodash';

import { useLocation } from 'js/lib/useRouter';

import { org_coursera_catalogp_degree_CatalogAvailabilityStatus as DegreeAvailabilityStatus } from 'bundles/degrees/common/__generated__/globalTypes';
import { useLocalizedContent } from 'bundles/internationalization-lib/hooks/useLocalizedContent';
import type {
  GetContentfulSeoDataQuery,
  GetContentfulSeoDataQueryVariables,
  InfoModuleItemFragment,
  PremiumHubAboveTheFoldFieldsFragment,
  PremiumHubBelowTheFoldFieldsFragment,
} from 'bundles/premium-hub/__generated__/contentfulTypes';
import { useDomains } from 'bundles/premium-hub/components/degrees/useDomains';
import type { HubModuleValue, PremiumHubPageType } from 'bundles/premium-hub/constants';
import {
  CATEGORY_TIERS,
  CONTENTFUL_HUB_PAGE_TYPES,
  DEFAULT_MODULE_ORDER,
  DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT,
  HUB_MODULES,
  HubPages,
  LOCALIZED_PAGE_CONTENT_FIELDS,
  PREMIUM_HUB_MODULE_ORDER,
  ProductVariant,
} from 'bundles/premium-hub/constants';
import type { IndustryRelevanceContent } from 'bundles/premium-hub/data/industryRelevanceModuleContent';
import {
  getIndustryRelevanceContent,
  industryRelevanceContent,
} from 'bundles/premium-hub/data/industryRelevanceModuleContent';
import useWebinarQuery from 'bundles/premium-hub/hooks/useWebinarQuery';
import GetAllDegreeCategories from 'bundles/premium-hub/queries/GetAllDegreeCategoriesQuery.graphql';
import GetDegreesByFilter from 'bundles/premium-hub/queries/GetDegreesByFilter.graphql';
import GetDegreesById from 'bundles/premium-hub/queries/GetDegreesById.graphql';
import GetDegreesByMarketingType from 'bundles/premium-hub/queries/GetDegreesByMarketingType.graphql';
import type { DegreeCategoryFieldsFragment } from 'bundles/premium-hub/queries/__generated__/DegreeCategoryFieldsFragment';
import type { GetAllDegreeCategoriesQuery } from 'bundles/premium-hub/queries/__generated__/GetAllDegreeCategoriesQuery';
import type {
  PremiumHubProductsByFilterPaginatedQueryVariables as PpcQueryVariables,
  PremiumHubProductsByFilterPaginatedQuery,
} from 'bundles/premium-hub/queries/__generated__/GetDegreesByFilter';
import type {
  PremiumHubGetDegreesByIdQuery,
  PremiumHubGetDegreesByIdQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/GetDegreesById';
import type {
  GetDegreesByMarketingTypeQuery,
  GetDegreesByMarketingTypeQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/GetDegreesByMarketingType';
import type { PremiumProductFieldsFragment } from 'bundles/premium-hub/queries/__generated__/PremiumProductFieldsFragment';
import type { getAllDomains_DomainsV1Resource_domains_elements as Domains } from 'bundles/premium-hub/queries/__generated__/getAllDomains';
import type {
  DegreeCategoryBySlugQuery,
  DegreeCategoryBySlugQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/getDegreeCategoryBySlug';
import {
  PremiumHubAboveTheFoldFields,
  PremiumHubBelowTheFoldFields,
  getContentfulSeoData,
} from 'bundles/premium-hub/queries/contentfulQueries';
import getDegreeCategoryBySlug from 'bundles/premium-hub/queries/getDegreeCategoryBySlug.graphql';
import type {
  CategoryData,
  ContentfulVflpConfigProps,
  DegreeContentfulMetadata,
  PremimumHubPageContent,
  PremiumProductWithMetadata,
} from 'bundles/premium-hub/types';
import { AVAILABILITY_STATUS } from 'bundles/premium-hub/types';
import { filterNullOrUndefined, getOverviewListTitle, mapToDegreeContentfulSlug } from 'bundles/premium-hub/utils';
import { useDegreeAtAGlanceFromCms } from 'bundles/premium-hub/utils/contentfulIntegration';
import {
  convertDegreeByMarketingTypeToPPCFields,
  convertDegreeCategoryFieldsToPPCDegreeCategory,
  convertPpcPremiumProductToPremiumProductProps,
} from 'bundles/premium-hub/utils/dataTransformationUtils';
import { sortIndustryRelevanceModules } from 'bundles/premium-hub/utils/sortIndustryRelevanceModules';
import { getSubdomainDetails } from 'bundles/seo/utils/domainAndMetadataUtils';

type Arguments = {
  slug: string;
  pageType: PremiumHubPageType;
};

export type ValuesFromPremiumHubPageData = {
  premiumProductList?: PremiumProductFieldsFragment[] | undefined;
  degreeCategories?: DegreeCategoryFieldsFragment[] | null;
  pageContent?: PremimumHubPageContent;
  useSimplifiedHeader?: boolean;
  domains?: Domains[];
  currentEducationAttainment?: number | null;
  storeEducationAttainment?: (educationalAttainment: number) => void;
  atAGlanceData?: DegreeContentfulMetadata[];
  degreeListWithMetadata: PremiumProductWithMetadata[] | undefined;
  isPreview: boolean;
  isInternationalSubdomain: boolean;
  i18nSupportedLocales?: Maybe<Maybe<string>[]>;
  moduleOrder: HubModuleValue[];
  categoryData: CategoryData | undefined;
  informationSectionContent?: InfoModuleItemFragment | null;
  webinars: {
    pastWebinars: DegreeWebinar[] | undefined;
    upcomingWebinars: DegreeWebinar[] | undefined;
  };
  industryRelevance: IndustryRelevanceContent | IndustryRelevanceContent[] | undefined;
  loading: boolean;
  displayLearnBrowseTabs: boolean;
};

const getModuleOrder = (moduleOrderFromContentful: string, pageType: PremiumHubPageType): HubModuleValue[] => {
  if (!(pageType in PREMIUM_HUB_MODULE_ORDER)) return [];
  const orders = PREMIUM_HUB_MODULE_ORDER[pageType];
  if (moduleOrderFromContentful in orders) return orders[moduleOrderFromContentful];
  const orderEntries = Object.values(orders);
  if (!orderEntries.length) return DEFAULT_MODULE_ORDER;
  return orderEntries[0];
};

const getCategoriesForQuery = (slug: string): string[] | null => {
  switch (slug) {
    case 'public-health':
      return ['HEALTHCARE'];
    case 'maestria-en-linea-latinoamerica':
      return ['TOP_LATAM'];
    default:
      return [snakeCase(slug).toUpperCase()];
  }
};

/**
 * Automatically determines the variables for a query to the premium product collections based on the page type and slug
 * @param slug
 * @param pageType
 * @returns
 */
const getPpcQueryVariables = (
  slug: string,
  pageType: PremiumHubPageType,
  vflpConfig: Maybe<ContentfulVflpConfigProps>
): PpcQueryVariables => {
  const defaultVars = {
    catalogAvailabilityStatus: DegreeAvailabilityStatus.LIVE,
    productVariants: [ProductVariant.BachelorsDegree, ProductVariant.MastersDegree],
  };

  if (pageType === HubPages.DegreesCategoryHubPage) {
    return {
      ...defaultVars,
      productVariants: [...defaultVars.productVariants, ProductVariant.PostgraduateDiploma],
      categories: getCategoriesForQuery(slug),
    };
  } else if (pageType === HubPages.ProductVariantHubPage) {
    if (slug in DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT) {
      return {
        ...defaultVars,
        productVariants: DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT[slug as keyof typeof DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT],
      };
    }
  } else if (pageType === HubPages.LandingPage) {
    if (!vflpConfig?.ppcFilters) return {};
    const { categories, productVariants } = vflpConfig.ppcFilters;
    return {
      ...defaultVars,
      productVariants,
      categories,
    };
  }

  return defaultVars;
};

const getOverviewPageQueryConfigs = (
  slug: string,
  pageType: PremiumHubPageType,
  vflpConfig: Maybe<ContentfulVflpConfigProps>
): {
  skipPpcQuery: boolean;
  ppcQueryVariables: PpcQueryVariables;
  skipByMarketingTypeQuery: boolean;
  byMarketingTypeQueryVariables: GetDegreesByMarketingTypeQueryVariables;
  skipQueryByIds: boolean;
  queryByIdsVariables: {
    ids: string[];
  };
} => {
  const skipPpcQuery = pageType === HubPages.LandingPage && !vflpConfig?.ppcFilters;
  const ppcQueryVariables = skipPpcQuery ? {} : getPpcQueryVariables(slug, pageType, vflpConfig);

  const skipByMarketingTypeQuery =
    pageType !== HubPages.LandingPage || !skipPpcQuery || !vflpConfig?.byMarketingTypeFilter;
  const byMarketingTypeQueryVariables = {
    marketingType: vflpConfig?.byMarketingTypeFilter ?? ('PATHWAY' as const),
  };

  const skipQueryByIds =
    pageType !== HubPages.LandingPage || !skipPpcQuery || !skipByMarketingTypeQuery || !vflpConfig?.customIds;
  const queryByIdsVariables = {
    ids: vflpConfig?.customIds?.map((originalId) => originalId.replace('degree', 'base')) ?? [],
  };

  return {
    skipPpcQuery,
    ppcQueryVariables,
    skipByMarketingTypeQuery,
    byMarketingTypeQueryVariables,
    skipQueryByIds,
    queryByIdsVariables,
  };
};

const convertSlugToContentfulSlug = (slug: string, pageType: PremiumHubPageType) =>
  slug === 'degrees' && pageType === HubPages.MainHubPage ? 'degrees_hub' : slug;

const getCategoryData = (
  pageType: PremiumHubPageType,
  slug: string,
  degreeCategoryData: DegreeCategoryBySlugQuery | undefined
): CategoryData | undefined => {
  if (pageType === HubPages.DegreesCategoryHubPage && degreeCategoryData?.DegreeCategoryQueries?.getBySlug) {
    const { name, slug: categorySlug, id } = degreeCategoryData?.DegreeCategoryQueries?.getBySlug;
    return { name, slug: categorySlug, id };
  }

  if (pageType === HubPages.ProductVariantHubPage) {
    const productVariant = DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT[slug as keyof typeof DEGREE_HUB_SLUG_TO_PRODUCT_VARIANT];
    return {
      name: getOverviewListTitle(productVariant),
      slug,
      id: slug,
    };
  }

  if (pageType === HubPages.MainHubPage) {
    return {
      name: '',
      slug: 'degrees',
      id: 'degrees',
    };
  }

  if (pageType === HubPages.LandingPage) {
    return {
      name: '',
      slug,
      id: 'VALUE_FIRST_LANDING_PAGE',
    };
  }

  return undefined;
};

const usePremiumHubPageData = ({ slug, pageType }: Arguments): ValuesFromPremiumHubPageData | null => {
  const location = useLocation();
  const atAGlanceData = useDegreeAtAGlanceFromCms();
  const { domains } = useDomains();

  const isPreview = location.query.preview ? Number(location.query.preview) === 1 : false;

  // page content via localization app
  const { data: pageContentAboveTheFold, loading: pageContentAboveTheFoldLoading } =
    useLocalizedContent<PremiumHubAboveTheFoldFieldsFragment>({
      isPreview,
      contentType: LOCALIZED_PAGE_CONTENT_FIELDS.contentType,
      fragmentDoc: PremiumHubAboveTheFoldFields,
      fragmentFieldsName: LOCALIZED_PAGE_CONTENT_FIELDS.fragmentFieldsAboveTheFoldName,
      filters: {
        slug: convertSlugToContentfulSlug(slug, pageType),
        pageType: CONTENTFUL_HUB_PAGE_TYPES[pageType],
      },
      onError: (error) =>
        Sentry.captureException(error, {
          extra: {
            message: 'Error fetching premium hub above the fold page content',
          },
        }),
    });
  const { data: pageContentBelowTheFold } = useLocalizedContent<PremiumHubBelowTheFoldFieldsFragment>({
    isPreview,
    contentType: LOCALIZED_PAGE_CONTENT_FIELDS.contentType,
    fragmentDoc: PremiumHubBelowTheFoldFields,
    fragmentFieldsName: LOCALIZED_PAGE_CONTENT_FIELDS.fragmentFieldsBelowTheFoldName,
    filters: {
      slug: convertSlugToContentfulSlug(slug, pageType),
      pageType: CONTENTFUL_HUB_PAGE_TYPES[pageType],
    },
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching premium hub below the fold page content',
        },
      }),
  });

  const pageContent: PremimumHubPageContent | undefined = pageContentAboveTheFold
    ? { ...pageContentAboveTheFold, ...pageContentBelowTheFold }
    : undefined;
  const moduleOrder = getModuleOrder(pageContent?.moduleOrder ?? '', pageType);
  const informationSectionContent = pageContent?.visualConsistency?.infoModule;
  const useSimplifiedHeader = pageContent?.useSimplifiedHeader ?? false;

  // degree list
  const vflpConfig = pageContent?.vflpConfiguration;
  const {
    byMarketingTypeQueryVariables,
    ppcQueryVariables,
    skipByMarketingTypeQuery,
    skipPpcQuery,
    queryByIdsVariables,
    skipQueryByIds,
  } = getOverviewPageQueryConfigs(slug, pageType, vflpConfig);
  const { data: premiumProductCollectionData } = useQuery<PremiumHubProductsByFilterPaginatedQuery, PpcQueryVariables>(
    GetDegreesByFilter,
    {
      variables: {
        ...ppcQueryVariables,
        page: 1,
        pageSize: 100,
      },
      context: { clientName: 'gatewayGql' },
      skip: skipPpcQuery,
      errorPolicy: 'all',
      onError: (error) =>
        Sentry.captureException(error, {
          extra: {
            message: 'Error fetching premium hub products by filter',
          },
        }),
    }
  );
  const { data: byIdsData, loading: byIdsLoading } = useQuery<
    PremiumHubGetDegreesByIdQuery,
    PremiumHubGetDegreesByIdQueryVariables
  >(GetDegreesById, {
    variables: {
      ...queryByIdsVariables,
    },
    context: { clientName: 'gatewayGql' },
    skip: skipQueryByIds,
    errorPolicy: 'all',
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching premium hub products by IDs',
        },
      }),
  });
  const {
    data: premiumProductCollectionDataByMarketingType,
    loading: premiumProductCollectionDataByMarketingTypeLoading,
  } = useQuery<GetDegreesByMarketingTypeQuery, GetDegreesByMarketingTypeQueryVariables>(GetDegreesByMarketingType, {
    variables: byMarketingTypeQueryVariables,
    context: { clientName: 'gatewayGql' },
    skip: skipByMarketingTypeQuery,
    errorPolicy: 'all',
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching premium hub products by marketing type',
        },
      }),
  });

  let premiumProductList: PremiumProductFieldsFragment[] | undefined;

  if (premiumProductCollectionData) {
    premiumProductList =
      premiumProductCollectionData?.PremiumProductCollections?.queryCollectionPaginated?.learningProducts?.filter(
        filterNullOrUndefined
      );
  } else if (byIdsData) {
    premiumProductList = byIdsData.PremiumProductCollections?.multiGetDegrees?.filter(filterNullOrUndefined);
  } else if (premiumProductCollectionDataByMarketingType) {
    premiumProductList = premiumProductCollectionDataByMarketingType?.PremiumProductCollections?.queryByMarketingType
      ?.filter(filterNullOrUndefined)
      .map(convertDegreeByMarketingTypeToPPCFields);
  }

  // degree categories
  const { data: degreeCategoriesData, loading: degreeCategoriesLoading } = useQuery<GetAllDegreeCategoriesQuery, {}>(
    GetAllDegreeCategories,
    {
      variables: {
        filter: {
          orderBy: 'DRE',
          tiers: [CATEGORY_TIERS.one, CATEGORY_TIERS.two],
          degreeCountSpecs: {
            catalogAvailabilityStatuses: [AVAILABILITY_STATUS.LIVE],
          },
        },
      },
      context: {
        clientName: 'gatewayGql',
      },
      ssr: true,
      errorPolicy: 'all',
      onError: (error) =>
        Sentry.captureException(error, {
          extra: {
            message: 'Error fetching all degree categories',
          },
        }),
    }
  );
  const degreeCategories = degreeCategoriesData?.DegreeCategoryQueries?.getAll;

  // current category
  const { data: degreeCategoryData, loading: degreeCategoryLoading } = useQuery<
    DegreeCategoryBySlugQuery,
    DegreeCategoryBySlugQueryVariables
  >(getDegreeCategoryBySlug, {
    skip: pageType !== HubPages.DegreesCategoryHubPage,
    variables: { slug },
    context: { clientName: 'gatewayGql' },
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching degree categories by slug',
        },
      }),
  });

  const categoryData: CategoryData | undefined = getCategoryData(pageType, slug, degreeCategoryData);

  // SEO data
  const isInternationalSubdomain = getSubdomainDetails(location)?.isInternationalSubdomain;
  const { data: contentfulSeoData, loading: contentfulSeoLoading } = useQuery<
    GetContentfulSeoDataQuery,
    GetContentfulSeoDataQueryVariables
  >(getContentfulSeoData, {
    variables: {
      pageLocation: '/degrees',
      preview: isPreview,
    },
    context: { clientName: isPreview ? 'contentfulPreviewGql' : 'contentfulGql' },
    errorPolicy: 'all',
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching contentful SEO data',
        },
      }),
  });

  const i18nSupportedLocales = contentfulSeoData?.seoDataCollection?.items?.[0]?.i18NSupportedLocales;

  // combine degrees with at-a-glance data
  const degreeListWithMetadata: PremiumProductWithMetadata[] | undefined = premiumProductList?.map((degree) => {
    const aagItem = atAGlanceData?.find((item) => item?.slug === mapToDegreeContentfulSlug(degree?.slug ?? '')) ?? {};
    return {
      ...aagItem,
      ...convertPpcPremiumProductToPremiumProductProps(degree),
    };
  });

  // webinars
  const {
    upcomingWebinars,
    pastWebinars,
    loading: webinarsLoading,
  } = useWebinarQuery(pageContent?.pageId || '', moduleOrder.includes(HUB_MODULES.webinars));

  // industry relevance content
  /**
   * TODO: Remove hard-coded industry relevance and replace with data from Contentful
   */
  const industryRelevanceModules = Object.values(industryRelevanceContent());
  const orderedIndustryRelevanceModules = sortIndustryRelevanceModules(
    industryRelevanceModules,
    degreeCategories?.map(convertDegreeCategoryFieldsToPPCDegreeCategory) ?? []
  );
  const industryRelevance =
    pageType === HubPages.MainHubPage ? orderedIndustryRelevanceModules : getIndustryRelevanceContent(slug);

  const isVflpTabbedPage = pageType === HubPages.LandingPage && vflpConfig?.displayLearnBrowseTabs;
  const displayLearnBrowseTabs = isVflpTabbedPage;

  const renderBlockingRequestsLoading =
    degreeCategoriesLoading ||
    degreeCategoryLoading ||
    pageContentAboveTheFoldLoading ||
    premiumProductCollectionDataByMarketingTypeLoading ||
    webinarsLoading ||
    contentfulSeoLoading ||
    byIdsLoading;

  if (renderBlockingRequestsLoading) {
    // Don't return anything until all the required data is loaded, this limits the amount of re-renders
    return null;
  }
  return {
    premiumProductList,
    useSimplifiedHeader,
    degreeCategories,
    categoryData,
    pageContent,
    domains,
    atAGlanceData,
    isPreview,
    isInternationalSubdomain,
    i18nSupportedLocales,
    moduleOrder,
    degreeListWithMetadata,
    informationSectionContent,
    webinars: { pastWebinars, upcomingWebinars },
    industryRelevance,
    loading: renderBlockingRequestsLoading, // TODO: this shouldn't be needed
    displayLearnBrowseTabs,
  };
};

export default usePremiumHubPageData;
