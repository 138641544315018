import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: var(--cds-spacing-100);

  ${breakpoints.up('sm')} {
    padding-bottom: var(--cds-spacing-50);
  }
`;

export default styles;
