import * as React from 'react';

import HeaderRightNavButton from 'bundles/page-header/components/HeaderRightNavButton';
import { getCareerAcademyLabel, getCareerAcademyLink } from 'bundles/page/components/shared/utils/careerAcademyUtils';

type Props = {
  shouldShow: boolean;
};

const CareerAcademyLink: React.FC<Props> = ({ shouldShow }) => {
  if (!shouldShow) {
    return null;
  }
  const label = getCareerAcademyLabel();
  const careerAcademyProps = {
    href: getCareerAcademyLink('globalnav'),
    label,
    name: 'career-academy',
    noBorder: true,
    wrapperClassName: 'c-ph-career-academy',
  };
  return <HeaderRightNavButton {...careerAcademyProps} key={label} buttonWrapperType="span" />;
};

export default CareerAcademyLink;
