import * as React from 'react';

export const TrendingIcon = (): React.ReactElement => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', alignContent: 'center' }} data-testid="trending-icon">
      <svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#636363">
        <path d="M134.15-284.23q-6.61-6.62-6.11-14.27.5-7.65 6.11-13.27l192.54-194.77q18.39-19.15 45.46-19.15 27.08 0 46.23 19.15l96.85 97.08q6.92 6.92 16.92 6.92 10 0 17.7-6.92l223.38-220.62H660q-8.54 0-14.27-5.73T640-650.08q0-8.54 5.73-14.27t14.27-5.73h147.69q13.93 0 23.12 9.2 9.19 9.19 9.19 23.11v147.69q0 8.54-5.73 14.27T820-470.08q-8.54 0-14.27-5.34-5.73-5.35-5.73-13.89v-112.46L577.62-379.38Q559.23-361 532.15-361q-27.07 0-45.46-18.38l-96.84-96.85q-6.93-6.92-17.7-6.92t-17.69 6.92l-192 192q-6.38 6.38-14.15 6.38-7.77 0-14.16-6.38Z" />
      </svg>
    </div>
  );
};

export default TrendingIcon;
