import type React from 'react';

import type { NotificationSeverity } from '@core/notifications/types';
import type { TypographyProps } from '@core/Typography2';
import Typography from '@core/Typography2';

import getTitleCss from './getTitleCss';

export type TitleProps = {
  /**
   * Text to display as the title for the notification
   */
  children?: string;
  /**
   * Severity type of Notification
   * @default information
   */
  severity?: NotificationSeverity;
  /**
   * @default h2
   */
  component?: React.ElementType;
} & Omit<TypographyProps, 'color' | 'variant' | 'component'>;

const Title = (props: TitleProps): React.ReactElement<TitleProps> | null => {
  const { children, severity, component = 'h2', ...rest } = props;
  const titleCss = getTitleCss(severity);

  if (!children) {
    return null;
  }

  return (
    <Typography
      component={component}
      variant="subtitleMedium"
      {...rest}
      css={titleCss}
    >
      {children}
    </Typography>
  );
};

export default Title;
