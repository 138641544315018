import { useCallback, useRef } from 'react';

/**
 * A hook that closes the virtual keyboard (by blurring the input field ) when the user scrolls on touch devices.
 * @param options
 */
function useCloseVirtualKeyboard(options: {
  inputRef: React.RefObject<HTMLInputElement>;
}): { onTouchStart: () => void; onTouchEnd: () => void; onScroll: () => void } {
  const { inputRef } = options;

  const isTouchDown = useRef(false);

  const onTouchStart = useCallback(() => {
    isTouchDown.current = true;
  }, []);

  const onTouchEnd = useCallback(() => {
    isTouchDown.current = false;
  }, []);

  const onScroll = useCallback(() => {
    if (
      !inputRef.current ||
      document.activeElement !== inputRef.current ||
      !isTouchDown.current
    ) {
      return;
    }

    inputRef.current?.blur();
  }, [inputRef, isTouchDown]);

  return {
    onScroll,
    onTouchEnd,
    onTouchStart,
  };
}

export default useCloseVirtualKeyboard;
