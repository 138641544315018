/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo } from 'react';
import type { MutableRefObject } from 'react';

import { processImage } from 'js/lib/imgix';

import { Grid, ProductCard, Typography2, breakpoints } from '@coursera/cds-core';
import type { ProductType, SectionName } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { buildDegreeUrlAbsolute } from 'bundles/common/utils/urlUtils';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { InstructorProfileVisualConsistencyFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import { PRODUCT_CARD_PARTNER_LOGO_PARAMS } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { PremiumProductWithMetadata } from 'bundles/premium-hub/types/index';
import { filterNullOrUndefined } from 'bundles/premium-hub/utils';
import { removeProductIdPrefix } from 'bundles/premium-hub/utils/dataTransformationUtils';

import _t from 'i18n!nls/premium-hub';

type Props = {
  instructorHeader?: Maybe<string>;
  instructors?: Array<Maybe<InstructorProfileVisualConsistencyFragment>>;
  degreeListWithMetadata?: Array<PremiumProductWithMetadata>;
};

const sectionName = 'instructor' as SectionName;

const styles = {
  instructorsWrapper: css`
    padding: var(--cds-spacing-600) 0;
    ${breakpoints.down('sm')} {
      padding: var(--cds-spacing-400) 0;
    }
  `,
  titleContainer: css`
    margin-bottom: var(--cds-spacing-300);
  `,
  titleLink: css`
    display: block;
  `,
  profilePicture: css`
    margin-right: var(--cds-spacing-100);
    border-radius: 50%;
  `,
  productCard: css`
    .cds-ProductCard-base {
      ${breakpoints.down('sm')} {
        max-width: 100% !important;
      }
    }
  `,
};

const INSTRUCTOR_IMAGE_SIZE_PX = 40;

const UnifiedInstructorsList: React.FunctionComponent<Props> = ({
  instructorHeader,
  instructors,
  degreeListWithMetadata,
}) => {
  const trackV3 = useTracker();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();

  const instructorListRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName,
      index: sectionIndex,
    },
  });

  const instructorsWithId = useMemo(
    () =>
      instructors?.filter(filterNullOrUndefined).map((instructor) => ({
        ...instructor,
        programId: degreeListWithMetadata?.find((degree) => degree.slug === instructor?.programSlug)?.id,
      })),
    [instructors, degreeListWithMetadata]
  );

  if (!instructors || !instructors.length) {
    return null;
  }

  return (
    <div css={styles.instructorsWrapper} data-test="rc-UnifiedInstructorsList" ref={instructorListRef}>
      <Grid container direction="column">
        <Typography2 component="h2" variant="titleSmall" css={styles.titleContainer}>
          {instructorHeader ?? _t('Learn from expert faculty')}
        </Typography2>
        <Grid container spacing={16}>
          {instructorsWithId?.map((instructor, index) => {
            const { name, position, partner, program, partnerLogo, programSlug, programId } = instructor;
            const profileImage = processImage(instructor?.profilePicture?.url, {
              auto: 'format,compress',
              fit: 'fill',
              width: INSTRUCTOR_IMAGE_SIZE_PX,
              height: INSTRUCTOR_IMAGE_SIZE_PX,
            });
            const degreeLink = buildDegreeUrlAbsolute(programSlug ?? '');
            const instructorDescription = [name, position, partner, program].filter(filterNullOrUndefined).join(', ');

            const trackingDataV3 = {
              productCard: { index },
              product: {
                id: removeProductIdPrefix(programId ?? ''),
                slug: programSlug ?? '',
                name: program ?? '',
                type: 'degree' as ProductType,
              },
              pageSection: {
                sectionName,
                index: sectionIndex,
              },
            };

            return (
              <Grid key={instructor?.id} item xs={12} sm={6} md={4} css={styles.productCard}>
                <ProductCard
                  variant="grid"
                  productType="Degree"
                  partners={[
                    {
                      name: partner ?? '',
                      logoUrl: processImage(partnerLogo?.url ?? '', PRODUCT_CARD_PARTNER_LOGO_PARAMS),
                    },
                  ]}
                  title={{
                    name: program ?? '',
                    linkProps: {
                      css: styles.titleLink,
                      href: degreeLink,
                      component: TrackedLink2,
                      'aria-label': instructorDescription,
                    },
                    customLinkProps: {
                      trackingName: 'instructor_degree_card',
                      withVisibilityTracking: true,
                      requireFullyVisible: false,
                      data: {
                        product: programSlug,
                      },
                      onClick: () => trackV3('click_product_card', trackingDataV3),
                    },
                  }}
                  body={
                    <Grid container wrap="nowrap">
                      <Grid item>
                        <img
                          css={styles.profilePicture}
                          src={profileImage}
                          width={INSTRUCTOR_IMAGE_SIZE_PX}
                          height={INSTRUCTOR_IMAGE_SIZE_PX}
                          loading="lazy"
                          alt=""
                        />
                      </Grid>
                      <Grid item>
                        <Typography2 variant="subtitleMedium" component="p">
                          {name}
                        </Typography2>
                        <Typography2 component="p" variant="bodySecondary">
                          {position}
                        </Typography2>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(UnifiedInstructorsList);
