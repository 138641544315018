import React from 'react';

import type { ForwardRefComponent } from '@coursera/cds-common';

import { BaseHeadingGroup } from '@core/dialogs/common/BaseHeadingGroup';
import type { BaseHeadingGroupProps } from '@core/dialogs/common/BaseHeadingGroup';

import { useDialogContext } from './DialogContextProvider';
import { getHeadingGroupCss, headingGroupClasses } from './styles';

type HeadingGroupType = ForwardRefComponent<HTMLDivElement, Props>;

type BaseProps = Omit<BaseHeadingGroupProps, 'color'>;

export type Props = BaseProps & {
  children?: React.ReactNode;
};

const HeadingGroup: HeadingGroupType = React.forwardRef<HTMLDivElement, Props>(
  function HeadingGroup(props: Props, ref: React.Ref<HTMLDivElement>) {
    const { heading, supportText, id, children, ...rest } = props;

    const { contentWidth, headingGroupId } = useDialogContext();

    return (
      <div
        ref={ref}
        css={getHeadingGroupCss({ contentWidth })}
        id={id || headingGroupId}
        {...rest}
      >
        <BaseHeadingGroup
          className={headingGroupClasses.headerGroup}
          heading={heading}
          supportText={supportText}
        />

        {children && (
          <div className={headingGroupClasses.freeContent}>{children}</div>
        )}
      </div>
    );
  }
);

export default HeadingGroup;
