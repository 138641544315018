import * as React from 'react';

import type { IconProps, LinkProps } from '@coursera/cds-core';
import { Link } from '@coursera/cds-core';

import { HelpCenters, buildSalesforceArticleUrl } from 'bundles/common/utils/salesforceUrlBuilder';
import { buildPartnerHelpUrl } from 'bundles/common/utils/urlUtils';

import _t from 'i18n!nls/authoring';

export type Props = {
  articleId?: string;
  categoryId?: string;
  sectionId?: string;
  anchor?: string;
  linkText?: React.ReactNode;
  icon?: React.ReactElement<IconProps>;
  iconPosition?: 'before' | 'after';
  linkClasses?: string;
  pendoTarget?: string;
  linkProps?: Omit<LinkProps, 'children'>;
  salesforceArticleSlug?: string;
};

const PartnerHelpLink = (props: Props): JSX.Element => {
  const {
    articleId,
    categoryId,
    sectionId,
    anchor,
    linkText,
    icon,
    iconPosition = 'after',
    linkClasses,
    linkProps,
    pendoTarget,
    salesforceArticleSlug,
  } = props;
  const anchorPart = anchor ? `#${anchor}` : '';

  let link;

  if (salesforceArticleSlug) {
    link = buildSalesforceArticleUrl(HelpCenters.PARTNER, true, '', salesforceArticleSlug);
  } else {
    link = buildPartnerHelpUrl(articleId, categoryId, sectionId) + anchorPart;
  }

  return (
    <Link
      className={linkClasses}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      icon={icon}
      iconPosition={iconPosition}
      {...linkProps}
      {...(pendoTarget ? { 'data-pendo': pendoTarget } : {})}
    >
      {linkText || _t('Learn more')}
    </Link>
  );
};

export default PartnerHelpLink;
