import type React from 'react';
import { graphql } from 'react-apollo';

import { branch, compose, renderComponent, withProps } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import user from 'js/lib/user';

import { getCertificateDomain, getDegreeDomain, getRootDomain } from 'bundles/browse/components/PageHeader/constants';
import { DomainGetAllQuery } from 'bundles/browse/components/Queries';
import { injectSubdomainsToDomains } from 'bundles/browse/utils';
import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import type {
  LoggedOutProgramHeaderQuery,
  LoggedOutProgramHeaderQueryVariables,
} from 'bundles/page/common/__generated__/LoggedOutProgramHeaderQuery';
import {
  graphqlProgramOptions,
  loggedOutProgramHeaderQuery,
  programAndDegreeMembershipQuery,
} from 'bundles/page/common/queries';
import { ProgramCurriculumDomainsQuery } from 'bundles/program-home/utils/ProgramHomeGraphqlQueries';
import type { ReferralOfferData } from 'bundles/referral/types';
import inServerContext from 'bundles/ssr/util/inServerContext';

import _t from 'i18n!nls/page';

type PropsFromLoggedOutProgramHeaderQuery = {
  programLogoDisplay?: {
    id: string;
    metadata: {
      name: string;
      rectangularLogo: string | null;
      squareLogo: string | null;
    };
  } | null;
};

export const isAuthoringPathname = (pathname: $TSFixMe) => {
  return (
    pathname &&
    // 2020-Feb-14: A valid path to the admin app is also `/admin`.
    (pathname === '/admin' ||
      pathname === '/admin-v2' ||
      pathname.startsWith('/admin/') ||
      pathname.startsWith('/admin-v2') ||
      pathname.startsWith('/groups/') ||
      pathname.startsWith('/teach/') ||
      pathname.startsWith('/teach-partner/') ||
      pathname.startsWith('/teach-program/') ||
      pathname.startsWith('/teach-specialization'))
  );
};

type NavButton = {
  href: string;
  label: string;
  name: string;
  htmlAttributes?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
};

export type AccountDropdownOptionParams = {
  thirdPartyOrganizations?: Array<ThirdPartyOrganizationsV1>;
  isStaff: boolean;
  hasCheatingIncidents?: boolean;
  referralOfferData?: ReferralOfferData;
};

export const getAccountDropdownOptions = ({
  thirdPartyOrganizations,
  isStaff,
  hasCheatingIncidents,
  referralOfferData,
}: AccountDropdownOptionParams): NavButton[] => {
  const locale = _t.getLocale();
  const navButtons: NavButton[] = [];
  if (isStaff) {
    navButtons.push({
      href: '/admin/',
      label: _t('Educator Admin'),
      name: 'admin',
    });
  }

  if (thirdPartyOrganizations?.length) {
    thirdPartyOrganizations.forEach(({ name, slug }: $TSFixMe) => {
      navButtons.push({
        href: `/o/${slug}/admin`,
        label: `${name} ${_t('Admin')}`,
        name: slug,
      });
    });
  }
  navButtons.push({
    href: '/account-profile',
    label: _t('Profile'),
    name: 'profile',
  });
  navButtons.push({
    href: '/my-purchases',
    label: _t('My Purchases'),
    name: 'my-purchases',
  });
  if (hasCheatingIncidents) {
    navButtons.push({
      href: '/integrity-portal',
      label: _t('Honor Code Dashboard'),
      name: 'integrity-portal',
    });
  }
  if (referralOfferData?.shouldShowCourseraPlusReferralOffer) {
    navButtons.push(referralOfferData.button());
  }
  navButtons.push({
    href: '/account-settings',
    label: _t('Settings'),
    name: 'account-settings',
  });
  navButtons.push({
    href: '/updates',
    label: _t('Updates'),
    name: 'updates',
  });
  navButtons.push({
    href: '/accomplishments',
    label: _t('Accomplishments'),
    name: 'accomplishments',
  });
  if (isStaff) {
    navButtons.push({
      href: `https://partner.coursera.help/hc/${locale}`,
      label: _t('Educator Resource Center'),
      name: 'partner-resource-center',
      htmlAttributes: { target: '_blank' },
    });
    navButtons.push({
      href: `https://learner.coursera.help/hc/${locale}`,
      label: _t('Learner Help Center'),
      name: 'learner-help-center',
      htmlAttributes: { target: '_blank' },
    });
  } else {
    navButtons.push({
      href: `https://learner.coursera.help/hc/${locale}`,
      label: _t('Help Center'),
      name: 'learner-help-center',
      htmlAttributes: { target: '_blank' },
    });
  }

  return navButtons;
};

export const withDomainsGraphql = graphql(DomainGetAllQuery, {
  // `shouldSkipOptionalExternalDataFetch` is a PageHeader prop passed in by critical pages like payments
  // that want to avoid unncessary calls to prevent slow loading / SSR errors from graphql timeouts
  //
  // `shouldRenderEnterpriseProgramsAndProgramCurriculumDomains` is a prop passed in by the Enterprise version of this
  // HOC to suppress this one without using `branch(..)`, which causes tree thrashing.
  skip: (props: $TSFixMe) =>
    props.shouldRenderEnterpriseProgramsAndProgramCurriculumDomains ||
    !!props.currentProgram ||
    props.shouldSkipOptionalExternalDataFetch,
  options: {
    fetchPolicy: 'cache-first',
    ssr: false,
  },
  props: ({ data: { DomainsV1Resource } }: $TSFixMe) => {
    const domains = (DomainsV1Resource && DomainsV1Resource.domains && DomainsV1Resource.domains.elements) || [];
    return { domains };
  },
});

export const withOldMegaMenuSectionData = withProps((props: $TSFixMe) => {
  let { domains } = props;

  // adding Degrees and Certificates sections
  domains = domains?.concat([
    {
      id: getRootDomain().id,
      name: getRootDomain().name,
    },
    {
      id: getDegreeDomain().id,
      name: getDegreeDomain().name,
    },
    {
      id: getCertificateDomain().id,
      name: getCertificateDomain().name,
    },
  ]);
  return {
    domains: domains?.filter((x: $TSFixMe) => !!x),
  };
});

const withError = branch((props: $TSFixMe) => props.error, renderComponent(ErrorMessage));

export const withProgramCurriculumDomainsGraphql = graphql(ProgramCurriculumDomainsQuery, {
  skip: (props: $TSFixMe) => !props.programId,
  props: ({
    data: { ProgramCurriculumDomainsV1Resource: DomainsV1, SubdomainsV1Resource: SubdomainsV1, error },
  }: $TSFixMe) => {
    if (error) {
      return { error: true };
    }
    const domains = (DomainsV1 && DomainsV1.get && DomainsV1.get.domains) || [];
    const subdomains = (SubdomainsV1 && SubdomainsV1.getAll && SubdomainsV1.getAll.elements) || [];
    // due to the complexity in the BE implementations, it is easier to getAll, filter, and inject the subdomains in FE
    return { domains: injectSubdomainsToDomains(domains, subdomains) };
  },
});

export const withEnterpriseProgramsAndProgramCurriculumDomains = compose(
  withProps((props: $TSFixMe) => ({ programId: props.switcherSelections?.programId ?? props.programId ?? undefined })),
  withProgramCurriculumDomainsGraphql,
  withError
);

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ options: () => { variables: { ... Remove this comment to see the full error message
export const withDegreesAndPrograms = graphql(programAndDegreeMembershipQuery, graphqlProgramOptions);

// Use `skip` to avoid using `branch(..)` this high up, since swapping these HOCs causes tree thrashing.
export const populateWithDegreesAndProgramsOnClientside = () => {
  const frozenEmptyArray = Object.freeze([]);

  // Use `skip` to avoid using `branch(..)` this high up, since swapping these HOCs causes tree thrashing.
  return compose(
    // @ts-expect-error TSMIGRATION
    graphql(programAndDegreeMembershipQuery, {
      ...graphqlProgramOptions,
      // Defer membership queries to client-side so it wouldn't make SSR fail and
      // pages (i.e. payments) that don't care about program and degrees can specify to skip the fetch
      skip: ({ shouldSkipOptionalExternalDataFetch }: $TSFixMe) => {
        return !(user.isAuthenticatedUser() && !inServerContext && !shouldSkipOptionalExternalDataFetch);
      },
    }),
    graphql<
      { router: InjectedRouter },
      LoggedOutProgramHeaderQuery,
      LoggedOutProgramHeaderQueryVariables,
      PropsFromLoggedOutProgramHeaderQuery
    >(loggedOutProgramHeaderQuery, {
      options: ({ router }) => ({ variables: { programSlug: router.params.programSlug }, ssr: false }),
      skip: ({ router }) => !router?.params?.programSlug,
      props: ({ data }) => {
        const programLogoDisplay = data?.EnterpriseProgramsV1Resource?.slug?.elements?.[0];
        return { programLogoDisplay };
      },
    }),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ programMemberships, programs,... Remove this comment to see the full error message
    withProps(({ programMemberships, programs, degreeMemberships, degrees }) => ({
      programMemberships: programMemberships ?? frozenEmptyArray,
      programs: programs ?? frozenEmptyArray,
      degreeMemberships: degreeMemberships ?? frozenEmptyArray,
      degrees: degrees ? degrees.filter((degree: $TSFixMe) => !!degree) : frozenEmptyArray,
    }))
  );
};

// Pass a flag to avoid using `branch(..)` this high up, since swapping these HOCs causes tree thrashing.
export const withDomains = compose(
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ switcherSelections }: { switc... Remove this comment to see the full error message
  withProps(({ switcherSelections }) => {
    const shouldRenderEnterpriseProgramsAndProgramCurriculumDomains = Boolean(switcherSelections?.programId);
    return { shouldRenderEnterpriseProgramsAndProgramCurriculumDomains };
  }),
  withEnterpriseProgramsAndProgramCurriculumDomains,
  withDomainsGraphql
);
