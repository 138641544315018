import type React from 'react';

import type { PopoverProps } from '@material-ui/core';

import type { Props as FocusTrapProps } from '@core/Modal/FocusTrap';
import FocusTrap from '@core/Modal/FocusTrap';

import { PopoverContext } from './context';
import containerCss, { classes } from './styles/containerCss';

export type Props = {
  /**
   * Helps to style children based on the layout they are in.
   */
  layout: 'drawer' | 'dropdown';

  /**
   * Set a ref to initial element that has to be focused.
   * MUI Modal focuses itself and it exists in focus loop. To get rid of it we redirect focus to the initial focus ref.
   */
  initialFocusRef?: FocusTrapProps['initialFocusRef'];

  returnFocusRef?: FocusTrapProps['returnFocusRef'];

  autoFocus?: FocusTrapProps['autoFocus'];

  onClose?: PopoverProps['onClose'];

  children: React.ReactNode;
};

/**
 * Container that creates the focus trap and uses the PopoverContext to pass onClose handler
 * @param props
 */
const Container = (props: Props) => {
  const {
    initialFocusRef,
    returnFocusRef,
    onClose,
    children,
    autoFocus,
    layout,
  } = props;
  return (
    <FocusTrap
      autoFocus={autoFocus}
      css={containerCss}
      initialFocusRef={initialFocusRef}
      returnFocusRef={returnFocusRef}
    >
      <div className={classes.inner}>
        <PopoverContext.Provider value={{ onClose, layout }}>
          {children}
        </PopoverContext.Provider>
      </div>
    </FocusTrap>
  );
};

export default Container;
