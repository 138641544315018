/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Button, Dialog, SelectField, SelectOption } from '@coursera/cds-core';
import { Loading } from '@coursera/coursera-ui';

import {
  getDegreeReadinessOptions,
  getFinancialPlanOptions,
  getLearnerTimelineOptions,
  validateFieldOptions,
} from 'bundles/expression-of-interest/components/experiments/qualifyingQuestionsUtils';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';

import _t from 'i18n!nls/expression-of-interest';

type NaptimeNumberType = number | undefined | null;

type QualifyingQuestionsInputs = {
  highestEducation: NaptimeNumberType;
  learnerTimeline: NaptimeNumberType;
  financePlan: NaptimeNumberType;
};

type Props = {
  trackingProductId: string;
  isUserProfileLoading?: boolean;
  setHighestEducation: (input?: number | null) => void;
  setLearnerTimeline: (input?: number | null) => void;
  setFinancePlan: (input?: number | null) => void;
  handleSubmit: () => Promise<void | {}>;
  navigateNextSteps: () => void;
  setHasSkipped: (input: boolean) => void;
  enableSkip?: boolean;
} & QualifyingQuestionsInputs;

type QualifyingQuestionsInputsErrorStatus = {
  highestEducationErrorStatus: boolean;
  learnerTimelineErrorStatus: boolean;
  financePlanErrorStatus: boolean;
};

const styles = {
  selectFieldsStyling: css`
    div:nth-child(n + 2) {
      margin-top: var(--cds-spacing-200);
    }
  `,
  primaryButton: css`
    margin-right: var(--cds-spacing-200);
  `,
};

export const QualifyingQuestionsSection = (props: Props): React.ReactElement => {
  const {
    trackingProductId,
    isUserProfileLoading,
    highestEducation,
    learnerTimeline,
    financePlan,
    setHighestEducation,
    setLearnerTimeline,
    setFinancePlan,
    handleSubmit,
    navigateNextSteps,
    setHasSkipped,
    enableSkip,
  } = props;

  const [highestEducationCurrentValue, setHighestEducationCurrentValue] = useState<NaptimeNumberType>(
    validateFieldOptions(getDegreeReadinessOptions(), highestEducation)
  );
  const [learnerTimelineCurrentValue, setLearnerTimelineCurrentValue] = useState<NaptimeNumberType>(
    validateFieldOptions(getLearnerTimelineOptions(), learnerTimeline)
  );
  const [financePlanCurrentValue, setFinancePlanCurrentValue] = useState<NaptimeNumberType>(
    validateFieldOptions(getFinancialPlanOptions(), financePlan)
  );
  const [fieldErrors, setFieldErrors] = useState<QualifyingQuestionsInputsErrorStatus>({
    financePlanErrorStatus: false,
    highestEducationErrorStatus: false,
    learnerTimelineErrorStatus: false,
  });

  const { trackInteractEoi, trackSubmitEoi } = useEoiEventing(trackingProductId);

  const highestEducationSelectRef = useRef<HTMLInputElement>(null);
  const learnerTimelineSelectRef = useRef<HTMLInputElement>(null);
  const financePlanSelectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setHighestEducation(highestEducationCurrentValue);
    setLearnerTimeline(learnerTimelineCurrentValue);
    setFinancePlan(financePlanCurrentValue);
  }, [
    highestEducationCurrentValue,
    learnerTimeline,
    financePlan,
    setHighestEducation,
    setLearnerTimeline,
    learnerTimelineCurrentValue,
    setFinancePlan,
    financePlanCurrentValue,
  ]);

  const { HeadingGroup, Content, Actions } = Dialog;

  const getHasEmptyField = () => {
    let hasEmptyFieldValue = false;

    const submittedFieldErrorsPresent = {
      financePlanErrorStatus: false,
      highestEducationErrorStatus: false,
      learnerTimelineErrorStatus: false,
    };

    Object.keys(fieldErrors).forEach((key) => {
      if (key === 'highestEducationErrorStatus' && !highestEducationCurrentValue) {
        submittedFieldErrorsPresent[key] = true;
        hasEmptyFieldValue = true;
      } else if (key === 'learnerTimelineErrorStatus' && !learnerTimelineCurrentValue) {
        submittedFieldErrorsPresent[key] = true;
        hasEmptyFieldValue = true;
      } else if (key === 'financePlanErrorStatus' && !financePlanCurrentValue) {
        submittedFieldErrorsPresent[key] = true;
        hasEmptyFieldValue = true;
      }
    });

    setFieldErrors(submittedFieldErrorsPresent);

    return hasEmptyFieldValue;
  };

  const handleQualifyingQuestionsSubmit = () => {
    if (!getHasEmptyField()) {
      handleSubmit()
        .then(() => {
          navigateNextSteps();
          trackInteractEoi('submit_qualifying_questions', 'qualifying_questions');
        })
        .catch(() => {
          trackSubmitEoi('api_failure', 'qualifying_questions');
        });
    } else {
      trackSubmitEoi('invalid_submission', 'qualifying_questions');
    }
  };

  const handleSkip = () => {
    setHasSkipped(true);
    navigateNextSteps();
    trackInteractEoi('skip', 'qualifying_questions');
  };

  if (isUserProfileLoading) {
    return (
      <Content data-testid="qualifying-questions-loading-state">
        <Loading />
      </Content>
    );
  }

  return (
    <>
      <HeadingGroup supportText={_t('So we can provide more personalized next steps')}>
        {_t('Tell us more about yourself')}
      </HeadingGroup>
      <Content css={styles.selectFieldsStyling}>
        <SelectField
          fullWidth
          label={_t('What is your highest level of education?')}
          value={highestEducationCurrentValue ?? undefined}
          onChange={(e) => setHighestEducationCurrentValue(e.target.value as number)}
          placeholder={_t('Select level of education')}
          validationStatus={fieldErrors.highestEducationErrorStatus ? 'error' : undefined}
          validationLabel={_t('Please select your highest level of education')}
          ref={highestEducationSelectRef}
        >
          {getDegreeReadinessOptions().map((option) => (
            <SelectOption key={option.enum} value={option.value}>
              {option.text}
            </SelectOption>
          ))}
        </SelectField>
        <SelectField
          fullWidth
          label={_t('How soon are you looking to start a degree?')}
          value={learnerTimelineCurrentValue ?? undefined}
          onChange={(e) => setLearnerTimelineCurrentValue(e.target.value as number)}
          placeholder={_t('Select timeline')}
          validationStatus={fieldErrors.learnerTimelineErrorStatus ? 'error' : undefined}
          validationLabel={_t('Please select your timeline')}
          ref={learnerTimelineSelectRef}
        >
          {getLearnerTimelineOptions().map((option) => (
            <SelectOption key={option.enum} value={option.value}>
              {option.text}
            </SelectOption>
          ))}
        </SelectField>
        <SelectField
          fullWidth
          label={_t('How do you plan on financing your education?')}
          value={financePlanCurrentValue ?? undefined}
          onChange={(e) => setFinancePlanCurrentValue(e.target.value as number)}
          placeholder={_t('Select financial option')}
          validationStatus={fieldErrors.financePlanErrorStatus ? 'error' : undefined}
          validationLabel={_t('Please select how your are planning on financing this progam')}
          ref={financePlanSelectRef}
        >
          {getFinancialPlanOptions().map((option) => (
            <SelectOption key={option.enum} value={option.value}>
              {option.text}
            </SelectOption>
          ))}
        </SelectField>
      </Content>
      <Actions>
        <Button onClick={handleQualifyingQuestionsSubmit} css={styles.primaryButton}>
          {_t('View next steps')}
        </Button>
        {enableSkip && (
          <Button variant="secondary" onClick={handleSkip}>
            {_t('Skip')}
          </Button>
        )}
      </Actions>
    </>
  );
};

export default QualifyingQuestionsSection;
