import gql from 'graphql-tag';

import {
  getCertificatesByCategory,
  getCertificatesByProductVariant,
  getTopCertificates,
} from 'bundles/premium-hub/queries/restQueries';

export { getTopCertificates, getCertificatesByCategory, getCertificatesByProductVariant };

export const getAllDomains = gql`
  query getAllDomains {
    DomainsV1Resource {
      domains: getAll {
        elements {
          id
          topic
          slug
          name
          description
          backgroundImageUrl
          subdomains {
            elements {
              id
              slug
              topic
              name
              domainId
              description
            }
          }
        }
      }
    }
  }
`;

export const getCertificateRevenuesFromNostos = gql`
  query GetCertificateRevenueQuery($slugs: String!) {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "certificate_popularity_gsheet_data", keys: $slugs) {
        elements {
          id
          content
        }
      }
    }
  }
`;

export default {
  getTopCertificates,
  getAllDomains,
};
