import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

export type WidthOptions = { contentWidth: string };

export const getContentWidth = ({ contentWidth }: WidthOptions) => {
  if (contentWidth === 'auto') {
    return css`
      width: auto;
      margin: inherit;
    `;
  }

  return css`
    width: ${contentWidth};
    margin: 0 auto;

    ${breakpoints.down('sm')} {
      width: auto;
      margin: inherit;
    }
  `;
};
