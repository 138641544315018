import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('FullscreenDialog', [
  'dialog',
  'scrollContainer',
]);

export const getFullscreenDialogCss = () => {
  return css`
    .${classes.dialog} {
      background-color: var(--cds-color-neutral-background-primary);
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      position: fixed;
      top: 0;
      left: 0;
    }

    .${classes.scrollContainer} {
      flex: 1 1 auto;
    }
  `;
};
