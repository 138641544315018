import { useCallback, useEffect } from 'react';

import store from 'js/lib/coursera.store';
import { useLocation, useRouter } from 'js/lib/useRouter';
import utmSource from 'js/lib/utm-source';

import * as actions from 'bundles/authentication/shared/actions';
import { isTermsOfUseRolloutForUS } from 'bundles/authentication/utils';
import thirdPartyAuth from 'bundles/third-party-auth/lib';
import Instrumentation from 'bundles/userModal/lib/instrumentation';

const PATH_BLOCKLIST = ['/degrees', '/coronavirus', '/government', '/for-university-and-college-students', '/business'];
const ONE_SECOND = 1000;

const GoogleID = () => {
  const location = useLocation();
  const router = useRouter();

  const isPathBlocked = PATH_BLOCKLIST.filter((item) => router.location.pathname.startsWith(item)).length > 0;
  const isEmailSource = utmSource.isEmail();
  const hasPreviouslyLoggedIn = store.get('has_previously_logged_in');

  const show = useCallback(() => {
    const previousURL = location.pathname;

    thirdPartyAuth
      .connect('googleOneTap')
      .then((response) => {
        Instrumentation.thirdPartyAuth('googleOneTap', response);

        const { isRegistration = false } = response ?? {};

        if (isRegistration) {
          actions.onSignup(undefined, {
            addShowTouAcceptParam: isTermsOfUseRolloutForUS(),
          });
        } else {
          actions.onLogin();
        }
      })
      .fail((error) => {
        Instrumentation.thirdPartyError('googleOneTap', error);

        const code = error?.code;
        let query = { ...router.location.query };

        switch (code) {
          // We need to support old cases so I'll be supporting accountLinkedToSocialAccount and
          // existingCourseraAccountNoPasswordSet until we fully deploy this
          case 'accountLinkedToSocialAccount':
          case 'existingCourseraAccountNoPasswordSet':
            query = { ...query, authMode: 'linked', authType: 'google', serviceCode: error?.existingAccountType };
            break;

          case 'unknownStatus':
            // authMode is coming from router.location.query
            query = { ...query, authType: 'google', errorCode: code };
            break;

          default:
            query = { ...query, authMode: 'complete', authType: 'google', completeMode: code };
            break;
        }

        const targetUrl = {
          pathname: router.location.pathname,
          params: router.params,
          query,
        };

        router.push({ ...targetUrl, pathname: previousURL });
      });
  }, [location.pathname, router]);

  useEffect(() => {
    if (!isPathBlocked && !isEmailSource && !hasPreviouslyLoggedIn) {
      setTimeout(show, ONE_SECOND);
    }
    // We only want to execute this ONCE after the file is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GoogleID;
