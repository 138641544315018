import type { PropsWithChildren } from 'react';
import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { Query } from 'react-apollo';

import type {
  Eoi_RealTimeTargetingOptionsInput as RealTimeTargetingOptions,
  Eoi_UrlParameterInput as UrlParameterInput,
} from '__generated__/graphql-types';

import { simplifyQueryValue } from 'js/lib/queryUtils';
import { useLocation } from 'js/lib/useRouter';

import ShouldPrepareToShowChiliPiperForDegreeProgramQuery from 'bundles/expression-of-interest/components/ChiliPiper/ShouldPrepareToShowChiliPiperForDegreeProgram.graphql';
import ShouldShowChiliPiperForDegreeProgramQuery from 'bundles/expression-of-interest/components/ChiliPiper/ShouldShowChiliPiperForDegreeProgram.graphql';
import { CHILI_PIPER_ACCOUNT, addChiliPiperWidget } from 'bundles/expression-of-interest/utils/chiliPiperUtils';

import type {
  ShouldPrepareToShowChiliPiperForDegreeProgramQuery as ShouldPrepareToShowChiliPiperForDegreeProgramQueryData,
  ShouldPrepareToShowChiliPiperForDegreeProgramQueryVariables,
} from './__generated__/ShouldPrepareToShowChiliPiperForDegreeProgram';
import type {
  ShouldShowChiliPiperForDegreeProgramQuery as ShouldShowChiliPiperForDegreeProgramQueryData,
  ShouldShowChiliPiperForDegreeProgramQueryVariables,
} from './__generated__/ShouldShowChiliPiperForDegreeProgram';

export type ChiliPiperInfo = {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  ProgramSlug: string;
  SurveyResponseId: string;
};

type ChiliPiperContextType = {
  submitToChiliPiper: (info: ChiliPiperInfo, router: string) => void;
};

const ChiliPiperContext = createContext<ChiliPiperContextType | undefined>(undefined);

export const useChiliPiper = (): ChiliPiperContextType | undefined => {
  return useContext(ChiliPiperContext);
};

type Props = {
  programSlug: string;
};

export const ChiliPiperProvider = ({ programSlug, children }: PropsWithChildren<Props>) => {
  const locationContext = useLocation();
  const [dateTimeAtMount, setDateTimeAtMount] = useState(0);
  const [shouldPrepareForChiliPiper, setShouldPrepareForChiliPiper] = useState(false);
  const [shouldShowChiliPiper, setShouldShowChiliPiper] = useState(false);

  const [didMount, setDidMount] = useState(false);

  const [matchingUrlParameters, setMatchingUrlParameters] = useState<Array<UrlParameterInput>>([]);
  const [dataForChiliPiper, setDataForChiliPiper] = useState<{ info: ChiliPiperInfo; router: string } | undefined>();

  useEffect(() => {
    // Capture time at mount. Should be only called once.
    setDateTimeAtMount(Date.now());
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (!shouldPrepareForChiliPiper) {
      return;
    }
    const urlParameters: Array<UrlParameterInput> = [];
    const queryParams = locationContext.query;
    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];
      urlParameters.push({ key, value: simplifyQueryValue(value) ?? '' }); // TODO handle null/undefined case
    });
    setMatchingUrlParameters(urlParameters);

    addChiliPiperWidget();
  }, [shouldPrepareForChiliPiper, locationContext]);

  useEffect(() => {
    if (!shouldShowChiliPiper || !dataForChiliPiper) {
      return;
    }

    window.ChiliPiper.submit(CHILI_PIPER_ACCOUNT, dataForChiliPiper.router, {
      lead: dataForChiliPiper.info,
      map: true,
    });
  }, [shouldShowChiliPiper, dataForChiliPiper]);

  const realtimeTargetingOptions: RealTimeTargetingOptions = {
    totalTimeOnPageInMiliseconds: Date.now() - dateTimeAtMount,
    matchingUrlParameters,
  };

  const submitToChiliPiper = (info: ChiliPiperInfo, routerData: string) => {
    setDataForChiliPiper({ info, router: routerData });
  };

  return (
    <ChiliPiperContext.Provider
      value={{
        submitToChiliPiper,
      }}
    >
      <Query<
        ShouldPrepareToShowChiliPiperForDegreeProgramQueryData,
        ShouldPrepareToShowChiliPiperForDegreeProgramQueryVariables
      >
        query={ShouldPrepareToShowChiliPiperForDegreeProgramQuery}
        variables={{ programSlug }}
        errorPolicy="all"
        skip={!didMount || !programSlug}
        context={{ clientName: 'gatewayGql' }}
        onCompleted={(data) => {
          const shouldPrepare = !!data.ExpressionOfInterest?.shouldPrepareToShowChiliPiperForDegreeProgram;
          setShouldPrepareForChiliPiper(shouldPrepare);
        }}
      >
        {() => {
          return (
            <Query<ShouldShowChiliPiperForDegreeProgramQueryData, ShouldShowChiliPiperForDegreeProgramQueryVariables>
              query={ShouldShowChiliPiperForDegreeProgramQuery}
              variables={{ programSlug, realtimeTargetingOptions }}
              skip={!(shouldPrepareForChiliPiper && !!dataForChiliPiper)}
              errorPolicy="all"
              context={{ clientName: 'gatewayGql' }}
              onCompleted={(data) => {
                const shouldEnable = !!data.ExpressionOfInterest?.shouldShowChiliPiperForDegreeProgram;
                setShouldShowChiliPiper(shouldEnable);
              }}
            >
              {() => <>{children}</>}
            </Query>
          );
        }}
      </Query>
    </ChiliPiperContext.Provider>
  );
};
