/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment } from 'react';
import type { MutableRefObject } from 'react';

import { processImage } from 'js/lib/imgix';

import { CardMetadata, ProductCard } from '@coursera/cds-core';
import type { ProductType, SectionName } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { getProductTypeFromVariant } from 'bundles/collections-carousel/constants';
import { PRODUCT_CARD_PARTNER_LOGO_PARAMS } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { UnifiedProductCardProps } from 'bundles/premium-hub/types';
import { removeProductIdPrefix } from 'bundles/premium-hub/utils/dataTransformationUtils';
import { buildValuePropCardUrl } from 'bundles/premium-hub/utils/index';
import { eventingV3ProductType } from 'bundles/product-card/components/utils/productCardV2Utils';


const bannerImgixParams = {
  auto: 'format,compress',
  format: 'avif',
  fit: 'fill',
  width: 350,
};

type Props = {
  product: UnifiedProductCardProps;
  rankingIndex: number;
  subindex?: number;
  title: string;
  sectionName: SectionName;
  displayVariant?: 'grid' | 'list';
};

const ProductCardForCourseAndS12n: React.FunctionComponent<Props> = ({
  product,
  rankingIndex,
  subindex,
  title,
  sectionName,
  displayVariant = 'grid',
}) => {
  const trackV3 = useTracker();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();

  const { id, title: productTitle, slug, bannerImage, partnerInfo, programTimeFrame, productVariant } = product;

  const eventV3ProductType = eventingV3ProductType(getProductTypeFromVariant(product.productVariant));
  const trackingDataV3 = {
    productCard: { index: rankingIndex },
    product: {
      id: removeProductIdPrefix(id ?? ''),
      slug: slug ?? '',
      name: productTitle,
      ...(eventV3ProductType !== undefined && {
        type: eventV3ProductType as ProductType,
      }),
    },
    pageSection: {
      sectionName: sectionName as SectionName,
      subindex,
      index: sectionIndex,
    },
  };

  const valuePropositionCardRef: MutableRefObject<HTMLAnchorElement | null> = useVisibilityTracker(
    'view_product_card',
    trackingDataV3
  );

  const productLink = buildValuePropCardUrl(slug, productVariant);
  const footerStrings = [];
  if (programTimeFrame) footerStrings.push(programTimeFrame);
  return (
    <ProductCard
      variant={displayVariant}
      productType="Degree"
      renderPreviewImage={() => (
        <img src={processImage(bannerImage, bannerImgixParams)} aria-hidden="true" loading="lazy" />
      )}
      partners={[
        {
          name: partnerInfo?.partnerName || '',
          logoUrl: processImage(partnerInfo?.partnerLogo || '', PRODUCT_CARD_PARTNER_LOGO_PARAMS),
        },
      ]}
      title={{
        name: productTitle ?? '',
        linkProps: {
          href: productLink,
          ref: valuePropositionCardRef,
          onClick: () => trackV3('click_product_card', trackingDataV3),
        },
        customLinkProps: {
          trackingName: 'learn_more_value_proposition_related_program_link',
          data: { valueProposition: title, degreeId: id },
          withVisibilityTracking: true,
        },
      }}
      footer={
        <Fragment>
          {footerStrings.length ? <CardMetadata data-testid="DegreeFooterStrings" metadata={footerStrings} /> : null}
        </Fragment>
      }
    />
  );
};

export default ProductCardForCourseAndS12n;
