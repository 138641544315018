import type { ReactNode } from 'react';
import React from 'react';

import { useOption } from '@react-aria/listbox';
import { getItemCount } from '@react-stately/collections';
import type { ListState } from '@react-stately/list';
import type { Node } from '@react-types/shared';
import clsx from 'clsx';

import { CheckIcon } from '@coursera/cds-icons';

import ButtonBase from '@core/ButtonBase';
import Typography from '@core/Typography2';

import optionsCss, { classes, itemLayoutCss } from './styles/optionCss';

export type Props = {
  /**
   * Additional element rendered at the start of option element.
   */
  prefix?: ReactNode;

  /**
   * Additional element rendered at the end of option element.
   */
  suffix?: ReactNode;

  /**
   * Additional context for an option
   */
  supportText?: string;

  /**
   * Additional class name
   */
  className?: string;

  /**
   * Custom text value to use for matching or selection
   */
  textValue?: string;

  /**
   * Node in collection
   * @ignore
   */
  item: Node<unknown>;

  /**
   * ListBox state
   * @ignore
   */
  state: ListState<unknown>;
};

/**
 * Renders option
 */
const Option = (props: Props) => {
  const {
    supportText,
    prefix,
    suffix: suffixProp,
    item,
    state,
    className,
  } = props;

  const ref = React.useRef(null);
  const { optionProps, isSelected, isDisabled, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  optionProps['aria-posinset'] =
    typeof item.index !== 'undefined' ? item.index + 1 : undefined;
  optionProps['aria-setsize'] = getItemCount(state.collection);

  const ElementType: React.ElementType = item.props.href ? 'a' : 'div';

  const suffix =
    suffixProp ||
    (isSelected ? (
      <div css={{ padding: '0 var(--cds-spacing-50)', margin: 'auto 0' }}>
        <CheckIcon
          className={classes.checkboxIcon}
          color="interactive"
          size="medium"
        />
      </div>
    ) : undefined);

  return (
    <ButtonBase
      ref={ref}
      className={clsx(className, {
        [classes.focused]: isFocused,
        [classes.checked]: isSelected,
        [classes.disabled]: isDisabled,
      })}
      component={ElementType}
      css={[itemLayoutCss, optionsCss]}
      {...optionProps}
    >
      {prefix && <div className={classes.prefix}>{prefix}</div>}
      <div className={classes.content}>
        <Typography color="inherit" component="div">
          {item.rendered}
        </Typography>
        {supportText && (
          <Typography
            color={isDisabled ? 'inherit' : 'supportText'}
            component="div"
            variant="bodySecondary"
          >
            {supportText}
          </Typography>
        )}
      </div>
      {suffix && <div className={classes.suffix}>{suffix}</div>}
    </ButtonBase>
  );
};

export default Option;
