import URI from 'jsuri';

import API from 'js/lib/api';
import store from 'js/lib/coursera.store';

import type { SignupParams } from 'bundles/authentication/shared/types/sharedTypes';
import { toValidRedirect } from 'bundles/authentication/utils/navigationUtils';
import { ENROLL } from 'bundles/enroll/utils/enrollActionParams';
import { SHOW_TERMS_OF_USE_ACCEPT_PARAM } from 'bundles/user-consent/constants';

/**
 * String that contains 6 digits.
 */
type MFA = string;

type LoginParams = {
  email: string;
  mfa?: MFA;
  password: string;
  redirectTo?: string;
  token: string;
};

// TODO(adrian): tmp solution
export type LoginResponse = Record<string, unknown>;

type InvalidateParams = {
  password: string;
};

type OnAuthenticationOptions = {
  addShowTouAcceptParam?: boolean;
};

const api = API('', { type: 'rest' });

export function redirect(url?: string): void;
export function redirect(params?: Record<string, string>): void;
export function redirect(input: string | Record<string, string> = {}): void {
  if (typeof input === 'string') {
    return window.location.replace(toValidRedirect(input));
  }

  const currentURI = window.location.href;

  const deconstructedURI = currentURI.split('/');

  const uriWithoutIndiaRegion = [
    ...deconstructedURI.slice(0, 3),
    deconstructedURI[3].replace(/^in\?/, '?'),
    ...deconstructedURI.slice(4),
  ].join('/');

  const URL = new URI(uriWithoutIndiaRegion);

  URL.deleteQueryParam('authMode');
  URL.deleteQueryParam('redirectTo');

  Object.entries(input).forEach(([key, value]) => URL.addQueryParam(key, value));

  if (window.location.href === URL.toString()) {
    return window.location.reload();
  }

  return window.location.replace(toValidRedirect(URL.toString()));
}

export async function signup(
  { email, name, password, redirectTo, token }: SignupParams,
  others: Record<string, unknown> = {}
) {
  return Promise.resolve(
    api.post('api/register/v1', {
      data: {
        email,
        name,
        password,
        recaptchaToken: token,
        verifyEmail: email,
        ...(redirectTo && { redirectTo }),
        ...others,
      },
    })
  );
}

export async function login({ email, mfa, password, redirectTo, token }: LoginParams): Promise<LoginResponse> {
  return Promise.resolve(
    api.post('api/login/v3', {
      data: {
        email,
        password,
        recaptchaToken: token,
        webrequest: true,
        ...(mfa && { code: mfa }),
        ...(redirectTo && { redirectTo }),
      },
    })
  );
}

export async function invalidate({ password }: InvalidateParams): Promise<void> {
  return Promise.resolve(
    api.post('api/v1/logoutAllDevices', {
      data: {
        password,
      },
    })
  );
}

declare global {
  interface Window {
    appName?: string;
  }
}

export function onLogin(redirectTo?: string): void;
export function onLogin(redirectTo?: Record<string, string>): void;
export function onLogin(redirectTo?: any): void {
  // Mark device as previously logged-in
  store.set('has_previously_logged_in', true);

  redirect(redirectTo);
}

export function getShouldPrioritizeOnboarding(redirectTo?: string): boolean;
export function getShouldPrioritizeOnboarding(redirectTo?: Record<string, string>): boolean;
export function getShouldPrioritizeOnboarding(redirectTo?: any): boolean {
  const isRedirectToHome = decodeURIComponent(redirectTo) === '/';
  const isRedirectToSearch = decodeURIComponent(redirectTo) === '/search';
  const isRedirectToBrowse = decodeURIComponent(redirectTo) === '/browse';

  return !redirectTo || isRedirectToHome || isRedirectToSearch || isRedirectToBrowse;
}

export function getShouldAddOnboardingProps(url?: string): boolean {
  const URL = new URI(url);

  const isEnrollmentFlow = URL.getQueryParamValue('action') === ENROLL;
  const isStudentUpswell = URL.getQueryParamValue('canContinue');
  const isFinaid = URL.getQueryParamValue('aid') === 'true';

  return !(isEnrollmentFlow || isFinaid || isStudentUpswell);
}

export function onSignup(redirectTo?: string, options?: OnAuthenticationOptions): void;
export function onSignup(redirectTo?: Record<string, string>, options?: OnAuthenticationOptions): void;
export function onSignup(redirectTo?: any, options?: OnAuthenticationOptions): void {
  // Mark device as previously logged-in
  store.set('has_previously_logged_in', true);

  // Specifically disallowing modal use on signup when enrollment or financial aid
  // is also included in the params. This prevents the traditional Onboarding flow from
  // complicating the usecase enabled by the enrollment flow for onboarding.
  // When the enrollment param is appended to the URL, we know that a user was attempting
  // to enroll before being prompted to signup. As such, we make sure that this param
  // isn't present before starting with any of the redirection strategies for Onboarding.
  let params = {};
  const shouldAddOnboardingProps = getShouldAddOnboardingProps(window.location.href);
  if (shouldAddOnboardingProps) {
    params = {
      isNewUser: 'true',
    };
  }
  if (options?.addShowTouAcceptParam) {
    params = {
      ...params,
      [SHOW_TERMS_OF_USE_ACCEPT_PARAM]: '1',
    };
  }

  // Due to authentication applying `redirectTo` params by default, we need to select
  // from what entry points we intend to surface the onboarding modal.
  const shouldPrioritizeOnboarding = getShouldPrioritizeOnboarding(redirectTo);

  if (options?.addShowTouAcceptParam || shouldPrioritizeOnboarding) {
    redirect(params || redirectTo);
  } else {
    redirect(redirectTo || params);
  }
}
