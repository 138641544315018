import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('mobilePagination', [
  'nav',
  'navStatus',
  'navControl',
  'navControlStart',
  'navControlEnd',
  'navControlFirstLast',
]);

const getMobilePaginationCss = css`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  list-style: none;

  .${classes.navStatus} {
    align-self: flex-start;
    margin-top: 6px;
  }

  .${classes.navControl} {
    display: flex;
    flex-direction: column;
    max-width: 36px;

    .${classes.navControlFirstLast} {
      margin-top: var(--cds-spacing-150);
      white-space: nowrap;
    }

    &.${classes.navControlStart} {
      align-items: flex-start;

      .${classes.navControlFirstLast} {
        margin-left: calc(var(--cds-spacing-100) * -1);
      }
    }

    &.${classes.navControlEnd} {
      align-items: flex-end;

      .${classes.navControlFirstLast} {
        margin-right: calc(var(--cds-spacing-100) * -1);
      }
    }
  }
`;

export default getMobilePaginationCss;
