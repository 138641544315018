import * as React from 'react';

import classNames from 'classnames';

import BCorpLogo from 'bundles/page-footer/components/BCorpLogo';
import type { ColumnHeaderProps } from 'bundles/page-footer/components/global-footer/common/LinkColumn';
import { ColumnHeader } from 'bundles/page-footer/components/global-footer/common/LinkColumn';
import {
  DESKTOP_BADGE_HEIGHT,
  DESKTOP_BADGE_WIDTH,
  MOBILE_BADGE_HEIGHT,
  MOBILE_BADGE_WIDTH,
} from 'bundles/page-footer/components/global-footer/constants';
import MobileAppBadges from 'bundles/page/components/MobileAppBadges';

import _t from 'i18n!nls/page';

import 'css!bundles/page-footer/components/global-footer/common/__styles__/LogoBadgeColumn';

type Props = {
  badgeColumn?: boolean;
  isMobile?: boolean;
  hideBadges?: boolean;
  hideDescription?: boolean;
  leftAligned?: boolean;
  header?: string;
  enableNewDefensiveSubfooter?: boolean;
  extraClassName?: string;
} & ColumnHeaderProps;

export default function LogoBadgeColumn({
  badgeColumn,
  isMobile,
  hideBadges,
  hideDescription,
  leftAligned,
  header,
  enableNewDefensiveSubfooter,
  extraClassName = '',
  columnHeader,
}: Props) {
  const columnClasses = classNames(
    'rc-GlobalFooter_column',
    'rc-GlobalFooter_column--logo-column',
    { 'rc-GlobalFooter_column--left-aligned': leftAligned },
    { 'rc-GlobalFooter_column--no-description': hideDescription }
  );

  return (
    <div className={columnClasses}>
      <div className="rc-GlobalFooter_column_container rc-GlobalFooter_column_container--logo-column">
        {header && (
          <ColumnHeader
            columnHeader={columnHeader}
            extraClassName={classNames('rc-GlobalFooter_column_header--badges', extraClassName)}
          >
            {header}
          </ColumnHeader>
        )}
        {!hideDescription && (
          <p className="rc-GlobalFooter_description">
            {_t(
              'Coursera provides universal access to the world’s best education, partnering with top universities and organizations to offer courses online.'
            )}
          </p>
        )}
        {!hideBadges && (
          <MobileAppBadges
            isColumn={badgeColumn && !isMobile}
            badgeHeight={isMobile ? MOBILE_BADGE_HEIGHT : DESKTOP_BADGE_HEIGHT}
            badgeWidth={isMobile ? MOBILE_BADGE_WIDTH : DESKTOP_BADGE_WIDTH}
            justifyContentBetween
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            enableNewDefensiveSubfooter={enableNewDefensiveSubfooter}
          />
        )}
        <BCorpLogo />
      </div>
    </div>
  );
}
