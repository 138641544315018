import { css } from '@emotion/react';

import { classes as buttonBaseClasses } from '@core/ButtonBase/getButtonBaseCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('ContextualHelp', [
  'container',
  'helpToggleButton',
  'label',
  'tooltipExpanded',
  'tooltipContainer',
  'tooltipContent',
  'tooltipActions',
  'tooltipCloseButton',
  'tooltipCtaLink',
  'tooltipCta',
  'helpContent',
  'expanded',
  'inverted',
]);

const getContextualHelpCss = css`
  .${classes.container} {
    display: block;

    /* This is the triangle pointer for the tooltip.
    It is added to the bottom of the CTA because that way it will always be in the correct position
    regardless of where the CTA itself renders.
    Alternately, if we render this at the top of the tooltip content,
    but we need to manually calculate the CTA position and align the triangle to it, which is less performant. */
    &.${classes.tooltipExpanded} .${classes.helpToggleButton}::before {
      border-left: solid transparent 12px;
      border-right: solid transparent 12px;
      border-bottom: solid var(--cds-color-emphasis-primary-background-xweak)
        12px;
      top: 32px;
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
    }

    &.${classes.expanded} .${classes.helpToggleButton} {
      .i-neck {
        fill: var(--cds-color-neutral-primary-invert);
      }
    }

    &.${classes.inverted} .${classes.helpToggleButton} {
      .${classes.label} {
        color: var(--cds-color-neutral-primary-invert);
      }

      &:focus,
      &:hover {
        .i-neck,
        .i-dot,
        .i-top-serif,
        .i-bottom-serif {
          fill: var(--cds-color-neutral-primary-invert);
        }

        .i-background {
          fill: var(--cds-color-neutral-primary-invert);
          stroke: var(--cds-color-neutral-primary-invert);
        }
      }

      &:active {
        .i-background {
          fill: var(--cds-color-neutral-primary-invert);
          stroke: var(--cds-color-neutral-primary-invert);
        }

        .i-neck {
          fill: var(--cds-color-neutral-primary-invert);
        }
      }
    }
  }

  .${classes.label} {
    display: inline;
  }

  .${classes.label} > * {
    /* always inline the first label sibling to correctly align the CTA button after the label */
    display: inline;
  }

  .${classes.helpToggleButton} {
    position: relative;
    bottom: -2px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: -8px 0 0 4px;

    /* override default focus ring style */
    &.${buttonBaseClasses.focusVisible}::after {
      border-radius: 50%;
      inset: 1px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .cds-button-startIcon {
      margin: 0;
    }

    &:focus,
    &:hover {
      .i-neck,
      .i-dot,
      .i-top-serif,
      .i-bottom-serif {
        fill: var(--cds-color-interactive-primary-hover);
      }

      .i-background {
        fill: var(--cds-color-interactive-primary-hover);
        stroke: var(--cds-color-interactive-primary-hover);
      }
    }

    &:active {
      .i-background {
        fill: var(--cds-color-interactive-primary-pressed);
        stroke: var(--cds-color-interactive-primary-pressed);
      }
    }
  }

  .${classes.tooltipContainer} {
    position: relative;
    width: 100%;
    margin-top: var(--cds-spacing-200);
    padding: var(--cds-spacing-200);
    background-color: var(--cds-color-emphasis-primary-background-xweak);
    border-radius: var(--cds-border-radius-50);
    overflow: auto;
    box-sizing: border-box;
  }

  .${classes.tooltipActions} {
    margin-top: var(--cds-spacing-200);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: start;
  }

  .${classes.tooltipCloseButton} {
    margin-right: var(--cds-spacing-300);
  }
`;

export default getContextualHelpCss;
