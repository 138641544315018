import clsx from 'clsx';

import Typography from '@core/Typography2';

import PaginationItem from './PaginationItem';
import getMobilePaginationCss, { classes } from './styles/mobilePaginationCss';
import type { PageAriaLabel, RenderItem } from './types';
import type { UsePaginationItem } from './usePagination';

export type MobilePaginationProps = {
  total: number;
  firstPageControlProps: UsePaginationItem;
  lastPageControlProps: UsePaginationItem;
  previousPageControlProps: UsePaginationItem;
  nextPageControlProps: UsePaginationItem;
  currentPage?: number;

  getAriaLabel?: PageAriaLabel;
  showFirstLastControls?: boolean;
  renderItem?: RenderItem;
};

const defaultRenderItem: RenderItem = (itemProps) => (
  <PaginationItem {...itemProps} />
);

const MobilePagination = (props: MobilePaginationProps) => {
  const {
    currentPage,
    total,
    firstPageControlProps,
    lastPageControlProps,
    nextPageControlProps,
    previousPageControlProps,
    showFirstLastControls = false,
    getAriaLabel,
    renderItem = defaultRenderItem,
  } = props;

  return (
    <div css={getMobilePaginationCss} data-testid="mobile-pagination">
      <div className={clsx(classes.navControl, classes.navControlStart)}>
        {renderItem({
          variant: previousPageControlProps.type,
          selected: previousPageControlProps.selected,
          page: previousPageControlProps.page,
          onClick: previousPageControlProps.onClick,
          disabled: previousPageControlProps.disabled,
          'aria-label': getAriaLabel?.(
            previousPageControlProps.type,
            previousPageControlProps.page,
            previousPageControlProps.selected
          ),
        })}

        {showFirstLastControls &&
          renderItem({
            variant: firstPageControlProps.type,
            selected: firstPageControlProps.selected,
            page: firstPageControlProps.page,
            onClick: firstPageControlProps.onClick,
            disabled: firstPageControlProps.disabled,
            'aria-label': getAriaLabel?.(
              firstPageControlProps.type,
              firstPageControlProps.page,
              firstPageControlProps.selected
            ),
            className: classes.navControlFirstLast,
          })}
      </div>
      <Typography className={classes.navStatus} component="p" role="status">
        {`Page ${currentPage} of ${total}`}
      </Typography>
      <div className={clsx(classes.navControl, classes.navControlEnd)}>
        {renderItem({
          variant: nextPageControlProps.type,
          selected: nextPageControlProps.selected,
          page: nextPageControlProps.page,
          onClick: nextPageControlProps.onClick,
          disabled: nextPageControlProps.disabled,
          'aria-label': getAriaLabel?.(
            nextPageControlProps.type,
            nextPageControlProps.page,
            nextPageControlProps.selected
          ),
        })}
        {showFirstLastControls &&
          renderItem({
            variant: lastPageControlProps.type,
            selected: lastPageControlProps.selected,
            page: lastPageControlProps.page,
            onClick: lastPageControlProps.onClick,
            disabled: lastPageControlProps.disabled,
            'aria-label': getAriaLabel?.(
              lastPageControlProps.type,
              lastPageControlProps.page,
              lastPageControlProps.selected
            ),
            className: classes.navControlFirstLast,
          })}
      </div>
    </div>
  );
};

export default MobilePagination;
