export const SectionTypes = {
  TERMINAL: 'terminalSection',
  QUESTION: 'questionSection',
};

export const RecommendationTypes = {
  COURSE: 'courseRecommendation',
  S12N: 's12nRecommendation',
};

export const ProductTypes = {
  DEGREE: 'Degree',
  SPECIALIZATION: 'Specialization',
  SPECIALIZATION_CATEGORY: 'SpecializationCategory',
  GENERIC_PAGE: 'GenericPage',
  DEGREE_CATEGORY: 'DegreeCategory',
  DEGREE_HUB: 'DegreeHub',
} as const;

export const DegreeQualifiedTitle = 'Your Next Step: Apply Now!';
