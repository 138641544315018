/* eslint-disable react/no-danger */
import * as React from 'react';

import type { Degree, PPCDegreeCategory } from 'bundles/premium-hub/types';
import { getUrlFromProductVariant } from 'bundles/premium-hub/utils';
import { clearEmptyFields } from 'bundles/seo/utils/utils';

type Props = {
  categories?: Array<PPCDegreeCategory>;
  productList?: Array<
    | {
        slug: string;
        name: string;
      }
    | Degree
  >;
};

const createCanonicalDegreeLink = (slug: $TSFixMe) => {
  return `https://www.coursera.org/degrees/${slug}`;
};

const createItemList = (productList: $TSFixMe) =>
  productList.map(({ productVariant, slug }: $TSFixMe, index: $TSFixMe) => ({
    '@type': 'ListItem',
    position: index + 1,
    url: `https://www.coursera.org${getUrlFromProductVariant(productVariant, slug)}`,
  }));

const createMarkup = (markupJson: $TSFixMe) => ({
  __html: `
    <script type="application/ld+json">
      ${JSON.stringify(clearEmptyFields(markupJson))}
    </script>
  `,
});

const getProductsMarkupJson = (productList: $TSFixMe) => {
  if (!productList || !productList.length) {
    return null;
  }

  const itemListElement = createItemList(productList);

  const productsMarkupJson = {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement,
  };

  return productsMarkupJson;
};

const MainHubGoogleSchemaMarkup: React.FunctionComponent<Props> = ({ categories, productList }) => {
  const categoriesMarkupJson = categories && {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: categories.map((category, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      url: createCanonicalDegreeLink(category.slug),
    })),
  };

  const productsMarkupJson = getProductsMarkupJson(productList);

  if (!categoriesMarkupJson && !productsMarkupJson) {
    return null;
  }

  return (
    <div className="rc-DegreesHubGoogleSchemaMarkup">
      {categoriesMarkupJson && <div dangerouslySetInnerHTML={createMarkup(categoriesMarkupJson)} />}
      {productsMarkupJson && <div dangerouslySetInnerHTML={createMarkup(productsMarkupJson)} />}
    </div>
  );
};

export default MainHubGoogleSchemaMarkup;
