import { forwardRef } from 'react';

import { breakpoints } from '@coursera/cds-common';

import { useMediaQuery } from '@core/utils';

import type { Props as DrawerProps } from './Drawer';
import Drawer from './Drawer';
import type { Props as DropdownProps } from './Dropdown';
import Dropdown from './Dropdown';
import type { BasePopoverProps } from './types';

export type Props = {
  /**
   * If true, the popover is visible.
   */
  open: BasePopoverProps['open'];

  /**
   * Anchor element that is used to position the Dropdown component.
   */
  anchorElement: DropdownProps['anchorElement'];

  /**
   * Callback fired when Popover is closed.
   */
  onClose: BasePopoverProps['onClose'];

  /**
   * Body slot. Use sub-components like Popover.Body, Popover.Header, Popover.Footer for consistent styling.
   *
   * Can be a function that receives an object with `isDrawer` property. This property is set to true when Drawer is rendered.
   *
   * @example
   * ```tsx
   * <Popover.Header>Here goes your header component</Popover.Header>
   * <Popover.Body>Scrollable content goes here</Popover.Body>
   * <Popover.Footer>Here goes your footer</Popover.Footer>
   *
   * {({ isDrawer }) => (
   *  <>
   *    {isDrawer && (<Popover.Header>Here goes your header component</Popover.Header>)}
   *    <Popover.Body>Scrollable content goes here</Popover.Body>
   *     <Popover.Footer>Here goes your footer</Popover.Footer>
   *  </>
   *
   * )}
   *
   * ```
   */
  children:
    | React.ReactNode
    | ((props: {
        /**
         * Is set to true when Drawer is rendered.
         */
        isDrawer: boolean;
      }) => React.ReactNode);

  /**
   * Props for the Drawer component that is rendered on XS screen
   */
  drawerProps?: Omit<DrawerProps, 'open' | 'children'>;

  /**
   * Props for the Dropdown component that is rendered on SM-LG screen
   */
  dropdownProps?: Omit<DropdownProps, 'open' | 'children'>;

  /**
   * Reference to a focusable element that should receive initial focus when the
   * component opens. By default, the first focusable element inside the component
   * will receive focus.
   */
  initialFocusRef?: DropdownProps['initialFocusRef'];

  /**
   * Reference to a focusable element that should receive focus when the component
   * closes. By default, focus will return to the element that had focus when the
   * component was opened.
   */
  returnFocusRef?: DropdownProps['returnFocusRef'];
};

/**
 * The popover utility is a responsive container that can be used to display a menu list of menu options or a custom component.
 *
 * Related sub-components:
 *  - Popover.Header
 *  - Popover.Body
 *  - Popover.Footer
 *
 * See [Props](__storybookUrl__/components-utility-popover--default#props)
 */
const Popover = forwardRef<HTMLElement, Props>(function _Popover(
  props: Props,
  ref
) {
  const {
    anchorElement,
    children,
    open,
    onClose,
    initialFocusRef,
    returnFocusRef,
    drawerProps,
    dropdownProps,
  } = props;
  const isXs = useMediaQuery(breakpoints.down('xs'));

  return isXs ? (
    <Drawer
      ref={ref}
      initialFocusRef={initialFocusRef}
      open={open}
      returnFocusRef={returnFocusRef}
      onClose={onClose}
      {...drawerProps}
    >
      {typeof children === 'function' ? children({ isDrawer: isXs }) : children}
    </Drawer>
  ) : (
    <Dropdown
      ref={ref}
      anchorElement={anchorElement}
      initialFocusRef={initialFocusRef}
      open={open}
      returnFocusRef={returnFocusRef}
      onClose={onClose}
      {...dropdownProps}
    >
      {typeof children === 'function' ? children({ isDrawer: isXs }) : children}
    </Dropdown>
  );
});

export default Popover;
