/** @jsx jsx */
import { jsx } from '@emotion/react';

import { forwardRef, useCallback, useMemo } from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';

import InternationalPhoneNumberField from 'bundles/account-profile/components/i18n-phone-number-input/InternationalPhoneNumberField';
import formStyles from 'bundles/account-profile/components/i18n-phone-number-input/styles/FormStyles';
import type { CustomStyles, ValidPhone } from 'bundles/account-profile/components/i18n-phone-number-input/types';
import { newValidPhone } from 'bundles/account-profile/components/i18n-phone-number-input/utils';
import type { ProfilePhone, ProfilesV1UpdateProps } from 'bundles/account-profile/components/types';
import type ProfilesV1Type from 'bundles/naptimejs/resources/profiles.v1';

import _t from 'i18n!nls/account-profile';

type handleAccountFormChange = (data: ProfilePhone) => void;
type handleEoiFormChange = (data: { text?: string }) => void;

type Props = {
  /*
   * The old account profile type is different from the recruiting form. This is due
   * to the old form using the NaptimeResources where as the recruitment form uses the
   * generated type. https://coursera.atlassian.net/browse/LPD-48
   *
   * Since this component is used in both we are accepting both types temporarily and
   * addressing the types as tech debt.
   */
  profile?: ProfilesV1UpdateProps | ProfilesV1Type;
  /*
   * The below props were added/changed because this component is now being used in EOI forms.
   * Each form (/account-profile and EOI) show errors in different ways and
   * have slightly different functionality.
   */
  customStyles?: CustomStyles;
  // For EOI forms we don't consider an empty phone number valid
  isEmptyValid?: boolean;
  submissionError?: boolean;
  isOptional?: boolean;
  formChangeFormatter?: (phone?: ValidPhone) => { text?: string } | ProfilePhone;
  handleFormChange: handleAccountFormChange | handleEoiFormChange;
  setRegion?: (region: string) => void;
  handleError?: (hasError: boolean) => void;
};

const InternationalPhoneNumberInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    profile,
    customStyles,
    isEmptyValid = true,
    submissionError = false,
    isOptional = true,
    formChangeFormatter,
    handleFormChange,
    setRegion,
    handleError,
  } = props;

  const handlePhoneNumberChange = useCallback(
    (phone?: ValidPhone) => {
      if (formChangeFormatter) {
        handleFormChange(formChangeFormatter(phone));
      } else if (phone) {
        handleFormChange({
          phone: {
            phoneNumber: phone.phoneNumber.toString(),
            countryCode: phone.countryCode.toString(),
          },
        });
      }
    },
    [formChangeFormatter, handleFormChange]
  );

  const phoneNumberLabel = useMemo(() => {
    if (customStyles) {
      return (
        <Grid container direction="row">
          <Typography2 component="span" css={customStyles.phoneNumberLabel}>
            {_t('Phone Number')}
          </Typography2>
          {isOptional && (
            <Typography2 component="span" css={customStyles.optionalText}>
              {_t('(Optional)')}
            </Typography2>
          )}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm css={formStyles.labelWidth}>
          <div css={[formStyles.labelWidth, formStyles.formLabel]}>{_t('Phone Number')}</div>
        </Grid>
      );
    }
  }, [customStyles, isOptional]);

  if (!profile) {
    return null;
  }

  const containerGridStyles =
    customStyles !== undefined ? customStyles.phoneNumberInputContainer : formStyles.formGroup;

  let phone: ValidPhone | undefined;
  if (profile?.phone) {
    phone = newValidPhone(profile?.phone);
  } else {
    phone = undefined;
  }

  return (
    <div className="rc-PhoneNumberInput" data-testid="international_phone_number_input">
      <Grid container css={containerGridStyles}>
        {phoneNumberLabel}
        <Grid item xs={12} sm>
          <div css={formStyles.formControl}>
            <InternationalPhoneNumberField
              phone={phone}
              customStyles={customStyles}
              isEmptyValid={isEmptyValid}
              submissionError={submissionError}
              ref={ref}
              onPhoneNumberChange={handlePhoneNumberChange}
              setRegion={setRegion}
              handleError={handleError}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default InternationalPhoneNumberInput;
