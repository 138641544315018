import URI from 'jsuri';

import { supportedLanguageSubdomains } from 'js/lib/language';

import type { SeoLocation } from 'bundles/seo/CourseraMetatagsV2Types';

type PageSubdomainAndPathType = {
  subdomain: string;
  path: string;
};

type SubdomainDetailsType = {
  pageSubdomainAndPathname: PageSubdomainAndPathType | null;
  pageSubdomain?: string;
  isInternationalSubdomain: boolean;
};

export const TWITTER_DESC_MAX_LEN = 60;
export const META_DESC_MAX_LEN = 250;

// REF http://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
export const removeLineBreakFromString = (s: string) => s.replace(/(\r\n|\n|\r)/gm, ' ');
export const limitDescriptionLength = (s: string, limit = META_DESC_MAX_LEN) => {
  return s.length > limit ? `${s.substring(0, s.lastIndexOf(String.fromCharCode(32), limit - 4) + 1)}...` : s;
};

// pagetest and staging are used for e2e testing edge, behavior should be the same as www
export const isSubdomainWWW = (subdomain?: string) =>
  subdomain === 'www' || subdomain === 'pagetest' || subdomain === 'staging';

export const isProductPrimaryLanguageOnSupportedLanguageDomain = (
  productPrimaryLanguage: string,
  subdomain: string
) => {
  if (productPrimaryLanguage === subdomain) {
    return true;
  }

  // We check this because 'en' primary language is served on 'www' subdomain
  if (isSubdomainWWW(subdomain) && productPrimaryLanguage === 'en') {
    return true;
  }

  // Products with primary language of pt-BR i.e Brazilian portuguese should be served under the pt subdomain
  if (subdomain === 'pt' && productPrimaryLanguage === 'pt-BR') {
    return true;
  }

  if (
    isSubdomainWWW(subdomain) &&
    productPrimaryLanguage !== 'pt-BR' &&
    !supportedLanguageSubdomains.includes(productPrimaryLanguage)
  ) {
    // We allow product primary language that are not part of the supported language domains to live on www subdomain
    return true;
  }

  return false;
};

export const getPageUrl = (pageHref: string | undefined | null, location: SeoLocation) => {
  if (!pageHref && (!location.hostname || !location.protocol)) {
    return null;
  }
  const pageURL = pageHref || `https://${location.hostname}${location.pathname}`;
  return pageURL;
};

export const getPageSubdomainAndPath = (
  pageHref: string | undefined | null,
  location: SeoLocation
): PageSubdomainAndPathType | null => {
  const pageURL = getPageUrl(pageHref, location);

  if (!pageURL) {
    return null;
  }

  if (pageURL.indexOf('https://coursera.org') >= 0 || !pageURL.includes('.coursera.org')) {
    return null;
  }

  const parsedURL = new URI(pageURL);

  return {
    subdomain: parsedURL.host().split('.')[0],
    path: parsedURL.path(),
  };
};

export const getSubdomainDetails = (
  location: SeoLocation,
  pageHref?: string | undefined | null
): SubdomainDetailsType => {
  const pageSubdomainAndPathname = getPageSubdomainAndPath(pageHref, location);
  const pageSubdomain = pageSubdomainAndPathname?.subdomain;
  const isInternationalSubdomain = !isSubdomainWWW(pageSubdomain);

  return {
    pageSubdomainAndPathname,
    pageSubdomain,
    isInternationalSubdomain,
  };
};
