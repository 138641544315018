/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import Media from 'react-media';

import { MOBILE_BREAKPOINT_PX } from 'bundles/page-footer/components/global-footer/constants';
import useUserAgent from 'bundles/page/hooks/useUserAgent';

type FooterWrapperProps = {
  footerComponentMobile: React.ReactNode;
  footerComponentDesktop: React.ReactNode;
  extraClassNames?: string;
};

export const FooterWrapper = ({
  footerComponentMobile,
  footerComponentDesktop,
  extraClassNames,
}: FooterWrapperProps) => {
  const userAgent = useUserAgent();
  return (
    <footer className={extraClassNames} data-e2e="page-footer" data-catchpoint="page-footer">
      <Media query={{ maxWidth: MOBILE_BREAKPOINT_PX - 1 }} defaultMatches={userAgent?.isMobileBrowser}>
        {(matches) => {
          return matches ? footerComponentMobile : footerComponentDesktop;
        }}
      </Media>
    </footer>
  );
};
