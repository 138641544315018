import * as React from 'react';

import { get as getUser } from 'js/lib/user';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import {
  isCoachGAEpic,
  isExistingCoachAccess,
  useProductFeatures,
} from 'bundles/ai-course-coach/utils/productFeatureFlags';
import { captureMessageInSentry } from 'bundles/ai-course-coach/utils/sentryUtils';
import { IdTypes } from 'bundles/product-features';

type Props = {
  children: (props: { showEmbeddedCoach: boolean }) => JSX.Element | null;
  epicImpression?: boolean;
};

function useCoachPermissions({ epicImpression }: { epicImpression?: boolean } = {}) {
  const userId = getUser().id?.toString();
  const [showEmbeddedCoach, setShowEmbeddedCoach] = React.useState<boolean>(false);

  const { loading, error, features } = useProductFeatures({ idType: IdTypes.User, id: userId });
  const isCoachEnterpriseEnabled = features?.get?.('enterprise', 'enableAiCoach');
  const isCoachDegreesEnabled = features?.get?.('degrees', 'enableAiCoachForDegrees');
  const isCoachGA = isCoachGAEpic({ epicImpression });
  const isExistingAccess = isExistingCoachAccess();

  React.useEffect(() => {
    if (loading || error || !features) {
      if (error) {
        captureMessageInSentry({
          level: 'warning',
          error,
          source: 'useCoachPermissions',
          extra: {
            errorString: JSON.stringify(error, null, 2),
            externalId: getUser().external_id,
            isCoachEnterpriseEnabled,
            isCoachDegreesEnabled,
            isCoachGA,
          },
        });
      }
      setShowEmbeddedCoach(false);
      return;
    }

    // always enabled for Enterprise, if it is not enterprise enabled then check the existing access group or holdout GA epic
    setShowEmbeddedCoach(isCoachDegreesEnabled || isCoachEnterpriseEnabled || isExistingAccess || isCoachGA);
  }, [loading, error, features, isCoachEnterpriseEnabled, isCoachDegreesEnabled, isCoachGA, isExistingAccess]);

  return { showEmbeddedCoach };
}

const CoachPermissionsWrapper: React.FC<Props> = ({ children, epicImpression }) => {
  const { showEmbeddedCoach } = useCoachPermissions({ epicImpression });

  return children({ showEmbeddedCoach });
};

export { CoachPermissionsWrapper, useCoachPermissions };
