import { useLocalizedStringFormatter } from '@coursera/cds-common';

import ButtonBase from '@core/ButtonBase';

import getSearchClearButtonCss, { classes } from './getSearchClearButtonCss';
import i18nMessages from './i18n';

type Props = {
  onClear: () => void;
  disabled?: boolean;
};

/**
 * Custom icon button for clearing the search input
 */
export const SearchClearButton = (props: Props) => {
  const { onClear, disabled } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  return (
    <ButtonBase
      aria-label={stringFormatter.format('clear_search')}
      className={classes.searchClearButton}
      css={getSearchClearButtonCss}
      disabled={disabled}
      onClick={onClear}
    >
      {/*
        TODO: CDS design team will eventually find a scalable way but for now we download the SVG directly from MUI -
        https://fonts.google.com/icons?selected=Material+Symbols+Outlined:cancel:FILL@0;wght@400;GRAD@0;opsz@48&icon.query=cancel

        see comment: https://www.figma.com/file/G6nWyeonN9QmItPpFQysVv?node-id=91:22206#474312739
      */}
      <svg
        aria-hidden={true}
        className={classes.searchClearIcon}
        viewBox="0 -940 940 940"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m330-288 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
      </svg>
    </ButtonBase>
  );
};
