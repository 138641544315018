import * as React from 'react';

import classNames from 'classnames';

import type { FooterLink } from 'bundles/page-footer/components/global-footer/types';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/page';

import 'css!bundles/page-footer/components/global-footer/common/__styles__/Copyright';

type Props = {
  links?: Array<FooterLink>;
  marginBottom?: boolean;
  marginTop?: boolean;
};

export default function Copyright({ marginBottom, marginTop, links }: Props) {
  const currentYear = new Date().getFullYear();

  const copyrightRowClasses = classNames('rc-GlobalFooter_copyright-row', {
    'rc-GlobalFooter_copyright-row--margin-bottom': marginBottom,
    'rc-GlobalFooter_copyright-row--margin-top': marginTop,
    'rc-GlobalFooter_copyright-row--no-list': !Array.isArray(links),
    'lohp-rebrand': true,
  });

  return (
    <div className={copyrightRowClasses}>
      <span className="rc-GlobalFooter_copyright-row_text rc-GlobalFooter_copyright_col">
        {_t('© #{currentYear} Coursera Inc. All rights reserved.', { currentYear })}
      </span>
      {Array.isArray(links) && (
        <ul className="rc-GlobalFooter_copyright_list rc-GlobalFooter_copyright_col">
          {links.map((link) => (
            <li className="rc-GlobalFooter_copyright_list_item" key={link.label}>
              <TrackedLink2
                className="rc-GlobalFooter_copyright_list_item_link rc-GlobalFooter_copyright-row_text"
                trackingName={`sub-content_link_${link.label.split(' ').join('-').toLowerCase()}`}
                href={link.href}
              >
                {link.label}
              </TrackedLink2>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
