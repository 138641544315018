import type {
  Maybe,
  PremiumHubTestimonials,
  PremiumHubTestimonialsTestimonialsCollection,
} from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import { UnifiedHubProductVariant } from 'bundles/premium-hub/constants';
import type { PremiumProductProps } from 'bundles/premium-hub/hooks/withPremiumHubPremiumProductCollections';
import type { DegreeCategoryFieldsFragment } from 'bundles/premium-hub/queries/__generated__/DegreeCategoryFieldsFragment';
import type { DegreesByMarketingType_FieldsFragment as DegreesByMarketingTypeFieldsFragment } from 'bundles/premium-hub/queries/__generated__/GetDegreesByMarketingType';
import type { PremiumProductFieldsFragment } from 'bundles/premium-hub/queries/__generated__/PremiumProductFieldsFragment';
import type {
  Degree,
  PPCDegreeCategory,
  Partner,
  PremiumProductWithMetadata,
  ProductCardProps,
} from 'bundles/premium-hub/types';
import { filterNullOrUndefined } from 'bundles/premium-hub/utils';

export const convertPremiumProductToDegree = (product: PremiumProductFieldsFragment): Degree => {
  const domainIds = product.domainIds.filter(filterNullOrUndefined);
  return {
    ...product,
    domainIds,
  };
};

export const convertPpcPremiumProductToPremiumProductProps = (
  product: PremiumProductFieldsFragment
): PremiumProductProps => {
  const domainIds = product.domainIds.filter(filterNullOrUndefined);
  const partnerIds = product.partnerEntities.map((partner) => partner.id);
  const partners: Partner[] = product.partnerEntities.map((partner) => {
    const { name, id, squareLogo, location } = partner;
    return {
      name,
      id,
      squareLogo,
      location: location ? { country: location.country ?? undefined } : undefined,
    };
  });
  return {
    ...product,
    marketingName: product.name,
    bannerImage: product.thumbnailUrl,
    partnerIds,
    domainIds,
    partners: { elements: partners },
    partner: partners[0],
    degreeAttributes: {
      hasScholarship: product?.degreeAttributes?.hasScholarship,
      tuitionInUsd: product?.degreeAttributes?.tuitionInUsd,
    },
  };
};

export const convertDegreeCategoryFieldsToPPCDegreeCategory = (
  degreeCategory: DegreeCategoryFieldsFragment
): PPCDegreeCategory => {
  const { degreeCount } = degreeCategory;
  return {
    ...degreeCategory,
    degreeCount: degreeCount ?? 0,
  };
};

export const convertPremiumProductWithMetadataToPremiumHubProductCardProps = (
  product: PremiumProductWithMetadata
): ProductCardProps => {
  const {
    productVariant,
    id,
    slug,
    name,
    bannerImage,
    upcomingTermDates,
    partners,
    atAGlance,
    partnerContentful,
    programDeadlines,
    degreeAttributes,
  } = product;
  const firstPartner = partners?.elements?.[0];
  const convertedPartner = firstPartner
    ? {
        id: firstPartner.id,
        name: firstPartner.name,
        squareLogo: firstPartner.squareLogo ?? '',
        partnerMonotoneLogo: partnerContentful?.partnerMonotoneLogo?.url ?? '',
        partnerLogo: partnerContentful?.partnerLogo?.url ?? '',
        country: firstPartner?.location?.country ?? '',
      }
    : undefined;
  return {
    id,
    slug,
    name,
    bannerImage: bannerImage ?? undefined,
    degreeLevel: productVariant ?? undefined,
    duration: atAGlance?.timeFrame ?? undefined,
    upcomingTermDates: upcomingTermDates ?? undefined,
    productVariant: UnifiedHubProductVariant.Degree,
    partner: convertedPartner,
    description: atAGlance?.ranking ?? undefined,
    timeFrame: atAGlance?.timeFrame ?? undefined,
    programDeadlines,
    degreeAttributes: {
      hasScholarship: degreeAttributes?.hasScholarship,
      tuitionInUsd: degreeAttributes?.tuitionInUsd,
    },
  };
};

export const convertTestimonialsForProps = (
  testimonialsSection?: Maybe<PremiumHubTestimonials>
): PremiumHubTestimonialsTestimonialsCollection => {
  const collection: PremiumHubTestimonialsTestimonialsCollection = {
    total: -1,
    skip: -1,
    limit: -1,
    items: [],
  };

  if (testimonialsSection?.testimonialsCollection?.items) {
    const items = testimonialsSection.testimonialsCollection.items.filter(filterNullOrUndefined);
    collection.items = items;
    collection.total = items.length;
  }

  return collection;
};

export const convertDegreeByMarketingTypeToPPCFields = (
  degree: DegreesByMarketingTypeFieldsFragment
): PremiumProductFieldsFragment => {
  return {
    ...degree,
    shortName: degree.shortName ?? '',
    programDeadlines: [],
    __typename: 'PremiumProductCollections_learningProduct',
  };
};

export const getEventingProductTypeFromVariant = (productVariant?: string | null) => {
  if (!productVariant) return undefined;

  switch (productVariant) {
    case UnifiedHubProductVariant.Degree:
      return 'degree';
    case UnifiedHubProductVariant.Mastertrack:
      return 'master_track';
    case UnifiedHubProductVariant.UniversityCertificate:
      return 'premium_certificate';
    case UnifiedHubProductVariant.ProfessionalCertificate:
      return 's12n';
    default:
      return undefined;
  }
};

export const removeProductIdPrefix = (id: string) => id.replace(/^degree~/, '');
