export const TERMS_OF_USE_UPDATE_MODAL_SLUG = 'update-terms-of-use-modal';

export const PROFILE_CONSENT_COOKIE_CONSENT_NAMES = {
  ccpaRequired: 'ccpaRequired',
  gdprRequired: 'gdprRequired',
  touRequired: 'touRequired',
} as const;

export const PROFILE_CONSENT_COOKIE_NAME = 'profileconsent';

export const TERMS_OF_USE_MODAL_TYPES = {
  updateModal: 'UPDATE_MODAL',
  acceptModal: 'ACCEPT_MODAL',
} as const;

export const SHOW_TERMS_OF_USE_ACCEPT_PARAM = 'showTouAccept';

export const EXCLUDED_APP_NAMES = ['about'];
export const EXCLUDED_PATHS = /(about\/(terms|privacy))/gi;

export default {
  TERMS_OF_USE_UPDATE_MODAL_SLUG,
  TERMS_OF_USE_MODAL_TYPES,
  PROFILE_CONSENT_COOKIE_CONSENT_NAMES,
  PROFILE_CONSENT_COOKIE_NAME,
  SHOW_TERMS_OF_USE_ACCEPT_PARAM,
  EXCLUDED_APP_NAMES,
  EXCLUDED_PATHS,
};
