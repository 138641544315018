import type { SerializedStyles } from '@emotion/react';

import phoneUtil from 'google-libphonenumber';

import type { Theme } from '@coursera/cds-core';

const PhoneNumberFormat = phoneUtil.PhoneNumberFormat;

export type UndefinedOr<T> = T | undefined;

type Brand<K, T> = K & { __brand: T };

// 2 alpha ISO 3166-1  (ie, 'US')
// see https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
export type PhoneNumberRegion = Brand<string, 'PhoneNumberRegion'>;

// this is an invalid region in google-libphonenumber
export const INVALID_REGION = 'ZZ' as PhoneNumberRegion;

// this is an invalid country code in google-libphonenumber
export const INVALID_COUNTRY_CODE = 0 as PhoneNumberCountryCode;

export const DEFAULT_REGION = 'US' as PhoneNumberRegion;

export function isPhoneNumberRegion(maybe: string): maybe is PhoneNumberRegion {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  const code = util.getCountryCodeForRegion(maybe);
  return !!code && maybe !== INVALID_REGION && code !== INVALID_COUNTRY_CODE;
}

export function newPhoneNumberRegion(region: string): UndefinedOr<PhoneNumberRegion> {
  return isPhoneNumberRegion(region) ? region : undefined;
}

// the number country code (ie, '+1')
// see https://en.wikipedia.org/wiki/List_of_country_calling_codes
export type PhoneNumberCountryCode = Brand<number, 'PhoneNumberCountryCode'>;

export function isCountryCode(maybeCode: number): maybeCode is PhoneNumberCountryCode {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  const region = util.getRegionCodeForCountryCode(maybeCode);
  return !!region && region !== INVALID_REGION && maybeCode !== INVALID_COUNTRY_CODE;
}

export function newCountryCode(maybe: number | string): UndefinedOr<PhoneNumberCountryCode> {
  switch (typeof maybe) {
    case 'string':
      try {
        return newCountryCode(parseInt(maybe, 10));
      } catch (e) {
        return undefined;
      }
    case 'number':
      return isCountryCode(maybe) ? maybe : undefined;
    default:
      return undefined;
  }
}

function newPhoneNumber<T extends Brand<string, unknown>>(
  maybe: string,
  format: phoneUtil.PhoneNumberFormat,
  region?: PhoneNumberRegion
): UndefinedOr<T> {
  const util = phoneUtil.PhoneNumberUtil.getInstance();
  const validationResult = phoneUtil.PhoneNumberUtil.ValidationResult;
  try {
    const phoneNumber = util.parse(maybe, region);
    if (util.isPossibleNumberWithReason(phoneNumber) === validationResult.IS_POSSIBLE) {
      if (!region || phoneNumber.getCountryCode() === util.getCountryCodeForRegion(region)) {
        return util.format(phoneNumber, format) as T;
      }
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}

// a phone number in E164 format, ie, just `+` and digits
// for a number like `+1 (424) 425-2467`, the "E164" version is `+14244252467`
// see https://en.wikipedia.org/wiki/E.164
export type E164PhoneNumber = Brand<string, 'E164PhoneNumber'>;

export function newE164PhoneNumber(maybe: string, region?: PhoneNumberRegion): UndefinedOr<E164PhoneNumber> {
  return newPhoneNumber<E164PhoneNumber>(maybe, PhoneNumberFormat.E164, region);
}

export function isE164PhoneNumber(maybe: string): maybe is E164PhoneNumber {
  return !!newE164PhoneNumber(maybe);
}

// pretty formatted phone number WITH international country code
// for example `+1 (424) 425-2467`
export type InternationalPhoneNumber = Brand<string, 'InternationalPhoneNumber'>;

export function newInternationalPhoneNumber(maybe: string): UndefinedOr<InternationalPhoneNumber> {
  return newPhoneNumber<InternationalPhoneNumber>(maybe, PhoneNumberFormat.INTERNATIONAL);
}

export function isInternationalPhoneNumber(maybe: string): maybe is InternationalPhoneNumber {
  return !!newInternationalPhoneNumber(maybe);
}

// pretty formatted phone number WITHOUT international country code
// for example `(424) 425-2467`
export type NationalPhoneNumber = Brand<string, 'NationalPhoneNumber'>;

export function newNationalPhoneNumber(maybe: string, region: PhoneNumberRegion): UndefinedOr<NationalPhoneNumber> {
  return newPhoneNumber<NationalPhoneNumber>(maybe, PhoneNumberFormat.NATIONAL, region);
}

export function isNationalPhoneNumber(maybe: string, region: PhoneNumberRegion): maybe is NationalPhoneNumber {
  return !!newNationalPhoneNumber(maybe, region);
}

export type RegionDetails = {
  code: PhoneNumberCountryCode;
  label: string;
};

export type PhoneNumberRegionToDetailsMapping = Record<PhoneNumberRegion, RegionDetails>;

export type ValidPhone = {
  phoneNumber: E164PhoneNumber | '';
  countryCode: PhoneNumberCountryCode;
};

export type CustomStyles = {
  phoneNumberLabel?: (theme: Theme) => SerializedStyles;
  phoneNumberTextField?: (theme: Theme) => SerializedStyles;
  optionalText: SerializedStyles;
  phoneNumberInputContainer?: SerializedStyles;
};

export const __exportForTesting__ = {
  newPhoneNumber,
};
