import type { LegacyRef } from 'react';
import * as React from 'react';

import withKeyboardControls from './withKeyboardControls';

type ChildProps = {
  'aria-controls'?: string;
  'aria-labelledby'?: string;
  id?: string;
};

type Props = {
  children: React.ReactNode;
  domRef?: LegacyRef<HTMLUListElement>;
  activateClick?: boolean;
  selectOnArrow?: boolean;
  allowDefaultOnEnter?: boolean;
  onEnter?: (childProps: ChildProps, event: React.KeyboardEvent<HTMLElement>) => void;
  onEsc?: (childProps: ChildProps, event: React.KeyboardEvent<HTMLElement>) => void;
};

const List = ({
  onEsc,
  onEnter,
  activateClick,
  allowDefaultOnEnter,
  selectOnArrow,
  children,
  domRef,
  ...otherProps
}: Props) => (
  <ul ref={domRef} {...otherProps}>
    {children}
  </ul>
);

const ListWithKeyboardControls = withKeyboardControls(List);

export default ListWithKeyboardControls;
