import { useMemo } from 'react';

import {
  IS_PART_OF_COURSERA_LITE,
  IS_PART_OF_COURSERA_PLUS,
  SEARCH_FILTERS_FOR_URL_SYNC,
} from 'bundles/search-common/constants/filter';
import { useOwnsCourseraLite } from 'bundles/search-common/providers/LearnerProvider';
import type { SearchURLParameters } from 'bundles/search-common/providers/searchTypes';
import { addFiltersToFacetFilters } from 'bundles/search-common/utils/providerUtils';

function useSearchParamsFromQueryString(queryString: string): SearchURLParameters {
  const isSubscribedToCourseraLite = useOwnsCourseraLite();

  const searchParams: SearchURLParameters = useMemo(() => {
    const queryParams = new URLSearchParams(queryString);
    const params: SearchURLParameters = {};

    for (const [key, value] of queryParams.entries()) {
      if (SEARCH_FILTERS_FOR_URL_SYNC.includes(key)) {
        const facetFilter = `${key}:${value}`;
        params.facetFilters = addFiltersToFacetFilters([facetFilter], params.facetFilters);
      } else if (key === IS_PART_OF_COURSERA_LITE && isSubscribedToCourseraLite) {
        const facetFilter = `${IS_PART_OF_COURSERA_PLUS}:${value}`;
        params.facetFilters = addFiltersToFacetFilters([facetFilter], params.facetFilters);
      } else if (key === 'query') {
        params.query = value;
      } else if (key === 'page') {
        params.page = +value;
      } else if (key === 'sortBy' && (value === 'BEST_MATCH' || value === 'NEW')) {
        params.sortBy = value;
      }
    }

    return params;
  }, [queryString, isSubscribedToCourseraLite]);

  return searchParams;
}

export default useSearchParamsFromQueryString;
