/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { DegreeWebinar_DegreeWebinar as DegreeWebinar } from '__generated__/graphql-types';

import { processImage } from 'js/lib/imgix';
import { MOMENT_FORMATS, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, Grid, Link, Typography2 } from '@coursera/cds-core';
import { VideoIcon } from '@coursera/cds-icons';

import { buildDegreeUrlRelative } from 'bundles/common/utils/urlUtils';
import type { TrackingProps } from 'bundles/page/components/TrackedLink2';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { PRODUCT_CARD_PARTNER_LOGO_PARAMS } from 'bundles/premium-hub/constants';
import { eventSchemaDataFromWebinar } from 'bundles/premium-hub/utils/structuredDataUtils';
import GoogleMarkup from 'bundles/seo/components/GoogleMarkup';

import _t from 'i18n!nls/premium-hub';

const styles = {
  container: css`
    position: relative;
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    border-radius: 16px;
    padding: var(--cds-spacing-200);
    height: 100%;
    transform: translateZ(0);
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);

    &:hover {
      box-shadow: 0 0 40px -8px rgb(0 0 0 / 16%), 0 0 24px -16px rgb(0 0 0 / 1600%);
      transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
    }
  `,

  pastWebinarContainer: css`
    background-color: var(--cds-color-neutral-background-primary-weak);
  `,

  webinarContent: css`
    height: calc(100% + var(--cds-spacing-200));
  `,

  webinarMonth: css`
    text-align: center;
  `,
  webinarDay: css`
    text-align: center;
  `,
  webinarTime: css`
    text-align: center;
    text-transform: uppercase;
  `,

  webinarInfo: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,

  partnerInfo: css`
    margin-bottom: var(--cds-spacing-100);
    display: flex;
    gap: var(--cds-spacing-50);
    align-items: center;
  `,

  partnerLogo: css`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
  `,

  partnerName: css`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,

  webinarTitle: css`
    margin-bottom: var(--cds-spacing-200);
    flex: 1;

    &:visited,
    &:hover {
      color: inherit !important;
      text-decoration: none;
    }

    // Makes the clickable area of the title link cover the whole card (similar to the CDS ProductCard)
    &:before {
      content: '';
      cursor: inherit;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
  `,

  degreeName: css`
    margin-bottom: var(--cds-spacing-100);
  `,

  upcomingWebinarTitle: css`
    color: var(--cds-color-neutral-primary);
  `,

  previousWebinarTitle: css`
    color: var(--cds-color-neutral-primary-weak);
  `,

  cta: css`
    margin-right: var(--cds-spacing-200);
  `,

  videoIcon: css`
    margin: 0 auto;
    display: block;
  `,
  infoContainer: css`
    margin-bottom: var(--cds-spacing-200);
  `,
};

const WebinarDate = ({ date }: { date: Date }) => {
  return (
    <>
      <Typography2 variant="subtitleMedium" component="p" css={styles.webinarMonth}>
        {formatDateTimeDisplay(date, MOMENT_FORMATS.LONG_MONTH_ONLY_DISPLAY)}
      </Typography2>
      <Typography2 variant="titleLarge" component="p" css={styles.webinarDay}>
        {formatDateTimeDisplay(date, MOMENT_FORMATS.DAY)}
      </Typography2>
      <Typography2 variant="bodySecondary" component="p" css={styles.webinarTime}>
        {formatDateTimeDisplay(date, MOMENT_FORMATS.TIME_ONLY_DISPLAY)}
      </Typography2>
    </>
  );
};

type WebinarCardProps = {
  webinar: DegreeWebinar;
  refAlt?: (ref: HTMLAnchorElement | null) => void;
};

export const WebinarCard = ({ webinar, refAlt }: WebinarCardProps) => {
  const { catalogDegree, timestamp, title, registerLink, recordingLink } = webinar;
  if (!catalogDegree || !timestamp?.seconds) return null;
  const { languageCode } = catalogDegree;
  const firstPartner = catalogDegree?.partnerEntities?.[0];
  const partnerLogo = firstPartner && processImage(firstPartner.squareLogo, PRODUCT_CARD_PARTNER_LOGO_PARAMS);
  const degreePageUrl = buildDegreeUrlRelative(catalogDegree.slug);
  const webinarDate = new Date(parseInt(timestamp?.seconds, 10) * 1000);
  const isUpcoming = webinarDate > new Date();
  const structuredData = eventSchemaDataFromWebinar(webinar);

  // Don't render the component if the register/recording links are not provided
  if ((isUpcoming && !registerLink) || (!isUpcoming && !recordingLink)) return null;

  return (
    <>
      {structuredData && <GoogleMarkup graph={structuredData} />}

      <div css={[styles.container, isUpcoming ? null : styles.pastWebinarContainer]}>
        <Grid container alignItems="center" spacing={12} css={styles.webinarContent}>
          <Grid item justifyContent="center" xs={3} md={4}>
            {isUpcoming ? (
              <WebinarDate date={webinarDate} />
            ) : (
              <>
                <TrackedLink2
                  href={recordingLink}
                  withVisibilityTracking={false}
                  trackingName="watch_recording_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  ariaLabel={_t('Watch a replay of #{title}', {
                    title,
                  })}
                  hrefLang={languageCode}
                >
                  <VideoIcon width={48} height={48} color="support" css={styles.videoIcon} />
                </TrackedLink2>
              </>
            )}
          </Grid>

          <Grid item justifyContent="center" xs={9} md={8} css={styles.webinarInfo}>
            <div css={styles.infoContainer}>
              <div css={styles.partnerInfo}>
                {partnerLogo && (
                  <img
                    src={partnerLogo}
                    alt={_t('Partner Logo for #{partnerName}', {
                      partnerName: firstPartner?.name,
                    })}
                    css={styles.partnerLogo}
                    loading="lazy"
                  />
                )}
                <Typography2
                  variant="bodySecondary"
                  color="supportText"
                  component="span"
                  css={styles.partnerName}
                  lang={languageCode}
                >
                  {firstPartner && firstPartner.name}
                </Typography2>
              </div>

              <Typography2
                component="p"
                variant="bodySecondary"
                color={isUpcoming ? 'body' : 'supportText'}
                css={styles.degreeName}
                lang={languageCode}
              >
                {catalogDegree.name}
              </Typography2>

              <Link
                href={isUpcoming ? registerLink : recordingLink}
                component={TrackedLink2}
                variant="quiet"
                typographyVariant="subtitleMedium"
                target="_blank"
                rel="noopener noreferrer"
                css={[styles.webinarTitle, isUpcoming ? styles.upcomingWebinarTitle : styles.previousWebinarTitle]}
                trackingName={isUpcoming ? 'register_to_watch_link' : 'watch_recording_link'}
                withVisibilityTracking={false}
                hrefLang={languageCode}
                lang={languageCode}
                refAlt={refAlt}
              >
                {title}
              </Link>
            </div>

            <div>
              {isUpcoming ? (
                <Button
                  variant="primary"
                  size="small"
                  css={styles.cta}
                  data-testid="webinar-cta-btn"
                  trackingName="register_to_watch_link"
                  aria-label={_t('Register for #{title}', {
                    title,
                  })}
                  component={(props: TrackingProps) => (
                    <TrackedLink2
                      {...props}
                      href={registerLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      hrefLang={languageCode}
                    />
                  )}
                  withVisibilityTracking={false}
                >
                  {_t('Register')}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  size="small"
                  css={styles.cta}
                  data-testid="webinar-cta-btn"
                  trackingName="watch_recording_link"
                  aria-label={_t('Watch a replay of #{title}', {
                    title,
                  })}
                  component={(props: TrackingProps) => (
                    <TrackedLink2
                      {...props}
                      href={recordingLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      hrefLang={languageCode}
                    />
                  )}
                  withVisibilityTracking={false}
                >
                  {_t('Watch replay')}
                </Button>
              )}
              <Button
                href={degreePageUrl}
                component="a"
                variant="ghost"
                size="small"
                aria-label={_t('Learn more about the #{programName} degree program', {
                  programName: catalogDegree.name,
                })}
                hrefLang={languageCode}
              >
                {_t('View degree')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default WebinarCard;
