/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { CardMetadata } from '@coursera/cds-core';
import { ReadingIcon, VideoIcon } from '@coursera/cds-icons';

import type { RecommendedNavigationItem } from 'bundles/ai-course-coach/utils/types';
import toHumanReadableTypeName from 'bundles/ondemand/utils/toHumanReadableTypeName';

type Props = {
  metadata: string[];
  itemType: RecommendedNavigationItem;
};

const styles = {
  metadata: css`
    display: flex;
    flex-direction: row;
    gap: var(--cds-spacing-50);
    color: var(--cds-color-neutral-primary-weak);
  `,
};

export const NavigationMetadata = (props: Props): React.ReactElement | null => {
  const { metadata, itemType } = props;

  if (!itemType) {
    return null;
  }

  const itemTypeName = toHumanReadableTypeName(itemType);
  let itemIcon;

  switch (itemType) {
    case 'supplement':
      itemIcon = <ReadingIcon />;
      break;
    case 'lecture':
      itemIcon = <VideoIcon />;
      break;
    // case 'exam':
    //   itemIcon = <QuizIcon />;
    //   break;
    // case 'staffGraded':
    //   itemIcon = <AssignmentIcon />;
    //   break;
    default:
      itemIcon = null;
  }

  return (
    <div css={styles.metadata}>
      {itemIcon}
      <CardMetadata metadata={[itemTypeName, ...metadata]} />
    </div>
  );
};

export default NavigationMetadata;
