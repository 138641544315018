import _ from 'lodash';

import type { Namespaces } from './__generated__/types';
import { Defaults } from './__generated__/types';
import type { FeatureIdentification, GetConfiguredFeaturesData, Scope } from './types';

export const createKey = (first: string, second: string) => `${first}~${second}`;

export function assertName(namespace: string, name: string) {
  const isValid = namespace in Defaults && name in Defaults[namespace as keyof Namespaces];
  if (!isValid) {
    throw new RangeError(
      `Unknown product feature specification "${String(namespace)}.${String(
        name
      )}". If this is a recently added feature, be sure run the code generator via "yarn product-features:types"`
    );
  }
}

export function convertToNamespaces<S extends Scope<{}>>(data: GetConfiguredFeaturesData): S {
  const result: S = _.cloneDeep(Defaults) as unknown as S;

  // Mutable view to merge overrides in without fiddling with TypeScript.
  const result0 = result as Record<string, Record<string, unknown>>;

  for (const {
    featureIdentification: { name, namespace },
    value,
  } of data.featureConfigurations) {
    const values = result0[namespace];
    if (values != null && values[name] != null) {
      values[name] = value;
    }
  }

  return result;
}

export function scopeToFeatureIdentifications<S extends Scope<{}>>(scope: S): FeatureIdentification[] {
  const featureIdentifications: FeatureIdentification[] = _.flatMap(
    scope as Record<string, Record<string, unknown>>,
    (names, namespace) => _.map(names, (unknown, name) => ({ namespace, name }))
  );
  return featureIdentifications;
}
