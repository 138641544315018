import type React from 'react';

import clsx from 'clsx';

import type { IconProps } from '@coursera/cds-common';
import { IconContext } from '@coursera/cds-common';

import Typography from '@core/Typography2';

import { classes, getHeaderSupportTextCss } from './headerSupportTextCss';

export type Props = {
  id?: string;
  'aria-label'?: string;
  'aria-hidden'?: boolean;
  icon?: React.ReactElement<IconProps>;
  children: React.ReactNode;
};

const styles = getHeaderSupportTextCss();

const HeaderSupportText = (props: Props) => {
  const { children, icon, ...rest } = props;

  return (
    <Typography
      color="supportText"
      component="span"
      css={styles}
      data-testid="accordion-header-support-text"
      variant="bodySecondary"
      {...rest}
    >
      {icon && (
        <IconContext.Provider
          value={{
            'aria-hidden': true,
            size: 'small',
            className: clsx(icon.props.className, classes.icon),
          }}
        >
          {icon}
        </IconContext.Provider>
      )}
      {children}
    </Typography>
  );
};

export default HeaderSupportText;
