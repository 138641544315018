import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('form-card', [
  'label',
  'success',
  'error',
  'disabled',
  'checked',
  'pressed',
  'input',
  'focusVisible',
  'hasIcon',
  'labelText',
  'labelSuffix',
  'supportText',
  'nonInteractiveSpace',
]);

const getFormCardCss = css`
  &.${classes.label} {
    position: relative;
    display: grid;
    grid-template-areas:
      'input label'
      '. supportText'
      '. nonInteractiveSpace';
    grid-template-columns: calc(var(--cds-spacing-300) + var(--cds-spacing-150)) auto;
    border: 1px solid var(--cds-color-neutral-stroke-primary);
    border-radius: var(--cds-border-radius-50);
    background-color: var(--cds-color-neutral-background-primary);
    padding: var(--cds-spacing-200);
    cursor: pointer;

    &.${classes.focusVisible}::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: var(--cds-border-radius-50);
      box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
        0 0 0 2px var(--cds-color-interactive-background-primary-hover-weak);
    }

    &.${classes.hasIcon} {
      grid-template-areas:
        'input icon label'
        '. . supportText'
        '. nonInteractiveSpace nonInteractiveSpace';
      grid-template-columns:
        calc(var(--cds-spacing-300) + var(--cds-spacing-150)) calc(
          var(--cds-spacing-300) + var(--cds-spacing-150)
        )
        auto;
    }

    .${classes.input} {
      grid-area: input;
      align-self: start;
      padding: 0;
      padding-right: var(--cds-spacing-150);
      color: var(--cds-color-neutral-stroke-primary);
      fill: var(--cds-color-neutral-primary-invert);

      & input + svg {
        fill: inherit;
      }
    }

    .${classes.labelText} {
      display: flex;
      grid-area: label;
      word-break: break-word;

      .${classes.labelSuffix} {
        margin-left: auto;
        padding-left: var(--cds-spacing-100);
        white-space: nowrap;
      }
    }

    .${classes.supportText} {
      grid-area: supportText;
      color: var(--cds-color-neutral-primary-weak);
    }

    .${classes.nonInteractiveSpace} {
      grid-area: nonInteractiveSpace;
      margin-top: var(--cds-spacing-200);
    }

    &.${classes.checked} {
      border-color: var(--cds-color-interactive-primary);

      .${classes.input}, .${classes.labelText} {
        color: var(--cds-color-interactive-primary);
        fill: transparent;
      }
    }

    &:hover,
    &.${classes.focusVisible} {
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
      border-color: var(--cds-color-interactive-primary);

      .${classes.input}, .${classes.labelText} {
        color: var(--cds-color-interactive-primary-hover);
      }
    }

    &.${classes.success}:not(.${classes.disabled}) {
      background-color: var(--cds-color-feedback-background-success-weak);
      border-color: var(--cds-color-feedback-success);

      .${classes.input}, .${classes.labelText} {
        color: var(--cds-color-feedback-success);
      }

      &:hover,
      &.${classes.focusVisible} {
        border-color: var(--cds-color-feedback-success-hover);

        .${classes.input}, .${classes.labelText} {
          color: var(--cds-color-feedback-success-hover);
        }
      }
    }

    &.${classes.error}:not(.${classes.disabled}) {
      background-color: var(--cds-color-feedback-background-error-weak);
      border-color: var(--cds-color-feedback-error);

      .${classes.input}, .${classes.labelText} {
        color: var(--cds-color-feedback-error);
      }

      &:hover,
      &.${classes.focusVisible} {
        border-color: var(--cds-color-feedback-error-hover);

        .${classes.input}, .${classes.labelText} {
          color: var(--cds-color-feedback-error-hover);
        }
      }
    }

    &.${classes.pressed} {
      background-color: var(
        --cds-color-interactive-background-primary-pressed-weak
      );
      border-color: var(--cds-color-interactive-primary-pressed);

      .${classes.input}, .${classes.labelText} {
        color: var(--cds-color-interactive-primary-pressed);
      }
    }

    &.${classes.disabled} {
      cursor: default;
      border-color: var(--cds-color-neutral-disabled-strong);
      background-color: var(--cds-color-neutral-background-primary-weak);

      .${classes.input}, .${classes.labelText}, .${classes.supportText} {
        color: var(--cds-color-neutral-disabled-strong);
        fill: var(--cds-color-neutral-disabled);
      }

      &.${classes.checked} {
        .${classes.input} {
          fill: var(--cds-color-neutral-disabled-strong);
        }
      }
    }
  }
`;

export default getFormCardCss;
