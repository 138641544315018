import React from 'react';

import { useCheckboxGroupContext } from '@core/CheckboxGroup/CheckboxGroupContext';
import type { BaseCheckboxProps } from '@core/forms';
import { BaseCheckbox } from '@core/forms';

export type CheckboxProps = Omit<BaseCheckboxProps, 'showOptionalMark'>;

/**
 * Checkboxes allow the user to select one or more items from a set
 *
 * See [Props](__storybookUrl__/components-inputs-checkbox--default#props)
 */
const Checkbox = React.forwardRef(function Checkbox(
  props: CheckboxProps,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement<CheckboxProps> {
  const { value, onChange, ...rest } = props;

  const context = useCheckboxGroupContext();
  let checked = props.checked;

  if (value && context?.value) {
    checked = context.value.includes(value);
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    context?.onChange?.(event, checked);
    onChange?.(event, checked);
  };

  return (
    <BaseCheckbox
      {...rest}
      ref={ref}
      checked={checked}
      value={value}
      onChange={handleChange}
    />
  );
});

export default Checkbox;
