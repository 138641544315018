import { extend, isArray, map, omit } from 'lodash';
import Q from 'q';

import Multitracker from 'js/app/multitrackerSingleton';

import AdsTracker from 'bundles/event-tracking/tracker';
import type { PaymentParams } from 'bundles/event-tracking/types';

function Eventing(...args: $TSFixMe[]) {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  AdsTracker.apply(this, args);
}

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
Eventing.prototype = new AdsTracker();

Eventing.prototype.send = function (params: $TSFixMe) {
  const deferred = Q.defer();
  Multitracker.pushV2(['adstracker.' + params.key, omit(params, 'key'), deferred.resolve]);
  return deferred.promise;
};

Eventing.prototype.prepareParams = function (key: $TSFixMe, additionalData: $TSFixMe) {
  return extend({ key }, additionalData);
};

Eventing.prototype.trackSignup = function () {
  return this.track('signup');
};

Eventing.prototype.trackEnroll = function () {
  return this.track('enroll');
};

Eventing.prototype.trackAddToCart = function ({ productId }: { productId: string }) {
  return this.track('add_to_cart', { productId });
};

Eventing.prototype._trackPaymentEvent = function (
  eventName: string,
  userId: number,
  paymentsInfo: PaymentParams,
  orderId: number
) {
  const paymentsInfoAsArray = isArray(paymentsInfo) ? paymentsInfo : [paymentsInfo];
  const promises = map(paymentsInfoAsArray, (paymentInfo) => {
    const { id, value, name, specializationId, currency, isFirstTimePayer, isFinaidCart, subscriptionCycle } =
      paymentInfo;
    const specialization = {
      SpecializationId:
        specializationId ||
        ((eventName === 'payment_specialization' ||
          eventName === 'payment_specialization_subscription' ||
          eventName === 'payment_specialization_prepaid') &&
          id) ||
        'Non-Spec',
    };
    const course = eventName === 'payment_non_specialization' ? { CourseId: id } : {};
    const subscription = subscriptionCycle ? { SubscriptionCycle: subscriptionCycle } : {};
    return this.track(eventName, {
      userId,
      cartId: orderId,
      Value: parseFloat(value || 0),
      Name: name,
      Currency: currency,
      FirstTimePayer: isFirstTimePayer,
      IsFinaidCart: isFinaidCart,
      ...specialization,
      ...course,
      ...subscription,
    });
  });
  return Q.allSettled(promises);
};

Eventing.prototype.trackS12NBulkPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_specialization_bulk_pay', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackS12NSubscriptionPayment = function (
  userId: number,
  paymentsInfo: PaymentParams,
  orderId: number
) {
  return this._trackPaymentEvent('payment_specialization_subscription', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackS12NVCPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_specialization', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackS12NPrepaidPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_specialization_prepaid', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackVCPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_non_specialization', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackGuidedProjectPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_guided_project', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackProjectPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_project', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackCourseraLiteSubscriptionPayment = function (
  userId: number,
  paymentsInfo: PaymentParams,
  orderId: number
) {
  return this._trackPaymentEvent('payment_coursera_lite_subscription', userId, paymentsInfo, orderId);
};
Eventing.prototype.trackCourseraPlusSubscriptionPayment = function (
  userId: number,
  paymentsInfo: PaymentParams,
  orderId: number
) {
  return this._trackPaymentEvent('payment_coursera_plus_subscription', userId, paymentsInfo, orderId);
};

Eventing.prototype.trackCourseraPlusPrepaidPayment = function (
  userId: number,
  paymentsInfo: PaymentParams,
  orderId: number
) {
  return this._trackPaymentEvent('payment_coursera_plus_prepaid', userId, paymentsInfo, orderId);
};

Eventing.prototype.trackMasterTrackPayment = function (userId: number, paymentsInfo: PaymentParams, orderId: number) {
  return this._trackPaymentEvent('payment_master_track', userId, paymentsInfo, orderId);
};

Eventing.prototype.trackEmailMeInfo = function (
  slug: string,
  productId: string,
  productName: string,
  productCategory: string
) {
  return this.track('email_me_info', slug, productId, productName, productCategory);
};

Eventing.prototype.trackEnrollButtonClick = function ({
  userId,
  productId,
  productCategory,
  productName,
  productSlug,
}: {
  userId?: number;
  productId?: string;
  productCategory?: string;
  productName?: string;
  productSlug?: string;
}) {
  return this.track('enroll_button_click', userId, productId, productCategory, productName, productSlug);
};

Eventing.prototype.trackS12nEnrollModalButtonClick = function ({
  userId,
  productId,
}: {
  userId?: number;
  productId?: string;
}) {
  return this.track('s12n_enroll_modal_button_click', userId, productId);
};

export default Eventing;
