import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import { EnterpriseProgramsV1NaptimeResource } from './__generated__/EnterpriseProgramsV1';

class EnterpriseProgramsV1 extends EnterpriseProgramsV1NaptimeResource {
  static RESOURCE_NAME = 'enterprisePrograms.v1' as const;

  @requireFields('metadata')
  get name() {
    return this.metadata.name;
  }

  @requireFields('metadata')
  get programName() {
    return this.metadata.name;
  }

  @requireFields('metadata')
  get slug() {
    return this.metadata.slug;
  }

  @requireFields('metadata')
  get curriculumId() {
    return this.metadata.curriculumId;
  }

  @requireFields('metadata')
  get tagline() {
    return this.metadata.tagline;
  }

  @requireFields('metadata')
  get isArchived() {
    return !!this.metadata.endedAt;
  }

  @requireFields('metadata')
  get launchedAt() {
    return this.metadata.launchedAt ?? undefined;
  }

  @requireFields('metadata')
  get skillSetAvailabilityType() {
    return this.metadata.skillSetAvailabilityType ?? undefined;
  }

  @requireFields('metadata')
  get rectangularLogo() {
    return this.metadata.rectangularLogo;
  }

  @requireFields('metadata')
  get squareLogo() {
    return this.metadata.squareLogo;
  }
}

export default EnterpriseProgramsV1;
