import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('SearchLoader', ['searchLoader']);

const getSearchLoaderCss = css`
  .${classes.searchLoader} {
    fill: none;
    position: relative;
    top: 2px;
    width: var(--search-action-button-size);
    height: 24px;
    transition: opacity 0.2s ease-in-out;
    stroke-width: 0.75rem;
    stroke: var(--cds-color-interactive-primary);
    stroke-dasharray: 300;
    animation: search-loading-animation 2.5s linear infinite;

    @keyframes search-loading-animation {
      0% {
        stroke-dashoffset: 600;
        transform: rotate(0deg);
      }

      100% {
        stroke-dashoffset: 60;
        transform: rotate(720deg);
      }
    }
  }
`;

export default getSearchLoaderCss;
