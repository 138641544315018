import { useQuery } from '@apollo/client';

import { getAllDomains } from 'bundles/premium-hub/queries';
import type { getAllDomains as getAllDomainsType } from 'bundles/premium-hub/queries/__generated__/getAllDomains';
import { filterNullOrUndefined } from 'bundles/premium-hub/utils/index';

export const useDomains = () => {
  const {
    data: allDomainsData,
    loading,
    error,
  } = useQuery<getAllDomainsType, {}>(getAllDomains, {
    fetchPolicy: 'cache-first' as const,
    errorPolicy: 'all',
    context: { clientName: 'gatewayGql' },
    ssr: false,
  });
  const domains = allDomainsData?.DomainsV1Resource?.domains?.elements.filter(filterNullOrUndefined) ?? [];
  return { domains, loading, error };
};
