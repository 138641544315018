import PendoLib, { LEARNER_API_KEY } from 'js/lib/pendo';
import user from 'js/lib/user';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import { useProductFeatures } from 'bundles/page-header/features';
import { IdTypes } from 'bundles/product-features';

const ENTERPRISE_COACH_INTRO_PENDO_GUIDE_ID = '4rCySKwYCcR5LfP5PQz6pDXDx5w';

const enabledApps = new Set(['ondemand', 'program-home']);

const hasShownCoachGuide = () => {
  return localStorageEx.getItem<boolean>(
    'isPendoCoachGuideLaunched',
    (isLaunched: string) => isLaunched === '1',
    false
  );
};

const initalizePendo = () => {
  const { external_id: externalId, is_staff: isStaff, is_superuser: isSuperuser } = user.get();

  try {
    PendoLib.init(
      {
        visitor: {
          id: externalId,
          isStaff,
          isSuperuser,
          isLoggedIn: true,
        },
        account: {
          id: 'enterprise',
        },
      },
      LEARNER_API_KEY,
      (pendo: pendo.Pendo) => {
        // This callback gets called every time the Pendo agent loads all guides.
        // So, need to exit here if we've already shown the guide.
        const isCoachGuideAlreadyShown = hasShownCoachGuide();
        if (isCoachGuideAlreadyShown) {
          return;
        }
        pendo.showGuideById(ENTERPRISE_COACH_INTRO_PENDO_GUIDE_ID);
        localStorageEx.setItem<boolean>('isPendoCoachGuideLaunched', true, () => '1');
      }
    );
  } catch (e) {
    // do nothing
    console.error(`Error showing Pendo guide`, e);
  }
};

const launchPendoCoachGuide = () => {
  const isCoachGuideAlreadyShown = hasShownCoachGuide();
  if (isCoachGuideAlreadyShown) {
    return;
  }

  initalizePendo();
};

export const useCoachIntroPendo = (appName: string) => {
  // define internal state for if we should show the pendo guide
  const { id: userId } = user.get();

  const isCoachTargetedApp = enabledApps.has(appName);
  const isCoachGuideAlreadyShown = hasShownCoachGuide();
  const skip = isCoachGuideAlreadyShown || !isCoachTargetedApp; // Skip the API call if we know we are not showing Coach or the guide has already been shown. API call is needed to determine whether learner is an Enterprise Coach learner.

  const { features, loading } = useProductFeatures({
    idType: IdTypes.User,
    id: userId?.toString(),
    skip,
    ssr: false,
  });

  const featuresLoaded = !loading && !skip;
  const shouldShowCoachGuide = featuresLoaded && Boolean(features.get('enterprise', 'enableAiCoach'));

  return { shouldShowCoachGuide, launchPendoCoachGuide };
};

export default useCoachIntroPendo;
