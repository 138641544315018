/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { compose, getContext } from 'recompose';

import Retracked from 'js/app/retracked';

import { Button, Typography2 } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import type { EventData } from 'bundles/authentication/shared/types/sharedTypes';
import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/page';

const styles = {
  dropdownWrap: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  longLabel: css`
    @media screen and (max-width: 1230px) and (min-width: 925px) {
      display: none;
    }
  `,
  shortLabel: css`
    display: none;

    @media screen and (max-width: 1230px) and (min-width: 925px) {
      display: block;
    }
  `,
  button: css`
    min-width: 144px;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @media screen and (max-width: 1230px) and (min-width: 925px) {
      min-width: 103px;
    }
  `,
  chevronIcon: css`
    width: 16px;
    height: 16px;
  `,
  degreeLinksList: css`
    background-color: var(--cds-color-neutral-background-primary);
    border-radius: 4px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    display: none;
    margin-top: -5px;
    padding: 11px 13px;
    position: absolute;
    width: 350px;
    z-index: 100;
    max-height: calc(80vh - 80px);
    overflow-y: auto;
  `,
  degreeLinksUl: css`
    list-style-type: none;
    margin: 0;
    padding: 0;
  `,
  degreeLink: css`
    display: block;
    max-width: unset;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  `,
  degreeLinkText: css`
    display: block;
    text-align: left;
    width: 100%;
  `,
  sectionHeader: css`
    padding: var(--cds-spacing-150) var(--cds-spacing-100);
  `,
};

type Context = {
  _eventData: EventData;
};

export const getPremiumHubLinks = () => [
  {
    title: _t('Online Degree'),
    subtitle: _t('Explore Bachelor’s & Master’s degrees'),
    shortname: 'degrees',
    url: '/degrees',
    entryType: 'menuitem',
  },
  {
    title: _t('MasterTrack™'),
    // eslint-disable-next-line no-restricted-syntax
    subtitle: _t('Earn credit towards a Master’s degree'),
    shortname: 'mastertracks',
    url: '/mastertrack',
    entryType: 'menuitem',
  },
  {
    title: _t('University Certificates'),
    subtitle: _t('Advance your career with graduate-level learning'),
    shortname: 'certificates',
    url: '/certificates/learn',
    entryType: 'menuitem',
  },
];

const DegreeDropdown = ({ _eventData }: Context) => {
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [focusedLinkId, setFocusedLinkId] = useState<number>(0);

  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownContainerRef = useRef<HTMLDivElement | null>(null);
  const degreeLinksContainer = useRef<HTMLDivElement | null>(null);
  const degreeLinkControls = useRef<NodeListOf<HTMLAnchorElement> | null>(null);
  const degreeLinks = getPremiumHubLinks();

  useEffect(() => {
    dropdownButtonRef.current = document.getElementById('onlineDegreesDropdownBtn') as HTMLButtonElement;
    dropdownContainerRef.current = document.getElementById('premiumProductHeaderDropdown') as HTMLDivElement;
    degreeLinkControls.current = document.querySelectorAll('.premium-dropdown-link[role="menuitem"]');
    degreeLinksContainer.current = document.getElementById('degreeLinksContainer') as HTMLDivElement;
    // degreeLinks as a dependency so that after new links are added they can be navigated with keyboard
  }, [degreeLinks]);

  const preventDefaultBehavior = (ev: React.KeyboardEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const focusOnLink = (index: number) => {
    setFocusedLinkId(index);
    if (degreeLinkControls.current) {
      degreeLinkControls.current[index].focus();

      degreeLinkControls.current.forEach((degreeLink) => {
        // Not just reassigning a parameter since this changes the
        // actual tabIndex for these links. Ignoring this eslint error!
        // eslint-disable-next-line no-param-reassign
        degreeLink.tabIndex = -1;
      });

      degreeLinkControls.current[index].tabIndex = 0;
    }
  };

  const closeDropdown = (isMouseLeave = false) => {
    if (degreeLinksContainer.current) {
      setDropdownToggle(false);
      // Need to set the style instead of using state
      // Doing so lets the dropdown be immediately visible & focusable on the next line
      degreeLinksContainer.current.style.display = 'none';
      if (!isMouseLeave) dropdownButtonRef.current?.focus();

      Retracked.trackComponent(_eventData, {}, 'degree_dropdown_button', 'leave');
    }
  };

  const onBackgroundMouseDown = (ev: MouseEvent) => {
    if (!dropdownContainerRef.current?.contains(ev.target as Node | null)) {
      closeDropdown();
    }
  };

  const openDropdown = (keyboard: boolean) => {
    if (!degreeLinksContainer.current) return;

    const degreeLinksForTracking = degreeLinks.map((degreeLink) => ({
      title: degreeLink.title,
      shortname: degreeLink.shortname,
      entryType: degreeLink.entryType,
    }));

    Retracked.trackComponent(_eventData, { degreeLinks: degreeLinksForTracking }, 'degree_dropdown_button', 'hover');

    // Need to set the style instead of using state
    // Doing so lets the dropdown be immediately visible & focusable on the next line
    degreeLinksContainer.current.style.display = 'block';
    setDropdownToggle(true);
    if (keyboard) {
      focusOnLink(0);
      window.addEventListener('mousedown', onBackgroundMouseDown, {
        once: true,
        capture: true,
      });
    }
  };

  const selectPremiumLink = (direction: 'next' | 'prev') => {
    if (degreeLinkControls.current) {
      const firstId = 0;
      const lastId = degreeLinkControls.current.length - 1;
      if (direction === 'next') {
        const nextId = focusedLinkId + 1;
        if (nextId > lastId) {
          focusOnLink(firstId);
        } else {
          focusOnLink(nextId);
        }
      } else if (direction === 'prev') {
        const prevId = focusedLinkId - 1;
        if (prevId < firstId) {
          focusOnLink(lastId);
        } else {
          focusOnLink(prevId);
        }
      }
    }
  };

  const dropdownButtonKeyboardHandler = (ev: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (ev.key) {
      case ' ':
      case 'Enter':
      case 'ArrowDown':
      case 'Down':
        openDropdown(true);
        preventDefaultBehavior(ev);
        break;
      case 'Esc':
      case 'Escape':
        closeDropdown();
        preventDefaultBehavior(ev);
        break;
      default:
        break;
    }
  };

  const menuItemKeyboardHandler = (ev: React.KeyboardEvent<HTMLAnchorElement>) => {
    switch (ev.key) {
      case 'ArrowUp':
      case 'Up':
        selectPremiumLink('prev');
        preventDefaultBehavior(ev);
        break;
      case 'ArrowDown':
      case 'Down':
        selectPremiumLink('next');
        preventDefaultBehavior(ev);
        break;
      case 'Esc':
      case 'Escape':
        closeDropdown();
        preventDefaultBehavior(ev);
        break;
      default:
        break;
    }
  };

  return (
    <div
      css={styles.dropdownWrap}
      id="premiumProductHeaderDropdown"
      className="rc-DegreeDropdown"
      ref={dropdownContainerRef}
      onMouseEnter={() => openDropdown(false)}
      onMouseLeave={() => closeDropdown(true)}
    >
      <div className="online-degree-dropdown">
        <TrackedCdsButton
          css={styles.button}
          variant="ghost"
          size="medium" // Need to define size here or it causes an error in local dev
          trackingName="toggle_online_degrees_dropdown"
          id="onlineDegreesDropdownBtn"
          data-testid="onlineDegreesDropdownBtn"
          aria-label={_t('Online Degrees')}
          aria-haspopup="true"
          aria-controls="onlineDegreesDropdownMenu"
          aria-expanded={dropdownToggle}
          icon={
            dropdownToggle ? (
              <ChevronUpIcon size="small" color="default" css={styles.chevronIcon} />
            ) : (
              <ChevronDownIcon size="small" color="default" css={styles.chevronIcon} />
            )
          }
          onKeyDown={dropdownButtonKeyboardHandler}
        >
          <Typography2 css={styles.longLabel} component="span" variant="bodyPrimary" className="long-label">
            {_t('Online Degrees')}
          </Typography2>
          <Typography2 css={styles.shortLabel} component="span" variant="bodyPrimary" className="short-label">
            {_t('Degrees')}
          </Typography2>
        </TrackedCdsButton>
      </div>
      <div id="degreeLinksContainer" className="degree-links" css={styles.degreeLinksList} ref={degreeLinksContainer}>
        <ul
          css={styles.degreeLinksUl}
          id="onlineDegreesDropdownMenu"
          role="menu"
          aria-labelledby="onlineDegreesDropdownBtn"
          aria-hidden={!dropdownToggle}
        >
          {degreeLinks.map((degreeLink) => {
            const identifier = `premium_dropdown_${degreeLink.shortname}`;

            return (
              <li key={identifier} role="none">
                <Button
                  css={styles.degreeLink}
                  id={identifier}
                  data-testid={identifier}
                  className="premium-dropdown-link"
                  component={TrackedA}
                  variant="ghost"
                  href={degreeLink.url}
                  trackingName={identifier}
                  tabIndex={-1}
                  role="menuitem"
                  onKeyDown={menuItemKeyboardHandler}
                >
                  <Typography2
                    css={styles.degreeLinkText}
                    component="span"
                    variant="subtitleMedium"
                    className="degree-link-title"
                  >
                    {degreeLink.title}
                  </Typography2>
                  <Typography2
                    css={styles.degreeLinkText}
                    component="span"
                    variant="bodySecondary"
                    className="degree-link-subtitle"
                  >
                    {degreeLink.subtitle}
                  </Typography2>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default compose<Context, {}>(getContext({ _eventData: PropTypes.object }))(DegreeDropdown);
