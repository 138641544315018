import * as React from 'react';

import { StyleSheet, css } from '@coursera/coursera-ui';

export type Props = React.HTMLProps<HTMLAnchorElement>;

const styles = StyleSheet.create({
  link: {
    background: 'transparent',
    border: 'none',
    color: '#2A73CC',
    cursor: 'pointer',
    display: 'inline',
    fontSize: 'inherit',
    padding: '0',
    transition: 'color 250ms ease-out',
    textDecoration: 'underline',
  },
});

const LinkButton = ({ className, ...props }: Props) => (
  // @ts-expect-error FIXME result of fixing typing on coursera-ui aphrodite exports in PR#45351
  <button {...props} {...css(className, styles.link)} type="button" />
);

export default LinkButton;
