import { useEffect, useState } from 'react';

import { useLocation } from 'js/lib/useRouter';

import { useCoachEpicEnabledState } from 'bundles/ai-course-coach/utils/productFeatureFlags';
import { getActiveConversationId, getChatTargetingRule } from 'bundles/common/utils/chatLoaderUtils';
import type { ChatTargetingRule } from 'bundles/common/utils/chatLoaderUtils';
import { getShowGlobalHelpButton } from 'bundles/common/utils/globalHelpButtonUtils';

type ChatWidgetName = 'BoostChat' | 'GlobalHelp' | 'CourseraCoach' | undefined;

const useShouldShowBoostChat = () => {
  const [conversationId, setConversationId] = useState<string | undefined>(undefined);
  const [targetingRule, setTargetingRule] = useState<ChatTargetingRule | undefined>(undefined);
  useEffect(() => {
    // Effect code is not run in SSR, which is what we want.  Setting the following using state (rather than a local variables) causes a 2nd render pass in CSR and avoids SSR<>CSR mismatch.
    setConversationId(getActiveConversationId());
    setTargetingRule(getChatTargetingRule(location.href));
  }, []);

  const showBoostChatButton = !!conversationId || !!targetingRule; // If there is an active chat conversation we always show the chat button on all pages because we want the learner to be able to easily resume that conversation.
  return showBoostChatButton;
};

// react context for use coach chat or not

// end context
const useShouldShowGlobalHelp = (pathname: string) => {
  const [showGlobalHelpButton, setShowGlobalHelpButton] = useState<boolean>(false);

  useEffect(() => {
    const shouldShowGlobalHelpButton = getShowGlobalHelpButton(pathname).showGlobalHelpButton;
    setShowGlobalHelpButton(shouldShowGlobalHelpButton);
  }, [pathname]);

  return showGlobalHelpButton;
};

export const useChatWidgetSelector = (): ChatWidgetName => {
  const { pathname } = useLocation();

  const { showEmbeddedCoach } = useCoachEpicEnabledState();

  const shouldShowBoostChat = useShouldShowBoostChat();
  const shouldShowGlobalHelp = useShouldShowGlobalHelp(pathname);

  // These widgets are in priority order.
  if (showEmbeddedCoach) {
    return 'CourseraCoach';
  }

  if (shouldShowBoostChat) {
    return 'BoostChat';
  }

  if (shouldShowGlobalHelp) {
    return 'GlobalHelp';
  }

  return undefined;
};

export default useChatWidgetSelector;
