import type React from 'react';
import { forwardRef } from 'react';

import type { PopoverProps } from '@material-ui/core';
import { Popover as MuiPopover } from '@material-ui/core';

import clsx from 'clsx';

import { useLocale } from '@coursera/cds-common';

import Container from './Container';
import type { Props as ContainerProps } from './Container';
import dropDownCss, { classes } from './styles/dropdownCss';
import type { StyleProps } from './styles/dropdownCss';
import type { BasePopoverProps } from './types';

type DropdownOrigin = {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
};

export type Props = {
  anchorElement?: PopoverProps['anchorEl'];

  /**
   * Reference to a focusable element that should receive initial focus when the
   * component opens. By default, the first focusable element inside the component
   * will receive focus.
   */
  initialFocusRef?: ContainerProps['initialFocusRef'];

  /**
   * Reference to a focusable element that should receive focus when the component
   * closes. By default, focus will return to the element that had focus when the
   * component was opened.
   */
  returnFocusRef?: ContainerProps['returnFocusRef'];

  /**
   * Determines whether first interactive element has to be focused when dropdown opens.
   * If set to `false` anything will be focused automatically.
   * @default true
   */
  autoFocus?: ContainerProps['autoFocus'];

  /**
   * Determines styling applied for the dropdown.
   * @default default
   */
  validationStatus?: 'default' | 'error' | 'success';

  /**
   * This is the point on the anchor where the dropdown's anchorElement will attach to.
   *
   * __Note__: the default horizontal point is automatically flipped on RTL languages.
   *
   * @default { vertical: 'bottom', horizontal: 'left' }
   */
  anchorOrigin?: DropdownOrigin;

  /**
   * This is the point on the popover which will attach to the anchor's origin.
   *
   * __Note__: the default horizontal point is automatically flipped on RTL languages.
   *
   * @default { vertical: 'top', horizontal: 'left' }
   */
  transformOrigin?: DropdownOrigin;

  /**
   * Disable the scroll lock behavior.
   * @default false
   */
  disableScrollLock?: boolean;
} & BasePopoverProps &
  StyleProps;

/**
 * Dropdown component
 */
const Dropdown = forwardRef<HTMLElement, Props>(function _Dropdown(props, ref) {
  const { direction } = useLocale();

  const {
    anchorElement,
    validationStatus = 'default',
    className,
    initialFocusRef,
    returnFocusRef,
    autoFocus,
    maxHeight,
    maxWidth,
    marginTop,
    onClose,
    anchorOrigin,
    transformOrigin,
    ...rest
  } = props;

  const anchorHorizontalPoint = direction === 'ltr' ? 'left' : 'right';

  const handleClose = (e: React.KeyboardEvent<HTMLElement>) => {
    // Stop propagation for Escape to prevent Safari resizing window.
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
    }
    onClose?.(e, 'escapeKeyDown');
  };

  return (
    <MuiPopover
      ref={ref}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: anchorOrigin?.vertical ?? 'bottom',
        horizontal: anchorOrigin?.horizontal ?? anchorHorizontalPoint,
      }}
      css={dropDownCss({ marginTop, maxHeight, maxWidth })}
      transformOrigin={{
        vertical: transformOrigin?.vertical ?? 'top',
        horizontal: transformOrigin?.horizontal ?? anchorHorizontalPoint,
      }}
      onClose={handleClose}
      {...rest}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      className={clsx(className, { [classes[validationStatus]]: true })}
      classes={{
        ...props.classes,
        paper: clsx(classes.paper, props.classes?.paper),
      }}
      dir={direction}
      elevation={0}
      transitionDuration={0}
    >
      <Container
        autoFocus={autoFocus}
        initialFocusRef={initialFocusRef}
        layout="dropdown"
        returnFocusRef={returnFocusRef}
        onClose={props.onClose}
      >
        {props.children}
      </Container>
    </MuiPopover>
  );
});

export default Dropdown;
