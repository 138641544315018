import * as Sentry from '@sentry/react';
import URI from 'jsuri';

import config from 'js/app/config';
import redirect from 'js/lib/coursera.redirect';

import type { AUTH_MODES_WITH_SINGLE_PAGE } from 'bundles/authentication/constants';

const navigateToFullPageAuth = (
  authMode: (typeof AUTH_MODES_WITH_SINGLE_PAGE)[keyof typeof AUTH_MODES_WITH_SINGLE_PAGE],
  redirectTo: string
) => {
  const authPageUrlLocation = new URI().setPath(`/${authMode}`).addQueryParam('redirectTo', redirectTo).toString();
  redirect.setLocation(authPageUrlLocation);
};

function toValidRedirect(redirectTo?: unknown) {
  if (!!redirectTo && typeof redirectTo === 'string') {
    let redirectUrl;
    try {
      redirectUrl = new URL(redirectTo);
    } catch (e) {
      // Allow only relative paths starting with `&`, `?`, or `/`
      if (redirectTo.match(/^[&?/]/)) {
        return redirectTo;
      }
      Sentry.captureException(new Error('unexpected redirect URL - malformed redirect URL'), {
        extra: { error: e, redirectTo },
      });
      return config.url.base;
    }
    const redirectDomain = redirectUrl.host;
    const redirectProtocol = redirectUrl.protocol;

    // Prevent non-HTTP/HTTPS schemes like `javascript:`
    if (!['http:', 'https:'].includes(redirectProtocol)) {
      Sentry.captureException(new Error('unexpected redirect URL - invalid protocol'), { extra: { redirectTo } });
      return config.url.base;
    }

    const courseraDomain = new URL(config.url.base).host.replace(/(https:\/\/)?(www.)?/, '');
    if (redirectDomain?.endsWith(courseraDomain)) {
      return redirectTo;
    }
    Sentry.captureException(new Error('unexpected redirect URL - untrusted domain'), { extra: { redirectTo } });
  }
  return config.url.base;
}

export { navigateToFullPageAuth, toValidRedirect };
