/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Avatar, Typography2 } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import { useIsSimplifiedPageHeader } from 'bundles/page-header/contexts/PageHeaderContext';

import _t from 'i18n!nls/page';

const styles = {
  box: () => css`
    display: flex;
    align-items: center;
  `,
  firstName: () => css`
    max-width: 100px;
    margin-right: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  fullName: () => css`
    max-width: 120px;
    color: #333;
    margin-right: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  outerContainer: css`
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    gap: 4px;
  `,
  nameSectionContainer: css`
    display: flex;
    align-items: center;
  `,
};

type Props = {
  user: {
    full_name: string;
    photo_120: string;
  };
  showFirstName?: boolean;
  showFullName?: boolean;
  showCaret?: boolean;
  isCaretUp?: boolean;
};

const UserPortrait = ({ user, showFirstName, showCaret, showFullName, isCaretUp }: Props) => {
  const { full_name: fullName, photo_120: photoUrl } = user;
  const firstName = fullName && fullName.split(' ')[0];
  const isSimplifiedPageHeader = useIsSimplifiedPageHeader();

  let chevron;
  // Show caret only when first name or fullname is visible
  if (showFirstName || showFullName || isSimplifiedPageHeader) {
    if (showCaret && isCaretUp) {
      chevron = <ChevronUpIcon data-testid="svg-chevron-up" size="small" color="default" />;
    } else {
      chevron = <ChevronDownIcon data-testid="svg-chevron-down" size="small" color="default" />;
    }
  }

  return (
    <div className="pii-hide" css={styles.box}>
      <Avatar
        variant="user"
        data-testid="user-avatar"
        initial={firstName?.[0]}
        imgProps={{ src: photoUrl, alt: _t('Profile image for #{learnerName}', { learnerName: fullName }) }}
      />
      <div css={styles.outerContainer}>
        <div css={styles.nameSectionContainer}>
          {showFirstName && !isSimplifiedPageHeader && (
            <Typography2
              component="p"
              variant="bodySecondary"
              data-e2e="UserPortraitFirstName"
              css={styles.firstName}
              className="body c-ph-username"
            >
              {firstName}
            </Typography2>
          )}
          {showFullName && !isSimplifiedPageHeader && (
            <Typography2
              component="p"
              variant="bodySecondary"
              data-e2e="UserPortraitFullName"
              css={styles.fullName}
              className="body c-ph-username"
            >
              {fullName}
            </Typography2>
          )}
          {chevron}
        </div>
      </div>
    </div>
  );
};

export default UserPortrait;
