/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { forwardRef, memo, useCallback } from 'react';

import { SelectField, SelectOption } from '@coursera/cds-core';

type Option = { id: string; text: string };

type CDSComboBoxProps = {
  placeholder: string;
  prompt: string;
  options: Option[];
  onChange: (obj: Option) => void;
  isRequired?: boolean;
  validationError?: string;
  defaultValue?: string;
};

const CDSDropdownElement = forwardRef<HTMLInputElement, CDSComboBoxProps>(
  (
    { options, placeholder, prompt, onChange, validationError, isRequired = false, defaultValue },
    ref
  ): React.ReactElement => {
    const onChangeWrapper = useCallback(
      (e) => {
        const obj = options?.find((opt: Option) => opt?.id === e?.target?.value);
        if (obj != null) {
          onChange(obj);
        }
      },
      [onChange, options]
    );

    return (
      <SelectField
        data-testid="eoi-select-field"
        defaultValue={defaultValue}
        onChange={onChangeWrapper}
        fullWidth
        optional={!isRequired}
        label={prompt}
        placeholder={placeholder}
        validationStatus={validationError != null ? 'error' : undefined}
        validationLabel={validationError}
        inputProps={{ ref }}
      >
        {options?.map(({ id, text }: Option) => (
          <SelectOption key={id} value={id}>
            {text}
          </SelectOption>
        ))}
      </SelectField>
    );
  }
);

export default memo(CDSDropdownElement);
