import { useQuery } from '@apollo/client';

import type {
  GetSurveryIdByDegreeSlugQuery,
  GetSurveryIdByDegreeSlugQueryVariables,
} from 'bundles/university-program-qualification/queries/__generated__/getSurveryIdByDegreeSlug';
import GetSurveryIdByDegreeSlug from 'bundles/university-program-qualification/queries/getSurveryIdByDegreeSlug.graphql';

export const useGetSurveyIdByDegreeSlug = (slug: string) => {
  const { data } = useQuery<GetSurveryIdByDegreeSlugQuery, GetSurveryIdByDegreeSlugQueryVariables>(
    GetSurveryIdByDegreeSlug,
    {
      variables: {
        slug,
      },
      context: { clientName: 'gatewayGql' },
    }
  );
  return data?.Degree?.queryBySlug?.id;
};

export default useGetSurveyIdByDegreeSlug;
