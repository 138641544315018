import _ from 'underscore';

import { QuestionTypes, ResponseTypes } from 'bundles/survey-form/constants/SurveyFormConstants';
import type { QuestionType } from 'bundles/survey-form/constants/SurveyFormConstants';

const { SHORT_ANSWER, NUMERIC, DROPDOWN, CHECKBOX, SINGLE_CHECKBOX, PARAGRAPH_ANSWER, TYPEAHEAD, MULTIPLE_CHOICE } =
  QuestionTypes;
const {
  SHORT_ANSWER_RESPONSE,
  NUMERIC_RESPONSE,
  DROPDOWN_RESPONSE,
  CHECKBOX_RESPONSE,
  SINGLE_CHECKBOX_RESPONSE,
  PARAGRAPH_ANSWER_RESPONSE,
  TYPEAHEAD_RESPONSE,
  MULTIPLE_CHOICE_RESPONSE,
} = ResponseTypes;
const typeMapping = {
  [SHORT_ANSWER]: SHORT_ANSWER_RESPONSE,
  [DROPDOWN]: DROPDOWN_RESPONSE,
  [NUMERIC]: NUMERIC_RESPONSE,
  [CHECKBOX]: CHECKBOX_RESPONSE,
  [SINGLE_CHECKBOX]: SINGLE_CHECKBOX_RESPONSE,
  [PARAGRAPH_ANSWER]: PARAGRAPH_ANSWER_RESPONSE,
  [TYPEAHEAD]: TYPEAHEAD_RESPONSE,
  [MULTIPLE_CHOICE]: MULTIPLE_CHOICE_RESPONSE,
};

const SurveyFormUtils = {
  getQuestionType(question: $TSFixMe) {
    return _(question.content).keys()[0] as QuestionType;
  },

  getAnswerType(answer: $TSFixMe) {
    return _(answer).keys()[0];
  },

  getAnswerText(answer: $TSFixMe) {
    if (!answer) {
      return undefined;
    }
    const answerType = SurveyFormUtils.getAnswerType(answer);
    const content = answer[answerType];
    switch (answerType) {
      case NUMERIC_RESPONSE:
        return content.response;
      case SHORT_ANSWER_RESPONSE:
      case PARAGRAPH_ANSWER_RESPONSE:
        return content.text;
      default:
        return content;
    }
  },

  getResponseTypeFromQuestionType(questionType: QuestionType) {
    return typeMapping[questionType];
  },

  getValidationTypes(question: $TSFixMe) {
    const { validations } = question.content[SurveyFormUtils.getQuestionType(question)];
    return _(validations).map((validation) => {
      const validationType = _(validation).keys()[0];
      const validationDetails = validation[validationType];

      return {
        validationType,
        validationDetails,
      };
    });
  },

  getSectionData(section: $TSFixMe, type: $TSFixMe) {
    return section[type];
  },

  getQuestions(section: $TSFixMe, id: $TSFixMe) {
    const { questions } = section;
    return _(questions).find((question) => {
      return question.questionId === id;
    });
  },

  isResponseEmpty(response: $TSFixMe, responseType: $TSFixMe) {
    switch (responseType) {
      case SHORT_ANSWER_RESPONSE:
        return response[SHORT_ANSWER_RESPONSE].text === '';
      case NUMERIC_RESPONSE:
        return response[NUMERIC_RESPONSE].response === '';
      case DROPDOWN_RESPONSE:
        return !response[DROPDOWN_RESPONSE] || _(response[DROPDOWN_RESPONSE]).isEmpty();
      case PARAGRAPH_ANSWER_RESPONSE:
        return response[PARAGRAPH_ANSWER_RESPONSE].text === '';
      case TYPEAHEAD_RESPONSE:
        return !response[TYPEAHEAD_RESPONSE] || _(response[TYPEAHEAD_RESPONSE]).isEmpty();
      case CHECKBOX_RESPONSE:
        return !response[CHECKBOX_RESPONSE] || response[CHECKBOX_RESPONSE].ids.length === 0;
      case MULTIPLE_CHOICE_RESPONSE:
        return !response[MULTIPLE_CHOICE_RESPONSE];
      default:
        return false;
    }
  },

  processResponse(response: $TSFixMe) {
    const processedResponse = {};
    _(response).each((value, key) => {
      if (_(value).has(NUMERIC_RESPONSE)) {
        const processedValue = value;
        processedValue[NUMERIC_RESPONSE].response = parseFloat(value[NUMERIC_RESPONSE].response);
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        processedResponse[key] = processedValue;
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        processedResponse[key] = value;
      }
    });
    return processedResponse;
  },
};

export default SurveyFormUtils;

export const {
  getQuestionType,
  getAnswerType,
  getAnswerText,
  getResponseTypeFromQuestionType,
  getValidationTypes,
  getSectionData,
  getQuestions,
  isResponseEmpty,
  processResponse,
} = SurveyFormUtils;
