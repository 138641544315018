import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Hidden } from '@coursera/cds-core';

export type Program = {
  metadata?: {
    rectangularLogo?: string | null;
    squareLogo?: string | null;
  };
  name?: string | null;
};

type Props = {
  program?: Program;
  preferRectangle?: boolean;
  logoHeight?: number;
  logoWidth?: number;
};

const EnterpriseProgramLogo = ({ program, preferRectangle = true, logoHeight = 32, logoWidth = 32 }: Props) => {
  const hasRectangularLogo = !!program?.metadata?.rectangularLogo;
  const hasSquareLogo = !!program?.metadata?.squareLogo;
  const showProgramLogo = hasRectangularLogo || hasSquareLogo;
  const programLogo =
    preferRectangle && hasRectangularLogo ? program?.metadata?.rectangularLogo : program?.metadata?.squareLogo;

  if (!showProgramLogo) {
    return null;
  } else if (preferRectangle && hasRectangularLogo) {
    if (hasSquareLogo) {
      // It's preferred to show the rectangularLogo here but at small screen sizes, there isn't enough
      // room in the header so we show the squareLogo instead
      return (
        <>
          <Hidden mdDown>
            <Imgix src={programLogo as string} maxWidth={logoWidth} maxHeight={logoHeight} alt={program?.name ?? ''} />
          </Hidden>
          <Hidden lgUp>
            <Imgix
              src={program.metadata?.squareLogo as string}
              maxWidth={logoWidth}
              maxHeight={logoHeight}
              alt={program?.name ?? ''}
            />
          </Hidden>
        </>
      );
    } else {
      // If there isn't a squareLogo, just show the rectangularLogo (it might get cut off a bit at
      // smaller desktop screen sizes)
      return (
        <Imgix
          src={program.metadata?.rectangularLogo as string}
          maxWidth={logoWidth}
          maxHeight={logoHeight}
          alt={program?.name ?? ''}
          style={{ overflow: 'hidden' }}
        />
      );
    }
  } else {
    if (hasSquareLogo) {
      return (
        <Imgix
          src={program.metadata?.squareLogo ? program.metadata.squareLogo : ''}
          maxWidth={logoWidth}
          maxHeight={logoHeight}
          alt={program?.name ?? ''}
        />
      );
    }
    return (
      <Imgix
        src={program.metadata?.rectangularLogo ? program.metadata.rectangularLogo : ''}
        maxWidth={logoWidth}
        maxHeight={logoHeight}
        alt={program?.name ?? ''}
      />
    );
  }
};

export default EnterpriseProgramLogo;
