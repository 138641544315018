import * as React from 'react';

import type UserAgent from 'js/lib/useragent';

import type { LearnMoreSectionProps } from 'bundles/cms/types';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import InfoCard from 'bundles/premium-hub/components/shared/InfoCard';

import 'css!bundles/premium-hub/components/__styles__/shared/LearnMore';

type Props = LearnMoreSectionProps & {
  userAgent?: UserAgent;
};

const LearnMore = ({ learnMoreInfoHeading, learnMoreCards, userAgent }: Props) => {
  if (!learnMoreCards || !learnMoreCards.length) {
    return null;
  }

  return (
    <div className="rc-LearnMore">
      {learnMoreInfoHeading && (
        <TrackedDiv
          trackingName="learn_more_info_card_heading"
          withVisibilityTracking={true}
          atMostOnce={true}
          trackClicks={false}
        >
          <h2 className="learnMoreHeading">{learnMoreInfoHeading}</h2>
        </TrackedDiv>
      )}
      {learnMoreCards.map((content) => (
        <TrackedDiv
          key={content?.title}
          trackingName="learn_more_info_card"
          data={{ valueProposition: content?.title }}
          withVisibilityTracking={true}
          atMostOnce={true}
          trackClicks={false}
        >
          <InfoCard userAgent={userAgent} marketingContent={content} />
        </TrackedDiv>
      ))}
    </div>
  );
};

export default LearnMore;
