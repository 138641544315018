import React from 'react';

import { useListBoxSection } from '@react-aria/listbox';
import type { ListState } from '@react-stately/list';
import type { Node } from '@react-types/shared';

import { useLocalizedStringFormatter } from '@coursera/cds-common';

import i18nMessages from '@core/Autocomplete/i18n';
import Divider from '@core/Divider';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import Option from './Option';
import getGroupCss, { classes } from './styles/groupCss';

export type Props = {
  group: Node<unknown>;
  state: ListState<unknown>;
};

/**
 * Renders a group of options
 */
const Group = (props: Props) => {
  const { group, state } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: group.rendered,
    'aria-label': group['aria-label'],
  });

  const children = state.collection.getChildren
    ? Array.from(state.collection.getChildren(group.key))
    : [];

  return (
    <React.Fragment>
      {group.key !== state.collection.getFirstKey() && (
        <div
          css={getGroupCss}
          data-testid="visual-separator"
          role="presentation"
        >
          <Divider className={classes.divider} />
        </div>
      )}

      <div {...itemProps} css={getGroupCss}>
        {group.rendered && (
          <Typography
            {...headingProps}
            className={classes.label}
            color="body"
            component="div"
            variant="subtitleMedium"
          >
            {group.rendered}
            <VisuallyHidden>
              {stringFormatter.format('itemsNumberLabel', {
                number: children.length,
              })}
            </VisuallyHidden>
          </Typography>
        )}
        <div {...groupProps} className={classes.list} role="group">
          {children.map((item) => {
            return (
              <Option
                key={item.key}
                item={item}
                state={state}
                {...item.props}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Group;
