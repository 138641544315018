/* eslint-disable react/no-danger */
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Markdown from 'react-markdown';

import type { FaqProps } from 'bundles/cms/types';
import { clearEmptyFields } from 'bundles/seo/utils/utils';

type Props = {
  faqs: Array<FaqProps | null>;
};

class FaqsSchemaMarkup extends React.Component<Props, {}> {
  // Regex for checking whether the answer's markdown ends with 2 newlines and a link;
  // copy may vary (usually "Learn more"), but URLs always point to an /articles/ page
  trailingLinkRegex = /(\n){2,}\[([^\]]+)\]\(([^)]+\/articles\/[^)]+)\)$/;

  generatePlainHtmlFromMarkDown = (markdownSource: string | undefined | null) => {
    // If present, remove trailing link from markdown source before rendering
    const markdownSrc = markdownSource?.replace(this.trailingLinkRegex, '').trim();
    const plainHtml = renderToStaticMarkup(<Markdown source={markdownSrc ?? undefined} />);
    return plainHtml;
  };

  generatePlainTextFromHtml = (html: string) => {
    // Regex for removing html tags and related attributes from a string
    const htmlElements = /(<([^>]+)>)/gi;
    const plainText = html.replace(htmlElements, '');
    return plainText;
  };

  // Returns URL (string) of the trailing "articles" link, or undefined if it DNE
  extractTrailingLink = (markdownSource: string | undefined | null) => {
    const trailingLinkMatch = markdownSource?.match(this.trailingLinkRegex);
    return trailingLinkMatch ? trailingLinkMatch[3].trim() : undefined;
  };

  generateBrowseFAQMarkup = () => {
    const { faqs } = this.props;
    return faqs
      .filter((faq): faq is FaqProps => !!faq)
      .map(({ question, answer }) => ({
        '@type': 'Question',
        name: this.generatePlainTextFromHtml(this.generatePlainHtmlFromMarkDown(question)),
        acceptedAnswer: {
          '@type': 'Answer',
          text: this.generatePlainTextFromHtml(this.generatePlainHtmlFromMarkDown(answer)),
        },
        url: this.extractTrailingLink(answer),
      }));
  };

  render() {
    const markupJson = {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.generateBrowseFAQMarkup(),
    };

    const createMarkup = () => ({
      __html: `
        <script type="application/ld+json">
          ${JSON.stringify(clearEmptyFields(markupJson))}
        </script>
      `,
    });

    return (
      <div className="rc-FaqsSchemaMarkup">
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
    );
  }
}

export default FaqsSchemaMarkup;
