import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import { getContentWidth } from './contentWidthCss';
import type { WidthOptions } from './contentWidthCss';

export const getContentCss = (options: WidthOptions) => {
  return css`
    padding: 0 var(--cds-spacing-600);

    ${breakpoints.down('sm')} {
      padding: 0 var(--cds-spacing-200);
    }

    ${getContentWidth(options)}
  `;
};
