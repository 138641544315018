/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo } from 'react';

import classnames from 'classnames';

import a11yKeyPress from 'js/lib/a11yKeyPress';

import { PlayIcon } from '@coursera/cds-icons';

import type { TestimonialFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import Card from 'bundles/premium-hub/components/shared/Card';

import _t from 'i18n!nls/premium-hub';

import 'css!bundles/premium-hub/components/__styles__/degrees/VideoTestimonial';

type Props = TestimonialFragment & {
  totalSecondaryVideos?: number;
  isPrimary?: boolean;
  onClick: (x0: string) => void;
};

const styles = {
  primaryVideoTestimonialText: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    pointer-events: none;
    background-color: rgb(0 0 0 / 46%);
  `,
  videoPlayerIcon: css`
    && {
      height: 48px;
      width: 48px;
      margin-top: 2px;
      margin-left: 2px;
    }
  `,
  cardShadow: css`
    position: relative;
    width: 100%;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: rgb(31 31 31 / 12%) 0 1px 6px, rgb(31 31 31 / 12%) 0 1px 4px;
    margin-bottom: 1.5rem;
  `,
  cardBlock: css`
    padding: 0.75rem 1.5rem;
  `,
};

const VideoIcon = ({ position, onClick }: $TSFixMe) => {
  return (
    <div
      className="playIcon"
      role="button"
      onClick={onClick}
      onKeyPress={(event) => a11yKeyPress(event, onClick)}
      aria-label={_t('Video presentation, #{position}', { position })}
      tabIndex={0}
    >
      <PlayIcon color="invert" css={styles.videoPlayerIcon} />
    </div>
  );
};

const VideoTestimonial: React.FunctionComponent<Props> = ({
  onClick,
  videoEmbedUrl,
  authorName,
  authorTitle,
  text,
  isPrimary,
  totalSecondaryVideos,
  image,
}) => {
  const handleClickTestimonial = useCallback(() => {
    if (videoEmbedUrl) {
      onClick(videoEmbedUrl);
    }
  }, [onClick, videoEmbedUrl]);

  const classNames = useMemo(
    () =>
      classnames('rc-VideoTestimonial', {
        primaryTestimonial: isPrimary,
        doubleTestimonial: !isPrimary && totalSecondaryVideos === 2,
        tripleTestimonial: !isPrimary && totalSecondaryVideos && totalSecondaryVideos > 2,
      }),
    [isPrimary, totalSecondaryVideos]
  );

  return isPrimary ? (
    <Card css={styles.cardShadow} isInteractive={!!videoEmbedUrl}>
      <div
        className={classNames}
        style={{ backgroundImage: `url(${image?.url || image})` }}
        role="button"
        onClick={handleClickTestimonial}
        onKeyPress={(event) => a11yKeyPress(event, handleClickTestimonial)}
        tabIndex={-1}
        aria-label={authorTitle ? _t('Video presentation, #{authorTitle}', { authorTitle }) : _t('Video presentation')}
      />
      <section className="text-xs-center color-white" css={styles.primaryVideoTestimonialText}>
        {text && <blockquote className="d-block m-a-1 font-lg">{text}</blockquote>}
        {authorName && authorTitle && (
          <strong className="d-block m-b-2 font-xs">
            {authorName}, {authorTitle}
          </strong>
        )}
        {videoEmbedUrl && <VideoIcon onClick={handleClickTestimonial} position={authorTitle} />}
      </section>
    </Card>
  ) : (
    <Card css={styles.cardShadow} isInteractive={!!videoEmbedUrl}>
      <div
        className={classNames}
        style={{ backgroundImage: `url(${image?.url || image})` }}
        {...(videoEmbedUrl && {
          tabIndex: -1,
          'aria-label': authorTitle
            ? _t('Video presentation, #{authorTitle}', { authorTitle })
            : _t('Video presentation'),
          onClick: handleClickTestimonial,
          onKeyPress: (event) => a11yKeyPress(event, handleClickTestimonial),
        })}
      >
        <div className="videoOverlay" />
        {videoEmbedUrl && (
          <Card>
            <VideoIcon position={authorTitle} />
          </Card>
        )}
      </div>
      <Card className="testimonialInfo" css={styles.cardBlock}>
        {text && <blockquote className="d-block m-b-1">{text}</blockquote>}
        <strong className="d-block">{authorName}</strong>
        <em className="d-block">{authorTitle}</em>
      </Card>
    </Card>
  );
};

export default VideoTestimonial;
