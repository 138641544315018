import type { HTMLAttributes } from 'react';

import headerCss from './styles/headerCss';

export type Props = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

function Header({ children, ...rest }: Props) {
  return (
    <div css={headerCss} {...rest}>
      {children}
    </div>
  );
}

export default Header;
