import gql from 'graphql-tag';

type Section = {
  typeName: string;
  definition: Definition;
};

type Definition = {
  inviteToGroup: boolean;
  recommendations: any[];
  body: Record<string, BodyDefinition>;
  title: string;
};

type BodyDefinition = {
  value: string;
  renderableHtmlWithMetadata: RenderableHtmlWithMetadata;
  dtdId: string;
};

type RenderableHtmlWithMetadata = {
  renderableHtml: string;
  metadata: Metadata;
};

type Metadata = {
  isPlainText: boolean;
  hasCodeBlock: boolean;
  hasMath: boolean;
  hasAssetBlock: boolean;
};

export type CreateOrUpdateResponse = {
  QualificationSurveySectionResponse: {
    section: Section;
    id: string;
    sectionId: string;
    __typename: 'QualificationSurveySectionResponsesV2';
  };
  errors: unknown;
};

type ResponseText = {
  text: string;
};

type ResponseSelect = {
  id: string;
  text: string;
};

type ResponseKey = 'emailAddress' | 'firstName' | 'lastName' | 'phoneNumber' | 'categoriesSelection';

type EoiContext = {
  source?: string;
  utmParameters?: Record<string, unknown>;
};

export type CreateOrUpdateVariables = {
  productId: string;
  shouldProcessTerminalActions: boolean;
  responseId?: string;
  sectionId: string;
  eoiContext: EoiContext;
  responses: Partial<Record<ResponseKey, Record<string, ResponseText | ResponseSelect>>>;
  trackingFields?: Record<string, unknown>;
};

export const CREATE_OR_UPDATE = gql`
  mutation CreateOrUpdateQualificationSurveySectionResponse(
    $productId: String!
    $shouldProcessTerminalActions: Boolean!
    $responseId: String
    $sectionId: String!
    $responses: DataMap!
    $eoiContext: DataMap!
    $trackingFields: DataMap
  ) {
    QualificationSurveySectionResponse(
      productId: $productId
      shouldProcessTerminalActions: $shouldProcessTerminalActions
      responseId: $responseId
      input: { sectionId: $sectionId, responses: $responses, eoiContext: $eoiContext, trackingFields: $trackingFields }
    )
      @rest(
        method: "POST"
        type: "QualificationSurveySectionResponsesV2"
        path: "qualificationSurveySectionResponses.v2?action=createOrUpdate&productId={args.productId}&shouldProcessTerminalActions={args.shouldProcessTerminalActions}{args.responseId}"
      ) {
      id
      sectionId
      section {
        typeName
        definition {
          body
          inviteToGroup
          recommendations
          title
        }
      }
    }
  }
`;
