/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import { useLocation } from 'js/lib/useRouter';

import { NotFound } from 'bundles/common/components/withNotFound';
import { getVflpFilterSettings } from 'bundles/premium-hub/components/search/searchUtils';
import PremiumHubPageHeader from 'bundles/premium-hub/components/shared/PremiumHubPageHeader';
import { HubPages } from 'bundles/premium-hub/constants';
import useLearnBrowse from 'bundles/premium-hub/hooks/useLearnBrowse';
import usePremiumHubPageData from 'bundles/premium-hub/hooks/usePremiumHubPageData';
import OverviewPageContent from 'bundles/premium-hub/pages/subpages/OverviewPageContent';
import SearchPageContent from 'bundles/premium-hub/pages/subpages/SearchPageContent';
import { convertPpcPremiumProductToPremiumProductProps } from 'bundles/premium-hub/utils/dataTransformationUtils';
import DegreesCategorySchemaMarkup from 'bundles/seo/components/DegreesCategorySchemaMarkup';

import _t from 'i18n!nls/premium-hub';

const LandingPageContent: React.FC = () => {
  const pageType = HubPages.LandingPage;
  const location = useLocation();
  const { slug, inBrowse } = useLearnBrowse(pageType);
  const premiumHubPageData = usePremiumHubPageData({
    slug,
    pageType,
  });

  if (!premiumHubPageData) {
    return null;
  }

  const { categoryData, premiumProductList, pageContent, loading, useSimplifiedHeader = false } = premiumHubPageData;

  if (!loading && !pageContent) {
    // If pageContent doesn't exist, a page for this route hasn't been configured in Contentful
    return <NotFound />;
  }

  const { presetFilters } = getVflpFilterSettings();

  return (
    <>
      <PremiumHubPageHeader isSimplified={useSimplifiedHeader} location={location} />
      <main id="main">
        <DegreesCategorySchemaMarkup
          allDegreeLists={[
            {
              degreeType: _t('Degrees'),
              title: categoryData?.name ?? '',
              degrees: premiumProductList?.map(convertPpcPremiumProductToPremiumProductProps) ?? [],
            },
          ]}
          currentHubPage={pageType}
        />
        {inBrowse ? (
          <SearchPageContent
            {...premiumHubPageData}
            currentPageType={pageType}
            shouldShowDriftChat={false}
            presetSearchFilters={presetFilters}
          />
        ) : (
          <OverviewPageContent {...premiumHubPageData} currentPageType={pageType} shouldShowDriftChat={false} />
        )}
      </main>
    </>
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_hub',
      page: 'degree_sub',
    },
  }))
)(LandingPageContent);
