const SurveyFormConstants = {
  QuestionTypes: {
    SHORT_ANSWER: 'org.coursera.survey.question.ShortAnswerContent',
    NUMERIC: 'org.coursera.survey.question.NumericContent',
    DROPDOWN: 'org.coursera.survey.question.DropdownContent',
    CHECKBOX: 'org.coursera.survey.question.CheckboxContent',
    SINGLE_CHECKBOX: 'org.coursera.survey.question.SingleCheckboxContent', // TODO backend API does not exist
    PARAGRAPH_ANSWER: 'org.coursera.survey.question.ParagraphAnswerContent', // TODO backend API does not exist
    TYPEAHEAD: 'org.coursera.survey.question.TypeaheadContent',
    MULTIPLE_CHOICE: 'org.coursera.survey.question.MultipleChoiceContent',
  } as const,

  ResponseTypes: {
    SHORT_ANSWER_RESPONSE: 'org.coursera.survey.question.ShortAnswerResponse',
    NUMERIC_RESPONSE: 'org.coursera.survey.question.NumericResponse',
    DROPDOWN_RESPONSE: 'org.coursera.survey.question.DropdownResponse',
    CHECKBOX_RESPONSE: 'org.coursera.survey.question.CheckboxResponse',
    SINGLE_CHECKBOX_RESPONSE: 'org.coursera.survey.question.SingleCheckboxResponse',
    PARAGRAPH_ANSWER_RESPONSE: 'org.coursera.survey.question.ParagraphAnswerResponse',
    MULTIPLE_CHOICE_RESPONSE: 'org.coursera.survey.question.MultipleChoiceResponse',
    TYPEAHEAD_RESPONSE: 'org.coursera.survey.question.TypeaheadResponse',
  } as const,

  ValidationInputTypes: {
    EMAIL_VALIDATION: 'org.coursera.survey.question.validation.EmailValidation',
    PHONE_VALIDATION: 'org.coursera.survey.question.validation.PhoneValidation',
    HEX_COLOR_VALIDATION: 'org.coursera.survey.question.validation.HexColorValidation',
    SLUG_VALIDATION: 'org.coursera.survey.question.validation.SlugValidation',
    URL_VALIDATION: 'org.coursera.survey.question.validation.UrlValidation',
    NAME_VALIDATION: 'org.coursera.survey.question.validation.NameValidation',
    TEXT_LENGTH_VALIDATION: 'org.coursera.survey.question.validation.TextLengthValidation',
  } as const,
};

export const AutoCompleteConstants: { [key: string]: string } = {
  firstName: 'given-name',
  lastName: 'family-name',
  emailAddress: 'email',
  phoneNumber: 'tel',
} as const;

export default SurveyFormConstants;

export const { QuestionTypes, ResponseTypes, ValidationInputTypes } = SurveyFormConstants;

export type QuestionType = typeof SurveyFormConstants.QuestionTypes[keyof typeof SurveyFormConstants.QuestionTypes];
export type ResponseType = typeof SurveyFormConstants.ResponseTypes[keyof typeof SurveyFormConstants.ResponseTypes];
export type ValidationInputType =
  typeof SurveyFormConstants.ValidationInputTypes[keyof typeof SurveyFormConstants.ValidationInputTypes];
export type AutoCompleteConstantType = typeof AutoCompleteConstants[keyof typeof AutoCompleteConstants];

export type SurveyResponse = Record<string, Record<ResponseType, Record<string, string>>>;
