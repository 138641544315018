import type { IndustryRelevanceContent } from 'bundles/premium-hub/data/industryRelevanceModuleContent';
import type { PPCDegreeCategory } from 'bundles/premium-hub/types';

/*
  Order the industry relevance modules to match the order we get back from the GetAllDegreeCategoriesQuery (DRE order)
*/

export const sortIndustryRelevanceModules = (
  modules: IndustryRelevanceContent[],
  categories: PPCDegreeCategory[]
): IndustryRelevanceContent[] => {
  if (!categories || categories.length === 0) {
    return modules;
  }

  // Create an array of the ordered category slugs
  const categorySlugs = categories.map((category) => category.slug);

  // Sort the modules based on the category order
  const sortedModules = modules.sort((a, b) => {
    const aCategoryIndex = categorySlugs.indexOf(a.categorySlug);
    const bCategoryIndex = categorySlugs.indexOf(b.categorySlug);
    return aCategoryIndex - bCategoryIndex;
  });

  return sortedModules;
};
