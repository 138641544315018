import { graphql } from 'react-apollo';

import { useQuery } from '@apollo/client';

import { getCmsLocaleString } from 'js/lib/language';
import { useParams } from 'js/lib/useRouter';

import { getGrowthCmsGraphqlContext } from 'bundles/cms/utils/CmsUtils';
import type { PartnerInformation } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type {
  AtAGlanceQueryQuery,
  AtAGlanceQueryQueryVariables,
  DegreeDescriptionPageItemFragment,
  RedesignAtAGlanceQueryQuery,
  RedesignAtAGlanceQueryQueryVariables,
  UniversityProgramItemFragment,
} from 'bundles/premium-hub/__generated__/contentfulTypes';
import {
  getAllCertifcateContentfulData,
  getAllDegreesAtAGlance,
  getAllDegreesRedesignAtAGlance,
} from 'bundles/premium-hub/queries/contentfulQueries';
import type { CertificateProps, DegreeContentfulMetadata } from 'bundles/premium-hub/types';
import { combineOldAndRedesignAtAGlance, filterNullOrUndefined } from 'bundles/premium-hub/utils/index';

type InnerProps = {
  certificatesList?: Array<CertificateProps>;
};

type ContentfulCertificate = {
  slug: string;
  description?: string | undefined;
  durationDescription?: string | undefined;
};

const formatAagItem = (
  atAGlanceItem: UniversityProgramItemFragment | DegreeDescriptionPageItemFragment
): DegreeContentfulMetadata => {
  const { slug, atAGlance } = atAGlanceItem;

  let partner: PartnerInformation | undefined;
  if ('partner' in atAGlanceItem) partner = atAGlanceItem.partner as PartnerInformation;
  if ('partnerInformation' in atAGlanceItem) partner = atAGlanceItem.partnerInformation as PartnerInformation;

  const metadata: DegreeContentfulMetadata = {
    slug,
    atAGlance,
    partnerContentful: {
      partnerMonotoneLogo: {
        url: partner?.partnerMonotoneLogo?.url,
      },
      partnerLogo: {
        url: partner?.partnerLogo?.url,
      },
    },
  };

  return metadata;
};

export const withCertificatesContentfulIntegration = graphql(getAllCertifcateContentfulData, {
  options: ({ certificatesList }: InnerProps) => {
    const slugs = certificatesList?.map((certificate) => ({ slug: certificate.slug }));

    return {
      variables: {
        slugs,
        locale: getCmsLocaleString(),
      },
      context: { clientName: 'contentfulGql' },
    };
  },
  props: ({
    data,
    ownProps,
  }: {
    data?: {
      degreesHubDegreeMarketingInformationCollection?: { items?: Array<{ slug: string; description?: string }> };
    };
    ownProps?: InnerProps;
  }) => {
    const certifcateContentfulData = data?.degreesHubDegreeMarketingInformationCollection?.items;
    const certificatesToShow = ownProps?.certificatesList;

    const contentfulCertificatesBySlug: Record<string, ContentfulCertificate> = {};
    certifcateContentfulData?.forEach((certificate) => {
      contentfulCertificatesBySlug[certificate.slug] = certificate;
    });

    const allCertificateData = certificatesToShow?.map((certificate) => {
      const contentfulCertificateDataForSlug = contentfulCertificatesBySlug[certificate.slug];

      return {
        ...contentfulCertificateDataForSlug,
        ...certificate,
        duration: contentfulCertificateDataForSlug?.durationDescription,
      };
    });

    return {
      allCertificateData,
    };
  },
});

/**
 * Fetches and returns At-a-glance data from old and new DDPs, combined into a single array
 */
export const useDegreeAtAGlanceFromCms = () => {
  const params = useParams();
  const isPreview = params?.preview ? Number(params.preview) === 1 : false;
  const options = {
    variables: {
      locale: getCmsLocaleString(),
      preview: isPreview,
    },
    context: getGrowthCmsGraphqlContext(isPreview),
    errorPolicy: 'all' as const,
  };

  const { data: oldAtAGlanceData } = useQuery<AtAGlanceQueryQuery, AtAGlanceQueryQueryVariables>(
    getAllDegreesAtAGlance,
    options
  );

  const formattedOldAagData: DegreeContentfulMetadata[] =
    oldAtAGlanceData?.universityProgramPageContentCollection?.items.filter(filterNullOrUndefined).map(formatAagItem) ??
    [];

  const { data: newAtAGlanceData } = useQuery<RedesignAtAGlanceQueryQuery, RedesignAtAGlanceQueryQueryVariables>(
    getAllDegreesRedesignAtAGlance,
    options
  );

  const formattedNewAagData: DegreeContentfulMetadata[] =
    newAtAGlanceData?.universityProgramDegreeDescriptionPageCollection?.items
      .filter(filterNullOrUndefined)
      .map(formatAagItem) ?? [];

  const atAGlanceData = combineOldAndRedesignAtAGlance(formattedOldAagData, formattedNewAagData);

  return atAGlanceData;
};
