/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { getUrlParam } from 'js/lib/util';

import ChatButton from 'bundles/common/components/ChatButton';

import { getBoostConfig } from './utils/BoostConfig';
import { getAddBoostChatScriptToPage, getChatPanel, removeHiddenChatMessages } from './utils/BoostUtils';

// Developer note: Boost chat will give a CORS error when you run local dev on port :9443.  It seems that it's unable to allowlist origins with port numbers.
// To workaround this, you can either:
// A: Launch Chrome with CORS disabled and use this Chrome window only for local development of Boost chat: mkdir /tmp/chrome-dev; open -n -a Google\ Chrome --args --disable-web-security -–allow-file-access-from-files --user-data-dir="/tmp/chrome-dev"
// B: (Have not tried) Run a local reverse proxy server at :443 and put the local dev server :9443 behind it.  Do NOT find-and-replace 9443 with 443 in the codebase, because you'd have to run `sudo` to start the local server at the lower port number and this would mess up your local webpack or snowpack file permissions.

// You can use the following code snippet in your browser console to turn on Boost chat
/*
const epicOverrides = JSON.parse(localStorage.getItem('EpicOverrides')) || {};
epicOverrides.BlueJays = epicOverrides.BlueJays || {};
epicOverrides.BlueJays.enableChat = true;
epicOverrides.BlueJays.ChatBoostInstanceName = '845coursera'; // or 'coursera-poc' for the non-prod POC instance of Boost
epicOverrides.BlueJays.ChatConfig = {
  targetingRules: [
    { urlPattern: '/learn/autobots-degree' }, // set whatever URL regex patterns you want to test with locally, as regex strings.  you don't need to start with or end with .*
    { urlPattern: '/learn/python' }
  ]
};
localStorage.setItem('EpicOverrides', JSON.stringify(epicOverrides));
*/

type Props = {
  conversationId?: string;
  initialActionId?: number;
  isInlineButton?: boolean;
};

type BoostStylesAndLauncherButtonProps = {
  visible: boolean;
  inline?: boolean;
};

// Since we can't use CDS React components on a 3rd party UI, we have to reproduce the appearance using CSS overrides
// The following shouldn't be needed by anything else, and I'm not sure there's a much better place for it to live right now
const cdsSecondaryButtonStyle = css`
  display: block;
  background-color: var(--cds-color-neutral-primary-invert);
  color: var(--cds-color-interactive-primary);
  border: 1px solid var(--cds-color-interactive-primary);
  border-radius: 4px;
  padding: var(--cds-spacing-150) var(--cds-spacing-400);
  margin: var(--cds-spacing-100) 0;
  font-size: 16px;
  font-weight: 700;

  &:hover {
    text-decoration: none;
    background-color: var(--cds-color-interactive-stroke-primary-focus-invert);
    color: var(--cds-color-interactive-primary-hover);
    border-color: var(--cds-color-interactive-primary-hover);
    opacity: 1;
  }

  &:active {
    text-decoration: none;
    border-color: var(--cds-color-interactive-primary-selected);
  }

  &:focus {
    text-decoration: none;
    background-color: var(--cds-color-interactive-background-primary-selected-weak);
    color: var(--cds-color-interactive-primary-selected);
    outline: none;
    box-shadow: var(--cds-color-interactive-primary) 0 0 0 1px inset;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    vertical-align: middle;
  }

  svg {
    margin-left: var(--cds-spacing-100);
    height: 20px;
    min-width: 20px;
    vertical-align: middle;
  }
`;

// Boost chat takes a config object where we can specify many colors (see BoostConfig.ts).  But this wasn't powerful enough to implement all styling, so we are resorting to CSS overrides.
const boostOverrideStyles = css`
  #chat-container .ChatWindow {
    .MessageList {
      button[class*='Link__ActionLinkButton'],
      a[class*='Link__StyledLink'] {
        ${cdsSecondaryButtonStyle}
      }

      button {
        ${cdsSecondaryButtonStyle}
      }
    }

    form {
      textarea {
        line-height: 1rem;
      }
    }

    #boost-chat-panel-menu {
      a {
        color: var(--cds-color-interactive-primary);

        &:hover {
          opacity: 1;
          color: var(--cds-color-interactive-primary-hover);
        }
      }

      button {
        ${cdsSecondaryButtonStyle}
      }
    }

    .TitleBar {
      button:focus {
        box-shadow: var(--cds-color-interactive-primary) 0 0 0 1px inset;
      }
    }
  }

  #chat-container .iJlqsr,
  #chat-container .eQcoNc,
  #chat-container .iRUGCb,
  #chat-container .bWBbwQ {
    opacity: 1 !important;
  }
`;

const handleChatButtonClick = () => {
  // TODO: TOOL-1629 Add Retracked eventing
  const chatPanel = getChatPanel();
  chatPanel?.show();
};

/** Enables overriding the starting action ID via boostStartActionId URL param, which is very useful for testing chat flows */
const getOverrideInitialActionId = () => {
  const startTriggerActionIdFromUrl = getUrlParam(location.href, 'boostStartActionId');
  if (!startTriggerActionIdFromUrl) {
    return undefined;
  }
  return parseInt(startTriggerActionIdFromUrl, 10) || undefined; // treat 0 and NaN as if no URL param was given
};

/**
 * Returns the Boost action ID of the initial bot welcome message (action), or returns undefined if resuming a conversation or using the default welcome action configured in Boost.
 */
const getStartTriggerActionId = (conversationId: string | undefined, initialActionId: number | undefined) => {
  if (conversationId) {
    // startTriggerActionId is meaningless when resuming a conversation
    return undefined;
  }
  return getOverrideInitialActionId() || initialActionId;
};

const BoostStylesAndLauncherButton = ({ visible, inline }: BoostStylesAndLauncherButtonProps) => {
  useEffect(() => {
    const source = document.querySelector('#chat-container .MessageList > div');
    // Using a MutationObserver to remove hidden messages from the chat message list, because Boost chat doesn't provide a way to do this directly
    const observer = new MutationObserver(removeHiddenChatMessages);

    if (source) {
      observer.observe(source, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <React.Fragment>
      <Global styles={boostOverrideStyles} />
      <ChatButton show={visible} onClick={handleChatButtonClick} isInlineButton={inline} />
    </React.Fragment>
  );
};

/**
 * Renders a button on the lower right of the screen to launch a Boost.ai-powered chat session with a chatbot or support agent.
 * In general this component should not be used directly, see ChatLoader.tsx instead.
 */
const BoostChat = ({ conversationId, initialActionId, isInlineButton }: Props) => {
  const [showChatButton, setShowChatButton] = useState(false); // default to false to hide the chat button until script is loaded and Boost chat is initialized

  // Initialize the Boost chat panel script with initial context for the chat (one time effect):
  useEffect(() => {
    // initialActionId is meaningless when resuming a conversation.  If we're not resuming a chat (i.e. conversationId is undefined) and initialActionId is undefined, the Boost chatbot flow will start with the default welcome action.
    const startTriggerActionId = getStartTriggerActionId(conversationId, initialActionId);
    const pageUrl = location.href;
    const boostConfig = getBoostConfig({ startTriggerActionId, conversationId, pageUrl });
    const addScript = getAddBoostChatScriptToPage(setShowChatButton, boostConfig);
    addScript(); // this will check to make sure it's not already on the page
    // eslint wants `theme` added to the deps for this hook, but we only want to do this effect on initial render and we know theme won't be meaningfully changing
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const boostStylesAndLauncherButton = (
    <BoostStylesAndLauncherButton visible={showChatButton} inline={isInlineButton} />
  );

  if (!showChatButton) {
    // This is important to prevent document.body below from trying to evaluate in SSR
    return null;
  }

  if (isInlineButton) {
    return boostStylesAndLauncherButton;
  }

  return createPortal(boostStylesAndLauncherButton, document.body);
};

export default BoostChat;
