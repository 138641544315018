/**
 * Arguments that have semantic meaning to the heartbeat system.
 * Changing these values would require downstream DE updates
 * */
export const ROUTE_ARGUMENTS = {
  INSTITUTION_SLUG: 'institutionSlug',
  PROGRAM_SLUG: 'programSlug',
  COURSE_SLUG: 'courseSlug',
  CONTEXT_ID: 'contextId',
  ITEM_ID: 'itemId',
};
