import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

const getActionsCss = css`
  margin: var(--cds-spacing-300) 0 0;

  ${breakpoints.up('sm')} {
    & button:first-of-type {
      margin-top: 0;
      margin-right: var(--cds-spacing-200);
    }
  }

  ${breakpoints.down('xs')} {
    margin: var(--cds-spacing-300) 0 0;
    text-align: center;

    & button {
      width: 100%;
      max-width: none;
    }

    & button:nth-of-type(2) {
      margin: var(--cds-spacing-150) 0 0 0;
    }
  }
`;

export default getActionsCss;
