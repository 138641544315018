/* @jsx jsx */
import type * as React from 'react';

import _t from 'i18n!nls/premium-hub';

/*
  Temporary data for industry relevance module, once we know if the experiment is successful, we will move this data to Contentful
*/

type AvailableDomainCategories = 'business' | 'computer-science' | 'data-science' | 'data-analytics' | 'public-health';

/* TODO: Remove this type once it's generated by Contentful */
export type IndustryRelevanceCareer = {
  id: string;
  name: string;
  averageSalary: number;
};

/* TODO: Remove this type once it's generated by Contentful */
export type IndustryRelevanceContent = {
  title: string;
  industryName: string;
  categorySlug: string;
  ctaHref?: string;
  onCtaClick?: () => void;
  ctaText?: string;
  description: string;
  careersTitle?: string;
  careers: IndustryRelevanceCareer[];
  tabHeading?: string;
  salaryCitation?: string;
};

/* TODO: Remove this type once it's generated by Contentful */
export type InfoModuleContent = {
  moduleHeader: string;
  body: Array<string | React.ReactElement>;
  highlightsHeader: string;
  highlights: Array<{
    title: string;
    icon: React.ReactElement;
    body: string;
  }>;
};

export const industryRelevanceContent = () => ({
  'data-science': {
    title: _t('A skills-intensive degree for in-demand careers'),
    industryName: _t('Data Science'),
    categorySlug: 'data-science',
    ctaHref: '/degrees/data-science',
    ctaText: _t('View data science degrees'),
    description: `Data science is the most in-demand skill, according to the World Data Science Initiative.
       Beyond the incredible job growth projected for [data scientists](/articles/what-is-a-data-scientist), 
       professionals who work with data often command [higher-than-average salaries](/articles/data-scientist-salary). 
       Beyond the jobs listed here, learn more about [what you can do with a data science degree](/articles/data-science-career).`,
    careersTitle: _t('In-demand careers for data science graduates'),
    careers: [
      {
        id: '1',
        name: _t('Data Analyst'),
        averageSalary: 81300,
      },
      {
        id: '2',
        name: _t('Data Scientist'),
        averageSalary: 130000,
      },
      {
        id: '3',
        name: _t('Data Engineer'),
        averageSalary: 130000,
      },
    ],
    salaryCitation: _t('* Source: Lightcast™.(2023), Job Postings Report (Jul 2022 - Jun 2023). Retrieved July 2023.'),
  },
  'computer-science': {
    title: _t('A practical degree for career diversity'),
    industryName: _t('Computer Science'),
    categorySlug: 'computer-science',
    ctaHref: 'degrees/computer-science',
    ctaText: _t('View computer science degrees'),
    description: `Computer science is a [popular major](/articles/most-popular-college-majors) worldwide for several reasons, 
      including high demand for computer and IT professionals, [higher-than-average salaries](/articles/highest-paying-computer-science-jobs), and wide-ranging career opportunities. 
      In addition to the in-demand jobs listed here, learn more about [what you can do with a computer science degree](/articles/computer-science-jobs-for-the-future).`,
    careersTitle: _t('In-demand careers for computer science graduates'),
    careers: [
      {
        id: '1',
        name: _t('Cybersecurity Analyst'),
        averageSalary: 112600,
      },
      {
        id: '2',
        name: _t('Front-End Developer'),
        averageSalary: 125400,
      },
      {
        id: '3',
        name: _t('Back-End Developer'),
        averageSalary: 140200,
      },
    ],
    salaryCitation: _t('* Source: Lightcast™.(2023), Job Postings Report (Jul 2022 - Jun 2023). Retrieved July 2023.'),
  },
  business: {
    title: _t('A diverse degree for in-demand careers'),
    industryName: _t('Business'),
    categorySlug: 'business',
    ctaHref: '/degrees/business',
    ctaText: _t('View business degrees'),
    tabHeading: _t('MBA and Business'),
    description: `Business often leads or is among [the most popular majors](/articles/most-popular-college-majors) in the world because it remains in demand across industries.
      That ongoing growth, combined with [higher-than-average salaries](/articles/business-degree-salary), makes it a strong choice when you’re interested in earning 
      either an undergraduate or graduate degree.`,
    careersTitle: _t('In-demand careers for business graduates'),
    careers: [
      {
        id: '1',
        name: _t('Digital Marketer'),
        averageSalary: 55000,
      },
      {
        id: '2',
        name: _t('Accountant'),
        averageSalary: 64400,
      },
      {
        id: '3',
        name: _t('Project Manager'),
        averageSalary: 86500,
      },
    ],
    salaryCitation: _t('* Source: Lightcast™.(2023), Job Postings Report (Jul 2022 - Jun 2023). Retrieved July 2023.'),
  },
  'data-analytics': {
    title: _t('An advanced degree for today’s data-driven industries'),
    industryName: _t('Data Analytics'),
    categorySlug: 'data-analytics',
    ctaHref: '/degrees/data-analytics',
    ctaText: _t('View data analytics degrees'),
    tabHeading: _t('Data Analytics'),
    description: `In today’s evolving business landscape, organizations often base their decisions on data so they can adapt quickly,
      creating a high demand for graduates proficient in data analytics.
      Investing in a data analytics graduate degree opens the door to a diverse range of career options in various industries and can lead to higher salaries.`,
    careersTitle: _t('In-demand careers for data analytics graduates'),
    careers: [
      {
        id: '1',
        name: _t('Data Analyst'),
        averageSalary: 81300,
      },
      {
        id: '2',
        name: _t('Data Scientist'),
        averageSalary: 130000,
      },
      {
        id: '3',
        name: _t('Data Engineer'),
        averageSalary: 130000,
      },
    ],
    salaryCitation: _t('* Source: Lightcast™.(2023), Job Postings Report (Jul 2022 - Jun 2023). Retrieved July 2023.'),
  },
  'public-health': {
    title: _t('A graduate degree for community good'),
    industryName: _t('Public Health'),
    categorySlug: 'public-health',
    ctaHref: '/degrees/public-health',
    ctaText: _t('View public health degrees'),
    tabHeading: _t('Public Health'),
    description: `A public health degree equips you with the knowledge and skills to address pressing local and global health issues,
      enabling you to positively influence community well-being. It opens doors to a wide array of careers, including epidemiology,
      health care management, and policy development.`,
    careersTitle: _t('In-demand careers for public health graduates'),
    careers: [
      {
        id: '1',
        name: _t('Epidemiologist'),
        averageSalary: 71400,
      },
      {
        id: '2',
        name: _t('Health Education Specialist'),
        averageSalary: 74100,
      },
      {
        id: '3',
        name: _t('Medical and Health Services Manager'),
        averageSalary: 92500,
      },
      {
        id: '4',
        name: _t('Biostatistician'),
        averageSalary: 97700,
      },
    ],
    salaryCitation: _t(
      '* Source: Lightcast™.(2023), Job Postings Report (Jan 2022 - December 2022). Retrieved September 2023.'
    ),
  },
});

// Function to check if a slug is in ValidSlugs
export const hasIndustryRelevanceContent = (slug: string): boolean => {
  return (slug as AvailableDomainCategories) in industryRelevanceContent();
};

export const getIndustryRelevanceContent = (slug: string): IndustryRelevanceContent | undefined => {
  return industryRelevanceContent()[slug as AvailableDomainCategories];
};
