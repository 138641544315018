import type { Ref } from 'react';
import { forwardRef } from 'react';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';

import bodyCss from './styles/bodyCss';

type BaseProps = {
  children: React.ReactNode;
};

export interface BodyTypeMap<D extends React.ElementType = 'div'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = BodyTypeMap['defaultComponent']
> = OverrideProps<BodyTypeMap<D>, D> & { component?: React.ElementType };

const Body: OverridableComponent<BodyTypeMap> = forwardRef(function Body(
  props: Props,
  ref: Ref<HTMLDivElement>
) {
  const { children, component, ...rest } = props;

  const Root = component || 'div';

  return (
    <Root ref={ref} css={bodyCss} {...rest}>
      {children}
    </Root>
  );
});

export default Body;
