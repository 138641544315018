/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Grid, Typography2, breakpoints, useTheme } from '@coursera/cds-core';

import type { ProductOfferingCardProps } from 'bundles/premium-hub/types';

const styles = {
  contentContainer: {
    display: 'flex',
  },
  partnerLogoContainer: (theme: Theme) => ({ marginRight: 'var(--cds-spacing-400)' }),
  partnerLogo: (theme: Theme) => ({
    maxHeight: '96px',
    borderRadius: '5px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.13)',
    [`${breakpoints.down('sm')}`]: { maxHeight: '64px' },
  }),
  partnerText: (theme: Theme) => ({
    marginBottom: 'var(--cds-spacing-100)',
  }),
  programDetails: (theme: Theme) => ({
    marginBottom: 'var(--cds-spacing-100)',
  }),
  linkText: (theme: Theme) => ({
    color: 'var(--cds-color-blue-700)',
    ':hover': {
      textDecoration: 'underline',
    },
  }),
  applicationDeadlineContainer: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 var(--cds-spacing-150)',
  }),
};

const ProductOfferingCardDesktop: React.FunctionComponent<ProductOfferingCardProps> = ({
  title,
  partnerInfo,
  programDetails,
  programSummary,
  programDeadlineText,
  linkText,
}) => {
  const theme = useTheme();

  return (
    <Grid container style={{ padding: 'var(--cds-spacing-400) 0' }}>
      <Grid lg={9}>
        <div css={styles.contentContainer}>
          {partnerInfo?.partnerLogo && partnerInfo?.partnerName && (
            <div css={styles.partnerLogoContainer(theme)}>
              <img src={partnerInfo.partnerLogo} alt={partnerInfo.partnerName} css={styles.partnerLogo} />
            </div>
          )}
          <div>
            <Typography2 variant="subtitleMedium" component="p">
              {title}
            </Typography2>
            {partnerInfo?.partnerText && (
              <Typography2 variant="bodySecondary" component="p" css={styles.partnerText(theme)}>
                {partnerInfo.partnerText}
              </Typography2>
            )}
            <Typography2 variant="subtitleMedium" component="p" css={styles.programDetails(theme)}>
              {programDetails}
            </Typography2>
            {programSummary && (
              <Typography2 variant="bodySecondary" component="i">
                {programSummary}
              </Typography2>
            )}
          </div>
        </div>
      </Grid>
      <Grid container lg={3} style={{ ...styles.applicationDeadlineContainer(theme), textAlign: 'center' }}>
        <div>
          {programDeadlineText && (
            <Typography2 variant="subtitleMedium" component="p">
              {programDeadlineText}
            </Typography2>
          )}
          <Typography2 variant="subtitleMedium" component="p" css={styles.linkText(theme)}>
            {linkText}
          </Typography2>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProductOfferingCardDesktop;
