/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { SelectField, SelectOption } from '@coursera/cds-core';

import formStyles from 'bundles/account-profile/components/i18n-phone-number-input/styles/FormStyles';
import type { PhoneNumberRegion } from 'bundles/account-profile/components/i18n-phone-number-input/types';
import { isPhoneNumberRegion } from 'bundles/account-profile/components/i18n-phone-number-input/types';
import { getPhoneNumberRegionToDetailsMapping } from 'bundles/account-profile/components/i18n-phone-number-input/utils';

import _t from 'i18n!nls/account-profile';

const styles = {
  select: css`
    min-width: 88px;

    .cds-select-select {
      .i18n-country-code-select-field-text {
        display: none;
      }

      .i18n-country-code-select-field-flag {
        font-size: 32px !important;
        line-height: 1.5rem !important;
      }
    }
  `,
  region: css`
    margin: 0 4px;
  `,
};

const baseStyles = [
  styles.select,
  formStyles.hideChildLabel,
  formStyles.removeTopMarginImmediateChildDiv,
  formStyles.removeChildInputBottomMargin,
];

type CountryCodeSelectFieldProps = {
  textFieldTopOffset: number;
  selectedRegion?: PhoneNumberRegion;
  onRegionChange: (region: PhoneNumberRegion) => void;
};

const CountryCodeSelectField = (props: CountryCodeSelectFieldProps) => {
  const { textFieldTopOffset, selectedRegion, onRegionChange } = props;

  const calculatedStyles = useMemo(() => {
    return [
      ...baseStyles,
      css`
        margin-top: ${textFieldTopOffset}px;
      `,
    ];
  }, [textFieldTopOffset]);

  const options = useMemo(() => {
    const mapping = getPhoneNumberRegionToDetailsMapping();

    return Object.entries(mapping).map(([key, { code, label }]) => {
      return (
        <SelectOption key={key} value={key}>
          <ReactCountryFlag className="i18n-country-code-select-field-flag" countryCode={key} role={undefined} />
          <span className="i18n-country-code-select-field-text">
            <span css={styles.region}>{label || key}</span>
            <span>(+{code})</span>
          </span>
        </SelectOption>
      );
    });
    // The options never change so we should only calculate it when the
    // component renders for the first time
  }, []);

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const maybeRegion = event?.target?.value;
    if (typeof maybeRegion === 'string' && isPhoneNumberRegion(maybeRegion)) {
      onRegionChange(maybeRegion);
    }
  };

  const handleOpen = (event: ChangeEvent<unknown>) => {
    const selectFieldElement = event.target as HTMLDivElement;
    // Simulating pressing tab so the focus goes to the first country option
    // This is preferable to using document.querySelector since we would need a timeout
    selectFieldElement.dispatchEvent(new KeyboardEvent('keydown', { key: 'Tab' }));
  };

  return (
    <SelectField
      css={calculatedStyles}
      label={_t('Phone Number Country Code')}
      fullWidth={false}
      value={selectedRegion}
      onChange={handleChange}
      onOpen={handleOpen}
    >
      {options}
    </SelectField>
  );
};

export default CountryCodeSelectField;
