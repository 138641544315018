/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Media from 'react-media';

import { groupBy, keyBy, map, mapValues } from 'lodash';

import { simplifyQueryValue } from 'js/lib/queryUtils';
import { useRetracked } from 'js/lib/retracked';
import { useLocation, useNavigate } from 'js/lib/useRouter';

import { Button, Grid, Pagination, breakpoints } from '@coursera/cds-core';

import { useUserAgent } from 'bundles/page/contexts/UserAgentApiContext';
import BrowseBanner from 'bundles/premium-hub/components/degrees/BrowseBanner';
import SearchCards from 'bundles/premium-hub/components/search/SearchCards';
import {
  ALL_PAGES_PRESET_FILTER_VALUES,
  DEFAULT_SEARCH_FILTERS,
  LANDING_PAGE_SEARCH_FILTERS,
  RESULTS_PER_PAGE,
} from 'bundles/premium-hub/components/search/searchConstants';
import { orderDegreesByRank, paginateResults } from 'bundles/premium-hub/components/search/searchUtils';
import Container from 'bundles/premium-hub/components/shared/Container';
import { HubPages } from 'bundles/premium-hub/constants';
import type { DegreeContentfulMetadata, PremiumProductWithMetadata } from 'bundles/premium-hub/types';
import ActiveFilterItems from 'bundles/search-common/components/ActiveFilterItems';
import NoSearchResultsMessage from 'bundles/search-common/components/NoSearchResultsMessage';
import SearchResultsHeader from 'bundles/search-common/components/SearchResultsHeader';
import SearchFilters from 'bundles/search-common/components/filters/SearchFilters';
import { SEARCH_FILTERS_INDEX_ID, SEARCH_MAIN_INDEX_ID } from 'bundles/search-common/constants';
import { useSearch } from 'bundles/search-common/providers/SearchContext';
import type { SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/premium-hub';

type Props = {
  presetFilters?: string[];
  dreOrderedProducts?: PremiumProductWithMetadata[];
  atAGlanceData?: DegreeContentfulMetadata[];
  slug: string;
  productId: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  surveyProductType: string;
};

const styles = {
  header: (isMobile: boolean) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${isMobile ? '0px' : '12px'};

    ${breakpoints.down('md')} {
      min-height: var(--cds-spacing-400);
    }
  `,
  resultsHeader: () =>
    css`
      margin-top: var(--cds-spacing-50);
    `,
  exploreAllDegreesContainer: () => css`
    display: flex;
    height: min-content;
    justify-content: end;
    ${breakpoints.down('sm')} {
      justify-content: center;
      margin-bottom: var(--cds-spacing-300);

      .viewMoreDegreesButton {
        width: 100%;
        max-width: 100%;
      }
    }
  `,
  pagination: css`
    display: flex;
    margin-bottom: var(--cds-spacing-400);
    margin-top: var(--cds-spacing-400);
    align-items: center;
    justify-content: center;
  `,
  degreeComparisonButton: css`
    display: flex;
    height: min-content;
    justify-content: end;
    ${breakpoints.down('sm')} {
      justify-content: start;
      margin-bottom: var(--cds-spacing-400);
    }
  `,
};

export const Search = ({
  presetFilters = [],
  dreOrderedProducts,
  atAGlanceData = [],
  productId,
  currentHubPage,
  surveyProductType,
  slug,
}: Props) => {
  const userAgent = useUserAgent();
  const { getSearchResults, addFilters, removeFilters, isLoading, setFiltersByFacet } = useSearch();
  const searchData = getSearchResults(SEARCH_MAIN_INDEX_ID);
  const filterData = getSearchResults(SEARCH_FILTERS_INDEX_ID);
  const defaultFilters = currentHubPage === HubPages.LandingPage ? LANDING_PAGE_SEARCH_FILTERS : DEFAULT_SEARCH_FILTERS;

  const { query, facets, pagination, facetFilters, source } = searchData || {};
  const allFilters = filterData?.facets;
  const filterAttributes = [...defaultFilters];
  const totalHits = pagination?.totalElements;
  const showNoResultsView = totalHits === 0;
  const activeFilters = [...new Set(facetFilters?.flat())];
  const filters: Record<string, SearchFilterOption[]> = {};
  facets?.forEach(({ name, valuesAndCounts }) => {
    filters[name] =
      valuesAndCounts?.map((filter) => ({
        ...filter,
        label: filter.value,
        isRefined: activeFilters?.includes(`${name}:${filter.value}`),
      })) || [];
  });

  filterAttributes.forEach((filter) => {
    const isActive = activeFilters.some((str) => str.includes(filter));
    if (isActive) {
      allFilters?.forEach(({ name, valuesAndCounts }) => {
        if (name === filter) {
          filters[name] =
            valuesAndCounts?.map((filterItem) => ({
              ...filterItem,
              label: filterItem.value,
              isRefined: activeFilters?.includes(`${name}:${filterItem.value}`),
            })) || [];
        }
      });
    }
  });

  const {
    query: { query: searchQuery },
    pathname,
  } = useLocation();
  const navigate = useNavigate();
  const trackComponent = useRetracked();

  const groupedFilters = groupBy(activeFilters, (filter) => filter.split(':')[0]);
  const keyedFilters = keyBy(groupedFilters, (filter) => filter[0]?.split(':')?.[0]);
  const filtersApplied = mapValues(keyedFilters, (group) => map(group, (filter) => filter.split(':')[1]));
  const removableFilters = activeFilters.filter(
    (filter) => ![...ALL_PAGES_PRESET_FILTER_VALUES, ...presetFilters].includes(filter)
  );

  // Client side pagination
  const PAGE_SIZE = 12;
  const [page, setPage] = useState(1);
  const handlePageChange = (event: React.ChangeEvent<unknown>, nextPage: number) => {
    setPage(nextPage);
    window?.scroll(0, 0);

    trackComponent({
      trackingData: { resultsPageNumber: nextPage, page: window?.location?.pathname },
      trackingName: 'search_results_change_page_number',
      action: 'search',
    });
  };

  useEffect(() => {
    setPage(1);
  }, [totalHits]);

  const rankedAndPaginatedResults = useMemo(() => {
    const rankedResults = orderDegreesByRank(searchData?.elements, dreOrderedProducts);
    const paginatedResults = paginateResults(rankedResults, page, PAGE_SIZE);
    return paginatedResults;
  }, [searchData, dreOrderedProducts, page, PAGE_SIZE]);

  useEffect(() => {
    if (!isLoading && showNoResultsView) {
      trackComponent({
        trackingName: 'degrees_browse_no_results_found',
        trackingData: { filtersApplied, pathname },
        action: 'view',
      });
    }
  }, [isLoading, showNoResultsView, filtersApplied, pathname, trackComponent]);

  const eventingData = {
    searchIndex: source?.indexName || '',
    searchIndexPosition: 1,
    page,
    searchQuery,
    filtersApplied,
  };

  const onExploreDegreesClick = () => {
    trackComponent({
      trackingName: 'search_explore_all_degrees_button',
      trackingData: {},
      action: 'click',
    });
    navigate('/degrees/browse');
  };

  const slugs = pathname.split('/');
  const inBrowse = slugs[slugs.length - 1] === 'browse';
  const inCoreDegreesHub = slugs[slugs.length - (inBrowse ? 2 : 1)] === 'degrees';
  const showExploreAllDegreesButton = !inCoreDegreesHub;

  return (
    <Container>
      <Media query={{ maxWidth: breakpoints.values.md - 1 }} defaultMatches={userAgent?.isMobileBrowser}>
        {(isMobile) => (
          <Grid container spacing={{ xs: 16, md: 32, lg: 64 }}>
            <Grid item xs={12} md={3}>
              <SearchResultsHeader
                searchQuery={simplifyQueryValue(searchQuery)}
                numberOfHits={totalHits || undefined}
                shouldHide={!isMobile}
                isLoading={isLoading}
              />
              <Grid css={styles.header(isMobile)}>
                <Grid css={styles.resultsHeader}>
                  {/* 
                    The loading check is required while we have the hardcoded duration filter, we should remove it once it's fixed.
                    The filter component doesn't correctly detemine the loading state and show the skeleton 
                    while the hardcoded filter us present.
                  */}
                  {!isLoading && (
                    <SearchFilters
                      filters={filters}
                      activeFilters={removableFilters}
                      eventingData={eventingData}
                      isMobile={isMobile}
                      filtersListOrder={DEFAULT_SEARCH_FILTERS}
                      showingNoResults={!!showNoResultsView}
                      addFilters={(filter: string) => addFilters(SEARCH_MAIN_INDEX_ID, [filter])}
                      removeFilters={(filter: string) => removeFilters(SEARCH_MAIN_INDEX_ID, [filter])}
                      clearFilters={() => removeFilters(SEARCH_MAIN_INDEX_ID, removableFilters)}
                      setFiltersByFacet={(facetName: string, newFilters: string[]) => {
                        setFiltersByFacet(SEARCH_MAIN_INDEX_ID, facetName, newFilters);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid css={!isMobile ? styles.header(isMobile) : null}>
                <Grid container css={styles.resultsHeader}>
                  <Grid item sm={12} md={showExploreAllDegreesButton && !isMobile ? 6 : 12}>
                    <SearchResultsHeader
                      numberOfHits={totalHits || undefined}
                      shouldHide={isMobile}
                      isLoading={isLoading}
                    />
                  </Grid>
                  {showExploreAllDegreesButton && !isMobile && (
                    <Grid item sm={12} md={6} css={styles.exploreAllDegreesContainer}>
                      <Button
                        className="viewMoreDegreesButton"
                        variant="secondary"
                        size="small"
                        onClick={onExploreDegreesClick}
                      >
                        {_t('View more degrees')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!isMobile && removableFilters && (
                <ActiveFilterItems
                  items={removableFilters}
                  removeFilter={(filter: string) => removeFilters(SEARCH_MAIN_INDEX_ID, [filter])}
                  clearFilters={() => removeFilters(SEARCH_MAIN_INDEX_ID, removableFilters)}
                  eventingData={eventingData}
                />
              )}
              {showNoResultsView ? (
                <NoSearchResultsMessage query={query} />
              ) : (
                <>
                  <SearchCards
                    hits={rankedAndPaginatedResults}
                    premiumProductList={dreOrderedProducts ?? []}
                    resultsPerPage={RESULTS_PER_PAGE}
                    isLoading={isLoading}
                    atAGlanceData={atAGlanceData}
                  />
                  <Pagination
                    css={styles.pagination}
                    hidePageSizeInput={true}
                    page={page}
                    total={totalHits || 0}
                    pageSize={PAGE_SIZE}
                    onPageChange={handlePageChange}
                  />
                  {currentHubPage === HubPages.MainHubPage && (
                    <BrowseBanner
                      productId={productId}
                      currentHubPage={currentHubPage}
                      surveyProductType={surveyProductType}
                      slug={slug}
                    />
                  )}
                </>
              )}
              {showExploreAllDegreesButton && isMobile && (
                <Grid item css={styles.exploreAllDegreesContainer}>
                  <Button className="viewMoreDegreesButton" variant="secondary" onClick={onExploreDegreesClick}>
                    {_t('View more degrees')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Media>
    </Container>
  );
};

export default Search;
