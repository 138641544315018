import Skeleton from '@core/Skeleton';
import Typography from '@core/Typography2';

import { classes, itemLayoutCss } from './styles/itemCss';

/**
 * Renders a skeleton loading state for a menu item.
 */
const ItemLoading = () => {
  return (
    <li className={classes.loading} css={itemLayoutCss} role="presentation">
      <div className={classes.prefix}>
        <Skeleton height="36px" variant="rect" width="36px" />
      </div>
      <div className={classes.content}>
        <Typography component="div" variant="bodyPrimary">
          <Skeleton variant="text" />
        </Typography>
      </div>
    </li>
  );
};

export default ItemLoading;
