const FRIENDBUY_COUPONS_PROMO_ID = 17994;
const FRIENDBUY_PROFESSIONAL_CERTS_PROMO_ID = 90526;
const COURSERA_PLUS_FRIEND_PROMO_ID = 186845;
const COURSERA_PLUS_ADVOCATE_PROMO_ID = 186874;

export const FRIENDBUY_PROMOTION_IDS = [
  FRIENDBUY_COUPONS_PROMO_ID,
  FRIENDBUY_PROFESSIONAL_CERTS_PROMO_ID,
  COURSERA_PLUS_ADVOCATE_PROMO_ID,
  COURSERA_PLUS_FRIEND_PROMO_ID,
];

export const FRIENDBUY_STRING = 'friendbuy';
export const FREE_REFERRAL_FOR_FRIEND_PROMO_CODE = 'KEytre0863';

export const REFER_A_FRIEND_PAGE_URL = '/referral/invite';
export const REFER_A_FRIEND_PAGE_URL_UK = '/referral/invite?utm_medium=UK';
export const REFER_A_FRIEND_PAGE_CPLUS_ALTRUISTIC = '/referral/invite?utm_medium=ExistingCplus';
export const REFER_A_FRIEND_TERMS_LINK = 'https://learner.coursera.help/hc/articles/360034721851';
// eslint-disable-next-line camelcase
export const REFERRAL_LIHP_UTM_PARAM = { utm_source: 'LIHP' };
// eslint-disable-next-line camelcase
export const REFERRAL_CPLUS_ALTRUISTIC_UTM_PARAM = { utm_medium: 'ExistingCplus' };
// eslint-disable-next-line camelcase
export const REFERRAL_UK_UTM_PARAM = { utm_medium: 'UK' };
// eslint-disable-next-line camelcase
export const REFERRAL_ACCOUNT_DROPDOWN_UTM_PARAM = { utm_source: 'accountdropdown' };

export const NEXT_GEN_FRIENDBUY_MERCHANT_ID = 'db0b5fe0-053b-4b8c-af90-01d3aaa7c725';
export const NEXT_GEN_FRIENDBUY_SCRIPTS = [
  'https://static.fbot.me/friendbuy.js',
  'https://campaign.fbot.me/' + NEXT_GEN_FRIENDBUY_MERCHANT_ID + '/campaigns.js',
];
export const REGULAR_REFERRAL_WIDGET_COMPONENT_ID = 'friendbuy-landing-page';
export const GOOGLE_CERTIFICATES_REFERRAL_WIDGET_COMPONENT_ID = 'google-certs-friendbuy-landing-page';
export const FRIENDBUY_SIGNUP_TRACKING_PATHS = ['/courseraplus/special/coursera-frnd-rfrl-2022'];

export const REFERRAL_ELIGIBLE_COUNTRIES = ['US', 'GB', 'CA'];

export const REFERRAL_BUTTON_NAME = 'refer-a-friend';
