import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('mobileMenuList', ['root']);

const getMobileMenuListCss = css`
  outline: none;
  overflow: auto;
  max-height: 350px;
  padding: var(--cds-spacing-200);

  & > li + li {
    margin-top: var(--cds-spacing-50);
  }
`;

export default getMobileMenuListCss;
