import * as React from 'react';
import { useContext } from 'react';

import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromWithSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';
import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';
import { useHasCourseraPlusSearchFilterParam } from 'bundles/search-common/providers/RouterProvider';

const LearnerContext = React.createContext<PropsFromCourseraPlusProductOwnerships & PropsFromWithSubscriptionsByUser>(
  {}
);

export const isInShowPlusPillExperiment = () => {
  GrowthDiscoveryExperiments.get('showCourseraPlusPillOnSearch');
};

export const useOwnsCourseraPlus = () => {
  const { ownsCourseraPlus, ownsCourseraLite } = useContext(LearnerContext);
  const showForExperiment = !ownsCourseraLite && isInShowPlusPillExperiment();
  return Boolean(ownsCourseraPlus || showForExperiment);
};
export const useOwnsCourseraLite = () => {
  const { ownsCourseraLite, ownsCourseraPlus } = useContext(LearnerContext);
  // make sure that there's no more than 1 of uswOwnsCourseraLite and useOwnsCourseraPlus returns true
  return Boolean(ownsCourseraLite && !ownsCourseraPlus);
};

export const useShowCourseraTier = () => {
  const ownsLite = useOwnsCourseraLite();
  const ownsPlus = useOwnsCourseraPlus();
  const inUrl = useHasCourseraPlusSearchFilterParam();
  return ownsLite || ownsPlus || inUrl;
};

export const chooseCourseraLite = (ownsLite: boolean) => {
  return ownsLite;
};

export const useChooseCourseraLite = () => {
  return chooseCourseraLite(useOwnsCourseraLite());
};

// TODO this name is used all over the place, fix those in future prs
export const useIsEnrolledInCPlus = () => Boolean(useOwnsCourseraPlus());

type InputProps = { children: React.ReactNode };

type PropsToComponent = PropsFromCourseraPlusProductOwnerships & InputProps;

const LearnerProvider = ({ children, ownsCourseraPlus, ownsCourseraLite }: PropsToComponent) => {
  return <LearnerContext.Provider value={{ ownsCourseraPlus, ownsCourseraLite }}>{children}</LearnerContext.Provider>;
};

export default withCourseraPlusProductOwnerships()(LearnerProvider);
