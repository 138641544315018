import * as React from 'react';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import ErrorBoundaryWithTagging from 'bundles/common/components/ErrorBoundaryWithTagging';
import NotificationIcon from 'bundles/notification-center/components/NotificationIcon';

const NotificationCenter = createLoadableComponent(
  () => import('bundles/notification-center/components/NotificationCenter')
);
/**
 * We only do data fetching for notification center on the client side.
 *
 * This is a deliberate design decision to
 * 1) avoid any performance regressions in server-side rendering on apps and
 * 2) reduce risk of SSR errors
 */
class ClientSideRenderedNotificationCenter extends React.Component<{}, { componentDidMount: boolean }> {
  state = {
    componentDidMount: false,
  };

  componentDidMount() {
    this.setState({ componentDidMount: true });
  }

  render() {
    const { componentDidMount } = this.state;

    if (!componentDidMount) {
      return (
        <div className="rc-NotificationCenter">
          <NotificationIcon unreadCount={0} active={false} onClick={() => {}} />
        </div>
      );
    }

    return (
      <ErrorBoundaryWithTagging componentName="NotificationCenter">
        <NotificationCenter />
      </ErrorBoundaryWithTagging>
    );
  }
}

export default ClientSideRenderedNotificationCenter;
