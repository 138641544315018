import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('checkboxInput', [
  'checked',
  'disabled',
  'indeterminate',
]);

export default (focusVisibleClassName?: string) => css`
  &:hover,
  &:active,
  &.${classes.disabled}, &.${classes.checked}:hover {
    background-color: transparent;
  }

  &.${focusVisibleClassName} {
    svg {
      border-radius: var(--cds-border-radius-25);
      box-shadow: var(--cds-color-neutral-primary-invert) 0 0 0 2px,
        var(--cds-color-interactive-stroke-primary-focus) 0 0 0 3px,
        var(--cds-color-interactive-background-primary-hover-weak) 0 0 0 4px;
    }
  }
`;
