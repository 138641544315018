import * as React from 'react';

type DefaultCompanyLogoProps = {
  size?: number;
  stroke?: string;
  fill?: string;
  darkFill?: string;
};

const DefaultCompanyLogo = (props: DefaultCompanyLogoProps) => {
  const { size = 48, stroke = '#5F6465', fill = '#FFF', darkFill = '#E1E1E1' } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>default company logo</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 48 0 48 48 0 48" />
        <path
          d="M0,24 C0,37.2545625 10.7446875,48 24,48 C37.2553125,48 48,37.2545625 48,24 C48,10.7454375 37.2553125,0 24,0 C10.7446875,0 0,10.7454375 0,24 Z"
          fill={darkFill}
        />
        <path
          d="M21.95,21.35 L16,24.75 L16,26.45 C16,27.8586625 16.85,29 17.7,29 L31.3,29 C32.15,29 33,27.8586625 33,26.45 L33,24.75 L27.05,21.35"
          fill={fill}
        />
        <path
          d="M20.25,16.675 C20.25,19.65 21.91855,22.2 24.5,22.2 C27.08145,22.2 28.75,19.65 28.75,16.675 C28.75,13.7 27.9,12 24.5,12 C21.1,12 20.25,13.7 20.25,16.675 Z"
          fill={fill}
        />
        <path
          d="M20.25,16.675 C20.25,19.65 21.91855,22.2 24.5,22.2 C27.08145,22.2 28.75,19.65 28.75,16.675 C28.75,13.7 27.9,12 24.5,12 C21.1,12 20.25,13.7 20.25,16.675 Z"
          stroke={stroke}
        />
        <path
          d="M21.95,21.35 L16,24.75 L16,26.45 C16,27.8586625 16.85,29 17.7,29 L31.3,29 C32.15,29 33,27.8586625 33,26.45 L33,24.75 L27.05,21.35"
          stroke={stroke}
        />
        <path
          d="M10.95,25.35 L5,28.75 L5,30.45 C5,31.8586625 5.85,33 6.7,33 L20.3,33 C21.15,33 22,31.8586625 22,30.45 L22,28.75 L16.05,25.35"
          fill={fill}
        />
        <path
          d="M9.25,20.675 C9.25,23.65 10.91855,26.2 13.5,26.2 C16.08145,26.2 17.75,23.65 17.75,20.675 C17.75,17.7 16.9,16 13.5,16 C10.1,16 9.25,17.7 9.25,20.675 Z"
          fill={fill}
        />
        <path
          d="M9.25,20.675 C9.25,23.65 10.91855,26.2 13.5,26.2 C16.08145,26.2 17.75,23.65 17.75,20.675 C17.75,17.7 16.9,16 13.5,16 C10.1,16 9.25,17.7 9.25,20.675 Z"
          stroke={stroke}
        />
        <path
          d="M10.95,25.35 L5,28.75 L5,30.45 C5,31.8586625 5.85,33 6.7,33 L20.3,33 C21.15,33 22,31.8586625 22,30.45 L22,28.75 L16.05,25.35"
          stroke={stroke}
        />
        <path
          d="M32.95,25.35 L27,28.75 L27,30.45 C27,31.8586625 27.85,33 28.7,33 L42.3,33 C43.15,33 44,31.8586625 44,30.45 L44,28.75 L38.05,25.35"
          fill={fill}
        />
        <path
          d="M31.25,20.675 C31.25,23.65 32.91855,26.2 35.5,26.2 C38.08145,26.2 39.75,23.65 39.75,20.675 C39.75,17.7 38.9,16 35.5,16 C32.1,16 31.25,17.7 31.25,20.675 Z"
          fill={fill}
        />
        <path
          d="M31.25,20.675 C31.25,23.65 32.91855,26.2 35.5,26.2 C38.08145,26.2 39.75,23.65 39.75,20.675 C39.75,17.7 38.9,16 35.5,16 C32.1,16 31.25,17.7 31.25,20.675 Z"
          stroke={stroke}
        />
        <path
          d="M32.95,25.35 L27,28.75 L27,30.45 C27,31.8586625 27.85,33 28.7,33 L42.3,33 C43.15,33 44,31.8586625 44,30.45 L44,28.75 L38.05,25.35"
          stroke={stroke}
        />
      </g>
    </svg>
  );
};

export default DefaultCompanyLogo;
