// using globalThis to get the appName
// what is globalThis?
// checkout MSDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/globalThis
export function getGlobalAppName(): string {
  if (typeof globalThis !== 'undefined') {
    // Check if globalThis has the property appName
    if (Object.prototype.hasOwnProperty.call(globalThis, 'appName')) {
      // Ensure appName is a string before returning it
      const appName = (globalThis as any).appName;
      if (typeof appName === 'string') {
        return appName;
      }
    }
  }
  return 'unknown';
}
