import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';

import sessionStorageEx from 'bundles/common/utils/sessionStorageEx';
import BlueJaysExperiments from 'bundles/epic/clients/BlueJays';

export type ChatTargetingRule = {
  /** urlPattern will be parsed as a RegExp.  If it matches the URL, the chat button will be visible. */
  urlPattern: string; // this can't be a RegExp because it comes from JSON

  /** initialActionId can be undefined, or a valid Boost action ID integer.  If specified, on the Boost side the very next step after this action must be to run the Read User Information process.  If undefined, chat will start at the default welcome message */
  initialActionId?: number;

  // In the future we may need params like this:
  // offsetBottomPx?: number; // See TOOL-1953. Override how far from the bottom of the screen.  Use case: the targeted page(s) have a lower right Help icon and you want to place the button on top of it
  // offsetRightPx?: number; // similar idea to above, but in case you want to put the chat icon to the left of the Help icon
  // delayMillisec?: number; // wait this amount of time before showing the chat button (e.g. we only want to show it if they might be stuck)
  // epicBoolParamNamespace and epicBoolParamKey optional strings // if both are set, the chat button will only show on the matched URLs if that epic param has a truthy value.  Enables more specific experiments compared to the overall BlueJays.enableChat param.
};

const CONVERSATION_ID_SESSION_KEY = 'chatConversationId';

export const getChatTargetingRuleSet = (): ChatTargetingRule[] =>
  BlueJaysExperiments.get('ChatConfig')?.targetingRules ?? [];

/**
 * Evaluates basic criteria for if the user is eligible for chat (e.g. EPIC check).  If they are, checks to see if chat is enabled for the given URL and returns the specifics of the matched page-level targeting rule.
 * @param url URL of page to check for rules (e.g. location.href)
 * @returns Undefined if the user isn't eligible or no rule was found for the current page.  Otherwise, returns the rule, which can optionally include information about how chat should be initialized.
 */
export const getChatTargetingRule = (url: string): ChatTargetingRule | undefined => {
  const userInfo = user.get();

  if (!userInfo || !userInfo.authenticated || !userInfo.id) {
    return undefined; // don't show chat to anonymous users
  }

  const isEnglishUser = !userInfo.locale || userInfo.locale.toLowerCase().indexOf('en') === 0;
  if (!isEnglishUser) {
    return undefined; // for now, chat is only available to English users
  }

  const isTargetedByEpic = BlueJaysExperiments.get('enableChat') ?? false;
  if (!isTargetedByEpic) {
    return undefined;
  }

  try {
    return getChatTargetingRuleSet().find((rule) => new RegExp(rule.urlPattern).test(url));
  } catch (err) {
    logger.error('Error with one or more chat targeting rules.  Check that all urlPattern regexes are valid.', err);
    return undefined;
  }
};

export const getActiveConversationId = (): string | undefined => {
  // TODO: TOOL-1925 Prefix the sessionStorage key with the current username.  Scenario: Someone might have an active conversation, log out and not close their browser tab, then pass their device to a family member.  The new user (or logged out user) should not resume the previous user's chat.
  // TODO: TOOL-1920 Use a chatStartTime timestamp stored in sessionStorage to ensure that the chat is not more than some age (e.g. 1 hour)
  return sessionStorageEx.getItem(CONVERSATION_ID_SESSION_KEY, (id) => id, undefined);
};

export const setConversationId = (conversationId?: string) => {
  if (!conversationId) {
    sessionStorageEx.removeItem(CONVERSATION_ID_SESSION_KEY);
    return;
  }
  sessionStorageEx.setItem(CONVERSATION_ID_SESSION_KEY, conversationId, String);
};
