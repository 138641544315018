import React from 'react';

import type {
  ForwardRefComponent,
  BaseComponentProps,
} from '@coursera/cds-common';

import { CloseButton } from '@core/dialogs/common/CloseButton';

import { useDialogContext } from './DialogContextProvider';
import { getHeaderCss, headerClasses } from './styles';

type HeaderType = ForwardRefComponent<HTMLDivElement, Props>;

type BaseProps = Omit<BaseComponentProps<'div'>, 'children'>;

export type Props = BaseProps & {
  /**
   * Slot for supplementary header content. `slot1` will always render on the
   * same row as the `Close` button.
   */
  slot1?: React.ReactNode;

  /**
   * Slot for supplementary header content. `slot2` will render on the same row
   * as the `Close` button on large breakpoints, and moves to the row below on
   * smaller breakpoints.
   *
   * If content for `slot1` is also provided, `slot2` will render between `slot1`
   * and the `Close` button on large breakpoints.
   */
  slot2?: React.ReactNode;
};

const styles = getHeaderCss();

const Header: HeaderType = React.forwardRef(function Header(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { slot1, slot2, ...rest } = props;

  const { onClose } = useDialogContext();

  return (
    <div ref={ref} css={styles} {...rest}>
      {slot1 && <div className={headerClasses.slot1}>{slot1}</div>}
      {slot2 && <div className={headerClasses.slot2}>{slot2}</div>}
      <CloseButton className={headerClasses.closeBtn} onClick={onClose} />
    </div>
  );
});

Header.displayName = 'FullscreenDialog.Header';

export default Header;
