import * as React from 'react';
import Media from 'react-media';

import _ from 'lodash';
import PropsTypes from 'prop-types';
import type BaseStore from 'vendor/cnpm/fluxible.v0-4/addons/BaseStore';

import { isRightToLeft } from 'js/lib/language';
import user from 'js/lib/user';

import { config } from 'bundles/browse/components/cds/browseTheme';
import type {
  DiscoveryCollection,
  DiscoveryCollectionEntity,
} from 'bundles/browse/components/types/DiscoveryCollections';
import {
  GRADUATE_CERTIFICATE_ENTITY_NAME,
  ONDEMAND_SPECIALIZATIONS_V1,
  POSTGRADUATE_DIPLOMA_ENTITY_NAME,
  PRODUCT_TYPE_NAMES,
  SCREEN_TABLET,
  UNIVERSITY_CERTIFICATE_ENTITY_NAME,
  productDifficultyLevels,
} from 'bundles/browse/constants';
import type { DegreeListsV3 } from 'bundles/browse/types/degree-list';
import GrowthExperiments from 'bundles/epic/clients/Growth';
import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';
import { MOBILE_BREAKPOINT_PX } from 'bundles/page-footer/components/global-footer/constants';
import { getCourseType } from 'bundles/program-common/utils/courseUtils';
import type {
  ProgramCurriculumDomainsQuery_ProgramCurriculumDomainsV1Resource_get_domains as Domain,
  ProgramCurriculumDomainsQuery_SubdomainsV1Resource_getAll_elements as Subdomain,
} from 'bundles/program-home/utils/__generated__/ProgramCurriculumDomainsQuery';
import { NormalS12n, ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';
import { getS12nVariantLabel, getSdpUrl } from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import { GUIDED_PROJECT, RHYME_PROJECT, SELF_PACED_PROJECT } from 'bundles/search-common/constants/entityTypes';
import type ApplicationStore from 'bundles/ssr/stores/ApplicationStore';

import _t from 'i18n!nls/browse';

type CourseTypeMetadata = {
  courseTypeMetadata: {
    __typename: string;
  };
};

export function filterUndefined<T>(item: T | undefined | null | false): item is T {
  return !!(item as T);
}

export const getCurrentPageFromRouter = (hasPageQuery: string) => {
  const queryPageNum = Number(hasPageQuery);
  const currentPage: number = queryPageNum > 1 ? queryPageNum : 1;
  return currentPage;
};

const disableBodyScrolling = () => {
  const bodyStyle = document?.body?.style;
  if (bodyStyle && window) {
    const nextScrollPosition =
      bodyStyle.position === 'fixed' ? document.body.dataset.yScrollPositionBeforeDisable : window.scrollY;
    document.body.dataset.yScrollPositionBeforeDisable = `${nextScrollPosition}`;
    bodyStyle.top = `-${nextScrollPosition}px`;
    bodyStyle.overflowY = 'scroll';
    bodyStyle.position = 'fixed';
    bodyStyle.width = '100%';
  }
};

const enableBodyScrolling = () => {
  const bodyStyle = document?.body?.style;
  if (bodyStyle && window) {
    const stylePropertiesToRemove = ['overflow-y', 'position', 'top', 'width'];
    stylePropertiesToRemove.forEach((propName) => bodyStyle.removeProperty(propName));
    if (document.body.dataset.yScrollPositionBeforeDisable) {
      const prevScrollYPosition = parseInt(document.body.dataset.yScrollPositionBeforeDisable, 10);
      window.scrollTo(0, prevScrollYPosition);
    }
  }
};

export const disableScrolling = (shouldBeDisabled: boolean): void => {
  if (shouldBeDisabled) {
    disableBodyScrolling();
  } else {
    enableBodyScrolling();
  }
};

type Context = {
  getStore?: (storeName: string) => BaseStore;
};

export type PropsFromWithMediaMaxWidthScreenTablet = {
  matches: boolean;
};

export const withMediaMaxWidthScreenTablet = <TProps extends {} = {}>(
  Component: React.ComponentType<TProps & PropsFromWithMediaMaxWidthScreenTablet>
) => {
  const MediaHOC: React.FunctionComponent<TProps> = (props: TProps, { getStore }: Context) => {
    const applicationStore = getStore && (getStore('ApplicationStore') as ApplicationStore);
    const userAgent = applicationStore?.getUserAgent();
    return (
      <Media query={{ maxWidth: SCREEN_TABLET }} defaultMatches={userAgent?.isMobileBrowser}>
        {(matches: boolean) => <Component {...props} matches={matches} />}
      </Media>
    );
  };
  MediaHOC.contextTypes = {
    getStore: PropsTypes.func,
  };
  return MediaHOC;
};

export type PropsFromWithMediaMinWidthMobile = {
  matches: boolean;
};

export const withMediaMinWidthMobile = <TProps extends {} = {}>(
  Component: React.ComponentType<TProps & PropsFromWithMediaMinWidthMobile>
): React.ComponentType<TProps> => {
  const MediaHOC: React.FunctionComponent<TProps> = (props, { getStore }: Context) => {
    const applicationStore = getStore && (getStore('ApplicationStore') as ApplicationStore);
    const userAgent = applicationStore?.getUserAgent();
    return (
      <Media query={{ minWidth: MOBILE_BREAKPOINT_PX }} defaultMatches={!userAgent?.isMobileBrowser}>
        {(matches) => <Component {...props} matches={matches} />}
      </Media>
    );
  };
  MediaHOC.contextTypes = {
    getStore: PropsTypes.func,
  };
  return MediaHOC;
};

export const disableMobileScrolling = (() => {
  let scrollYPosition = 0;
  return (disabled: boolean) => {
    const bodyStyle = document && document.body && document.body.style;
    if (disabled && bodyStyle) {
      scrollYPosition = window.scrollY;
      bodyStyle.position = 'fixed';
    } else if (bodyStyle) {
      bodyStyle.position = '';
      window.scrollTo(0, scrollYPosition);
    }
  };
})();

export const isProductS12n = (
  product: Partial<{
    id: string;
    __typename: string;
    onDemandSpecializationId: string | null;
  }>
): boolean => {
  return product.id === product.onDemandSpecializationId || product.__typename === ONDEMAND_SPECIALIZATIONS_V1;
};

export const generateMegaMenuDomainUrl = ({
  currentProgramSlug,
  domainSlug,
  subdomainSlug,
}: {
  currentProgramSlug?: string;
  domainSlug?: string;
  subdomainSlug?: string | null;
}) => {
  let generatedUrl = '/browse';

  if (currentProgramSlug) generatedUrl = `/programs/${currentProgramSlug}`;
  if (domainSlug) generatedUrl += `/${domainSlug}`;
  if (domainSlug && subdomainSlug && domainSlug !== subdomainSlug) generatedUrl += `/${subdomainSlug}`;

  return generatedUrl;
};

export type DomainWithSubdomain = Domain & {
  subdomains: {
    elements: Array<Subdomain>;
  };
};

export function injectSubdomainsToDomains(domains: Domain[], subdomains: Subdomain[]): DomainWithSubdomain[] {
  return _.map(domains, (domain): DomainWithSubdomain => {
    return {
      ...domain,
      subdomains: {
        elements: _.filter(subdomains, (subdomain) => domain.subdomainIds.includes(subdomain.id)),
      },
    };
  });
}

export const getProductDifficultyLevelsTranslated = (key: string) => {
  const translations = {
    [productDifficultyLevels.INTERMEDIATE.toUpperCase()]: _t('Intermediate'),
    [productDifficultyLevels.BEGINNER.toUpperCase()]: _t('Beginner'),
    [productDifficultyLevels.ADVANCED.toUpperCase()]: _t('Advanced'),
    [productDifficultyLevels.MIXED.toUpperCase()]: _t('Mixed'),
  };
  return translations[key] || key;
};

const capitalizeFirstLetterEachWord = (str: string | undefined): string | undefined => {
  if (!str) {
    return undefined;
  }
  return str.replace(/\w\S*/g, (word) => word.replace(/^\w/, (char) => char.toUpperCase()));
};

const getProductDisplayTypeName = (productTypeName?: string | null) => {
  if (productTypeName === SELF_PACED_PROJECT) {
    return PRODUCT_TYPE_NAMES.SELF_PACED_PROJECT;
  } else if (productTypeName === GUIDED_PROJECT || productTypeName === RHYME_PROJECT) {
    return PRODUCT_TYPE_NAMES.GUIDED_PROJECT;
  }
  return productTypeName;
};

const abbreviateLargeNumber = (num: number, locale?: string): string => {
  // eslint-disable-next-line new-cap
  return Intl.NumberFormat(locale, { notation: 'compact' }).format(num);
};

export const getTranslatedProductName = (productType?: string | null) => {
  const productName = getProductDisplayTypeName(productType);
  const productNameAllCapitalize = productName?.toUpperCase() || productName;
  const prettyProductName = capitalizeFirstLetterEachWord(productName?.toLowerCase());
  switch (productNameAllCapitalize) {
    case PRODUCT_TYPE_NAMES.COURSE:
      return _t('Course');
    case PRODUCT_TYPE_NAMES.GUIDED_PROJECT:
      return _t('Guided Project');
    case PRODUCT_TYPE_NAMES.SELF_PACED_PROJECT:
      return _t('Project');
    case PRODUCT_TYPE_NAMES.SPECIALIZATION:
      return _t('Specialization');
    case PRODUCT_TYPE_NAMES.CERTIFICATE:
      return _t('Professional Certificate');
    case PRODUCT_TYPE_NAMES.MASTERTRACK:
      return isRightToLeft(_t.getLocale())
        ? _t('#{R}MasterTrack Certificate', { R: String.fromCharCode(174) })
        : _t('MasterTrack#{R} Certificate', { R: String.fromCharCode(174) });
    case PRODUCT_TYPE_NAMES.DEGREE:
      return _t('Degree');
    case POSTGRADUATE_DIPLOMA_ENTITY_NAME:
      return _t('Post Graduate');
    case GRADUATE_CERTIFICATE_ENTITY_NAME:
      return _t('Graduate Certificate');
    case UNIVERSITY_CERTIFICATE_ENTITY_NAME:
      return _t('University Certificate');
    default:
      return prettyProductName || productName;
  }
};

export const getProductCardDisplayProps = (
  product: {
    isRhymeProject?: boolean;
    courseIds?: string[];
    courseTypeMetadata?: CourseTypeMetadata | null;
    productVariant?: string;
    slug?: string;
    views?: number;
  },
  isS12n?: boolean,
  isCDS?: boolean
): {
  cardHref?: string;
  label: string | JSX.Element;
  labelAsText: string;
  gradient?: {
    deg: number;
    start: string;
    end: string;
  };
} => {
  const courseType = product?.courseTypeMetadata && getCourseType(product.courseTypeMetadata);
  let label: string | JSX.Element;
  let labelAsText: string;
  let gradient: { deg: number; start: string; end: string } | undefined;
  let cardHref;

  // Order matters - currently Professional Certificates live on the s12n model.
  if (isS12n) {
    const s12nLabel = getS12nVariantLabel(product.productVariant);
    gradient = {
      deg: 135,
      start: '#046082',
      end: '#046082',
    };
    cardHref = product?.slug && product?.productVariant && getSdpUrl(product.productVariant, product.slug);

    switch (product.productVariant) {
      case ProfessionalCertificateS12n:
        label = s12nLabel;
        labelAsText = s12nLabel;
        if (isCDS) {
          gradient = {
            deg: 135,
            start: config.colors.blue900,
            end: config.colors.blue900,
          };
        }
        break;
      case NormalS12n:
      default:
        if (product.courseIds && product.courseIds.length > 0) {
          label = _t('#{specialization} (#{numCourses} Courses)', {
            numCourses: product.courseIds.length,
            specialization: s12nLabel,
          });
        } else {
          label = s12nLabel;
        }
        labelAsText = label;
        if (isCDS) {
          gradient = {
            deg: 135,
            start: config.colors.purple800,
            end: config.colors.purple800,
          };
        }
        break;
    }
  } else if (product.isRhymeProject || courseType === 'GuidedProject') {
    label = _t('Guided Project');
    labelAsText = label;
    cardHref = product?.slug && `/projects/${product.slug}`;
  } else if (courseType === 'Project') {
    labelAsText = _t('Project');
    label = <span style={{ color: '#1859AA' }}>{labelAsText}</span>;
    cardHref = product?.slug && `/projects/${product.slug}`;
  } else if (product.productVariant === 'clip') {
    labelAsText = product.views
      ? _t('Video · #{viewCountText} Views', { viewCountText: abbreviateLargeNumber(product.views, _t.getLocale()) })
      : _t('Video');
    label = labelAsText;
    cardHref = product?.slug && `/videos/${product.slug}`;
  } else {
    label = _t('Course');
    labelAsText = label;
    cardHref = product?.slug && `/learn/${product.slug}`;
  }

  return {
    cardHref,
    label,
    labelAsText,
    gradient,
  };
};

const typeNameToS12nProductVariant = (__typename: string) => {
  switch (__typename) {
    case 'DiscoveryCollections_professionalCertificate':
      return ProfessionalCertificateS12n;
    case 'DiscoveryCollections_specialization':
      return NormalS12n;
    default:
      return undefined;
  }
};

export const getDiscoverCollectionProductCardDisplayProps = (
  product: DiscoveryCollectionEntity,
  isCDS?: boolean
): {
  cardHref?: string;
  label: string | JSX.Element;
  labelAsText: string;
  gradient?: {
    deg: number;
    start: string;
    end: string;
  };
} => {
  const isGuidedProject = product.__typename === 'DiscoveryCollections_guidedProject';
  const isProject = product.__typename === 'DiscoveryCollections_project';
  const s12nProductVariant = typeNameToS12nProductVariant(product.__typename);

  let label: string | JSX.Element;
  let labelAsText: string;
  let gradient: { deg: number; start: string; end: string } | undefined;
  let cardHref: string;

  if (s12nProductVariant) {
    const s12nLabel = getS12nVariantLabel(s12nProductVariant);
    cardHref = getSdpUrl(s12nProductVariant, product.slug);
    gradient = {
      deg: 135,
      start: '#046082',
      end: '#046082',
    };

    switch (s12nProductVariant) {
      case ProfessionalCertificateS12n:
        label = s12nLabel;
        labelAsText = label;
        if (isCDS) {
          gradient = {
            deg: 135,
            start: config.colors.blue900,
            end: config.colors.blue900,
          };
        }
        break;
      case NormalS12n:
      default:
        label = _t('#{specialization} (#{numCourses} Courses)', {
          numCourses: product.__typename === 'DiscoveryCollections_specialization' && product.courseCount,
          specialization: s12nLabel,
        });
        labelAsText = label;
        if (isCDS) {
          gradient = {
            deg: 135,
            start: config.colors.purple800,
            end: config.colors.purple800,
          };
        }
        break;
    }
  } else if (isGuidedProject) {
    labelAsText = _t('Guided Project');
    label = <span style={{ color: '#1859AA' }}>{labelAsText}</span>;
    cardHref = `/projects/${product.slug}`;
  } else if (isProject) {
    labelAsText = _t('Project');
    label = <span style={{ color: '#1859AA' }}>{labelAsText}</span>;
    cardHref = `/projects/${product.slug}`;
  } else {
    label = _t('Course');
    labelAsText = label;
    cardHref = `/learn/${product.slug}`;
  }

  return {
    cardHref,
    label,
    labelAsText,
    gradient,
  };
};

export const retrieveGuidedProjectsUrl = ({ domainSlug }: { domainSlug: string }) =>
  GrowthExperiments.get('domainToGuidedProjectsMapping')[domainSlug];

export const indexToInsertMastertrackCollection = (collections: ({ id?: string } | DegreeListsV3 | undefined)[]) => {
  const indexOfGuidedProject = collections.findIndex(
    (collection) => collection && 'id' in collection && collection?.id?.includes('Rhyme')
  );
  const indexOfMostPopularCourses = collections.findIndex(
    (collection) => collection && 'id' in collection && collection?.id?.includes('mostPopularByEnrollments')
  );
  const indexToInsert =
    (indexOfGuidedProject >= 0 && indexOfGuidedProject + 1) ||
    (indexOfMostPopularCourses >= 0 && indexOfMostPopularCourses + 1);
  return indexToInsert || collections.length;
};

// For use with new DiscoveryCollections resource.
export const indexToInsertMastertracksInDiscoveryCollections = (
  collections: (DiscoveryCollection | DegreeListsV3 | null)[]
) => {
  const indexOfGuidedProject = collections.findIndex((collection) => collection?.label?.includes('Guided Projects'));
  const indexOfMostPopularCourses = collections.findIndex(
    (collection) => collection && 'id' in collection && collection?.id?.includes('mostPopularByEnrollments')
  );
  const indexToInsert =
    (indexOfGuidedProject >= 0 && indexOfGuidedProject + 1) ||
    (indexOfMostPopularCourses >= 0 && indexOfMostPopularCourses + 1);
  return indexToInsert || collections.length;
};

export const indexToInsertUcertsInDiscoveryCollections = (
  collections: (DiscoveryCollection | DegreeListsV3 | null)[],
  searchTerm: string
) => {
  const indexOfMostPopularCourses = collections.findIndex((collection) => collection?.id?.includes(searchTerm));

  const indexToInsert = indexOfMostPopularCourses >= 0 && indexOfMostPopularCourses + 1;
  return indexToInsert || collections.length;
};

export const retrieveCareerPathCLPAnchorLinks = ({ learningPathSlug }: { learningPathSlug: string }) =>
  GrowthExperiments.get('careerLearningtoCLPAnchorMapping')[learningPathSlug];

export const isInPersonalizedBrowseExperiment = () => {
  // We have two experiments for the personalized browse experiment. One for logged-in and one for logged-out.
  // They are effectively the same, but makes it easier for data science to parse results.
  if (user.isAuthenticatedUser()) {
    return GrowthDiscoveryExperiments.get('showNewRecsOnLIDomain');
  } else {
    return GrowthDiscoveryExperiments.get('showNewRecsOnLODomain');
  }
};

// For the personalized browse pages experiment, we change the context ID depending on experiment variant.
export const getContextIdForPersonalizedBrowseExperiment = (contextId: string) => {
  const isInExperiment = isInPersonalizedBrowseExperiment();

  // For every collection set on browse, we have an experiment version that includes some new realtime collections.
  // The experiment version has the same exact context ID, except it adds the suffix 'realtime'.
  // We are testing between the default contextId and the 'realtime' version.
  if (isInExperiment) {
    return contextId + '-realtime';
  } else {
    return contextId;
  }
};
