import gql from 'graphql-tag';

/* eslint-disable graphql/template-strings */
export const getTopCertificates = gql`
  query TopCertificatesQuery {
    TopCertificatesQueryResponse
      @rest(
        type: "TopCertificatesQueryResponse"
        path: "grpc/degreemarketing/unifiedlists/v1beta1/CertificateListsAPI/GetCertificateList"
        method: "GET"
      ) {
      certificateList
    }
  }
`;

/*
This query expects an argument for the entire query param string:
Example: productVariants=program-type

If you want more than one product type, you'll need to add multiple query params of the same property.
This is a requirement of using the google.api.http lib for gRPC queries.
Source: https://github.com/googleapis/googleapis/blob/7cde5d0df08801e00fc45df1546942aa2692d5c3/google/api/http.proto#L119

For multiple product variants, the argument value would like the following:
Example: productVariants=program-type1&productVariants=program-type2
*/
export const getCertificatesByProductVariant = gql`
  query getCertificatesByProductVariantQuery($productVariantsQueryParam: String!) {
    CertificatesByProductVariantResponse(productVariantsQueryParam: $productVariantsQueryParam)
      @rest(
        type: "CertificatesByProductVariantResponse"
        path: "grpc/degreemarketing/unifiedlists/v1beta1/CertificateListsAPI/GetCertificateList?{args.productVariantsQueryParam}"
        method: "GET"
      ) {
      certificateList
    }
  }
`;

export const getCertificatesByCategory = gql`
  query getCertificatesByCategoryQuery($category: String!) {
    CertificatesByCategoryResponse(category: $category)
      @rest(
        type: "CertificatesByCategoryResponse"
        path: "grpc/degreemarketing/unifiedlists/v1beta1/CertificateListsAPI/GetCertificateList?category={args.category}"
        method: "GET"
      ) {
      certificateList
    }
  }
`;
