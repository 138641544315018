import type AdminPermission from 'bundles/admin/utils/AdminPermission';

import _t from 'i18n!nls/admin-v2';

export const BASE_PATH = '/admin-v2';
export const ADMIN_V1_ROOT = '/admin/courses';

export const AdminRouteNames = {
  ROOT: 'admin-v2-root',
  HOME: 'home',
  COURSES: 'courses',
  PROGRAMS: 'programs',
  SPECIALIZATIONS: 'specializations',
  PROJECTS: 'projects',
  LEARNERS: 'learners',
  ANALYTICS: 'analytics',
  INSTITUTION: 'institution',
  SETTINGS: 'settings',
  ACCOUNT: 'account',
  PEOPLE: 'people',
  PROMOTIONS: 'promotions',
  MY_GROUP: 'groups',
  HELP: 'help',
} as const;

export default AdminRouteNames;

export type AdminRouteNamesValues = (typeof AdminRouteNames)[keyof typeof AdminRouteNames];

type AdminRoute = {
  isHidden: boolean;
  href?: string;
  name: string;
  target: string;
  trackingName?: string;
  subItems?: Array<{
    name: string;
    target: string;
  }>;
  openInNewWindow?: boolean;
};

export const getAdminV2HomeRoutes = (adminPermission: AdminPermission) => {
  return [
    {
      target: AdminRouteNames.COURSES,
      name: _t('Courses'),
      isHidden: !adminPermission.canViewCourses(),
      trackingName: 'admin_courses',
      subItems: [],
    },
    {
      target: AdminRouteNames.PROJECTS,
      name: _t('Projects'),
      isHidden: !adminPermission.canViewCourses(),
      trackingName: 'admin_projects',
      subItems: [],
    },
    {
      target: AdminRouteNames.PROGRAMS,
      name: _t('Programs'),
      isHidden: !adminPermission.canViewDegrees(),
      trackingName: 'admin_programs',
      subItems: [],
    },
    {
      target: AdminRouteNames.SPECIALIZATIONS,
      name: _t('Specializations'),
      isHidden: !adminPermission.canViewS12n(),
      trackingName: 'admin_specializations',
      subItems: [],
    },
  ];
};

export const getAdminV2Routes = (
  shortName: string | undefined,
  adminPermission: AdminPermission
): Array<AdminRoute> => {
  if (!shortName) return [];

  return [
    {
      target: AdminRouteNames.HOME,
      href: `${BASE_PATH}/${shortName}/${AdminRouteNames.HOME}`,
      name: _t('Home'),
      isHidden: false,
      trackingName: 'admin_left_nav_home',
    },
    {
      target: AdminRouteNames.ANALYTICS,
      href: `${BASE_PATH}/${shortName}/${AdminRouteNames.ANALYTICS}`,
      name: _t('Analytics'),
      isHidden: !adminPermission.canViewAnalytics(),
      trackingName: 'admin_left_nav_analytics',
    },
    {
      target: AdminRouteNames.INSTITUTION,
      href: `${BASE_PATH}/${shortName}/${AdminRouteNames.INSTITUTION}`,
      name: _t('Institution'),
      isHidden: !adminPermission.canViewInstitutions(),
      trackingName: 'admin_left_nav_institution',
    },
    {
      target: AdminRouteNames.PROMOTIONS,
      href: `${BASE_PATH}/${shortName}/${AdminRouteNames.PROMOTIONS}`,
      name: _t('Messages'),
      isHidden: !adminPermission.canViewInstitutions(),
      trackingName: 'admin_left_nav_messages',
    },
    {
      target: AdminRouteNames.HELP,
      href: 'https://partner.coursera.help/hc/',
      name: _t('Help'),
      isHidden: false,
      trackingName: 'admin_left_nav_help',
      openInNewWindow: true,
    },
  ];
};

export const {
  ROOT,
  HOME,
  COURSES,
  PROGRAMS,
  SPECIALIZATIONS,
  LEARNERS,
  PROMOTIONS,
  ANALYTICS,
  INSTITUTION,
  SETTINGS,
  ACCOUNT,
  MY_GROUP,
} = AdminRouteNames;
