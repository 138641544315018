import type React from 'react';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import Button from '@core/Button';

import { classes } from './getContextualHelpCss';
import i18nMessages from './i18n';

export type ContextualHelpTooltipProps = {
  /**
   * Help content for the tooltip
   */
  children?: React.ReactNode;

  /**
   * Optional CTA that renders next to the Close button. For example, a "Learn More" link.
   */
  cta?: React.ReactNode;

  /**
   * Additional properties on the Close button
   */
  closeButtonProps?: {
    'aria-label': string;
  };
};

const ContextualHelpTooltip = (
  props: ContextualHelpTooltipProps & { onClose: () => void }
) => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  return (
    <div className={classes.tooltipContainer}>
      <div className={classes.tooltipContent}>{props.children}</div>
      <div className={classes.tooltipActions}>
        {/* 
            The close button is rendered after tooltipCta but flex direction is reversed in css
            to maintain tab order. This way the Close button visually appears first, but is tabbed
            last in case there is a tooltipCta that needs to receive focus first.
        */}
        <div className={classes.tooltipCta}>{props.cta}</div>
        <Button
          aria-label={props.closeButtonProps?.['aria-label']}
          className={classes.tooltipCloseButton}
          edgeAlign="start"
          icon={<CloseIcon size="small" />}
          iconPosition="before"
          size="small"
          variant="ghost"
          onClick={props.onClose}
        >
          {stringFormatter.format('close')}
        </Button>
      </div>
    </div>
  );
};

export default ContextualHelpTooltip;
