export const surveyUrls = {
  homePage: {
    US: {
      loggedIn: 'https://www.surveymonkey.com/r/SM7P6K2?',
      loggedOut: 'https://www.surveymonkey.com/r/ST36WPH?',
    },
    GB: {
      loggedIn: 'https://www.surveymonkey.com/r/YY5B8G8?',
      loggedOut: 'https://www.surveymonkey.com/r/YY7SLQF?',
    },
    IN: {
      loggedIn: '',
      loggedOut: 'https://www.surveymonkey.com/r/SFJ98L5?',
    },
  },
  degreesHub: {
    US: {
      loggedIn: 'https://www.surveymonkey.com/r/SXJG8QM?',
      loggedOut: 'https://www.surveymonkey.com/r/SMZWNHC?',
    },
    GB: {
      loggedIn: 'https://www.surveymonkey.com/r/Y2H3655?',
      loggedOut: 'https://www.surveymonkey.com/r/Y22M82N?',
    },
    IN: {
      loggedIn: '',
      loggedOut: '',
    },
  },
  XDP: {
    US: {
      loggedIn: '',
      loggedOut: 'https://www.surveymonkey.com/r/coursera_feedback_survey?',
    },
    GB: {
      loggedIn: '',
      loggedOut: '',
    },
    IN: {
      loggedIn: '',
      loggedOut: 'https://www.surveymonkey.com/r/coursera_feedback_survey?',
    },
  },
} as const;
