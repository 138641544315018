/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';

import { isRightToLeft } from 'js/lib/language';

import { ButtonBase, FormLabel, Typography2, useFocusRing, useId } from '@coursera/cds-core';
import type { ButtonBaseProps } from '@coursera/cds-core';

import Tooltip from 'bundles/common/components/Tooltip/FloatingTooltip';

import _t from 'i18n!nls/cds-labs';

export type Props = {
  /**
   * Current state of the switch. Use this prop for controlled component or to set default state.
   *
   * @default false
   */
  selected?: boolean;

  /** Visual label that describes the switch setting being toggled.
   *  Recommended to have a visual label tied to the toggle, but if you absolutely
   *  need to hide it then please make sure to pass `aria-label` instead.
   */
  label?: string;

  /** Additional support text that further describes the label  */
  supportText?: React.ReactNode;

  /** Callback when the switch is toggled */
  onToggle?: (isSelected: boolean) => void;

  /**
   * Read-only version of the switch. Toggle is still focusable for screen-reader.
   * @default false
   */
  disabled?: boolean;

  /** Highly recommended to pass aria-label when using standalone Switch i.e. not passing a `label`. */
  'aria-label'?: string;

  /** Pass this when using custom labels. */
  'aria-labelledby'?: string;
} & Omit<ButtonBaseProps, 'children'>;

const styles = {
  root: (isRTL: boolean) => css`
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: flex-start;

    /* toggle is rendered first in DOM for a11y, this reverses visual order to show label first */
    flex-direction: row-reverse;

    &.standalone {
      display: inline;
    }

    .switch-label {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .switch-toggle {
      position: relative;
      border-radius: 12px;
      height: 24px;
      width: 40px;
      display: flex;
      align-items: center;
      transition: background 0.1s ease-in-out;

      .switch-indicator {
        background: white;
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        transition: left 0.1s ease-in-out;
        left: ${isRTL ? 'calc(100% - 20px - 2px)' : '2px'};
      }

      &:focus,
      &:hover {
        cursor: pointer;
      }
    }

    .cds-buttonBase-focusVisible::after {
      border-radius: var(--cds-border-radius-200) !important;
    }

    &.switch-on {
      .switch-toggle {
        background: var(--cds-color-interactive-primary);

        .switch-indicator {
          left: ${isRTL ? '2px' : 'calc(100% - 20px - 2px)'};
        }
      }
    }

    &.switch-off {
      .switch-toggle {
        background: var(--cds-color-neutral-disabled-strong);
      }
    }

    &.disabled {
      .switch-toggle {
        background: var(--cds-color-grey-100);
        cursor: not-allowed;
      }
    }
  `,
};

/**
 * Switch input component allows users to toggle an individual setting ON or OFF.
 *
 * Use `<Switch>` when you need the update to be executed immediately. e.g. "Enable dark mode"
 * to instantly update the UI when toggled, or saving a preference to BE.
 *
 * Use `<Checkbox>` instead when the selection is an option and there is a subsequent action
 * that actually executes this selection. e.g. Selecting an option in a quiz and then submitting the quiz.
 */
export const Switch = (props: Props): React.ReactElement => {
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    supportText = '',
    className = '',
    selected = false,
    label,
    disabled = false,
    onToggle,
  } = props;

  const { focusProps } = useFocusRing();
  const [toggleState, setToggleState] = useState<boolean>(selected);

  const handleToggle = () => {
    if (disabled) {
      return;
    }

    const newState = !toggleState;
    onToggle?.(newState);
    setToggleState(newState);
  };

  React.useEffect(() => {
    setToggleState(selected);
  }, [selected]);

  const baseId = useId();
  const labelId = `${baseId}-label`;
  const switchId = `${baseId}-switch`;

  return (
    <div
      className={classNames('cds-labs-Switch', className, {
        disabled,
        'switch-on': toggleState,
        'switch-off': !toggleState,
        standalone: !label,
      })}
      css={styles.root(isRightToLeft(_t.getLocale()))}
    >
      <Tooltip message={toggleState ? _t('On') : _t('Off')} placement="bottom">
        <ButtonBase
          id={switchId}
          role="switch"
          type="button"
          className="switch-toggle"
          aria-checked={toggleState}
          aria-labelledby={ariaLabelledBy ?? labelId}
          aria-label={ariaLabel}
          onClick={handleToggle}
          aria-disabled={disabled}
          {...focusProps}
        >
          <span className="switch-indicator" />
        </ButtonBase>
      </Tooltip>
      {label ? (
        <FormLabel className="switch-label" id={labelId}>
          <Typography2 variant="subtitleMedium" component="div">
            {label}
          </Typography2>

          {supportText ? (
            <Typography2 variant="bodySecondary" color="supportText" component="div">
              {supportText}
            </Typography2>
          ) : null}
        </FormLabel>
      ) : null}
    </div>
  );
};

export default Switch;
