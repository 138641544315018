import _ from 'underscore';

import { getParsedNumber } from 'bundles/expression-of-interest/utils/PhoneUtils';
import { ResponseTypes, ValidationInputTypes } from 'bundles/survey-form/constants/SurveyFormConstants';
import type { ValidationInputType } from 'bundles/survey-form/constants/SurveyFormConstants';
import {
  getAnswerText,
  getAnswerType,
  getQuestionType,
  getResponseTypeFromQuestionType,
  getValidationTypes,
} from 'bundles/survey-form/utils/SurveyFormUtils';

import _t from 'i18n!nls/survey-form';

const { NUMERIC_RESPONSE } = ResponseTypes;

/*
In order to create a new validation type,
  1. Add the constant to bundles/survey-form/constants/SurveyFormConstants
  2. Add the constant name below
  3. Add an error message to ValidationErrorMessages below
  4. Add a regex to ValidationRegexes below
  5. Add unit tests to ./__tests__/ValidationUtils.js
Note that this pattern only handles regex validations.
*/
const {
  EMAIL_VALIDATION,
  PHONE_VALIDATION,
  HEX_COLOR_VALIDATION,
  SLUG_VALIDATION,
  URL_VALIDATION,
  NAME_VALIDATION,
  TEXT_LENGTH_VALIDATION,
} = ValidationInputTypes;

export const validationErrorMessages = (fieldName?: $TSFixMe) => ({
  RequiredErrorMessageForFieldName: _t('#{fieldName} is required', { fieldName }),
  RequiredErrorMessage: _t('This item is required'),
  RequiredFieldErrorMessage: _t('This field is required'),
  InvalidNumericInputMessage: _t('Please enter a number'),

  ValidationErrorMessages: {
    [EMAIL_VALIDATION]: _t('Please enter a valid email address'),
    [PHONE_VALIDATION]: _t('Please enter a valid phone number'),
    [HEX_COLOR_VALIDATION]: _t('Please enter a "#" sign followed by valid three or six digit hex color code'),
    [SLUG_VALIDATION]: _t('Please enter a valid slug using only letters, numbers, and dashes'),
    [URL_VALIDATION]: _t('Please enter a valid URL'),
    [NAME_VALIDATION]: _t('Please enter a valid name between 3 and 150 characters'),
  },
});

const getTextLengthErrorMessage = (bounds: $TSFixMe) => {
  if (bounds?.upperBound && bounds?.lowerBound) {
    return _t('Please enter an input that stays between the bounds of #{lower} and #{upper} characters', {
      lower: bounds.lowerBound,
      upper: bounds.upperBound,
    });
  } else if (bounds?.upperBound) {
    return _t('Please enter an input that does not exceed the #{upper} character limit', {
      upper: bounds.upperBound,
    });
  } else if (bounds?.lowerBound) {
    return _t('Please enter an input that exceeds the #{lower} character minimum', {
      lower: bounds.lowerBound,
    });
  } else {
    return _t('Please enter an input that stays between the character limit bounds');
  }
};

const ValidationUtils = {
  ValidationRegexes: {
    [EMAIL_VALIDATION]:
      /^[a-zA-Z0-9!#$%&'*+\/=?\^_`{|}~\-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?\^_`{|}~\-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-]*[a-zA-Z0-9])?$/,
    [PHONE_VALIDATION]: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    [HEX_COLOR_VALIDATION]: /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/,
    [SLUG_VALIDATION]: /^[a-zA-Z0-9-]+$/,
    [URL_VALIDATION]:
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    [NAME_VALIDATION]: /^.{1,150}$/,
    [TEXT_LENGTH_VALIDATION]: /.*/,
  },

  // returns true if input is valid
  validateNumericInput(input: $TSFixMe) {
    return !Number.isNaN(parseFloat(input));
  },

  validateTextLengthBound(bounds: $TSFixMe, input: $TSFixMe) {
    let validLength = false;

    if (bounds?.upperBound) {
      validLength = input.length <= bounds.upperBound;
    }
    if (bounds?.lowerBound) {
      validLength = input.length >= bounds.lowerBound;
    }

    return validLength;
  },

  validatePhoneInput(input: string): boolean {
    const parsedNumber = getParsedNumber(input) || getParsedNumber('+' + input);
    const isPhoneInputValid = !!parsedNumber;

    return isPhoneInputValid;
  },

  // returns true if input is valid
  validateByRegex(validation: ValidationInputType, input: string): boolean {
    const isValidInput = ValidationUtils.ValidationRegexes[validation].test(input);

    if (isValidInput && validation === PHONE_VALIDATION) {
      // validate if phone input is a possible number
      return ValidationUtils.validatePhoneInput(input);
    }

    return isValidInput;
  },

  getFirstValidationMessage(validations: $TSFixMe, answerText: $TSFixMe) {
    const index = _.findIndex(validations, (validation) => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      if (validation.validationType === TEXT_LENGTH_VALIDATION) {
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        return answerText && !ValidationUtils.validateTextLengthBound(validation.validationDetails, answerText);
      }

      // answerText must be truthy (i.e. empty value is okay since these responses are not required)
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      return answerText && !ValidationUtils.validateByRegex(validation.validationType, answerText);
    });

    if (index > -1) {
      const validation = validations[index];
      return validation.validationType === TEXT_LENGTH_VALIDATION
        ? getTextLengthErrorMessage(validation.validationDetails)
        : // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          validationErrorMessages().ValidationErrorMessages[validation.validationType];
    } else {
      return undefined;
    }
  },

  getValidateMessage(question: $TSFixMe, answer: $TSFixMe) {
    const key = getQuestionType(question);
    const responseKey = getResponseTypeFromQuestionType(key);
    const fieldName = question.content[key].prompt;
    // Empty string should not be accepted for required field
    if (question.isRequired && (answer?.[responseKey]?.text === '' || !answer)) {
      if (!fieldName || responseKey === ResponseTypes.DROPDOWN_RESPONSE) {
        return validationErrorMessages().RequiredFieldErrorMessage;
      }
      return validationErrorMessages(fieldName).RequiredErrorMessageForFieldName;
    }
    const answerType = getAnswerType(answer);
    if (answerType === NUMERIC_RESPONSE && !ValidationUtils.validateNumericInput(getAnswerText(answer))) {
      return validationErrorMessages(fieldName).InvalidNumericInputMessage;
    }
    const validations = getValidationTypes(question);
    const answerText = getAnswerText(answer);
    return ValidationUtils.getFirstValidationMessage(validations, answerText);
  },

  validateResponse(sectionData: $TSFixMe, response: $TSFixMe) {
    const validationErrors = {};
    const { questions } = sectionData;
    _(questions).each((question) => {
      const questionId = question.id;
      const answer = response[questionId];
      const validationError = ValidationUtils.getValidateMessage(question, answer);
      if (validationError) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        validationErrors[questionId] = validationError;
      }
    });
    return validationErrors;
  },
};

export default ValidationUtils;

export const {
  ValidationRegexes,
  validateNumericInput,
  validateByRegex,
  getFirstValidationMessage,
  getValidateMessage,
  validateResponse,
} = ValidationUtils;
