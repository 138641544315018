/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import ErrorBoundaryWithTagging from 'bundles/common/components/ErrorBoundaryWithTagging';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import DegreeHubScrollAnchor from 'bundles/premium-hub/components/degrees/DegreeHubScrollAnchor';
import DegreesHubBanner from 'bundles/premium-hub/components/degrees/DegreesHubBanner';
import HeroBanner from 'bundles/premium-hub/components/degrees/HeroBanner';
import { SearchContainer } from 'bundles/premium-hub/components/search/SearchContainer';
import HubBanner from 'bundles/premium-hub/components/shared/HubBanner';
import InlineNavigation from 'bundles/premium-hub/components/shared/InlineNavigation';
import type { HubModuleValue } from 'bundles/premium-hub/constants';
import { HUB_MODULES, HubPages, ProductType } from 'bundles/premium-hub/constants';
import type { PropsFromHubPageContent } from 'bundles/premium-hub/types';
import { getSurveyProductType } from 'bundles/premium-hub/utils';

type Props = PropsFromHubPageContent & {
  presetSearchFilters?: string[];
  hiddenSearchFilters?: string[];
};

const SearchPageContent = (props: Props) => {
  const {
    pageContent,
    moduleOrder,
    degreeListWithMetadata,
    currentPageType,
    categoryData,
    presetSearchFilters,
    hiddenSearchFilters,
    atAGlanceData,
  } = props;

  if (!pageContent) return null;

  const { slug, pageHeading, pageSubheading, coreHubHeroValuePropContent } = pageContent;

  const onCoreHubPage = currentPageType === HubPages.MainHubPage;

  const getModule = (module: HubModuleValue) => {
    switch (module) {
      case HUB_MODULES.degreesHubBanner:
        return (
          <>
            <DegreesHubBanner
              slug={slug ?? ''}
              pageHeading={pageHeading}
              pageSubheading={pageSubheading}
              currentHubPage={HubPages.MainHubPage}
              productId="degrees"
              surveyProductType="GenericPage"
            />
            <DegreeHubScrollAnchor />
            <InlineNavigation pageType={currentPageType} />
          </>
        );

      case HUB_MODULES.hubBanner:
        return (
          <>
            <HubBanner
              slug={slug}
              pageHeading={pageHeading}
              pageSubheading={pageSubheading}
              categoryName={categoryData?.name ?? ''}
              currentHubPage={currentPageType}
              productId={categoryData?.id ?? ''}
              productType={ProductType.Degrees}
              surveyProductType={getSurveyProductType(currentPageType)}
            />
            <InlineNavigation />
          </>
        );

      case HUB_MODULES.heroBanner:
        return (
          <>
            <HeroBanner
              slug={slug ?? ''}
              pageHeading={coreHubHeroValuePropContent?.heading ?? pageHeading}
              pageSubheading={coreHubHeroValuePropContent?.subheading ?? pageSubheading}
              categoryName={categoryData?.name}
              heroImage={coreHubHeroValuePropContent?.heroImage?.url}
              currentHubPage={currentPageType}
              productId={onCoreHubPage ? 'degrees' : categoryData?.id ?? ''}
              productType={ProductType.Degrees}
              surveyProductType={getSurveyProductType(currentPageType)}
            />
            <DegreeHubScrollAnchor />
            <InlineNavigation />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <TrackedDiv
        trackingName="search_page_content"
        withVisibilityTracking
        atMostOnce
        requireFullyVisible={false}
        trackClicks={false}
      >
        {moduleOrder.map((module) => (
          <ErrorBoundaryWithTagging key={module} componentName={module}>
            <>{getModule(module)}</>
          </ErrorBoundaryWithTagging>
        ))}
        <ErrorBoundaryWithTagging componentName="SearchContainer">
          <SearchContainer
            presetFilters={presetSearchFilters}
            hiddenFilters={hiddenSearchFilters}
            dreOrderedProducts={degreeListWithMetadata}
            atAGlanceData={atAGlanceData}
            slug={slug ?? ''}
            productId={onCoreHubPage ? 'degrees' : categoryData?.id ?? ''}
            currentHubPage={currentPageType}
            surveyProductType={getSurveyProductType(currentPageType)}
          />
        </ErrorBoundaryWithTagging>
      </TrackedDiv>
    </>
  );
};

export default SearchPageContent;
