/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Divider } from '@coursera/coursera-ui';

type Props = {
  text?: string;
};

const styles = {
  text: css`
    background-color: var(--cds-color-white-0);
    color: #616161;
    font-size: 12px;
    line-height: 18px;
    padding: 0 var(--cds-spacing-150);
  `,
};

const Separator = ({ text }: Props) => (
  <Divider style={{ margin: '24px 0' }}>{text && <div css={styles.text}>{text}</div>}</Divider>
);

export default Separator;
