/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useRef, useState } from 'react';

import { get } from 'js/lib/user';

import { Button, Dialog, Typography2 } from '@coursera/cds-core';
import { Loading } from '@coursera/coursera-ui';

import InternationalPhoneNumberInput from 'bundles/account-profile/components/i18n-phone-number-input/InternationalPhoneNumberInput';
import type { ProfilePhone } from 'bundles/account-profile/components/types';
import { getQualifyingQuestionsExpt } from 'bundles/degree-description/utils/experiments';
import { useEoiOptimizationsContext } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';
import { isDestinationDegree, updateUserProfile } from 'bundles/expression-of-interest/utils/util';
import type { Phone } from 'bundles/naptimejs/resources/__generated__/ProfilesV1';
import { customFormattedPhoneStyles } from 'bundles/university-program-qualification/components/SurveyQuestion';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import _t from 'i18n!nls/expression-of-interest';

type Props = {
  phone: Phone;
  isPhoneLoading: boolean;
  handleSubmit: () => Promise<void | {}>;
  navigateQualifyingQuestions: () => void;
  navigateNextSteps: () => void;
  navigateConfirmation: () => void;
  navigateSelectSubject: () => void;
  handlePhoneChange: (data?: ProfilePhone) => void;
  hasCompletedQQform?: boolean;
  trackingProductId: string;
};

const styles = {
  phoneInput: css`
    padding-bottom: var(--cds-spacing-300);
  `,
  primaryButton: css`
    margin-right: var(--cds-spacing-200);
  `,
};

export const PhoneNumberSection = (props: Props): React.ReactElement => {
  const {
    handleSubmit,
    navigateConfirmation,
    navigateSelectSubject,
    navigateQualifyingQuestions,
    navigateNextSteps,
    phone,
    isPhoneLoading,
    handlePhoneChange,
    hasCompletedQQform,
    trackingProductId,
  } = props;

  const [fieldError, setFieldError] = useState<boolean>(false);
  const { trackSubmitEoi } = useEoiEventing(trackingProductId);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const { slug, productType } = useEoiOptimizationsContext();
  const qualifyingQuestionsVariant = getQualifyingQuestionsExpt(slug, true);
  const isDestinationDegreeEoi = isDestinationDegree(slug);

  const disclaimerText = isDestinationDegreeEoi
    ? _t(
        'By sharing my phone number, I consent to receiving calls, texts or emails from Coursera and the university about educational opportunities.'
      )
    : _t(
        'By sharing my phone number, I consent to receiving calls or texts from Coursera about educational opportunities.'
      );
  const titleCopy = isDestinationDegreeEoi ? _t('Last step to view application') : _t('Add your phone number');
  const supportTextCopy = isDestinationDegreeEoi
    ? _t(
        'To learn more about this degree program and begin your application, provide us with your phone number. Enrollment counselors from the university may contact you by email or phone to support your application process. You can opt out at any time.'
      )
    : _t(
        'Our enrollment counselors may reach out to walk you through program details and provide personalized next steps.'
      );
  const submitButtonCopy = isDestinationDegreeEoi ? _t('View application') : _t('Add phone number');

  const { HeadingGroup, Content, Actions } = Dialog;

  const navigateNext = () => {
    if (productType === ProductTypes.DEGREE_HUB) {
      navigateSelectSubject();
    } else if (qualifyingQuestionsVariant === 'A') {
      navigateNextSteps();
    } else if (qualifyingQuestionsVariant === 'B' && !hasCompletedQQform) {
      navigateQualifyingQuestions();
    } else if (qualifyingQuestionsVariant === 'B' && hasCompletedQQform) {
      navigateNextSteps();
    } else {
      navigateConfirmation();
    }
  };

  const handlePhoneSubmit = () => {
    if (phone.phoneNumber === '') {
      setFieldError(true);
      trackSubmitEoi('invalid_submission', 'phone_number_section');
    } else if (!fieldError) {
      handleSubmit()
        .then(() => {
          const formattedPhone = phone;
          // user profile expects the phone number to be stripped of its country code
          formattedPhone.phoneNumber = phone.phoneNumber.replace(`+${phone.countryCode}`, '');
          const currentUser = get();
          updateUserProfile({
            userId: currentUser.id,
            externalId: currentUser.external_id,
            fullname: currentUser.fullName,
            phone: formattedPhone,
          });

          navigateNext();
          trackSubmitEoi('success', 'phone_number_section');
        })
        .catch(() => {
          trackSubmitEoi('api_failure', 'phone_number_section');
        });
    } else {
      trackSubmitEoi('invalid_submission', 'phone_number_section');
    }
  };

  if (isPhoneLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  return (
    <>
      <HeadingGroup supportText={supportTextCopy}>{titleCopy}</HeadingGroup>
      <Content>
        <div css={styles.phoneInput}>
          <InternationalPhoneNumberInput
            profile={{ phone }}
            isOptional={false}
            isEmptyValid={false}
            customStyles={customFormattedPhoneStyles}
            submissionError={fieldError}
            handleFormChange={handlePhoneChange}
            handleError={(hasError: boolean) => {
              setFieldError(hasError);
            }}
            ref={phoneNumberRef}
          />
        </div>

        <Typography2 component="p" variant="bodySecondary" color="supportText">
          {disclaimerText}
        </Typography2>
      </Content>
      <Actions>
        <Button onClick={handlePhoneSubmit} css={styles.primaryButton}>
          {submitButtonCopy}
        </Button>
      </Actions>
    </>
  );
};

export default PhoneNumberSection;
