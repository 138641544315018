import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { useLocation } from 'js/lib/useRouter';
import { isAuthenticatedUser } from 'js/lib/user';

import { isTermsOfUseRolloutForUS, shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import {
  PROFILE_CONSENT_COOKIE_CONSENT_NAMES,
  SHOW_TERMS_OF_USE_ACCEPT_PARAM,
  TERMS_OF_USE_MODAL_TYPES,
} from 'bundles/user-consent/constants';
import useRemoveTermsOfUseAcceptParam from 'bundles/user-consent/hooks/useRemoveTouAcceptParam';
import ProfileConsentsQuery from 'bundles/user-consent/queries/ProfileConsentsQuery.graphql';
import type {
  ProfileConsentsQuery as ProfileConsentsQueryType,
  ProfileConsentsQueryVariables,
} from 'bundles/user-consent/queries/__generated__/ProfileConsentsQuery';
import type { TermsOfUseModalTypes } from 'bundles/user-consent/types';
import { isExcludedPage, isMissingCookieConsent, setProfileConsentCookie } from 'bundles/user-consent/utils';

export const useShowTermsOfUseModal = (shouldShowCCPAMarketingConsent = false) => {
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);

  const location = useLocation();
  const removeTermsOfUseAcceptParam = useRemoveTermsOfUseAcceptParam();

  const isUserEligibleBase = isAuthenticatedUser() && !isExcludedPage(location.pathname);

  const isUserEligibleForModal = isUserEligibleBase && isTermsOfUseRolloutForUS();
  const hasTouAcceptParam = location.query?.[SHOW_TERMS_OF_USE_ACCEPT_PARAM];

  let modalType: TermsOfUseModalTypes = TERMS_OF_USE_MODAL_TYPES.updateModal;
  let shouldShowModal: boolean;
  let skipProfileConsentsQuery: boolean;
  const showMarketingConsent = shouldShowLegalConsolidationAuthFlow() && shouldShowCCPAMarketingConsent;

  if (showMarketingConsent) {
    modalType = TERMS_OF_USE_MODAL_TYPES.acceptModal;
    skipProfileConsentsQuery = true;
    shouldShowModal = isUserEligibleForModal;
  } else if (hasTouAcceptParam) {
    modalType = TERMS_OF_USE_MODAL_TYPES.acceptModal;
    skipProfileConsentsQuery =
      !isUserEligibleForModal || !isMissingCookieConsent(PROFILE_CONSENT_COOKIE_CONSENT_NAMES.touRequired);
    shouldShowModal = isUserEligibleForModal;
  } else {
    shouldShowModal = isUserEligibleForModal;
    skipProfileConsentsQuery =
      !isUserEligibleForModal || !isMissingCookieConsent(PROFILE_CONSENT_COOKIE_CONSENT_NAMES.touRequired);
  }

  const {
    data,
    loading: isRequiresTermsOfUseConsentLoading,
    error,
  } = useQuery<ProfileConsentsQueryType, ProfileConsentsQueryVariables>(ProfileConsentsQuery, {
    skip: skipProfileConsentsQuery,
    context: { clientName: 'gatewayGql' },
    fetchPolicy: 'network-only',
  });

  const requiresTermsOfUseConsent = data?.ProfileConsents?.me?.requiresTermsOfUseConsent;

  if (
    !showMarketingConsent &&
    (skipProfileConsentsQuery || isRequiresTermsOfUseConsentLoading || !requiresTermsOfUseConsent)
  ) {
    shouldShowModal = false;
  }

  useEffect(() => {
    if (hasTouAcceptParam && !isRequiresTermsOfUseConsentLoading && !shouldShowModal) {
      // Remove the touAccept param from the route
      removeTermsOfUseAcceptParam();
    }
  }, [shouldShowModal, hasTouAcceptParam, isRequiresTermsOfUseConsentLoading, removeTermsOfUseAcceptParam]);

  useEffect(() => {
    setShowTermsOfUseModal(shouldShowModal);
  }, [shouldShowModal]);

  useEffect(() => {
    // Set the cookie to false when BE returns false or when BE returns an error
    if ((!isRequiresTermsOfUseConsentLoading && requiresTermsOfUseConsent === false) || !!error) {
      setProfileConsentCookie({ [PROFILE_CONSENT_COOKIE_CONSENT_NAMES.touRequired]: false });
    }
  }, [error, isRequiresTermsOfUseConsentLoading, requiresTermsOfUseConsent]);

  return {
    shouldShowModal,
    showTermsOfUseModal,
    setShowTermsOfUseModal,
    modalType,
  };
};

export default useShowTermsOfUseModal;
