/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useMemo } from 'react';

import { Typography2, breakpoints, typography, typography2 } from '@coursera/cds-core';

import SimpleMenuItem from 'bundles/megamenu/components/goal-item/SimpleMenuItem';
import { simplifiedMegaMenuContent } from 'bundles/megamenu/constants/simplifiedMegaMenuContent';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { useTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/megamenu';

export type Props = {
  isMobile: boolean;
};

const MAX_ITEMS_PER_COLUMN = 6;

const style = {
  grid: () => css`
    font-family: var(--cds-font-family-source-sans-pro);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: var(--cds-spacing-200);
    line-height: var(--cds-line-height-350);
    justify-content: space-evenly;
    column-gap: var(--cds-spacing-300);
    max-width: 1440px;
    margin: auto;
  `,
  mobileGrid: () => css`
    font-family: var(--cds-font-family-source-sans-pro);
    display: flex;
    flex-direction: column;
    padding-top: 98px;
    line-height: var(--cds-line-height-400);
    font-size: 16px;
  `,
  viewAllLink: () => css`
    &:hover,
    &:focus {
      text-decoration: underline;
    }

    ${typography2.subtitleMedium}
    border-radius: 2px;
    min-height: 36px;
    text-align: left;
    vertical-align: middle;
    color: var(--cds-color-blue-700);
    border: none;
    display: inline-block;
    white-space: normal;
    margin-top: 15px;

    ${breakpoints.down('sm')} {
      width: 100%;
      padding-left: 8px;
      font-size: 14px;
    }
  `,
  removeExtraMargin: css`
    margin-top: 0;
  `,
  removeExtraPadding: css`
    padding-right: 0;
  `,
  sectionContainer: css`
    height: 100%;
    width: fit-content;
    margin-bottom: 44px;
  `,
  mobileSectionContainer: css`
    height: 100%;
    width: 100%;
  `,
  sectionTitle: css`
    ${typography.h2semibold}
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 18px;
    width: 100%;
    height: fit-content;
    text-wrap: wrap;
  `,
  mobileSectionTitle: css`
    ${typography.h2semibold}
    display: block;
    border-top: 1px solid #c8c8c8;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 12px;
    width: 100%;
    padding-top: 20px;
  `,
  subsection: () => css`
    list-style: none;
    padding: 0;
    width: max-content;
    max-height: 70%;
    column-gap: var(--cds-spacing-400);

    ${breakpoints.down('sm')} {
      padding-left: 8px;
    }
  `,
  noSectionPadding: () => css`
    ${breakpoints.down('sm')} {
      padding: 0;
    }
  `,
  item: () => css`
    flex: 0 0 auto;
    width: fit-content;
    box-sizing: border-box;
  `,
  headerItem: () => css`
    font-weight: 600;
    line-height: 20px;

    ${breakpoints.down('sm')} {
      font-size: 14px;
      line-height: 28px;
    }
  `,
  headerMargin: () => css`
    margin-bottom: 18px;
  `,
};

export const SimplifiedMegaMenuSection = (props: Props) => {
  const { isMobile } = props;
  const megaMenuContent = useMemo(() => simplifiedMegaMenuContent(), []);
  const track = useTracker();

  return (
    <div
      data-testid="simplified-megaMenu-section"
      aria-label={isMobile ? _t('Menu content') : ''}
      css={[isMobile ? style.mobileGrid : style.grid]}
    >
      {megaMenuContent.map((section, sectionIndex) => {
        return (
          <TrackedDiv
            className="mobile-submenu-header"
            tabIndex={isMobile ? 0 : -1}
            key={section.name}
            css={[
              isMobile ? style.mobileSectionContainer : style.sectionContainer,
              sectionIndex === megaMenuContent.length - 1 && style.removeExtraPadding,
            ]}
            trackingName="mega_menu_section"
            trackClicks={false}
            trackMouseEnters={true}
            data={{
              sectionName: section.name,
              sectionType: section.type,
              viewAllText: section.footer?.label,
              viewAllUrl: section.footer?.href,
              sectionItemsLength: section.children?.length,
            }}
          >
            <Typography2
              variant="subtitleMedium"
              component="p"
              css={isMobile ? style.mobileSectionTitle : style.sectionTitle}
            >
              {section.label}
            </Typography2>
            {section?.children && (
              <ul
                css={[
                  style.subsection,
                  !isMobile && { columnCount: Math.ceil(section.children.length / MAX_ITEMS_PER_COLUMN) },
                ]}
              >
                {section.children.map((menuItem, index) => {
                  return (
                    <li css={style.item} key={`${menuItem.name}-${section.name}`}>
                      {menuItem.type === 'link' ? (
                        <SimpleMenuItem
                          item={{
                            ...menuItem,
                            name: menuItem.label,
                            id: menuItem.name,
                            url: menuItem.href,
                            type: section.sectionType,
                          }}
                          isMobile={isMobile}
                          tabName={menuItem.name}
                          sectionName={menuItem.name}
                          tabId={section.name}
                          hideAnchorColor={true}
                          isSimplifiedMegaMenu={true}
                        />
                      ) : (
                        <span css={style.headerItem}>{menuItem.label}</span>
                      )}
                      {menuItem?.children && (
                        <ul
                          css={[
                            style.subsection,
                            style.noSectionPadding,
                            index + 1 !== section.children?.length && style.headerMargin,
                          ]}
                        >
                          {menuItem.children.map((item) => {
                            return (
                              <li css={style.item} key={`${item.name}-${menuItem.name}`}>
                                <SimpleMenuItem
                                  item={{
                                    ...item,
                                    name: item.label,
                                    id: item.name,
                                    url: item.href,
                                    type: section.sectionType,
                                  }}
                                  isMobile={isMobile}
                                  tabName={item.name}
                                  tabId={section.name}
                                  sectionName={menuItem.name}
                                  hideAnchorColor={true}
                                  isSimplifiedMegaMenu={true}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
            <TrackedA
              tabIndex={0}
              href={section.footer?.href}
              trackingName={isMobile ? 'mobile_section_view_all_button' : 'section_view_all_button'}
              css={[style.viewAllLink, !section.children?.length && style.removeExtraMargin]}
              data={{
                sectionName: section?.label,
                sectionType: section.sectionType,
                domainSlug: section.name,
                viewAllText: section.footer?.label,
                viewAllUrl: section.footer?.href,
                sectionItemsLength: section?.children?.length || 0,
              }}
              onClick={() => {
                track('click_megamenu_item', {
                  megamenuItemLink: section.footer?.href ?? '',
                  megamenuItemName: section?.footer?.name ?? '',
                  megamenuSection: section?.name ?? '',
                  megamenuItemType: 'view_all_link',
                });
              }}
            >
              {section.footer?.label}
            </TrackedA>
          </TrackedDiv>
        );
      })}
    </div>
  );
};

export default SimplifiedMegaMenuSection;
