import DegreesGrowthExperiments from 'bundles/epic/clients/DegreesGrowth';

export const CHILI_PIPER_SCRIPT_URL = 'https://js.chilipiper.com/marketing.js';
export const CHILI_PIPER_ACCOUNT = 'coursera';

export const CHILI_PIPER_ROUTERS = {
  degreesEoi: 'degrees-eoi-form',
  drift: 'drift-router',
} as const;

/**
 *
 * @param slug The degree or landing page's slug
 * @param isLandingPage Denotes if the page is a landing page or not.
 * @returns Returns a string of the slug with /landing if it's a landing page. e.g. `imba`, `imba/landing`
 */
export const getSlugWithLandingPath = (slug: string, isLandingPage = false) => {
  if (!isLandingPage) {
    return slug;
  } else {
    return `${slug}/landing`;
  }
};

/**
 * Searches through the list of enabled custom routers and returns the one to be used
 * for the current page. Only works with pages under `/degrees`.
 * @param slugWithPath The slug, along with the rest of its path to support landing pages. e.g. `/degrees/imba` or `/degrees/imba/landing`
 * @returns string | undefined
 */
export const getChiliPiperCustomRouter = (slugWithPath: string): string | undefined => {
  const routerList = DegreesGrowthExperiments.preview('enabledCustomChiliPiperRouters');
  return routerList[slugWithPath];
};

/**
 * Adds the Chili Piper script to the page.
 * Only run this function in componentDidMount or useEffect, otherwise `document` does not exist
 */
export const addChiliPiperWidget = () => {
  if (document && !window?.ChiliPiper) {
    const chiliPiperScript = document.createElement('script');
    chiliPiperScript.type = 'text/javascript';
    chiliPiperScript.async = true;
    chiliPiperScript.src = CHILI_PIPER_SCRIPT_URL;
    document.head.appendChild(chiliPiperScript);
  }
};
