import { useEffect, useState } from 'react';

import { useLocalizedStringFormatter } from '@coursera/cds-common';

import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import i18nMessages from './i18n';

type Props = {
  characterLimit: number;
  charactersLeft: number;
  id?: string;
  isLimitReached?: boolean;
  className?: string;
};

const CharacterLimit = (props: Props) => {
  const {
    className,
    charactersLeft,
    characterLimit,
    isLimitReached,
    id,
  } = props;

  const [announcedCharactersLeft, setAnnouncedCharactersLeft] = useState(
    charactersLeft
  );

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  useEffect(() => {
    const ratio = charactersLeft / characterLimit;

    // Announce when  100% || 50% || 10% || less than or equal 0% of the limit left
    if (ratio === 0.5 || ratio === 0.1 || ratio === 1 || ratio <= 0) {
      setAnnouncedCharactersLeft(charactersLeft);
    }
  }, [characterLimit, charactersLeft]);

  return (
    <>
      <VisuallyHidden id={id}>
        {stringFormatter.format('characterLimitLabel', {
          number: characterLimit,
        })}
      </VisuallyHidden>
      <Typography
        aria-hidden
        className={className}
        color={isLimitReached ? 'error' : 'supportText'}
        component="p"
        variant="bodySecondary"
      >
        {isLimitReached
          ? stringFormatter.format('characterLimitTooMany', {
              number: Math.abs(charactersLeft),
            })
          : stringFormatter.format('characterLimitRemaining', {
              number: charactersLeft,
            })}
      </Typography>
      <VisuallyHidden aria-live="polite">
        {isLimitReached
          ? stringFormatter.format('characterLimitTooManySr', {
              number: Math.abs(announcedCharactersLeft),
            })
          : stringFormatter.format('characterLimitRemainingSr', {
              number: announcedCharactersLeft,
            })}
      </VisuallyHidden>
    </>
  );
};

export default CharacterLimit;
