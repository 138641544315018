import React from 'react';

import { Divider as MuiDivider } from '@material-ui/core';

import clsx from 'clsx';

import type { ComponentPropsWithRef } from '@coursera/cds-common';

import getDividerCss, { classes } from './getDividerCss';

export type Props = {
  /**
   * Defines the color of the divider.
   * @default light
   */
  color?: 'light' | 'dark' | 'white';
  /**
   * Defines the divider orientation.
   * @default horizontal
   */
  orientation?: 'horizontal' | 'vertical';
} & ComponentPropsWithRef<'hr'>;

/**
 * A divider is a visual aid to create intentional hierarchy between individual chunks
 * of content within a layout, content group, or component.
 *
 * See [Props](__storybookUrl__/components-data-display-divider--default#props)
 */
const Divider = React.forwardRef(function Divider(
  props: Props,
  ref: React.Ref<HTMLHRElement>
) {
  const { 'aria-hidden': ariaHidden = true, color, className, ...rest } = props;

  const flexItem = props.orientation === 'vertical';

  return (
    <MuiDivider
      ref={ref}
      aria-hidden={ariaHidden}
      className={clsx(className, {
        [classes.dark]: color === 'dark',
        [classes.white]: color === 'white',
      })}
      css={getDividerCss}
      flexItem={flexItem}
      {...rest}
    />
  );
});

Divider.defaultProps = {
  orientation: 'horizontal',
  color: 'light',
};

export default Divider;
