import { useEffect } from 'react';

import { disableScrolling } from 'bundles/browse/utils';
import { useIsMobileHeader } from 'bundles/front-page/hooks/useIsMobileHeader';

export const useDisableScrollOnMobile = (isSearchBarFocused: boolean) => {
  const isMobileHeader = useIsMobileHeader();

  useEffect(() => {
    if (!isMobileHeader) {
      return;
    }

    disableScrolling(isSearchBarFocused);
  }, [isSearchBarFocused, isMobileHeader]);
};
