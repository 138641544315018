import { forceVisible } from 'react-lazyload';

import { prodAssetsRoot } from 'js/constants/cloudfront';
import imgix from 'js/lib/imgix';
import path from 'js/lib/path';

type ImageConfig = {
  src: string;
  maxWidth: number;
  maxHeight: number;
};

const FETCH_LAZY_IMAGES_TIMER = 3000;

export const DOCUMENT_COMPLETE_READY_STATE = 'complete';

export function getLowQualityImageSrc(imageConfig: ImageConfig) {
  // Handle pathological case of missing src. This will show up as missing
  // in the browser, with the alt tag. We do this instead of passing the
  // unoptimized URL so that the developer can see the failure in the
  // render.
  const { src, maxWidth, maxHeight, ...otherConfig } = imageConfig;
  if (!src) return '';

  const isAbsoluteAlready = src.slice(0, 4) === 'http' || src.slice(0, 2) === '//';

  const absoluteSrc = isAbsoluteAlready ? src : path.join(prodAssetsRoot, src);

  const options = Object.assign({ maxWidth, maxHeight, ...otherConfig });

  // Uses configuration for LQIP as suggested by imgix: https://blog.imgix.com/2016/06/01/lqip-your-images
  return imgix.processImage(absoluteSrc, Object.assign({ auto: 'format,compress', dpr: 2, blur: 200, px: 8 }, options));
}

export function fetchLazyImages() {
  setTimeout(() => forceVisible(), FETCH_LAZY_IMAGES_TIMER);
}
