import * as React from 'react';
import { Query, graphql } from 'react-apollo';

import user from 'js/lib/user';

import GetCheatingIncidentsByUserIdAndCourseIdsQuery from 'bundles/integrity-portal/api/GetCheatingIncidentsByUserIdAndCourseIdsQuery.graphql';
import type {
  GetCheatingIncidentsByUserIdAndCourseIdsQueryQuery,
  GetCheatingIncidentsByUserIdAndCourseIdsQueryQueryVariables,
} from 'bundles/integrity-portal/api/__generated__/GetCheatingIncidentsByUserIdAndCourseIdsQuery';
import { CheatingIncidentRulings } from 'bundles/integrity-portal/lib/constants';
import type { CheatingIncident } from 'bundles/integrity-portal/lib/types';

const defaultQueryRulings = [
  CheatingIncidentRulings.CheatingConfirmed,
  CheatingIncidentRulings.AppealOther,
  CheatingIncidentRulings.AppealFalsePositive,
  CheatingIncidentRulings.AppealRequested,
  CheatingIncidentRulings.AppealDenied,
];

export const withCheatingIncidentsByUserIdAndCourseIdsGraphql = graphql<
  {},
  GetCheatingIncidentsByUserIdAndCourseIdsQueryQuery,
  GetCheatingIncidentsByUserIdAndCourseIdsQueryQueryVariables,
  { hasCheatingIncidents: boolean }
>(GetCheatingIncidentsByUserIdAndCourseIdsQuery, {
  options: () => ({
    context: {
      clientName: 'gatewayGql',
    },
    variables: { userId: user.get().id, courseIds: [], rulings: defaultQueryRulings },
    ssr: false,
  }),
  props: ({ data }) => {
    const dataEdges = data?.CheatingIncident?.queryCheatingIncidentsByUserIdAndCourseIds?.edges;
    if (data?.error || !dataEdges) {
      return { hasCheatingIncidents: false };
    }
    return { hasCheatingIncidents: dataEdges.length > 0 };
  },
});

export const getItemIdToCheatingIncidentMap = (incidents?: CheatingIncident[]) => {
  return (
    incidents?.reduce<Record<string, CheatingIncident[]>>((map, incident) => {
      const itemId = incident.courseItemContext?.itemId;
      if (!itemId) {
        return map;
      }
      return { ...map, [itemId]: map[itemId] ? [...map[itemId], incident] : [incident] };
    }, {}) || {}
  );
};

export const getCourseIdToCheatingIncidentMap = (incidents?: CheatingIncident[]) => {
  return (
    incidents?.reduce<Record<string, CheatingIncident[]>>((map, incident) => {
      const courseId = incident.courseContext?.courseId;
      if (!courseId) {
        return map;
      }
      return { ...map, [courseId]: map[courseId] ? [...map[courseId], incident] : [incident] };
    }, {}) || {}
  );
};

export const getRulingTypesInIncidents = (incidents?: CheatingIncident[]) => {
  return Array.from(new Set(incidents?.map((incident) => incident.ruling)));
};

const getCheatingIncidentsInCourse =
  (incidents?: CheatingIncident[]) =>
  (courseId: string): Record<string, CheatingIncident> => {
    return (
      incidents
        ?.filter((incident) => incident.courseContext?.courseId === courseId)
        .reduce((map, incident) => {
          const itemId = incident.courseItemContext?.itemId;
          return itemId ? { ...map, [itemId]: incident } : map;
        }, {}) || {}
    );
  };

export type CheatingIncidentItemIdMap = Record<string, CheatingIncident>;

type Props = {
  userId?: number;
  courseIds?: string[];
  children: (x0: {
    incidents: CheatingIncident[];
    getCheatingIncidentsInCourse: (courseId: string) => Record<string, CheatingIncident>;
  }) => React.ReactElement | null;
};

export const CheatingIncidentsByUserIdAndCourseIdAPI: React.FC<Props> = ({ children, userId, courseIds }) => {
  return (
    <Query<
      GetCheatingIncidentsByUserIdAndCourseIdsQueryQuery,
      GetCheatingIncidentsByUserIdAndCourseIdsQueryQueryVariables
    >
      query={GetCheatingIncidentsByUserIdAndCourseIdsQuery}
      variables={{ userId: userId || user.get().id, courseIds: courseIds || [], rulings: defaultQueryRulings }}
      context={{ clientName: 'gatewayGql' }}
    >
      {({ data, loading }) => {
        const incidents = data?.CheatingIncident?.queryCheatingIncidentsByUserIdAndCourseIds?.edges?.map(
          (edge) => edge?.node
        ) as CheatingIncident[] | undefined;

        if (loading || !incidents) {
          return children({ incidents: [], getCheatingIncidentsInCourse: getCheatingIncidentsInCourse(incidents) });
        }

        return children({ incidents, getCheatingIncidentsInCourse: getCheatingIncidentsInCourse(incidents) });
      }}
    </Query>
  );
};
