/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';

import { Grid, breakpoints } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

type Props = {
  children: React.ReactNode;
  className?: string;
  component?: any;
  ariaLabel?: string;
};

const styles: Record<string, Interpolation<Theme>> = {
  pageContainer: () => css`
    position: relative;
    margin: 0 auto;
    padding: 0 12px;
    width: 100%;

    @media (min-width: ${breakpoints.values.md}px) {
      max-width: 1152px;
    }

    @media (min-width: ${breakpoints.values.lg}px) {
      max-width: 1440px;
    }
  `,
};

function Container({ children, className, component, ariaLabel }: Props): React.ReactElement {
  return (
    <Grid css={styles.pageContainer} className={className} component={component} aria-label={ariaLabel}>
      {children}
    </Grid>
  );
}

export default React.memo(Container);
