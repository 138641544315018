import gql from 'graphql-tag';

export const ProgramCurriculumDomainsQuery = gql`
  query ProgramCurriculumDomainsQuery($programId: String!) {
    ProgramCurriculumDomainsV1Resource {
      get(id: $programId) {
        id
        domains {
          id
          slug
          name
          subdomainIds
        }
      }
    }
    SubdomainsV1Resource {
      getAll: getAll {
        elements {
          id
          name
          domainId
          slug
        }
      }
    }
  }
`;

export const CourseTypeMetadataByCourseIdQuery = gql`
  query CourseTypeMetadataByCourseIdQuery($courseId: String!) {
    CourseTypeMetadataV1Resource {
      get(id: $courseId) {
        id
        courseTypeMetadata {
          ... on CourseTypeMetadataV1_rhymeProjectMember {
            rhymeProject {
              typeNameIndex
            }
          }
          ... on CourseTypeMetadataV1_projectMember {
            project {
              typeNameIndex
            }
          }
          ... on CourseTypeMetadataV1_standardCourseMember {
            standardCourse {
              typeNameIndex
            }
          }
        }
      }
    }
  }
`;

export const CourseTypeMetadataByCourseIdsQuery = gql`
  query CourseTypeMetadataByCourseIdsQuery($courseIds: [String!]!) {
    CourseTypeMetadataV1Resource {
      multiGet(ids: $courseIds) {
        elements {
          id
          courseTypeMetadata {
            ... on CourseTypeMetadataV1_rhymeProjectMember {
              rhymeProject {
                typeNameIndex
              }
            }
          }
        }
      }
    }
  }
`;

export const TargetSkillProfileUserStatesQuery = gql`
  query TargetSkillProfileUserStatesQuery($userId: String!, $programId: String!) {
    TargetSkillProfileUserStatesV1Resource {
      byUserAndProgram(programId: $programId, userId: $userId) {
        elements {
          id
          state
          targetSkillProfileId
          targetSkillProfile {
            id
            slug
            title
          }
          targetSkillProfileSummary {
            id
            targetSkillProficiencies {
              skillId
              skillName
              targetProficiency
            }
          }
        }
      }
    }
  }
`;

export const EnterpriseProgramSessionAssociationsQueryV1 = gql`
  query EnterpriseProgramSessionAssociationsQuery($programId: String!, $productId: String!) {
    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramAndCourse(programId: $programId, courseId: $productId) {
        elements {
          id
          session {
            id
            courseId
            startsAt
            endsAt
          }
        }
      }
    }
  }
`;

export const EnterpriseProgramS12nSessionAssociationsQuery = gql`
  query EnterpriseProgramS12nSessionAssociationsQuery($programIds: [String!]!, $s12nIds: [String!]!) {
    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramsAndSpecializations(programIds: $programIds, s12nIds: $s12nIds) {
        elements {
          id
          session {
            id
            courseId
            startsAt
            endsAt
          }
        }
      }
    }
  }
`;

export const EnterpriseTargetSkillProfileSummariesQueryV1 = gql`
  query ObjectiveSummaryQuery($objectiveSlug: String!) {
    EnterpriseTargetSkillProfileSummariesV1Resource {
      bySlug(slug: $objectiveSlug) {
        elements {
          id
          slug
          title
          academyIds
          occupationId
          description {
            ... on EnterpriseTargetSkillProfileSummariesV1_cmlMember {
              cml {
                dtdId
                value
              }
            }
          }
          targetSkillProficiencies {
            skillId
            skillName
            targetProficiency
          }
        }
      }
    }
  }
`;

export const AutoenrolledCoursesQuery = gql`
  query AutoenrolledCoursesQuery($programId: String!, $includeEnrolledCoursesOnly: Boolean) {
    ProgramEnrollmentSettingsV1Resource {
      joinProgramCourses(programId: $programId, includeEnrolledCoursesOnly: $includeEnrolledCoursesOnly) {
        elements {
          id

          enrollmentTarget {
            targetProduct {
              ... on ProgramEnrollmentSettingsV1_courseEnrollmentMember {
                courseEnrollment {
                  targetCourseId
                }
              }
            }
          }

          triggerEventJoinedProgram {
            id
          }
        }
      }
    }
  }
`;

export const CourseAutoenrollmentSessionDatesQuery = gql`
  query CourseAutoenrollmentSessionDatesQuery($courseIds: [String!]!, $programId: String!) {
    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramAndCourses(courseIds: $courseIds, programId: $programId, limit: 50) {
        elements {
          id
          session {
            id
            courseId
            startsAt
            endsAt
          }
        }
      }
    }
  }
`;
