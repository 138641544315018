import type { DegreeWebinar_DegreeWebinar as DegreeWebinar } from '__generated__/graphql-types';
import type { Event, ItemList, Review, WithContext } from 'schema-dts';

import config from 'js/app/config';

import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { ProductVariantCardsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';

export const eventSchemaDataFromWebinar = (webinar: DegreeWebinar): WithContext<Event> | null => {
  const { catalogDegree, timestamp, title, registerLink, recordingLink } = webinar;
  if (!catalogDegree || !timestamp?.seconds || !title) return null;

  const { partnerEntities } = catalogDegree;
  const firstPartner = partnerEntities?.[0] ?? null;
  const partnerLogo = firstPartner?.squareLogo ?? '';
  const webinarDate = new Date(parseInt(timestamp.seconds, 10) * 1000);
  const isUpcoming = webinarDate > new Date();
  const locationUrl = isUpcoming ? registerLink : recordingLink;
  if (!locationUrl) return null;

  const eventSchemaData: WithContext<Event> = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: title,
    startDate: webinarDate.toISOString(),
    eventStatus: 'https://schema.org/EventScheduled',
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    location: {
      '@type': 'VirtualLocation',
      url: locationUrl,
    },
    image: partnerLogo,
    organizer: {
      '@type': 'Organization',
      name: firstPartner?.name ?? '',
      url: firstPartner?.links?.website ?? '',
    },
  };

  if (isUpcoming) {
    // Add signup information for upcoming webinars
    eventSchemaData.offers = {
      '@type': 'Offer',
      url: locationUrl,
      price: '0',
      priceCurrency: 'USD',
      availability: 'https://schema.org/InStock',
      validFrom: new Date().toISOString(),
    };
  }

  return eventSchemaData;
};

export const productVariantCardsSchemaData = (
  productVariantCards: Array<Maybe<ProductVariantCardsFragment>>
): WithContext<ItemList> | null => {
  const filteredCards = productVariantCards.filter((item): item is ProductVariantCardsFragment => {
    return item !== null && item !== undefined;
  });

  const schemaData: WithContext<ItemList> = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: filteredCards.map((item, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        url: `${config.url.base}${item.callToActionLink}`,
        name: item.title || '',
      };
    }),
  };

  return schemaData;
};

type TestimonialSchemaDataProps = {
  quoteText?: string | null;
  authorName?: string | null;
  authorTitle?: string | null;
  authorOrg?: string | null;
};
export const testimonialSchemaData = ({
  quoteText,
  authorName,
  authorTitle,
  authorOrg,
}: TestimonialSchemaDataProps): WithContext<Review> | null => {
  if (!quoteText || !authorName || !authorTitle || !authorOrg) return null;
  const schemaData: WithContext<Review> = {
    '@context': 'https://schema.org',
    '@type': 'Review',
    reviewBody: quoteText || '',
    author: {
      '@type': 'Person',
      name: authorName || '',
    },
    itemReviewed: {
      '@type': 'Course',
      name: authorTitle || '',
      provider: {
        '@type': 'Organization',
        name: authorOrg || '',
      },
    },
  };

  return schemaData;
};
