import type { KeyedObject, PremiumPageType } from 'bundles/xddp/types';

export const PREMIUM_PAGE_TYPES: KeyedObject<PremiumPageType> = {
  DDP: 'DDP',
  DLP: 'DLP',
  PremiumCertificate: 'PremiumCertificate',
  PremiumCertificateLandingPage: 'PremiumCertificateLandingPage',
  DegreeDescription: 'DegreeDescription',
  PremiumHub: 'PremiumHub',
  UniversityProgram: 'UniversityProgram',
};

export const LOCAL_STORAGE_EOI_SECTION_DATA_KEY = 'X-EOI-Preview-Data';
export const LOCAL_STORAGE_EOI_PRODUCT_ID_KEY = 'X-EOI-ProductId';
export const LOCAL_STORAGE_EOI_EMBEDDED_SIGN_IN_DATA = 'X-EOI-EmbeddedSignInData';

// One hour 1000 * 60 * 60
export const ONE_HOUR_IN_MS = 3600000;

// Show only top three FAQs https://coursera.atlassian.net/browse/ACQU-1457
export const MAX_FAQS_TO_SHOW = 3;

export enum TestimonialCardsVariant {
  DOUBLE = 'DOUBLE',
  TRIPLE = 'TRIPLE',
}
