import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props as LocalNotificationProps } from './LocalNotification';

export const classes = generateEmotionClasses('localNotification', [
  'inlineAction',
  'content',
  'iconContentInner',
  'contentInner',
  'inlineActionContainer',
  'dismissible',
  'hasDismiss',
  'actions',
  'hasActions',
]);

export const severities = {
  information: css`
    background: var(--cds-color-feedback-background-informative-weak);
  `,
  success: css`
    background: var(--cds-color-feedback-background-success-weak);
  `,
  error: css`
    background: var(--cds-color-feedback-background-error-weak);
  `,
  warning: css`
    background: var(--cds-color-feedback-background-warning-weak);
  `,
};

export const severityColor = {
  information: 'var(--cds-color-feedback-informative)',
  success: 'var(--cds-color-feedback-success)',
  error: 'var(--cds-color-feedback-error)',
  warning: 'var(--cds-color-feedback-warning)',
};

export const getLocalNotificationCss = (props: LocalNotificationProps) => css`
  display: flex;
  padding: var(--cds-spacing-150) var(--cds-spacing-200);
  border-left: 4px solid ${severityColor[props.severity]};
  border-radius: var(--cds-border-radius-25);
  ${severities[props.severity as NonNullable<keyof typeof severities>]};

  .${classes.content} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: auto;

    ${breakpoints.down('xs')} {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }

  .${classes.iconContentInner} {
    display: flex;
    flex: 1 1 auto;
  }

  .${classes.contentInner} {
    margin-left: var(--cds-spacing-150);
    flex: 1 1 auto;

    ${breakpoints.down('xs')} {
      margin: 0;
    }
  }

  .${classes.inlineAction} {
    ${breakpoints.down('sm')} {
      display: none;
    }

    margin-right: 16px;
  }

  .${classes.inlineActionContainer} {
    flex: none;

    ${breakpoints.down('sm')} {
      align-self: flex-start;
    }

    ${breakpoints.down('xs')} {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 36px;
      margin: 0 0 var(--cds-spacing-150) 0;
    }

    & > button:last-of-type {
      margin-right: -8px;
    }
  }

  &.${classes.dismissible} {
    .${classes.inlineActionContainer} {
      ${breakpoints.up('sm')} {
        margin-left: var(--cds-spacing-300);
      }

      ${breakpoints.up('md')} {
        margin-left: var(--cds-spacing-600);
      }
    }
  }

  &.${classes.hasActions} {
    .${classes.inlineActionContainer} {
      align-self: flex-start;
    }
  }
`;
