import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Dialog, Typography2 } from '@coursera/cds-core';

import PartnerHelpLink from 'bundles/authoring/common/components/PartnerHelpLink';

import _t from 'i18n!nls/admin-v2';

type Props = {
  onClose: () => void;
};

const GroupsDeprecationModal: React.FunctionComponent<Props> = ({ onClose }) => {
  return (
    <Dialog aria-describedby="dialog-content" open={true} onClose={onClose}>
      <Dialog.HeadingGroup>{_t('Update to navigation')}</Dialog.HeadingGroup>

      <Dialog.Content>
        <Typography2 variant="subtitleLarge" component="p">
          {_t('The Groups tab has been removed')}
        </Typography2>

        <Typography2 component="p">{_t('To complete group-management tasks, please do the following:')}</Typography2>
        <ol>
          <Typography2 component="li">{_t('Click on the course you want to configure')}</Typography2>

          <Typography2 component="li">
            {_t('Click on the private session of that course that you want to configure')}
          </Typography2>

          <Typography2 component="li">{_t('Click on the Settings tab at the top of the page')}</Typography2>

          <Typography2 component="li">{_t('Click on the Group Settings option on the side menu')}</Typography2>
        </ol>
        <FormattedMessage
          message={_t('If you have any questions, please {contactSupportLink}.')}
          contactSupportLink={<PartnerHelpLink linkText={_t('contact support')} />}
        />
      </Dialog.Content>

      <Dialog.Actions>
        <Button type="button" size="medium" variant="primary" onClick={onClose}>
          {_t('OK')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default GroupsDeprecationModal;
