import DegreesGrowthExperiments from 'bundles/epic/clients/DegreesGrowth';
import { driftCode } from 'bundles/expression-of-interest/utils/drift';
import type { Drift } from 'bundles/expression-of-interest/utils/driftTypes';

declare global {
  interface Window {
    drift: Drift | undefined;
  }
}

/**
 * Determines if we should add Drift to the page, as decided via the Epic experiment.
 * @returns boolean
 */
export const shouldShowDrift = () => DegreesGrowthExperiments.get('showDriftChat');

/**
 * Adds the Drift chat widget to a page via the script tag
 * Only run this function in useEffect or componentDidMount, otherwise `document` does not exist
 */
export const addDriftChatWidget = () => {
  if (document) {
    const driftScript = document.createElement('script');
    driftScript.async = true;
    driftScript.type = 'text/javascript';
    driftScript.innerHTML = driftCode;
    document.head.appendChild(driftScript);
  }
};

/**
 * Sets the bottom position of the Drift icon and chat window.
 * This allows us to move around the Drift widget so it doesn't overlap other elements.
 * @param bottom This sets how far from the bottom of the viewport that the chat & icon widgets should appear at.
 */
export const setDriftChatWidgetYAxis = (bottom: number) => {
  if (document) {
    const driftChat: HTMLDivElement | null = document.querySelector('#drift-frame-chat');
    const driftController: HTMLDivElement | null = document.querySelector('#drift-frame-controller');
    if (driftChat && driftController) {
      driftChat.style.transition = 'bottom 0.3s';
      driftController.style.transition = 'bottom 0.3s';

      driftChat.style.bottom = `${bottom}px`;
      driftController.style.bottom = `${bottom}px`;
    }
  }
};

/**
 * Controls the visibility of the Drift icon and chat window.
 * @param shouldShow A boolean controlling if the Drift widget should be shown or hidden.
 */
export const setDriftVisibility = (shouldShow: boolean) => {
  const drift = window?.drift;

  if (drift) {
    drift.on('ready', () => {
      if (shouldShow) {
        drift.api.widget.show();
      } else {
        drift.api.widget.hide();
      }
    });
  }
};
