import { DEFAULT_SEARCH_FILTERS, IS_PART_OF_COURSERA_PLUS } from 'bundles/search-common/constants/filter';

import _t from 'i18n!nls/search-common';

export type FilterConfig = {
  getName: (isCourseraLiteFilter?: boolean) => string;
  getNameOnMobile: (isCourseraLiteFilter?: boolean) => string;
  property: string;
  showOutsideOfAllTab: boolean;
  showAllItems: boolean;
  filtersListOrder?: string;
  getToolTip?: () => string;
  getSupportText?: () => string;
};

export { DEFAULT_SEARCH_FILTERS, SEARCH_FILTERS_WITH_COURSERA_PLUS } from 'bundles/search-common/constants/filter';

export const FILTERS_CONFIG: Record<string, FilterConfig> = {
  language: {
    getName: () => _t('Language'),
    getNameOnMobile: () => _t('Language'),
    property: 'language',
    showOutsideOfAllTab: false,
    showAllItems: false,
    getToolTip: () => _t('The language used throughout the course, in both instruction and assessments.'),
  },
  subtitleLanguage: {
    getName: () => _t('Subtitles'),
    getNameOnMobile: () => _t('Subtitles'),
    property: 'subtitleLanguage',
    showOutsideOfAllTab: false,
    showAllItems: false,
  },
  productDifficultyLevel: {
    getName: () => _t('Level'),
    getNameOnMobile: () => _t('Level'),
    property: 'productDifficultyLevel',
    showOutsideOfAllTab: false,
    showAllItems: true,
  },
  productDuration: {
    getName: () => _t('Duration'),
    getNameOnMobile: () => _t('Duration'),
    property: 'productDuration',
    showOutsideOfAllTab: false,
    showAllItems: true,
  },
  partners: {
    getName: () => _t('Educator'),
    getNameOnMobile: () => _t('Educator'),
    property: 'partners',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  skills: {
    getName: () => _t('Skills'),
    getNameOnMobile: () => _t('Skills'),
    property: 'skills',
    showOutsideOfAllTab: false,
    showAllItems: false,
  },
  productTypeDescription: {
    getName: () => _t('Learning Product'),
    getNameOnMobile: () => _t('Learning Product'),
    property: 'productTypeDescription',
    showOutsideOfAllTab: true,
    showAllItems: true,
  },
  [IS_PART_OF_COURSERA_PLUS]: {
    getName: (isCourseraLiteFilter?: boolean) => (isCourseraLiteFilter ? _t('Coursera Lite') : _t('Coursera Plus')),
    getNameOnMobile: (isCourseraLiteFilter?: boolean) =>
      isCourseraLiteFilter ? _t('Coursera Lite') : _t('Coursera Plus'),
    property: IS_PART_OF_COURSERA_PLUS,
    showOutsideOfAllTab: true,
    showAllItems: true,
  },
  topic: {
    getName: () => _t('Subject'),
    getNameOnMobile: () => _t('Subject'),
    property: 'topic',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  hasLabAssignments: {
    getName: () => _t('Labs'),
    getNameOnMobile: () => _t('Labs'),
    property: 'hasLabAssignments',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  degreeLevel: {
    getName: () => _t('Program Level'),
    getNameOnMobile: () => _t('Program Level'),
    property: 'degreeLevel',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  partnerCountry: {
    getName: () => _t('Country'),
    getNameOnMobile: () => _t('Country'),
    property: 'partnerCountry',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  degreePathwayType: {
    getName: () => _t('Pathway Degrees'),
    getNameOnMobile: () => _t('Pathway Degrees'),
    property: 'degreePathwayType',
    showOutsideOfAllTab: true,
    showAllItems: false,
    getSupportText: () => _t('Build toward a degree by completing approved courses or programs'),
  },
  degreeMonthsTillNextAdmission: {
    getName: () => _t('Upcoming Application Deadline'),
    getNameOnMobile: () => _t('Upcoming Application Deadline'),
    property: 'degreeMonthsTillNextAdmission',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
  degreeHasScholarship: {
    getName: () => _t('Financial Aid'),
    getNameOnMobile: () => _t('Financial Aid'),
    property: 'degreeHasScholarship',
    showOutsideOfAllTab: true,
    showAllItems: false,
  },
};

export function extendDefaultFilterList(defaultList: string[], shouldShowCourseraPlusFilter: boolean) {
  let filterList = defaultList;
  if (shouldShowCourseraPlusFilter) {
    filterList = [IS_PART_OF_COURSERA_PLUS, ...defaultList];
  }
  return filterList;
}

const getFiltersList = (options?: {
  shouldShowCourseraPlusFilter?: boolean;
  shouldHideSkillsFilter?: boolean;
  filtersListOrder?: string[];
}): FilterConfig[] => {
  const { shouldShowCourseraPlusFilter, filtersListOrder, shouldHideSkillsFilter } = options || {};
  const SKILLS_FILTER_KEY = 'skills';

  let filterKeys = filtersListOrder ?? extendDefaultFilterList(DEFAULT_SEARCH_FILTERS, !!shouldShowCourseraPlusFilter);

  if (shouldHideSkillsFilter) {
    filterKeys = filterKeys.filter((key) => key !== SKILLS_FILTER_KEY);
  }

  return filterKeys.map((filterKey) => FILTERS_CONFIG[filterKey]).filter(Boolean);
};

export default getFiltersList;
