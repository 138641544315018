/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useRetracked } from 'js/lib/retracked';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Button, Grid, Typography, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';
import type { SectionName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import ActionBar from 'bundles/expression-of-interest/components/ActionBar';
import { EoiDialog } from 'bundles/expression-of-interest/components/EoiDialog';
import { EoiOptimizationsContextProvider } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import HeaderLogo from 'bundles/page-header/components/HeaderLogo';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import Container from 'bundles/premium-hub/components/shared/Container';
import HubBreadcrumbs from 'bundles/premium-hub/components/shared/HubBreadcrumbs';
import type { PremiumHubPageType, ProductType } from 'bundles/premium-hub/constants';
import { HubPages } from 'bundles/premium-hub/constants';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import CroppedBottomCShapeMask from 'images/premium-hub/CroppedBottomCShapeMask.png';
import CroppedCShapeMask from 'images/premium-hub/CroppedCShapeMask.png';

import _t from 'i18n!nls/premium-hub';

type Props = {
  slug: string;
  productId: string;
  productType?: (typeof ProductType)[keyof typeof ProductType];
  pageHeading?: Maybe<string>;
  pageSubheading?: Maybe<string>;
  heroImage?: Maybe<string>;
  categoryName?: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  surveyProductType: string;
};

type BannerConfig = {
  bannerColor: string;
  maskColor: string;
  maskOpacity: number;
  headingColor: 'body' | 'invertBody';
  subheadingColor: 'supportText' | 'invertBody';
  eoiButtonVariant: 'primary' | 'primaryInvert';
};

const styles: Record<string, CSSProperties> = {
  hubBannerContainer: (theme: Theme, bannerColor: string) => ({
    background: `${bannerColor}`,
  }),
  hubBannerWrapper: (bannerColor: string, isEOIPresent: boolean, theme: Theme) => ({
    width: '100vw',
    position: 'relative',
    maxWidth: '100%',

    [breakpoints.down('xs')]: {
      background: bannerColor,
    },

    '.BannerContainer': {
      position: 'relative',
      maxWidth: '100%',
    },

    '.BannerRow': {
      [breakpoints.down('sm')]: {
        padding: '0 0 var(--cds-spacing-200)',
      },
    },

    '.BannerContentCol': {
      ...(!isEOIPresent && { marginBottom: 'var(--cds-spacing-400)' }),
      [breakpoints.down('sm')]: {
        padding: 0,
        margin: 'var(--cds-spacing-200) 0 0 0',
      },
    },
  }),
  coreHubBackground: (theme: Theme, heroImage: string) => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${heroImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'initial',
    maskImage: `url(${CroppedCShapeMask})`,
    maskRepeat: 'no-repeat',
    maskPosition: '100% 75%',
    maskSize: 'auto',
    backgroundPositionX: '160%',
    backgroundPositionY: '25%',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  background: (theme: Theme, maskColor: string, maskOpacity: number) => ({
    width: '100%',
    backgroundColor: maskColor,
    opacity: maskOpacity,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maskImage: `url(${CroppedBottomCShapeMask})`,
    maskRepeat: 'no-repeat',
    maskPosition: 'center',
    maskSize: 'cover',
    [breakpoints.down('md')]: {
      display: 'none',
    },
    [breakpoints.up('lg')]: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'initial',
      maskImage: `url(${CroppedBottomCShapeMask})`,
      maskRepeat: 'no-repeat',
      maskPosition: '130%',
      maskSize: '60% 100%',
    },
  }),
  bannerContent: () => ({
    maxWidth: '100%',
  }),
  mainHeroContainer: (theme: Theme) => ({
    padding: 'var(--cds-spacing-600) 0',
    [breakpoints.down('md')]: {
      padding: 'var(--cds-spacing-300) 0',
      justifyContent: 'center',
    },
    [breakpoints.down('sm')]: {
      padding: '0',
      justifyContent: 'center',
    },
  }),
  breadcrumbsContainer: () => ({
    position: 'relative',
  }),
  heading: (theme: Theme) => ({
    margin: '0 0 var(--cds-spacing-200) 0',
    padding: '0 0 0 0',
    [breakpoints.down('sm')]: {
      margin: '0 0 var(--cds-spacing-150) 0',
      textAlign: 'left',
      fontSize: '32px',
    },
  }),
  subheading: (theme: Theme) => ({
    margin: '0 0 var(--cds-spacing-300) 0',
    padding: '0 var(--cds-spacing-1400) 0 0',
    [breakpoints.down('sm')]: {
      margin: '0 0 var(--cds-spacing-200) 0',
      padding: '0 0 0 0',
      textAlign: 'left',
      display: 'none',
    },
  }),
  buttonsContainer: (theme: Theme) => ({
    [breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      justifyContent: 'center',
    },
  }),
  button: (theme: Theme) => ({
    marginRight: 'var(--cds-spacing-200)',
    [breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      marginRight: '0',
      marginBottom: 'var(--cds-spacing-200)',
    },
  }),
};

const getBannerColors = (pageType: PremiumHubPageType): BannerConfig => {
  let bannerConfig: BannerConfig = {
    bannerColor: 'var(--cds-color-neutral-background-primary-weak)',
    maskColor: 'var(--cds-color-neutral-disabled)',
    maskOpacity: 1,
    headingColor: 'body',
    subheadingColor: 'supportText',
    eoiButtonVariant: 'primary',
  };

  switch (pageType) {
    case HubPages.DegreesCategoryHubPage:
      bannerConfig = {
        ...bannerConfig,
        bannerColor: 'var(--cds-color-emphasis-primary-background-xweak)',
        maskColor: 'var(--cds-color-interactive-primary)',
        maskOpacity: 0.1,
      };
      break;

    case HubPages.LandingPage:
      bannerConfig = {
        ...bannerConfig,
        bannerColor: 'var(--cds-color-emphasis-primary-background-xxstrong)',
        maskColor: 'var(--cds-color-emphasis-primary-background-xweak)',
        maskOpacity: 0.1,
        headingColor: 'invertBody',
        subheadingColor: 'invertBody',
        eoiButtonVariant: 'primaryInvert',
      };
      break;

    default:
      break;
  }

  return bannerConfig;
};

const HeroBanner: React.FunctionComponent<Props> = ({
  slug,
  pageHeading,
  pageSubheading,
  categoryName,
  productId,
  productType,
  surveyProductType,
  currentHubPage,
  heroImage,
}) => {
  const theme = useTheme();
  const trackComponent = useRetracked();
  const trackV3 = useTracker();
  const [isScrollPastBanner, setScrollPastBanner] = useState<boolean>(false);
  const [isDcatEoiOpen, setIsDcatEoiOpen] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      const bannerEoi = document.getElementById('RequestInfoButton');

      if (!bannerEoi) return;

      const bannerBottom = bannerEoi.getBoundingClientRect().bottom;
      if (bannerBottom < 0) {
        setScrollPastBanner(true);
      } else {
        setScrollPastBanner(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!pageHeading) {
    return null;
  }

  const { bannerColor, maskColor, maskOpacity, headingColor, subheadingColor, eoiButtonVariant } =
    getBannerColors(currentHubPage);
  const onCoreHubPage = currentHubPage === HubPages.MainHubPage;
  const onLandingPage = currentHubPage === HubPages.LandingPage;
  const displayBreadcrumbs = !onCoreHubPage && !onLandingPage;

  const handleEoiClick = (v2TrackingName: string, v3SectionName: SectionName) => {
    trackComponent({
      trackingName: v2TrackingName,
      trackingData: { currentPage: currentHubPage },
      action: 'click',
    });

    trackV3('interact_eoi', {
      eoiInteraction: 'open_form',
      pageSection: {
        sectionName: v3SectionName,
        subindex: 1, // there is only one EOI CTA on the page (the banner and sticky header are considered the same)
      },
    });

    setIsDcatEoiOpen(true);
  };

  const actionBarData = {
    isFixed: isScrollPastBanner,
    partnerName: 'Coursera Logo',
    programName: categoryName || pageHeading,
    partnerMonotoneLogo: (
      <HeaderLogo
        disableUserInteraction={false}
        enableCourseraLogoOnly={true}
        isOpened={false}
        displaySwitcher={false}
        logoWrapper="div"
      />
    ),
  };

  const completeProductId = tupleToStringKey([surveyProductType, productId]);
  const eoiProductType =
    currentHubPage === HubPages.MainHubPage ? ProductTypes.DEGREE_HUB : ProductTypes.DEGREE_CATEGORY;

  const renderBannerContent = () => (
    <Grid item container css={styles.bannerContent} className="BannerContentCol" md={12} lg={12}>
      <Grid item lg={7} xs={12}>
        <div css={styles.mainHeroContainer}>
          {displayBreadcrumbs && (
            <div css={styles.breadcrumbsContainer}>
              <HubBreadcrumbs productName={categoryName || pageHeading} productType={productType} inHeroBannerTest />
            </div>
          )}
          <Typography variant="d2semibold" component="h1" color={headingColor} css={styles.heading}>
            {pageHeading}
          </Typography>
          {pageSubheading && (
            <Typography variant="h2" component="p" color={subheadingColor} css={styles.subheading}>
              {pageSubheading}
            </Typography>
          )}
          <div css={styles.buttonsContainer}>
            <Button
              id="RequestInfoButton"
              component={TrackedButton}
              variant={eoiButtonVariant}
              onClick={() => handleEoiClick('hero_banner_request_info_button', 'hero_banner')}
              withVisibilityTracking={false}
              requireFullyVisible={false}
              css={styles.button(theme)}
            >
              {_t('Request info')}
            </Button>

            <ActionBar actionBarData={actionBarData}>
              <div className="eoi-buttons-container">
                <Button
                  variant="primary"
                  className="rc-EmailMeButton"
                  component={TrackedButton}
                  withVisibilityTracking={false}
                  requireFullyVisible={false}
                  onClick={() => handleEoiClick('hero_banner_request_info_sticky_button', 'sticky_page_header')}
                  fullWidth
                >
                  {_t('Request info')}
                </Button>
              </div>
            </ActionBar>

            <EoiOptimizationsContextProvider slug={slug} productType={eoiProductType}>
              <EoiDialog
                isOpen={isDcatEoiOpen}
                onClose={() => setIsDcatEoiOpen(false)}
                onOpen={() => setIsDcatEoiOpen(true)}
                productId={completeProductId}
              />
            </EoiOptimizationsContextProvider>
          </div>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div data-testid="HeroBanner" css={styles.hubBannerContainer(theme, bannerColor)}>
      <TrackedDiv
        trackingName="hero_banner"
        trackingData={{ currentPage: currentHubPage }}
        withVisibilityTracking={true}
        atMostOnce={true}
        trackClicks={false}
        css={styles.hubBannerWrapper(bannerColor, !!productId, theme)}
      >
        <Container>
          <Grid container className="BannerContainer BannerRow">
            <Grid
              item
              id="background"
              css={
                onCoreHubPage
                  ? styles.coreHubBackground(theme, heroImage)
                  : styles.background(theme, maskColor, maskOpacity)
              }
            />
            {renderBannerContent()}
          </Grid>
        </Container>
      </TrackedDiv>
    </div>
  );
};

export default React.memo(HeroBanner);
