import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';

import { SEARCH_MAIN_INDEX_ID, SEARCH_NEW_INDEX_ID } from 'bundles/search-common/constants';
import { SearchContext } from 'bundles/search-common/providers/SearchContext';
import type { SearchConfig, SearchResults, SearchStates } from 'bundles/search-common/providers/searchTypes';
import type { SearchQuery, SearchQueryVariables } from 'bundles/search-common/queries/__generated__/searchQuery';
import SEARCH_QUERY from 'bundles/search-common/queries/searchQuery.graphql';
import {
  getFilterCategory,
  getProviderFetchMoreUpdateQuery,
  getQueryParamsFromSearchRequests,
  mergeFiltersAndStateWithResults,
} from 'bundles/search-common/utils/providerUtils';

export type Return = {
  isLoading: boolean;
  error?: ApolloError;
  results?: SearchResults;
  loadMore?: (pageNumber: number, activeIndex?: string) => void;
};

/**
 * useSearchProviderQuery is a hook that handles search provider requests.
 * It takes in searchStates and searchConfigs as parameters and returns an object with loading, error, and results.
 * This hook is specific for SearchProvider due to its active filters logic that makes additional requests to populate
 * the search filters options regardless of user selections. After making the request, it's necessary to combine the results into the main
 * index to incorporate the full filter options.
 *
 * @param {SearchStates} searchStates - The search states to be used, default is an empty object.
 * @param {SearchConfig[]} searchConfigs - The initial search configs required just to add back facetFilters (enterpriseIndexConfig).
 * @returns {Return} An object containing isLoading, error, and results.
 *
 * @example
 * useSearchProviderQuery(searchStates, searchConfigs);
 *
 * @note This function should only be used for SearchProvider component.
 */
function useSearchProviderQuery(searchStates: SearchStates = {}, searchConfigs: SearchConfig[], ssr: boolean): Return {
  const searchContext = useContext(SearchContext);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // this will set true only if the component is mounted on client side
    // it will be false during SSR and rehydration
    setIsClient(true);
  }, []);

  if (searchContext === undefined) {
    throw new Error('useSearchProviderQuery must be used within a SearchProvider');
  }
  const searchStatesArray = useMemo(() => Object.values(searchStates), [searchStates]);

  const mainSearchIndexPosition = searchStatesArray.findIndex((searchState) => searchState.id === SEARCH_MAIN_INDEX_ID);
  const newSearchIndexPosition = searchStatesArray.findIndex((searchState) => searchState.id === SEARCH_NEW_INDEX_ID);

  const allSearchRequestQueryParams = useMemo(
    () => getQueryParamsFromSearchRequests(searchConfigs, searchStatesArray),
    [searchConfigs, searchStatesArray]
  );

  const filterCategory: string[] = useMemo(
    () => getFilterCategory(allSearchRequestQueryParams),
    [allSearchRequestQueryParams]
  );

  const { loading, error, data, fetchMore } = useQuery<SearchQuery, SearchQueryVariables>(SEARCH_QUERY, {
    variables: { requests: allSearchRequestQueryParams },
    context: { clientName: 'gatewayGql' },
    notifyOnNetworkStatusChange: isClient, // needed for fetchMore, only needed in client anyways
    errorPolicy: 'all', // needed so we still render products even if product card even if some are missing from product card application
    ssr,
  });

  const loadNextPage = (pageNumber: number, activeIndex?: string) => {
    // increment page variable for request
    const isUsingPrimaryIndex = activeIndex === SEARCH_MAIN_INDEX_ID;
    const indexToLoadMoreOn = isUsingPrimaryIndex ? mainSearchIndexPosition : newSearchIndexPosition;
    const updatedSearchParams = allSearchRequestQueryParams.map((searchRequest, i) => {
      if (i === indexToLoadMoreOn) {
        const modifiedRequest = { ...searchRequest };
        modifiedRequest.cursor = pageNumber.toString();
        return modifiedRequest;
      } else {
        return searchRequest;
      }
    });
    // make request and combine results
    fetchMore({
      variables: { requests: updatedSearchParams },
      updateQuery: getProviderFetchMoreUpdateQuery(indexToLoadMoreOn),
    });
  };

  const results = useMemo(
    () => mergeFiltersAndStateWithResults(data, searchStatesArray, searchStates, filterCategory, searchConfigs),
    [data, searchStatesArray, searchStates, filterCategory, searchConfigs]
  );

  return {
    isLoading: loading,
    error,
    results,
    loadMore: loadNextPage,
  };
}

export default useSearchProviderQuery;
