/** @jsx jsx */
import { ThemeProvider, jsx } from '@emotion/react';

import * as React from 'react';

import Search from 'bundles/premium-hub/components/search/Search';
import { getSearchConfigs } from 'bundles/premium-hub/components/search/searchUtils';
import usePostgraduateDiplomaData from 'bundles/premium-hub/components/search/usePostgraduateDiplomaData';
import type { HubPages } from 'bundles/premium-hub/constants';
import type { DegreeContentfulMetadata, PremiumProductWithMetadata } from 'bundles/premium-hub/types';
import LearnerProvider from 'bundles/search-common/providers/LearnerProvider';
import RouterProvider from 'bundles/search-common/providers/RouterProvider';
import SearchProvider from 'bundles/search-common/providers/SearchProvider';
import { searchTheme } from 'bundles/search-common/searchTheme';

type SearchContainerProps = {
  presetFilters?: string[];
  hiddenFilters?: string[];
  dreOrderedProducts?: PremiumProductWithMetadata[];
  atAGlanceData?: DegreeContentfulMetadata[];
  slug: string;
  productId: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  surveyProductType: string;
};

export const SearchContainer = ({
  presetFilters,
  hiddenFilters,
  dreOrderedProducts,
  atAGlanceData,
  productId,
  currentHubPage,
  surveyProductType,
  slug,
}: SearchContainerProps) => {
  const searchConfigs = getSearchConfigs(presetFilters, hiddenFilters, currentHubPage);
  const postgraduateDiplomas = usePostgraduateDiplomaData(atAGlanceData) ?? [];
  const dreOrderedProductsToUse = dreOrderedProducts ? [...dreOrderedProducts, ...postgraduateDiplomas] : undefined;
  return (
    <ThemeProvider theme={searchTheme}>
      <RouterProvider>
        <LearnerProvider>
          <SearchProvider searchConfigs={searchConfigs}>
            <Search
              presetFilters={presetFilters}
              dreOrderedProducts={dreOrderedProductsToUse}
              atAGlanceData={atAGlanceData}
              slug={slug}
              productId={productId}
              currentHubPage={currentHubPage}
              surveyProductType={surveyProductType}
            />
          </SearchProvider>
        </LearnerProvider>
      </RouterProvider>
    </ThemeProvider>
  );
};
