import type * as React from 'react';

import type { ButtonName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { useAuthModalTrackerContext } from 'bundles/authentication/shared/components/authentication-modal/contexts/AuthenticationTracking';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  buttonName: ButtonName;
};

export function useAuthenticationModalTracking({ onClick, buttonName }: Props) {
  const trackEvent = useTracker();
  const modalName = useAuthModalTrackerContext()?.modalName;

  const trackClick = (e: React.MouseEvent<HTMLElement>) => {
    const trackingData = {
      button: {
        name: buttonName,
      },
      ...(modalName && { modal: { name: modalName } }),
    };

    trackEvent('click_button', trackingData);
    onClick?.(e);
  };

  return trackClick;
}

export default useAuthenticationModalTracking;
