import * as React from 'react';

import requestUrlInfo from 'js/lib/requestUrlInfo';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import GlobalFooter from 'bundles/page-footer/components/global-footer/GlobalFooter';
import SeoGlobalFooter from 'bundles/page-footer/components/seo-sub-footer/SeoGlobalFooter';
import ErrorBoundaryWithLogging from 'bundles/page/components/ErrorBoundaryWithLogging';
import SeoPathConfigurationQueryWrapper from 'bundles/seo/api/SeoPathConfigurationQueryWrapper';

import 'css!bundles/page-footer/components/global-footer/__styles__/GlobalFooter';

type SEOFooterProps = {
  isStickyBarPresent?: boolean;
};

type PageFooterProps = SEOFooterProps & {
  isDirectoryPage?: boolean;
  elRef?: React.RefObject<HTMLDivElement>;
};

const SeoGlobalFooterContent: React.FC<SEOFooterProps> = ({ isStickyBarPresent }) => {
  const {
    location: { pathname, search },
  } = useRouter();
  const hostname = requestUrlInfo().hostname;

  return (
    <ErrorBoundaryWithLogging ErrorComponent={() => <SeoGlobalFooter isStickyBarPresent={isStickyBarPresent} />}>
      <SeoPathConfigurationQueryWrapper path={hostname + pathname + search}>
        <SeoGlobalFooter isStickyBarPresent={isStickyBarPresent} />
      </SeoPathConfigurationQueryWrapper>
    </ErrorBoundaryWithLogging>
  );
};

const PageFooter: React.FC<PageFooterProps> = ({ isStickyBarPresent, isDirectoryPage, elRef }) => {
  let pageFooterContent: JSX.Element;
  if (!user.isAuthenticatedUser() && !isDirectoryPage) {
    pageFooterContent = <SeoGlobalFooterContent isStickyBarPresent={isStickyBarPresent} />;
  } else {
    pageFooterContent = <GlobalFooter isStickyBarPresent={isStickyBarPresent} />;
  }

  return (
    <div ref={elRef} className="page-footer-container" role="contentinfo">
      {pageFooterContent}
    </div>
  );
};

export default PageFooter;
