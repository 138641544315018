import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import { SvgFacebook } from '@coursera/coursera-ui/svg';

import SocialButton from 'bundles/authentication/components/SSOButton';
import useAuthenticationModalTracking from 'bundles/authentication/shared/hooks/useAuthenticationModalTracking';
import withSocialSSO from 'bundles/authentication/with/SocialSSO';
import withSingleTracked from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/authentication';

const TrackedSocialButton = withSingleTracked({ type: 'BUTTON' })(SocialButton);

type Props = {
  className?: string;
  onClick?: () => void;
  btnLabel?: string;
  customStyles?: SerializedStyles;
  iconColor?: string;
};

const Facebook = React.forwardRef(
  ({ className, onClick, btnLabel, customStyles, iconColor }: Props, ref: React.Ref<HTMLElement>) => {
    const handleOnClick = useAuthenticationModalTracking({ onClick, buttonName: 'signup_with_facebook' });
    return (
      <TrackedSocialButton
        className={classNames(className, 'FacebookAlternative')}
        customStyles={customStyles}
        icon={SvgFacebook}
        iconColor={iconColor ?? '#3b5998'}
        onClick={handleOnClick}
        trackingName="continue_with_facebook_auth_btn"
        _refAltAsTrueRef={ref}
      >
        {btnLabel ?? _t('Continue with Facebook')}
      </TrackedSocialButton>
    );
  }
);
Facebook.displayName = 'Facebook';

export default withSocialSSO(Facebook, 'facebook');
