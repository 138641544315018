/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import { useLocation } from 'js/lib/useRouter';
import user from 'js/lib/user';

import type { DialogProps } from '@coursera/cds-core';
import { Button, Dialog } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import ActionBar from 'bundles/expression-of-interest/components/ActionBar';
import EoiDialog from 'bundles/expression-of-interest/components/EoiDialog';
import { EoiOptimizationsContextProvider } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import { INTERACTION_EVENTS_MAP } from 'bundles/expression-of-interest/constants';
import { getActionQueryParam } from 'bundles/expression-of-interest/utils/util';
import HeaderLogo from 'bundles/page-header/components/HeaderLogo';
import TrackedButton from 'bundles/page/components/TrackedButton';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import _t from 'i18n!nls/premium-hub';

type Props = {
  slug: string;
  productId: string;
  pageHeading?: Maybe<string>;
  categoryName?: string;
  surveyProductType: string;
};

const styles = {
  eoiDialog: css`
    /* Remove border and shadow from EOI component and hide back button */
    & .rc-EmailMeInfoFields,
    & .rc-EoiContainer .section-embedded {
      padding: 0;
      box-shadow: none;
      border: none;
    }

    & #degrees-eoi-back-button {
      display: none;
    }
  `,
  eoiContent: (isUserLoggedIn: boolean) => css`
    padding-bottom: ${isUserLoggedIn ? '20px' : '0'};
  `,
};

type EoiContainingElementProps = {
  shouldUseDialog: boolean;
  children: React.ReactChild;
} & DialogProps;

export const EoiContainingElement = ({ shouldUseDialog, children, ...dialogProps }: EoiContainingElementProps) => {
  return shouldUseDialog ? (
    <Dialog {...dialogProps} css={styles.eoiDialog}>
      <Dialog.Content>
        <div css={styles.eoiContent(user?.isAuthenticatedUser() ?? false)}>{children}</div>
      </Dialog.Content>
    </Dialog>
  ) : (
    <>{children}</>
  );
};

const scrollToNearestEOIContainer = () => {
  const eoiConfirmationEl = document.querySelector('.rc-EoiContainer');
  const degreeFilterEOIEL = document.querySelector('.rc-DegreesFilterAndEoiContainer');

  if (degreeFilterEOIEL) {
    degreeFilterEOIEL.scrollIntoView({ block: 'nearest' });
  } else if (eoiConfirmationEl) {
    eoiConfirmationEl.scrollIntoView({ block: 'nearest' });
  }
};

const DegreesStickyActionBar = (props: Props) => {
  const location = useLocation();
  const [shouldShowEOISurvey, setShouldShowEOISurvey] = useState<boolean>(false);
  const { slug, pageHeading, categoryName, productId, surveyProductType } = props;
  const [isScrollPastBanner, setScrollPastBanner] = useState(false);
  const isOnDegreesHubPage = /^\/degrees(\/browse)?\/?$/g.test(location.pathname);

  const track = useTracker();
  const { v2TrackingName, v3EventKey } = INTERACTION_EVENTS_MAP.emailMeInfo;

  useEffect(() => {
    // This ensures that the EOI form values are carried forward to the next stage after user is logged in.
    const actionQueryParam = getActionQueryParam(location);
    if (actionQueryParam) {
      setShouldShowEOISurvey(true);
    }

    const onScroll = () => {
      const degreeFiltersEl = document.getElementById('HubBanner');

      if (!degreeFiltersEl) return;

      const bannerBottom = degreeFiltersEl.getBoundingClientRect().bottom;
      if (bannerBottom < 0) {
        setScrollPastBanner(true);
      } else {
        setScrollPastBanner(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowEOISurvey = () => setShouldShowEOISurvey(true);

  const actionBarData = {
    isFixed: isScrollPastBanner,
    partnerName: 'Coursera Logo',
    programName: categoryName || pageHeading,
    partnerMonotoneLogo: (
      <HeaderLogo
        disableUserInteraction={false}
        enableCourseraLogoOnly={true}
        isOpened={false}
        displaySwitcher={false}
        logoWrapper="div"
      />
    ),
  };

  const stickyHeader = (
    <ActionBar actionBarData={actionBarData}>
      <div className="eoi-buttons-container">
        <Button
          variant="primary"
          trackingName={v2TrackingName}
          className="rc-EmailMeButton"
          component={TrackedButton}
          withVisibilityTracking={false}
          requireFullyVisible={false}
          onClick={() => {
            handleShowEOISurvey();
            scrollToNearestEOIContainer();
            track('interact_eoi', {
              eoiInteraction: v3EventKey,
            });
          }}
          fullWidth
        >
          {_t('Email Me Info')}
        </Button>
      </div>
    </ActionBar>
  );

  const completeProductId = tupleToStringKey([surveyProductType, productId]);
  const productType = isOnDegreesHubPage ? ProductTypes.DEGREE_HUB : ProductTypes.DEGREE_CATEGORY;

  return isOnDegreesHubPage ? (
    <>
      {!shouldShowEOISurvey && stickyHeader}
      <EoiOptimizationsContextProvider slug={slug} productType={productType}>
        <EoiDialog
          isOpen={shouldShowEOISurvey}
          onOpen={() => setShouldShowEOISurvey(true)}
          onClose={() => setShouldShowEOISurvey(false)}
          productId={completeProductId}
        />
      </EoiOptimizationsContextProvider>
    </>
  ) : null;
};

export default DegreesStickyActionBar;
