import { css } from '@emotion/react';

const styles = css`
  box-sizing: content-box;
  display: flex;
  gap: var(--cds-spacing-50);
  flex-direction: column;
`;

export default styles;
