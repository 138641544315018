import Root from './Autocomplete';
import { Option, Group, Link } from './Collection';

type RootType = typeof Root;

export interface AutocompleteType extends RootType {
  /**
   * The Autocomplete option component [Optional]
   *
   * See [Props](__storybookUrl__/components-inputs-autocomplete--default#props)
   *
   * @see Autocomplete
   * @see Autocomplete.Group
   */
  Option: typeof Option;

  /**
   * The Autocomplete option component [Optional]
   *
   * See [Props](__storybookUrl__/components-inputs-autocomplete--default#props)
   *
   * @see Autocomplete
   * @see Autocomplete.Group
   */
  Link: typeof Link;

  /**
   * The Autocomplete group component for grouping options [Optional]
   *
   * See [Props](__storybookUrl__/components-inputs-autocomplete--default#props)
   *
   * @see Autocomplete
   * @see Autocomplete.Option
   */
  Group: typeof Group;
}

/**
 * Autocomplete presents a menu of checkbox options where multiple items can be selected and are used to filter or sort content on a page. The Autocomplete is ideal when displaying a selection of 3-15 options.
 *
 * See [Props](__storybookUrl__/components-inputs-autocomplete--default#props)
 *
 * @example
 * ## Anatomy:
 *
 * ### Static:
 * ```jsx
 * <Autocomplete>
 *   <Autocomplete.Option key="1">Option 1</Autocomplete.Option>
 *   <Autocomplete.Option key="2">Option 2</Autocomplete.Option>
 *   <Autocomplete.Option key="3">Option 3</Autocomplete.Option>
 * </Autocomplete>
 * ```
 *
 * ### Dynamic:
 * ```jsx
 * <Autocomplete items={options}>
 *   {(option) => (<Autocomplete.Option key={option.value}>{option.label}</Autocomplete.Option>)}
 * </Autocomplete>
 * ```
 * #### Grouped
 * ```jsx
 * <Autocomplete items={groups}>
 *   {(group) => (
 *      <Autocomplete.Group
 *         key={group.title}
 *         items={group.children}
 *         title={group.title}
 *       >
 *         {(item) => <Autocomplete.Option key={item.id}>{item.label}</Autocomplete.Option>}
 *       </Autocomplete.Group>
 *     )}
 * </Autocomplete>
 * ```
 *
 * @see Autocomplete
 * @see Autocomplete.Option
 * @see Autocomplete.Group
 */
const Autocomplete: AutocompleteType = Object.assign(Root, {
  Option,
  Group,
  Link,
});

export type { Props as AutocompleteProps } from './Autocomplete';

export type { OptionProps, GroupProps, LinkProps } from './Collection';

export { Autocomplete };
