import type { ApolloError, DocumentNode, TypedDocumentNode } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { CountryCode, LanguageCode } from '__generated__/graphql-types';

import getLocalizedDataQuery from 'bundles/internationalization-lib/api/LocalizedDataQuery.graphql';
import type {
  GetLocalizedDataQuery,
  GetLocalizedDataQueryVariables,
} from 'bundles/internationalization-lib/api/__generated__/LocalizedDataQuery';
import { getLocalizedQueryOption } from 'bundles/internationalization-lib/components/WithLocalizedContent';

type LocalizedQueryArguments = {
  isPreview: boolean;
  contentType: string;
  fragmentDoc: DocumentNode | TypedDocumentNode; // this type should be a AST document from graphql
  fragmentFieldsName: string;
  countryCode?: CountryCode;
  languageCode?: LanguageCode;
  filters?: Record<string, string>; // this is used to make the query more specific.
  ssr?: boolean;
  contentfulSpace?: string;
  onError?: (error: ApolloError | undefined) => void;
};

type UseLocalizedContentArgs = LocalizedQueryArguments & {
  skipQuery?: boolean;
};

export const useLocalizedContent = <DataOutputType extends {}>(useLocalizedContentArgs: UseLocalizedContentArgs) => {
  const { skipQuery, ssr, onError, ...localizedQueryArgs } = useLocalizedContentArgs;
  const options = getLocalizedQueryOption(localizedQueryArgs);
  const { data, loading, error } = useQuery<GetLocalizedDataQuery, GetLocalizedDataQueryVariables>(
    getLocalizedDataQuery,
    {
      variables: options.variables,
      context: options.context,
      skip: skipQuery,
      ssr: ssr === undefined ? true : ssr,
      onError,
    }
  );
  const contentData = data?.LocalizedData?.getLocalizedData?.contentData;
  return { data: contentData ? (JSON.parse(contentData) as DataOutputType) : undefined, loading, error };
};
