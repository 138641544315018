import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useLocation } from 'js/lib/useRouter';

import { filterNullOrUndefined } from 'bundles/browse/components/Domain/withPremiumProductCollections';
import { getInfoFromPathname } from 'bundles/premium-hub/hooks/useLearnBrowse';
import GetPostgraduateDiplomas from 'bundles/premium-hub/queries/GetPostgradDiplomas.graphql';
import type {
  GetPostgraduateDiplomasQuery,
  GetPostgraduateDiplomasQueryVariables,
} from 'bundles/premium-hub/queries/__generated__/GetPostgradDiplomas';
import type { DegreeContentfulMetadata, PremiumProductWithMetadata } from 'bundles/premium-hub/types';
import { mapToDegreeContentfulSlug } from 'bundles/premium-hub/utils';
import { convertPpcPremiumProductToPremiumProductProps } from 'bundles/premium-hub/utils/dataTransformationUtils';

const usePostgraduateDiplomaData = (atAGlanceData?: DegreeContentfulMetadata[]) => {
  const location = useLocation();
  const { slug } = getInfoFromPathname(location.pathname);
  const { data } = useQuery<GetPostgraduateDiplomasQuery, GetPostgraduateDiplomasQueryVariables>(
    GetPostgraduateDiplomas,
    {
      context: { clientName: 'gatewayGql' },
      skip: slug !== 'degrees' || Boolean(atAGlanceData) === false,
    }
  );
  const pgDipsWithMetadata: PremiumProductWithMetadata[] | undefined = useMemo(() => {
    const pgDips = data?.PremiumProductCollections?.queryCollectionPaginated?.learningProducts;
    return pgDips?.filter(filterNullOrUndefined)?.map((degree) => {
      const aagItem = atAGlanceData?.find((item) => item?.slug === mapToDegreeContentfulSlug(degree?.slug ?? '')) ?? {};
      return {
        ...aagItem,
        ...convertPpcPremiumProductToPremiumProductProps(degree),
      };
    });
  }, [atAGlanceData, data?.PremiumProductCollections?.queryCollectionPaginated?.learningProducts]);
  return pgDipsWithMetadata;
};

export default usePostgraduateDiplomaData;
