import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('popoverContainer', ['inner']);

const getContainerCss = css`
  height: inherit;
  width: inherit;
  min-width: inherit;

  .${classes.inner} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export default getContainerCss;
