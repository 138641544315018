import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('AutocompleteOption', [
  'checked',
  'disabled',
  'prefix',
  'suffix',
  'content',
  'supportText',
  'checkboxIcon',
  'loading',
  'focused',
]);

export const itemLayoutCss = css`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: var(--cds-spacing-100);
  gap: var(--cds-spacing-150);
  min-width: 200px;
  box-sizing: border-box;
  border-radius: var(--cds-border-radius-50);

  .${classes.content} {
    flex: 1;
  }

  .${classes.prefix}, .${classes.suffix} {
    display: flex;
  }

  &.${classes.loading} {
    .${classes.content} {
      align-self: center;
    }
  }
`;

const itemCss = css`
  color: var(--cds-color-neutral-primary);

  .${classes.checkboxIcon} {
    display: block;
    fill: var(--cds-color-neutral-background-primary);
  }
  &.${classes.checked} {
    background-color: var(
      --cds-color-interactive-background-primary-hover-weak
    );

    .${classes.checkboxIcon} {
      color: var(--cds-color-interactive-primary);
      fill: var(--cds-color-interactive-primary);
    }
  }

  /* Use separate styles for virtual focus */
  &.${classes.focused} {
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: var(--cds-border-radius-50);
      box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
        0 0 0 2px var(--cds-color-interactive-stroke-primary-focus-invert);
      outline: 1px solid transparent;
      outline-offset: 2px;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(
      --cds-color-interactive-background-primary-hover-weak
    );
  }

  &.${classes.disabled} {
    background-color: unset;
    color: var(--cds-color-neutral-disabled-strong);
  }
`;

export default itemCss;
