/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import BlueJays from 'bundles/epic/data/defaults/BlueJays.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  globalHelpButtonUrlExclusionsRegex: string;
  adminUser: boolean;
  educatorUser: boolean;
  enableEducatorChat: boolean;
  enableAdminChat: boolean;
  adminChatConfig: any;
  educatorChatConfig: any;
  enableChat: boolean;
  ChatBoostInstanceName: string;
  ChatConfig: any;
  LearnerPlatformPendoFeedbackSampleIncluded: boolean;
  LearnerNotesPendoSampleIncluded: boolean;
  LearnerForumPendoSampleIncluded: boolean;
  LearnerWelcomePendoSampleIncluded: boolean;
  LearnerPeerReviewPendoSampleIncluded: boolean;
  LearnerMyPurchasesPendoSampleIncluded: boolean;
  enableEnterprisePanelFederationQuery: boolean;
};

const NAMESPACE = 'BlueJays';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([BlueJays as $TSFixMe]);

const BlueJaysEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default BlueJaysEpicClient;
