/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import Markdown from 'bundles/cms/components/Markdown';
import type { InfoModuleItemFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';

type Props = {
  content: InfoModuleItemFragment;
};

const styles = {
  header: css`
    font-size: 28px;
    margin-bottom: var(--cds-spacing-400);
  `,
  bodyParagraph: css`
    margin-bottom: var(--cds-spacing-200);
  `,
};

const InformationalSectionContent = ({ content }: Props) => {
  const { moduleHeader, moduleBody } = content;
  return (
    <div>
      <Typography2 component="h2" variant="titleMedium" css={styles.header}>
        {moduleHeader}
      </Typography2>
      <div>
        <Typography2 component="p" key={moduleBody?.toString()} variant="bodyPrimary" css={styles.bodyParagraph}>
          <Markdown source={moduleBody} />
        </Typography2>
      </div>
    </div>
  );
};

export default InformationalSectionContent;
