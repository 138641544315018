/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import { Accordion, AccordionGroup, AccordionHeader, AccordionPanel, Button, Typography } from '@coursera/cds-core';
import { CloseIcon, InfoFilledIcon } from '@coursera/cds-icons';

import SearchFilterMobile from 'bundles/search-common/components/filters/SearchFilterMobile';
import type { FilterConfig } from 'bundles/search-common/components/filters/getFiltersList';
import { useOwnsCourseraLite } from 'bundles/search-common/providers/LearnerProvider';
import type { SearchActiveFilters, SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

type Props = {
  filtersList: FilterConfig[];
  isHidden: boolean;
  toggleMobileFilterMenu: () => void;
  addFilters: (filters: string) => void;
  removeFilters: (filters: string) => void;
  clearFilters: () => void;
  showCPlusSupportText?: boolean;
  filters?: Record<string, SearchFilterOption[] | undefined>;
  eventingData: {
    activeFilters?: SearchActiveFilters;
    searchIndex: string;
    searchIndexPosition: number;
  };
};

const styles = {
  mobileFiltersModal: (isHidden: boolean) => css`
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    z-index: 4000; /* layover header */
    display: ${isHidden ? 'none' : 'initial'};
  `,
  contentContainer: css`
    padding: 16px 16px 80px;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  `,
  footer: css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: white;
    border-top: 1px solid #ebeced;
    padding: 12px 0;
    display: flex;
    justify-content: space-evenly;
  `,

  infoIcon: css`
    color: var(--cds-color-blue-700) !important;
  `,

  hasSupportText: css`
    .cds-AccordionHeader-button {
      padding-bottom: var(--cds-spacing-100) !important;
    }
  `,
};

function getSupportPropsFromFilter(filter: FilterConfig) {
  let supportText;
  let supportTextProps;
  if (filter.getToolTip?.()) {
    supportText = filter.getToolTip();
    supportTextProps = { icon: <InfoFilledIcon color="interactive" css={styles.infoIcon} /> };
  }
  if (filter.getSupportText?.()) {
    supportText = filter.getSupportText();
  }
  return { supportText, supportTextProps };
}

export const SearchFiltersMobileModal = ({
  filters,
  filtersList,
  isHidden,
  addFilters,
  removeFilters,
  clearFilters,
  toggleMobileFilterMenu,
  showCPlusSupportText,
  eventingData,
}: Props) => {
  // Filter out any filters that have no options available
  const visibleFilters = filtersList.filter((filter) => {
    const attribute = filter.property;
    return filters?.[attribute];
  });
  const isSubscribedToCourseraLite = useOwnsCourseraLite();

  return (
    <div css={styles.mobileFiltersModal(isHidden)}>
      <div css={styles.contentContainer}>
        <div css={styles.header}>
          <Typography variant="h2semibold">{_t('Filter by: ')}</Typography>
          <Button
            variant="ghost"
            icon={<CloseIcon size="small" />}
            iconPosition="after"
            onClick={toggleMobileFilterMenu}
          >
            {_t('Close')}
          </Button>
        </div>
        <AccordionGroup spacing={undefined}>
          {visibleFilters.map((filter) => {
            const attribute = filter.property;
            const name = filter.getNameOnMobile(isSubscribedToCourseraLite);

            const { supportText, supportTextProps } = getSupportPropsFromFilter(filter);

            return (
              <Accordion key={attribute} variant="silent" css={!!supportText && styles.hasSupportText}>
                <AccordionHeader label={name} supportText={supportText} supportTextProps={supportTextProps} />

                <AccordionPanel>
                  <SearchFilterMobile
                    attribute={attribute}
                    filterName={name}
                    showCPlusSupportText={showCPlusSupportText}
                    items={filters?.[attribute]}
                    addFilters={addFilters}
                    removeFilters={removeFilters}
                    eventingData={eventingData}
                  />
                </AccordionPanel>
              </Accordion>
            );
          })}
        </AccordionGroup>
      </div>
      <div css={styles.footer}>
        <Button variant="primary" onClick={toggleMobileFilterMenu}>
          {_t('Apply')}
        </Button>
        <Button variant="secondary" onClick={clearFilters}>
          {_t('Clear All')}
        </Button>
      </div>
    </div>
  );
};

export default memo(SearchFiltersMobileModal);
