/**
 * This file contains utils for the Qualifying Questions experiment.
 * Once a winning variant is found, this file should be adjusted to reflect the winner.
 */
import { DegreeAuthoring_ProductVariant as DegreeVariant } from '__generated__/graphql-types';

import _t from 'i18n!nls/expression-of-interest';

/**
 * This should match the education levels from the User Profile here:
 * https://github.com/webedx-spark/infra-services/blob/b0421ffabed99bc8543b37f56152f92e23030f58/libs/models/src/main/scala/org/coursera/user/StandardProfileMigrator.scala#L341
 */
const EDUCATION_LEVEL_RANK_MAP = {
  highSchool: 4,
  bachelorsDegree: 8,
} as const;

export const isLearnerQualified = (learnerEducation?: number | null, productVariant?: string): boolean => {
  if (
    (productVariant === DegreeVariant.BachelorsDegree || productVariant === DegreeVariant.MastersDegree) &&
    learnerEducation
  ) {
    switch (productVariant) {
      case DegreeVariant.BachelorsDegree:
        return learnerEducation >= EDUCATION_LEVEL_RANK_MAP.highSchool;
      case DegreeVariant.MastersDegree:
        return learnerEducation >= EDUCATION_LEVEL_RANK_MAP.bachelorsDegree;
      default:
        return false;
    }
  } else {
    // We're not running this experiment on any other product types.
    return false;
  }
};

export const getRequiredEducation = (productVariant?: string): string | null => {
  if (productVariant === DegreeVariant.BachelorsDegree) {
    return _t('High school diploma (or equivalent)');
  } else if (productVariant === DegreeVariant.MastersDegree) {
    return _t('Bachelor’s degree');
  } else return null;
};

export type QualifyingQuestionsOption = {
  value: number;
  enum: string;
  text: string;
};

export const getDegreeReadinessOptions = () => [
  {
    value: 4,
    enum: 'HIGH_SCHOOL_DIPLOMA',
    text: _t('High school diploma (or equivalent)'),
  },
  {
    value: 5,
    enum: 'COLLEGE_NO_DEGREE',
    text: _t('Some college but no degree'),
  },
  {
    value: 6,
    enum: 'ASSOCIATE_DEGREE',
    text: _t('Associate degree (e.g. AA, AS)'),
  },
  {
    value: 8,
    enum: 'BACHELORS_DEGREE',
    text: _t("Bachelor's degree (e.g. BA, AB, BS)"),
  },
  {
    value: 9,
    enum: 'MASTERS_DEGREE',
    text: _t("Master's degree (e.g. MA, MS, MEng, MEd, MSW, MNA)"),
  },
  {
    value: 11,
    enum: 'DOCTORATE_DEGREE',
    text: _t('Doctorate degree (e.g. PhD, EdD)'),
  },
];

export const getLearnerTimelineOptions = () => [
  {
    value: 1,
    enum: 'DEGREE_READINESS_ASAP',
    text: _t('As soon as possible'),
  },
  {
    value: 2,
    enum: 'DEGREE_READINESS_3_TO_6_MONTHS',
    text: _t('3-6 months'),
  },
  {
    value: 3,
    enum: 'DEGREE_READINESS_6_TO_12_MONTHS',
    text: _t('6-12 months'),
  },
  {
    value: 4,
    enum: 'DEGREE_READINESS_12_MONTHS_OR_MORE',
    text: _t('12 months or more'),
  },
  {
    value: 5,
    enum: 'DEGREE_READINESS_NOT_SURE',
    text: _t("I'm not sure"),
  },
];

export const getFinancialPlanOptions = () => [
  {
    value: 1,
    enum: 'SELF_FINANCED',
    text: _t('Self-financed'),
  },
  {
    value: 2,
    enum: 'EMPLOYER_TUITION_REIMBURSEMENT',
    text: _t('Employer Tuition Reimbursement'),
  },
  {
    value: 3,
    enum: 'SCHOLARSHIP',
    text: _t('Scholarship'),
  },
  {
    value: 4,
    enum: 'GOVERNMENT_FINANCIAL_AID',
    text: _t('Government (including U.S. Federal Financial Aid or Student Finance UK)'),
  },
  {
    value: 5,
    enum: 'OTHER_OR_UNSURE',
    text: _t('Other / Unsure'),
  },
];

// This function ensures that the rank present on load for a qualifying question field
// has a valid rank value. If not, it will return undefined for the value. This is being added
// as a safety measure since naptime doesn't have strict value validation when submitting an update.
export const validateFieldOptions = (options: QualifyingQuestionsOption[], currentValue?: number | null) => {
  const validValues = options.map((option) => option.value);
  return validValues.includes(currentValue ?? -1) ? currentValue : undefined;
};
