import { css } from '@emotion/react';

import { classes as buttonBaseClasses } from '@core/ButtonBase/getButtonBaseCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('SearchClearButton', [
  'searchClearButton',
  'searchClearIcon',
]);

const getSearchClearButtonCss = css`
  &.${classes.searchClearButton} {
    width: var(--search-action-button-size);
    height: var(--search-action-button-size);

    /* override default focus ring style */
    &.${buttonBaseClasses.focusVisible}::after {
      border-radius: 50%;
      inset: 8px;
    }

    .${classes.searchClearIcon} {
      width: 16px;
      height: 16px;
    }

    .${classes.searchClearIcon} path {
      fill: var(--cds-color-neutral-primary-weak);
    }

    &:focus,
    &:hover {
      .${classes.searchClearIcon} path {
        fill: var(--cds-color-interactive-primary-hover);
      }
    }

    &:active {
      .${classes.searchClearIcon} path {
        fill: var(--cds-color-interactive-primary-pressed);
      }
    }
  }
`;

export default getSearchClearButtonCss;
