import { setConversationId } from 'bundles/common/utils/chatLoaderUtils';
import BlueJaysExperiments from 'bundles/epic/clients/BlueJays';

import type { BoostChatPanel, BoostEvent } from '../types/Boost';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { HiddenMessages, sendHiddenMessage } from './BoostUtils';

// Built-in system event keys (these use camlCase naming):
const CONVERSATION_ID_CHANGED_EVENT_TYPE = 'conversationIdChanged';

// The following string values needs to match the string given in the JSON Emit Event in Boost (naming convention is lower_snake_case)
const CLEAR_CUSTOM_PAYLOAD_EVENT_TYPE = 'clear_custom_payload';
const GET_EPIC_PARAM_EVENT_TYPE = 'get_epic_param';

export const addConversationIdChangedEventListener = (chatPanel: BoostChatPanel) => {
  chatPanel.addEventListener(CONVERSATION_ID_CHANGED_EVENT_TYPE, (event: BoostEvent) => {
    setConversationId(event.detail?.conversationId);
  });
};

export const getClearCustomPayloadEventListener = (chatPanel: BoostChatPanel) => async () => {
  // When we use customPayload these objects can get quite large.  They are sent in every HTTP POST for every message the user types, which is wasteful.
  // Once the bot flow has consumed a customPayload and no longer needs it, it can send this event (clear_custom_payload) to get it cleared.
  // This can also potentially prevent bugs, e.g. clearing an API call response from the custom payload will prevent an older API response from accidentally getting picked up when it no longer applies.
  chatPanel.setCustomPayload(null);
};

export const addClearCustomPayloadEventListener = (chatPanel: BoostChatPanel) => {
  chatPanel.addEventListener(CLEAR_CUSTOM_PAYLOAD_EVENT_TYPE, getClearCustomPayloadEventListener(chatPanel));
};

export const getEpicParamEventListener = (chatPanel: BoostChatPanel) => async (event: BoostEvent) => {
  const key = event.detail.key as never; // apologies for this, but in this particular case epicClient.get() is so strongly typed that we can't have dynamic key names otherwise
  const epicParam = BlueJaysExperiments.get(key);

  if (!epicParam) {
    sendHiddenMessage(HiddenMessages.EVENT_HANDLER_FAILURE, chatPanel);
    return;
  }

  chatPanel.setCustomPayload({
    // eslint-disable-next-line camelcase
    epic_param_value: epicParam,
  });
  sendHiddenMessage(HiddenMessages.EVENT_HANDLER_SUCCESS, chatPanel);
};

export const addEpicParamEventListener = (chatPanel: BoostChatPanel) => {
  chatPanel.addEventListener(GET_EPIC_PARAM_EVENT_TYPE, getEpicParamEventListener(chatPanel));
};
