import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('HeaderSupportText', ['icon']);

export const getHeaderSupportTextCss = () => {
  return css`
    margin-top: var(--cds-spacing-50);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 1;

    .${classes.icon} {
      color: inherit;
      margin: 2px 8px 0 0;
      vertical-align: middle;
      flex-shrink: 0;
    }
  `;
};
