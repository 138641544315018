import * as React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import { useRouter } from 'js/lib/useRouter';

import { getShowGlobalHelpButton } from 'bundles/common/utils/globalHelpButtonUtils';

import ErrorBoundaryWithTagging from './ErrorBoundaryWithTagging';

const Help = createLoadableComponent(() => import('bundles/common/components/Help'));

type GlobalHelpButtonState = {
  showGlobalHelpButton: boolean;
  isAdminPage?: boolean;
  isEducatorPage?: boolean;
};

const GlobalHelpButton = () => {
  const [showGlobalHelpButtonState, setShowGlobalHelpButtonState] = useState<GlobalHelpButtonState>({
    showGlobalHelpButton: false,
    isAdminPage: false,
    isEducatorPage: false,
  });
  const router = useRouter();
  const {
    location: { pathname },
  } = router;

  useEffect(() => {
    setShowGlobalHelpButtonState(getShowGlobalHelpButton(pathname));
  }, [pathname]);
  const { showGlobalHelpButton, isAdminPage, isEducatorPage } = showGlobalHelpButtonState;

  if (!showGlobalHelpButton) {
    return null;
  }

  // Since the component exists on almost every page, we're taking extra precaution to make sure it has no way to crash the whole page/platform by using a local ErrorBoundary.
  return createPortal(
    <ErrorBoundaryWithTagging componentName="global_help_button">
      <Help trackingName="global_icon" isAdminPage={isAdminPage} isEducatorPage={isEducatorPage} />
    </ErrorBoundaryWithTagging>,
    document.body
  );
};

export default GlobalHelpButton;
