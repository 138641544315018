import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('popoverDropdown', [
  'paper',
  'default',
  'success',
  'error',
]);

export type StyleProps = {
  /**
   * Configures the max-width of the dropdown.
   * @default 600px
   */
  maxWidth?: string;

  /**
   * Configures the max-height of the dropdown.
   * @default 100%-80px
   */
  maxHeight?: string;

  /**
   * Configures offset from the controller.
   * @default --cds-spacing-100
   */
  marginTop?: string;
};

const getDropdownCss = ({
  maxHeight = 'calc(100% - 80px)',
  maxWidth = '600px',
  marginTop = 'var(--cds-spacing-100)',
}: StyleProps) => css`
  --popover-padding: var(--cds-spacing-200);
  --dropdown-border-color: var(--cds-color-interactive-primary-hover);

  .${classes.paper} {
    box-sizing: border-box;
    display: flex;
    max-width: ${maxWidth};
    margin-top: ${marginTop};
    max-height: ${maxHeight};
    overflow: hidden;
    border: 1px solid var(--dropdown-border-color);
    border-radius: var(--cds-border-radius-50);
  }

  &.${classes.success} {
    --dropdown-border-color: var(--cds-color-feedback-success-hover);
  }

  &.${classes.error} {
    --dropdown-border-color: var(--cds-color-feedback-error-hover);
  }
`;

export default getDropdownCss;
