import * as React from 'react';

import type { Graph, Thing, WithContext } from 'schema-dts';

import { clearEmptyFields } from 'bundles/seo/utils/utils';

type Props = {
  graph: Graph | WithContext<Thing>;
};

const GoogleMarkup = ({ graph }: Props) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        <script type="application/ld+json">
          ${JSON.stringify(clearEmptyFields(graph))}
        </script>
      `,
      }}
    />
  );
};

export default GoogleMarkup;
