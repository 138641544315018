import * as React from 'react';
import { Element as ReactScrollElement, scroller } from 'react-scroll';

type ScrollProps = {
  smooth?: boolean;
  horizontal?: boolean;
  offset?: number;
  duration?: number | (() => number);
  delay?: number;
};

const defaultProps: ScrollProps = {
  smooth: true,
};

export const scrollAnchorName = 'degreeHubScrollAnchor';

/**
 * Scrolls the page down to the `DegreeHubScrollAnchor`
 * @param scrollProps react-scroll scroll options
 */
export const scrollToAnchor = (scrollProps?: ScrollProps) => {
  const propsToUse = scrollProps ?? defaultProps;
  scroller.scrollTo(scrollAnchorName, propsToUse);
  (document.getElementById(scrollAnchorName) as HTMLElement)?.focus();
};

/**
 * This component does nothing on its own. Designed for use with the exported `scrollToAnchor` function.
 * Add this component to your code and then call `scrollToAnchor` to scroll the page down to the point where the scroll anchor exists.
 */
const DegreeHubScrollAnchor = () => {
  return (
    <div tabIndex={-1} id={`${scrollAnchorName}`}>
      <ReactScrollElement name={scrollAnchorName} />
    </div>
  );
};

export default DegreeHubScrollAnchor;
