/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Grid, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import DegreesDiscoveryExperiments from 'bundles/epic/clients/DegreesDiscovery';
import { EoiOptimizationsContextProvider } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { ExpressionOfInterestContainerFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import DegreesStickyActionBar from 'bundles/premium-hub/components/degrees/DegreesStickyActionBar';
import { EoiWidgetContainer } from 'bundles/premium-hub/components/degrees/EoiWidgetContainer';
import Container from 'bundles/premium-hub/components/shared/Container';
import HubBreadcrumbs from 'bundles/premium-hub/components/shared/HubBreadcrumbs';
import type { PremiumHubPageType, ProductType } from 'bundles/premium-hub/constants';
import { HubPages } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

import HeroBannerMaskHub from 'images/premium-hub/HeroBannerMaskHub.svg';
import HeroBannerMaskMainHub from 'images/premium-hub/HeroBannerMaskMainHub.svg';

type Props = {
  slug: string;
  productId: string;
  productType?: (typeof ProductType)[keyof typeof ProductType];
  pageHeading?: Maybe<string>;
  pageSubheading?: Maybe<string>;
  categoryName?: string;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
  surveyProductType: string;
  expressionOfInterestContainer?: Maybe<ExpressionOfInterestContainerFragment>;
};

type BannerConfig = {
  bannerColor: string;
  maskColor: string;
  maskOpacity: number;
  headingColor: 'body' | 'invertBody';
  subheadingColor: 'supportText' | 'invertBody';
  eoiButtonVariant: 'primary' | 'primaryInvert';
  maskImage: string;
};

const styles = {
  hubBannerContainer: (bannerColor: string) => css`
    background: ${bannerColor};
  `,
  hubBannerWrapper: (bannerColor: string) => css`
    width: 100vw;
    position: relative;
    max-width: 100%;
    ${breakpoints.up('xs')} {
      background: ${bannerColor};
    }

    .BannerContainer {
      position: relative;
      max-width: 100%;
    }
  `,
  background: (maskColor: string, maskOpacity: number, maskImage: string) => css`
    width: 100%;
    background-color: ${maskColor};
    opacity: ${maskOpacity};
    mask-size: cover;
    ${breakpoints.down('md')} {
      display: none;
    }
    ${breakpoints.up('lg')} {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: initial;
      mask-image: url(${maskImage});
      mask-repeat: no-repeat;
      mask-position: 115%;
      mask-size: 60% 100%;
    }
  `,
  bannerContent: (isEOIPresent: boolean) => css`
    max-width: 100%;
    margin-bottom: ${isEOIPresent ? 0 : 'var(--cds-spacing-400)'};
    gap: var(--cds-spacing-200);

    ${breakpoints.up('md')} {
      flex-wrap: nowrap;
    }

    & .rc-EoiContainer .section-embedded {
      border-top: 0 !important;
      border-radius: var(--cds-spacing-100);
      margin-bottom: 0 !important;
      box-shadow: 0 0 24px -16px rgb(0 0 0 / 16%), 0 0 40px -8px rgb(0 0 0 / 16%) !important;
    }
  `,
  mainHubHeroContainer: css`
    padding: var(--cds-spacing-400) 0;
    ${breakpoints.down('md')} {
      padding: var(--cds-spacing-300) 0;
      justify-content: center;
    }
  `,
  breadcrumbsContainer: css`
    position: relative;
  `,
  heading: css`
    margin: 0 0 var(--cds-spacing-150) 0;
  `,
  subheading: css`
    padding: 0;
  `,
};

const getBannerColors = (pageType: PremiumHubPageType): BannerConfig => {
  let bannerConfig: BannerConfig = {
    bannerColor: 'var(--cds-color-neutral-background-primary-weak)',
    maskColor: 'var(--cds-color-neutral-disabled)',
    maskOpacity: 1,
    headingColor: 'body',
    subheadingColor: 'supportText',
    eoiButtonVariant: 'primary',
    maskImage: HeroBannerMaskHub,
  };

  switch (pageType) {
    case HubPages.MainHubPage:
      bannerConfig = {
        ...bannerConfig,
        bannerColor: 'var(--cds-color-emphasis-primary-background-xxstrong)',
        maskColor: 'var(--cds-color-neutral-stroke-primary-weak)',
        headingColor: 'invertBody',
        subheadingColor: 'invertBody',
        maskImage: HeroBannerMaskMainHub,
      };
      break;

    case HubPages.DegreesCategoryHubPage:
      bannerConfig = {
        ...bannerConfig,
        bannerColor: 'var(--cds-color-emphasis-primary-background-xweak)',
        maskColor: 'var(--cds-color-interactive-primary)',
        maskOpacity: 0.1,
      };
      break;

    case HubPages.LandingPage:
      bannerConfig = {
        ...bannerConfig,
        bannerColor: 'var(--cds-color-emphasis-primary-background-xxstrong)',
        maskColor: 'var(--cds-color-emphasis-primary-background-xweak)',
        headingColor: 'invertBody',
        subheadingColor: 'invertBody',
        eoiButtonVariant: 'primaryInvert',
        maskImage: HeroBannerMaskMainHub,
      };
      break;

    default:
      break;
  }

  return bannerConfig;
};

const HeroBanner: React.FunctionComponent<Props> = ({
  slug,
  pageHeading,
  pageSubheading,
  categoryName,
  productId,
  productType,
  surveyProductType,
  expressionOfInterestContainer,
  currentHubPage,
}) => {
  const heroBannerUnificationEpic = DegreesDiscoveryExperiments.preview('heroBannerUnification');
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const heroBannerRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: { sectionName: 'hero_banner', index: sectionIndex },
  });

  if (!pageHeading) {
    return null;
  }

  const { bannerColor, maskColor, maskOpacity, headingColor, subheadingColor, maskImage } =
    getBannerColors(currentHubPage);
  const onCoreHubPage = currentHubPage === HubPages.MainHubPage;
  const onLandingPage = currentHubPage === HubPages.LandingPage;
  const displayBreadcrumbs = !onCoreHubPage && !onLandingPage;
  const shouldShowEoiForm = (onCoreHubPage && heroBannerUnificationEpic === 'C') || !onCoreHubPage;

  const completeProductId = tupleToStringKey([surveyProductType, productId]);
  const eoiProductType =
    currentHubPage === HubPages.MainHubPage ? ProductTypes.DEGREE_HUB : ProductTypes.DEGREE_CATEGORY;

  const filterWidget = (
    <DegreesStickyActionBar
      slug={slug}
      pageHeading={pageHeading}
      categoryName={categoryName}
      productId={productId}
      surveyProductType={surveyProductType}
    />
  );

  const renderBannerContent = () => (
    <div css={styles.mainHubHeroContainer}>
      <Grid container css={styles.bannerContent(!!productId)} justifyContent="space-between">
        <Grid item xs={12} md={7}>
          {displayBreadcrumbs && <HubBreadcrumbs productName={categoryName || pageHeading} productType={productType} />}
          <Typography variant="d2semibold" component="h1" color={headingColor} css={styles.heading}>
            {pageHeading}
          </Typography>
          {(heroBannerUnificationEpic === 'B' || heroBannerUnificationEpic === 'C') && pageSubheading && (
            <Typography2 component="p" color={subheadingColor} css={styles.subheading}>
              {pageSubheading}
            </Typography2>
          )}
        </Grid>
        <Grid item data-testid="BannerEOIContainer" xs={12} md={5} lg={4}>
          {shouldShowEoiForm && productId && slug && (
            <>
              <EoiOptimizationsContextProvider slug={slug} productType={eoiProductType}>
                <EoiWidgetContainer
                  productName={categoryName || pageHeading}
                  productId={completeProductId}
                  programInterestText={expressionOfInterestContainer?.programInterestText}
                  eoiButtonText={expressionOfInterestContainer?.emailMeButtonText}
                />
              </EoiOptimizationsContextProvider>
            </>
          )}
          {!shouldShowEoiForm && filterWidget}
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div
      data-testid="HeroBanner"
      data-test="HubBanner"
      css={styles.hubBannerContainer(bannerColor)}
      ref={heroBannerRef}
    >
      <TrackedDiv
        trackingName="hero_banner"
        trackingData={{ currentPage: currentHubPage }}
        withVisibilityTracking={true}
        atMostOnce={true}
        trackClicks={false}
        css={styles.hubBannerWrapper(bannerColor)}
      >
        <Container>
          <div id="background" css={styles.background(maskColor, maskOpacity, maskImage)} />
          <div className="BannerContainer" id="HubBanner">
            {renderBannerContent()}
          </div>
        </Container>
      </TrackedDiv>
    </div>
  );
};

export default React.memo(HeroBanner);
