/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import type { ReactChild } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { simplifyQueryValue } from 'js/lib/queryUtils';
import { useLocation, useNavigate } from 'js/lib/useRouter';

import { Button, Dialog, InlineNotification, Link, PasswordField, TextField, Typography2 } from '@coursera/cds-core';

import InternationalPhoneNumberInput from 'bundles/account-profile/components/i18n-phone-number-input/InternationalPhoneNumberInput';
import type { ProfilePhone } from 'bundles/account-profile/components/types';
import {
  LOGIN_REDESIGN_RECAPTCHA_ID,
  RECAPTCHA_ACTIONS,
  EMAIL_CONSTRAINT as isEmailValid,
  PASSWORD_CONSTRAINT as isPasswordValid,
} from 'bundles/authentication/constants';
import Separator from 'bundles/authentication/shared/Separator';
import * as actions from 'bundles/authentication/shared/actions';
import { useRecaptcha } from 'bundles/authentication/shared/hooks/useRecaptcha';
import { HANDLED_ERRORS, NEEDS_2FA_ERRORS, errorMessageFactory } from 'bundles/authentication/utils/loginUtils';
import { useEoiOptimizationsContext } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import EoiSSO from 'bundles/expression-of-interest/components/authentication/EoiSSO';
import { ACTION_QUERY_PARAMS, CONTINUE_EOI_PARAMS } from 'bundles/expression-of-interest/constants';
import type { ActionQueryParam } from 'bundles/expression-of-interest/types';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';
import { isDestinationDegree, setActionQueryParamAndReload } from 'bundles/expression-of-interest/utils/util';
import type { Phone } from 'bundles/naptimejs/resources/__generated__/ProfilesV1';
import { customFormattedPhoneStyles } from 'bundles/university-program-qualification/components/SurveyQuestion';
import { trackAuthAttempts } from 'bundles/user-account/utils/authTrackingUtils';
import Instrumentation from 'bundles/userModal/lib/instrumentation';

import _t from 'i18n!nls/expression-of-interest';

type Props = {
  learnerEmail: string;
  phone: Phone;
  handleEmailChange: (email: string) => void;
  handlePhoneChange: (phone: ProfilePhone) => void;
  handlePreviousSection: () => void;
  handleSubmit: () => Promise<void | {}>;
  trackingProductId: string;
};

const styles = {
  primaryButton: css`
    margin-right: var(--cds-spacing-200);
  `,
  reCAPTCHA: css`
    display: none;
  `,
  passwordField: css`
    margin-top: var(--cds-spacing-150);
    margin-bottom: var(--cds-spacing-150);
  `,
  supportText: css`
    margin-top: var(--cds-spacing-150);
  `,
};

export const LoginSection = (props: Props): React.ReactElement => {
  const {
    learnerEmail,
    phone,
    handleEmailChange,
    handlePreviousSection,
    handlePhoneChange,
    handleSubmit,
    trackingProductId,
  } = props;
  const { trackInteractEoi, trackSubmitEoi } = useEoiEventing(trackingProductId);
  const { slug } = useEoiOptimizationsContext();
  const location = useLocation();
  const navigate = useNavigate();

  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const verificationCodeInput = useRef<HTMLInputElement | null>(null);
  const [password, setPassword] = useState<string>('');
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [hasPhoneError, setHasPhoneError] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [has2FA, setHas2FA] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [formError, setFormError] = useState<string | ReactChild>('');

  const recaptcha = useRecaptcha(LOGIN_REDESIGN_RECAPTCHA_ID, { action: RECAPTCHA_ACTIONS.login });

  const isDestinationDegreeEoi = isDestinationDegree(slug);

  const disclaimerText = isDestinationDegreeEoi
    ? _t(
        'By sharing my phone number, I consent to receiving calls, texts or emails from Coursera and the university about educational opportunities.'
      )
    : _t(
        'By sharing my phone number, I consent to receiving calls or texts from Coursera about educational opportunities.'
      );

  useEffect(() => {
    const hasPasswordError = isPasswordValid(password);

    if (hasPasswordError) {
      setPasswordError(hasPasswordError);
    } else {
      setPasswordError('');
    }
  }, [password]);

  useEffect(() => {
    const hasEmailError = isEmailValid(learnerEmail);
    if (hasEmailError) {
      setEmailError(hasEmailError);
    } else {
      setEmailError('');
    }
  }, [learnerEmail]);

  useEffect(() => {
    if (phone.phoneNumber === '') {
      setHasPhoneError(true);
    } else {
      setHasPhoneError(false);
    }
  }, [phone]);

  const getDefaultError = () => {
    return (
      <FormattedMessage
        message={_t('An unexpected error has occurred. Please try again or contact our {helpCenterLink}.')}
        helpCenterLink={<Link href="https://courserahelp.zendesk.com">{_t('Learner Help Center')}</Link>}
      />
    );
  };

  const handleError = (response: $TSFixMe) => {
    trackSubmitEoi('api_failure', 'login_section');

    recaptcha.reset();

    const { errorCode: code } = response?.responseJSON || {};

    if (HANDLED_ERRORS.includes(code)) {
      const errorMessages = errorMessageFactory();
      const errorCode = code as keyof typeof errorMessages;

      if (errorCode === 'invalidCredential') {
        setFormError(errorMessages[errorCode]());
        verificationCodeInput.current?.focus();
      } else {
        setFormError(errorMessages[errorCode]);
      }

      setHas2FA(NEEDS_2FA_ERRORS.includes(code));
      setIsRunning(false);
    } else {
      setFormError(getDefaultError());
    }
  };

  const handleLogin = async () => {
    if (passwordError || emailError || hasPhoneError) {
      return setHasFormError(true);
    }
    const token = await recaptcha?.getResponse();
    if (!token) {
      return setHasFormError(true);
    }

    setIsRunning(true);

    const eventData = {
      namespace: {
        app: 'degree-description-app',
        page: 'login',
      },
    };
    trackAuthAttempts(eventData, 'login');

    const payload = {
      email: learnerEmail,
      password,
      token,
      ...(verificationCode && { mfa: verificationCode }),
    };

    try {
      trackInteractEoi('login', 'login_section');

      await actions.login(payload);
      Instrumentation.login();
      actions.onLogin();

      trackSubmitEoi('success', 'login_section');
      handleSubmit();

      const actionFromQuery = simplifyQueryValue(location?.query?.action) ?? ''; // TODO handle null/undefined case
      const action: ActionQueryParam = Object.values(ACTION_QUERY_PARAMS).includes(actionFromQuery)
        ? actionFromQuery
        : ACTION_QUERY_PARAMS.EMAIL_ME_INFO;
      return setActionQueryParamAndReload(action, location, navigate, CONTINUE_EOI_PARAMS.CONTINUE_FROM_LOGIN);
    } catch (e) {
      return handleError(e);
    }
  };

  const handleBack = () => {
    trackInteractEoi('back', 'login_section');
    handlePreviousSection();
  };

  const handleForgotPassword = () => {
    trackInteractEoi('forgot_password', 'login_section');
  };

  const { HeadingGroup, Content, Actions } = Dialog;

  return (
    <>
      <HeadingGroup
        supportText={_t(
          'After logging in, you will receive information on upcoming enrollment dates, webinars, and program news via email.'
        )}
      >
        {_t('Welcome back!')}
      </HeadingGroup>
      <Content>
        <>
          <div css={styles.reCAPTCHA} id={LOGIN_REDESIGN_RECAPTCHA_ID} data-testid="recaptcha-signup" />
          <EoiSSO trackingProductId={trackingProductId} />
          <Separator text={_t('or')} />
        </>

        {formError && <InlineNotification severity="error">{formError}</InlineNotification>}

        <TextField
          label={_t('Email')}
          type="email"
          placeholder={_t('name@email.com')}
          fullWidth
          onChange={(e) => handleEmailChange(e.target.value)}
          ref={verificationCodeInput}
          validationStatus={hasFormError && !!emailError ? 'error' : undefined}
          validationLabel={emailError}
          value={learnerEmail}
          autoComplete="email"
        />

        <div css={styles.passwordField}>
          <PasswordField
            label={_t('Password')}
            placeholder={_t('Enter your password')}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            validationStatus={hasFormError && !!passwordError ? 'error' : undefined}
            validationLabel={passwordError}
            value={password}
          />
          <Link
            href="/reset"
            target="_blank"
            variant="quiet"
            typographyVariant="bodySecondary"
            onClick={handleForgotPassword}
          >
            {_t('Forgot password?')}
          </Link>
        </div>

        <InternationalPhoneNumberInput
          profile={{ phone }}
          isOptional={false}
          isEmptyValid={false}
          customStyles={customFormattedPhoneStyles}
          submissionError={hasFormError}
          handleFormChange={handlePhoneChange}
          handleError={(err) => {
            if (err) {
              setHasFormError(true);
            }
          }}
          ref={phoneNumberRef}
        />

        <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.supportText}>
          {disclaimerText}
        </Typography2>

        {has2FA && (
          <TextField
            label={_t('Verification code')}
            type="number"
            placeholder="000000"
            fullWidth
            onChange={(e) => setVerificationCode(e.target.value)}
            ref={verificationCodeInput}
            value={verificationCode}
            autoComplete="one-time-code"
          />
        )}
      </Content>
      <Actions>
        <Button onClick={handleLogin} css={styles.primaryButton} loading={isRunning} disabled={isRunning}>
          {_t('Login and submit')}
        </Button>

        <Button onClick={handleBack} variant="secondary">
          {_t('Back')}
        </Button>
      </Actions>
    </>
  );
};

export default LoginSection;
