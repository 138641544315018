import _t from 'i18n!nls/user-account';

export const USER_NAME_MIN_LENGTH = 1;

export const USER_NAME_MAX_LENGTH = 128;

export const USER_NAME_REGEX_PATTERN = `.{${USER_NAME_MIN_LENGTH},${USER_NAME_MAX_LENGTH}}`;

export const USER_PASSWORD_MIN_LENGTH = 8;

export const USER_PASSWORD_MAX_LENGTH = 72;

export const USER_PASSWORD_REGEX_PATTERN = `.{${USER_PASSWORD_MIN_LENGTH},${USER_PASSWORD_MAX_LENGTH}}`;

export const USER_EMAIL_REGEX_PATTERN = '.+@.+\\..+';

export const SIGNUP_FORM_KEY = 'signup';
export const LOGIN_FORM_KEY = 'login';
export const COMPLETE_FORM_KEY = 'complete';
export const LINK_FORM_KEY = 'link';
export const LINKED_FORM_KEY = 'linked';
export const EXPIRED_LINK_FORM_KEY = 'expired';
export const FORGOT_FORM_KEY = 'forgot';

export const AUTH_MODES = {
  signup: 'signup',
  signupEmbedded: 'signup_embedded',
  login: 'login',
  expired: 'expired',
} as const;

export const AUTH_FORM_IDS = {
  name: 'name',
  email: 'email',
  password: 'password',
  verificationCode: 'verificationCode',
} as const;

export const getFormTranslations = () => ({
  AUTH: _t('Authentication'),
  SLOGAN: _t('Learn skills from top universities for free.'),
  COMPLETE: _t('Complete'),
  LOGIN: _t('Log in'),
  SIGN_UP: _t('Sign up'),
  SIGN_UP_WITH_ORG: _t('Sign up with your organization'),
  SIGN_IN_WITH_ORG: _t('Log in with your organization'),
  OR: _t('or'),
  HAVING_TROUBLE: _t('Having trouble logging in?'),
});

export type SIGNUP_FORM_KEY_TYPE = 'signup';
export type LOGIN_FORM_KEY_TYPE = 'login';
export type COMPLETE_FORM_KEY_TYPE = 'complete';
export type LINK_FORM_KEY_TYPE = 'linked';

export type AuthMode = SIGNUP_FORM_KEY_TYPE | LOGIN_FORM_KEY_TYPE | COMPLETE_FORM_KEY_TYPE | LINK_FORM_KEY_TYPE;

export type UserObjectType = {
  id: number;
  fullName: string;
  // eslint-disable-next-line camelcase
  email_address: string;
  authenticated: boolean;
};

enum MODES {
  COMPLETE = 'complete',
  LINK = 'link',
  LOGIN = 'login',
  SIGNUP = 'signup',
  EXPIRED = 'expired',
}

export { MODES };
