import type * as React from 'react';

import type { ButtonName, SectionName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { useAuthModalTrackerContext } from 'bundles/authentication/shared/components/authentication-modal/contexts/AuthenticationTracking';

type EventData = {
  name: ButtonName;
  sectionName?: SectionName;
};

type TrackEvent = ({ name }: EventData) => void;

type AuthenticationButtonTrackingProps = {
  children: (track: TrackEvent) => React.ReactElement | null;
};

const AuthenticationV3CtaTracking: React.FC<AuthenticationButtonTrackingProps> = ({ children }) => {
  const track = useTracker();
  const modalName = useAuthModalTrackerContext()?.modalName;
  const trackButton: TrackEvent = ({ name, sectionName }) =>
    track('click_button', {
      button: { name },
      ...(sectionName && { pageSection: { sectionName } }),
      ...(modalName && { modal: { name: modalName } }),
    });

  return children(trackButton);
};

export default AuthenticationV3CtaTracking;
