import { useLocation } from 'js/lib/useRouter';

import useSearchParamsFromQueryString from 'bundles/search-common/utils/useSearchParamsFromQueryString';

import type { SearchURLParameters } from './searchTypes';

function useSearchParameters(): SearchURLParameters {
  const location = useLocation();
  const searchParams = useSearchParamsFromQueryString(location.search);

  return searchParams;
}

export default useSearchParameters;
