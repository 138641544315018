/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import Markdown from 'bundles/cms/components/Markdown';
import type { CategoryIntroFieldsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import Container from 'bundles/premium-hub/components/shared/Container';

type Props = CategoryIntroFieldsFragment;

const styles: Record<string, CSSProperties> = {
  categoryIntroWrapper: (theme: Theme) => ({
    marginTop: 'var(--cds-spacing-1000)',
    [`@media (max-width: ${breakpoints.values.md}px)`]: {
      marginTop: 'var(--cds-spacing-800)',
    },
    '.ContentRow': {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      [`@media (max-width: ${breakpoints.values.md}px)`]: {
        flexDirection: 'column-reverse',
      },
    },
  }),
  categoryIntroDescription: (theme: Theme) => ({
    '.rc-Markdown.styled': {
      h2: theme.typography.h2semibold,
    },
    [`@media (max-width: ${breakpoints.values.md}px)`]: { marginTop: 'var(--cds-spacing-400)' },
  }),
  profilePictureContainer: {
    position: 'relative',
    width: '100%',
  },
  learnerInfo: (theme: Theme) => ({
    padding: 'var(--cds-spacing-100)',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.57) 100%)',
  }),
};

const UnifiedCategoryIntro: React.FunctionComponent<Props> = ({
  categoryIntroDescription,
  profilePicture,
  learnerName,
  enrolledProgram,
}) => {
  const theme = useTheme();
  if (!categoryIntroDescription) {
    return null;
  }
  return (
    <div css={styles.categoryIntroWrapper(theme)}>
      <Container data-test="UnifiedCategoryIntro">
        <Grid container className="ContentRow">
          {categoryIntroDescription && (
            <Grid item md={6} lg={6}>
              <div css={styles.categoryIntroDescription(theme)}>
                <Markdown source={categoryIntroDescription} />
              </div>
            </Grid>
          )}
          <Grid item md={4} lg={4} css={{ width: '100%', padding: '0 12px' }}>
            <div css={styles.profilePictureContainer} data-test="UnifiedCategoryProfile">
              {profilePicture && (
                <img
                  src={profilePicture?.url || (profilePicture as string)}
                  alt=""
                  className="w-100 h-100 d-block"
                  data-test="CategoryProfilePicture"
                />
              )}
              {learnerName && enrolledProgram && (
                <div css={styles.learnerInfo(theme)}>
                  <Typography2 component="p" variant="bodyPrimary" color="invertBody">
                    {learnerName}
                  </Typography2>
                  <Typography2 component="p" variant="bodySecondary" color="invertBody">
                    {enrolledProgram}
                  </Typography2>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default UnifiedCategoryIntro;
